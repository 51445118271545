/*
 * Reducer actions related with login
 */
import { ActionTypes } from 'constants/index';

export function loginFailed() {
  return {
    type: ActionTypes.LOGIN_FAILED,
  };
}

export function onLoginResponse(response) {
  return {
    type: ActionTypes.LOGIN_RESPONSE,
    response,
  };
}

export function requestFacebookLogin(accessToken) {
  return {
    type: ActionTypes.LOGIN_FACEBOOK_REQUEST,
    accessToken,
  };
}

export function requestGoogleLogin(accessToken) {
  return {
    type: ActionTypes.LOGIN_GOOGLE_REQUEST,
    accessToken,
  };
}

export function requestEmailLogin(email, password, remember) {
  return {
    type: ActionTypes.LOGIN_EMAIL_REQUEST,
    email,
    password,
    remember,
  };
}

export function logout() {
  return {
    type: ActionTypes.LOGOUT,
  };
}

export function requestRecoverPassword(email) {
  return {
    type: ActionTypes.RECOVER_PASSWORD,
    email,
  };
}

export function requestResetPassword(token_pass_recovery, password) {
  return {
    type: ActionTypes.RESET_PASSWORD,
    token_pass_recovery,
    password,
  };
}

export function updateProfileResponse(response) {
  return {
    type: ActionTypes.UPDATE_PROFILE_RESPONSE,
    response,
  };
}
