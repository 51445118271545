import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  accommodations: [],
  loading: false,
  accommodationsCount: 0,
  accommodation: null,
};

export default {
  accommodationReducer: handleActions(
    {
      [ActionTypes.GET_ACCOMMODATIONS](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_ACCOMMODATIONS_SUCCESS](state, action) {
        return {
          ...state,
          accommodations: action.accommodations,
          accommodationsCount: action.accommodationsCount,
          loading: false,
        };
      },
      [ActionTypes.GET_ACCOMMODATIONS_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_ACCOMMODATION](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_ACCOMMODATION_SUCCESS](state, action) {
        return {
          ...state,
          accommodation: action.accommodation,
          loading: false,
        };
      },
      [ActionTypes.GET_ACCOMMODATION_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.REMOVE_ACCOMMODATION](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.REMOVE_ACCOMMODATION_SUCCESS](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.REMOVE_ACCOMMODATION_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.SAVE_ACCOMMODATION](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.SAVE_ACCOMMODATION_SUCCESS](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.SAVE_ACCOMMODATION_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.UPDATE_ACCOMMODATION](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.UPDATE_ACCOMMODATION_SUCCESS](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.UPDATE_ACCOMMODATION_FAILURE](state, acton) {
        return {
          ...state,
          loading: false,
        };
      },
    },
    appState,
  ),
};
