import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from '../../../assets/media/images/Authentic-Traveling-Logo.png';
import leftArrow from 'assets/media/icons/left-arrow.png';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 25px;
  @media (min-width: 1170px) {
    padding-left: 55px;
  }
  @media (max-width: 970px) {
    width: 100%;
  }
`;

const GoBackHomeContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
`;

const GoBackHomeText = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;
`;

const Header = styled.div`
  border-radius: 4px;
  height: 90px;
  display: flex;
  padding-left: 40px;
`;

const AppLogo = styled.div`
  padding-right: 50px;
`;

const GetStarted = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: left;
  text-color: #9e9e9e;
`;

const RegistText = styled.p`
  color: #9e9e9e;
`;

const GoToStart = styled.span`
  display: block;
  @media (min-width: 970px) {
    display: initial;
  }
`;

const LinkText = styled.span`
  color: #38b6ff;
`;

const LeftArrowIcon = styled.img`
  width: 25px;
  padding-right: 10px;
  padding-bottom: 2px;
`;

const CustomLink = styled.a``;

export default class LoginHeader extends Component {
  render() {
    return (
      <Container>
        <GoBackHomeContainer>
          <CustomLink href="https://authentictraveling.com/">
            <GoBackHomeText>
              <LeftArrowIcon src={leftArrow} alt="left-arrow" />
              Return to Homepage
            </GoBackHomeText>
          </CustomLink>
        </GoBackHomeContainer>

        <Header>
          <AppLogo>
            <Link to={'/'}>
              <img src={Logo} width="250" />
            </Link>
          </AppLogo>
          <GetStarted>
            {this.props.backToLogin ? (
              <RegistText>
                Already have an account?
                <GoToStart>
                  <Link to={'/'}>
                    <LinkText> Log in now!→ </LinkText>
                  </Link>
                </GoToStart>
              </RegistText>
            ) : (
              <RegistText>
                Don’t have an account?
                <GoToStart>
                  <Link to={'/register'}>
                    <LinkText> Get started now!→ </LinkText>
                  </Link>
                </GoToStart>
              </RegistText>
            )}
          </GetStarted>
        </Header>
      </Container>
    );
  }
}
