import { put, call } from 'redux-saga/effects';
import * as regionsActions from '../actions/regionsActions';
import * as appActions from '../actions/app';
import { goBack } from 'modules/history';

export function* createRegions(api, action) {
  const response = yield call(api.createRegions, action.region);

  if (response.status == 200) {
    yield put(regionsActions.createRegionsSuccess());
    yield goBack();
    yield put(appActions.showAlert('Region Created.', { variant: 'success' }));
  } else {
    yield put(regionsActions.createRegionsFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getRegions(api, action) {
  const response = yield call(api.getRegions, action.id);
  if (response.status == 200) {
    yield put(regionsActions.getRegionsSuccess(response.data));
  } else {
    yield put(regionsActions.getRegionsFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* editRegions(api, action) {
  const response = yield call(api.editRegions, action.id, action.region);

  if (response.status == 200) {
    yield put(regionsActions.editRegionsSuccess());
    yield goBack();
    yield put(appActions.showAlert('Region Edited.', { variant: 'success' }));
  } else {
    yield put(regionsActions.editRegionsFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getRegionsForSelect(api, action) {
  const { params } = action;
  const response = yield call(api.getRegionsForSelect, params);
  if (response.status == 200) {
    yield put(regionsActions.getRegionsForSelectSuccess(response.data));
  } else {
    yield put(regionsActions.getRegionsForSelectFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getAllRegions(api, action) {
  const { page, elementsPerPage, country_id } = action;
  const response = yield call(api.getAllRegions, { page, elementsPerPage, country_id });

  if (response.status == 200) {
    yield put(regionsActions.getAllRegionsSuccess(response.data));
  } else {
    yield put(regionsActions.getAllRegionsFailure());
  }
}

export function* removeRegions(api, action) {
  const { id } = action;
  const response = yield call(api.removeRegions, id);

  if (response.status == 204) {
    yield put(regionsActions.removeRegionsSuccess(id));
    yield put(appActions.showAlert('Region Deleted.', { variant: 'success' }));
  } else {
    yield put(regionsActions.removeRegionsFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
