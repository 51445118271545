import React from 'react';
import styled from 'styled-components';
import { MONTHS } from '../../constants';
import { Formik } from 'formik';
import ModalExcel from 'components/Suggestions/ModalExcel';

const GridContainer = styled.div`
  padding-top: 40px;
`;

const BoxSugestions = styled.div`
  overflow-x: auto;
  display: block;
  max-width: 100%;
  display: grid;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const TBody = styled.div``;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: ${props => props.backgroundColor || '#38b6ff'};
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  line-height: 0;
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
  margin-left: ${props => props.marginLeft && props.marginLeft}%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const BoxContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  border-radius: 5px;
`;

const acceptable = styled.div``;
const Select = styled.select`
  width: 100%;
  height: 52px;
  border-radius: 5px;
  background-color: #ffffff;
  border: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  margin-left: 5px;
  margin-right: 20px;
`;

const LinkText = styled.p`
  padding-top: 5px;
  font-size: 18px;
  cursor: pointer;
  color: #38b6ff;
`;
const InputActivity = styled.div`
  width: 150px;
  height: 52px;
  background-color: ${props => props.backgroundColor};
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 25)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : -10)}px;
  ::placeholder {
    font-family: 'Josefin Sans';
    font-size: 10px;
    color: ${props => props.colorPrimary};
  }
  font-family: 'Josefin Sans';
  font-size: 17px;
  color: ${props => props.colorPrimary};
`;

const BoxVisits = styled.input`
  width: 7%;
  object-fit: contain;
  border-radius: 10px;
  margin-right: 10px;
  margin-left: 10px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: ${props => props.backgroundColor};
  border: 1px solid #000;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
`;

const VisitSuggestionForm = props => {
  const {
    token,
    viewMode,
    renderModalExcel,
    handleModalExcel,
    weather,
    visits,
    saveVisitSuggestion,
    country: { id },
  } = props;

  const selectSuggestion = (name, values) => {
    let value = '';
    switch (name) {
      case 'early_january':
        value = values.early_january;
        name = 'Early January';
        break;
      case 'mid_january':
        value = values.mid_january;
        name = 'Mid January';
        break;
      case 'late_january':
        value = values.late_january;
        name = 'Late January';
        break;
      case 'early_february':
        value = values.early_february;
        name = 'Early February';
        break;
      case 'mid_february':
        value = values.mid_february;
        name = 'Mid February';
        break;
      case 'late_february':
        value = values.late_february;
        name = 'Late February';
        break;
      case 'early_march':
        value = values.early_march;
        name = 'Early March';
        break;
      case 'mid_march':
        value = values.mid_march;
        name = 'Mid March';
        break;
      case 'late_march':
        value = values.late_march;
        name = 'Late March';
        break;
      case 'early_april':
        value = values.early_april;
        name = 'Early April';
        break;
      case 'mid_april':
        value = values.mid_april;
        name = 'Mid April';
        break;
      case 'late_april':
        value = values.late_april;
        name = 'Late April';
        break;
      case 'early_may':
        value = values.early_may;
        name = 'Early May';
        break;
      case 'mid_may':
        value = values.mid_may;
        name = 'Mid May';
        break;
      case 'late_may':
        value = values.late_may;
        name = 'Late May';
        break;
      case 'early_june':
        value = values.early_june;
        name = 'Early June';
        break;
      case 'mid_june':
        value = values.mid_june;
        name = 'Mid June';
        break;
      case 'late_june':
        value = values.late_june;
        name = 'Late June';
        break;
      case 'early_july':
        value = values.early_july;
        name = 'Early July';
        break;
      case 'mid_july':
        value = values.mid_july;
        name = 'Mid July';
        break;
      case 'late_july':
        value = values.late_july;
        name = 'Late July';
        break;
      case 'early_august':
        value = values.early_august;
        name = 'Early August';
        break;
      case 'mid_august':
        value = values.mid_august;
        name = 'Mid August';
        break;
      case 'late_august':
        value = values.late_august;
        name = 'Late August';
        break;
      case 'early_september':
        value = values.early_september;
        name = 'Early September';
        break;
      case 'mid_september':
        value = values.mid_september;
        name = 'Mid September';
        break;
      case 'late_september':
        value = values.late_september;
        name = 'Late September';
        break;
      case 'early_october':
        value = values.early_october;
        name = 'Early October';
        break;
      case 'mid_october':
        value = values.mid_october;
        name = 'Mid October';
        break;
      case 'late_october':
        value = values.late_october;
        name = 'Late October';
        break;
      case 'early_november':
        value = values.early_november;
        name = 'Early November';
        break;
      case 'mid_november':
        value = values.mid_november;
        name = 'Mid November';
        break;
      case 'late_november':
        value = values.late_november;
        name = 'Late November';
        break;
      case 'early_december':
        value = values.early_december;
        name = 'Early December';
        break;
      case 'mid_december':
        value = values.mid_december;
        name = 'Mid December';
        break;
      case 'late_december':
        value = values.late_december;
        name = 'Late December';
        break;
      default:
        break;
    }
    if (value === 'Inappropiate') {
      return (
        <InputActivity
          value={name}
          min={0}
          disabled
          backgroundColor={'#FFFFFF'}
          colorPrimary={'#484848'}
        >
          <p>{name}</p>
        </InputActivity>
      );
    } else if (value === 'Acceptable') {
      return (
        <InputActivity
          value={name}
          min={0}
          disabled
          backgroundColor={'#56c1fe'}
          colorPrimary={'#484848'}
        >
          <p>{name}</p>
        </InputActivity>
      );
    } else {
      return (
        <InputActivity
          name={name}
          value={name}
          min={0}
          disabled
          backgroundColor={'#004c7f'}
          colorPrimary={'#FFFFFF'}
        >
          <p>{name}</p>
        </InputActivity>
      );
    }
  };

  const renderMonthsSuggestions = values =>
    MONTHS.map((month, key) => {
      const monthLowerCase = month.toLocaleLowerCase();
      const earlyMonth = 'early_' + monthLowerCase;
      const midMonth = 'mid_' + monthLowerCase;
      const lateMonth = 'late_' + monthLowerCase;

      return (
        <FlexRow>
          {selectSuggestion(earlyMonth, values)}
          {selectSuggestion(midMonth, values)}
          {selectSuggestion(lateMonth, values)}
        </FlexRow>
      );
    });

  return (
    <GridContainer>
      <FlexColumn>
        <FlexRow>
          <FlexColumn>
            <Label marginTop={15}>Best Time To Visit For Weather Start Date</Label>
            <Label marginTop={-30} marginLeft={60}>
              <BoxVisits disabled backgroundColor={'#E4E4E4'} />
              Inappropiate
              <BoxVisits disabled backgroundColor={'#56c1fe'} />
              Aceptable
              <BoxVisits disabled backgroundColor={'#004c7f'} />
              Best
            </Label>
          </FlexColumn>
          {/* <LinkText onClick={() => handleModalExcel(true)} style={{ marginTop: 10 }}>
            Import from sheet
          </LinkText>*/}
        </FlexRow>
        <BoxSugestions>
          <BoxContainer>
            {renderMonthsSuggestions(weather)}
            {!viewMode && <Button onClick={() => saveVisitSuggestion(id, values)}>Save</Button>}
          </BoxContainer>
        </BoxSugestions>
      </FlexColumn>
      {/*<ModalExcel
        id={id}
        token={token}
        url="visit-suggestions"
        renderModalExcel={renderModalExcel}
        handleModalExcel={handleModalExcel}
      ></ModalExcel>*/}
    </GridContainer>
  );
};

export default VisitSuggestionForm;
