import { handleActions } from 'modules/helpers';
import { ActionTypes } from 'constants/index';

const DEFAULT_VISIT_SUGGESTION = {
  early_january: 'Inappropiate',
  mid_january: 'Inappropiate',
  late_january: 'Inappropiate',
  early_february: 'Inappropiate',
  mid_february: 'Inappropiate',
  late_february: 'Inappropiate',
  early_march: 'Inappropiate',
  mid_march: 'Inappropiate',
  late_march: 'Inappropiate',
  early_april: 'Inappropiate',
  mid_april: 'Inappropiate',
  late_april: 'Inappropiate',
  early_may: 'Inappropiate',
  mid_may: 'Inappropiate',
  late_may: 'Inappropiate',
  early_june: 'Inappropiate',
  mid_june: 'Inappropiate',
  late_june: 'Inappropiate',
  early_july: 'Inappropiate',
  mid_july: 'Inappropiate',
  late_july: 'Inappropiate',
  early_august: 'Inappropiate',
  mid_august: 'Inappropiate',
  late_august: 'Inappropiate',
  early_september: 'Inappropiate',
  mid_september: 'Inappropiate',
  late_september: 'Inappropiate',
  early_october: 'Inappropiate',
  mid_october: 'Inappropiate',
  late_october: 'Inappropiate',
  early_november: 'Inappropiate',
  mid_november: 'Inappropiate',
  late_november: 'Inappropiate',
  early_december: 'Inappropiate',
  mid_december: 'Inappropiate',
  late_december: 'Inappropiate',
};

const DEFAULT_AVERAGE_DAILY_EXPENSES = {
  //Budget
  public_transportation_budget: 0,
  sights_and_entertainment_budget: 0,
  meals_budget: 0,
  lodging_couple_budget: 0,
  lodging_alone_budget: 0,
  total_expenses_per_day_budget: 0,
  total_expenses_per_day_not_splitting_room_budget: 0,
  //Moderate
  public_transportation_moderate: 0,
  sights_and_entertainment_moderate: 0,
  meals_moderate: 0,
  lodging_couple_moderate: 0,
  lodging_alone_moderate: 0,
  total_expenses_per_day_moderate: 0,
  total_expenses_per_day_not_splitting_room_moderate: 0,
  //Pricier
  public_transportation_pricier: 0,
  sights_and_entertainment_pricier: 0,
  meals_pricier: 0,
  lodging_couple_pricier: 0,
  lodging_alone_pricier: 0,
  total_expenses_per_day_pricier: 0,
  total_expenses_per_day_not_splitting_room_pricier: 0,
  //Splurge
  public_transportation_splurge: 0,
  sights_and_entertainment_splurge: 0,
  meals_splurge: 0,
  lodging_couple_splurge: 0,
  lodging_alone_splurge: 0,
  total_expenses_per_day_splurge: 0,
  total_expenses_per_day_not_splitting_room_splurge: 0,
};

const DEFAULT_ACCOMMODATION_PRICING = {
  backpacker_accommodations_less_than: 0,
  backpacker_accommodations_less_than_usd: 0,
  budget_accommodations_minimum: 0,
  budget_accommodations_minimum_usd: 0,
  budget_accommodations_maximum: 0,
  budget_accommodations_maximum_usd: 0,
  moderate_accommodations_minimum: 0,
  moderate_accommodations_minimum_usd: 0,
  moderate_accommodations_maximum: 0,
  moderate_accommodations_maximum_usd: 0,
  pricier_accommodations_minimum: 0,
  pricier_accommodations_minimum_usd: 0,
  pricier_accommodations_maximum: 0,
  pricier_accommodations_maximum_usd: 0,
  splurge_accommodations_most_rooms_more_than: 0,
  splurge_accommodations_most_rooms_more_than_usd: 0,
};

const DEFAULT_CLIMATE = {
  city: '',
  region: '',
  average_high_january: 0,
  average_low_january: 0,
  daily_chance_of_precipitation_january: 0,
  average_dew_point_january: 0,
  average_sunshine_hours_january: 0,
  average_high_february: 0,
  average_low_february: 0,
  daily_chance_of_precipitation_february: 0,
  average_dew_point_february: 0,
  average_sunshine_hours_february: 0,
  average_high_march: 0,
  average_low_march: 0,
  daily_chance_of_precipitation_march: 0,
  average_dew_point_march: 0,
  average_sunshine_hours_march: 0,
  average_high_april: 0,
  average_low_april: 0,
  daily_chance_of_precipitation_april: 0,
  average_dew_point_april: 0,
  average_sunshine_hours_april: 0,
  average_high_may: 0,
  average_low_may: 0,
  daily_chance_of_precipitation_may: 0,
  average_dew_point_may: 0,
  average_sunshine_hours_may: 0,
  average_high_june: 0,
  average_low_june: 0,
  daily_chance_of_precipitation_june: 0,
  average_dew_point_june: 0,
  average_sunshine_hours_june: 0,
  average_high_july: 0,
  average_low_july: 0,
  daily_chance_of_precipitation_july: 0,
  average_dew_point_july: 0,
  average_sunshine_hours_july: 0,
  average_high_august: 0,
  average_low_august: 0,
  daily_chance_of_precipitation_august: 0,
  average_dew_point_august: 0,
  average_sunshine_hours_august: 0,
  average_high_september: 0,
  average_low_september: 0,
  daily_chance_of_precipitation_september: 0,
  average_dew_point_september: 0,
  average_sunshine_hours_september: 0,
  average_high_october: 0,
  average_low_october: 0,
  daily_chance_of_precipitation_october: 0,
  average_dew_point_october: 0,
  average_sunshine_hours_october: 0,
  average_high_november: 0,
  average_low_november: 0,
  daily_chance_of_precipitation_november: 0,
  average_dew_point_november: 0,
  average_sunshine_hours_november: 0,
  average_high_december: 0,
  average_low_december: 0,
  daily_chance_of_precipitation_december: 0,
  average_dew_point_december: 0,
  average_sunshine_hours_december: 0,
  best_time_to_visit_for_weather_start_date: 'Early January',
  best_time_to_visit_for_weather_end_date: 'Early January',
  best_time_to_visit_for_weather_start_date_2: 'Early January',
  best_time_to_visit_for_weather_end_date_2: 'Early January',
};

const DEFAULT_LIFECYCLE = {
  type: 'Country',
  score: '',
  off_the_beaten_path: 'True',
  up_and_coming: 'True',
  trustworthy_yet_authentic: 'True',
  typical_spot: 'True',
  safe_and_predictable: 'True',
  overall_ratings: '',
};

const DEFAULT_CUISINE_SPECIALITY = {
  type: 'Country',
  high_quality_cuisine: 'True',
  beer: 'True',
  cheese_and_dairy: 'True',
  coffee_and_tea: 'True',
  desserts: 'True',
  fruit_and_vegetables: 'True',
  health_food: 'True',
  liquor: 'True',
  pasta_and_noodles: 'True',
  red_meat: 'True',
  seafood: 'True',
  street_food: 'True',
  vegan_dining: 'True',
  vegetarian_dining: 'True',
  wine: 'True',
};

export const appState = {
  countries: [],
  country: {},
  climates: [],
  visit_suggestion: DEFAULT_VISIT_SUGGESTION,
  daily_expenses: DEFAULT_AVERAGE_DAILY_EXPENSES,
  accommodation_pricing: DEFAULT_ACCOMMODATION_PRICING,
  climate: DEFAULT_CLIMATE,
  loading: false,
  loadingData: false,
  lifecycles: [],
  lifecycle: DEFAULT_LIFECYCLE,
  lifecycleCount: 0,
  lifecycleCurrentPage: 0,
  loadingLifecycle: false,
  showLifecycleForm: false,
  cuisineSpecialities: [],
  cuisineSpeciality: DEFAULT_CUISINE_SPECIALITY,
  cuisineSpecialityCount: 0,
  cuisineSpecialityCurrentPage: 0,
  loadingCuisineSpeciality: false,
  showCuisineSpecialityForm: false,
  countriesForSelect: [],
};

export default {
  suggestionReducer: handleActions(
    {
      //Countries
      [ActionTypes.GET_COUNTRIES_REQUEST](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_COUNTRIES_SUCCESS](state, action) {
        const { countries, countriesCount } = action;
        return {
          ...state,
          countries,
          countriesCount,
          loading: false,
        };
      },
      [ActionTypes.GET_COUNTRIES_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_COUNTRY_REQUEST](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_COUNTRY_SUCCESS](state, action) {
        const { country } = action;
        return {
          ...state,
          country,
          loading: false,
          visit_suggestion: DEFAULT_VISIT_SUGGESTION,
          daily_expenses: DEFAULT_AVERAGE_DAILY_EXPENSES,
        };
      },
      [ActionTypes.GET_COUNTRY_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      //Visit Suggestions
      [ActionTypes.GET_VISIT_REQUEST](state) {
        return {
          ...state,
          loadingData: true,
        };
      },
      [ActionTypes.GET_VISIT_SUCCESS](state, action) {
        const { visit_suggestion } = action;
        return {
          ...state,
          visit_suggestion,
          loadingData: false,
        };
      },
      [ActionTypes.GET_VISIT_FAILURE](state) {
        return {
          ...state,
          loadingData: false,
        };
      },
      [ActionTypes.SAVE_VISIT_REQUEST](state) {
        return {
          ...state,
          loadingData: true,
        };
      },
      [ActionTypes.SAVE_VISIT_SUCCESS](state, action) {
        const { visit_suggestion } = action;
        return {
          ...state,
          visit_suggestion,
          loadingData: false,
          country: { ...state.country, visit_suggestion_id: visit_suggestion.id },
        };
      },
      [ActionTypes.SAVE_VISIT_FAILURE](state, action) {
        const { visit_suggestion } = action;
        return {
          ...state,
          loadingData: false,
          visit_suggestion,
        };
      },
      //AverageDailyExpenses
      [ActionTypes.GET_AVERAGE_DAILY_EXPENSES_REQUEST](state) {
        return {
          ...state,
          loadingData: true,
        };
      },
      [ActionTypes.GET_AVERAGE_DAILY_EXPENSES_SUCCESS](state, action) {
        const { daily_expenses } = action;
        return {
          ...state,
          daily_expenses,
          loadingData: false,
        };
      },
      [ActionTypes.GET_AVERAGE_DAILY_EXPENSES_FAILURE](state) {
        return {
          ...state,
          loadingData: false,
        };
      },
      [ActionTypes.SAVE_AVERAGE_DAILY_EXPENSES_REQUEST](state) {
        return {
          ...state,
          loadingData: true,
        };
      },
      [ActionTypes.SAVE_AVERAGE_DAILY_EXPENSES_SUCCESS](state, action) {
        const { daily_expenses } = action;
        return {
          ...state,
          loadingData: false,
          daily_expenses,
          country: { ...state.country, daily_expenses_id: daily_expenses.id },
        };
      },
      [ActionTypes.SAVE_AVERAGE_DAILY_EXPENSES_FAILURE](state) {
        const { daily_expenses } = action;
        return {
          ...state,
          loadingData: false,
          daily_expenses,
        };
      },
      //Accommodation Pricing
      [ActionTypes.GET_ACCOMMODATION_PRICING_REQUEST](state) {
        return {
          ...state,
          loadingData: true,
        };
      },
      [ActionTypes.GET_ACCOMMODATION_PRICING_SUCCESS](state, action) {
        const { accommodation_pricing } = action;
        return {
          ...state,
          accommodation_pricing,
          loadingData: false,
        };
      },
      [ActionTypes.GET_ACCOMMODATION_PRICING_FAILURE](state) {
        return {
          ...state,
          loadingData: false,
        };
      },
      [ActionTypes.SAVE_ACCOMMODATION_PRICING_REQUEST](state) {
        return {
          ...state,
          loadingData: true,
        };
      },
      [ActionTypes.SAVE_ACCOMMODATION_PRICING_SUCCESS](state, action) {
        const { accommodation_pricing } = action;
        return {
          ...state,
          loadingData: false,
          accommodation_pricing,
          country: { ...state.country, accommodation_id: accommodation_pricing.id },
        };
      },
      [ActionTypes.SAVE_ACCOMMODATION_PRICING_FAILURE](state) {
        const { accommodation_pricing } = action;
        return {
          ...state,
          loadingData: false,
          accommodation_pricing,
        };
      },
      //Climate
      [ActionTypes.GET_CLIMATE_REQUEST](state) {
        return {
          ...state,
          loadingData: true,
        };
      },
      [ActionTypes.GET_CLIMATE_SUCCESS](state, action) {
        const { climates, climatesCount } = action;
        return {
          ...state,
          climates: climates,
          climatesCount: climatesCount,
          loadingData: false,
        };
      },
      [ActionTypes.GET_CLIMATE_FAILURE](state) {
        return {
          ...state,
          loadingData: false,
        };
      },

      [ActionTypes.SAVE_CLIMATE_REQUEST](state) {
        return {
          ...state,
          loadingData: true,
        };
      },
      [ActionTypes.SAVE_CLIMATE_SUCCESS](state) {
        return {
          ...state,
          loadingData: false,
        };
      },
      [ActionTypes.SAVE_CLIMATE_FAILURE](state) {
        return {
          ...state,
          loadingData: false,
          climate: DEFAULT_CLIMATE,
        };
      },
      [ActionTypes.UPDATE_CLIMATE](state, action) {
        const { climate } = action;
        return {
          ...state,
          climates: (state.climates || []).map(city => {
            if (city.id == climate.id) {
              return climate;
            }
            return city;
          }),
          climate: DEFAULT_CLIMATE,
          loadingData: false,
        };
      },
      [ActionTypes.SET_CLIMATE](state, action) {
        const { climate } = action;
        return {
          ...state,
          climate: climate || DEFAULT_CLIMATE,
          loadingData: false,
        };
      },
      [ActionTypes.REMOVE_CLIMATE](state, action) {
        return {
          ...state,
        };
      },
      [ActionTypes.REMOVE_CLIMATE_SUCCESS](state, action) {
        const { id } = action;
        return {
          ...state,
          climates: state.climates.filter(ev => ev.id != id),
          loadingData: false,
        };
      },

      [ActionTypes.GET_LIFECYCLES_REQUEST](state, action) {
        return {
          ...state,
          lifecycles: [],
          lifecycleCount: 0,
          lifecycleCurrentPage: 0,
          lifecycle: DEFAULT_LIFECYCLE,
          loadingLifecycle: true,
        };
      },
      [ActionTypes.GET_LIFECYCLES_SUCCESS](state, action) {
        const {
          payload: { rows, count, currentPage },
        } = action;

        return {
          ...state,
          lifecycles: rows,
          lifecycleCount: count,
          lifecycleCurrentPage: currentPage,
          loadingLifecycle: false,
        };
      },
      [ActionTypes.GET_LIFECYCLES_FAILURE](state) {
        return {
          ...state,
          loadingLifecycle: false,
        };
      },
      [ActionTypes.GET_LIFECYCLE_REQUEST](state, action) {
        return {
          ...state,
          lifecycle: DEFAULT_LIFECYCLE,
          loadingLifecycle: true,
        };
      },
      [ActionTypes.GET_LIFECYCLE_SUCCESS](state, { payload }) {
        return {
          ...state,
          lifecycle: payload,
          loadingLifecycle: false,
        };
      },
      [ActionTypes.GET_LIFECYCLE_FAILURE](state) {
        return {
          ...state,
          loadingLifecycle: false,
        };
      },
      [ActionTypes.REMOVE_LIFECYCLE_REQUEST](state, action) {
        return {
          ...state,
          loadingLifecycle: true,
        };
      },
      [ActionTypes.REMOVE_LIFECYCLE_SUCCESS](state, action) {
        const { id } = action;
        return {
          ...state,
          lifecycles: state.lifecycles.filter(li => li.id != id),
          loadingLifecycle: false,
        };
      },
      [ActionTypes.REMOVE_LIFECYCLE_FAILURE](state) {
        return {
          ...state,
          loadingLifecycle: false,
        };
      },
      [ActionTypes.SAVE_LIFECYCLE_REQUEST](state) {
        return {
          ...state,
          loadingLifecycle: true,
        };
      },
      [ActionTypes.SAVE_LIFECYCLE_SUCCESS](state) {
        return {
          ...state,
          loadingLifecycle: false,
          lifecycle: DEFAULT_LIFECYCLE,
        };
      },
      [ActionTypes.SAVE_LIFECYCLE_FAILURE](state) {
        return {
          ...state,
          loadingLifecycle: false,
        };
      },
      [ActionTypes.SHOW_LIFECYCLE_FORM](state, action) {
        const { showForm } = action;
        return {
          ...state,
          showLifecycleForm: showForm,
        };
      },

      [ActionTypes.GET_CUISINE_SPECIALITIES_REQUEST](state, action) {
        return {
          ...state,
          cuisineSpecialities: [],
          cuisineSpecialityCount: 0,
          cuisineSpecialityCurrentPage: 0,
          cuisineSpeciality: DEFAULT_CUISINE_SPECIALITY,
          loadingCuisineSpeciality: true,
        };
      },
      [ActionTypes.GET_CUISINE_SPECIALITIES_SUCCESS](state, action) {
        const {
          payload: { rows, count, currentPage },
        } = action;

        return {
          ...state,
          cuisineSpecialities: rows,
          cuisineSpecialityCount: count,
          cuisineSpecialityCurrentPage: currentPage,
          loadingCuisineSpeciality: false,
        };
      },
      [ActionTypes.GET_CUISINE_SPECIALITIES_FAILURE](state) {
        return {
          ...state,
          loadingCuisineSpeciality: false,
        };
      },
      [ActionTypes.GET_CUISINE_SPECIALITY_REQUEST](state, action) {
        return {
          ...state,
          cuisineSpeciality: DEFAULT_CUISINE_SPECIALITY,
          loadingCuisineSpeciality: true,
        };
      },
      [ActionTypes.GET_CUISINE_SPECIALITY_SUCCESS](state, { payload }) {
        return {
          ...state,
          cuisineSpeciality: payload,
          loadingCuisineSpeciality: false,
        };
      },
      [ActionTypes.GET_CUISINE_SPECIALITY_FAILURE](state) {
        return {
          ...state,
          loadingCuisineSpeciality: false,
        };
      },
      [ActionTypes.REMOVE_CUISINE_SPECIALITY_REQUEST](state, action) {
        return {
          ...state,
          loadingCuisineSpeciality: true,
        };
      },
      [ActionTypes.REMOVE_CUISINE_SPECIALITY_SUCCESS](state, action) {
        const { id } = action;
        return {
          ...state,
          cuisineSpecialitys: state.cuisineSpecialities.filter(li => li.id != id),
          loadingCuisineSpeciality: false,
        };
      },
      [ActionTypes.REMOVE_CUISINE_SPECIALITY_FAILURE](state) {
        return {
          ...state,
          loadingCuisineSpeciality: false,
        };
      },
      [ActionTypes.SAVE_CUISINE_SPECIALITY_REQUEST](state) {
        return {
          ...state,
          loadingCuisineSpeciality: true,
        };
      },
      [ActionTypes.SAVE_CUISINE_SPECIALITY_SUCCESS](state) {
        return {
          ...state,
          loadingCuisineSpeciality: false,
          cuisineSpeciality: DEFAULT_CUISINE_SPECIALITY,
        };
      },
      [ActionTypes.SAVE_CUISINE_SPECIALITY_FAILURE](state) {
        return {
          ...state,
          loadingCuisineSpeciality: false,
        };
      },
      [ActionTypes.SHOW_CUISINE_SPECIALITY_FORM](state, action) {
        const { showForm } = action;
        return {
          ...state,
          showCuisineSpecialityForm: showForm,
        };
      },

      [ActionTypes.GET_COUNTRIES_FOR_SELECT](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_COUNTRIES_FOR_SELECT_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          countriesForSelect: action.countriesForSelect,
        };
      },
      [ActionTypes.GET_COUNTRIES_FOR_SELECT_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
    },
    appState,
  ),
};
