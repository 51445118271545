import { ActionTypes } from 'constants/index';

export function getRequestsBoutiquePackages(params) {
  return {
    type: ActionTypes.GET_REQUESTS_BOUTIQUE_PACKAGES,
    params,
  };
}

export function getRequestsBoutiquePackagesSuccess(payload) {
  return {
    type: ActionTypes.GET_REQUESTS_BOUTIQUE_PACKAGES_SUCCESS,
    payload,
  };
}

export function getRequestsBoutiquePackagesFailure() {
  return {
    type: ActionTypes.GET_REQUESTS_BOUTIQUE_PACKAGES_FAILURE,
  };
}

export function getRequestBoutiquePackage(id) {
  return {
    type: ActionTypes.GET_REQUEST_BOUTIQUE_PACKAGE,
    id,
  };
}

export function getRequestBoutiquePackageSuccess(payload) {
  return {
    type: ActionTypes.GET_REQUEST_BOUTIQUE_PACKAGE_SUCCESS,
    payload,
  };
}

export function getRequestBoutiquePackageFailure() {
  return {
    type: ActionTypes.GET_REQUEST_BOUTIQUE_PACKAGE_FAILURE,
  };
}

export function getMyRequestBoutiquePackage() {
  return {
    type: ActionTypes.GET_MY_REQUEST_BOUTIQUE_PACKAGE,
  };
}

export function getMyRequestBoutiquePackageSuccess(payload) {
  return {
    type: ActionTypes.GET_MY_REQUEST_BOUTIQUE_PACKAGE_SUCCESS,
    payload,
  };
}

export function getMyRequestBoutiquePackageFailure() {
  return {
    type: ActionTypes.GET_MY_REQUEST_BOUTIQUE_PACKAGE_FAILURE,
  };
}

export function saveRequestBoutiquePackage(payload) {
  return {
    type: ActionTypes.SAVE_PACKAGE_BOUTIQUE,
    payload,
  };
}

export function saveRequestBoutiqueSuccess() {
  return {
    type: ActionTypes.SAVE_PACKAGE_BOUTIQUE_SUCCESS,
  };
}

export function saveRequestBoutiqueFailure() {
  return {
    type: ActionTypes.SAVE_PACKAGE_BOUTIQUE_FAILURE,
  };
}

export function updateRequestBoutiquePackage(id, payload) {
  return {
    type: ActionTypes.UPDATE_PACKAGE_BOUTIQUE,
    id,
    payload,
  };
}

export function updateRequestBoutiqueSuccess() {
  return {
    type: ActionTypes.UPDATE_PACKAGE_BOUTIQUE_SUCCESS,
  };
}

export function updateRequestBoutiqueFailure() {
  return {
    type: ActionTypes.UPDATE_PACKAGE_BOUTIQUE_FAILURE,
  };
}

export function customerApprobeBoutiquePackageRequest() {
  return {
    type: ActionTypes.CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_REQUEST,
  };
}

export function customerApprobeBoutiquePackageSuccess() {
  return {
    type: ActionTypes.CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_SUCCESS,
  };
}

export function customerApprobeBoutiquePackageFailure() {
  return {
    type: ActionTypes.CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_FAILURE,
  };
}
