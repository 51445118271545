import React from 'react';
import styled from 'styled-components';
import Master from '../../../assets/media/icons/master.png';
import Visa from '../../../assets/media/icons/visa.png';
import Cirrus from '../../../assets/media/icons/cirrus.png';
import Maestro from '../../../assets/media/icons/maestro.png';
import Americanexpress from '../../../assets/media/icons/americanexpress.png';

const Item = styled.div`
  display: flex;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const Icon = styled.img`
  width: auto;
  max-width: 58px;
  height: auto;
  max-height: 46px;
  margin: 20px;
`;
const Ending = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Actions = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;
  margin-right: 15px;
  margin-left: 15px;
  align-items: center;
  @media (max-width: 650px) {
    width: 100%;
    justify-content: center;
    margin: inherit;
  }
`;

const CardInfo = styled.div`
  display: flex;
  width: 80%;
  @media (max-width: 650px) {
    width: 100%;
  }
`;

const PrimaryText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18.5px;
  text-align: left;
  color: #999999;
  margin-left: 30px;

}
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 175px;
  display: inline-block;
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 300;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.01);
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  float: right;
  margin-right: 15px;
  margin-left: 25px;
  cursor: pointer;
  &: hover {
    background: #00b4d52b;
    opacity: 0.4;
  }
  background: 'white';
  color: rgb(15, 203, 255);
  border-color: rgb(15, 203, 255);
  border: 2px solid;
`;

const chooseImage = brand => {
  switch (brand) {
    case 'Master':
      return Master;
    case 'Visa':
      return Visa;
    case 'Cirrus':
      return Cirrus;
    case 'Maestro':
      return Maestro;

    case 'American Express':
      return Americanexpress;
    default:
      return Master;
  }
};

export default props => {
  const { method, deletePaymentMethod, isAditional, setAsDefault } = props;

  return (
    <Item>
      <CardInfo>
        <Icon src={chooseImage(method.brand)} />
        <Ending>
          <PrimaryText> {`${method.brand} ending in ${method.last4}`}</PrimaryText>
        </Ending>
      </CardInfo>

      <Actions>
        {isAditional && (
          <Button onClick={() => setAsDefault({ default_source: method.id })}>
            Set as default
          </Button>
        )}
        <i
          onClick={() => deletePaymentMethod({ card_id: method.id })}
          className="far fa-trash-alt"
          style={{ color: 'red', width: '35px' }}
        />
      </Actions>
    </Item>
  );
};
