import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  restaurants: [],
  loading: false,
  restaurantsCount: 0,
  restaurant: null,
};

export default {
  restaurantReducer: handleActions(
    {
      [ActionTypes.GET_RESTAURANTS](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_RESTAURANTS_SUCCESS](state, action) {
        return {
          ...state,
          restaurants: action.restaurants,
          restaurantsCount: action.restaurantsCount,
          loading: false,
        };
      },
      [ActionTypes.GET_RESTAURANTS_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_RESTAURANT](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_RESTAURANT_SUCCESS](state, action) {
        return {
          ...state,
          restaurant: action.restaurant,
          loading: false,
        };
      },
      [ActionTypes.GET_RESTAURANT_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.REMOVE_RESTAURANT](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.REMOVE_RESTAURANT_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.REMOVE_RESTAURANT_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.UPDATE_RESTAURANT](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.UPDATE_RESTAURANT_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.UPDATE_RESTAURANT_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.SAVE_RESTAURANT](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.SAVE_RESTAURANT_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.SAVE_RESTAURANT_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
    },
    appState,
  ),
};
