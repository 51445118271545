import React, { Component } from 'react';
import StepContainer from '../../StepContainer';
import StepButtons from '../../StepButtons';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import styled from 'styled-components';
import './styles.css';
import CardList from '../../CardsList';
import Loader from 'components/Loader';

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
        height: '44px',
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

const PayContainer = styled.div`
  display: flex;
  padding: 22px;
  justify-content: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const CardConteiner = styled.div`
  width: 50%;
  margin-right: 20px;
  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0px;
  }
`;
const GrayText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 15.5px;
  text-align: left;
  color: #c0c0c0;
`;

const CardInfoSecondary = styled.div`
  display: flex;
`;
const CcvElement = styled.div`
  width: 50%;
  margin-left: 10px;
`;
const ExpElement = styled.div`
  width: 50%;
  margin-right: 10px;
`;
const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class Step3 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { changeStep, loading, step } = this.props;

    return (
      <>
        <StepContainer title={'Pay'}>
          <PayContainer>
            <CardConteiner>
              <CardsContainer>
                <GrayText>Acepted Cards</GrayText>
                <CardList />
              </CardsContainer>
              <CardNumberElement
                placeholder="Card Number ex: 1234 5678 9012 3546"
                {...createOptions()}
              />
              <CardInfoSecondary>
                <ExpElement>
                  <CardExpiryElement placeholder="Exp" {...createOptions()} />
                </ExpElement>
                <CcvElement>
                  <CardCvcElement placeholder="CCV" {...createOptions()} />
                </CcvElement>
              </CardInfoSecondary>
            </CardConteiner>
          </PayContainer>
        </StepContainer>
        <StepButtons
          backStep={() => changeStep(step - 1)}
          nextStep={() => changeStep(step + 1)}
          customNextText={loading ? <Loader color="white" /> : 'Finish'}
          nextDisabled={loading}
          backDisabled={loading}
        />
      </>
    );
  }
}

export default Step3;
