import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import defaultImage from 'assets/media/images/default.png';
import ListEmpty from 'components/ListEmpty';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
  margin-top: 30px;
`;

const TripContainer = styled.div`
  position: relative;
  width: 21%;
  min-height: 330px;
  padding: 20px;
  margin: 2%;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background: #fff;
`;

const ImageContainer = styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ImageHover = styled.a`
  position: absolute;
  transition: 0.5s ease;
  cursor: pointer;
  opacity: 0;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  top: 49%;
  left: 50%;
  text-align: center;
  width: -webkit-fill-available;
  height: 200px;
  background-color: rgba(89, 199, 255, 0.4);
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  &:hover {
    opacity: 1;
  }
`;

const ViewMoreButton = styled.div`
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 300;
  letter-spacing: 1px;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1rem 1.3rem;
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  border-color: #ffffff;
  border: 1px solid;
  margin: 80px 50px;
`;

const CreateTripContainer = styled.a`
  &:hover {
    opacity: 0.5;
  }
`;

const AddTripText = styled.p`
  color: #3e3e3e;
  font-size: 20px;
  margin: 50px 0px 30px 0px;
`;

const Primary = styled.p`
  color: #3e3e3e;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: left;
  margin-left: 25px;
`;

const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 10px;
  text-align: left;
  margin-left: 40px;
`;

const Image = styled.img`
  width: auto;
  height: 200px;
`;

const ListEmptyContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

class TripList extends Component {
  createTrip = () => {
    const { goTo, customerId } = this.props;
    const path = customerId ? `/trip/create/${customerId}` : '/trips/create';
    goTo(path);
  };

  viewTrip = id => {
    const { goTo, customerId } = this.props;
    if (customerId) {
      goTo(`/trips/${id}`);
    } else {
      goTo(`/customer-trip/${id}`);
    }
  };

  renderTrip({ id, title, start_date, image, end_date }, key) {
    return (
      <TripContainer key={key}>
        <ImageContainer>
          <Image src={image || defaultImage} />
          <ImageHover onClick={() => this.viewTrip(id)}>
            <ViewMoreButton>View More</ViewMoreButton>
          </ImageHover>
        </ImageContainer>
        <Primary>
          <i className="fas fa-map-marker-alt" style={{ color: '#38b6ff' }} />
          {`  ${title}`}
        </Primary>
        <Secondary>{`Departure:   ${
          start_date ? moment(start_date).format('MM-DD-YYYY') : 'Undefined'
        }`}</Secondary>
        <Secondary>{`Return:      ${
          end_date ? moment(end_date).format('MM-DD-YYYY') : 'Undefined'
        }`}</Secondary>
      </TripContainer>
    );
  }

  render() {
    const { trips, isAdmin } = this.props;
    return (
      <Container>
        {trips.map((trip, key) => {
          return this.renderTrip(trip, key);
        })}
        {isAdmin && (
          <TripContainer>
            <CreateTripContainer onClick={this.createTrip}>
              <AddTripText>ADD TRIP</AddTripText>
              <i className="fas fa-plus" style={{ color: '#9e9e9e', fontSize: '60px' }} />
            </CreateTripContainer>
          </TripContainer>
        )}
        {!isAdmin && trips.length == 0 && (
          <ListEmptyContainer>
            <ListEmpty message="There are currently no trips." />
          </ListEmptyContainer>
        )}
      </Container>
    );
  }
}

export default TripList;
