import React from 'react';
import styled from 'styled-components';

const CheckBox = styled.input`
  width: 20px;
  height: 21px;
  cursor: pointer;
`;

const Label = styled.p`
  color: #9e9e9e;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
`;

export default props => (
  <CheckboxContainer style={{ ...props.style }}>
    {props.labelInverted && <Label>{props.label}</Label>}
    <CheckBox
      type="checkbox"
      checked={props.isChecked}
      name={props.name}
      onChange={props.toggleChange}
    />
    {!props.labelInverted && <Label>{props.label}</Label>}{' '}
  </CheckboxContainer>
);
