import { ActionTypes } from 'constants/index';

export function getPaymentMethods() {
  return {
    type: ActionTypes.GET_PAYMENT_METHODS,
  };
}

export function savePaymentMethods(data) {
  return {
    type: ActionTypes.SAVE_PAYMENT_METHODS,
    data,
  };
}

export function createPaymentMethod(data) {
  return {
    type: ActionTypes.CREATE_PAYMENT_METHODS,
    data,
  };
}

export function deletePaymentMethod(data) {
  return {
    type: ActionTypes.DELETE_PAYMENT_METHOD,
    data,
  };
}

export function setDefaultMethod(data) {
  return {
    type: ActionTypes.SET_DEFAULT_METHOD,
    data,
  };
}
