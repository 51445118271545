import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import { FOODDRINK } from 'constants/index';
import RadioInput from 'components/RadioInput';

const SelectFoodDrinkBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SelectFoodDrinkPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`;

class SelectFoodDrinkView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { step, state, changeStep, handleChange, foodAndDrinkMaping } = this.props;

    return (
      <SelectFoodDrinkBox>
        <SelectFoodDrinkPrimaryBox>
          <PrimaryText text="What types of food and drink interest you? Please select all that apply and rate your interest level low, medium, or high" />
          <Content>
            {FOODDRINK.map(enrteainment => (
              <RadioInput
                style={{ marginBottom: 50 }}
                label={enrteainment}
                name={foodAndDrinkMaping[enrteainment]}
                options={[
                  { numericValue: 0, text: 'Nothing' },
                  { numericValue: 1, text: 'Low' },
                  { numericValue: 2, text: 'Medium' },
                  { numericValue: 3, text: 'High' },
                ]}
                handleChange={handleChange}
                currentValue={state[foodAndDrinkMaping[enrteainment]]}
                verticalAlign
              />
            ))}
          </Content>
          <StepButtons
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(step + 1)}
          />
        </SelectFoodDrinkPrimaryBox>
      </SelectFoodDrinkBox>
    );
  }
}

export default SelectFoodDrinkView;
