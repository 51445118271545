import React, { Component } from 'react';
import WelcomeView from './WelcomeView';

class Welcome extends Component {
  render() {
    return <WelcomeView />;
  }
}

export default Welcome;
