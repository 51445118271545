/*
 * Reducer actions related with customers
 */
import { ActionTypes } from 'constants/index';

export function getAllRegions(page, elementsPerPage, country_id) {
  return {
    type: ActionTypes.GET_ALL_REGIONS,
    page,
    elementsPerPage,
    country_id,
  };
}

export function getAllRegionsSuccess(payload) {
  return {
    type: ActionTypes.GET_ALL_REGIONS_SUCCESS,
    regions: payload.rows,
    regionsCount: payload.count,
  };
}

export function getAllRegionsFailure() {
  return {
    type: ActionTypes.GET_ALL_REGIONS_FAILURE,
  };
}

export function createRegions(region) {
  return {
    type: ActionTypes.CREATE_REGIONS,
    region,
  };
}

export function createRegionsSuccess() {
  return {
    type: ActionTypes.CREATE_REGIONS_SUCCESS,
  };
}

export function createRegionsFailure() {
  return {
    type: ActionTypes.CREATE_REGIONS_FAILURE,
  };
}

export function getRegions(id) {
  return {
    type: ActionTypes.GET_REGIONS,
    id,
  };
}

export function getRegionsSuccess(region) {
  return {
    type: ActionTypes.GET_REGIONS_SUCCESS,
    region,
  };
}

export function getRegionsFailure() {
  return {
    type: ActionTypes.GET_REGIONS_FAILURE,
  };
}

export function editRegions(id, region) {
  return {
    type: ActionTypes.EDIT_REGIONS,
    id,
    region,
  };
}

export function editRegionsSuccess() {
  return {
    type: ActionTypes.EDIT_REGIONS_SUCCESS,
  };
}

export function editRegionsFailure() {
  return {
    type: ActionTypes.EDIT_REGIONS_FAILURE,
  };
}

export function getRegionsForSelect(params) {
  return {
    type: ActionTypes.GET_REGIONS_FOR_SELECT,
    params,
  };
}

export function getRegionsForSelectSuccess(regionsForSelect) {
  return {
    type: ActionTypes.GET_REGIONS_FOR_SELECT_SUCCESS,
    regionsForSelect,
  };
}

export function getRegionsForSelectFailure() {
  return {
    type: ActionTypes.GET_REGIONS_FOR_SELECT_FAILURE,
  };
}

export function removeRegions(id) {
  return {
    type: ActionTypes.REMOVE_REGIONS,
    id,
  };
}

export function removeRegionsSuccess(id) {
  return {
    type: ActionTypes.REMOVE_REGIONS_SUCCESS,
    id,
  };
}

export function removeRegionsFailure() {
  return {
    type: ActionTypes.REMOVE_REGIONS_FAILURE,
  };
}
