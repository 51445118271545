import { ActionTypes } from 'constants/index';

export function getMeetings(page, elementsPerPage) {
  return {
    type: ActionTypes.GET_CUSTOMER_MEETINGS,
    page,
    elementsPerPage,
  };
}

export function getMeetingRequest(id) {
  return {
    type: ActionTypes.GET_MEETING_REQUEST,
    id,
  };
}

export function saveMeMeetings(payload) {
  return {
    type: ActionTypes.SAVE_ME_MEETINGS,
    meetings: payload.rows,
    meetingsCount: payload.count,
  };
}

export function saveMeMeetingSuccess(data) {
  return {
    type: ActionTypes.GET_MEETING_SUCCESS,
    data,
  };
}

export function saveMeetingSuccess(data) {
  return {
    type: ActionTypes.SAVE_MEETING_SUCCESS,
    data,
  };
}

export function getInitialMeetingRequest() {
  return {
    type: ActionTypes.GET_INITIAL_MEETING_REQUEST,
  };
}
