import React, { Component } from 'react';
import StepContainer from '../../StepContainer';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import RadioInput from '../../../components/RadioInput';
import ProgressBarSteps from 'components/ProgressBarSteps';

const StrengthsTestContainer = styled.div`
  margin: 25px 25%;
  @media (max-width: 1000px) {
    margin-left: 15%;
    margin-right: 15%;
  }
`;

const ProgresBarContainer = styled.div`
  width: 14%;
  position: absolute;
  bottom: 40px;
  right: 100px;
  @media (max-width: 875px) {
    display: none;
  }
`;

const CurrentStepIndicator = styled.p`
  color: #9e9e9e;
  font-size: 14px;
  text-align: center;
`;

class StrengthsTestView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { changeStep, handleChange, data, stepFields } = this.props;
    const { localStep } = data;

    return (
      <>
        <StepContainer title={stepFields.title} subtitle={stepFields.subtitle}>
          <StrengthsTestContainer>
            <RadioInput
              name={stepFields.name}
              label={stepFields.label}
              options={stepFields.options}
              handleChange={handleChange}
              currentValue={data[stepFields.name]}
              reverseScored={stepFields.reverseScored}
              verticalAlign
            />
          </StrengthsTestContainer>
          <ProgresBarContainer>
            <ProgressBarSteps percent={Math.round((localStep * 100) / 58)} />
          </ProgresBarContainer>
          <CurrentStepIndicator>Part 2 of 3</CurrentStepIndicator>

          <StepButtons
            backStep={() => changeStep(localStep - 1)}
            nextStep={() => changeStep(localStep + 1)}
          />
        </StepContainer>
      </>
    );
  }
}

export default StrengthsTestView;
