import { put, call } from 'redux-saga/effects';
import * as customerActions from '../actions/customerActions';

export function* getCustomersAsync(api, action) {
  //yield put(customerActions.enableLoader());
  const { page, elementsPerPage, search } = action;
  const response = yield call(api.getCustomers, { page, elementsPerPage, search });
  if (response.status == 200) {
    yield put(customerActions.saveCustomers(response.data));
  } else {
    //yield put(customerActions.customerFailed());
  }
}

export function* getFriendsAsync(api, action) {
  const { search } = action;
  const response = yield call(api.getFriends, { search });
  if (response.status == 200) {
    yield put(customerActions.getFriendsSuccess(response.data));
  } else {
    //yield put(customerActions.customerFailed());
  }
}

export function* getCustomerDetailsAsync(api, action) {
  //yield put(customerActions.enableLoader());
  const { id } = action;
  const response = yield call(api.getCustomer, id);

  if (response.status == 200) {
    yield put(customerActions.getCustomerSuccess(response.data));
  } else {
    //yield put(customerActions.customerFailed());
  }
}
