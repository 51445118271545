import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const TBody = styled.div``;

const ActionIcon = styled.i`
  color: ${props => props.color};
  font-size: 20px;
  padding-right: 10px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

class ActivityList extends Component {
  renderActivity({ id, name, category, address, duration, budget }, key) {
    const { onRemoveActivity, toEdit } = this.props;

    return (
      <Item key={key}>
        <SectionContainer width={'30%'}>
          <Primary>{name}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'30%'}>
          <Primary>{address}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'30%'}>
          <Primary>{`$${budget}`}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'10%'} textAlign="right">
          <ActionIcon
            color="red"
            className="fas fa-trash-alt"
            onClick={() => onRemoveActivity(id)}
          />
          <Link to={`${toEdit}/${id}`}>
            <ActionIcon color="#38b6ff" className="fas fa-edit" />
          </Link>
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const { activities, loading } = this.props;

    return (
      <>
        {loading ? (
          <LoaderContainer>
            <Loader type="pulse" size={125} />
          </LoaderContainer>
        ) : (
          <GridContainer>
            <THeader>
              <TitleContainer width={'30%'}>
                <Title>BUSINESS NAME</Title>
              </TitleContainer>
              <TitleContainer width={'30%'}>
                <Title>LOCATION</Title>
              </TitleContainer>
              <TitleContainer width={'30%'}>
                <Title>BUDGE</Title>
              </TitleContainer>
              <TitleContainer width={'10%'} />
            </THeader>
            <TBody>
              {activities.map((activity, key) => {
                return this.renderActivity(activity, key);
              })}
              {(!activities || !activities.length) && (
                <ListEmpty message="There are currently no activities" />
              )}
            </TBody>
          </GridContainer>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  null,
  mapDispatchToProps,
)(ActivityList);
