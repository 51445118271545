import React, { Component } from 'react';
import TheRoadView from './JournalTheRoadView';
import { connect } from 'react-redux';
import * as journalActions from 'actions/journalActions';
import { selectFields } from './StepFields/TheRoad';

class TheRoad extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      getRoadRequest,
      match: { params },
    } = this.props;
    getRoadRequest(params.id, params.day);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.road && this.props.road) ||
      (prevProps.road && prevProps.road != this.props.road)
    ) {
      const { message_morning, message_today, message_evening } = this.props.road;
      this.setState({ message_morning, message_today, message_evening });
    }
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  saveDay = () => {
    const {
      createDayRoadRequest,
      match: { params },
      day,
      road: { id },
    } = this.props;
    const { message_morning, message_today, message_evening } = this.state;

    createDayRoadRequest(id, {
      message_morning,
      message_today,
      message_evening,
      status: 'completed',
    });
  };

  render() {
    const { day, road, journal } = this.props;
    if (!road || !journal.journalRoads) return null;
    const { message_morning, message_today, message_evening } = this.state;
    const stepFields = selectFields(+day);
    const daysQuantity = journal.journalRoads.length;
    return (
      <TheRoadView
        {...this.props}
        saveDay={this.saveDay}
        stepFields={stepFields}
        currentDay={day}
        message_morning={message_morning}
        message_today={message_today}
        message_evening={message_evening}
        handleChange={this.handleChange}
        date={road.date}
        daysQuantity={daysQuantity}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    road: state.journalReducer.road,
    journal: state.journalReducer.journal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDayRoadRequest: (id, road) => dispatch(journalActions.createDayRoadRequest(id, road)),
    getRoadRequest: (journal_id, day) =>
      dispatch(journalActions.getRoadRequest({ journal_id, day })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TheRoad);
