import React, { Component } from 'react';
import CustomerDetailsView from './CustomerDetailsView';
import { connect } from 'react-redux';
import * as CustomersActions from '../../actions/customerActions';
import * as tripActions from '../../actions/tripActions';
import * as diagnosticActions from '../../actions/diagnosticActions';

class CustomerDetailsContainer extends Component {
  state = {
    activeTab: 'Trips',
  };

  componentDidMount() {
    const {
      fetchCustomer,
      match: { params },
      resetTripForm,
    } = this.props;
    resetTripForm();
    fetchCustomer(params.id);
  }

  changeTab = activeTab => {
    this.setState({
      activeTab,
    });
  };

  goTo = path => {
    const {
      history: { push },
    } = this.props;

    push(path);
  };

  updateMeeting = meeting => {
    const { updateMeeting } = this.props;
    updateMeeting(meeting);
  };
  render() {
    const { activeTab } = this.state;

    const tabs = [
      {
        action: () => this.changeTab('Trips'),
        title: 'Trips',
      },
      {
        action: () => this.changeTab('Payments'),
        title: 'Payments',
      },
      {
        action: () => this.changeTab('Subscriptions'),
        title: 'Subscriptions',
      },
      {
        action: () => this.changeTab('Diagnostic test'),
        title: 'Diagnostic test',
      },
      {
        action: () => this.changeTab('Discussions'),
        title: 'Discussions',
      },
      {
        action: () => this.changeTab('Courses'),
        title: 'Courses',
      },
    ];

    return (
      <CustomerDetailsView
        {...this.props}
        activeTab={activeTab}
        tabs={tabs}
        goTo={this.goTo}
        updateMeeting={this.updateMeeting}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.customerReducer.customer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchCustomer: id => dispatch(CustomersActions.getCustomer(id)),
    resetTripForm: () => dispatch(tripActions.resetTripForm()),
    updateMeeting: meeting => dispatch(diagnosticActions.updateMeeting(meeting)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerDetailsContainer);
