import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import TripListForCustomers from 'components/TripListForCustomers';
import * as tripActions from '../../actions/tripActions';

class CustomersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itinerariesModal: false,
      currentItineraries: [],
      types_filter: 'All',
    };
  }
  componentDidMount() {
    this.props.onGetMeTrips();
  }

  goTo = path => {
    const {
      history: { push },
    } = this.props;

    push(path);
  };

  toggleItinerariesModal = () => {
    this.setState({ itinerariesModal: !this.state.itinerariesModal });
  };

  selectCurrentItineraries = currentItineraries => {
    this.setState({ currentItineraries });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    let { trips } = this.props;

    const { itinerariesModal, currentItineraries, types_filter } = this.state;
    return (
      <TripListForCustomers
        isAdmin={false}
        trips={trips}
        goTo={this.goTo}
        toggleItinerariesModal={this.toggleItinerariesModal}
        itinerariesModal={itinerariesModal}
        currentItineraries={currentItineraries}
        selectCurrentItineraries={this.selectCurrentItineraries}
        handleChange={this.handleChange}
        types_filter={types_filter}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    trips: state.tripReducer.trips,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onGetMeTrips: () => dispatch(tripActions.getMeTrips()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomersContainer);
