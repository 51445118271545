import React, { Component } from 'react';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import ok from 'assets/media/icons/ok.png';
import pending from 'assets/media/icons/pending.png';
import remove from 'assets/media/icons/remove.png';
import draftInvoice from 'assets/media/icons/draftInvoice.png';
import openInvoice from 'assets/media/icons/openInvoice.png';
import errorInvoice from 'assets/media/icons/errorInvoice.png';
import moment from 'moment';
import Loader from 'components/Loader';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  height: 100%;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: ${props => props.alignItems};
  align-self: center;
  text-align: ${props => (props.left ? 'left' : 'center')};
  display: flex;
  flex-direction: ${props => props.flexDirecyion};
  padding-left: 15px;
  padding-right: 15px;
`;

const StatusImage = styled.img`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 19px;
`;

const Primary = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;

const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 15px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
`;
const TBody = styled.div``;

const SubscriptionDetailsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  padding: 0px 20px 0px 20px;
  margin: 20px;
`;

const SectionDetails = styled.div`
  position: relative;
  width: ${props => props.width}%;
  height: 100%;
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: left;
  ${props => props.hasPadding && 'padding: 25px 5px 25px 5px;'};
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const chooseInvoiceStatusImage = status => {
  switch (status) {
    case 'draft':
      return draftInvoice;
    case 'open':
      return openInvoice;
    case 'paid':
      return ok;
    case 'void':
      return errorInvoice;
    case 'uncollectible':
      return errorInvoice;
    default:
      return openInvoice;
  }
};

const chooseSubscriptionStatusImage = status => {
  switch (status) {
    case 'draft':
    case 'incomplete':
      return pending;
    case 'incomplete_expired':
      return remove;
    case 'trialing':
      return pending;
    case 'active':
      return ok;
    case 'past_due':
      return remove;
    case 'canceled':
      return remove;
    case 'unpaid':
      return remove;
    default:
      return openInvoice;
  }
};
class InvoiceList extends Component {
  renderInvoice({ status, amount, created_at, invoice_url }, key) {
    return (
      <Item key={key}>
        <SectionContainer flexDirecyion="column" alignItems="center" width={'25%'}>
          <Primary className="without-margin">
            <StatusImage size={40} src={chooseInvoiceStatusImage(status)} />
          </Primary>
          <Secondary className="without-margin">{status}</Secondary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer flexDirecyion="column" alignItems="center" width={'25%'}>
          <Secondary>${amount}</Secondary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer flexDirecyion="column" alignItems="center" width={'25%'}>
          <Secondary> {moment(created_at).format('MM-DD-YYYY hh:mm A')}</Secondary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer flexDirecyion="column" alignItems="center" width={'25%'}>
          <Secondary>
            {invoice_url ? (
              <a href={invoice_url} download>
                Download
              </a>
            ) : (
              'Invoice in process'
            )}
          </Secondary>
          <VerticalLine />
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const { invoices, subscription, loading } = this.props;
    if (!invoices) return null;
    return (
      <>
        {loading ? (
          <LoaderContainer>
            <Loader type="pulse" size={125} />
          </LoaderContainer>
        ) : (
          <>
            {subscription && subscription.product && (
              <SubscriptionDetailsContainer>
                <SectionDetails width={30} hasPadding>
                  <Secondary>Product</Secondary>
                  <Primary>{subscription.product.title}</Primary>
                </SectionDetails>
                <SectionDetails width={30} hasPadding>
                  <Secondary>Price</Secondary>
                  <Primary>${subscription.product.price}</Primary>
                </SectionDetails>
                <SectionDetails width={30} hasPadding>
                  <Secondary>Status</Secondary>
                  <Primary>{subscription.status}</Primary>
                </SectionDetails>
                <SectionDetails width={10} hasPadding>
                  <StatusImage size={75} src={chooseSubscriptionStatusImage(subscription.status)} />
                </SectionDetails>
              </SubscriptionDetailsContainer>
            )}
            <GridContainer>
              <THeader>
                <TitleContainer width={'25%'}>
                  <Title>INVOICE STATUS</Title>
                </TitleContainer>
                <TitleContainer width={'25%'}>
                  <Title>AMOUNT</Title>
                </TitleContainer>
                <TitleContainer width={'25%'}>
                  <Title>INVOICE DATE</Title>
                </TitleContainer>
                <TitleContainer width={'25%'}>
                  <Title>PDF</Title>
                </TitleContainer>
              </THeader>
              <TBody>
                {invoices.map((invoice, key) => this.renderInvoice(invoice))}
                {(!invoices || !invoices.length) && (
                  <ListEmpty message="There are currently no invoices" />
                )}
              </TBody>
            </GridContainer>
          </>
        )}
      </>
    );
  }
}

export default InvoiceList;
