import React, { Component } from 'react';
import styled from 'styled-components';
import Pagination from 'components/PaginationButtons';
import ListEmpty from 'components/ListEmpty';
import Button from 'components/Button';
import Loader from 'components/Loader';
import ToggleSwitch from 'components/ToggleSwitch';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Modal from 'components/Modal';
import ModalExcel from 'components/Suggestions/ModalExcel';

const Container = styled.div``;

const HeaderContainer = styled.div`
  margin: 20px;
  padding-bottom: 5px;
  position: relative;
  width: auto;
  position: relative;
  height: 50px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.widthSize &&
    `
    width: ${props.widthSize}%;
  `}
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 900px) {
    width: 100% !important;
  }
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  line-height: 0;
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

const Span = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  letter-spacing: normal;
  text-align: start;
  color: #999999;
  padding-top: 10px;
`;

const Select = styled.select`
  height: 52px;
  border-radius: 5px;
  background-color: #ffffff;
  border: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
`;

const ActionIcon = styled.i`
  color: ${props => props.color};
  font-size: 20px;
  padding-right: 10px;
`;

const GridContainer = styled.div`
  margin: 20px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TBody = styled.div``;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;
const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const InputActivity = styled.input`
  width: 100%;
  height: 50px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 5)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${props => props.justifyContent || 'space-between'};
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const LinkText = styled.p`
  padding-top: 5px;
  font-size: 18px;
  cursor: pointer;
  color: #38b6ff;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const elementsPerPage = 10;

class Lifecycle extends Component {
  state = {
    delayTyping: null,
    by: 'Country',
    isOpen: false,
    idSelected: null,
  };
  submit = values => {
    const {
      item: { id },
    } = this.props;
    const { save } = this.props;

    save(id, values);
  };

  renderForm = () => {
    const {
      item,
      switchFormShow,
      countryId,
      viewMode,
      regionsForSelect,
      citiesForSelect,
      getCitiesForSelect,
    } = this.props;
    const isEditing = item && item.id;

    return (
      <GridContainer>
        <FlexRow style={{ justifyContent: 'space-between', marginBottom: 20 }}>
          <Label>{`${
            isEditing ? 'Edit' : 'Add new'
          } point in Tourist Destination Lifecycle`}</Label>
          <Button onClick={() => switchFormShow(false)} style={{ marginTop: 0 }}>
            Back
          </Button>
        </FlexRow>
        <Formik
          // enableReinitialize
          initialValues={{ country_id: countryId, ...item }}
          onSubmit={this.submit}
          isInitialValid={isEditing}
          validationSchema={Yup.object().shape({
            type: Yup.string(),
            score: Yup.number().required('Required'),
            off_the_beaten_path: Yup.string().required('Required'),
            up_and_coming: Yup.string().required('Required'),
            trustworthy_yet_authentic: Yup.string().required('Required'),
            typical_spot: Yup.string().required('Required'),
            safe_and_predictable: Yup.string().required('Required'),
            overall_ratings: Yup.number().required('Required'),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
            isValid,
            handleSubmit,
          }) => {
            return (
              <GridContainer>
                <FlexRow justifyContent="flex-start">
                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Type</Label>
                    <Select
                      placeholder="Type"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      disabled={viewMode}
                    >
                      <option value={'Country'}>Country</option>
                      <option value={'Region'}>Region</option>
                      <option value={'City/Area'}>City/Area</option>
                    </Select>
                  </FlexColumn>

                  {values.type !== 'Country' && (
                    <FlexColumn widthSize={33} marginBottom={40}>
                      <Label color="#38b6ff">Region</Label>

                      <Select
                        name="region"
                        value={values.region}
                        onChange={e => {
                          handleChange(e);
                          getCitiesForSelect({ region_id: e.target.value });
                          setFieldValue('city', '');
                        }}
                        onBlur={handleBlur}
                        disabled={viewMode}
                      >
                        <option className="selectPlaceholder" value="" disabled selected hidden>
                          Select Region
                        </option>
                        {regionsForSelect.map(region => (
                          <option value={`${region.id}`}>{region.name}</option>
                        ))}
                      </Select>
                    </FlexColumn>
                  )}

                  {values.type !== 'Country' && values.type === 'City/Area' && (
                    <FlexColumn widthSize={33} marginBottom={40}>
                      <Label color="#38b6ff">City/Area</Label>
                      <Select
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.city && touched.city}
                        disabled={viewMode}
                      >
                        <option className="selectPlaceholder" value="" disabled selected hidden>
                          Select City
                        </option>
                        {citiesForSelect.map(city => (
                          <option value={`${city.id}`}>{city.name}</option>
                        ))}
                      </Select>
                    </FlexColumn>
                  )}
                </FlexRow>

                <FlexRow>
                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Score</Label>
                    <InputActivity
                      type="number"
                      placeholder="Score"
                      name="score"
                      value={values.score}
                      onChange={handleChange}
                      min={0}
                      max={5}
                      disabled={viewMode}
                    />
                    <Span>
                      (5 = tourist trap, 0 = totally off the beaten path) COMPARABLE BETWEEN
                      COUNTRIES
                    </Span>
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Overall Ratings</Label>
                    <InputActivity
                      type="number"
                      placeholder="Overall Ratings"
                      name="overall_ratings"
                      value={values.overall_ratings}
                      onChange={handleChange}
                      min={0}
                      max={3}
                      disabled={viewMode}
                    />
                    <Span>
                      (3 = must-see regardless of preference, 0 = worth knowing about & pay
                      attention to preferences)
                    </Span>
                  </FlexColumn>
                </FlexRow>
                <FlexRow>
                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Off The Beaten Path</Label>
                    <ToggleSwitch
                      size="sm"
                      name="off_the_beaten_path"
                      value={values.off_the_beaten_path}
                      checked={values.off_the_beaten_path === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                    <Span>(Free Spirits) LITTLE TOURISM</Span>
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Up And Coming</Label>
                    <ToggleSwitch
                      size="sm"
                      name="up_and_coming"
                      value={values.up_and_coming}
                      checked={values.up_and_coming === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                    <Span>(Jet-Sets) SOME TOURISM</Span>
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Trustworthy Yet Authentic</Label>
                    <ToggleSwitch
                      size="sm"
                      name="trustworthy_yet_authentic"
                      value={values.trustworthy_yet_authentic}
                      checked={values.trustworthy_yet_authentic === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                    <Span>(Smart Explorers) AVERAGE TOURISM</Span>
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Typical “Must-See” Spot</Label>
                    <ToggleSwitch
                      size="sm"
                      name="typical_spot"
                      value={values.typical_spot}
                      checked={values.typical_spot === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                    <Span>(Sightseers & Laid-Back Loungers) LOTS OF TOURISM BUT OK</Span>
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Safe & Predictable</Label>
                    <ToggleSwitch
                      size="sm"
                      name="safe_and_predictable"
                      value={values.safe_and_predictable}
                      checked={values.safe_and_predictable === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                    <Span>
                      (aka Tourist Traps) (No-Nonsense Vacationers) BORDERLINE OVERTOURISTED
                    </Span>
                  </FlexColumn>
                </FlexRow>

                {!viewMode && (
                  <Button disabled={!isValid} onClick={handleSubmit}>
                    Save
                  </Button>
                )}
              </GridContainer>
            );
          }}
        </Formik>
      </GridContainer>
    );
  };

  filter = event => {
    const { name, value } = event.target;
    const { find } = this.props;

    clearTimeout(this.state.delayTyping);

    const delayTyping = setTimeout(() => {
      const { countryId, search, by } = this.props;
      find({ country_id: countryId, search, by, [name]: value });
    }, 1000);

    this.setState({
      [name]: value,
      delayTyping,
    });
  };

  changePage = page => {
    const { search, by } = this.props;
    const { find, countryId } = this.props;

    find({ country_id: countryId, page, search, by });
  };

  toggleModal = (isOpen, idSelected) => {
    this.setState({ isOpen, idSelected });
  };

  onRemove = () => {
    const { idSelected } = this.state;
    const { remove } = this.props;

    remove(idSelected);

    this.toggleModal(false, null);
  };

  renderItem = (
    {
      id,
      city,
      region,
      score,
      off_the_beaten_path,
      up_and_coming,
      trustworthy_yet_authentic,
      typical_spot,
      safe_and_predictable,
      overall_ratings,
    },
    key,
    viewMode,
  ) => {
    const { by } = this.state;
    const { findOne, switchFormShow } = this.props;

    return (
      <Item key={key}>
        {by === 'City/Area' && (
          <SectionContainer width={'20%'}>
            <Primary>{city}</Primary>
            <VerticalLine />
          </SectionContainer>
        )}

        {by !== 'Country' && (
          <SectionContainer width={'20%'}>
            <Primary>{region}</Primary>
            <VerticalLine />
          </SectionContainer>
        )}

        <SectionContainer width={'20%'}>
          <Primary>{score}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{off_the_beaten_path}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{up_and_coming}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{trustworthy_yet_authentic}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{typical_spot}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{safe_and_predictable}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{overall_ratings}</Primary>
          <VerticalLine />
        </SectionContainer>

        <SectionContainer width={'15%'} textAlign="right">
          {!viewMode ? (
            <>
              <ActionIcon
                onClick={() => {
                  findOne(id);
                  switchFormShow(true);
                }}
                color="#38b6ff"
                className="fas fa-edit"
              />

              <ActionIcon
                onClick={() => {
                  this.toggleModal(true, id);
                }}
                color="#ff0000ad"
                className="fas fa-trash-alt"
              />
            </>
          ) : (
            <ActionIcon
              onClick={() => {
                findOne(id);
                switchFormShow(true);
              }}
              color="#38b6ff"
              className="fas fa-eye"
            />
          )}
        </SectionContainer>
      </Item>
    );
  };

  render() {
    const { search, by, isOpen } = this.state;
    const {
      viewMode,
      token,
      switchFormShow,
      list,
      count,
      currentPage,
      loading,
      showForm,
      searchLifeCycle,
      lifecyclesearchable,
      handleModalExcel,
      renderModalExcel,
      country,
      find,
    } = this.props;

    if (loading) {
      return (
        <LoaderContainer>
          <Loader type="pulse" size={125} />
        </LoaderContainer>
      );
    }

    return (
      <Container>
        {!showForm && (
          <Container>
            <HeaderContainer style={{ marginBottom: 20 }}>
              <FlexRow>
                <FlexColumn width={30}>
                  <InputActivity
                    type="text"
                    placeholder="Search LifeCycle"
                    name="lifecyclesearchable"
                    value={lifecyclesearchable}
                    onChange={searchLifeCycle}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Select placeholder="By" name="by" value={by} onChange={this.filter}>
                    <option value={'Country'}>Country</option>
                    <option value={'Region'}>Region</option>
                    <option value={'City/Area'}>City/Area</option>
                  </Select>
                </FlexColumn>
                <FlexColumn>
                  <Pagination
                    currentPage={currentPage}
                    changePage={this.changePage}
                    totalPages={Math.ceil(count / elementsPerPage)}
                  />
                </FlexColumn>
                {!viewMode && (
                  <FlexColumn width={25}>
                    <FlexRow>
                      <LinkText
                        onClick={() => handleModalExcel(true)}
                        style={{ marginTop: 15, marginRight: 15 }}
                      >
                        Import from sheet
                      </LinkText>
                      <Button
                        onClick={() => {
                          switchFormShow(true);
                        }}
                        style={{ marginTop: 0 }}
                      >
                        Add
                      </Button>
                    </FlexRow>
                  </FlexColumn>
                )}
              </FlexRow>
            </HeaderContainer>
            <GridContainer>
              <THeader>
                {by === 'City/Area' && (
                  <TitleContainer width={'20%'}>
                    <Title>City/Area</Title>
                  </TitleContainer>
                )}
                {by !== 'Country' && (
                  <TitleContainer width={'20%'}>
                    <Title>Region</Title>
                  </TitleContainer>
                )}
                <TitleContainer width={'20%'}>
                  <Title>Score</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Off-The-Beaten-Path</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Up-And-Coming</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Trustworthy Yet Authentic</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Typical “Must-See” Spot</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Safe & Predictable</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Overall Ratings</Title>
                </TitleContainer>
                <TitleContainer width={'15%'} />
              </THeader>
              <TBody>
                {(list || []).map((lifecycle, key) => {
                  return this.renderItem(lifecycle, key, viewMode);
                })}
                {(!list || !list.length) && (
                  <ListEmpty message="There are currently no lifecycles" />
                )}
              </TBody>
            </GridContainer>
          </Container>
        )}
        {showForm && this.renderForm()}
        <Modal
          closeIcon
          isVisible={isOpen}
          title="DELETE LIFECYCLE"
          cancelText="Cancel"
          onCancel={() => this.toggleModal(false, null)}
          successText="Remove"
          onSuccess={this.onRemove}
        >
          Are you sure of remove the lifecycle?
        </Modal>

        <ModalExcel
          get={find}
          id={country.id}
          token={token}
          url="lifecycles"
          currentPage={currentPage}
          elementsPerPage={elementsPerPage}
          renderModalExcel={renderModalExcel}
          handleModalExcel={handleModalExcel}
        ></ModalExcel>
      </Container>
    );
  }
}

export default Lifecycle;
