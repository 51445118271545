import React, { Component } from 'react';
import PastDestinationsView from './PastDestinationsView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';

class PastDestinations extends Component {
  changeStep = (step, past_destinations) => {
    const { changeCurrentStep, saveTripData } = this.props;

    saveTripData({ past_destinations });
    changeCurrentStep(step);
  };

  render() {
    const { past_destinations } = this.props;
    return (
      <PastDestinationsView
        {...this.props}
        changeStep={this.changeStep}
        past_destinations={past_destinations}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    past_destinations: state.tripReducer.tripForm.past_destinations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PastDestinations);
