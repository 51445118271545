import React, { Component } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import styled from 'styled-components';

const Body = styled.div`
  position: absolute;
  align-self: center;
  text-align: center;
  z-index: 100;
`;

const TextTitle = styled.div`
  color: #999999;
  font-weight: 350;
  font-size: 18px;
  line-height: 22px;
`;
const DownloadExample = styled.a`
  color: #38b6ff;
  font-weight: 350;
  font-size: 24px;
  line-height: 29px;
  text-decoration-line: underline;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default class DropzoneComponent extends Component {
  state = {
    status: 'empty',
  };

  getUploadParams = () => {
    const { url, authToken } = this.props;

    return {
      url,
      headers: {
        Authorization: authToken,
      },
    };
  };

  handleChangeStatus = ({ xhr, remove }, status) => {
    if (status === 'done') {
      const { handleResponse, removeAfterSuccess } = this.props;
      handleResponse(JSON.parse(xhr.response));
      if (removeAfterSuccess) {
        remove();
      }
    }
  };

  render() {
    const { status } = this.state;
    const { title, link, width, height } = this.props;

    const customWidth = width || '100%';
    const customHeight = height || '200px';

    return (
      <Container>
        {status === 'empty' && (
          <Body>
            <TextTitle>{title || 'Choose CSV File'}</TextTitle>
            {link && (
              <DownloadExample href={link} download>
                Download example
              </DownloadExample>
            )}
          </Body>
        )}
        <Dropzone
          getUploadParams={this.getUploadParams}
          onChangeStatus={this.handleChangeStatus}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          inputContent=""
          styles={{
            dropzone: {
              width: customWidth,
              height: customHeight,
              overflow: 'auto',
              borderStyle: 'dashed',
            },
            inputLabel: { color: '#38b6ff', fontFamily: "'Josefin Sans', sans-serif" },
            dropzoneActive: { borderColor: 'green' },
          }}
        />
      </Container>
    );
  }
}
