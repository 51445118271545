import React from 'react';
import styled from 'styled-components';
import Dropzone from 'components/DropZone';
import ReactMapboxGl, { Layer, Feature, Marker } from 'react-mapbox-gl';
import config from 'config/appConfig';
import marker from 'assets/media/icons/marker.png';
import SelectedButton from 'components/SelectedButton';
import Checkbox from 'components/Login/CheckboxRemember';
import RangeInput from 'components/RangeInput';

import {
  ENTERTAINMENT,
  NIGHTLIFE,
  MUSEUMS,
  FOODDRINK,
  OUTDOORACT,
  SIGHTSEEING,
  SHOPPING,
  TOURCLASSES,
  WELLNESS,
} from 'constants/index';

const Map = ReactMapboxGl({
  accessToken: config.MAPBOX_ACCESS_TOKEN,
});

const Container = styled.div`
  margin: 25px 10px;
`;

const FlexRow = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const TagsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  max-height: 305px;
  overflow-y: scroll;
  background-color: white;
  padding-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

const InputActivity = styled.input`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 25)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const Select = styled.select`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: 25px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const TextAreaActivity = styled.textarea`
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: 25px;
  resize: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding: 20px;
`;

const ImageProfile = styled.img`
  height: 95%;
  width: auto;
  margin: auto;
`;

const FlexBox = styled.div`
  display: flex;
`;

const Mark = styled.img`
  width: 40px;
  height: 40px;
`;

const DropzoneContainer = styled.div`
  margin-bottom: 34px;
  width: 100%;
  margin-top: 35px;
  box-shadow: 0px 6px 18px 0 rgba(0,0,0,0.04);
  background-color: #ffffff;
  margin-bottom: 39px;
  height: 255px;
  display: flex;
  justify-content: center;
  position: relative;
}
`;

const CityOption = styled.div`
  background-color: ${props => (props.active ? '#38b6ff' : '#f4f4f4')};
  border: none;
  height: 50px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
`;

const TextOption = styled.p`
  color: ${props => (props.active ? 'white' : '#999999')};
  margin-left: 8px;
  font-family: 'Josefin Sans', sans-serif;
`;

const PlacesList = styled.div`
  background-color: #ffffff;
  padding: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  border-radius: 5px;
`;
const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 140px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: #38b6ff;
`;

const ReplaceImageText = styled.p`
  color: white;
  position: absolute;
  top: 40%;
`;

const LittleText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #464646;
  width: 33%;
  text-align: center;
`;

const ActivityForm = props => {
  const {
    name,
    category,
    image,
    budget,
    duration,
    address,
    website,
    phone_number,
    description,
    lng,
    lat,
    motive,
    city_name,
    city_id,
    showCityList,
    showPlaceList,
    addressInput,
    sub_category,
    require_reservation,
    have_bussy_hours,
    aditional_notes,
    bussy_hours,
  } = props.data;

  const {
    handleChange,
    updateImage,
    token,
    clickMap,
    handleChangeAddress,
    places,
    selectPlace,
    saveOrUpdate,
    searchCity,
    citiesFiltered,
    selectCity,
    editMode,
    replaceImage,
    updateShowCityList,
    updateShowPlaceList,
    changeTags,
    handleChangeBussyHours,
    handleChangeCheckbox,
  } = props;

  return (
    <Container>
      <Header>
        <Button onClick={saveOrUpdate}>{editMode ? 'Update Activity' : 'Create Activity'}</Button>
      </Header>
      <FlexRow>
        <FlexColumn marginLeft={10} marginRight={20}>
          <FlexRow>
            <FlexColumn marginRight={10}>
              <Label>Business Name</Label>
              <InputActivity
                placeholder="Business Name"
                name="name"
                value={name}
                onChange={handleChange}
              />
            </FlexColumn>
            <FlexColumn marginLeft={10}>
              <Label>Category</Label>
              <Select
                placeholder="Category"
                name="category"
                value={category}
                onChange={handleChange}
              >
                <option value={'Entertainment'}>Entertainment</option>
                <option value={'Food & Drink'}>Food & Drink</option>
                <option value={'Museums'}>Museums</option>
                <option value={'Nightlife'}>Nightlife</option>
                <option value={'Outdoor Activities'}>Outdoor Activities</option>
                <option value={'Sightseeing'}>Sightseeing</option>
                <option value={'Shopping'}>Shopping</option>
                <option value={'Tours & Classes'}>Tours & Classes</option>
                <option value={'Wellness'}>Wellness</option>
              </Select>
            </FlexColumn>
          </FlexRow>

          <FlexRow>
            <FlexColumn marginRight={10}>
              <Label>Budget</Label>
              <InputActivity
                type="number"
                placeholder="Budget"
                name="budget"
                value={budget}
                onChange={handleChange}
              />
            </FlexColumn>
            <FlexColumn marginLeft={10}>
              <Label>Duration</Label>
              <InputActivity
                type="number"
                placeholder="Minutes"
                name="duration"
                value={duration}
                onChange={handleChange}
                min={0}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn marginBottom={25}>
              <Label>City</Label>
              <InputActivity
                marginBottom={'0'}
                placeholder="City"
                name="city_name"
                value={city_name}
                onChange={searchCity}
                onFocus={e => {
                  updateShowCityList(true);
                }}
              />
              {showCityList && citiesFiltered.length > 0 && (
                <PlacesList>
                  {citiesFiltered.slice(0, 3).map((city, key) => (
                    <CityOption
                      active={city.id == city_id}
                      onClick={() => {
                        selectCity(city);
                        updateShowCityList(false);
                      }}
                      key={key}
                    >
                      <TextOption active={city.id == city_id}>{city.name}</TextOption>
                    </CityOption>
                  ))}
                </PlacesList>
              )}
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn marginRight={10}>
              <Label>Address</Label>
              <InputActivity
                placeholder="Address"
                name="address"
                value={addressInput}
                onChange={handleChangeAddress}
                onFocus={() => {
                  updateShowPlaceList(true);
                }}
              />
            </FlexColumn>
            <FlexColumn marginLeft={10}>
              <FlexRow>
                <FlexColumn>
                  <Label>Lat</Label>
                  <InputActivity
                    disabled
                    placeholder="Lat"
                    name="lat"
                    value={lat.toFixed(5)}
                    onChange={handleChange}
                  />
                </FlexColumn>
                <FlexColumn marginLeft={10}>
                  <Label>Lng</Label>
                  <InputActivity
                    disabled
                    placeholder="Lng"
                    name="lng"
                    value={lng.toFixed(5)}
                    onChange={handleChange}
                  />
                </FlexColumn>
              </FlexRow>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn width={90}>
              {showPlaceList && places.length > 0 && (
                <PlacesList>
                  {places.map((place, key) => (
                    <CityOption
                      active={place.place_name == address}
                      key={key}
                      onClick={() => {
                        selectPlace(place);
                        updateShowPlaceList(false);
                      }}
                    >
                      <TextOption active={place.place_name == address}>
                        {place.place_name}
                      </TextOption>
                    </CityOption>
                  ))}
                </PlacesList>
              )}
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn marginRight={10}>
              <Label>Website</Label>
              <InputActivity
                placeholder="Website"
                name="website"
                value={website}
                onChange={handleChange}
              />
            </FlexColumn>
            <FlexColumn marginLeft={10}>
              <Label>Phone Number</Label>
              <InputActivity
                placeholder="Phone Number"
                name="phone_number"
                value={phone_number}
                onChange={handleChange}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <Checkbox
              isChecked={require_reservation}
              toggleChange={e => handleChangeCheckbox(e, 'require_reservation')}
              name="require_reservation"
              label={<Label>Is it necessary to make a reservation in advance?</Label>}
            />
          </FlexRow>

          <FlexRow>
            <FlexColumn width={100}>
              <Label>Description</Label>
              <TextAreaActivity
                placeholder="Description"
                name="description"
                value={description}
                onChange={handleChange}
              />
            </FlexColumn>
          </FlexRow>

          <FlexRow>
            <FlexColumn width={100}>
              <Label>Aditional notes</Label>
              <TextAreaActivity
                placeholder="Aditional notes"
                name="aditional_notes"
                value={aditional_notes}
                onChange={handleChange}
              />
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
        <FlexColumn width={90} marginLeft={20}>
          <FlexRow>
            <DropzoneContainer>
              {!image && (
                <Dropzone
                  title="Choose image"
                  height={255}
                  url={`${config.BASE_URL}/api/v1/upload/activity`}
                  authToken={token}
                  handleResponse={updateImage}
                  folder={'activity'}
                />
              )}
              {image && (
                <>
                  <ImageProfile src={image} />
                  {
                    <ReplaceImageText onClick={replaceImage}>
                      Click to Replace Image
                    </ReplaceImageText>
                  }
                </>
              )}
            </DropzoneContainer>
          </FlexRow>
          <FlexRow>
            <Map
              onClick={clickMap}
              style="mapbox://styles/mapbox/streets-v9"
              containerStyle={{
                width: '100%',
                height: '580px',
              }}
              center={[lng, lat]}
              zoom={[15]}
            >
              <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
                <Feature coordinates={[lng, lat]} />
              </Layer>
              <Marker coordinates={[lng, lat]}>
                <Mark src={marker} />
              </Marker>
            </Map>
          </FlexRow>

          <FlexRow>
            <Checkbox
              isChecked={have_bussy_hours}
              toggleChange={e => handleChangeCheckbox(e, 'have_bussy_hours')}
              name="have_bussy_hours"
              label={<Label>Load more recurring schedules?</Label>}
            />
          </FlexRow>
          {have_bussy_hours && (
            <FlexColumn
              style={{ height: 260, overflow: 'auto', paddingRight: 13, backgroundColor: 'white' }}
            >
              {bussy_hours.map((hour, index) => (
                <FlexRow>
                  <FlexColumn
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    width={100}
                  >
                    <Label>
                      {`Recurrence level at ${
                        index < 10 ? '0' + index.toString() : index.toString()
                      } Hours:`}
                    </Label>
                    <RangeInput
                      name={`bussy_hours.${index}`}
                      value={hour}
                      handleChange={e => handleChangeBussyHours(e, index)}
                      min="0"
                      max="10"
                      step="1"
                      minQuantityLabel="0"
                      maxQuantityLabel="10"
                      mediumQuantityValue="5"
                      style={{ width: '50%', margin: 0 }}
                    />
                  </FlexColumn>
                </FlexRow>
              ))}
            </FlexColumn>
          )}
        </FlexColumn>
      </FlexRow>

      <FlexRow>
        {category == 'Entertainment' && (
          <FlexColumn width={100} marginLeft={10} marginRight={20}>
            <Label marginTop={45}>Entertainment:</Label>
            <FlexBox>
              <TagsContainer>
                {ENTERTAINMENT.map(entertainment => (
                  <SelectedButton
                    text={entertainment}
                    active={sub_category.includes(entertainment)}
                    onClick={() => changeTags(entertainment, 'sub_category')}
                  />
                ))}
              </TagsContainer>
            </FlexBox>
          </FlexColumn>
        )}
        {category == 'Nightlife' && (
          <FlexColumn width={100} marginLeft={10} marginRight={20}>
            <Label marginTop={45}>Nightlife:</Label>
            <FlexBox>
              <TagsContainer>
                {NIGHTLIFE.map(nightlife => (
                  <SelectedButton
                    text={nightlife}
                    active={sub_category.includes(nightlife)}
                    onClick={() => changeTags(nightlife, 'sub_category')}
                  />
                ))}
              </TagsContainer>
            </FlexBox>
          </FlexColumn>
        )}
        {category == 'Museums' && (
          <FlexColumn width={100} marginLeft={10} marginRight={20}>
            <Label marginTop={45}>Museums: </Label>
            <FlexBox>
              <TagsContainer>
                {MUSEUMS.map(museums => (
                  <SelectedButton
                    text={museums}
                    active={sub_category.includes(museums)}
                    onClick={() => changeTags(museums, 'sub_category')}
                  />
                ))}
              </TagsContainer>
            </FlexBox>
          </FlexColumn>
        )}
        {category == 'Food & Drink' && (
          <FlexColumn width={100} marginLeft={10} marginRight={20}>
            <Label marginTop={45}>Food & Drink:</Label>
            <FlexBox>
              <TagsContainer>
                {FOODDRINK.map(food => (
                  <SelectedButton
                    text={food}
                    active={sub_category.includes(food)}
                    onClick={() => changeTags(food, 'sub_category')}
                  />
                ))}
              </TagsContainer>
            </FlexBox>
          </FlexColumn>
        )}
        {category == 'Outdoor Activities' && (
          <FlexColumn width={100} marginLeft={10} marginRight={20}>
            <Label marginTop={45}>Outdoor Activities:</Label>
            <FlexBox>
              <TagsContainer>
                {OUTDOORACT.map(outdoor => (
                  <SelectedButton
                    text={outdoor}
                    active={sub_category.includes(outdoor)}
                    onClick={() => changeTags(outdoor, 'sub_category')}
                  />
                ))}
              </TagsContainer>
            </FlexBox>
          </FlexColumn>
        )}
        {category == 'Sightseeing' && (
          <FlexColumn width={100} marginLeft={10} marginRight={20}>
            <Label marginTop={45}>Sightseeing:</Label>
            <FlexBox>
              <TagsContainer>
                {SIGHTSEEING.map(sightseeing => (
                  <SelectedButton
                    text={sightseeing}
                    active={sub_category.includes(sightseeing)}
                    onClick={() => changeTags(sightseeing, 'sub_category')}
                  />
                ))}
              </TagsContainer>
            </FlexBox>
          </FlexColumn>
        )}
        {category == 'Shopping' && (
          <FlexColumn width={100} marginLeft={10} marginRight={20}>
            <Label marginTop={45}>Shopping:</Label>
            <FlexBox>
              <TagsContainer>
                {SHOPPING.map(shopping => (
                  <SelectedButton
                    text={shopping}
                    active={sub_category.includes(shopping)}
                    onClick={() => changeTags(shopping, 'sub_category')}
                  />
                ))}
              </TagsContainer>
            </FlexBox>
          </FlexColumn>
        )}
        {category == 'Tours & Classes' && (
          <FlexColumn width={100} marginLeft={10} marginRight={20}>
            <Label marginTop={45}>Tours & Classes:</Label>
            <FlexBox>
              <TagsContainer>
                {TOURCLASSES.map(tours => (
                  <SelectedButton
                    text={tours}
                    active={sub_category.includes(tours)}
                    onClick={() => changeTags(tours, 'sub_category')}
                  />
                ))}
              </TagsContainer>
            </FlexBox>
          </FlexColumn>
        )}
        {category == 'Wellness' && (
          <FlexColumn width={100} marginLeft={10} marginRight={20}>
            <Label marginTop={45}>Wellness:</Label>
            <FlexBox>
              <TagsContainer>
                {WELLNESS.map(wellness => (
                  <SelectedButton
                    text={wellness}
                    active={sub_category.includes(wellness)}
                    onClick={() => changeTags(wellness, 'sub_category')}
                  />
                ))}
              </TagsContainer>
            </FlexBox>
          </FlexColumn>
        )}
      </FlexRow>
    </Container>
  );
};

export default ActivityForm;
