import React, { Component } from 'react';
import EateriesView from './EateriesView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';

class Eateriess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eateries: null,
    };
  }

  componentDidMount() {
    const { eateries } = this.props;
    this.setState({ eateries });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eateries != this.props.eateries) this.setState({ eateries: this.props.eateries });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { eateries } = this.state;
    saveTripData({ eateries });
    changeCurrentStep(step);
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { eateries } = this.state;

    return (
      <EateriesView
        {...this.props}
        changeStep={this.changeStep}
        handleChange={this.handleChange}
        eateries={eateries}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    eateries: state.tripReducer.tripForm.eateries,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Eateriess);
