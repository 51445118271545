import React, { Component } from 'react';
import SelectSightseeingView from './SelectSightseeingView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import { SIGHTSEEING } from 'constants/index';

const sightseeingMaping = Object.fromEntries(
  SIGHTSEEING.map(sightseeing => [
    sightseeing,
    sightseeing
      .toLowerCase()
      .replaceAll(/\s/g, '_')
      .replaceAll('_&', '')
      .replaceAll(',', '')
      .replaceAll('/', '_'),
  ]),
);
class SelectSightseeingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      castles_palaces_villas: 0,
      historic_important_sites: 0,
      markets: 0,
      modern_marvels: 0,
      monuments_statues: 0,
      nature_parks: 0,
      neighborhood_town_walks: 0,
      notable_architecture: 0,
      other_sightseeing: 0,
      sacred_religious_sites: 0,
      scenic_views: 0,
      scenic_walking_areas: 0,
      squares: 0,
      water_theme_parks: 0,
    };
  }
  componentDidMount() {
    const {
      castles_palaces_villas,
      historic_important_sites,
      markets,
      modern_marvels,
      monuments_statues,
      nature_parks,
      neighborhood_town_walks,
      notable_architecture,
      other_sightseeing,
      sacred_religious_sites,
      scenic_views,
      scenic_walking_areas,
      squares,
      water_theme_parks,
    } = this.props;
    this.setState({
      castles_palaces_villas,
      historic_important_sites,
      markets,
      modern_marvels,
      monuments_statues,
      nature_parks,
      neighborhood_town_walks,
      notable_architecture,
      other_sightseeing,
      sacred_religious_sites,
      scenic_views,
      scenic_walking_areas,
      squares,
      water_theme_parks,
    });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const {
      castles_palaces_villas,
      historic_important_sites,
      markets,
      modern_marvels,
      monuments_statues,
      nature_parks,
      neighborhood_town_walks,
      notable_architecture,
      other_sightseeing,
      sacred_religious_sites,
      scenic_views,
      scenic_walking_areas,
      squares,
      water_theme_parks,
    } = this.state;
    changeCurrentStep(step);
    saveTripData({
      sightseeing: {
        castles_palaces_villas,
        historic_important_sites,
        markets,
        modern_marvels,
        monuments_statues,
        nature_parks,
        neighborhood_town_walks,
        notable_architecture,
        other_sightseeing,
        sacred_religious_sites,
        scenic_views,
        scenic_walking_areas,
        squares,
        water_theme_parks,
      },
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <SelectSightseeingView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        sightseeingMaping={sightseeingMaping}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    castles_palaces_villas: state.tripReducer.tripForm.sightseeing.castles_palaces_villas,
    historic_important_sites: state.tripReducer.tripForm.sightseeing.historic_important_sites,
    markets: state.tripReducer.tripForm.sightseeing.markets,
    modern_marvels: state.tripReducer.tripForm.sightseeing.modern_marvels,
    monuments_statues: state.tripReducer.tripForm.sightseeing.monuments_statues,
    nature_parks: state.tripReducer.tripForm.sightseeing.nature_parks,
    neighborhood_town_walks: state.tripReducer.tripForm.sightseeing.neighborhood_town_walks,
    notable_architecture: state.tripReducer.tripForm.sightseeing.notable_architecture,
    other_sightseeing: state.tripReducer.tripForm.sightseeing.other_sightseeing,
    sacred_religious_sites: state.tripReducer.tripForm.sightseeing.sacred_religious_sites,
    scenic_views: state.tripReducer.tripForm.sightseeing.scenic_views,
    scenic_walking_areas: state.tripReducer.tripForm.sightseeing.scenic_walking_areas,
    squares: state.tripReducer.tripForm.sightseeing.squares,
    water_theme_parks: state.tripReducer.tripForm.sightseeing.water_theme_parks,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectSightseeingContainer);
