import React, { Component } from 'react';
import styled from 'styled-components';
import PayMethodItem from '../../components/PaymentMethods/PayMethodItem';
import PaymentMethodForm from '../../components/PaymentMethods/PaymentMethodForm';
import ListEmpty from 'components/ListEmpty';
import { injectStripe } from 'react-stripe-elements';
import Loader from 'components/Loader';

const PaymentMethodContainer = styled.div`
  box-shadow: 0 9px 18px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 3px;
  width: 98%;
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
`;
const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 175px;
  display: inline-block;
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 300;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.01);
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  float: right;
  margin: 20px;
  cursor: pointer;
  &: hover {
    background: #00b4d52b;
    opacity: 0.4;
  }
  background: 'white';
  color: rgb(15, 203, 255);
  border-color: rgb(15, 203, 255);
  border: 2px solid;
`;

const Divisor = styled.div`
  height: 0.5px;
  background-color: #b7b7b7;
  width: 97%;
  margin: auto;
`;

const PrimaryText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18.5px;
  text-align: left;
  color: #999999;
  margin-left: 30px;
`;

const Title = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 16px;
  color: #3e3e3e;
  margin: 20px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
`;

class UpdatePaymentMethodView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCardToken = () => {
    const { createMethod } = this.props;
    return this.props.stripe
      .createToken({ name: 'Card', type: 'card' })
      .then(cardToken => {
        if (cardToken.hasOwnProperty('token')) {
          createMethod({ card_token: cardToken.token.id });
        }
      })
      .catch(err => err);
  };
  render() {
    const {
      payment_methods,
      state,
      addCardModal,
      deletePaymentMethod,
      setAsDefault,
      loading,
    } = this.props;
    const { addCard } = state;
    return (
      <Container>
        <Header>
          <Title>PAYMENT METHODS</Title>
          <Button onClick={addCardModal}>Add Method</Button>
        </Header>

        <PaymentMethodContainer>
          <PrimaryText>Primary</PrimaryText>
          <Divisor />
          {loading ? (
            <LoaderContainer>
              <Loader type="pulse" size={60} />
            </LoaderContainer>
          ) : (
            payment_methods
              .filter(method => method.default_card)
              .map(method => (
                <PayMethodItem deletePaymentMethod={deletePaymentMethod} method={method} />
              ))
          )}
          <PrimaryText>Additional</PrimaryText>
          <Divisor />
          <Divisor />
          {loading ? (
            <LoaderContainer>
              <Loader type="pulse" size={60} />
            </LoaderContainer>
          ) : payment_methods.filter(method => !method.default_card).length ? (
            payment_methods
              .filter(method => !method.default_card)
              .map(method => (
                <PayMethodItem
                  deletePaymentMethod={deletePaymentMethod}
                  setAsDefault={setAsDefault}
                  method={method}
                  isAditional
                />
              ))
          ) : (
            <ListEmpty message="You don’t have any additional payment methods." />
          )}
        </PaymentMethodContainer>
        {addCard && (
          <PaymentMethodForm addCardModal={addCardModal} getCardToken={this.getCardToken} />
        )}
      </Container>
    );
  }
}

export default injectStripe(UpdatePaymentMethodView);
