import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Header from './Header';
import { replace } from 'modules/history';
import './styles.css';

const PageWithMenu = styled.div`
  display: flex;
`;

const PageContent = styled.div`
  width: calc(100% - 250px);
  @media (max-width: 700px) {
    width: calc(100% - 60px);
  }
`;

const Menu = styled.div`
  width: 250px;
  @media (max-width: 700px) {
    width: 60px;
  }
`;

const Banner = styled.div`
  width: 100%;
  background-color: #ebf8ff;
  display: flex;
  align-items: center;
  padding: 25px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const adminMenu = [
  { name: 'Trips', url: '/trips', icon: 'far fa-compass' },
  { name: 'Customers', url: '/customers', icon: 'far fa-user' },
  { name: 'Activities', url: '/activities', icon: 'fa fa-route fa-2x' },
  { name: 'Accomodations', url: '/accommodations', icon: 'fa fa-bed fa-2x' },
  { name: 'Restaurants', url: '/restaurants', icon: 'fa fa-utensils fa-2x' },

  { name: 'Pre Trip Courses', url: '/pre-trip-courses', icon: 'fa fa-bullhorn fa-2x' },
  { name: 'Suggestions', url: '/suggestions', icon: 'fa fa-flag fa-2x' },
  {
    name: 'Request Boutique Package',
    url: '/requests-of-boutique-packages',
    icon: 'fa fa-suitcase',
  },

  { name: 'Settings', url: '/settings', icon: 'fa fa-cog fa-2x' },
];

const customerMenu = [
  { name: 'Your Journeys', url: '/trip-content', icon: 'fa fa-globe-americas' },
  { name: 'About You', url: '/diagnostic-results', icon: 'fa fa-id-card' },
  { name: 'Courses', url: '/courses', icon: 'fa fa-book-reader' },
  {
    name: 'My Pre-Trip Courses',
    url: '/purchase-pre-trip-courses',
    icon: 'fa fa-folder-open fa-2x',
  },
  { name: 'Schedule Discussion', url: '/schedule-calls', icon: 'fa fa-phone-volume' },
  { name: 'Settings', url: '/settings', icon: 'fa fa-cog fa-2x' },
];

export default class MenuPage extends Component {
  render() {
    const { profile, role_code } = this.props;
    const testIncomplete = profile.diagnostic_step !== 9 && profile.role_id !== 1;

    const selectItems = () => {
      switch (role_code) {
        case 'admin':
          return adminMenu;
        case 'customer':
          return customerMenu;
        default:
          return [];
      }
    };

    const renderItem = ({ name, url, icon }, key) => (
      <li className="menu-item" key={key}>
        <a
          onClick={() => replace(url)}
          className={this.props.path === url ? 'activeMenu' : undefined}
        >
          <i className={icon} />
          <span className="nav-text">{name}</span>
        </a>
      </li>
    );

    return (
      <PageWithMenu>
        <Header profile={profile} />
        <Menu>
          <nav className="main-menu">
            <ul>
              <li className="title-menu">
                <span className="nav-text-title">MAIN MENU</span>
              </li>
              {selectItems().map((item, key) => renderItem(item, key))}
            </ul>
          </nav>
        </Menu>
        <PageContent>
          {testIncomplete && (
            <Banner>
              <p>
                Before we can begin, we need to ask you some questions.
                <Link to="/diagnostics"> Click here</Link> to get started!
              </p>
            </Banner>
          )}
          {this.props.children}
        </PageContent>
      </PageWithMenu>
    );
  }
}
