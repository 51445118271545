export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        strengthName: '',
        strengthDescription:
          '<p>Is there anything you’ve wanted to do on this trip but have been holding off on or have yet to accomplish? Are there any questions you’ve not yet answered or ideas you’ve not yet fully explored? Don’t over do it, but use your final full day on the road as an opportunity to both indulge in and reflect upon your travels. Take stock in the things you’ve accomplished and the lessons you’ve learned. Savor the final moments of this portion of your journey.</p>',
      };
    case 2:
      return {
        strengthName: '',
        strengthDescription:
          '<p>As you prepare to return home, take a few minutes to check in with yourself. What are you feeling? Is there anything in particular you’re going to miss about the people and places you’ve gotten to know on this journey? In what ways might you be taking them home with you? What are you most looking forward to about going home? Are there things you’ll appreciate more or differently than before?</p>',
      };
  }
};
