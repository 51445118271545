import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';
import TripView from './TripForm';

class EditTripContainer extends Component {
  state = { suggestionsModal: false };

  componentDidMount() {
    const {
      fetchTrip,
      match: { params },
    } = this.props;

    fetchTrip(params.id);
    if (params.customer) {
      saveTripData({ user_id: params.customer });
    }
  }

  handleModal = suggestionsModal => {
    this.setState({ suggestionsModal });
  };

  render() {
    const { suggestionsModal } = this.state;
    return (
      <TripView
        {...this.props}
        handleModal={this.handleModal}
        suggestionsModal={suggestionsModal}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.tripReducer.tripForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
    fetchTrip: id => dispatch(tripActions.getTrip(id)),
    resetTripForm: () => dispatch(tripActions.resetTripForm()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditTripContainer);
