import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

const DEFAULT_ITEM = {
  description: '',
};

export const appState = {
  list: [],
  item: DEFAULT_ITEM,
  count: 0,
  currentPage: 0,
  loading: false,
};

export default {
  boutiquePackageReducer: handleActions(
    {
      [ActionTypes.GET_REQUESTS_BOUTIQUE_PACKAGES](state, action) {
        return {
          ...state,
          list: [],
          item: DEFAULT_ITEM,
          count: 0,
          currentPage: 0,
          loading: true,
        };
      },
      [ActionTypes.GET_REQUESTS_BOUTIQUE_PACKAGES_SUCCESS](state, action) {
        const {
          payload: { rows, count, currentPage },
        } = action;

        return {
          ...state,
          list: rows,
          count,
          currentPage,
          loading: false,
        };
      },
      [ActionTypes.GET_REQUESTS_BOUTIQUE_PACKAGES_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_REQUEST_BOUTIQUE_PACKAGE](state, action) {
        return {
          ...state,
          item: DEFAULT_ITEM,
          loading: true,
        };
      },
      [ActionTypes.GET_REQUEST_BOUTIQUE_PACKAGE_SUCCESS](state, { payload }) {
        return {
          ...state,
          item: payload,
          loading: false,
        };
      },
      [ActionTypes.GET_REQUEST_BOUTIQUE_PACKAGE_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_MY_REQUEST_BOUTIQUE_PACKAGE](state, action) {
        return {
          ...state,
          item: DEFAULT_ITEM,
          loading: true,
        };
      },
      [ActionTypes.GET_MY_REQUEST_BOUTIQUE_PACKAGE_SUCCESS](state, { payload }) {
        return {
          ...state,
          item: payload,
          loading: false,
        };
      },
      [ActionTypes.GET_MY_REQUEST_BOUTIQUE_PACKAGE_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.SAVE_PACKAGE_BOUTIQUE](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.SAVE_PACKAGE_BOUTIQUE_SUCCESS](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.SAVE_PACKAGE_BOUTIQUE_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.UPDATE_PACKAGE_BOUTIQUE](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.UPDATE_PACKAGE_BOUTIQUE_SUCCESS](state) {
        return {
          ...state,
          loading: false,
          item: DEFAULT_ITEM,
        };
      },
      [ActionTypes.UPDATE_PACKAGE_BOUTIQUE_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
    },
    appState,
  ),
};
