import React, { Component } from 'react';
import styled from 'styled-components';
import {
  calculateTravelPersonalityResult,
  calculateCoreStrengthsResult,
  calculateFundationalStrengthsResult,
  calculateWellBeingResults,
} from 'helpers/diagnosticResults';
import WellBeingChart from 'components/ExplanationResults/Charts/WellBeingChart';
import FoundationalStrengthsChart from 'components/ExplanationResults/Charts/FoundationalStrengthsChart';
import CoreStrengthsChart from 'components/ExplanationResults/Charts/CoreStrengthsChart';
import ListEmpty from 'components/ListEmpty';
const parse = require('html-react-parser');

const Container = styled.div`
  display: flex;
  width: ${props => props.width}%;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const ChartBox = styled.div`
  display: flex;
  width: 50%;

  padding: 22px;
  flex-direction: column;
  background-color: white;
  margin: 20px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Label = styled.p`
  color: #9e9e9e;
  font-size: 22px;
`;

export const TravelText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  line-height: 1.47;
  color: #999999;
  text-align: justify;
`;
class DiagnosticTestSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.calculateResults();
  }

  componentDidUpdate(prevProps) {
    if (prevProps != this.props) this.calculateResults();
  }

  calculateResults = () => {
    if (this.props.wellBeings && this.props.travelPersonalities && this.props.strengths) {
      const wellBeingResults = calculateWellBeingResults(this.props);
      const fundationalStrengthsResult = calculateFundationalStrengthsResult(this.props);
      const coreStrengthsResult = calculateCoreStrengthsResult(this.props);
      const travelPersonalityResult = calculateTravelPersonalityResult(this.props);
      this.setState({
        wellBeingResults,
        fundationalStrengthsResult,
        travelPersonalityResult,
        coreStrengthsResult,
      });
    }
  };
  render() {
    const {
      wellBeingResults,
      fundationalStrengthsResult,
      coreStrengthsResult,
      travelPersonalityResult,
    } = this.state;
    if (
      !wellBeingResults ||
      !fundationalStrengthsResult ||
      !coreStrengthsResult ||
      !travelPersonalityResult
    )
      return <ListEmpty message="You did'nt complete your diagnostic test." />;

    return (
      <>
        <Container width={100}>
          <ChartBox>
            <Label>Well Being Test</Label>
            <WellBeingChart wellBeingResults={wellBeingResults} />
          </ChartBox>
          <ChartBox>
            <Label>Foundational Strengths Test</Label>
            <FoundationalStrengthsChart fundationalStrengthsResult={fundationalStrengthsResult} />
          </ChartBox>
        </Container>
        <Container width={100}>
          <ChartBox>
            <Label>Core Strengths Test</Label>
            <CoreStrengthsChart coreStrengthsResult={coreStrengthsResult} />
          </ChartBox>
          <ChartBox>
            <Label>Travel Personality Test</Label>
            <TravelText> {parse(travelPersonalityResult.content)} </TravelText>
          </ChartBox>
        </Container>
      </>
    );
  }
}

export default DiagnosticTestSummary;
