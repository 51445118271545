import React, { Component } from 'react';
import StepIndicatorView from './StepIndicatorView';
import { connect } from 'react-redux';
import * as diagnosticActions from '../../../actions/diagnosticActions';
import { selectFields } from './StepFields/DiagnosticIntroduction';

class StepIndicatorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeStep = async step => {
    const { changeCurrentStep } = this.props;
    changeCurrentStep(step);
  };

  render() {
    const stepFields = selectFields(this.props.step);

    return (
      <StepIndicatorView {...this.props} changeStep={this.changeStep} stepFields={stepFields} />
    );
  }
}

function mapStateToProps(state) {
  const { wellBeings, strengths, travelPersonalities } = state.diagnosticReducer;
  return {
    wellBeings,
    strengths,
    travelPersonalities,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(diagnosticActions.changeCurrentStep(step)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepIndicatorContainer);
