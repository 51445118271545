import { ActionTypes } from 'constants/index';

export function createCourseRequest(data) {
  return {
    type: ActionTypes.CREATE_COURSE_REQUEST,
    data,
  };
}

export function createCourseSuccess(course) {
  return {
    type: ActionTypes.CREATE_COURSE_SUCCESS,
    course,
  };
}

export function getCourseRequest(id) {
  return {
    type: ActionTypes.GET_COURSE_REQUEST,
    id,
  };
}

export function getCourseSuccess(goal) {
  return {
    type: ActionTypes.GET_COURSE_SUCCESS,
    goal,
  };
}

export function updateCourseRequest(id, data) {
  return {
    type: ActionTypes.UPDATE_COURSE_REQUEST,
    id,
    data,
  };
}
export function getCustomerCoursesRequest() {
  return {
    type: ActionTypes.GET_CUSTOMER_COURSES_REQUEST,
  };
}

export function getCustomerCoursesSuccess(courses) {
  return {
    type: ActionTypes.GET_CUSTOMER_COURSES_SUCCESS,
    courses,
  };
}

export function updateCourseStatusRequest(typeCourse) {
  return {
    type: ActionTypes.UPDATE_COURSE_STATUS_REQUEST,
    typeCourse,
  };
}
