export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        strengthName: 'Appreciation for Good',
        strengthDescription:
          '<p>When facing challenges, first imagine the best and worst scenarios before acting. Choose the most realistic course of action based upon what is most likely to occur. Compare the outcomes to obstacles handled this way to those that are dealt with in your usual manner.</p>',
      };
    case 2:
      return {
        strengthName: 'Curiosity',
        strengthDescription:
          '<p>Bring Curiosity into your interactions with others by trying to understand what they are thinking and feeling. Ask direct questions, such as ‘how are you feeling’ or ‘what do you think about this’. Be curious about how others perceive the people and places you encounter. Afterward, ask yourself what you learned from exploring these different perspectives on your journey.</p>',
      };
    case 3:
      return {
        strengthName: 'Gratitude',
        strengthDescription:
          '<p>In what ways is Gratitude used to foster connections in the communities you’ve visited? How does this affect culture, politics, and day-to-day life? In what ways might this help or hinder the well-being of the local people? What might you learn from these views on Gratitude?</p>',
      };
    case 4:
      return {
        strengthName: 'Love',
        strengthDescription:
          '<p>Carve out a few uninterrupted minutes each day to explore Love, either with yourself or with someone else. Reflect on the Love you’ve felt that day, on the specific actions of you’ve appreciated, and on the how you might share this Love with the greater world. During this time, be creative in the ways you express this Love, whether through touch, thought, word, or art.</p>',
      };
    case 5:
      return {
        strengthName: 'Open-Mindedness',
        strengthDescription:
          '<p>Many of us make a regular habit of critically evaluating others. But how often do we do this for ourselves? Use your Open-Mindedness to evaluate yourself from the perspective of others. Try and think critically about your personal qualities—strengths and weaknesses. Consider the pros and cons of these traits. Which ones are most beneficial to you? Which ones are most harmful? Are there any aspects of yourself that you’ve not previously seen, acknowledged, or accepted? How does this affect your connections with others?</p>',
      };
    case 6:
      return {
        strengthName: 'Appreciation for Good',
        strengthDescription:
          '<p>In what ways is an appreciation for good used to foster connections in the communities you visit? How do people respond to the love, support, and help they see and receive? Are these actions contagious or are they looked down upon? How does this relate to the general culture, politics, and day-to-day life? In what ways might this help or hinder the well-being of the local people? What might you learn from these views on the appreciation of good?</p>',
      };
  }
};
