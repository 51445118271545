import React, { Component } from 'react';
import { connect } from 'react-redux';
import InvoicesList from 'components/InvoicesList';
import * as invoiceActions from 'actions/invoiceActions';

class InvoiceContainer extends Component {
  componentDidMount() {
    this.props.onGetMeInvoices();
  }

  goTo = path => {
    const {
      history: { push },
    } = this.props;

    push(path);
  };

  render() {
    const { invoices, subscription, loading } = this.props;

    return <InvoicesList invoices={invoices} subscription={subscription} loading={loading} />;
  }
}

function mapStateToProps(state) {
  return {
    invoices: state.invoiceReducer.invoices,
    subscription: state.invoiceReducer.subscription,
    loading: state.invoiceReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onGetMeInvoices: () => dispatch(invoiceActions.getMeInvoices()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceContainer);
