/*
 * Reducer actions related with journal
 */
import { ActionTypes } from 'constants/index';

export function updateJournalRequest(data) {
  return {
    type: ActionTypes.UPDATE_JOURNAL_REQUEST,
    data,
  };
}

export function getJournalRequest(id) {
  return {
    type: ActionTypes.GET_JOURNAL_REQUEST,
    id,
  };
}
export function getJournalSuccess(journal) {
  return {
    type: ActionTypes.GET_JOURNAL_SUCCESS,
    journal,
  };
}
export function createDayCallingRequest(id, calling) {
  return {
    type: ActionTypes.CREATE_DAY_CALLING_REQUEST,
    id,
    calling,
  };
}

export function createDayDeaptureRequest(id, deapture) {
  return {
    type: ActionTypes.CREATE_DAY_DEAPTURE_REQUEST,
    id,
    deapture,
  };
}

export function createDayRoadRequest(id, road) {
  return {
    type: ActionTypes.CREATE_DAY_ROAD_REQUEST,
    id,
    road,
  };
}

export function createDayPreparingReturnRequest(id, preparingReturn) {
  return {
    type: ActionTypes.CREATE_DAY_PREPARING_RETURN_REQUEST,
    id,
    preparingReturn,
  };
}

export function createDayTheReturnRequest(id, returnData) {
  return {
    type: ActionTypes.CREATE_DAY_RETURN_REQUEST,
    id,
    returnData,
  };
}

export function getCallingRequest(data) {
  return {
    type: ActionTypes.GET_CALLING_REQUEST,
    data,
  };
}

export function getCallingSuccess(calling) {
  return {
    type: ActionTypes.GET_CALLING_SUCCESS,
    calling,
  };
}

export function getDeaptureRequest(data) {
  return {
    type: ActionTypes.GET_DEAPTURE_REQUEST,
    data,
  };
}

export function getDeaptureSuccess(deapture) {
  return {
    type: ActionTypes.GET_DEAPTURE_SUCCESS,
    deapture,
  };
}

export function getPreparingReturnRequest(data) {
  return {
    type: ActionTypes.GET_PREPARING_RETURN_REQUEST,
    data,
  };
}

export function getPreparingReturnSuccess(preparingReturn) {
  return {
    type: ActionTypes.GET_PREPARING_RETURN_SUCCESS,
    preparingReturn,
  };
}

export function getReturnRequest(data) {
  return {
    type: ActionTypes.GET_RETURN_REQUEST,
    data,
  };
}

export function getReturnSuccess(returnData) {
  return {
    type: ActionTypes.GET_RETURN_SUCCESS,
    returnData,
  };
}

export function getRoadRequest(data) {
  return {
    type: ActionTypes.GET_ROAD_REQUEST,
    data,
  };
}

export function getRoadSuccess(road) {
  return {
    type: ActionTypes.GET_ROAD_SUCCESS,
    road,
  };
}
