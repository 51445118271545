import React, { Component } from 'react';
import StepContainer from 'components/StepContainer';
import JournalDayStep from 'components/JournalDayStep';
import { goBack } from 'modules/history';

class HowUseView extends Component {
  render() {
    const { daysQuantity } = this.props;
    return (
      <StepContainer closeIcon width={'70%'} closeAction={() => goBack()}>
        <JournalDayStep {...this.props} headerTitle="The Calling" daysQuantity={daysQuantity} />
      </StepContainer>
    );
  }
}

export default HowUseView;
