import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'modules/theme';

import { Alert as AlertComponent, utils } from 'styled-minimal';
import Icon from 'components/Icon';

const { colors, palette } = utils.getTheme(theme);
const variants = { ...colors, ...palette };

AlertComponent.displayName = 'AlertComponent';

const AlertWrapper = styled(AlertComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-left: 6px;
  padding-right 6px;
  line-height: 1;
  position: relative;
`;

const AlertButton = styled.button`
  background-color: ${({ variant }) => variants[variant]};
  color: #ccc;
  display: flex;
  align-items: end;
  pointer-events: all;
  right: ${utils.spacer(1)};
  top: ${utils.spacer(1)};
  cursor: pointer;
`;
const Message = styled.div`
  width: ${props => (props.fullWidth ? '100%' : '95%')};
`;
const CloseButton = styled.div`
  width: 5%;
`;

const Alert = ({ children, handleClickClose, id, icon, ...rest }) => {
  const output = {};
  let name;

  return (
    <AlertWrapper {...rest} data-testid="AlertWrapper">
      <Message fullWidth={handleClickClose}>{children}</Message>
      <CloseButton>
        {handleClickClose && (
          <AlertButton
            data-id={id}
            onClick={handleClickClose}
            type="button"
            data-testid="AlertButton"
          >
            <Icon name="times" width={10} />
          </AlertButton>
        )}
      </CloseButton>
    </AlertWrapper>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  handleClickClose: PropTypes.func,
  icon: PropTypes.string,
  id: PropTypes.string,
  outline: PropTypes.bool,
  variant: PropTypes.string,
};

Alert.defaultProps = {
  outline: true,
  variant: 'info',
};

export default Alert;
