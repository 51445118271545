/*
 * Reducer actions related with diagnostic
 */
import { ActionTypes } from 'constants/index';

export function updateUserDiagnosticStep(id) {
  return {
    type: ActionTypes.UPDATE_USER_DIAGNOSTIC_STEP,
    id,
  };
}

export function changeCurrentStep(step) {
  return {
    type: ActionTypes.DIAGNOSTIC_CHANGE_CURRENT_STEP,
    step,
  };
}

// well being
export function saveWellBeing(wellBeings) {
  return {
    type: ActionTypes.DIAGNOSTIC_CREATE_WELL_BEING,
    wellBeings,
  };
}

export function getWellBeingDiagnostic() {
  return {
    type: ActionTypes.DIAGNOSTIC_GET_WELL_BEING,
  };
}

export function reciveWellBeing(wellBeings) {
  return {
    type: ActionTypes.DIAGNOSTIC_RECIVE_WELL_BEING,
    wellBeings,
  };
}

// strengths
export function saveStrengths(strengths) {
  return {
    type: ActionTypes.DIAGNOSTIC_CREATE_STRENGTHS,
    strengths,
  };
}

export function getStrengthsDiagnostic() {
  return {
    type: ActionTypes.DIAGNOSTIC_GET_STRENGTHS,
  };
}

export function reciveStrengths(strengths) {
  return {
    type: ActionTypes.DIAGNOSTIC_RECIVE_STRENGTHS,
    strengths,
  };
}

// Travel personality
export function saveTravelPersonality(travelPersonalities) {
  return {
    type: ActionTypes.DIAGNOSTIC_CREATE_TRAVEL_PERSONALITY,
    travelPersonalities,
  };
}

export function getTravelPersonalityDiagnostic() {
  return {
    type: ActionTypes.DIAGNOSTIC_GET_TRAVEL_PERSONALITY,
  };
}

export function reciveTravelPersonality(travelPersonalities) {
  return {
    type: ActionTypes.DIAGNOSTIC_RECIVE_TRAVEL_PERSONALITY,
    travelPersonalities,
  };
}

//profile
export function updateDiagnosticStepUser(diagnostic_step) {
  return {
    type: ActionTypes.UPDATE_DIAGNOSTIC_STEP_PERSIST,
    diagnostic_step,
  };
}
// meeting
export function updateMeeting(meeting) {
  return {
    type: ActionTypes.UPDATE_MEETING,
    meeting,
  };
}

export function updateMeetingSuccess(meeting) {
  return {
    type: ActionTypes.UPDATE_MEETING_SUCCESS,
    meeting,
  };
}

export function getPossibleMeetingHours(date) {
  return {
    type: ActionTypes.GET_POSSIBLE_MEETING_HOUR,
    date,
  };
}

export function recivePossibleHours(hours) {
  return {
    type: ActionTypes.RECIVE_POSSIBLE_MEETING_HOUR,
    hours,
  };
}

export function saveMeeting(date) {
  return {
    type: ActionTypes.SAVE_MEETING,
    date,
  };
}

export function saveCurrentMeetingId(id) {
  return {
    type: ActionTypes.SAVE_CURRENT_MEETING_ID,
    id,
  };
}
