import React, { Component } from 'react';
import GoalView from './GoalView';
import { connect } from 'react-redux';
import * as goalActions from 'actions/goalActions';

class GoalUpdateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 1 };
  }

  componentDidMount() {
    const {
      getGoalRequest,
      match: { params },
    } = this.props;
    getGoalRequest(params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.goal != this.props.goal) {
      const { goal } = this.props;
      if (goal) {
        this.setState({ ...goal });
      }
    }
  }

  changeStep = step => {
    this.setState({ step });
  };

  createGoals = () => {
    const { step, ...data } = this.state;
    const {
      updateGoalRequest,
      match: { params },
    } = this.props;
    updateGoalRequest(params.id, data);
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  render() {
    const { goal } = this.props;
    if (!goal) return null;
    const { step, ...data } = this.state;

    return (
      <GoalView
        goal={goal}
        step={this.state.step}
        handleChange={this.handleChange}
        changeStep={this.changeStep}
        data={data}
        createGoals={this.createGoals}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    goal: state.goalReducer.goal,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getGoalRequest: id => dispatch(goalActions.getGoalRequest(id)),
    updateGoalRequest: (id, data) => dispatch(goalActions.updateGoalRequest(id, data)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoalUpdateContainer);
