import React, { Component } from 'react';
import CitiesListView from './CitiesListView';
import { connect } from 'react-redux';
import * as citiesActions from 'actions/citiesActions';

class CitiesListContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10, removeModalIsOpen: false, citySelected: null };

  componentDidMount() {
    const {
      getAllCities,
      match: { params },
    } = this.props;

    const { currentPage, elementsPerPage } = this.state;

    if (params.country_id) {
      getAllCities(currentPage, elementsPerPage, params.country_id);
    } else {
      getAllCities(currentPage, elementsPerPage);
    }
  }

  changePage = currentPage => {
    const { elementsPerPage } = this.state;
    const {
      getAllCities,
      match: { params },
    } = this.props;
    if (params.country_id) {
      getAllCities(currentPage, elementsPerPage, params.country_id);
    }
    {
      getAllCities(currentPage, elementsPerPage);
    }
    getAllCities(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  toggleModal = () => {
    this.setState({ removeModalIsOpen: !this.state.removeModalIsOpen });
  };

  selectCity = citySelected => {
    this.setState({ citySelected }, () => this.toggleModal());
  };

  removeCity = () => {
    const { removeCities } = this.props;
    const { citySelected } = this.state;
    removeCities(citySelected);
    this.toggleModal();
  };

  render() {
    const { citiesCount, cities, loading } = this.props;
    const { currentPage, elementsPerPage, removeModalIsOpen } = this.state;
    return (
      <CitiesListView
        {...this.props}
        cities={cities}
        currentPage={currentPage}
        changePage={this.changePage}
        citiesCount={citiesCount}
        elementsPerPage={elementsPerPage}
        loading={loading}
        removeModalIsOpen={removeModalIsOpen}
        toggleModal={this.toggleModal}
        removeCity={this.removeCity}
        selectCity={this.selectCity}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    cities: state.citiesReducer.cities,
    citiesCount: state.citiesReducer.citiesCount,
    loading: state.citiesReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAllCities: (page, elementsPerPage, country_id) =>
      dispatch(citiesActions.getAllCities(page, elementsPerPage, country_id)),
    removeCities: id => dispatch(citiesActions.removeCities(id)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CitiesListContainer);
