import React, { Component } from 'react';
import DiagnosticResultsCustomerView from './DiagnosticResultsCustomerView';
import { connect } from 'react-redux';
import * as diagnosticActions from '../../actions/diagnosticActions';
import * as meetingActions from '../../actions/meetingActions';

class DiagnosticResultsCustomerContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      getWellBeingDiagnostic,
      getTravelPersonalityDiagnostic,
      getStrengthsDiagnostic,
      getInitialMeetingRequest,
    } = this.props;
    getWellBeingDiagnostic();
    getTravelPersonalityDiagnostic();
    getStrengthsDiagnostic();
    getInitialMeetingRequest();
  }

  render() {
    return <DiagnosticResultsCustomerView {...this.props} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInitialMeetingRequest: () => dispatch(meetingActions.getInitialMeetingRequest()),
    getStrengthsDiagnostic: () => dispatch(diagnosticActions.getStrengthsDiagnostic()),
    getWellBeingDiagnostic: () => dispatch(diagnosticActions.getWellBeingDiagnostic()),
    getTravelPersonalityDiagnostic: () =>
      dispatch(diagnosticActions.getTravelPersonalityDiagnostic()),
  };
}
export default connect(
  null,
  mapDispatchToProps,
)(DiagnosticResultsCustomerContainer);
