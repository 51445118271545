import React, { Component } from 'react';
import SelectMuseumView from './SelectMuseumView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import { MUSEUMS } from 'constants/index';

const museumMaping = Object.fromEntries(
  MUSEUMS.map(museum => [
    museum,
    museum
      .toLowerCase()
      .replaceAll(/\s/g, '_')
      .replaceAll('_&', ''),
  ]),
);

class SelectMuseumContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aquariums_zoos: 0,
      art_museums: 0,
      childrens_museums: 0,
      history_museums: 0,
      military_museums: 0,
      natural_history_museums: 0,
      other_museums: 0,
      science_museums: 0,
      speciality_museums: 0,
    };
  }
  componentDidMount() {
    const {
      aquariums_zoos,
      art_museums,
      childrens_museums,
      history_museums,
      military_museums,
      natural_history_museums,
      other_museums,
      science_museums,
      speciality_museums,
    } = this.props;
    this.setState({
      aquariums_zoos,
      art_museums,
      childrens_museums,
      history_museums,
      military_museums,
      natural_history_museums,
      other_museums,
      science_museums,
      speciality_museums,
    });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const {
      aquariums_zoos,
      art_museums,
      childrens_museums,
      history_museums,
      military_museums,
      natural_history_museums,
      other_museums,
      science_museums,
      speciality_museums,
    } = this.state;
    changeCurrentStep(step);
    saveTripData({
      musseum: {
        aquariums_zoos,
        art_museums,
        childrens_museums,
        history_museums,
        military_museums,
        natural_history_museums,
        other_museums,
        science_museums,
        speciality_museums,
      },
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <SelectMuseumView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        museumMaping={museumMaping}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    aquariums_zoos: state.tripReducer.tripForm.musseum.aquariums_zoos,
    art_museums: state.tripReducer.tripForm.musseum.art_museums,
    childrens_museums: state.tripReducer.tripForm.musseum.childrens_museums,
    history_museums: state.tripReducer.tripForm.musseum.history_museums,
    military_museums: state.tripReducer.tripForm.musseum.military_museums,
    natural_history_museums: state.tripReducer.tripForm.musseum.natural_history_museums,
    other_museums: state.tripReducer.tripForm.musseum.other_museums,
    science_museums: state.tripReducer.tripForm.musseum.science_museums,
    speciality_museums: state.tripReducer.tripForm.musseum.speciality_museums,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectMuseumContainer);
