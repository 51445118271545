import { put, call } from 'redux-saga/effects';
import * as diagnosticActions from '../actions/diagnosticActions';
import * as meetingActions from '../actions/meetingActions';

export function* saveWellBeing(api, action) {
  const response = yield call(api.saveWellBeing, action.wellBeings);
  if (response.status == 200) {
    yield put(diagnosticActions.updateUserDiagnosticStep(4));
    yield put(diagnosticActions.getWellBeingDiagnostic());
  }
}

export function* saveStrengths(api, action) {
  const response = yield call(api.saveStrengths, action.strengths);
  if (response.status == 200) {
    yield put(diagnosticActions.updateUserDiagnosticStep(6));
    yield put(diagnosticActions.getStrengthsDiagnostic());
  }
}

export function* saveTravelPersonality(api, action) {
  const response = yield call(api.saveTravelPersonality, action.travelPersonalities);
  if (response.status == 200) {
    yield put(diagnosticActions.updateUserDiagnosticStep(8));
    yield put(diagnosticActions.getTravelPersonalityDiagnostic());
  }
}
export function* getWellBeing(api) {
  const response = yield call(api.getWellBeing);
  if (response.status == 200 && response.data) {
    yield put(diagnosticActions.reciveWellBeing(formatResponseData(response.data)));
  }
}
export function* getSrengths(api) {
  const response = yield call(api.getSrengths);
  if (response.status == 200 && response.data) {
    yield put(diagnosticActions.reciveStrengths(formatResponseData(response.data)));
  }
}

export function* getTravelPersonality(api) {
  const response = yield call(api.getTravelPersonality);
  if (response.status == 200 && response.data) {
    yield put(diagnosticActions.reciveTravelPersonality(formatResponseData(response.data)));
  }
}

export function* updateDiagnosticStep(api, action) {
  const response = yield call(api.updateProfile, action.diagnostic_step);
  if (response.status == 200 && response.data) {
    yield put(diagnosticActions.updateUserDiagnosticStep(response.data.diagnostic_step));
  }
}

export function* getPossibleMeetingHours(api, action) {
  const response = yield call(api.getPossibleMeetingHours, action.date);
  if (response.status == 200 && response.data) {
    const { free_time_slots, meeting_duration } = response.data;
    const formatedData = [];
    free_time_slots.map(stringDate => {
      const start_date = new Date(stringDate);
      const end_date = new Date(stringDate);
      end_date.setMinutes(end_date.getMinutes() + meeting_duration);
      const formatedHour = `${toLocaleString(start_date)} - ${toLocaleString(end_date)}`;
      const timeSlot = { utcDate: stringDate, formatedHour };
      formatedData.push(timeSlot);
    });
    response.data.free_time_slots = formatedData;
    yield put(diagnosticActions.recivePossibleHours(response.data));
  }
}

export function* saveMeeting(api, action) {
  const response = yield call(api.saveMeeting, action.date);
  if (response.status == 200 && response.data) {
    //if the user decides to change the meeting time during the diagnosis, we need the id of the created meeting
    yield put(diagnosticActions.saveCurrentMeetingId(response.data.id));
    yield put(meetingActions.saveMeetingSuccess(response.data));
  }
}

function toLocaleString(date) {
  return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

export function* updateMeeting(api, action) {
  const response = yield call(api.updateMeeting, action.meeting);
  if (response.status == 200 && response.data) {
    yield put(diagnosticActions.updateMeetingSuccess(action.meeting));
  }
}

const formatResponseData = data => {
  const {
    user_id,
    created_at,
    id,
    updated_at,
    duration,
    ok,
    originalError,
    problem,
    status,
    ...newData
  } = data;
  return newData;
};
