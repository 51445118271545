import React, { Component } from 'react';
import LoginView from './LoginView';
import { connect } from 'react-redux';
import * as loginActions from '../../actions/loginActions';

class LoginContainer extends Component {
  facebookLogin = response => {
    this.props.onFacebookLogin(response.accessToken);
  };
  googleLogin = response => {
    this.props.onGoogleLogin(response.tokenId);
  };
  emailLogin = (email, password, remember) => {
    this.props.onEmailLogin(email, password, remember);
  };
  render() {
    const { isLoggedIn, email, password, remember, loading } = this.props;

    return (
      <LoginView
        emailLogin={this.emailLogin}
        facebookLogin={this.facebookLogin}
        googleLogin={this.googleLogin}
        isLoggedIn={isLoggedIn}
        email={email}
        password={password}
        remember={remember}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.loginReducer.isLoggedIn,
    email: state.loginReducer.email,
    password: state.loginReducer.password,
    remember: state.loginReducer.remember,
    loading: state.loginReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onEmailLogin: (email, password, remember) =>
      dispatch(loginActions.requestEmailLogin(email, password, remember)),
    onFacebookLogin: accessToken => dispatch(loginActions.requestFacebookLogin(accessToken)),
    onGoogleLogin: accessToken => dispatch(loginActions.requestGoogleLogin(accessToken)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginContainer);
