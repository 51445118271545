import React, { Component } from 'react';
import SelectShoppingView from './SelectShoppingView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import { SHOPPING } from 'constants/index';

const shoppingMaping = Object.fromEntries(
  SHOPPING.map(shopping => [
    shopping,
    shopping
      .toLowerCase()
      .replaceAll(/\s/g, '_')
      .replaceAll('_&', '')
      .replaceAll(',', '')
      .replaceAll('/', '_'),
  ]),
);

class SelectShoppingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artisan_shops_specialty_stores: 0,
      department_stores: 0,
      food_drink: 0,
      malls: 0,
      markets: 0,
      other_shooping: 0,
      shopping_districts_streets: 0,
    };
  }
  componentDidMount() {
    const {
      artisan_shops_specialty_stores,
      department_stores,
      food_drink,
      malls,
      markets,
      other_shooping,
      shopping_districts_streets,
    } = this.props;
    this.setState({
      artisan_shops_specialty_stores,
      department_stores,
      food_drink,
      malls,
      markets,
      other_shooping,
      shopping_districts_streets,
    });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const {
      artisan_shops_specialty_stores,
      department_stores,
      food_drink,
      malls,
      markets,
      other_shooping,
      shopping_districts_streets,
    } = this.state;
    changeCurrentStep(step);
    saveTripData({
      shopping: {
        artisan_shops_specialty_stores,
        department_stores,
        food_drink,
        malls,
        markets,
        other_shooping,
        shopping_districts_streets,
      },
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <SelectShoppingView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        shoppingMaping={shoppingMaping}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    artisan_shops_specialty_stores:
      state.tripReducer.tripForm.shopping.artisan_shops_specialty_stores,
    department_stores: state.tripReducer.tripForm.shopping.department_stores,
    food_drink: state.tripReducer.tripForm.shopping.food_drink,
    malls: state.tripReducer.tripForm.shopping.malls,
    markets: state.tripReducer.tripForm.shopping.markets,
    other_shooping: state.tripReducer.tripForm.shopping.other_shooping,
    shopping_districts_streets: state.tripReducer.tripForm.shopping.shopping_districts_streets,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectShoppingContainer);
