/*
 * Reducer actions related with customers
 */
import { ActionTypes } from 'constants/index';

export function getCustomers(page, elementsPerPage, search) {
  return {
    type: ActionTypes.GET_CUSTOMERS,
    page,
    elementsPerPage,
    search,
  };
}

export function getFriends(search) {
  return {
    type: ActionTypes.GET_FRIENDS,
    search,
  };
}

export function getFriendsSuccess(friends) {
  return {
    type: ActionTypes.GET_FRIENDS_SUCCESS,
    friends,
  };
}

export function getCustomer(id) {
  return {
    type: ActionTypes.GET_CUSTOMER,
    id,
  };
}

export function getCustomerSuccess(customer) {
  return {
    type: ActionTypes.GET_CUSTOMER_SUCCESS,
    customer,
  };
}

export function saveCustomers(customers) {
  return {
    type: ActionTypes.SAVE_CUSTOMERS,
    customers: customers.rows,
    customersCount: customers.count,
  };
}
