import React from 'react';
import styled from 'styled-components';

const ActivityButton = styled.a`
  width: 270px;
  display: inline-block;
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 300;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.01);
  border-radius: 4px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  cursor: pointer;
  &: hover {
    background: #00b4d51b;
    color: rgb(15, 203, 255);
    border-color: rgb(15, 203, 255);
    opacity: 0.4;
  }
  background: ${props => (props.selected ? '#00b4d51b' : 'white')};
  color: ${props => (props.selected ? 'rgb(15, 203, 255)' : '#9e9e9e')};
  border-color: ${props => (props.selected ? 'rgb(15, 203, 255)' : '#9e9e9e')};
  border: ${props => (props.selected ? '2px solid;' : '1px solid;')};
  margin: 15px;
`;

const Name = styled.p`
  color: #3e3e3e;
  font-size: 18px;
  margin: 0;
  text-align: initial;
  font-weight: normal;
`;
const Date = styled.p`
  color: #9e9e9e;
  text-align: initial;
`;

const Description = styled.p`
  color: ${props => props.color};
  text-align: initial;
  font-weight: normal;
  margin-bottom: 0px;
`;
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const IconBox = styled.div`
  background-color: ${props => props.color};
  border-radius: 25px;
  height: 32px;
`;

const Actions = styled.div`
  display: flex;
  float: right;
`;
const ActionButton = styled.i`
  width: 20px;
  margin-left: 10px;
`;
export default props => {
  let icon, color;
  switch (props.category) {
    case 'restaurant':
      icon = 'fas fa-utensils';
      color = '#EF4B8D';
      break;
    case 'accommodation':
      icon = 'fas fa-bed';
      color = '#4CB0E8';
      break;
    default:
      icon = 'fas fa-route';
      color = '#3CA555';
      break;
  }
  return (
    <ActivityButton selected={props.selected} onClick={props.action}>
      <FlexBox>
        <Name>{props.name}</Name>
        <IconBox color={`${color}33`}>
          <i className={icon} style={{ padding: '9px', color }} />
        </IconBox>
      </FlexBox>
      <Date>{props.hour}</Date>
      <Description color={color}>
        {props.description.length > 300
          ? `${props.description.substring(0, 300)}...`
          : props.description}
      </Description>
      <Actions>
        <ActionButton>
          <i className="far fa-trash-alt" style={{ color: 'red' }} onClick={props.deleteAction}></i>
        </ActionButton>
      </Actions>
    </ActivityButton>
  );
};
