import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import StepContainer from '../../StepContainer';
import StepButtons from '../../StepButtons';
import Button from 'components/Button';
import '../styles.css';

const PlanMeetingBox = styled.div`
  display: flex;
  margin: 25px 23%;
  flex-direction: column;
  @media (max-width: 1000px) {
    margin-left: 15%;
    margin-right: 15%;
  }
`;

const Select = styled.select`
  background: white;
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  height: 44px;
  margin-top: 15px;
  padding: 10px;
  width: 100%;
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const PrimaryText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 25px;
  color: ${props => props.color};
  text-align: center;
`;

const DatePickerBox = styled.p`
  margin: 35px 30%;
`;

const SecondaryText = styled.p`
  color: #696969;
  text-align: center;
  margin-top: 0;
`;

const ButtonBox = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -75px;
  top: 50%;
  margin-top: -25px;
`;

const PlanMeetingContainer = styled.div`
  min-height: 500px;
`;

class PlanMeetingView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const datePickers = document.getElementsByClassName('react-datepicker__input-container');
    Array.from(datePickers).forEach(el => el.childNodes[0].setAttribute('repreTripCoursely', true));
  }

  render() {
    const { changeStep, state, changeDate, changeHour, possibleHours, editMeeting } = this.props;
    const { localStep, selectedDate, hour } = state;

    return (
      <>
        <StepContainer>
          <PlanMeetingContainer>
            {localStep == 2 ? (
              <>
                <PrimaryText color="#353535">
                  You've Succesfully Booked Our One-on-One Discussion
                </PrimaryText>
                <SecondaryText>
                  Check your inbox for a confirmation email. If you need to change the time of our
                  discussion,
                  <br />
                  <a style={{ cursor: 'pointer' }} onClick={editMeeting}>
                    click here
                  </a>
                  .
                </SecondaryText>
                <Link to={'/'}>
                  <ButtonBox>
                    <Button>Go to Dashboard</Button>
                  </ButtonBox>
                </Link>
              </>
            ) : (
              <PlanMeetingBox>
                <PrimaryText color="#353535">Book Our One-on-One Discussion</PrimaryText>
                <SecondaryText>
                  Let's schedule a time to talk about your results and determine what your next
                  steps should be.
                </SecondaryText>
                <DatePickerBox>
                  <PrimaryText color="#696969">Book a meeting</PrimaryText>
                  <DatePicker
                    placeholderText="Select Date"
                    className="datepicker"
                    showPopperArrow={false}
                    selected={selectedDate}
                    onChange={date => changeDate(date)}
                    minDate={moment()
                      .add(3, 'd')
                      .toDate()}
                  />
                  <Select name="hour" onChange={changeHour}>
                    <option className="selectPlaceholder" value="" disabled selected hidden>
                      Select Hour
                    </option>
                    {possibleHours &&
                      possibleHours.free_time_slots.map((hour, key) => (
                        <option key={key} value={hour.utcDate}>
                          {hour.formatedHour}
                        </option>
                      ))}
                  </Select>
                </DatePickerBox>
              </PlanMeetingBox>
            )}
            {localStep == 1 && (
              <StepButtons
                nextDisabled={!selectedDate || !hour}
                customNextText="Book Discussion"
                backStep={() => changeStep(localStep - 1)}
                nextStep={() => changeStep(localStep + 1)}
              />
            )}
          </PlanMeetingContainer>
        </StepContainer>
      </>
    );
  }
}

export default PlanMeetingView;
