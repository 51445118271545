import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';

const data = {
  labels: ['Mindfulness', 'Confidence', 'Resilience', 'Anti-Fragility', 'Purpose'],
  datasets: [
    {
      label: 'Measurement of Foundational Strengths Components',
      backgroundColor: '#38b6ff',
      borderColor: '#13a2f4',
      borderWidth: 1,
      hoverBackgroundColor: '#999999',
      hoverBorderColor: 'grey',
    },
  ],
};

const options = {
  scales: {
    xAxes: [
      {
        ticks: {
          min: 0,
          max: 10,
          callback: function(value, index) {
            return index;
          },
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
};

const FoundationalStrengthsChart = props => {
  data.datasets[0].data = props.fundationalStrengthsResult;
  return <HorizontalBar data={data} width={100} height={65} options={options} />;
};

export default FoundationalStrengthsChart;
