import ApiConstants from './ApiConstants';
import apisauce from 'apisauce';
import config from 'config/appConfig';

const create = (baseURL = ApiConstants.BASE_API) => {
  const api = apisauce.create({
    baseURL,
  });
  // api
  const setAuthorization = token => api.setHeader('Authorization', token);
  const removeAuthToken = () => api.deleteHeader('Authorization');

  // customers
  const getCustomers = params => {
    return api.get(ApiConstants.GET_CUSTOMERS, params);
  };

  const getCustomer = id => {
    return api.get(`${ApiConstants.GET_CUSTOMER}/${id}`);
  };

  //friends
  const getFriends = params => {
    return api.get(ApiConstants.GET_FRIENDS, params);
  };

  // login
  const loginEmail = data => {
    return api.post(ApiConstants.LOGIN_EMAIL, data);
  };

  const loginFacebok = data => {
    return api.post(ApiConstants.LOGIN_FACEBOOK, { accessToken: data });
  };

  const loginGoogle = data => {
    return api.post(ApiConstants.LOGIN_GOOGLE, { accessToken: data });
  };

  const recoverPasswordRequest = data => {
    return api.post(ApiConstants.RECOVER_PASSWORD, { email: data });
  };

  const resetPasswordRequest = data => {
    return api.post(ApiConstants.RESET_PASSWORD, {
      token: data.token_pass_recovery,
      password: data.password,
    });
  };

  // register
  const registerRequest = data => {
    return api.post(ApiConstants.REGISTER_REQUEST, data);
  };

  //  well being
  const saveWellBeing = data => {
    return api.post(ApiConstants.DIAGNOSTIC_SAVE_WELL_BEING, data);
  };

  const getWellBeing = () => {
    return api.get(ApiConstants.DIAGNOSTIC_GET_WELL_BEING);
  };

  // strengths
  const saveStrengths = data => {
    return api.post(ApiConstants.DIAGNOSTIC_SAVE_STRENGTHS, data);
  };

  const getSrengths = () => {
    return api.get(ApiConstants.DIAGNOSTIC_GET_STRENGTHS);
  };

  // travel personality
  const saveTravelPersonality = data => {
    return api.post(ApiConstants.DIAGNOSTIC_SAVE_TRAVEL_PERSONALITY, data);
  };

  const getTravelPersonality = () => {
    return api.get(ApiConstants.DIAGNOSTIC_GET_TRAVEL_PERSONALITY);
  };

  // products
  const getAvailableProducts = params => {
    return api.get(ApiConstants.GET_AVAILABLE_PRODUCTS, params);
  };

  // BoutiquePackage
  const getRequestsBoutiquePackages = params => {
    return api.get(`${ApiConstants.BOUTIQUE_PACKAGE}`, params);
  };

  const getRequestBoutiquePackage = id => {
    return api.get(`${ApiConstants.BOUTIQUE_PACKAGE}/${id}`, params);
  };

  const getMyRequestBoutiquePackage = id => {
    return api.get(`${ApiConstants.BOUTIQUE_PACKAGE}/me`);
  };

  const saveRequestBoutiquePackage = data => {
    return api.post(`${ApiConstants.BOUTIQUE_PACKAGE}`, data);
  };

  const updateRequestBoutiquePackage = (id, data) => {
    return api.put(`${ApiConstants.BOUTIQUE_PACKAGE}/${id}`, data);
  };
  const customerApprobeBoutiquePackageRequest = () => {
    return api.post(`${ApiConstants.BOUTIQUE_PACKAGE}/user-apporve`);
  };
  //profile
  const updateProfile = params => {
    return api.put(ApiConstants.UPDATE_PROFILE, params);
  };

  const verifyDegradePlanRequest = () => {
    return api.get(`${ApiConstants.UPDATE_PROFILE}/can-degrade-plan`);
  };
  // meeting
  const getPossibleMeetingHours = params => {
    return api.get(ApiConstants.GET_POSSIBLE_MEETING_HOUR, params);
  };

  const saveMeeting = data => {
    return api.post(ApiConstants.MEETING, data);
  };

  const getMeetingRequest = id => {
    return api.get(`${ApiConstants.MEETING}/${id}`);
  };

  const getInitialMeetingRequest = () => {
    return api.get(`${ApiConstants.MEETING}/initial`);
  };

  const updateMeeting = data => {
    return api.put(`${ApiConstants.MEETING}/${data.id}`, data);
  };

  // trip
  const getDestinations = params => {
    return api.get(ApiConstants.GET_DESTINATIONS, params);
  };

  const persistTrip = (params, customerId) => {
    return api.post(`${ApiConstants.TRIP}/${customerId ? customerId : 'create'}`, params);
  };

  const getMeTrips = () => {
    return api.get(`${ApiConstants.TRIP}/me`);
  };

  const getTrip = id => {
    return api.get(`${ApiConstants.TRIP}/${id}`);
  };

  const editTrip = (params, id, customerId) => {
    return api.put(`${ApiConstants.TRIP}/${id}`, params);
  };

  const editTripSimple = (params, id) => {
    return api.put(`${ApiConstants.TRIP}/edit-simple/${id}`, params);
  };

  const createTripChargeRequest = id => {
    return api.post(`${ApiConstants.TRIP}/create-charge/${id}`);
  };

  const getAllTripsRequest = (page, elementsPerPage) => {
    return api.get(`${ApiConstants.TRIP}`, page, elementsPerPage);
  };

  //Trip Costs
  const getCosts = id => {
    return api.get(`${ApiConstants.GET_COSTS}/${id}`);
  };

  const updateCosts = (id, data) => {
    return api.put(`${ApiConstants.UPDATE_COST}/${id}`, data);
  };

  // payment methods
  const getPaymentMethods = () => {
    return api.get(`${ApiConstants.PAYMENT_METHODS}`);
  };

  const createPaymentMethod = data => {
    return api.post(`${ApiConstants.PAYMENT_METHODS}`, data);
  };

  const deletePaymentMethod = data => {
    return api.delete(`${ApiConstants.PAYMENT_METHODS}/${data.card_id}`);
  };

  const setDefaultMethod = data => {
    return api.put(`${ApiConstants.SET_DEFAULT_METHODS}`, data);
  };

  const getMapboxCoordinates = address => {
    return api.get(
      `${ApiConstants.MAPBOX_GEOCODE}/${address}.json?limit=6&access_token=${config.MAPBOX_ACCESS_TOKEN}`,
    );
  };

  const getMapboxAddress = address => {
    return api.get(
      `${ApiConstants.MAPBOX_GEOCODE}/${address.lng},${address.lat}.json?access_token=${config.MAPBOX_ACCESS_TOKEN}`,
    );
  };

  const getMapboxCities = address => {
    return api.get(
      `${ApiConstants.MAPBOX_GEOCODE}/${address}.json?types=place&access_token=${config.MAPBOX_ACCESS_TOKEN}`,
    );
  };
  // suggestions
  const getCountries = params => {
    return api.get(ApiConstants.COUNTRIES, params);
  };

  const getCountry = id => {
    return api.get(`${ApiConstants.COUNTRIES}/${id}`);
  };

  const getCountriesForSelect = () => {
    return api.get(`${ApiConstants.COUNTRIES}/for-select`);
  };

  const getVisit = id => {
    return api.get(`${ApiConstants.VISIT_SUGGESTIONS}/${id}`);
  };

  const saveVisit = data => {
    return api.post(`${ApiConstants.VISIT_SUGGESTIONS}`, data);
  };

  const getAverageDailyExpenses = id => {
    return api.get(`${ApiConstants.AVERAGE_DAILY_EXPENSES}/${id}`);
  };

  const saveAverageDailyExpenses = data => {
    return api.post(`${ApiConstants.AVERAGE_DAILY_EXPENSES}`, data);
  };

  const getAccommodationPricing = id => {
    return api.get(`${ApiConstants.ACCOMMODATION_PRICINGS}/${id}`);
  };

  const saveAccommodationPricing = data => {
    return api.post(`${ApiConstants.ACCOMMODATION_PRICINGS}`, data);
  };

  const getClimate = params => {
    return api.get(`${ApiConstants.CLIMATES}`, params);
  };

  const saveClimate = data => {
    return api.post(`${ApiConstants.CLIMATES}`, data);
  };

  const editClimate = (id, data) => {
    return api.put(`${ApiConstants.CLIMATES}/${id}`, data);
  };

  const deleteClimate = id => {
    return api.delete(`${ApiConstants.CLIMATES}/${id}`);
  };

  const getLifecycles = params => {
    return api.get(`${ApiConstants.LIFECYCLES}`, params);
  };

  const getLifecycle = id => {
    return api.get(`${ApiConstants.LIFECYCLES}/${id}`);
  };

  const deleteLifecycle = id => {
    return api.delete(`${ApiConstants.LIFECYCLES}/${id}`);
  };

  const createLifecycle = data => {
    return api.post(`${ApiConstants.LIFECYCLES}`, data);
  };

  const editLifecycle = (id, data) => {
    return api.put(`${ApiConstants.LIFECYCLES}/${id}`, data);
  };

  const getCuisineSpecialities = params => {
    return api.get(`${ApiConstants.CUISINE_SPECIALITIES}`, params);
  };

  const getCuisineSpeciality = id => {
    return api.get(`${ApiConstants.CUISINE_SPECIALITIES}/${id}`);
  };

  const deleteCuisineSpeciality = id => {
    return api.delete(`${ApiConstants.CUISINE_SPECIALITIES}/${id}`);
  };

  const createCuisineSpeciality = data => {
    return api.post(`${ApiConstants.CUISINE_SPECIALITIES}`, data);
  };

  const editCuisineSpeciality = (id, data) => {
    return api.put(`${ApiConstants.CUISINE_SPECIALITIES}/${id}`, data);
  };

  // activities
  const getActivities = params => {
    return api.get(ApiConstants.ACTIVITIES, params);
  };

  const createActivities = data => {
    return api.post(ApiConstants.ACTIVITIES, data);
  };

  const updateActivities = (id, data) => {
    return api.put(`${ApiConstants.ACTIVITIES}/${id}`, data);
  };

  const getActivity = (id, data) => {
    return api.get(`${ApiConstants.ACTIVITIES}/${id}`, data);
  };

  const deleteActivity = id => {
    return api.delete(`${ApiConstants.ACTIVITIES}/${id}`);
  };

  const saveActivity = activity => {
    return api.post(`${ApiConstants.ACTIVITIES}`, activity);
  };

  const getMapboxDistanceTime = (points, type) => {
    const promiseDirections = [];
    let URL;
    if (type == 'driving') {
      URL = ApiConstants.MAPBOX_DIRECTIONS_DRIVING;
    } else {
      URL = ApiConstants.MAPBOX_DIRECTIONS_WALKING;
    }
    for (let step = 0; step < points.length - 1; step++) {
      const from = points[step];
      const to = points[step + 1];
      promiseDirections.push(
        api.get(
          `${URL}/${from.lng},${from.lat};${to.lng},${to.lat}?access_token=${config.MAPBOX_ACCESS_TOKEN}`,
        ),
      );
    }

    return Promise.all(promiseDirections);
  };

  // restaurants
  const getRestaurants = params => {
    return api.get(ApiConstants.RESTAURANTS, params);
  };

  const createRestaurants = data => {
    return api.post(ApiConstants.RESTAURANTS, data);
  };

  const updateRestaurants = (id, data) => {
    return api.put(`${ApiConstants.RESTAURANTS}/${id}`, data);
  };

  const getRestaurant = (id, data) => {
    return api.get(`${ApiConstants.RESTAURANTS}/${id}`, data);
  };

  const deleteRestaurant = id => {
    return api.delete(`${ApiConstants.RESTAURANTS}/${id}`);
  };

  const saveRestaurant = restaurant => {
    return api.post(`${ApiConstants.RESTAURANTS}`, restaurant);
  };

  // accommodations
  const getAccommodations = params => {
    return api.get(ApiConstants.ACCOMMODATIONS, params);
  };

  const createAccommodations = data => {
    return api.post(ApiConstants.ACCOMMODATIONS, data);
  };

  const updateAccommodations = (id, data) => {
    return api.put(`${ApiConstants.ACCOMMODATIONS}/${id}`, data);
  };

  const getAccommodation = (id, data) => {
    return api.get(`${ApiConstants.ACCOMMODATIONS}/${id}`, data);
  };

  const deleteAccommodation = id => {
    return api.delete(`${ApiConstants.ACCOMMODATIONS}/${id}`);
  };

  const saveAccommodation = restaurant => {
    return api.post(`${ApiConstants.ACCOMMODATIONS}`, restaurant);
  };

  // itinerary
  const getActivitiesByCity = id => {
    return api.get(`${ApiConstants.ACTIVITIES_BY_CITY}/${id}`);
  };
  const getRestaurantsByCity = id => {
    return api.get(`${ApiConstants.RESTAURANTS_BY_CITY}/${id}`);
  };
  const getAccommodationsByCity = id => {
    return api.get(`${ApiConstants.ACCOMMODATIONS_BY_CITY}/${id}`);
  };

  const saveItinerary = itinerary => {
    return api.post(`${ApiConstants.ITINERARY}`, itinerary);
  };

  const getItinerary = id => {
    return api.get(`${ApiConstants.ITINERARY}/${id}`);
  };

  const markItineraryAsDefault = data => {
    return api.post(`${ApiConstants.ITINERARY}/mark-as-default`, data);
  };

  const generateItineraryRequest = itinerary => {
    return api.post(`${ApiConstants.ITINERARY}/generate`, itinerary);
  };
  //transactions
  const getMeTransactions = () => {
    return api.get(`${ApiConstants.TRANSACTION}/me`);
  };
  //invoices
  const getMeInvoices = () => {
    return api.get(`${ApiConstants.INVOICE}/me`);
  };

  //preTripCourses
  const createPreTripCourse = data => {
    return api.post(`${ApiConstants.PRE_TRIP_COURSE}`, data);
  };

  const getPreTripCourses = params => {
    return api.get(`${ApiConstants.PRE_TRIP_COURSE}`, params);
  };

  const getPreTripCourse = id => {
    return api.get(`${ApiConstants.PRE_TRIP_COURSE}/${id}`);
  };

  const updatePreTripCourse = (id, preTripCourse) => {
    return api.put(`${ApiConstants.PRE_TRIP_COURSE}/${id}`, preTripCourse);
  };

  const buyPreTripCourse = preTripCourse => {
    return api.post(`${ApiConstants.PRE_TRIP_COURSE}/buy`, {
      ...preTripCourse,
      preTripCourseId: preTripCourse.id,
    });
  };

  const deletePreTripCourse = id => {
    return api.delete(`${ApiConstants.PRE_TRIP_COURSE}/${id}`);
  };

  const purchasePreTripCourses = (page, elementsPerPage) => {
    return api.get(`${ApiConstants.PURCHASED_PRE_TRIP_COURSES}`, page, elementsPerPage);
  };

  const allPurchasePreTripCourses = (page, elementsPerPage) => {
    return api.get(`${ApiConstants.PURCHASED_PRE_TRIP_COURSES}/all`, page, elementsPerPage);
  };
  //schedule calls
  const getMeetings = params => {
    return api.get(`${ApiConstants.MEETING}/me`, params);
  };

  //journal
  const updateJournal = (id, journal) => {
    return api.put(`${ApiConstants.JOURNALS}/${id}`, journal);
  };

  const getJournal = id => {
    return api.get(`${ApiConstants.JOURNALS}/${id}`);
  };

  const createDayCalling = (id, journalCalling) => {
    return api.put(`${ApiConstants.JOURNAL_CALLINGS}/${id}`, journalCalling);
  };

  const createDayDeapture = (id, journalDeapture) => {
    return api.put(`${ApiConstants.JOURNAL_DEAPTURES}/${id}`, journalDeapture);
  };

  const createDayPreparingReturn = (id, journalPreparingReturn) => {
    return api.put(`${ApiConstants.JOURNAL_PREPARING_RETURNS}/${id}`, journalPreparingReturn);
  };

  const createDayReturn = (id, journalReturn) => {
    return api.put(`${ApiConstants.JOURNAL_RETURNS}/${id}`, journalReturn);
  };

  const createDayRoad = (id, journalRoad) => {
    return api.put(`${ApiConstants.JOURNAL_ROADS}/${id}`, journalRoad);
  };

  const getCallingRequest = data => {
    return api.get(`${ApiConstants.JOURNAL_CALLINGS}`, data);
  };

  const getDeaptureRequest = data => {
    return api.get(`${ApiConstants.JOURNAL_DEAPTURES}`, data);
  };

  const getPreparingReturnRequest = data => {
    return api.get(`${ApiConstants.JOURNAL_PREPARING_RETURNS}`, data);
  };

  const getReturnRequest = data => {
    return api.get(`${ApiConstants.JOURNAL_RETURNS}`, data);
  };

  const getRoadRequest = data => {
    return api.get(`${ApiConstants.JOURNAL_ROADS}`, data);
  };

  //authentic plan
  const createAuthenticPlanRequest = data => {
    return api.post(`${ApiConstants.AT_PLANS}`, data);
  };

  const getAutehnticPlanRequest = id => {
    return api.get(`${ApiConstants.AT_PLANS}/${id}`);
  };

  const updateAutehnticPlanRequest = (id, data) => {
    return api.put(`${ApiConstants.AT_PLANS}/${id}`, data);
  };

  //goal
  const createGoalRequest = data => {
    return api.post(`${ApiConstants.GOALS}`, data);
  };

  const getGoalRequest = id => {
    return api.get(`${ApiConstants.GOALS}/${id}`);
  };

  const updateGoalRequest = (id, data) => {
    return api.put(`${ApiConstants.GOALS}/${id}`, data);
  };

  //courses

  const createCourseRequest = data => {
    return api.post(`${ApiConstants.COURSES}`, data);
  };

  const getCourseRequest = id => {
    return api.get(`${ApiConstants.COURSES}/${id}`);
  };

  const updateCourseRequest = (id, data) => {
    return api.put(`${ApiConstants.COURSES}/${id}`, data);
  };

  const getCustomerCoursesRequest = () => {
    return api.get(`${ApiConstants.COURSES}/my-courses`);
  };

  const updateCourseStatusRequest = type => {
    return api.put(`${ApiConstants.COURSES}/complete/${type}`);
  };

  //cities
  const createCities = data => {
    return api.post(`${ApiConstants.CITIES}`, data);
  };

  const getAllCities = params => {
    return api.get(`${ApiConstants.CITIES}`, params);
  };

  const getCities = id => {
    return api.get(`${ApiConstants.CITIES}/${id}`);
  };

  const editCities = (id, city) => {
    return api.put(`${ApiConstants.CITIES}/${id}`, city);
  };

  const removeCities = (id, city) => {
    return api.delete(`${ApiConstants.CITIES}/${id}`, city);
  };

  const getCitiesForSelect = params => {
    return api.get(`${ApiConstants.CITIES}/for-select`, params);
  };

  //regions
  const getRegionsForSelect = params => {
    return api.get(`${ApiConstants.REGIONS}/for-select`, params);
  };

  const createRegions = data => {
    return api.post(`${ApiConstants.REGIONS}`, data);
  };

  const getAllRegions = params => {
    return api.get(`${ApiConstants.REGIONS}`, params);
  };

  const getRegions = id => {
    return api.get(`${ApiConstants.REGIONS}/${id}`);
  };

  const editRegions = (id, region) => {
    return api.put(`${ApiConstants.REGIONS}/${id}`, region);
  };

  const removeRegions = (id, region) => {
    return api.delete(`${ApiConstants.REGIONS}/${id}`, region);
  };

  return {
    // api
    setAuthorization,
    removeAuthToken,
    // customers
    getCustomers,
    getCustomer,
    //friends
    getFriends,
    // login
    loginEmail,
    loginFacebok,
    loginGoogle,
    recoverPasswordRequest,
    resetPasswordRequest,
    // register
    registerRequest,
    getAvailableProducts,
    // BoutiquePackage
    getRequestsBoutiquePackages,
    getRequestBoutiquePackage,
    getMyRequestBoutiquePackage,
    saveRequestBoutiquePackage,
    updateRequestBoutiquePackage,
    customerApprobeBoutiquePackageRequest,
    // well being
    saveWellBeing,
    getWellBeing,
    // strengths
    saveStrengths,
    getSrengths,
    // travel personality
    saveTravelPersonality,
    getTravelPersonality,
    //porfile
    updateProfile,
    verifyDegradePlanRequest,
    // meeting
    updateMeeting,
    getPossibleMeetingHours,
    saveMeeting,
    getMeetingRequest,
    getInitialMeetingRequest,
    // trip
    getDestinations,
    persistTrip,
    getTrip,
    getMeTrips,
    editTrip,
    editTripSimple,
    createTripChargeRequest,
    getAllTripsRequest,
    //CostTrips
    getCosts,
    updateCosts,
    // payment mmethods
    getPaymentMethods,
    createPaymentMethod,
    deletePaymentMethod,
    setDefaultMethod,
    // suggestions
    getCountries,
    getCountry,
    getVisit,
    saveVisit,
    getAverageDailyExpenses,
    saveAverageDailyExpenses,
    getAccommodationPricing,
    saveAccommodationPricing,
    getClimate,
    saveClimate,
    editClimate,
    deleteClimate,
    getLifecycles,
    getLifecycle,
    deleteLifecycle,
    createLifecycle,
    editLifecycle,
    getCuisineSpecialities,
    getCuisineSpeciality,
    deleteCuisineSpeciality,
    createCuisineSpeciality,
    editCuisineSpeciality,
    getCountriesForSelect,
    // activities
    getMapboxCoordinates,
    getActivities,
    createActivities,
    updateActivities,
    getMapboxAddress,
    getMapboxCities,
    saveActivity,
    getActivity,
    deleteActivity,
    getMapboxDistanceTime,

    //restaurants
    //getMapboxCoordinates,
    getRestaurants,
    createRestaurants,
    updateRestaurants,
    // getMapboxAddress,
    //getMapboxCities,
    saveRestaurant,
    getRestaurant,
    deleteRestaurant,
    //getMapboxDistanceTime,

    //accommodations
    //getMapboxCoordinates,

    getAccommodations,
    createAccommodations,
    updateAccommodations,
    // getMapboxAddress,
    //getMapboxCities,
    saveAccommodation,
    getAccommodation,
    deleteAccommodation,
    //getMapboxDistanceTime,

    //itinerary
    getActivitiesByCity,
    getRestaurantsByCity,
    getAccommodationsByCity,
    saveItinerary,
    getItinerary,
    markItineraryAsDefault,
    generateItineraryRequest,
    //transacations
    getMeTransactions,
    //invoices
    getMeInvoices,
    //add PreTripCourses
    createPreTripCourse,
    getPreTripCourses,
    getPreTripCourse,
    updatePreTripCourse,
    deletePreTripCourse,
    buyPreTripCourse,
    purchasePreTripCourses,
    allPurchasePreTripCourses,
    // schedule calls
    getMeetings,
    // journals
    updateJournal,
    getJournal,
    createDayCalling,
    createDayDeapture,
    createDayPreparingReturn,
    createDayReturn,
    createDayRoad,
    getCallingRequest,
    getDeaptureRequest,
    getPreparingReturnRequest,
    getReturnRequest,
    getRoadRequest,
    //autentic plan
    createAuthenticPlanRequest,
    getAutehnticPlanRequest,
    updateAutehnticPlanRequest,
    //goal
    createGoalRequest,
    getGoalRequest,
    updateGoalRequest,
    //courses
    createCourseRequest,
    getCourseRequest,
    updateCourseRequest,
    getCustomerCoursesRequest,
    updateCourseStatusRequest,
    //cities
    createCities,
    getAllCities,
    getCities,
    editCities,
    removeCities,
    getCitiesForSelect,
    // regions
    createRegions,
    getAllRegions,
    getRegions,
    editRegions,
    removeRegions,
    getRegionsForSelect,
  };
};

export default {
  create,
};
