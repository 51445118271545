import React, { Component } from 'react';
import AccommodationForm from './AccommodationForm';
import { connect } from 'react-redux';
import * as accommodationActions from 'actions/accommodationActions';
import * as tripActions from 'actions/tripActions';
import { debounce } from 'lodash';

class CreateAccommodationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 0,
      lat: 0,
    };
  }

  componentDidMount() {
    const {
      getAccommodation,
      match: { params },
    } = this.props;
    getAccommodation(params.id);
  }

  componentDidUpdate(prevProps) {
    const { places, accommodation, getDestinations, refreshCoordinates } = this.props;

    if (prevProps !== this.props) {
      if (prevProps.places !== places) {
        // When click in the map, select the first option
        this.setState({
          address: places.length > 0 && places[0].place_name,
          showPlaceList: true,
        });
      }
      if (!this.state.latitude) {
        // update state when the accommodation is in redux (edit)
        this.setState({
          ...accommodation,
          lat: accommodation.latitude,
          lng: accommodation.longitude,
          city_id: accommodation.city_id,
          city_name: accommodation.city.name,
        });
        this.selectPlace({
          center: [accommodation.longitude, accommodation.latitude],
          place_name: accommodation.address,
        });
        //refreshCoordinates(accommodation.address);
        //getDestinations({ search: accommodation.city_name });
      }
    }
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  updateImage = response => {
    this.setState({ changeAvatar: false, image: response.url });
  };

  clickMap = (map, evt) => {
    const { refreshAddress } = this.props;
    const { lng, lat } = evt.lngLat;
    refreshAddress({ lng, lat });
    this.setState({ lng, lat });
  };

  searchCoordinates = debounce(async () => {
    const { refreshCoordinates } = this.props;
    const { address } = this.state;

    refreshCoordinates(address);
  }, 600);

  handleChangeAddress = event => {
    const address = event.target.value;

    this.setState({ address, addressInput: address });
    this.searchCoordinates();
  };

  selectPlace = place => {
    const { center, place_name } = place;
    this.setState({
      lng: center[0],
      lat: center[1],
      address: place_name,
      addressInput: place_name,
    });
  };

  searchData = debounce(async => {
    const { getDestinations } = this.props;
    const { city_name } = this.state;
    getDestinations(city_name);
  }, 600);

  searchCity = event => {
    const search = event.target.value;
    this.setState({ city_name: search });
    this.searchData();
  };

  replaceImage = () => {
    this.setState({ image: null });
  };

  update = () => {
    const {
      updateAccommodation,
      match: { params },
    } = this.props;

    this.setState({ latitude: this.state.lat, longitude: this.state.lng }, () =>
      updateAccommodation({ ...this.state }, params.id),
    );
  };

  selectCity = city => {
    this.setState({ city_id: city.id, city_name: city.name });
  };

  updateShowCityList = status => {
    this.setState({ showCityList: status });
  };

  updateShowPlaceList = status => {
    this.setState({ showPlaceList: status });
  };

  handleChangeCheckbox = (evt, name) => {
    this.setState({ [name]: evt.target.checked });
  };

  render() {
    return (
      <AccommodationForm
        handleChange={this.handleChange}
        updateImage={this.updateImage}
        clickMap={this.clickMap}
        handleChangeAddress={this.handleChangeAddress}
        selectPlace={this.selectPlace}
        saveOrUpdate={this.update}
        searchCity={this.searchCity}
        selectCity={this.selectCity}
        replaceImage={this.replaceImage}
        updateShowCityList={this.updateShowCityList}
        updateShowPlaceList={this.updateShowPlaceList}
        handleChangeCheckbox={this.handleChangeCheckbox}
        editMode
        {...this.props}
        data={this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    places: state.activityReducer.places,
    citiesFiltered: state.tripReducer.tripForm.citiesFiltered,
    token: state.loginReducer.token,
    accommodation: state.accommodationReducer.accommodation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    refreshCoordinates: address => dispatch(accommodationActions.refreshCoordinates(address)),
    refreshAddress: address => dispatch(accommodationActions.refreshAddress(address)),
    updateAccommodation: (accommodation, id) =>
      dispatch(accommodationActions.updateAccommodation(accommodation, id)),
    getDestinations: params => dispatch(tripActions.getDestinations(params)),
    getAccommodation: id => dispatch(accommodationActions.getAccommodation(id)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAccommodationContainer);
