import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import styled from 'styled-components';
import Pagination from 'components/PaginationButtons';

const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;
class PdfView extends Component {
  render() {
    const { onDocumentLoadSuccess, width, pageNumber, numPages, changePage, file } = this.props;

    return (
      <>
        <Container>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page width={width} pageNumber={pageNumber} />
          </Document>

          <PaginationBox>
            <Pagination currentPage={pageNumber} changePage={changePage} totalPages={numPages} />
          </PaginationBox>
        </Container>
      </>
    );
  }
}
export default PdfView;
