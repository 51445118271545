import React from 'react';
import styled from 'styled-components';
import Pagination from 'components/PaginationButtons';
import ListEmpty from 'components/ListEmpty';
import AddClimateCityForm from './AddClimateCityForm';
import Button from 'components/Button';
import ModalExcel from 'components/Suggestions/ModalExcel';

const Container = styled.div``;

const HeaderContainer = styled.div`
  margin: 20px;
  padding-bottom: 5px;
  position: relative;
  width: auto;
  position: relative;
  height: 50px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const GridContainer = styled.div`
  margin: 20px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
  height: 470px;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  min-width: 1000px;
`;

const TBody = styled.div``;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 0px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;
const InputActivity = styled.input`
  width: 100%;
  height: 50px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 5)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const FlexRow = styled.div`
  display: flex;
  width: auto;
  justify-content: space-around;
  display: inline-flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  margin-bottom: -25px;
`;

const LabelClimates = styled.p`
  width: ${props => props.width}px;
  height: 30px;
  margin-bottom: 20px;
  font-size: 15px;
  color: black;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 5)}px;
  border-radius: 5px 5px 0px 0px;
  text-decoration: none;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
`;
const ValuesClimates = styled.p`
  width: ${props => props.width}px;
  height: ${props => (props.height ? props.height : 25)}px;
  font-size: 15px;
  padding-top: 7px;
  padding-left: ${props => props.paddingLeft}px;
  color: black;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  text-decoration: none;
  font-family: inherit;
  background-color: ${props => (props.secondaryColor ? '#EEEEEE' : '#f8f7f7')};
`;

const ClimatesList = props => {
  const {
    viewMode,
    token,
    searchCity,
    citySearchableText,
    currentPage,
    changePage,
    elementsPerPage,
    climatesCount,
    getClimate,
    //cities,
    loading = false,
    cities,
    climate,
    saveClimate,
    editClimate,
    setClimate,
    onRemoveClimate,
    isEditing,
    handleisEditing,
    renderAddCity,
    handleAddCity,
    handleModalExcel,
    renderModalExcel,
    country,
  } = props;

  const renderCitiesList = (city, key) => {
    return (
      <FlexRow>
        <SectionContainer>
          <ValuesClimates
            paddingLeft={'5'}
            textAlign={'start'}
            secondaryColor={key % 2}
            width={'150'}
          >
            {city.city}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'170'}>
            {city.average_high_january}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'170'}>
            {city.average_low_january}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'280'}>
            {city.daily_chance_of_precipitation_january}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'200'}>
            {city.average_dew_point_january}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'240'}>
            {city.average_sunshine_hours_january}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'180'}>
            {city.average_high_february}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'170'}>
            {city.average_low_february}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'285'}>
            {city.daily_chance_of_precipitation_february}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'210'}>
            {city.average_dew_point_february}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'250'}>
            {city.average_sunshine_hours_february}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'160'}>
            {city.average_high_march}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'160'}>
            {city.average_low_march}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'270'}>
            {city.daily_chance_of_precipitation_march}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'200'}>
            {city.average_dew_point_march}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'230'}>
            {city.average_sunshine_hours_march}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'150'}>
            {city.average_high_april}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'150'}>
            {city.average_low_april}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'260'}>
            {city.daily_chance_of_precipitation_april}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'185'}>
            {city.average_dew_point_april}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'220'}>
            {city.average_sunshine_hours_april}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'150'}>
            {city.average_high_may}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'150'}>
            {city.average_low_may}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'260'}>
            {city.daily_chance_of_precipitation_may}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'180'}>
            {city.average_dew_point_may}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'210'}>
            {city.average_sunshine_hours_may}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'150'}>
            {city.average_high_june}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'150'}>
            {city.average_low_june}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'255'}>
            {city.daily_chance_of_precipitation_june}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'180'}>
            {city.average_dew_point_june}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'210'}>
            {city.average_sunshine_hours_june}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'150'}>
            {city.average_high_july}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'150'}>
            {city.average_low_july}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'250'}>
            {city.daily_chance_of_precipitation_july}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'180'}>
            {city.average_dew_point_july}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'210'}>
            {city.average_sunshine_hours_july}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'160'}>
            {city.average_high_august}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'160'}>
            {city.average_low_august}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'270'}>
            {city.daily_chance_of_precipitation_august}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'200'}>
            {city.average_dew_point_august}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'230'}>
            {city.average_sunshine_hours_august}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'185'}>
            {city.average_high_september}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'185'}>
            {city.average_low_september}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'300'}>
            {city.daily_chance_of_precipitation_september}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'230'}>
            {city.average_dew_point_september}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'260'}>
            {city.average_sunshine_hours_september}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'170'}>
            {city.average_high_october}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'170'}>
            {city.average_low_october}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'280'}>
            {city.daily_chance_of_precipitation_october}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'210'}>
            {city.average_dew_point_october}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'240'}>
            {city.average_sunshine_hours_october}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'190'}>
            {city.average_high_november}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'190'}>
            {city.average_low_november}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'300'}>
            {city.daily_chance_of_precipitation_november}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'220'}>
            {city.average_dew_point_november}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'260'}>
            {city.average_sunshine_hours_november}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'180'}>
            {city.average_high_december}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'180'}>
            {city.average_low_december}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'300'}>
            {city.daily_chance_of_precipitation_december}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'220'}>
            {city.average_dew_point_december}
          </ValuesClimates>
        </SectionContainer>
        <SectionContainer>
          <ValuesClimates secondaryColor={key % 2} width={'250'}>
            {city.average_sunshine_hours_december}
          </ValuesClimates>
        </SectionContainer>
      </FlexRow>
    );
  };

  return (
    <Container>
      {!renderAddCity && (
        <>
          <HeaderContainer style={{ marginBottom: 20 }}>
            <FlexRow>
              <FlexColumn width={70}>
                <Pagination
                  currentPage={currentPage}
                  changePage={changePage}
                  totalPages={Math.ceil(climatesCount / elementsPerPage)}
                />
              </FlexColumn>
              <FlexColumn marginLeft={500} width={70}>
                <InputActivity
                  type="text"
                  placeholder="Search City"
                  name="citySearchableText"
                  value={citySearchableText}
                  onChange={searchCity}
                />
              </FlexColumn>
              {!viewMode && (
                <FlexColumn width={25}>
                  <FlexRow>
                    <LinkText onClick={() => handleModalExcel(true)} style={{ marginTop: 15 }}>
                      Import from sheet
                    </LinkText>
                    <Button onClick={() => handleAddCity(true)} style={{ marginTop: 0 }}>
                      Add City
                    </Button>
                  </FlexRow>
                </FlexColumn>
              )}
            </FlexRow>
          </HeaderContainer>
          <GridContainer>
            <THeader>
              <FlexRow>
                <LabelClimates
                  marginLeft={'160'}
                  width={'170'}
                  backgroundColor={'rgba(86, 193, 254, 0.2)'}
                >
                  Average High January
                </LabelClimates>
                <LabelClimates width={'170'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low January
                </LabelClimates>
                <LabelClimates width={'280'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation January
                </LabelClimates>
                <LabelClimates width={'200'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point January
                </LabelClimates>
                <LabelClimates width={'240'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours January
                </LabelClimates>
                <LabelClimates width={'180'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High February
                </LabelClimates>
                <LabelClimates width={'170'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low February
                </LabelClimates>
                <LabelClimates width={'285'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation February
                </LabelClimates>
                <LabelClimates width={'210'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point February
                </LabelClimates>
                <LabelClimates width={'250'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours February
                </LabelClimates>
                <LabelClimates width={'160'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High March
                </LabelClimates>
                <LabelClimates width={'160'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low March
                </LabelClimates>
                <LabelClimates width={'270'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation March
                </LabelClimates>
                <LabelClimates width={'200'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point March
                </LabelClimates>
                <LabelClimates width={'230'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours March
                </LabelClimates>
                <LabelClimates width={'150'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High April
                </LabelClimates>
                <LabelClimates width={'150'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low April
                </LabelClimates>
                <LabelClimates width={'260'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation April
                </LabelClimates>
                <LabelClimates width={'185'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point April
                </LabelClimates>
                <LabelClimates width={'220'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours April
                </LabelClimates>
                <LabelClimates width={'150'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High May
                </LabelClimates>
                <LabelClimates width={'150'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low May
                </LabelClimates>
                <LabelClimates width={'260'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation May
                </LabelClimates>
                <LabelClimates width={'180'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point May
                </LabelClimates>
                <LabelClimates width={'210'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours May
                </LabelClimates>
                <LabelClimates width={'150'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High June
                </LabelClimates>
                <LabelClimates width={'150'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low June
                </LabelClimates>
                <LabelClimates width={'255'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation June
                </LabelClimates>
                <LabelClimates width={'180'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point June
                </LabelClimates>
                <LabelClimates width={'210'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours June
                </LabelClimates>
                <LabelClimates width={'150'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High July
                </LabelClimates>
                <LabelClimates width={'150'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low July
                </LabelClimates>
                <LabelClimates width={'250'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation July
                </LabelClimates>
                <LabelClimates width={'180'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point July
                </LabelClimates>
                <LabelClimates width={'210'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours July
                </LabelClimates>
                <LabelClimates width={'160'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High August
                </LabelClimates>
                <LabelClimates width={'160'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low August
                </LabelClimates>
                <LabelClimates width={'270'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation August
                </LabelClimates>
                <LabelClimates width={'200'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point August
                </LabelClimates>
                <LabelClimates width={'230'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours August
                </LabelClimates>
                <LabelClimates width={'185'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High September
                </LabelClimates>
                <LabelClimates width={'185'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low September
                </LabelClimates>
                <LabelClimates width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation September
                </LabelClimates>
                <LabelClimates width={'230'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point September
                </LabelClimates>
                <LabelClimates width={'260'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours September
                </LabelClimates>
                <LabelClimates width={'170'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High October
                </LabelClimates>
                <LabelClimates width={'170'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low October
                </LabelClimates>
                <LabelClimates width={'280'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation October
                </LabelClimates>
                <LabelClimates width={'210'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point October
                </LabelClimates>
                <LabelClimates width={'240'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours October
                </LabelClimates>
                <LabelClimates width={'190'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High November
                </LabelClimates>
                <LabelClimates width={'190'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low November
                </LabelClimates>
                <LabelClimates width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation November
                </LabelClimates>
                <LabelClimates width={'220'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point November
                </LabelClimates>
                <LabelClimates width={'260'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours November
                </LabelClimates>
                <LabelClimates width={'180'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average High December
                </LabelClimates>
                <LabelClimates width={'180'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Low December
                </LabelClimates>
                <LabelClimates width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Daily Chance Of Precipitation December
                </LabelClimates>
                <LabelClimates width={'220'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Dew Point December
                </LabelClimates>
                <LabelClimates width={'250'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                  Average Sunshine Hours December
                </LabelClimates>
              </FlexRow>
            </THeader>
            <TBody>
              {cities.map((city, key) => {
                return renderCitiesList(city, key);
              })}
              {(!cities || !cities.length) && <ListEmpty message="There are currently no cities" />}
            </TBody>
          </GridContainer>
        </>
      )}

      {renderAddCity && (
        <AddClimateCityForm
          viewMode={viewMode}
          isEditing={isEditing}
          handleisEditing={handleisEditing}
          setClimate={setClimate}
          country={country}
          handleAddCity={handleAddCity}
          climate={climate}
          saveClimate={saveClimate}
          editClimate={editClimate}
        />
      )}

      <ModalExcel
        get={getClimate}
        id={country.id}
        token={token}
        url="climates"
        currentPage={currentPage}
        elementsPerPage={elementsPerPage}
        renderModalExcel={renderModalExcel}
        handleModalExcel={handleModalExcel}
      ></ModalExcel>
    </Container>
  );
};

export default ClimatesList;
