import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import SelectedButton from '../../SelectedButton';
import PrimaryText from '../PrimaryText';
import { TRAVELING_WITH } from 'constants/index';
import { Formik, FieldArray } from 'formik';

const TravelingForBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TravelingForPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
`;

const Container = styled.div`
  height: 90%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const InputActivity = styled.input`
  width: 100px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
`;

const Text = styled.p`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18.5px;
  line-height: 18px;
  text-transform: capitalize;
  text-align: center;
  color: #999999;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const IconAdd = styled.button`
  color: blue;
  text-align: center;
`;
const IconDelete = styled.button`
  color: red;
`;

const ChildrenBox = styled.div`
  margin: 11px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
`;
const renderChildrenoOlds = (childrenOldObj, key, form, push, remove, errors = {}) => {
  const childrenOldsSize = form.values.children_olds.length;
  const changeValue = e => {
    e.stopPropagation();
    form.setFieldValue(e.target.name, e.target.value);
  };

  return (
    <>
      <ChildrenBox>
        <Text>Children {key + 1}</Text>
        <div>
          <InputActivity
            placeholder="Years"
            type="number"
            name={`children_olds.${key}`}
            value={childrenOldObj}
            onChange={changeValue}
          />
        </div>

        {childrenOldsSize > 1 && (
          <IconDelete name="delete" color="red" width={30} onClick={() => remove(key)}>
            Delete
          </IconDelete>
        )}
      </ChildrenBox>

      {childrenOldsSize - 1 === key && (
        <div style={{ textAlign: 'center' }}>
          <IconAdd name="add" color="blue" width={30} onClick={() => push('')}>
            + Add
          </IconAdd>
        </div>
      )}
    </>
  );
};
class TravelingWithView extends Component {
  render() {
    const { step, state, changeStep, traveling_with, children_olds } = this.props;

    return (
      <TravelingForBox>
        <TravelingForPrimaryBox>
          <PrimaryText text="How would you describe your (potential) travel companions? Please select all that apply" />

          <Formik
            enableReinitialize
            initialValues={{ traveling_with, children_olds }}
            isInitialValid={true}
          >
            {({ values, handleChange, errors, handleSubmit, setFieldValue }) => {
              const changeTravelingWith = value => {
                const traveling_with = [...values.traveling_with];
                const exist = traveling_with.indexOf(value);
                if (exist != -1) {
                  traveling_with.splice(exist, 1);
                } else {
                  traveling_with.push(value);
                }
                setFieldValue('traveling_with', traveling_with);

                if (!values.traveling_with.includes('Children')) {
                  setFieldValue('children_olds', ['']);
                }
              };
              return (
                <Container>
                  <Content>
                    {TRAVELING_WITH.map(travelinWith => (
                      <SelectedButton
                        text={travelinWith}
                        onClick={() => changeTravelingWith(travelinWith)}
                        active={values.traveling_with.includes(travelinWith)}
                      />
                    ))}
                  </Content>
                  <Content>
                    {values.traveling_with.includes('Children') && (
                      <FieldArray
                        name="children_olds"
                        render={({ form, push, remove }) => (
                          <div style={{ marginTop: 30, marginBottom: 30 }}>
                            <Text>Age of children:</Text>
                            {values.children_olds.map((childrenOld, key) =>
                              renderChildrenoOlds(
                                childrenOld,
                                key,
                                form,
                                push,
                                remove,
                                errors && errors.children_olds ? errors.children_olds[key] : {},
                              ),
                            )}
                          </div>
                        )}
                      />
                    )}
                  </Content>
                  <StepButtons
                    nextDisabled={!traveling_with}
                    backStep={() => changeStep(step - 1, values)}
                    nextStep={() => changeStep(step + 1, values)}
                  />
                </Container>
              );
            }}
          </Formik>
        </TravelingForPrimaryBox>
      </TravelingForBox>
    );
  }
}

export default TravelingWithView;
