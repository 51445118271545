import { ActionTypes } from 'constants/index';

export function getAvailableProducts() {
  return {
    type: ActionTypes.GET_AVAILABLE_PRODUCTS,
  };
}

export function saveAvailableProducts(products) {
  return {
    type: ActionTypes.SAVE_AVAILABLE_PRODUCTS,
    products: products.rows,
    productsCount: products.count,
  };
}
