import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import ok from 'assets/media/icons/ok.png';
import { Link } from 'react-router-dom';
import pending from 'assets/media/icons/pending.png';
import remove from 'assets/media/icons/remove.png';
import meeting from 'assets/media/icons/meeting.png';
import ListEmpty from 'components/ListEmpty';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
`;
const Countdown = styled.p`
  font-size: 16px;
  color: #38b6ff;
`;
const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 14px;
`;

const StatusImage = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 19px;
`;

const TBody = styled.div``;

const chooseStatusImage = status => {
  switch (status) {
    case 'pending':
      return pending;
    case 'completed':
      return ok;
    case 'rejected':
      return remove;
    case 'in_process':
      return meeting;
    default:
      return pending;
  }
};

class MeetingsList extends Component {
  renderMeetings({ id, start_date, status }, isAdmin, key) {
    const now = moment();
    const expiration = moment(start_date);
    const diff = expiration.diff(now);
    const diffDuration = moment.duration(diff);

    const { user_id, updateMeeting } = this.props;
    return (
      <Item key={key}>
        <SectionContainer width={'20%'}>
          <Primary>Meeting {key + 1}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          {now.isAfter(expiration) ? (
            <Primary className="without-margin">Expired meeting</Primary>
          ) : (
            <Countdown>{`${diffDuration.days()}D-${diffDuration.hours()}H-${diffDuration.minutes()}M`}</Countdown>
          )}
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary className="without-margin">
            <i className="far fa-calendar icons non-meeting" />
          </Primary>
          <Secondary className="without-margin">
            {moment(start_date).format('MM-DD-YYYY')}
          </Secondary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={isAdmin ? '10%' : '20%'}>
          <Primary> {moment(start_date).format('hh:mm A')}</Primary>
          {isAdmin && <VerticalLine />}
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <StatusImage src={chooseStatusImage(status)} />
          <Primary className="without-margin">{status}</Primary>
          <VerticalLine />
        </SectionContainer>
        {isAdmin && status == 'pending' && (
          <SectionContainer width={'10%'}>
            <Link to={`/trip/create/${user_id}/${id}`}>
              <Countdown style={{ cursor: 'pointer' }}>Start</Countdown>
            </Link>
            <Countdown
              onClick={() => updateMeeting({ id, status: 'rejected' })}
              style={{ cursor: 'pointer' }}
            >
              Reject
            </Countdown>
          </SectionContainer>
        )}
      </Item>
    );
  }

  render() {
    const { meetings, isAdmin } = this.props;

    return (
      <GridContainer>
        <THeader>
          <TitleContainer width={'20%'}>
            <Title>MEET</Title>
          </TitleContainer>
          <TitleContainer width={'20%'}>
            <Title>COUNTDOWN</Title>
          </TitleContainer>
          <TitleContainer width={'20%'}>
            <Title>DATE</Title>
          </TitleContainer>
          <TitleContainer width={isAdmin ? '10%' : '20%'}>
            <Title>TIME</Title>
          </TitleContainer>
          <TitleContainer width={'20%'}>
            <Title>STATUS</Title>
          </TitleContainer>

          {isAdmin && (
            <TitleContainer width={'10%'}>
              <Title>Actions</Title>
            </TitleContainer>
          )}
        </THeader>
        <TBody>
          {meetings.map((call, key) => {
            return this.renderMeetings(call, isAdmin, key);
          })}
          {(!meetings || !meetings.length) && (
            <ListEmpty message="There are currently no discussions" />
          )}
        </TBody>
      </GridContainer>
    );
  }
}

export default MeetingsList;
