import React, { Component } from 'react';
import CoursesList from 'components/Courses/CoursesList';
import { connect } from 'react-redux';
import * as courseActions from 'actions/courseActions';

class CoursesListContainer extends Component {
  componentDidMount() {
    const { getCustomerCoursesRequest } = this.props;
    getCustomerCoursesRequest();
  }

  sendTest = type => {
    const { updateCourseStatusRequest } = this.props;
    updateCourseStatusRequest({ type });
  };

  render() {
    const { courses, loading } = this.props;
    return <CoursesList sendTest={this.sendTest} courses={courses} loading={loading} />;
  }
}

function mapStateToProps(state) {
  return {
    profile: state.loginReducer.profile,
    courses: state.courseReducer.courses,
    loading: state.courseReducer.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomerCoursesRequest: () => dispatch(courseActions.getCustomerCoursesRequest()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoursesListContainer);
