import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import SelectedButton from '../../SelectedButton';
import PrimaryText from '../PrimaryText';
import { HUMIDITYLEVELS } from 'constants/index';
import { Formik } from 'formik';

const TravelingForBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TravelingForPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 500px;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
`;

const Container = styled.div`
  height: 90%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

class HumidityLevelView extends Component {
  render() {
    const { step, state, changeStep, humidity_levels } = this.props;

    return (
      <TravelingForBox>
        <TravelingForPrimaryBox>
          <PrimaryText text="Which of the following levels of humidity would you be OK experiencing?" />

          <Formik enableReinitialize initialValues={{ humidity_levels }} isInitialValid={true}>
            {({ values, handleChange, errors, handleSubmit, setFieldValue }) => {
              const changeHumidityLevel = value => {
                const humidity_levels = [...values.humidity_levels];
                const exist = humidity_levels.indexOf(value);
                if (exist != -1) {
                  humidity_levels.splice(exist, 1);
                } else {
                  humidity_levels.push(value);
                }
                setFieldValue('humidity_levels', humidity_levels);
              };
              return (
                <Container>
                  <Content>
                    {HUMIDITYLEVELS.map(humidityLevel => (
                      <SelectedButton
                        text={humidityLevel}
                        onClick={() => changeHumidityLevel(humidityLevel)}
                        active={values.humidity_levels.includes(humidityLevel)}
                      />
                    ))}
                  </Content>

                  <StepButtons
                    nextDisabled={!values.humidity_levels.length}
                    backStep={() => changeStep(step - 1, values)}
                    nextStep={() => changeStep(step + 1, values)}
                  />
                </Container>
              );
            }}
          </Formik>
        </TravelingForPrimaryBox>
      </TravelingForBox>
    );
  }
}

export default HumidityLevelView;
