import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import JournalExplanationConcepts from 'components/JournalExplanationConcepts/JournalExplanationConceptsContainer';
import JournalHowUse from 'components/JournalHowUse/JournalHowUseContainer';
import TheCalling from 'components/JournalTheCalling/JournalTheCallingContainer';
import TheDeapture from 'components/JournalTheDeapture/JournalTheDeaptureContainer';
import TheRoad from 'components/JournalTheRoad/JournalTheRoadContainer';
import PreparingToReturn from 'components/JournalPreparingToReturn/JournalPreparingToReturnContainer';
import TheReturn from 'components/JournalTheReturn/JournalTheReturnContainer';

const JournalDiv = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-position-x: center;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-blend-mode: multiply;
  text-align: left;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 50px;
  overflow-x: scroll;
  z-index: 1050;
`;

const CreateItineraryView = props => {
  const { step, day, journal } = props;

  return (
    <JournalDiv>
      {step == 1 && <JournalExplanationConcepts {...props} />}
      {step == 2 && <JournalHowUse {...props} />}
      {step == 3 && <TheCalling {...props} day={day} journal={journal} />}
      {step == 4 && <TheDeapture {...props} day={day} journal={journal} />}
      {step == 5 && <TheRoad {...props} day={day} journal={journal} />}
      {step == 6 && <PreparingToReturn {...props} day={day} journal={journal} />}
      {step == 7 && <TheReturn {...props} day={day} journal={journal} />}
      {step == 8 && (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )}
    </JournalDiv>
  );
};

export default CreateItineraryView;
