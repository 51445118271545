import React, { Component } from 'react';
import StepContainer from 'components/StepContainer';
import StepButtons from 'components/StepButtons';
import styled from 'styled-components';
import { goBack } from 'modules/history';

const parse = require('html-react-parser');

const HowUseBox = styled.div`
  margin-left: 50px;
  margin-right: 30px;
  @media (max-width: 1000px) {
    margin-left: 15%;
    margin-right: 15%;
  }
`;

const CardTitle = styled.p`
  color: #38b6ff;
  font-size: 25px;
  text-align: center;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: 10px;
`;

export const ContentTitle = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 28px;
  text-align: center;
  margin-left: 70px;
  margin-right: 70px;
  color: #808080;
  font-weight: 600;
`;

export const ContentBody = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  line-height: 1.47;
  color: #999999;
  text-align: justify;
  height: 100%;
  padding-right: 15px;
  max-height: ${props => props.maxHeight}px;
  overflow: auto;
`;

class HowUseView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { changeStep, state, stepFields } = this.props;
    const { localStep } = state;
    return (
      <StepContainer closeIcon width={'850px'} closeAction={() => goBack()}>
        <HowUseBox>
          <div style={{ marginBottom: '50px' }}>
            <CardTitle marginTop={55}>How to use </CardTitle>
            <CardTitle marginTop={0}> Authentic Travel Journal</CardTitle>
            <ContentTitle>{stepFields.title}</ContentTitle>
            <ContentBody>{parse(stepFields.body)}</ContentBody>
          </div>
          <StepButtons
            backStep={() => changeStep(localStep - 1)}
            nextStep={() => changeStep(localStep + 1)}
          />
        </HowUseBox>
      </StepContainer>
    );
  }
}

export default HowUseView;
