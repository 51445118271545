import { put, call } from 'redux-saga/effects';
import * as goalActions from '../actions/goalActions';
import * as tripActions from '../actions/tripActions';
import * as appActions from '../actions/app';
import { goBack } from 'modules/history';

export function* createGoalRequest(api, action) {
  const response = yield call(api.createGoalRequest, action.data);

  if (response.status == 200) {
    yield put(tripActions.saveTrip({ goal: response.data }));

    yield put(appActions.showAlert('Goals activated.', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getGoalRequest(api, action) {
  const response = yield call(api.getGoalRequest, action.id);
  if (response.status == 200) {
    yield put(goalActions.getGoalSuccess(response.data));
  }
}

export function* updateGoalRequest(api, action) {
  const response = yield call(api.updateGoalRequest, action.id, action.data);
  if (response.status == 200) {
    yield goBack();
    yield put(appActions.showAlert('Authentic travel  plan updated.', { variant: 'success' }));
  }
}
