import { put, call } from 'redux-saga/effects';
import * as transactionActions from '../actions/transactionActions';
import * as appActions from '../actions/app';

export function* getMeTransactions(api, action) {
  const response = yield call(api.getMeTransactions);
  if (response.status == 200) {
    yield put(transactionActions.saveMeTransactions(response.data.rows));
  }
}
