const responses = ['Very much unlike me', 'Unlike me', 'Neutral', 'Like me', 'Very much like me'];

export const defaultState = {
  mindfuldness_1: '3',
  mindfuldness_2: '3',
  confidence_1: '3',
  confidence_2: '3',
  resilience_1: '3',
  resilience_2: '3',
  anti_fragility_1: '3',
  anti_fragility_2: '3',
  purpose_1: '3',
  purpose_2: '3',
  wisdom_1: '3',
  wisdom_2: '3',
  curiosity_1: '3',
  curiosity_2: '3',
  open_mindedness_1: '3',
  open_mindedness_2: '3',
  passion_learning_1: '3',
  passion_learning_2: '3',
  creativity_1: '3',
  creativity_2: '3',
  courage_1: '3',
  courage_2: '3',
  perseverance_1: '3',
  perseverance_2: '3',
  vibrancy_1: '3',
  vibrancy_2: '3',
  integrity_1: '3',
  integrity_2: '3',
  social_awarenes_1: '3',
  social_awarenes_2: '3',
  love_1: '3',
  love_2: '3',
  kindness_1: '3',
  kindness_2: '3',
  fairness_1: '3',
  fairness_2: '3',
  communal_duty_1: '3',
  communal_duty_2: '3',
  leadership_1: '3',
  leadership_2: '3',
  self_control_1: '3',
  self_control_2: '3',
  prudence_1: '3',
  prudence_2: '3',
  humility_1: '3',
  humility_2: '3',
  optimism_1: '3',
  optimism_2: '3',
  forgiveness_1: '3',
  forgiveness_2: '3',
  sense_humor_1: '3',
  sense_humor_2: '3',
  appreciation_good_1: '3',
  appreciation_good_2: '3',
  gratitude_1: '3',
  gratitude_2: '3',
  spirituality_1: '3',
  spirituality_2: '3',
  localStep: 1,
};

export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        label: 'Being aware of what thoughts pass through my mind.',
        name: 'mindfuldness_1',
        options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Very Often'],
        subtitle: 'How often have you experienced the following over the past week?',
      };
    case 2:
      return {
        reverseScored: true,
        label: 'Wishing I could control my emotions more easily.',
        name: 'mindfuldness_2',
        options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Very Often'],
        subtitle: 'How often have you experienced the following over the past week?',
      };

    case 3:
      return {
        subtitle: 'How well do the following statements describe you?',
        label: 'With enough effort and time, I can achieve lots of things.',
        name: 'confidence_1',
        options: responses,
      };
    case 4:
      return {
        subtitle: 'How well do the following statements describe you?',
        reverseScored: true,
        label: 'I doubt my abilities to accomplish my dreams and goals.',
        name: 'confidence_2',
        options: responses,
      };

    case 5:
      return {
        subtitle: 'How well do the following statements describe you:',

        label: 'I quickly recover from setbacks in my life.',
        name: 'resilience_1',
        options: responses,
      };
    case 6:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I am rarely able to bounce back from adversity.',
        name: 'resilience_2',
        options: responses,
      };
    case 7:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I welcome challenges as an opportunities to learn and grow.',
        name: 'anti_fragility_1',
        options: responses,
      };
    case 8:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'Stressful situations don’t teach me anything.',
        name: 'anti_fragility_2',
        options: responses,
      };

    case 9:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I have a clear sense of direction in life.',
        name: 'purpose_1',
        options: responses,
      };
    case 10:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I don’t really have a long-term and overacting goal.',
        name: 'purpose_2',
        options: responses,
      };
    case 11:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I have learned a lot from life and my past decisions.',
        name: 'wisdom_1',
        options: responses,
      };
    case 12:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'Those who know me well rarely describe me as having ‘street smarts’.',
        name: 'wisdom_2',
        options: responses,
      };
    case 13:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I am curious about lots of things.',
        name: 'curiosity_1',
        options: responses,
      };
    case 14:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I become bored easily.',
        name: 'curiosity_2',
        options: responses,
      };
    case 15:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I am willing to consider viewpoints different than my own.',
        name: 'open_mindedness_1',
        options: responses,
      };
    case 16:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I often make snap judgements',
        name: 'open_mindedness_2',
        options: responses,
      };
    case 17:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I get excited when I learn something new.',
        name: 'passion_learning_1',
        options: responses,
      };

    case 18:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I am not interested in visiting museums.',
        name: 'passion_learning_2',
        options: responses,
      };
    case 19:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I enjoy coming up with new ideas.',
        name: 'creativity_1',
        options: responses,
      };
    case 20:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I often struggle to be imaginative.',
        name: 'creativity_2',
        options: responses,
      };
    case 21:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I will stand up for my beliefs in the face of strong opposition.',
        name: 'courage_1',
        options: responses,
      };
    case 22:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'Fear often gets the better of me.',
        name: 'courage_2',
        options: responses,
      };
    case 23:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'When I start something, I finish it.',
        name: 'perseverance_1',
        options: responses,
      };
    case 24:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'It’s easy for me to get sidetracked.',
        name: 'perseverance_2',
        options: responses,
      };
    case 25:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I have lots of passion.',
        name: 'vibrancy_1',
        options: responses,
      };
    case 26:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I struggle to get excited about life.',
        name: 'vibrancy_2',
        options: responses,
      };
    case 27:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I live life according to my values.',
        name: 'integrity_1',
        options: responses,
      };
    case 28:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I struggle to keep my promises',
        name: 'integrity_2',
        options: responses,
      };
    case 29:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'It’s easy for me to form relationships, friendship or otherwise.',
        name: 'social_awarenes_1',
        options: responses,
      };
    case 30:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I struggle to understand other people.',
        name: 'social_awarenes_2',
        options: responses,
      };
    case 31:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I have people in my life who care about my wellbeing as if it were their own.',
        name: 'love_1',
        options: responses,
      };
    case 32:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'It’s challenging for me to give and receive love.',
        name: 'love_2',
        options: responses,
      };
    case 33:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I enjoy helping other people.',
        name: 'kindness_1',
        options: responses,
      };
    case 34:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I struggle to get as excited about other people’s successes as I am for my own.',
        name: 'kindness_2',
        options: responses,
      };
    case 35:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I treat everyone equally, regardless of who they are.',
        name: 'fairness_1',
        options: responses,
      };
    case 36:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'If I don’t like someone, it’s hard for me to treat them fairy.',
        name: 'fairness_2',
        options: responses,
      };
    case 37:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I’m willing to make personal sacrifices for the benefit of society as a whole.',
        name: 'communal_duty_1',
        options: responses,
      };
    case 38:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I work best when I am alone.',
        name: 'communal_duty_2',
        options: responses,
      };
    case 39:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I am very good at planning group activities.',
        name: 'leadership_1',
        options: responses,
      };
    case 40:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I struggle to get people to do things together without nagging them.',
        name: 'leadership_2',
        options: responses,
      };
    case 41:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'If I commit to a diet, I stick to it.',
        name: 'self_control_1',
        options: responses,
      };
    case 42:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'It’s challenging for me to control my emotions.',
        name: 'self_control_2',
        options: responses,
      };
    case 43:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I’m willing to forego short-term pleasure for long-term gain.',
        name: 'prudence_1',
        options: responses,
      };
    case 44:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I often make poor choices in my relationships or friendships.',
        name: 'prudence_2',
        options: responses,
      };
    case 45:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I’m not any more special than other people.',
        name: 'humility_1',
        options: responses,
      };
    case 46:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'When I accomplish something, I enjoy bragging about it.',
        name: 'humility_2',
        options: responses,
      };
    case 47:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I often have well-thought-out plans for what I want to do.',
        name: 'optimism_1',
        options: responses,
      };
    case 48:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I’m a ‘the glass is half empty’ kind of person.',
        name: 'optimism_2',
        options: responses,
      };
    case 49:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I’m more likely to offer mercy than try and get revenge.',
        name: 'forgiveness_1',
        options: responses,
      };
    case 50:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'It’s important for me to get even with those who trespass against me.',
        name: 'forgiveness_2',
        options: responses,
      };
    case 51:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'It’s easy for me to see the humor in a wide variety of situations.',
        name: 'sense_humor_1',
        options: responses,
      };
    case 52:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I don’t like mixing work and play.',
        name: 'sense_humor_2',
        options: responses,
      };
    case 53:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I often notice the beauty around me.',
        name: 'appreciation_good_1',
        options: responses,
      };
    case 54:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label:
          'I rarely—if ever—get excited by excellence in sport, art, music, science, or literature.',
        name: 'appreciation_good_2',
        options: responses,
      };
    case 55:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I often stop and count my blessings.',
        name: 'gratitude_1',
        options: responses,
      };
    case 56:
      return {
        subtitle: 'How well do the following statements describe you:',
        reverseScored: true,
        label: 'I rarely say thank you—especially not for little things.',
        name: 'gratitude_2',
        options: responses,
      };
    case 57:
      return {
        subtitle: 'How well do the following statements describe you:',
        label: 'I feel as though I am part of something bigger than myself.',
        name: 'spirituality_1',
        options: responses,
      };
    case 58:
      return {
        subtitle: 'How well do the following statements describe you:',

        reverseScored: true,
        label: 'When it comes to life, we’re all in this alone.',
        name: 'spirituality_2',
        options: responses,
      };
  }
};
