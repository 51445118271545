import React, { Component } from 'react';
import MeetingsView from './MeetingsView';
import { connect } from 'react-redux';
import * as MeetingActions from 'actions/meetingActions';
import * as diagnosticActions from 'actions/diagnosticActions';

class MeetingsContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10, addMeetingModal: false };

  componentDidMount() {
    this.fetchMeetings();
  }

  fetchMeetings = () => {
    const { currentPage, elementsPerPage } = this.state;
    const { getMeetings } = this.props;
    getMeetings(currentPage, elementsPerPage);
  };

  changePage = currentPage => {
    const { elementsPerPage } = this.state;
    const { getMeetings } = this.props;

    getMeetings(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  changeDate = date => {
    const { getPossibleMeetingHours } = this.props;
    getPossibleMeetingHours({ date });
    this.setState({
      selectedDate: date,
    });
  };

  changeHour = evt => {
    this.setState({
      hour: evt.target.value,
    });
  };

  toggleModal = () => {
    this.setState({ addMeetingModal: !this.state.addMeetingModal });
  };

  saveMeeting = meeting => {
    const { saveMeeting } = this.props;
    this.setState({ addMeetingModal: false });
    saveMeeting(meeting);
  };

  render() {
    const { currentPage, elementsPerPage, selectedDate, hour, addMeetingModal } = this.state;
    const { possibleHours } = this.props;

    return (
      <MeetingsView
        {...this.props}
        changePage={this.changePage}
        currentPage={currentPage}
        elementsPerPage={elementsPerPage}
        changeDate={this.changeDate}
        changeHour={this.changeHour}
        possibleHours={possibleHours}
        selectedDate={selectedDate}
        hour={hour}
        addMeetingModal={addMeetingModal}
        toggleModal={this.toggleModal}
        saveMeetingFnc={this.saveMeeting}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    meetings: state.meetingReducer.meetings,
    meetingsCount: state.meetingReducer.meetingsCount,
    possibleHours: state.diagnosticReducer.possibleHours,
    loadingMeeting: state.meetingReducer.loadingMeeting,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getMeetings: (currentPage, elementsPerPage) =>
      dispatch(MeetingActions.getMeetings(currentPage, elementsPerPage)),

    saveMeeting: date => dispatch(diagnosticActions.saveMeeting(date)),
    updateMeeting: meeting => dispatch(diagnosticActions.updateMeeting(meeting)),

    getPossibleMeetingHours: date => dispatch(diagnosticActions.getPossibleMeetingHours(date)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeetingsContainer);
