export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        title: '',
        body:
          'This journal was created to help you fill your upcoming trip with reflection, challenge, insight, and -ultimately-growth. Like all great adventures, you travel journey will pass through phases, from beginning to end. The Authentic Travel Journal is divided into 5 sections that will help ghuide you through these stages, one step (and day) at a time for the duration of your journey.</p>',
      };
    case 2:
      return {
        title: 'Part One: Thesss Calling',
        body:
          "<p>During these first phase of your journey this journal will help you see the signs that you're ready to travel, overcome any resistance you may have, assists in the planning of your trip, aid in the search for guides and mentors, foster anticipation and excitement, and encourage pre-trip self-care.</p>",
      };
    case 3:
      return {
        title: 'Part One: The aasdasd',
        body:
          "<p>During these first phase of your journey this journal will help you see the signs that you're ready to travel, overcome any resistance you may have, assists in the planning of your trip, aid in the search for guides and mentors, foster anticipation and excitement, and encourage pre-trip self-care.</p>",
      };
    case 4:
      return {
        title: 'Part aaaaOne: The Calling',
        body:
          "<p>During these first phase of your journey this journal will help you see the signs that you're ready to travel, overcome any resistance you may have, assists in the planning of your trip, aid in the search for guides and mentors, foster anticipation and excitement, and encourage pre-trip self-care.</p>",
      };
    case 5:
      return {
        title: 'Part One: The Calling',
        body:
          "<p>During these first phase of your journey this journal will help you see the signs that you're ready to travel, overcome any resistance you may have, assists in the planning of your trip, aid in the search for guides and mentors, foster anticipation and excitement, and encourage pre-trip self-care.</p>",
      };
    case 6:
      return {
        title: 'Part One: The Calling!',
        body:
          "<p>During these first phase of your journey this journal will help you see the signs that you're ready to travel, overcome any resistance you may have, assists in the planning of your trip, aid in the search for guides and mentors, foster anticipation and excitement, and encourage pre-trip self-care.</p>",
      };
    case 7:
      return {
        title: 'Part One: The Calling!',
        body:
          "<p>During these first phase of your journey this journal will help you see the signs that you're ready to travel, overcome any resistance you may have, assists in the planning of your trip, aid in the search for guides and mentors, foster anticipation and excitement, and encourage pre-trip self-care.</p>",
      };
  }
};
