import React, { Component } from 'react';
import FoodieDestinationView from './FoodieDestinationView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';

class FoodieDestination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foodie_destination: null,
    };
  }

  componentDidMount() {
    const { foodie_destination } = this.props;
    this.setState({ foodie_destination });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.foodie_destination != this.props.foodie_destination)
      this.setState({ foodie_destination: this.props.foodie_destination });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { foodie_destination } = this.state;
    saveTripData({ foodie_destination });
    changeCurrentStep(step);
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { foodie_destination } = this.state;

    return (
      <FoodieDestinationView
        {...this.props}
        changeStep={this.changeStep}
        handleChange={this.handleChange}
        foodie_destination={foodie_destination}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    foodie_destination: state.tripReducer.tripForm.foodie_destination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FoodieDestination);
