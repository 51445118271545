import React, { Component } from 'react';
import DiagnosticView from './DiagnosticView';
import { connect } from 'react-redux';
import * as diagnosticActions from '../../actions/diagnosticActions';
import { Redirect } from 'react-router-dom';

class DiagnosticContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      getWellBeingDiagnostic,
      getTravelPersonalityDiagnostic,
      getStrengthsDiagnostic,
    } = this.props;
    getWellBeingDiagnostic();
    getTravelPersonalityDiagnostic();
    getStrengthsDiagnostic();
  }

  render() {
    const { initialStep, step } = this.props;
    if (!step && initialStep == 9) {
      return <Redirect to="/" />;
    }
    return <DiagnosticView {...this.props} step={step ? step : initialStep} />;
  }
}

function mapStateToProps(state) {
  return {
    initialStep: state.loginReducer.profile.diagnostic_step, // initializing diagnostic (dont have local step registred)
    step: state.diagnosticReducer.step, //diagnostic in progress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStrengthsDiagnostic: () => dispatch(diagnosticActions.getStrengthsDiagnostic()),
    getWellBeingDiagnostic: () => dispatch(diagnosticActions.getWellBeingDiagnostic()),
    getTravelPersonalityDiagnostic: () =>
      dispatch(diagnosticActions.getTravelPersonalityDiagnostic()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiagnosticContainer);
