import { put, call, select } from 'redux-saga/effects';
import * as tripActions from '../actions/tripActions';
import { replace } from 'modules/history';

import * as appActions from '../actions/app';

export function* getDestinations(api, action) {
  const { search } = action;
  const response = yield call(api.getDestinations, { search });
  if (response.status == 200) {
    yield put(tripActions.saveAvailableCities(response.data));
  }
}
export function* persistTrip(api, action) {
  const state = yield select();
  const { tripForm } = state.tripReducer;
  const { user_id } = tripForm;
  const { citiesFiltered, searchString, step, ...trip } = tripForm;
  const response = yield call(api.persistTrip, trip, user_id);

  if (response.status == 200) {
    yield put(appActions.showAlert('Trip Created.', { variant: 'success' }));
    yield put(tripActions.resetTripForm());
    const response = yield call(api.getMeTrips);
    if (response.status == 200) {
      yield put(tripActions.saveMeTrips(response.data.rows));
    }
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
export function* getTrip(api, action) {
  const response = yield call(api.getTrip, action.id);
  if (response.status == 200) {
    yield put(tripActions.saveTrip(response.data));
    yield put(tripActions.saveTripData(response.data));
  }
}

export function* getMeTrips(api) {
  const response = yield call(api.getMeTrips);
  if (response.status == 200) {
    yield put(tripActions.saveMeTrips(response.data.rows));
  }
}

export function* editTrip(api, action) {
  const state = yield select();
  const { tripForm } = state.tripReducer;
  const { user_id } = tripForm;
  const { citiesFiltered, searchString, step, ...trip } = tripForm;
  const response = yield call(api.editTrip, trip, action.id, user_id);

  if (response.status == 200) {
    yield put(appActions.showAlert('Trip Edited.', { variant: 'success' }));
    yield put(tripActions.resetTripForm());
    yield replace(`/trips/${trip.id}`);
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
    yield put(tripActions.resetTripForm());
  }
}

export function* editTripSimple(api, action) {
  const response = yield call(api.editTripSimple, action.data, action.id);

  if (response.status == 200) {
    yield put(tripActions.saveTrip(action.data));
    yield put(appActions.showAlert('Trip Edited.', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
    yield put(tripActions.resetTripForm());
  }
}

export function* createTripChargeRequest(api, action) {
  const response = yield call(api.createTripChargeRequest, action.id);

  if (response.status == 200) {
    yield put(tripActions.saveTrip({ status: 'approved' }));
    yield put(appActions.showAlert('Charge success.', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
    yield put(tripActions.resetTripForm());
  }
}

export function* createAuthenticPlanRequest(api, action) {
  const response = yield call(api.createAuthenticPlanRequest, action.data);

  if (response.status == 200) {
    yield put(appActions.showAlert('At activated.', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
    yield put(tripActions.resetTripForm());
  }
}

export function* createGoalRequest(api, action) {
  const response = yield call(api.createGoalRequest, action.data);
  if (response.status == 200) {
    yield put(appActions.showAlert('Goal activated.', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
    yield put(tripActions.resetTripForm());
  }
}

export function* getAllTripsRequest(api, action) {
  const { page, elementsPerPage } = action;
  const response = yield call(api.getAllTripsRequest, { page, elementsPerPage });
  if (response.status == 200) {
    yield put(tripActions.getAllTripsSuccess(response.data));
  }
}

export function* getCostsRequest(api, action) {
  const response = yield call(api.getCosts, action.id);
  if (response.status == 200) {
    yield put(tripActions.getCostsSuccess(response.data));
  }
}

export function* updateCostsRequest(api, action) {
  const response = yield call(api.updateCosts, action.id, action.data);
  if (response.status == 200) {
    yield put(tripActions.updateCostsSuccess());
  }
}
