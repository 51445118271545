import { put, call, select } from 'redux-saga/effects';
import * as journalActions from '../actions/journalActions';
import * as appActions from '../actions/app';
import { replace } from 'modules/history';

export function* updateJournalRequest(api, action) {
  const response = yield call(api.updateJournal, action.data.id, action.data);
  if (response.status == 200) {
    yield put(journalActions.getJournalSuccess(response.data));
    yield replace(`/journal/${action.data.id}`);
  }
}

export function* getJournalRequest(api, action) {
  const response = yield call(api.getJournal, action.id);
  if (response.status == 200) {
    yield put(journalActions.getJournalSuccess(response.data));
  }
}

export function* createDayCallingRequest(api, action) {
  const state = yield select();
  const response = yield call(api.createDayCalling, action.id, action.calling);
  if (response.status == 200) {
    yield put(appActions.showAlert('Journal Day Created!', { variant: 'success' }));
  }
  yield replace(`/journal/${state.journalReducer.journal.id}`);
}

export function* createDayDeaptureRequest(api, action) {
  const state = yield select();
  const response = yield call(api.createDayDeapture, action.id, action.deapture);
  if (response.status == 200) {
    yield put(appActions.showAlert('Journal Day Created!', { variant: 'success' }));
  }
  yield replace(`/journal/${state.journalReducer.journal.id}`);
}

export function* createDayPreparingReturnRequest(api, action) {
  const state = yield select();
  const response = yield call(api.createDayPreparingReturn, action.id, action.preparingReturn);
  if (response.status == 200) {
    yield put(appActions.showAlert('Journal Day Created!', { variant: 'success' }));
  }
  yield replace(`/journal/${state.journalReducer.journal.id}`);
}

export function* createDayTheReturnRequest(api, action) {
  const state = yield select();
  const response = yield call(api.createDayReturn, action.id, action.returnData);
  if (response.status == 200) {
    yield put(appActions.showAlert('Journal Day Created!', { variant: 'success' }));
  }
  yield replace(`/journal/${state.journalReducer.journal.id}`);
}

export function* createDayRoadRequest(api, action) {
  const state = yield select();
  const response = yield call(api.createDayRoad, action.id, action.road);
  if (response.status == 200) {
    yield put(appActions.showAlert('Journal Day Created!', { variant: 'success' }));
  }
  yield replace(`/journal/${state.journalReducer.journal.id}`);
}

export function* getCallingRequest(api, action) {
  const response = yield call(api.getCallingRequest, action.data);
  if (response.status == 200) {
    yield put(journalActions.getCallingSuccess(response.data));
  }
}

export function* getDeaptureRequest(api, action) {
  const response = yield call(api.getDeaptureRequest, action.data);
  if (response.status == 200) {
    yield put(journalActions.getDeaptureSuccess(response.data));
  }
}

export function* getPreparingReturnRequest(api, action) {
  const response = yield call(api.getPreparingReturnRequest, action.data);
  if (response.status == 200) {
    yield put(journalActions.getPreparingReturnSuccess(response.data));
  }
}

export function* getReturnRequest(api, action) {
  const response = yield call(api.getReturnRequest, action.data);
  if (response.status == 200) {
    yield put(journalActions.getReturnSuccess(response.data));
  }
}

export function* getRoadRequest(api, action) {
  const response = yield call(api.getRoadRequest, action.data);
  if (response.status == 200) {
    yield put(journalActions.getRoadSuccess(response.data));
  }
}
