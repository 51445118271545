import React from 'react';
import styled from 'styled-components';
import SuggestionsList from 'components/Meeting/SuggestionsList';
import CreateSuggestionContainer from 'components/Meeting/CreateSuggestionContainer';
import Pagination from 'components/PaginationButtons';

const Container = styled.div``;

const HeaderContainer = styled.div`
  margin: 38px;
  padding-bottom: 5px;
  position: relative;
  width: auto;
  position: relative;
  height: 50px;
`;

const InputActivity = styled.input`
  width: 100%;
  height: 50px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 25)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const SuggestionsView = props => {
  const {
    countryId,
    countries,
    countriesCount,
    currentPage,
    elementsPerPage,
    changePage,
    loading,
    countrySearchableText,
    searchCountry,
    setCountryId,
  } = props;

  return !countryId ? (
    <Container>
      <HeaderContainer>
        <FlexRow>
          <FlexColumn>
            <Pagination
              currentPage={currentPage}
              changePage={changePage}
              totalPages={Math.ceil(countriesCount / elementsPerPage)}
            />
          </FlexColumn>
          <FlexColumn width={30}>
            <InputActivity
              type="text"
              placeholder="Search Country"
              name="searchable"
              value={countrySearchableText}
              onChange={searchCountry}
            />
          </FlexColumn>
        </FlexRow>
      </HeaderContainer>

      <SuggestionsList countries={countries} loading={loading} setCountryId={setCountryId} />
    </Container>
  ) : (
    <CreateSuggestionContainer countryId={countryId} setCountryId={setCountryId} />
  );
};

export default SuggestionsView;
