import React, { Component } from 'react';
import AccommodationForm from './AccommodationForm';
import { connect } from 'react-redux';
import * as accommodationActions from 'actions/accommodationActions';
import * as tripActions from 'actions/tripActions';
import { debounce } from 'lodash';

class CreateAccommodationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 0,
      lat: 0,
    };
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude: lat, longitude: lng } = position.coords;
      this.setState({ lng, lat });
    });
  }

  componentDidUpdate(prevProps) {
    const { places } = this.props;
    if (prevProps.places !== places) {
      this.setState({
        address: places.length > 0 && places[0].place_name,
        showPlaceList: true,
      });
    }
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  updateImage = response => {
    this.setState({ changeAvatar: false, image: response.url });
  };

  clickMap = (map, evt) => {
    const { refreshAddress } = this.props;
    const { lng, lat } = evt.lngLat;
    refreshAddress({ lng, lat });
    this.setState({ lng, lat });
  };

  searchCoordinates = debounce(async () => {
    const { refreshCoordinates } = this.props;
    const { address } = this.state;

    refreshCoordinates(address);
  }, 600);

  handleChangeAddress = event => {
    const address = event.target.value;

    this.setState({ address, addressInput: address });
    this.searchCoordinates();
  };

  selectPlace = place => {
    const { center, place_name } = place;
    this.setState({
      lng: center[0],
      lat: center[1],
      address: place_name,
      addressInput: place_name,
    });
  };

  changeTags = (value, arrayName) => {
    const array = [...this.state[arrayName]];
    const exist = array.indexOf(value);
    if (exist != -1) {
      array.splice(exist, 1);
    } else {
      array.push(value);
    }
    this.setState({
      [arrayName]: array,
    });
  };

  searchData = debounce(async => {
    const { getDestinations } = this.props;
    const { city_name } = this.state;
    getDestinations(city_name);
  }, 600);

  searchCity = event => {
    const search = event.target.value;
    this.setState({ city_name: search });
    this.searchData();
  };

  save = () => {
    const { saveAccommodation } = this.props;
    const { lat: latitude, lng: longitude } = this.state;
    saveAccommodation({ latitude, longitude, ...this.state });
  };

  selectCity = city => {
    this.setState({ city_id: city.id, city_name: city.name });
  };

  updateShowCityList = status => {
    this.setState({ showCityList: status });
  };

  updateShowPlaceList = status => {
    this.setState({ showPlaceList: status });
  };

  replaceImage = () => {
    this.setState({ image: null });
  };

  handleChangeCheckbox = (evt, name) => {
    this.setState({ [name]: evt.target.checked });
  };

  render() {
    return (
      <AccommodationForm
        handleChange={this.handleChange}
        updateImage={this.updateImage}
        clickMap={this.clickMap}
        handleChangeAddress={this.handleChangeAddress}
        selectPlace={this.selectPlace}
        changeTags={this.changeTags}
        saveOrUpdate={this.save}
        searchCity={this.searchCity}
        selectCity={this.selectCity}
        updateShowCityList={this.updateShowCityList}
        updateShowPlaceList={this.updateShowPlaceList}
        replaceImage={this.replaceImage}
        handleChangeCheckbox={this.handleChangeCheckbox}
        {...this.props}
        data={this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    places: state.activityReducer.places,
    citiesFiltered: state.tripReducer.tripForm.citiesFiltered,
    token: state.loginReducer.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    refreshCoordinates: address => dispatch(accommodationActions.refreshCoordinates(address)),
    refreshAddress: address => dispatch(accommodationActions.refreshAddress(address)),
    saveAccommodation: accommodation =>
      dispatch(accommodationActions.saveAccommodation(accommodation)),
    getDestinations: search => dispatch(tripActions.getDestinations(search)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAccommodationContainer);
