import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import Pagination from 'components/PaginationButtons';
import Button from 'components/Button';
import Modal from 'components/Modal';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};

  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  padding: 0px 5px 0px 5px;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const TBody = styled.div``;

const ActionIcon = styled.i`
  color: ${props => props.color};
  font-size: 20px;
  padding-right: 10px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const HeaderContainer = styled.div`
  margin: 38px;
  position: relative;
  width: auto;
  display: flex;
  justify-content: space-between;
`;

class RegionsListView extends Component {
  renderRegions({ id, name }, key) {
    const { selectRegion } = this.props;

    return (
      <Item key={key}>
        <SectionContainer textAlign="left" width={'80%'}>
          <Primary>{name}</Primary>
          <VerticalLine />
        </SectionContainer>

        <SectionContainer width={'20%'} textAlign="right">
          <ActionIcon color="red" className="fas fa-trash-alt" onClick={() => selectRegion(id)} />
          <Link to={`/regions/edit/${id}`}>
            <ActionIcon color="#38b6ff" className="fas fa-edit" />
          </Link>
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const {
      regions,
      regionsCount,
      loading,
      elementsPerPage,
      currentPage,
      changePage,
      removeModalIsOpen,
      toggleModal,
      removeRegion,
      country_id,
    } = this.props;

    return (
      <>
        {loading ? (
          <LoaderContainer>
            <Loader type="pulse" size={125} />
          </LoaderContainer>
        ) : (
          <>
            <HeaderContainer>
              <Pagination
                currentPage={currentPage}
                changePage={changePage}
                totalPages={Math.ceil(regionsCount / elementsPerPage)}
              />
              {country_id ? (
                <Link to={`/regions/create/country/${country_id}`}>
                  <Button>Create Region</Button>
                </Link>
              ) : (
                <Link to="/regions/create">
                  <Button>Create Region</Button>
                </Link>
              )}
            </HeaderContainer>

            <GridContainer>
              <THeader>
                <TitleContainer width={'80%'}>
                  <Title>REGION NAME</Title>
                </TitleContainer>
              </THeader>
              <TBody>
                {regions.map((activity, key) => {
                  return this.renderRegions(activity, key);
                })}
                {(!regions || !regions.length) && (
                  <ListEmpty message="There are currently no regions" />
                )}
              </TBody>
            </GridContainer>

            <Modal
              closeIcon
              isVisible={removeModalIsOpen}
              title="DELETE REGION"
              cancelText="Cancel"
              onCancel={toggleModal}
              successText="Remove"
              onSuccess={removeRegion}
            >
              Are you sure of remove the activity?
            </Modal>
          </>
        )}
      </>
    );
  }
}

export default RegionsListView;
