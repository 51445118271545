import React from 'react';
import styled from 'styled-components';
import './styles.css';
import { Link } from 'react-router-dom';
import defaultAvatar from 'assets/media/images/avatar.png';
import ok from 'assets/media/icons/ok.png';
import pending from 'assets/media/icons/pending.png';
import remove from 'assets/media/icons/remove.png';

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  width: ${props => props.width};
  height: 100%;
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.left ? 'left' : 'center')};
  padding-top: 15px;
`;

const Image = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 19px;
  margin-top: 19px;
  object-fit: cover;
`;

const Primary = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;
const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 10px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: -10px;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
`;
const SelectPlanButton = styled.button`
  background-color: transparent;
  border-width: 0px;
  color: #9e9e9e;
  padding-top: 15px;
  cursor: pointer;
`;

export default class BoutiquePackageListItem extends React.Component {
  renderStatus = status => {
    switch (status) {
      case 'pending':
        return 'Pending';
      case 'approved_for_admin':
        return 'Approved For Admin';
      case 'approved':
        return 'Approved';
      case 'rejected_for_admin':
        return 'Rejected For Admin';
      case 'rejected_for_customer':
        return 'Rejected For Customer';
      default:
        break;
    }
  };

  render() {
    const { item, showDetails } = this.props;

    return (
      <Item>
        <SectionContainer width={'30%'}>
          <Primary>
            {item.user.first_name} {item.user.last_name}
          </Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'25%'}>
          <Primary>{item.user.email}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{this.renderStatus(item.status)}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'25%'}>
          <SelectPlanButton onClick={() => showDetails(item)}>
            View Details <i className="fas fa-chevron-right" />
          </SelectPlanButton>
        </SectionContainer>
      </Item>
    );
  }
}
