import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';

const data = {
  labels: [
    'Stunting Emotions',
    'Growth Emotions',
    'Active Immersion',
    'Connections',
    'Significance',
    'Achievement',
    'Fitness',
    'Overall well-being',
  ],
  datasets: [
    {
      label: 'Measurement of Personal Well-Being Components',
      backgroundColor: '#38b6ff',
      borderColor: '#13a2f4',
      borderWidth: 1,
      hoverBackgroundColor: '#999999',
      hoverBorderColor: 'grey',
      //data: [1, 55, 30, 3, 12, 1, 88],
    },
  ],
};

const options = {
  scales: {
    xAxes: [
      {
        ticks: {
          min: 0,
          max: 100,
          callback: function(value, index) {
            switch (index) {
              case 3:
                return 'Room for Growth';
              case 6:
                return 'Satisfactory';
              case 10:
                return 'Flourishing';
            }
          },
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
  },
};

const WellBeingChart = props => {
  data.datasets[0].data = props.wellBeingResults.map(e => e.percent);
  data.labels = props.wellBeingResults.map(e => e.label);
  return <HorizontalBar data={data} width={100} height={65} options={options} />;
};

export default WellBeingChart;
