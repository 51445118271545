import React, { Component } from 'react';
import CustomerTripDetailView from './CustomerTripDetailView';
import { connect } from 'react-redux';
import moment from 'moment';
import * as tripActions from '../../actions/tripActions';
import * as activityActions from '../../actions/activityActions';
import * as CustomersActions from '../../actions/customerActions';
import * as itineraryActions from '../../actions/itineraryActions';

class CustomerTripDetailsContainer extends Component {
  state = {
    geoloCationAlert: false,
    allDays: null,
    daySelected: 0,
    activitySelected: null,
    activePopup: true,
    currentLocation: [0, 0],
    activeTab: 'Trip Overview',
    noDaysAlert: false,
    tabs: [],
    tripCostModal: false,
    addMember: false,
    currentPage: 1,
    elementsPerPage: 10,
    searchFriend: '',
    costs: [...this.props.tripCostDetails],
  };

  componentDidMount() {
    const {
      getItinerary,
      fetchTrip,
      onGetCosts,
      onGetFriends,
      match: { params },
    } = this.props;

    getItinerary(params.itinerary_id);
    fetchTrip(params.trip_id);
    onGetCosts(params.trip_id);
    onGetFriends();

    navigator.geolocation.getCurrentPosition((position, error) => {
      const { latitude, longitude } = position.coords;
      this.setState({ currentLocation: [longitude, latitude] });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    var allDays = [];
    const { tripDetails, tripCostDetails, itineraryForCustomer } = this.props;

    navigator.geolocation.getCurrentPosition((position, error) => {
      const { latitude, longitude } = position.coords;
      if (prevState.currentLocation[0] != latitude) {
        this.setState({ currentLocation: [longitude, latitude] });
      }
    });

    if (prevProps.tripCostDetails != tripCostDetails) {
      this.setState({ costs: this.props.tripCostDetails });
    }

    if (prevProps.itineraryForCustomer != itineraryForCustomer) {
      itineraryForCustomer.stops.forEach(stop =>
        stop.days.forEach(day => {
          allDays.push({ city: stop.city, ...day });
        }),
      );
      if (allDays.length > 0) {
        this.calculateDayTimes(allDays[0]);
        this.setState({
          allDays,
          tabs: [
            {
              action: () => this.changeTab('Trip Overview'),
              title: 'Trip Overview',
            },
            {
              action: () => this.changeTab('Day Overview'),
              title: 'Day Overview',
            },
          ],
        });
      } else {
        this.setState({
          allDays,
          tabs: [
            {
              action: () => this.changeTab('Trip Overview'),
              title: 'Trip Overview',
            },
            {
              action: () => this.noDayAlert(),
              title: 'Day Overview',
            },
          ],
        });
      }
    }
  }

  handleChange = (index, value) => {
    let { costs } = this.state;
    let newCost = [...costs];
    let newItem = { ...newCost[index], percentage: Math.round(parseInt(value, 10)) };
    newCost[index] = newItem;
    this.setState({
      costs: newCost,
    });
  };

  addFriend = value => {
    const {
      match: { params },
    } = this.props;
    const friends = [...this.state.costs];
    const exist = friends.findIndex(friend => friend.user_id == value.id);
    if (exist != -1) {
      friends.splice(exist, 1);
    } else {
      if (friends.length < 5) {
        friends.push({
          percentage: 0,
          email: value.email,
          user_id: value.id,
          trip_id: params.id,
          status: 'pending',
          user: value,
          amount: 0,
        });
      }
    }
    this.setState({
      costs: friends,
    });
  };

  editTripCost = () => {
    const {
      upadateCost,
      match: { params },
    } = this.props;
    const { costs } = this.state;

    upadateCost(params.trip_id, costs);
    this.setState({ tripCostModal: !this.state.tripCostModal, addMember: false });
  };

  getFriend = evt => {
    this.setState({ searchFriend: evt.target.value });
    this.props.onGetFriends(this.state.searchFriend);
  };

  toggleTripCostModal = () => {
    const {
      onGetCosts,
      match: { params },
    } = this.props;
    this.setState({ tripCostModal: !this.state.tripCostModal, addMember: false });

    if (this.state.tripCostModal) {
      onGetCosts(params.id);
    }
    this.props.onGetFriends();
  };

  toggleAddMember = () => {
    this.setState({ addMember: !this.state.addMember });
  };

  changeTab = activeTab => {
    this.setState({
      activeTab,
    });
  };

  noDayAlert = () => {
    this.setState({
      noDaysAlert: !this.state.noDaysAlert,
    });
  };

  getGreetingTime = m => {
    if (!m || !m.isValid()) {
      return;
    }
    const split_afternoon = 12;
    const split_evening = 17;
    const currentHour = parseFloat(m.format('HH'));
    if (currentHour >= split_afternoon && currentHour <= split_evening) {
      return 'afternoon';
    } else if (currentHour >= split_evening) {
      return 'evening';
    } else {
      return 'morning';
    }
  };

  activityIcon = category => {
    let icon, color;
    switch (category) {
      case 'restaurant':
        icon = 'fas fa-utensils';
        color = '#EF4B8D';
        break;
      case 'accommodation':
        icon = 'fas fa-bed';
        color = '#4CB0E8';
        break;
      default:
        icon = 'fas fa-route';
        color = '#3CA555';
        break;
    }
    return { icon, color };
  };

  changeDay = daySelected => {
    const { allDays } = this.state;
    this.setState({ daySelected });

    this.calculateDayTimes(allDays[daySelected]);
  };

  calculateDayTimes = day => {
    const { currentLocation } = this.state;
    const dayCoordinates = [{ lng: currentLocation[0], lat: currentLocation[1] }];
    if (this.state.currentLocation[0] == 0) {
      this.setState({ geoloCationAlert: true });
    } else {
      this.setState({ geoloCationAlert: false });
    }
    day.activities.forEach(act => {
      dayCoordinates.push({
        lat: act.activity.latitude,
        lng: act.activity.longitude,
      });
    });

    const { onGetDistanceTime } = this.props;
    onGetDistanceTime(dayCoordinates);
  };

  showPopup = () => {
    this.setState({ activePopup: !this.state.activePopup });
  };

  selectActivity = activitySelected => {
    this.setState({ activitySelected });
  };

  filterForDayMoment = (activities, dayMoment) => {
    const filteredActivities = [];
    for (let step = 0; step < activities.length; step++) {
      const act = activities[step];
      if (this.getGreetingTime(moment(act.start_date)) == dayMoment) {
        filteredActivities.push({ ...act, position: step });
      }
    }
    return filteredActivities;
  };

  viewDayDetail = daySelected => {
    const { allDays } = this.state;
    this.calculateDayTimes(allDays[daySelected]);
    this.setState({
      daySelected,
      activeTab: 'Day Overview',
    });
  };

  approveTripCustomer = () => {
    const {
      tripDetails,
      editTripSimple,
      itineraryForCustomer,
      markItineraryAsDefault,
    } = this.props;
    editTripSimple(tripDetails.id, { status: 'user_approve' });
    markItineraryAsDefault({ itinerary_id: itineraryForCustomer.id, trip_id: tripDetails.id });
  };

  render() {
    const {
      allDays,
      daySelected,
      activitySelected,
      activePopup,
      currentLocation,
      activeTab,
      tabs,
      noDaysAlert,
      geoloCationAlert,
      tripCostModal,
      addMember,
      searchFriend,
      costs,
    } = this.state;

    return (
      <CustomerTripDetailView
        {...this.props}
        allDays={allDays}
        costs={costs}
        activityIcon={this.activityIcon}
        getGreetingTime={this.getGreetingTime}
        getFriend={this.getFriend}
        daySelected={daySelected}
        changeDay={this.changeDay}
        activitySelected={activitySelected}
        toggleTripCostModal={this.toggleTripCostModal}
        toggleAddMember={this.toggleAddMember}
        addMember={addMember}
        tripCostModal={tripCostModal}
        showPopup={this.showPopup}
        activePopup={activePopup}
        currentLocation={currentLocation}
        selectActivity={this.selectActivity}
        filterForDayMoment={this.filterForDayMoment}
        tabs={tabs}
        activeTab={activeTab}
        viewDayDetail={this.viewDayDetail}
        noDaysAlert={noDaysAlert}
        noDayAlert={this.noDayAlert}
        geoloCationAlert={geoloCationAlert}
        approveTripCustomer={this.approveTripCustomer}
        handleChange={this.handleChange}
        editTripCost={this.editTripCost}
        addFriend={this.addFriend}
      />
    );
  }
}

function mapStateToProps(state) {
  const customer = state.customerReducer.customer ? state.customerReducer.customer.profile : null;
  const coordinates =
    state.activityReducer.places.length > 0 ? state.activityReducer.places[0].center : null;

  const tripDetails = state.tripReducer.tripDetails;
  const profile = state.loginReducer.profile;

  const tripCostBase = [
    {
      percentage: 100,
      email: profile.email,
      user_id: profile.id,
      trip_id: tripDetails ? tripDetails.id : null,
      status: 'pending',
      user: profile,
      amount: 0,
    },
  ];

  return {
    tripDetails,
    tripCostDetails: state.tripReducer.costs.length ? state.tripReducer.costs : tripCostBase,
    role_code: state.loginReducer.profile.role_code,
    customer,
    friends: state.customerReducer.friends,
    coordinates,
    walkingDurations: state.activityReducer.walkingDurations,
    drivingDurations: state.activityReducer.drivingDurations,
    profile,
    itineraryForCustomer: state.itineraryReducer.itineraryForCustomer,
    stops: state.itineraryReducer.stops,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTrip: id => dispatch(tripActions.getTrip(id)),
    refreshCoordinates: address => dispatch(activityActions.refreshCoordinates(address)),
    onGetDistanceTime: points => dispatch(activityActions.getDistanceTime(points)),
    editTripSimple: (id, data) => dispatch(tripActions.editTripSimple(id, data)),
    onGetFriends: search => dispatch(CustomersActions.getFriends(search)),
    onGetCosts: id => dispatch(tripActions.getCosts(id)),
    upadateCost: (id, data) => dispatch(tripActions.updateCosts(id, data)),
    getItinerary: id => dispatch(itineraryActions.getItinerary(id)),
    markItineraryAsDefault: data => dispatch(itineraryActions.markItineraryAsDefault(data)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerTripDetailsContainer);
