import React, { Component } from 'react';
import DiagnosticFinishedView from './DiagnosticFinishedView';
import { connect } from 'react-redux';
import * as diagnosticActions from '../../../actions/diagnosticActions';

class DiagnosticFinishedContainer extends Component {
  changeStep = async step => {
    const { changeCurrentStep } = this.props;
    changeCurrentStep(step);
  };

  render() {
    return <DiagnosticFinishedView {...this.props} changeStep={this.changeStep} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(diagnosticActions.changeCurrentStep(step)),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(DiagnosticFinishedContainer);
