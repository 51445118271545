import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  courses: [],
};

export default {
  courseReducer: handleActions(
    {
      [ActionTypes.GET_CUSTOMER_COURSES_REQUEST](state, action) {
        return {
          ...state,
          courses: action.courses,
          loading: true,
        };
      },
      [ActionTypes.GET_CUSTOMER_COURSES_SUCCESS](state, action) {
        return {
          ...state,
          courses: action.courses,
          loading: false,
        };
      },
    },
    appState,
  ),
};
