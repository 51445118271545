import React, { Component } from 'react';
import StepContainerWithImage from '../../StepContainerWithImage';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import ConceptContent from '../../../components/ConceptContent';

const ExplanacionConceptsBox = styled.div`
  margin-left: 50px;
  margin-right: 30px;
  height: 535px;
  @media (max-width: 1000px) {
    margin-left: 15%;
    margin-right: 15%;
  }
  @media (max-width: 500px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

class ExplanacionConceptsView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { changeStep, state, stepFields } = this.props;
    const { localStep } = state;

    return (
      <StepContainerWithImage
        globalTitle={stepFields.title}
        globalSubtitle={stepFields.subtitle}
        image={stepFields.image}
      >
        <ExplanacionConceptsBox>
          <div style={{ height: '85%' }}>
            <ConceptContent
              isHtml
              maxHeight={370}
              title={stepFields.title}
              content={stepFields.body}
            />
          </div>
          <StepButtons
            customNextText={localStep == 1 ? "Let's start" : null}
            style={{ height: '26%' }}
            justifyContent={'space-between'}
            hiddenBack={localStep == 1}
            backStep={() => changeStep(localStep - 1)}
            nextStep={() => changeStep(localStep + 1)}
          />
        </ExplanacionConceptsBox>
      </StepContainerWithImage>
    );
  }
}

export default ExplanacionConceptsView;
