import React, { Component } from 'react';
import RegisterSuccessView from './RegisterSuccessView';

class RegisterSuccess extends Component {
  render() {
    return <RegisterSuccessView />;
  }
}

export default RegisterSuccess;
