import React from 'react';
import styled from 'styled-components';
import { appColor } from 'modules/theme';
import Button from 'components/Button';
import { MONTHS } from 'constants/index';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DATE_LIST } from 'constants/index';

const GridContainer = styled.div``;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  line-height: 0;
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

const InputActivity = styled.input`
  width: 100%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(248, 247, 247, 1);
  background-color: #ffffff;
  border: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 25%;
`;
const InputCity = styled.input`
  margin-top: 15%;
  height: 100%;
  background-color: #ffffff;
  border: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
`;

const Select = styled.select`
  width: 100%;
  height: 52px;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #616161;
  margin-left: 5px;
  margin-right: 20px;
`;

const LabelClimates = styled.p`
  width: ${props => props.width}px;
  height: 30%;
  font-size: 15px;
  padding: ${props => props.padding}rem;
  color: black;
  border: 2px solid;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  background-color: ${props => props.backgroundColor};
`;
const BoxContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  border-radius: 5px;
`;

const BoxClimates = styled.div`
  overflow-x: auto;
  display: block;
  max-width: 100%;
  display: grid;
`;

const PaddingResult = styled.div`
  padding-top: ${props => props.paddingTop}rem;
`;

const AddClimateCityForm = props => {
  const {
    viewMode,
    isEditing,
    handleisEditing,
    setClimate,
    handleAddCity,
    climate,
    country: { id },
  } = props;

  const selectClimate = (name, values, handleChange) => {
    let value = '';
    switch (name) {
      //january
      case 'average_high_january':
        value = values.average_high_january;
        break;
      case 'average_low_january':
        value = values.average_low_january;
        break;
      case 'daily_chance_of_precipitation_january':
        value = values.daily_chance_of_precipitation_january;
        break;
      case 'average_dew_point_january':
        value = values.average_dew_point_january;
        break;
      case 'average_sunshine_hours_january':
        value = values.average_sunshine_hours_january;
        break;
      //february
      case 'average_high_february':
        value = values.average_high_february;
        break;
      case 'average_low_february':
        value = values.average_low_february;
        break;
      case 'daily_chance_of_precipitation_february':
        value = values.daily_chance_of_precipitation_february;
        break;
      case 'average_dew_point_february':
        value = values.average_dew_point_february;
        break;
      case 'average_sunshine_hours_february':
        value = values.average_sunshine_hours_february;
        break;
      //march
      case 'average_high_march':
        value = values.average_high_march;
        break;
      case 'average_low_march':
        value = values.average_low_march;
        break;
      case 'daily_chance_of_precipitation_march':
        value = values.daily_chance_of_precipitation_march;
        break;
      case 'average_dew_point_march':
        value = values.average_dew_point_march;
        break;
      case 'average_sunshine_hours_march':
        value = values.average_sunshine_hours_march;
        break;
      //april
      case 'average_high_april':
        value = values.average_high_april;
        break;
      case 'average_low_april':
        value = values.average_low_april;
        break;
      case 'daily_chance_of_precipitation_april':
        value = values.daily_chance_of_precipitation_april;
        break;
      case 'average_dew_point_april':
        value = values.average_dew_point_april;
        break;
      case 'average_sunshine_hours_april':
        value = values.average_sunshine_hours_april;
        break;
      //may
      case 'average_high_may':
        value = values.average_high_may;
        break;
      case 'average_low_may':
        value = values.average_low_may;
        break;
      case 'daily_chance_of_precipitation_may':
        value = values.daily_chance_of_precipitation_may;
        break;
      case 'average_dew_point_may':
        value = values.average_dew_point_may;
        break;
      case 'average_sunshine_hours_may':
        value = values.average_sunshine_hours_may;
        break;
      //june
      case 'average_high_june':
        value = values.average_high_june;
        break;
      case 'average_low_june':
        value = values.average_low_june;
        break;
      case 'daily_chance_of_precipitation_june':
        value = values.daily_chance_of_precipitation_june;
        break;
      case 'average_dew_point_june':
        value = values.average_dew_point_june;
        break;
      case 'average_sunshine_hours_june':
        value = values.average_sunshine_hours_june;
        break;
      //july
      case 'average_high_july':
        value = values.average_high_july;
        break;
      case 'average_low_july':
        value = values.average_low_july;
        break;
      case 'daily_chance_of_precipitation_july':
        value = values.daily_chance_of_precipitation_july;
        break;
      case 'average_dew_point_july':
        value = values.average_dew_point_july;
        break;
      case 'average_sunshine_hours_july':
        value = values.average_sunshine_hours_july;
        break;
      //august
      case 'average_high_august':
        value = values.average_high_august;
        break;
      case 'average_low_august':
        value = values.average_low_august;
        break;
      case 'daily_chance_of_precipitation_august':
        value = values.daily_chance_of_precipitation_august;
        break;
      case 'average_dew_point_august':
        value = values.average_dew_point_august;
        break;
      case 'average_sunshine_hours_august':
        value = values.average_sunshine_hours_august;
        break;
      //september
      case 'average_high_september':
        value = values.average_high_september;
        break;
      case 'average_low_september':
        value = values.average_low_september;
        break;
      case 'daily_chance_of_precipitation_september':
        value = values.daily_chance_of_precipitation_september;
        break;
      case 'average_dew_point_september':
        value = values.average_dew_point_september;
        break;
      case 'average_sunshine_hours_september':
        value = values.average_sunshine_hours_september;
        break;
      //october
      case 'average_high_october':
        value = values.average_high_october;
        break;
      case 'average_low_october':
        value = values.average_low_october;
        break;
      case 'daily_chance_of_precipitation_october':
        value = values.daily_chance_of_precipitation_october;
        break;
      case 'average_dew_point_october':
        value = values.average_dew_point_october;
        break;
      case 'average_sunshine_hours_october':
        value = values.average_sunshine_hours_october;
        break;
      //november
      case 'average_high_november':
        value = values.average_high_november;
        break;
      case 'average_low_november':
        value = values.average_low_november;
        break;
      case 'daily_chance_of_precipitation_november':
        value = values.daily_chance_of_precipitation_november;
        break;
      case 'average_dew_point_november':
        value = values.average_dew_point_november;
        break;
      case 'average_sunshine_hours_november':
        value = values.average_sunshine_hours_november;
        break;
      //december
      case 'average_high_december':
        value = values.average_high_december;
        break;
      case 'average_low_december':
        value = values.average_low_december;
        break;
      case 'daily_chance_of_precipitation_december':
        value = values.daily_chance_of_precipitation_december;
        break;
      case 'average_dew_point_december':
        value = values.average_dew_point_december;
        break;
      case 'average_sunshine_hours_december':
        value = values.average_sunshine_hours_december;
        break;
      default:
        break;
    }
    1;

    return (
      <InputActivity
        type="number"
        placeholder="0"
        name={name}
        value={value}
        onChange={handleChange}
        disabled={viewMode}
      />
    );
  };

  const renderPerMonths = (values, handleChange) =>
    MONTHS.map((month, key) => {
      const monthLowerCase = month.toLocaleLowerCase();
      const averageHigh = 'average_high_' + monthLowerCase;
      const averageLow = 'average_low_' + monthLowerCase;
      const dailyChancePrecipitation = 'daily_chance_of_precipitation_' + monthLowerCase;
      const averageDewPoint = 'average_dew_point_' + monthLowerCase;
      const averageSunshineHours = 'average_sunshine_hours_' + monthLowerCase;
      return (
        <FlexRow>
          <LabelClimates width={'200'} backgroundColor={'#56C1FE'}>
            Average High - {month}
            <PaddingResult paddingTop={3.2}>
              {selectClimate(averageHigh, values, handleChange)}
            </PaddingResult>
          </LabelClimates>
          <LabelClimates width={'200'} backgroundColor={'#56C1FE'}>
            Average Low - {month}
            <PaddingResult paddingTop={3.2}>
              {selectClimate(averageLow, values, handleChange)}
            </PaddingResult>
          </LabelClimates>
          <LabelClimates width={'350'} backgroundColor={'#56C1FE'}>
            Daily Chance Of Precipitation - {month}
            <PaddingResult paddingTop={3.2}>
              {selectClimate(dailyChancePrecipitation, values, handleChange)}
            </PaddingResult>
          </LabelClimates>
          <LabelClimates width={'250'} backgroundColor={'#56C1FE'}>
            Average Dew Point - {month}
            <PaddingResult paddingTop={3.2}>
              {selectClimate(averageDewPoint, values, handleChange)}
            </PaddingResult>
          </LabelClimates>
          <LabelClimates width={'280'} backgroundColor={'#56C1FE'}>
            Average Sunshine Hours - {month}
            <PaddingResult paddingTop={3.2}>
              {selectClimate(averageSunshineHours, values, handleChange)}
            </PaddingResult>
          </LabelClimates>
        </FlexRow>
      );
    });

  return (
    <GridContainer>
      <FlexRow style={{ justifyContent: 'space-between', marginBottom: 20 }}>
        {isEditing ? <Label>Edit City</Label> : <Label>Add New City</Label>}
        <Button
          onClick={() => {
            handleAddCity(false);
            handleisEditing(false);
            setClimate(null);
          }}
          style={{ marginTop: 0 }}
        >
          Back
        </Button>
      </FlexRow>
      <Formik
        enableReinitialize
        initialValues={climate}
        isInitialValid={isEditing ? true : false}
        validationSchema={Yup.object().shape({
          city: Yup.string().required('Required'),
          region: Yup.string().required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, resetForm }) => {
          return (
            <>
              <GridContainer>
                <FlexRow
                  style={{ justifyContent: 'space-between', marginBottom: 40, marginTop: 20 }}
                >
                  <FlexColumn>
                    <BoxClimates>
                      <BoxContainer>
                        <FlexColumn marginRight={0}>
                          <InputCity
                            marginBottom={1}
                            type="text"
                            placeholder="city"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.city && touched.city}
                            min={0}
                            disabled={viewMode}
                          />
                        </FlexColumn>
                        {renderPerMonths(values, handleChange)}

                        <FlexColumn>
                          <LabelClimates width={'280'} backgroundColor={'#56C1FE'}>
                            Best time to visit for weather start date
                          </LabelClimates>
                          {!viewMode ? (
                            <PaddingResult
                              name="best_time_to_visit_for_weather_start_date"
                              value={values.best_time_to_visit_for_weather_start_date}
                              onChange={handleChange}
                              disabled={viewMode}
                            >
                              {DATE_LIST.map(date => (
                                <option value={`${date.value}`}>{date.name}</option>
                              ))}
                            </PaddingResult>
                          ) : (
                            <InputActivity
                              marginBottom={1}
                              value={values.best_time_to_visit_for_weather_start_date}
                              disabled={viewMode}
                            />
                          )}
                        </FlexColumn>
                        <FlexColumn>
                          <LabelClimates width={'280'} backgroundColor={'#56C1FE'}>
                            Best time to visit for weather end date
                          </LabelClimates>
                          {!viewMode ? (
                            <PaddingResult
                              name="best_time_to_visit_for_weather_end_date"
                              value={values.best_time_to_visit_for_weather_end_date}
                              onChange={handleChange}
                              disabled={viewMode}
                            >
                              {DATE_LIST.map(date => (
                                <option value={`${date.value}`}>{date.name}</option>
                              ))}
                            </PaddingResult>
                          ) : (
                            <InputActivity
                              marginBottom={1}
                              value={values.best_time_to_visit_for_weather_end_date}
                              disabled={viewMode}
                            />
                          )}
                        </FlexColumn>
                        <FlexColumn>
                          <LabelClimates width={'285'} backgroundColor={'#56C1FE'}>
                            Best time to visit for weather start date 2
                          </LabelClimates>
                          {!viewMode ? (
                            <PaddingResult
                              name="best_time_to_visit_for_weather_start_date_2"
                              value={values.best_time_to_visit_for_weather_start_date_2}
                              onChange={handleChange}
                              disabled={viewMode}
                            >
                              {DATE_LIST.map(date => (
                                <option value={`${date.value}`}>{date.name}</option>
                              ))}
                            </PaddingResult>
                          ) : (
                            <InputActivity
                              marginBottom={1}
                              value={values.best_time_to_visit_for_weather_start_date_2}
                              disabled={viewMode}
                            />
                          )}
                        </FlexColumn>
                        <FlexColumn>
                          <LabelClimates width={'280'} backgroundColor={'#56C1FE'}>
                            Best time to visit for weather end date 2
                          </LabelClimates>
                          {!viewMode ? (
                            <PaddingResult
                              name="best_time_to_visit_for_weather_end_date_2"
                              value={values.best_time_to_visit_for_weather_end_date_2}
                              onChange={handleChange}
                            >
                              {DATE_LIST.map(date => (
                                <option value={`${date.value}`}>{date.name}</option>
                              ))}
                            </PaddingResult>
                          ) : (
                            <InputActivity
                              marginBottom={1}
                              value={values.best_time_to_visit_for_weather_end_date_2}
                              disabled={viewMode}
                            />
                          )}
                        </FlexColumn>
                      </BoxContainer>
                    </BoxClimates>
                  </FlexColumn>
                </FlexRow>
              </GridContainer>
            </>
          );
        }}
      </Formik>
    </GridContainer>
  );
};

export default AddClimateCityForm;
