import React from 'react';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import Modal from 'components/Modal';
import './styles.css';
import defaultAvatar from 'assets/media/images/avatar.png';
import Checkbox from '../components/Login/CheckboxRemember';
import RangeInput from 'components/RangeInput';

const FlexBox = styled.div`
  display: flex;
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'initial')};
  width: ${props => props.width}%;
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
`;

const ScreenPart = styled.div`
  width: ${props => props.width}%;
  margin: 20px 10px;
`;

const Avatar = styled.img`
  vertical-align: middle;
  width: 35px;
  height: 35px;
  margin: 5px;
  margin-right: 15px;
  border-radius: 50%;
  object-fit: cover;
`;

const TitleTripCost = styled.p`
  position: static;
  width: 145px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
`;
const TextTripCost = styled.p`
  position: static;
  margin: 20px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #818181;
`;

const CreateTripCostContainer = styled.a`
  &:hover {
    opacity: 0.5;
  }
`;

const ItemTripCost = styled.div`
  margin-top: 30px;
`;
const AddFriendText = styled.p`
  color: #38b6ff;
  font-size: 20px;
  margin: 50px 0px 30px 0px;
`;

const TitlePage = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #3e3e3e;
`;

const InputActivity = styled.input`
  width: 50%;
  height: 50px;
  object-fit: contain;
  border-radius: 5px;
  background-color: #f7fafc;
  border: none;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 5)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const renderCustomerTripCost = (cost, profile, addFriend, handleChange, key) => {
  return (
    <FlexBox flexDirection="columm" justifyContent="center" style={{ width: '33%' }}>
      <div
        style={{
          border: '1px solid #C4C4C4',
          margin: '10px',
          boxSizing: 'border-box',
          borderRadius: '7px',
          minWidth: '80%',
        }}
      >
        <FlexBox style={{ paddingLeft: '10px', marginTop: '5px' }}>
          <ScreenPart
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            width={95}
          >
            <Avatar src={cost.user.avatar || defaultAvatar} />
            <p
              style={{
                fontWweight: 'bold',
                fontSize: '24px',
                color: '#000000',
                marginTop: '10px',
              }}
            >
              {profile.id != cost.user.id ? cost.user.first_name : 'You'}
            </p>
          </ScreenPart>
          <ScreenPart>
            {profile.id != cost.user.id && (
              <i
                class="fas fa-minus-circle"
                onClick={() => addFriend(cost.user)}
                style={{ color: 'red', marginTop: '10px', fontSize: '18px' }}
              ></i>
            )}
          </ScreenPart>
        </FlexBox>
        <FlexBox flexDirection={'row'} justifyContent="center">
          <FlexBox flexDirection={'column'} style={{ paddingLeft: '10px', marginTop: '5px' }}>
            <p
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '16px',
                color: '#818181',
              }}
            >
              Trip payment precent
            </p>
          </FlexBox>
          <FlexBox flexDirection={'column'} style={{ paddingLeft: '10px', marginTop: '5px' }}>
            <p
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '16px',
                color: '#000000',
              }}
            >
              {`${cost.percentage}%`}
            </p>
          </FlexBox>
        </FlexBox>

        <FlexBox
          flexDirection={'column'}
          style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '5px' }}
          width={'100%'}
        >
          <RangeInput
            hiddenValue
            name="price_total_budget"
            min="0"
            max="100"
            step="1"
            value={cost.percentage}
            handleChange={evt => handleChange(key, evt.target.value)}
          />
        </FlexBox>

        <FlexBox
          flexDirection={'column'}
          style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '5px' }}
          width={'100%'}
        >
          <p
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '14px',
              lineHeight: '16px',
              color: '#000000',
            }}
          >
            {`Total: ${cost.amount}`}
          </p>
        </FlexBox>
      </div>
    </FlexBox>
  );
};

const renderCustomer = (friend, profile, addFriend, costs, key) => {
  if (profile.id == friend.id) {
    return;
  } else {
    return (
      <>
        <FlexBox
          width={100}
          alignItems={'start'}
          style={{
            backgroundColor: '#f7fafc',
            borderRadius: '5px',
            marginBottom: '10px',
            marginTop: '10px',
            border: '1px solid #999',
          }}
          flexDirection={'row'}
          justifyContent={'center'}
        >
          <ScreenPart
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            width={95}
          >
            <Avatar src={friend.avatar || defaultAvatar} />
            <p>{`${friend.first_name} ${friend.last_name}`}</p>
          </ScreenPart>
          <ScreenPart width={5} onClick={() => addFriend(friend)}>
            <Checkbox
              style={{ justifyContent: 'end', marginTop: '5px', marginBottom: '5px' }}
              labelInverted
              name={friend.first_name}
              isChecked={costs.findIndex(cost => cost.user_id == friend.id) != -1}
            />
          </ScreenPart>
        </FlexBox>
      </>
    );
  }
};

const TripCostModal = props => {
  const {
    tripCostModal,
    toggleTripCostModal,
    toggleAddMember,
    addMember,
    friends,
    searchFriend,
    getFriend,
    profile,
    costs,
    handleChange,
    editTripCost,
    addFriend,
  } = props;

  const sumOfCosts = costs.reduce((accum, cost) => {
    return accum + cost.percentage;
  }, 0);

  const count = costs.length;

  return (
    <Modal
      closeIcon
      isVisible={tripCostModal}
      onCancel={toggleTripCostModal}
      onSuccess={editTripCost}
      disabled={sumOfCosts !== 100}
      width={'50%'}
      widthMedium={'70%'}
    >
      {!addMember ? (
        <>
          <TitleTripCost>Trip Cost</TitleTripCost>
          <TextTripCost>
            You can split the payment between your friends. You can add up to 5 friends
          </TextTripCost>
          <FlexBox flexDirection="row" wrap="wrap">
            {count < 5 && (
              <FlexBox flexDirection="columm" justifyContent="center" style={{ width: '33%' }}>
                <CreateTripCostContainer
                  onClick={toggleAddMember}
                  style={{
                    border: '1px dashed #38b6ff',
                    padding: '25px',
                    margin: '10px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    boxSizing: 'border-box',
                    borderRadius: '7px',
                    minWidth: '80%',
                  }}
                >
                  <ItemTripCost>
                    <i
                      className="fas fa-plus-circle"
                      style={{ color: '#38B6FF', fontSize: '60px' }}
                    />
                    <AddFriendText>Add Friend</AddFriendText>
                  </ItemTripCost>
                </CreateTripCostContainer>
              </FlexBox>
            )}
            {costs.map((cost, key) =>
              renderCustomerTripCost(cost, profile, addFriend, handleChange, key),
            )}
          </FlexBox>
          {sumOfCosts !== 100 && (
            <TextTripCost style={{ color: 'red' }}>
              The sum of the percentages must be 100%
            </TextTripCost>
          )}
        </>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <i
              className="fas fa-long-arrow-alt-left"
              style={{ color: '#999', fontSize: '20px', cursor: 'pointer' }}
              onClick={toggleAddMember}
            ></i>
          </div>
          <FlexBox>
            <TitlePage>Choose Friends</TitlePage>
            <TitlePage style={{ marginLeft: '20px' }}>{`${count}/5`}</TitlePage>
          </FlexBox>

          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <InputActivity
              type="text"
              placeholder="Search Friends"
              name="citySearchableText"
              value={searchFriend}
              onChange={getFriend}
            />
          </div>

          {friends.map((friend, key) => renderCustomer(friend, profile, addFriend, costs, key))}
        </>
      )}
    </Modal>
  );
};

export default TripCostModal;
