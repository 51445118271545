import React, { Component } from 'react';
import SelectWellnessView from './SelectWellnessView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import { WELLNESS } from 'constants/index';

const wellnessMaping = Object.fromEntries(
  WELLNESS.map(wellness => [
    wellness,
    wellness
      .toLowerCase()
      .replaceAll(/\s/g, '_')
      .replaceAll('_&', '')
      .replaceAll(',', '')
      .replaceAll('/', '_'),
  ]),
);
class SelectWellnessContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: 0,
      gyms: 0,
      massage: 0,
      meditation: 0,
      other_wellness: 0,
      resorts_retreats: 0,
      saunas_bathhouses: 0,
      spas_thermal_springs: 0,
      yoga: 0,
    };
  }
  componentDidMount() {
    const {
      classes,
      gyms,
      massage,
      meditation,
      other_wellness,
      resorts_retreats,
      saunas_bathhouses,
      spas_thermal_springs,
      yoga,
    } = this.props;
    this.setState({
      classes,
      gyms,
      massage,
      meditation,
      other_wellness,
      resorts_retreats,
      saunas_bathhouses,
      spas_thermal_springs,
      yoga,
    });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const {
      classes,
      gyms,
      massage,
      meditation,
      other_wellness,
      resorts_retreats,
      saunas_bathhouses,
      spas_thermal_springs,
      yoga,
    } = this.state;
    changeCurrentStep(step);
    saveTripData({
      wellness: {
        classes,
        gyms,
        massage,
        meditation,
        other_wellness,
        resorts_retreats,
        saunas_bathhouses,
        spas_thermal_springs,
        yoga,
      },
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <SelectWellnessView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        wellnessMaping={wellnessMaping}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    classes: state.tripReducer.tripForm.wellness.classes,
    gyms: state.tripReducer.tripForm.wellness.gyms,
    massage: state.tripReducer.tripForm.wellness.massage,
    meditation: state.tripReducer.tripForm.wellness.meditation,
    other_wellness: state.tripReducer.tripForm.wellness.other_wellness,
    resorts_retreats: state.tripReducer.tripForm.wellness.resorts_retreats,
    saunas_bathhouses: state.tripReducer.tripForm.wellness.saunas_bathhouses,
    spas_thermal_springs: state.tripReducer.tripForm.wellness.spas_thermal_springs,
    yoga: state.tripReducer.tripForm.wellness.yoga,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectWellnessContainer);
