import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import { Formik, FieldArray } from 'formik';

import paperbin from 'assets/media/icons/papelera.png';
import Checkbox from '../../Login/CheckboxRemember';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

const IconAdd = styled.button`
  bottom: 18.75%;
  color: blue;
`;
const IconDelete = styled.button`
  bottom: 18.75%;
  color: red;
`;
const Text = styled.text`
  width: 100%;
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18.5px;
  line-height: 18px;
  text-transform: capitalize;
  color: #999999;
  margin-top: 40px;
  margin-bottom: 7px;
`;

const TextArea = styled.textarea`
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  height: 150px;
  resize: none;
  padding: 10px;
  width: 100%;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
`;

const InputActivity = styled.input`
  width: 100%;
  height: 80px;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
`;

const FlexRow = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
`;

const Paperbin = styled.img`
  width: 30px;
  height: 15px;
`;

const PastDestinationBox = styled.div`
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 6px 18px 0 rgb(0 0 0 / 4%);
  background-color: #fbfbfb;
  margin-bottom: 15px;
`;

const renderDestination = (destinationObj, key, form, push, remove, errors = {}) => {
  const pastDestinationsSize = form.values.past_destinations.length;
  const changeValue = e => {
    e.stopPropagation();
    form.setFieldValue(e.target.name, e.target.value);
  };

  const handleChangeCheckbox = e => {
    e.stopPropagation();
    form.setFieldValue(`past_destinations.${key}.like_back`, e.target.checked);
  };

  return (
    <PastDestinationBox>
      <FlexRow>
        <FlexColumn marginLeft={3} marginRight={3}>
          <Text>Destination</Text>
          <InputActivity
            placeholder="Destination"
            type="text"
            name={`past_destinations.${key}.destination`}
            value={destinationObj.destination}
            onChange={changeValue}
          />
        </FlexColumn>
        <FlexColumn marginLeft={3} marginRight={3}>
          <Text>Year</Text>
          <InputActivity
            placeholder="Year"
            type="number"
            name={`past_destinations.${key}.year`}
            value={destinationObj.year}
            onChange={changeValue}
          />
        </FlexColumn>
      </FlexRow>
      <FlexRow>
        <FlexColumn marginLeft={3} marginRight={3}>
          <Text>Description</Text>
          <TextArea
            placeholder="Description"
            type="text"
            name={`past_destinations.${key}.description`}
            value={destinationObj.description}
            onChange={changeValue}
          />
        </FlexColumn>
      </FlexRow>
      <FlexRow>
        <FlexColumn marginLeft={3} marginRight={3}>
          <Checkbox
            label="Would you like to come back?"
            name={`past_destinations.${key}.like_back`}
            isChecked={destinationObj.like_back}
            toggleChange={handleChangeCheckbox}
          />
        </FlexColumn>
      </FlexRow>
      <div
        style={{
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            margin: '3%',
            width: '100%',
          }}
        >
          {pastDestinationsSize - 1 === key && (
            <IconAdd
              name="add"
              color="blue"
              width={30}
              onClick={() => push({ destination: '', year: '', description: '', like_back: false })}
            >
              + Add
            </IconAdd>
          )}
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          {pastDestinationsSize > 1 && (
            <>
              <IconDelete name="delete" color="red" width={30} onClick={() => remove(key)}>
                Delete
              </IconDelete>
              <div>
                <Paperbin src={paperbin}></Paperbin>
              </div>
            </>
          )}
        </div>
      </div>
    </PastDestinationBox>
  );
};

class PastDestinationsView extends Component {
  render() {
    const { step, changeStep, past_destinations } = this.props;
    return (
      <Container>
        <PrimaryText text="Where have you traveled in the past?" />

        <Formik enableReinitialize initialValues={{ past_destinations }} isInitialValid={true}>
          {({ values, handleChange, errors, handleSubmit }) => {
            return (
              <FlexColumn>
                <FieldArray
                  name="past_destinations"
                  render={({ form, push, remove }) => (
                    <>
                      {values.past_destinations.map((destination, key) =>
                        renderDestination(
                          destination,
                          key,
                          form,
                          push,
                          remove,
                          errors && errors.past_destinations ? errors.past_destinations[key] : {},
                        ),
                      )}
                    </>
                  )}
                />

                <StepButtons
                  backStep={() => changeStep(step - 1, values.past_destinations)}
                  nextStep={() => changeStep(step + 1, values.past_destinations)}
                />
              </FlexColumn>
            );
          }}
        </Formik>
      </Container>
    );
  }
}

export default PastDestinationsView;
