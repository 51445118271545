import React, { Component } from 'react';
import styled from 'styled-components';
import { replace } from 'modules/history';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 15px;
  height: 50px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  height: 100%;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: ${props => props.alignItems};
  align-self: center;
  text-align: ${props => (props.left ? 'left' : 'center')};
  display: flex;
  flex-direction: ${props => props.flexDirection};
  padding-left: 15px;
  padding-right: 15px;
`;

const Secondary = styled.p`
  color: ${props => props.color};
  font-size: 15px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const TBody = styled.div``;

class CourseList extends Component {
  renderCourse(type, name, status, sendTest, isAdmin, key) {
    return (
      <Item key={key}>
        <SectionContainer flexDirection="column" alignItems="flex-start" width={'50%'}>
          <Secondary color="#9e9e9e" className="without-margin">
            {name}
          </Secondary>
        </SectionContainer>
        <SectionContainer
          style={{ justifyContent: 'flex-end' }}
          flexDirection="row"
          alignItems="flex-end"
          width={'50%'}
        >
          {!status && (
            <Secondary
              color="#38b6ff"
              onClick={() => sendTest(type, name)}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            >
              Send Test
            </Secondary>
          )}

          {status == 'pending' && <Secondary color="red">Pending</Secondary>}
          {status == 'completed' && <Secondary color="#4BC142">Completed</Secondary>}

          {!isAdmin && (
            <Secondary
              color="#38b6ff"
              onClick={() => {
                replace(`/courses/${type}/${status}`);
              }}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            >
              View
            </Secondary>
          )}
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const { sendTest, courses, isAdmin, loading } = this.props;
    return (
      <>
        {loading ? (
          <LoaderContainer>
            <Loader type="pulse" size={125} />
          </LoaderContainer>
        ) : (
            <GridContainer>
              <TBody>
                {courses.map((course, key) =>
                  this.renderCourse(course.type, course.name, course.status, sendTest, isAdmin, key),
                )}
                {courses.length == 0 && <ListEmpty message="There are currently no courses" />}
              </TBody>
            </GridContainer>
          )}
      </>
    );
  }
}

export default CourseList;
