import { put, call } from 'redux-saga/effects';
import * as citiesActions from '../actions/citiesActions';
import * as appActions from '../actions/app';
import { goBack } from 'modules/history';

export function* createCities(api, action) {
  const response = yield call(api.createCities, action.city);

  if (response.status == 200) {
    yield put(citiesActions.createCitiesSuccess());
    yield put(appActions.showAlert('City Created.', { variant: 'success' }));
    yield goBack();
  } else {
    yield put(citiesActions.createCitiesFailure(response.data));

    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getCities(api, action) {
  const response = yield call(api.getCities, action.id);
  if (response.status == 200) {
    yield put(citiesActions.getCitiesSuccess(response.data));
  } else {
    yield put(citiesActions.getCitiesFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* editCities(api, action) {
  const response = yield call(api.editCities, action.id, action.city);

  if (response.status == 200) {
    yield put(citiesActions.editCitiesSuccess());
    yield put(appActions.showAlert('City Edited.', { variant: 'success' }));
    yield goBack();
  } else {
    yield put(citiesActions.editCitiesFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getAllCities(api, action) {
  const { page, elementsPerPage, country_id } = action;
  const response = yield call(api.getAllCities, { page, elementsPerPage, country_id });

  if (response.status == 200) {
    yield put(citiesActions.getAllCitiesSuccess(response.data));
  } else {
    yield put(citiesActions.getAllCitiesFailure());
  }
}

export function* removeCities(api, action) {
  const { id } = action;
  const response = yield call(api.removeCities, id);

  if (response.status == 204) {
    yield put(citiesActions.removeCitiesSuccess(id));
    yield put(appActions.showAlert('City Deleted.', { variant: 'success' }));
  } else {
    yield put(citiesActions.removeCitiesFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getCitiesForSelect(api, action) {
  const { params } = action;
  const response = yield call(api.getCitiesForSelect, params);
  if (response.status == 200) {
    yield put(citiesActions.getCitiesForSelectSuccess(response.data));
  } else {
    yield put(citiesActions.getCitiesForSelectFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
