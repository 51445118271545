import React, { Component } from 'react';
import AuthenticPlanView from './AuthenticPlanView';
import { connect } from 'react-redux';
import * as authenticPlanActions from 'actions/authenticPlanActions';

class AuthenticPlanUpdateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 1 };
  }

  componentDidMount() {
    const {
      getAuthenticPlanRequest,
      match: { params },
    } = this.props;
    getAuthenticPlanRequest(params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authenticPlan != this.props.authenticPlan) {
      const { authenticPlan } = this.props;
      if (authenticPlan) {
        this.setState({ ...authenticPlan });
      }
    }
  }

  changeStep = step => {
    this.setState({ step });
  };

  createAtPlan = () => {
    const { step, ...data } = this.state;
    const {
      updateAuthenticPlanRequest,
      match: { params },
    } = this.props;
    updateAuthenticPlanRequest(params.id, data);
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  render() {
    const { authenticPlan } = this.props;
    if (!authenticPlan) return null;
    const { step, ...data } = this.state;

    return (
      <AuthenticPlanView
        authenticPlan={authenticPlan}
        step={this.state.step}
        handleChange={this.handleChange}
        changeStep={this.changeStep}
        data={data}
        createAtPlan={this.createAtPlan}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticPlan: state.authenticPlanReducer.authenticPlan,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAuthenticPlanRequest: id => dispatch(authenticPlanActions.getAuthenticPlanRequest(id)),
    updateAuthenticPlanRequest: (id, data) =>
      dispatch(authenticPlanActions.updateAuthenticPlanRequest(id, data)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticPlanUpdateContainer);
