import React, { Component } from 'react';
import UpdatePaymentMethodView from './UpdatePaymentMethodView';
import { connect } from 'react-redux';
import * as paymentMethodsActions from '../../actions/paymentMethodsActions';
import { StripeProvider } from 'react-stripe-elements';
import config from '../../config/appConfig';
import { Elements } from 'react-stripe-elements';

class UpdatePaymentMethodContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { addCard: false };
  }

  componentDidMount() {
    const { getPaymentMethods } = this.props;
    getPaymentMethods();
  }

  addCardModal = () => {
    this.setState({ addCard: !this.state.addCard });
  };

  createMethod = token => {
    const { createPaymentMethod } = this.props;
    this.setState({ addCard: false });
    createPaymentMethod(token);
  };

  setAsDefault = defaultSource => {
    const { setDefaultMethod } = this.props;
    setDefaultMethod(defaultSource);
  };
  render() {
    return (
      <StripeProvider apiKey={config.STRIPE_PUBLIC_KEY}>
        <Elements
          fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap' }]}
        >
          <UpdatePaymentMethodView
            {...this.props}
            addCardModal={this.addCardModal}
            createMethod={this.createMethod}
            setAsDefault={this.setAsDefault}
            state={this.state}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    payment_methods: state.paymentMethodReducer.paymentMethods,
    loading: state.paymentMethodReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getPaymentMethods: () => dispatch(paymentMethodsActions.getPaymentMethods()),
    createPaymentMethod: card_token =>
      dispatch(paymentMethodsActions.createPaymentMethod(card_token)),
    deletePaymentMethod: card_id => dispatch(paymentMethodsActions.deletePaymentMethod(card_id)),
    setDefaultMethod: defaultSource =>
      dispatch(paymentMethodsActions.setDefaultMethod(defaultSource)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdatePaymentMethodContainer);
