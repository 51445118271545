import { put, call } from 'redux-saga/effects';
import * as accommodationActions from 'actions/accommodationActions';
import * as appActions from 'actions/app';
import { replace } from 'modules/history';

export function* getAccommodationsAsync(api, action) {
  const { page, elementsPerPage } = action;
  const { status, data } = yield call(api.getAccommodations, { page, elementsPerPage });

  if (status === 200) {
    yield put(accommodationActions.getAccommodationsSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* getAccommodationAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.getAccommodation, id);
  if (status === 200) {
    yield put(accommodationActions.getAccommodationSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* saveAccommodation(api, action) {
  const response = yield call(api.saveAccommodation, action.accommodation);
  if (response.status === 200) {
    yield put(appActions.showAlert('Accommodation saved!', { variant: 'success' }));
    yield put(accommodationActions.saveAccommodationSuccess());

    yield replace(`/accommodations`);
  } else {
    yield put(accommodationActions.saveAccommodationFailure());

    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
export function* removeAccommodationAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.deleteAccommodation, id);

  if (status === 204) {
    yield put(appActions.showAlert('Accommodation deleted.', { variant: 'success' }));
    const response = yield call(api.getAccommodations, { page: 1, elementsPerPage: 10 });
    yield put(accommodationActions.removeAccommodationSuccess());
    if (response.status === 200) {
      yield put(accommodationActions.getAccommodationsSuccess(response.data));
    } else {
      yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
    }
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
    yield put(accommodationActions.removeAccommodationFailure());
  }
}

export function* updateAccommodationAsync(api, action) {
  const response = yield call(api.updateAccommodations, action.id, action.accommodation);
  if (response.status === 200) {
    yield replace(`/accommodations`);
    yield put(accommodationActions.updateAccommodationSuccess());

    yield put(appActions.showAlert('Accommodation updated!', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
    yield put(accommodationActions.updateAccommodationSuccess());
  }
}
