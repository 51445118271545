/*
 * Reducer actions related with customers
 */
import { ActionTypes } from 'constants/index';

export function getActivities(page, elementsPerPage) {
  return {
    type: ActionTypes.GET_ACTIVITIES,
    page,
    elementsPerPage,
  };
}

export function getActivitiesSuccess(payload) {
  return {
    type: ActionTypes.GET_ACTIVITIES_SUCCESS,
    activities: payload.rows,
    activitiesCount: payload.count,
  };
}

export function getActivity(id) {
  return {
    type: ActionTypes.GET_ACTIVITY,
    id,
  };
}

export function getActivitySuccess(activity) {
  return {
    type: ActionTypes.GET_ACTIVITY_SUCCESS,
    activity,
  };
}

export function refreshCoordinates(address) {
  return {
    type: ActionTypes.REFRESH_COORDINATES,
    address,
  };
}
export function refreshAddress(longLat) {
  return {
    type: ActionTypes.REFRESH_ADDRESS,
    longLat,
  };
}
export function savePlaces(options) {
  return {
    type: ActionTypes.SAVE_ACTIVITY_PLACES,
    places: options,
  };
}

export function saveActivity(activity) {
  return {
    type: ActionTypes.SAVE_ACTIVITY,
    activity,
  };
}
export function removeActivity(id, page, elementsPerPage) {
  return {
    type: ActionTypes.REMOVE_ACTIVITY,
    id,
  };
}

export function updateActivity(activity, id) {
  return {
    type: ActionTypes.UPDATE_ACTIVITY,
    activity,
    id,
  };
}

export function getDistanceTime(points) {
  return {
    type: ActionTypes.GET_DISTANCE_TIME,
    points,
  };
}

export function saveDistanceTimeDriving(drivingDuration) {
  return {
    type: ActionTypes.SAVE_DRIVING_DURATIONS,
    drivingDuration,
  };
}

export function saveDistanceTimeWalking(walkingDuration) {
  return {
    type: ActionTypes.SAVE_WALKING_DURATIONS,
    walkingDuration,
  };
}

export function resetDistanceTimeDriving() {
  return {
    type: ActionTypes.RESET_DURATIONS,
  };
}
