import React, { Component } from 'react';
import SelectDatesRangeView from './SelectDatesRangeView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';

class HaveDates extends Component {
  changeStep = (step, values) => {
    const { changeCurrentStep, saveTripData } = this.props;
    changeCurrentStep(step);
    saveTripData(values);
  };

  render() {
    return <SelectDatesRangeView {...this.props} changeStep={this.changeStep} state={this.state} />;
  }
}

function mapStateToProps(state) {
  return {
    month_ranges: state.tripReducer.tripForm.month_ranges,
    know_dates: state.tripReducer.tripForm.know_dates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HaveDates);
