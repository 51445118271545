import { put, call } from 'redux-saga/effects';
import * as meetingActions from '../actions/meetingActions';

export function* getMeetings(api, action) {
  const { page, elementsPerPage } = action;
  const response = yield call(api.getMeetings, { page, elementsPerPage });
  if (response.status == 200) {
    yield put(meetingActions.saveMeMeetings(response.data));
  }
}

export function* getMeetingRequest(api, action) {
  const response = yield call(api.getMeetingRequest, action.id);
  if (response.status == 200) {
    yield put(meetingActions.saveMeMeetingSuccess(response.data));
  }
}

export function* getInitialMeetingRequest(api) {
  const response = yield call(api.getInitialMeetingRequest);
  if (response.status == 200) {
    yield put(meetingActions.saveMeMeetingSuccess(response.data));
  }
}
