import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import CheckboxRemember from '../../Login/CheckboxRemember';

const ChooseCityBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

const SecondaryText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #464646;
  text-align: center;
  margin-top: 50px;
`;
const Search = styled.input`
  background-color: #f4f4f4;
  border: none;
  height: 44px;
  width: 100%;
  padding-left: 40px;
  ::placeholder {
    color: #999999;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    font-weight: 300;
  }
`;
const CityOption = styled.div`
  background-color: #f4f4f4;
  border: none;
  height: 30px;
  width: 70%;
  cursor: pointer;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const SelectedCities = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: center;
`;
const TextOption = styled.p`
  color: #999999;
  margin-left: 8px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
  line-height: 0;
`;
const TextSelected = styled.p`
  color: #464646;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 15px;
  margin-top: 5px;
  text-align: center;
`;
const CitySelected = styled.div`
  margin: 10px;
`;

const SelectIcon = styled.div`
  height: 44px;
  width: 70%;
  position: relative;
  @media (max-width: 1000px) {
    width: 100%;
  }
  }
`;
class ChooseCityView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      state,
      step,
      changeStep,
      selectCity,
      searchCity,
      removeCity,
      citiesFiltered,
      handleChangeCheckbox,
    } = this.props;
    const { searchString, cities_selected, like_to_go } = state;
    return (
      <>
        <ChooseCityBox>
          <PrimaryText text="What countries regions, or cities would you like to visit on your journey?" />
          <CheckboxRemember
            style={{ justifyContent: 'center' }}
            labelInverted
            label="Do you have an idea of where you’d like to go on your journey?"
            name="like_to_go"
            isChecked={like_to_go}
            toggleChange={handleChangeCheckbox}
          />
          {like_to_go && (
            <>
              <SelectIcon>
                <i
                  className="fas fa-search"
                  style={{ position: 'absolute', top: '15px', left: '18px', color: '#999999' }}
                />

                <Search
                  autocomplete="false"
                  type="search"
                  placeholder="Enter the city you want to travel"
                  name="city"
                  value={searchString}
                  onChange={searchCity}
                />
              </SelectIcon>
              {searchString &&
                citiesFiltered &&
                citiesFiltered.map((city, key) => (
                  <CityOption onClick={() => selectCity(city)} key={key}>
                    <TextOption>{city.name}</TextOption>
                  </CityOption>
                ))}
              <SecondaryText>Our selected cities</SecondaryText>
              <SelectedCities>
                {cities_selected &&
                  cities_selected.map((dest, key) => (
                    <CitySelected onClick={() => removeCity(dest)} key={key}>
                      <TextSelected>
                        {dest.name}
                        <i
                          class="fas fa-times-circle"
                          aria-hidden="true"
                          style={{ color: 'rgb(105, 105, 105)', marginLeft: '3px' }}
                        />
                      </TextSelected>
                    </CitySelected>
                  ))}
              </SelectedCities>
            </>
          )}

          <StepButtons
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(step + 1)}
          />
        </ChooseCityBox>
      </>
    );
  }
}

export default ChooseCityView;
