import React from 'react';
import styled from 'styled-components';

const StepButton = styled.button`
  border-radius: 5px;
  color: white;
  background-color: ${props => (props.next ? '#38b6ff' : 'inherit')};
  border: 1px solid #38b6ff;
  width: 150px;
  height: 50px;
  color: ${props => (props.next ? 'white' : '#38b6ff')};
  margin: 5px 5px 30px 5px;
  cursor: pointer;
  opacity: ${props => (props.disabled ? '0.5' : 'inherit')};
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const StepButtons = styled.div`
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  font-family: 'Josefin Sans', sans-serif;
`;

const Arrow = styled.span`
  margin-left: 10px;
  margin-right: 10px;
`;

export default props => (
  <StepButtons justifyContent={props.justifyContent}>
    <>
      {!props.nonDisplayBack && (
        <StepButton
          onClick={props.backStep}
          disabled={props.backDisabled}
          hidden={props.hiddenBack}
        >
          <Arrow>←</Arrow> Back
        </StepButton>
      )}
      {!props.nonDisplayNext && (
        <StepButton next onClick={props.nextStep} disabled={props.nextDisabled}>
          {props.customNextText ? (
            props.customNextText
          ) : (
            <p>
              Next <Arrow>→</Arrow>
            </p>
          )}
        </StepButton>
      )}
    </>
  </StepButtons>
);
