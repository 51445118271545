import React, { Component } from 'react';
import TravelPersonalityTestView from './TravelPersonalityTestView';
import { connect } from 'react-redux';
import * as diagnosticActions from '../../../actions/diagnosticActions';
import { selectFields, defaultState } from './StepFields/DiagnosticTravelPersonality';

class TravelPersonalityTest extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    const { travelPersonalities, step, previusStep } = this.props;
    if (travelPersonalities) {
      this.setState({ ...travelPersonalities, localStep: step < previusStep ? 10 : 1 });
    }
  }

  changeStep = step => {
    if (1 <= step && step <= 10) {
      this.setState({ localStep: step });
    } else {
      const { changeCurrentStep, saveTravelPersonalityTest } = this.props;
      const globalStep = step < 1 ? 5 : 8; // 3 = strengths / 5 = results
      changeCurrentStep(globalStep);
      saveTravelPersonalityTest(this.state);
    }
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const stepFields = selectFields(this.state.localStep);

    return (
      <TravelPersonalityTestView
        {...this.props}
        changeStep={this.changeStep}
        data={this.state}
        stepFields={stepFields}
        handleChange={this.handleChange}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    travelPersonalities: state.diagnosticReducer.travelPersonalities,
    previusStep: state.diagnosticReducer.previusStep,
    step: state.diagnosticReducer.step,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    saveTravelPersonalityTest: travelPersonalities =>
      dispatch(diagnosticActions.saveTravelPersonality(travelPersonalities)),
    changeCurrentStep: step => dispatch(diagnosticActions.changeCurrentStep(step)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TravelPersonalityTest);
