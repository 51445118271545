import React, { Component } from 'react';
import PlanMeetingView from './PlanMeetingView';
import { connect } from 'react-redux';
import * as diagnosticActions from '../../../actions/diagnosticActions';
import moment from 'moment';

class PlanMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localStep: 1,
      editMode: false,
    };
  }

  changeStep = step => {
    const { saveMeeting, currentMeetingId, updateMeeting } = this.props;
    const { editMode } = this.state;
    if (step != 0) {
      this.setState({ localStep: step });
      if (step == 2) {
        if (editMode) {
          updateMeeting({ date: this.state.hour, id: currentMeetingId });
        } else {
          saveMeeting({ date: this.state.hour, is_initial_meeting: true });
        }
      }
    }
    const { changeCurrentStep, updateDiagnosticStepUser } = this.props;
    const globalStep = step < 1 ? 8 : 9;
    changeCurrentStep(globalStep);
    updateDiagnosticStepUser({ diagnostic_step: globalStep });
  };

  changeDate = date => {
    const { getPossibleMeetingHours } = this.props;
    getPossibleMeetingHours({ date });
    this.setState({
      selectedDate: date,
    });
  };

  changeHour = evt => {
    this.setState({
      hour: evt.target.value,
    });
  };

  editMeeting = () => {
    this.changeStep(1);
    this.setState({ editMode: true });
  };

  render() {
    const { possibleHours } = this.props;
    return (
      <PlanMeetingView
        {...this.props}
        changeStep={this.changeStep}
        changeDate={this.changeDate}
        changeHour={this.changeHour}
        state={this.state}
        editMeeting={this.editMeeting}
        possibleHours={possibleHours}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(diagnosticActions.changeCurrentStep(step)),
    saveMeeting: date => dispatch(diagnosticActions.saveMeeting(date)),
    updateMeeting: meeting => dispatch(diagnosticActions.updateMeeting(meeting)),

    getPossibleMeetingHours: date => dispatch(diagnosticActions.getPossibleMeetingHours(date)),
    updateDiagnosticStepUser: step => dispatch(diagnosticActions.updateDiagnosticStepUser(step)),
  };
}

function mapStateToProps(state) {
  return {
    possibleHours: state.diagnosticReducer.possibleHours,
    currentMeetingId: state.diagnosticReducer.currentMeetingId,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanMeeting);
