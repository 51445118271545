import React from 'react';
import styled from 'styled-components';
import logo from '../../../assets/media/images/facebook-logo.png';

const FacebookButton = styled.a`
  border-radius: 4px;
  width: 50%;
  outline: none;
  *zoom: 1;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  vertical-align: middle;
  border: 1px solid;
  -webkit-transition: all 0.2s ease;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.01);
  font-size: 10px;
  display: flex;
  color: #4682b4;
  border-color: #4682b4;
  color: #4682b4;
  border-color: #4682b4;
  cursor: pointer;
  &:hover {
    background: #4682b4;
    color: #fff;
    border-color: #4682b4;
  }
`;

const TextButton = styled.p`
  margin: auto;
`;

const FacebookLogo = styled.img`
  margin: 13px 7px 13px 13px;
  height: 17px;
`;

export default props => (
  <FacebookButton onClick={props.renderProps.onClick}>
    <FacebookLogo src={logo} />
    <TextButton>LOGIN WITH FACEBOOK</TextButton>
  </FacebookButton>
);
