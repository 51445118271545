// import * as loginReducer from './loginReducer';
// export default Object.assign(loginReducer);

import app from './app';
import user from './user';
import loginReducer from './loginReducer';
import customerReducer from './customerReducer';
import registerReducer from './registerReducer';
import diagnosticReducer from './diagnosticReducer';
import productReducer from './productReducer';
import tripReducer from './tripReducer';
import paymentMethodsReducer from './paymentMethodsReducer';
import activityReducer from './activityReducer';
import restaurantReducer from './restaurantReducer';
import accommodationReducer from './accommodationReducer';

import itineraryReducer from './itineraryReducer';
import transactionReducer from './transactionReducer';
import invoiceReducer from './invoiceReducer';
import preTripCourseReducer from './preTripCourseReducer';
import meetingReducer from './meetingReducer';
import citiesReducer from './citiesReducer';
import regionsReducer from './regionsReducer';
import journalReducer from './journalReducer';
import authenticPlanReducer from './authenticPlanReducer';
import goalReducer from './goalReducer';
import courseReducer from './courseReducer';
import suggestionReducer from './suggestionReducer';
import boutiquePackageReducer from './boutiquePackageReducer';

export default {
  ...app,
  ...user,
  ...loginReducer,
  ...customerReducer,
  ...registerReducer,
  ...diagnosticReducer,
  ...productReducer,
  ...tripReducer,
  ...paymentMethodsReducer,
  ...activityReducer,
  ...restaurantReducer,
  ...accommodationReducer,
  ...itineraryReducer,
  ...transactionReducer,
  ...invoiceReducer,
  ...preTripCourseReducer,
  ...meetingReducer,
  ...citiesReducer,
  ...regionsReducer,
  ...journalReducer,
  ...authenticPlanReducer,
  ...goalReducer,
  ...courseReducer,
  ...suggestionReducer,
  ...boutiquePackageReducer,
};
