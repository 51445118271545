import React, { Component } from 'react';
import DailyBudgetView from './DailyBudgetView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import * as productsActions from '../../../actions/productsActions';

class DailyBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price_total_budget: null,
      show_bar: false,
    };
  }

  componentDidMount() {
    const { price_total_budget } = this.props;
    if (
      price_total_budget != 'moreThan10000' &&
      price_total_budget != 'lessThan1000' &&
      price_total_budget != null
    ) {
      this.setState({
        show_bar: true,
        price_total_budget,
      });
    } else {
      this.setState({
        price_total_budget,
      });
    }
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { price_total_budget } = this.state;
    changeCurrentStep(step);
    saveTripData({
      price_total_budget,
    });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  toggleBar = show_bar => {
    if (!show_bar) {
      this.setState({ show_bar, price_total_budget: null });
    } else {
      this.setState({ show_bar });
    }
  };

  setDailyBudget = price_total_budget => {
    this.setState({ price_total_budget });
  };

  render() {
    return (
      <DailyBudgetView
        {...this.props}
        changeStep={this.changeStep}
        handleChange={this.handleChange}
        toggleBar={this.toggleBar}
        state={this.state}
        setDailyBudget={this.setDailyBudget}
      />
    );
  }
}

function mapStateToProps(state) {
  const { price_total_budget, know_dates } = state.tripReducer.tripForm;
  return {
    price_total_budget,
    know_dates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyBudget);
