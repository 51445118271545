import React from 'react';
import styled from 'styled-components';
import { appColor } from 'modules/theme';
import { Formik } from 'formik';
import ModalExcel from 'components/Suggestions/ModalExcel';

const GridContainer = styled.div`
  overflow-x: auto;
`;

const FlexRow = styled.div`
  display: flex;
  width: auto;
  justify-content: space-around;
  display: inline-flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 3)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 3)}px;
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}%;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const InputActivity = styled.p`
  width: ${props => props.width && props.width}px;
  height: 30px;
  object-fit: contain;
  border-radius: 0px;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#f8f7f7')};
  border: none;
  margin-top: ${props => (props.marginTop ? props.marginTop : -15)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: ${props => (props.fontSize ? props.fontSize : 20)}px;
    color: #545454;
  }
  font-family: 'Josefin Sans', sans-serif;
  color: #545454;
  font-size: ${props => (props.fontSize ? props.fontSize : 20)}px;
  padding-left: ${props => props.paddingLeft && props.paddingLeft}%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const InputNames = styled.p`
  width: ${props => props.width && props.width}px;
  height: 30px;
  object-fit: contain;
  border-radius: 5px 0px 0px 5px;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#f8f7f7')};
  border: none;
  margin-top: ${props => (props.marginTop ? props.marginTop : -15)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: ${props => (props.fontSize ? props.fontSize : 20)}px;
    color: #545454;
  }
  font-family: 'Josefin Sans', sans-serif;
  color: #545454;
  font-size: ${props => (props.fontSize ? props.fontSize : 20)}px;
  padding-left: ${props => props.paddingLeft && props.paddingLeft}%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const LabelExpenses = styled.p`
  width: ${props => props.width}px;
  height: 30px;
  margin-bottom: 20px;
  font-size: 15px;
  color: black;
  border-radius: 5px 5px 0px 0px;
  text-decoration: none;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
`;

const AverageDailyExpenses = props => {
  const {
    country: { id },
    viewMode,
    token,
    dailyExpenses,
    saveAverageDailyExpenses,
    handleModalExcel,
    renderModalExcel,
  } = props;

  return (
    <GridContainer>
      <>
        <FlexRow>
          <FlexColumn>
            <LabelExpenses></LabelExpenses>
            <InputNames width={'80'} fontSize={15}>
              Budget
            </InputNames>
            <InputNames backgroundColor={'#EEEEEE'} width={'80'} marginTop={-10} fontSize={15}>
              Moderate
            </InputNames>
            <InputNames width={'80'} marginTop={-10} fontSize={15}>
              Pricier
            </InputNames>
            <InputNames backgroundColor={'#EEEEEE'} width={'80'} marginTop={-10} fontSize={15}>
              Splurge
            </InputNames>
          </FlexColumn>
          <FlexColumn>
            <LabelExpenses width={'200'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
              Public Transportation
            </LabelExpenses>
            <InputActivity width={'200'}>
              ${dailyExpenses.public_transportation_budget}
            </InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'200'}>
              ${dailyExpenses.public_transportation_moderate}
            </InputActivity>
            <InputActivity width={'200'}>
              ${dailyExpenses.public_transportation_pricier}
            </InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'200'}>
              ${dailyExpenses.public_transportation_splurge}
            </InputActivity>
          </FlexColumn>
          <FlexColumn>
            <LabelExpenses width={'200'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
              Sights And Entertainment
            </LabelExpenses>
            <InputActivity width={'200'}>
              ${dailyExpenses.sights_and_entertainment_budget}
            </InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'200'}>
              ${dailyExpenses.sights_and_entertainment_moderate}
            </InputActivity>
            <InputActivity width={'200'}>
              ${dailyExpenses.sights_and_entertainment_pricier}
            </InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'200'}>
              ${dailyExpenses.sights_and_entertainment_splurge}
            </InputActivity>
          </FlexColumn>
          <FlexColumn>
            <LabelExpenses width={'80'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
              Meals
            </LabelExpenses>
            <InputActivity width={'80'}>${dailyExpenses.meals_budget}</InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'80'}>
              ${dailyExpenses.meals_moderate}
            </InputActivity>
            <InputActivity width={'80'}>${dailyExpenses.meals_pricier}</InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'80'}>
              ${dailyExpenses.meals_splurge}
            </InputActivity>
          </FlexColumn>
          <FlexColumn>
            <LabelExpenses width={'100'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
              Lodging (2 p)
            </LabelExpenses>
            <InputActivity width={'100'}>${dailyExpenses.lodging_couple_budget}</InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'100'}>
              ${dailyExpenses.lodging_couple_moderate}
            </InputActivity>
            <InputActivity width={'100'}>${dailyExpenses.lodging_couple_pricier}</InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'100'}>
              ${dailyExpenses.lodging_couple_splurge}
            </InputActivity>
          </FlexColumn>
          <FlexColumn>
            <LabelExpenses width={'120'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
              Lodging (alone)
            </LabelExpenses>
            <InputActivity width={'120'}>${dailyExpenses.lodging_alone_budget}</InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'120'}>
              ${dailyExpenses.lodging_alone_moderate}
            </InputActivity>
            <InputActivity width={'120'}>${dailyExpenses.lodging_alone_pricier}</InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'120'}>
              ${dailyExpenses.lodging_alone_splurge}
            </InputActivity>
          </FlexColumn>
          <FlexColumn>
            <LabelExpenses width={'120'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
              Total Expenses
            </LabelExpenses>
            <InputActivity width={'120'}>
              ${dailyExpenses.total_expenses_per_day_budget}
            </InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'120'}>
              ${dailyExpenses.total_expenses_per_day_moderate}
            </InputActivity>
            <InputActivity width={'120'}>
              ${dailyExpenses.total_expenses_per_day_pricier}
            </InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'120'}>
              ${dailyExpenses.total_expenses_per_day_splurge}
            </InputActivity>
          </FlexColumn>
          <FlexColumn>
            <LabelExpenses width={'270'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
              Total Expenses (not splitting Room)
            </LabelExpenses>
            <InputActivity width={'270'}>
              ${dailyExpenses.total_expenses_per_day_not_splitting_room_budget}
            </InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'270'}>
              ${dailyExpenses.total_expenses_per_day_not_splitting_room_moderate}
            </InputActivity>
            <InputActivity width={'270'}>
              ${dailyExpenses.total_expenses_per_day_not_splitting_room_pricier}
            </InputActivity>
            <InputActivity backgroundColor={'#EEEEEE'} width={'270'}>
              ${dailyExpenses.total_expenses_per_day_not_splitting_room_splurge}
            </InputActivity>
          </FlexColumn>
        </FlexRow>
      </>

      {/*<ModalExcel
        id={id}
        token={token}
        url="average-daily-expenses"
        renderModalExcel={renderModalExcel}
        handleModalExcel={handleModalExcel}
      ></ModalExcel>*/}
    </GridContainer>
  );
};

export default AverageDailyExpenses;
