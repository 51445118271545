import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import ok from 'assets/media/icons/ok.png';
import pending from 'assets/media/icons/pending.png';
import remove from 'assets/media/icons/remove.png';
import Master from 'assets/media/icons/master.png';
import Visa from 'assets/media/icons/visa.png';
import Cirrus from 'assets/media/icons/cirrus.png';
import Maestro from 'assets/media/icons/maestro.png';
import Americanexpress from 'assets/media/icons/americanexpress.png';
import moment from 'moment';
import Loader from 'components/Loader';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  height: 100%;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: ${props => props.alignItems};
  align-self: center;
  text-align: ${props => (props.left ? 'left' : 'center')};
  display: flex;
  flex-direction: ${props => props.flexDirecyion};
  padding-left: 15px;
  padding-right: 15px;
`;

const BrandImage = styled.img`
  height: 34px;
  margin-top: 19px;
`;

const StatusImage = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 19px;
`;
const Primary = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;
const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 15px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
`;
const TBody = styled.div``;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const chooseImage = brand => {
  switch (brand) {
    case 'Master':
      return Master;
    case 'Visa':
      return Visa;
    case 'Cirrus':
      return Cirrus;
    case 'Maestro':
      return Maestro;

    case 'American Express':
      return Americanexpress;
    default:
      return Master;
  }
};

const chooseStatusImage = status => {
  switch (status) {
    case 'pending':
      return pending;
    case 'succeeded':
      return ok;
    case 'failed':
      return remove;
    default:
      return pending;
  }
};

class TransactionList extends Component {
  renderTransaction({ brand, last4, price, status, description, created_at }, key) {
    return (
      <Item key={key}>
        <SectionContainer flexDirecyion="column" alignItems="flex-start" width={'25%'}>
          <BrandImage src={chooseImage(brand)} />
          <Secondary>{`${brand} ending in ${last4}`}</Secondary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer flexDirecyion="column" alignItems="flex-start" width={'25%'}>
          <Primary>{description}</Primary>
          <VerticalLine />
        </SectionContainer>

        {/* <SectionContainer flexDirecyion='column' alignItems='center'width={'22%'}>
          <Primary>{`${trip.planner.first_name} ${trip.planner.last_name}`}</Primary>
          <Secondary>{trip.planner.role_name}</Secondary>
          <VerticalLine />
        </SectionContainer> */}
        <SectionContainer flexDirecyion="column" alignItems="center" width={'15%'}>
          <Primary className="without-margin">
            <StatusImage src={chooseStatusImage(status)} />
          </Primary>
          <Secondary className="without-margin">{status}</Secondary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer flexDirecyion="column" alignItems="center" width={'15%'}>
          <Secondary className="without-margin">{`$${price}`}</Secondary>
        </SectionContainer>
        <SectionContainer flexDirecyion="column" alignItems="center" width={'20%'}>
          <Primary className="without-margin">
            <i className="far fa-calendar icons non-meeting" />
          </Primary>
          <Secondary className="without-margin">
            {moment(created_at).format('MM-DD-YYYY hh:mm A')}
          </Secondary>
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const { transactions, loading } = this.props;
    if (!transactions) return null;
    return (
      <>
        {loading ? (
          <LoaderContainer>
            <Loader type="pulse" size={125} />
          </LoaderContainer>
        ) : (
          <GridContainer>
            <THeader>
              <TitleContainer width={'25%'}>
                <Title>CREDIT CARD</Title>
              </TitleContainer>
              <TitleContainer width={'25%'}>
                <Title>TYPE</Title>
              </TitleContainer>
              {/* <TitleContainer width={'22%'}>
            <Title>PLANED BY</Title>
          </TitleContainer> */}
              <TitleContainer width={'15%'}>
                <Title>STATUS</Title>
              </TitleContainer>
              <TitleContainer width={'15%'}>
                <Title>AMOUNT</Title>
              </TitleContainer>
              <TitleContainer width={'20%'}>
                <Title>DATE</Title>
              </TitleContainer>
            </THeader>
            <TBody>
              {transactions.map((transaction, key) => this.renderTransaction(transaction, key))}
              {(!transactions || !transactions.length) && (
                <ListEmpty message="There are currently no payments" />
              )}
            </TBody>
          </GridContainer>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  null,
  mapDispatchToProps,
)(TransactionList);
