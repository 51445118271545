import React, { Component } from 'react';
import { connect } from 'react-redux';
import TransactionList from 'components/TransactionList';
import * as transactionActions from '../../actions/transactionActions';

class TransactionContainer extends Component {
  componentDidMount() {
    this.props.onGetMeTransactions();
  }

  goTo = path => {
    const {
      history: { push },
    } = this.props;

    push(path);
  };

  render() {
    const { transactions, loading } = this.props;
    return <TransactionList transactions={transactions} loading={loading} />;
  }
}

function mapStateToProps(state) {
  return {
    transactions: state.transactionReducer.transactions,
    loading: state.transactionReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onGetMeTransactions: () => dispatch(transactionActions.getMeTransactions()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionContainer);
