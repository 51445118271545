import React from 'react';
import styled from 'styled-components';
const SelectedButton = styled.button`
  border-radius: 5px;
  color: white;
  border: 1px solid;
  border-color: ${props => (props.active ? '#38b6ff' : '#b7b7b7')};
  width: 200px;
  height: 52px;
  color: ${props => (props.active ? '#38b6ff' : '#b7b7b7')};
  margin: 12px;
  font-family: 'Josefin Sans', sans-serif;
`;

export default props => (
  <SelectedButton active={props.active} onClick={props.onClick}>
    {props.text}
    {props.active && (
      <i
        className="fas fa-check-circle"
        style={{
          float: 'right',
          marginRight: '12px',
          color: '#38b6ff',
        }}
      ></i>
    )}
  </SelectedButton>
);
