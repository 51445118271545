import React from 'react';
import styled from 'styled-components';

const Label = styled.p`
  margin-top: 3px;
`;

const RangeContainer = styled.span`
  display: grid;
  margin-bottom: 20px;
`;

const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
`;

const QuantitiValue = styled.p`
  color: #9e9e9e;
  font-size: 12px;
  display: flex;
  flex-direction: column-reverse;
  text-align: ${props => props.textAlign};
  width: 33%;
`;
const InputBox = styled.div``;

const CurrentValue = styled.p`
  color: #38b6ff;
  font-size: 18px;
`;
export default props => (
  <RangeContainer style={props.style}>
    <InputBox>
      <Quantity>
        <Label>{props.label}</Label>
        <CurrentValue>
          {props.isPrice && '$'}
          {!props.hiddenValue && props.value}
        </CurrentValue>
      </Quantity>
      <input
        type="range"
        name={props.name}
        onChange={props.handleChange}
        min={props.min}
        max={props.max}
        value={props.value}
        step={props.step}
      />
    </InputBox>
    <Quantity>
      <QuantitiValue textAlign="left">
        {props.minQuantityLabel}
        <span>{props.minQuantityValue}</span>
      </QuantitiValue>
      <QuantitiValue textAlign="center">
        {props.mediumQuantityLabel}
        <span>{props.mediumQuantityValue}</span>
      </QuantitiValue>
      <QuantitiValue textAlign="right">
        {props.maxQuantityLabel}
        <span>{props.maxQuantityValue}</span>
      </QuantitiValue>
    </Quantity>
  </RangeContainer>
);
