import React, { Component } from 'react';
import styled from 'styled-components';
import StepContainer from 'components/StepContainer';
import Button from 'components/Button';
import { goBack } from 'modules/history';

const FlexBox = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: baseline;
  flex-direction: ${props => props.flexDirection};
`;

const TextArea = styled.textarea`
border-style: solid;
    border-width: 1px;
    border: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #bebcbc;
    height: ${props => props.height || 100}px;
    resize: none;
    padding: 10px;
    width: 100%;
}
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const Label = styled.p`
  font-size: 13px;
  line-height: 0;
  color: #999999;
`;

const LongTextAreaContainer = styled.div`
  width: 100%;
  margin: 5px;
`;

const IntroContainer = styled.div`
  width: 80%;
  margin: 20px;
  height: 500px;
  overflow: auto;
  padding-right: 15px;
`;

const InputsContainer = styled.div`
  width: 80%;
  margin: 20px;
  overflow: auto;
  padding-right: 15px;
`;

const ModalDiv = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-position-x: center;
  background-size: cover;
  max-width: 100%;
  background-blend-mode: multiply;
  text-align: left;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 50px;
  overflow-x: scroll;
  z-index: 1050;
`;

const StepSubtitle = styled.p`
  text-align: center;
  color: #808080;
  font-size: 30px;
  margin-top: 40px;
  margin-left: 80px;
  margin-right: 80px;
`;

const Text = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  line-height: 1.47;
  color: #999999;
  text-align: justify;
  overflow: auto;
`;

class PreparingToReturnView extends Component {
  render() {
    const { createGoals, handleChange, step, changeStep, data } = this.props;
    const { goal_1, goal_2, goal_3 } = data;
    return (
      <ModalDiv>
        <StepContainer closeIcon width={'70%'} closeAction={() => goBack()}>
          {step == 1 && (
            <>
              <StepSubtitle>Discuss and Create Goals</StepSubtitle>
              <FlexBox justifyContent="center">
                <IntroContainer>
                  <Text>
                    In order create progress within our lives—in order to create the best future for
                    ourselves that we can—we need to first identify what exactly we want. There are
                    a number of ways to go about doing this, but one that I’ve found super effective
                    with clients is to create an ideal self. This exercise is based on the believe
                    that we all have an inherent capacity toward growth, well-being,
                    and—ultimately—flourishing.
                  </Text>
                  <Text>
                    Listen to the following. Afterward, I will ask you to write out your self-growth
                    plan—something based on who you are, and who you want to be—using concrete and
                    attainable goals. Evidence shows that you’re more likely to accomplish your
                    goals if you write them down and share them with a friend or mentor with
                    occasional updates.
                  </Text>
                  <div style={{ marginLeft: '20px' }}>
                    <Text>
                      Sit up straight, with your back against your seat or chair. Allow your body to
                      be in a relaxed position, with feet flat on the floor and hands in your lap.
                      Take three slow and deep breaths and gently close your eyes.
                    </Text>

                    <Text>
                      Now imagine a better version of yourself. Specifically, imagine a version of
                      yourself that has returned home from a life-changing journey and is no longer
                      struggling with (whichever area of development we discussed before as being
                      most problematic). Know that this version of you is good for you. Know that
                      being this person will make you happier and more satisfied. Know that you want
                      to be this sort of person. Know that you have to be this sort of person.
                    </Text>

                    <Text>
                      Now begin to visualize the specific details of better you. How did you become
                      this person? What was the journey like to get there? What exactly did you do?
                    </Text>

                    <Text>
                      Now think about your core strengths. Imagine that you’ve carried them with you
                      in a light backpack that’s always with you. As you hold them in your mind,
                      focus on the abilities, talents, skills, and interests that are related to
                      these core strengths.
                    </Text>

                    <Text>
                      Imagine the specific actions, behaviors, habits, and routines that use these
                      core strengths. What exactly did you do on your journey? How did you act? What
                      did you think?
                    </Text>

                    <Text>
                      See if you can’t connect some actions to achieving the better version of you
                      that you’ve imagined. In what ways can you use your core strengths—and the
                      actions based upon them—to become a better you?
                    </Text>
                    <Text>
                      Once you have a clear or somewhat clear list of actions, behaviors, habits,
                      and routines that will help you to be a better you, can you commit to doing
                      some of them?
                    </Text>
                    <Text>Select a few actions you’ll be willing to take moving forward.</Text>
                    <Text>
                      Now visualize some of the potential barriers—either internal or external—that
                      could stop your progress during the course of your journey. How can you
                      overcome these barriers? Who will support you when you face these obstacles?
                    </Text>
                    <Text>
                      Finally, imagine what it will be like when you finally progress to being a
                      better version of yourself. What exactly would change in your life? What
                      specific feelings, actions, or beliefs will have changed?
                    </Text>
                    <Text>
                      When you are ready, bring you attention back to the room. Open your eyes.
                    </Text>
                    <Text>
                      Now let’s take a moment to express in written form the future you just created
                      for yourself. Let’s begin by filling out the Background portion of Your
                      Authentic Travel Plan.
                    </Text>
                    <Text style={{ fontStyle: 'italic' }}>
                      How did it make you feel to express these thoughts and feelings? Have you ever
                      shared these goals and desires with yourself or others? Are you excited,
                      nervous, eager, or a combination of feelings? Did you realize something new
                      about yourself and what you really want right now?
                    </Text>
                  </div>
                  <Text>
                    Now please complete let’s step back for a moment and reflect on your future
                    vision you just shared
                  </Text>

                  <Text style={{ marginLeft: '20px', fontStyle: 'italic' }}>
                    How did it make you feel to express these thoughts and feelings? Have you ever
                    shared these goals and desires with yourself or others? Are you excited,
                    nervous, eager, or a combination of feelings? Did you realize something new
                    about yourself and what you really want right now?
                  </Text>
                  <Text>
                    Know that whatever you’re feeling right now is valid. Also know that you—and
                    only you—have the power to turn these dreams into a reality. Others will be
                    there to assist you along the way —including your friends, family, the team at
                    Authentic Traveling, your community, and the strangers you meet along the
                    way—but ultimately you have to decide to do the things necessary to make all of
                    this a reality.
                  </Text>
                  <Text>
                    But how do we go about doing this? How do we transform distant goals and faraway
                    dreams into manageable first-steps and clearcut objectives?
                  </Text>
                  <Text>
                    The first step is to recognize that the vision for the future you’ve just shared
                    doesn’t have to stay the same. As time goes on, as you learn more about yourself
                    and the world, as you develop new strengths and interests, the ways in which you
                    go about achieving your best life will undoubtedly change. Because of this, it’s
                    not necessary to know all the elevate details about how you’re going to get
                    there. Just like a ship’s captain sailing off to a far distant land, you first
                    need to steer yourself in the approximate direction of where you want to go;
                    only with time does it become clear where you want to land.
                  </Text>
                  <Text>
                    The second step is to understand what constitutes an effect goal. Great goals
                    are SMART— Specific, Measurable, Attainable, Relevant, and Time-Based. Allow me
                    to explain.
                  </Text>
                  <div style={{ marginLeft: '20px' }}>
                    <Text style={{ fontWeight: 'bold' }}>Specific</Text>
                    <Text>
                      Great goals are clear and specific, which helps to remain focused and
                      motivated. To make your goals more specific, try and answer the six ‘W’
                      questions:
                    </Text>
                    <div style={{ marginLeft: '20px' }}>
                      <Text>
                        -What do I want to accomplish?
                        <br /> -When do I want to accomplish this goal?
                        <br />
                        -Where do I need to go?-Who should be involved?
                        <br /> -Which resources or limits are there?
                        <br /> -Why do I want to accomplish this goal?
                      </Text>
                    </div>
                  </div>

                  <div style={{ marginLeft: '20px' }}>
                    <Text style={{ fontWeight: 'bold' }}>Measurable</Text>
                    <Text>
                      Great goals are also measurable, allowing you to easily track your progress
                      and know when you’ve succeeded. Being able to clearly see both how far you’ve
                      come and what you need to do to finish helps you to stay motivated and creates
                      excitement around the thought of accomplishing your goal. When setting
                      measurable goals, ask questions like:
                    </Text>
                    <div style={{ marginLeft: '20px' }}>
                      <Text>
                        -How much?
                        <br /> -How long?
                        <br /> -How many?
                        <br /> -How will I know when I've succeeded?
                        <br />
                      </Text>
                    </div>
                  </div>

                  <div style={{ marginLeft: '20px' }}>
                    <Text style={{ fontWeight: 'bold' }}>Attainable</Text>
                    <Text>
                      Great goals should also be attainable. You should be able to see a realistic
                      path you can take to success. You should feel challenged and your abilities
                      should be stretched, but everything should remain possible. If your goals is
                      so difficult that it starts to feel unattainable, then you’re more likely to
                      just give up. Achievable goals answer questions like:
                    </Text>
                    <div style={{ marginLeft: '20px' }}>
                      <Text>
                        -Given my current situation (financial, mental/physical health, time
                        constraints) <br />
                        how realistic is this goal?
                        <br />
                        -How can I accomplish this goal?
                        <br />
                      </Text>
                    </div>
                  </div>

                  <div style={{ marginLeft: '20px' }}>
                    <Text style={{ fontWeight: 'bold' }}>Relevant</Text>
                    <Text>
                      Great goals need to be relevant. They need to matter to you, aligning with
                      your greater purpose in travel or life. Having a deep sense of purpose behind
                      your goals ensures that you’ll never run out of motivation, even when the
                      going gets tough and helps to keep things in perspective. If you goal isn’t
                      relevant to you, you will lack the fortitude necessary to handle adversity
                      when it undoubtedly appears. Relevant goals answer ‘yes’ to these questions:
                    </Text>
                    <div style={{ marginLeft: '20px' }}>
                      <Text>
                        -Is this something that matters to me? <br />
                        -Do I really want to accomplish this?
                        <br />
                        -Will completing this goal improve the world in some way?
                        <br />
                        -Am I improving my life by succeeding?
                        <br />
                        -Is this the right time or place for me to try and achieve this goal?
                        <br />
                      </Text>
                    </div>
                  </div>

                  <div style={{ marginLeft: '20px' }}>
                    <Text style={{ fontWeight: 'bold' }}>Time-Based</Text>
                    <Text>
                      Great goals have a target date. They have a deadline for completion that you
                      can stick to. This helps ensure that one temporary, short-term goal—which is
                      supposed to be a single step on the way towards aiding greater goals or your
                      purpose—doesn’t become a massive time-suck or permanent hinderance. Time-based
                      goals can usually answer these questions:
                    </Text>
                    <div style={{ marginLeft: '20px' }}>
                      <Text>
                        -When? <br />
                        -What can be done today to help accomplish this?
                        <br />
                        -What can I do six weeks from now?
                        <br />
                        -What can I do six months from now?
                        <br />
                      </Text>
                    </div>
                  </div>

                  <Text>
                    Finally, as we discussed before, it’s most efficient and effective to rely on
                    our personal strengths when trying to achieve our goals.
                  </Text>
                  <Text>
                    With this in mind, let’s aim to set 3 goals for your upcoming journey. Again,
                    these can change, but it’s helpful when planing to have such goals in mind.
                  </Text>
                </IntroContainer>
              </FlexBox>

              <Button
                onClick={() => changeStep(2)}
                style={{
                  float: 'right',
                  marginTop: '20px',
                  marginRight: '10%',
                  marginBottom: '50px',
                }}
              >
                Create Your Goals
              </Button>
            </>
          )}

          {step == 2 && (
            <>
              <StepSubtitle>Your Goals</StepSubtitle>
              <FlexBox justifyContent="center" style={{ marginTop: '40px' }}>
                <InputsContainer>
                  <Text>
                    I have set the following goals for myself to achieve over the course of my
                    journey. Each of these goals is specific in scope and target date; clearly
                    measurable by action, behavior, or number; attainable given my individual
                    strengths, weaknesses, and circumstances; and in accordance with my values and
                    purpose.
                  </Text>
                  <FlexBox justifyContent="space-between" flexDirection="column">
                    <LongTextAreaContainer>
                      <Label>Goal 1:</Label>
                      <TextArea height="60" name="goal_1" value={goal_1} onChange={handleChange} />
                    </LongTextAreaContainer>
                    <LongTextAreaContainer>
                      <Label>Goal 2:</Label>
                      <TextArea height="60" name="goal_2" value={goal_2} onChange={handleChange} />
                    </LongTextAreaContainer>

                    <LongTextAreaContainer>
                      <Label>Goal 3 :</Label>
                      <TextArea height="60" name="goal_3" value={goal_3} onChange={handleChange} />
                    </LongTextAreaContainer>
                  </FlexBox>
                </InputsContainer>
              </FlexBox>
              <Button
                onClick={createGoals}
                style={{
                  float: 'right',
                  marginTop: '20px',
                  marginRight: '10%',
                  marginBottom: '50px',
                }}
              >
                Create Your Goals
              </Button>
            </>
          )}
        </StepContainer>
      </ModalDiv>
    );
  }
}

export default PreparingToReturnView;
