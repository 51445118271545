import React, { Component } from 'react';
import PreparingToReturnView from './JournalPreparingToReturnView';
import { connect } from 'react-redux';
import * as journalActions from 'actions/journalActions';
import { selectFields } from './StepFields/PreparingToReturn';
import { push } from 'modules/history';

class PreparingToReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message_morning: '',
      message_today: '',
      message_evening: '',
      message_new_learn: '',
      message_new_life: '',
      message_new_ability: '',
    };
  }

  componentDidMount() {
    const {
      getPreparingReturnRequest,
      match: { params },
    } = this.props;
    getPreparingReturnRequest(params.id, params.day);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.preparingReturn && this.props.preparingReturn) ||
      (prevProps.preparingReturn && prevProps.preparingReturn != this.props.preparingReturn)
    ) {
      const {
        message_morning,
        message_today,
        message_evening,
        message_new_learn,
        message_new_life,
        message_new_ability,
      } = this.props.preparingReturn;
      this.setState({
        message_morning,
        message_today,
        message_evening,
        message_new_learn,
        message_new_life,
        message_new_ability,
      });
    }
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  saveDay = () => {
    const {
      createDayPreparingReturnRequest,
      match: { params },
      day,
      preparingReturn: { id },
    } = this.props;
    const {
      message_morning,
      message_today,
      message_evening,
      message_new_learn,
      message_new_life,
      message_new_ability,
    } = this.state;

    createDayPreparingReturnRequest(id, {
      message_morning,
      message_today,
      message_evening,
      message_new_learn,
      message_new_life,
      message_new_ability,
      status: 'completed',
    });
  };

  render() {
    const { day, preparingReturn } = this.props;
    if (!preparingReturn) return null;
    const {
      message_morning,
      message_today,
      message_evening,
      message_new_learn,
      message_new_life,
      message_new_ability,
    } = this.state;
    const stepFields = selectFields(+day);

    return (
      <PreparingToReturnView
        {...this.props}
        saveDay={this.saveDay}
        stepFields={stepFields}
        currentDay={day}
        message_morning={message_morning}
        message_today={message_today}
        message_evening={message_evening}
        message_new_learn={message_new_learn}
        message_new_life={message_new_life}
        message_new_ability={message_new_ability}
        handleChange={this.handleChange}
        date={preparingReturn.date}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    preparingReturn: state.journalReducer.preparingReturn,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createDayPreparingReturnRequest: (id, preparingReturn) =>
      dispatch(journalActions.createDayPreparingReturnRequest(id, preparingReturn)),
    getPreparingReturnRequest: (journal_id, day) =>
      dispatch(journalActions.getPreparingReturnRequest({ journal_id, day })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreparingToReturn);
