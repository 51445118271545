import { ActionTypes } from 'constants/index';

export function changeCurrentStep(step) {
  return {
    type: ActionTypes.TRIP_CHANGE_CURRENT_STEP,
    step,
  };
}
export function saveTripData(data) {
  return {
    type: ActionTypes.SAVE_TRIP_DATA,
    data,
  };
}
export function getDestinations(search) {
  return {
    type: ActionTypes.GET_DESTINATIONS,
    search,
  };
}
export function saveAvailableCities(data) {
  return {
    type: ActionTypes.SAVE_DESTINATIONS,
    data,
  };
}
export function persistTripData() {
  return {
    type: ActionTypes.SUBMIT_TRIP,
  };
}

export function getTrip(id) {
  return {
    type: ActionTypes.GET_TRIP,
    id,
  };
}

export function saveTrip(tripDetails) {
  return {
    type: ActionTypes.SAVE_TRIP,
    tripDetails,
  };
}

export function getMeTrips() {
  return {
    type: ActionTypes.GET_ME_TRIPS,
  };
}

export function saveMeTrips(trips) {
  return {
    type: ActionTypes.SAVE_ME_TRIPS,
    trips,
  };
}
export function editTrip(id) {
  return {
    type: ActionTypes.EDIT_TRIP,
    id,
  };
}

export function editTripSimple(id, data) {
  return {
    type: ActionTypes.EDIT_TRIP_SIMPLE_REQUEST,
    id,
    data,
  };
}

export function resetTripForm() {
  return {
    type: ActionTypes.RESET_TRIP_FORM,
  };
}

export function createTripChargeRequest(id) {
  return {
    type: ActionTypes.CREATE_TRIP_CHARGE_REQUEST,
    id,
  };
}

export function getAllTripsRequest(page, elementsPerPage) {
  return {
    type: ActionTypes.GET_ALL_TRIPS_REQUEST,
    page,
    elementsPerPage,
  };
}
export function getAllTripsSuccess(data) {
  return {
    type: ActionTypes.GET_ALL_TRIPS_SUCCESS,
    data,
  };
}

//trip costs

export function getCosts(id) {
  return {
    type: ActionTypes.GET_COSTS,
    id,
  };
}

export function getCostsSuccess(costs) {
  return {
    type: ActionTypes.GET_COSTS_SUCCESS,
    costs,
  };
}

export function updateCosts(id, data) {
  return {
    type: ActionTypes.UPDATE_COST,
    id,
    data,
  };
}

export function updateCostsSuccess() {
  return {
    type: ActionTypes.UPDATE_COST_SUCCESS,
  };
}
