import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PageWithMenu from '../components/PageWithMenu';

const RoutePrivate = ({
  component: Component,
  isAuthenticated,
  to,
  role_code,
  profile,
  path,
  allow,
  logout,
  withoutMenu,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && allow.includes(role_code) ? (
          withoutMenu ? (
            <Component {...props} />
          ) : (
            <PageWithMenu path={props.location.pathname} profile={profile} role_code={role_code}>
              <Component {...props} />
            </PageWithMenu>
          )
        ) : (
          <Redirect
            to={{
              pathname: to,
              state: { redirect: props.location.pathname, isAuthenticated },
            }}
          />
        )
      }
    />
  );
};

RoutePrivate.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object,
  profile: PropTypes.object,
  to: PropTypes.string,
};

RoutePrivate.defaultProps = {
  to: '/',
};

export default RoutePrivate;
