import React from 'react';
import styled from 'styled-components';
import config from 'config/appConfig';
import Modal from 'components/Modal';
import { replace } from 'modules/history';
import ReactPlayer from 'react-player';

const Title = styled.p`
  font-size: 40px;
  color: #353535;
  text-align: center;
  font-weight: 300;
`;

const Login = props => {
  return (
    <Modal
      closeIcon
      isVisible={true}
      successText="Let's Start!"
      onSuccess={() => replace('/diagnostics')}
      width="60%"
      widthMedium="40%"
    >
      <Title>About Authentic Traveling</Title>
      <div className="player-wrapper">
        <ReactPlayer
          width={'100%'}
          height={'70%'}
          controls={true}
          url={`${config.BASE_URL}/uploads/activities/user_1/introvideo.mp4`}
          playing
        />
      </div>
    </Modal>
  );
};

export default Login;
