import React from 'react';
import styled from 'styled-components';

const DestinationButton = styled.a`
  width: 270px;
  display: inline-block;
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 300;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.01);
  border-radius: 4px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  cursor: pointer;
  background: ${props => (props.selected ? '#00b4d51b' : 'white')};
  color: ${props => (props.selected ? 'rgb(15, 203, 255)' : '#9e9e9e')};
  border-color: ${props => (props.selected ? 'rgb(15, 203, 255)' : '#9e9e9e')};
  border: ${props => (props.selected ? '2px solid;' : '1px solid;')};
  margin: 15px;
`;

const Name = styled.p`
  color: #3e3e3e;
  font-size: 18px;
  margin: 0;
  text-align: initial;
  font-weight: normal;
`;
const Date = styled.p`
  color: #9e9e9e;
  text-align: initial;
`;

const ActionButton = styled.i`
  width: 20px;
  margin-left: 10px;
`;

const Actions = styled.div`
  display: flex;
  float: right;
`;

export default props => (
  <DestinationButton selected={props.selected}>
    <Name>{props.name}</Name>
    <Date>{props.date}</Date>
    <Actions>
      <ActionButton>
        <i className="far fa-trash-alt" style={{ color: 'red' }} onClick={props.deleteAction} />
      </ActionButton>
      <ActionButton>
        <i className="far fa-hand-pointer" onClick={props.action} />
      </ActionButton>
    </Actions>
  </DestinationButton>
);
