import React, { Component } from 'react';
import styled from 'styled-components';
import Dropzone from 'components/DropZone';
import Input from 'components/Input';
import pdfFile from 'assets/media/images/pdf.png';
import LightButton from 'components/LightButton';
import WarningButton from 'components/WarningButton';
import config from 'config/appConfig';

const Container = styled.div`
  overflow-x: auto;
  display: block;
  max-width: 100%;
  margin: 10px;
`;
const Label = styled.p`
  font-size: 14px;
  margin: 0px;
`;

const DropzoneContainer = styled.div`
  margin-bottom: 34px;
  width: 100%;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  height: 120px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 0px;
  margin-top: 30px;
`;

const ImageProfile = styled.img`
  height: 95%;
  width: auto;
  margin: auto;
`;

const ReplaceImageText = styled.p`
  color: white;
  position: absolute;
  top: 40%;
  background-color: darkgray;
  padding: 5px;
  border-radius: 8px;
}


`;

const FormContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: end;
`;

const InputBox = styled.div`
  display: ${props => (props.inlineBlock ? 'inline-block' : 'initial')};

  width: ${props => props.width}%;
  padding: 10px;
`;

class ActivityForm extends Component {
  render() {
    const {
      handleChange,
      updateImage,
      token,
      replaceImage,
      createPreTripCourses,
      updatePreTripCourse,
      updateFile,
      replaceFile,
    } = this.props;
    const { id, title, image, file } = this.props.data;
    return (
      <Container>
        <FormContainer justifyContent="center">
          <InputBox width={50}>
            <Label>Title</Label>
            <Input placeholder="Title" name="title" value={title} onChange={handleChange} />
          </InputBox>

          <InputBox width={50}>
            <Label>Image</Label>

            <DropzoneContainer>
              {!image && (
                <Dropzone
                  title="Choose image"
                  height={120}
                  url={`${config.BASE_URL}/api/v1/upload/pre-trip-courses`}
                  authToken={token}
                  handleResponse={updateImage}
                  folder={'pre-trip-courses'}
                />
              )}
              {image && (
                <>
                  <ImageProfile src={image} />
                  {
                    <ReplaceImageText onClick={replaceImage}>
                      Click to Replace Image
                    </ReplaceImageText>
                  }
                </>
              )}
            </DropzoneContainer>
          </InputBox>
        </FormContainer>

        <InputBox width={100} inlineBlock>
          <Label>File</Label>

          <DropzoneContainer>
            {!file && (
              <Dropzone
                title="Choose file"
                accept=".pdf"
                height={120}
                url={`${config.BASE_URL}/api/v1/upload/pre-trip-courses-file`}
                authToken={token}
                handleResponse={updateFile}
                folder={'pre-trip-courses-file'}
              />
            )}
            {file && (
              <>
                <ImageProfile src={pdfFile} />

                {<ReplaceImageText onClick={replaceFile}>Click to Replace File</ReplaceImageText>}
              </>
            )}
          </DropzoneContainer>
        </InputBox>
        <FormContainer justifyContent="flex-end">
          <LightButton
            onClick={
              id
                ? () => updatePreTripCourse(id, this.props.data)
                : () => createPreTripCourses(this.props.data)
            }
          >
            Save
          </LightButton>
          <WarningButton onClick={() => this.props.history.goBack()}>Cancel</WarningButton>
        </FormContainer>
      </Container>
    );
  }
}

export default ActivityForm;
