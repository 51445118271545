import React from 'react';
import styled from 'styled-components';
import Pagination from 'components/PaginationButtons';
import ListEmpty from 'components/ListEmpty';
import AddClimateCityForm from './AddClimateCityForm';
import Button from 'components/Button';
import ModalExcel from 'components/Suggestions/ModalExcel';

const Container = styled.div``;

const HeaderContainer = styled.div`
  margin: 20px;
  padding-bottom: 5px;
  position: relative;
  width: auto;
  position: relative;
  height: 50px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const ActionIcon = styled.i`
  color: ${props => props.color};
  font-size: 20px;
  padding-right: 10px;
`;

const GridContainer = styled.div`
  margin: 20px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TBody = styled.div``;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;
const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const InputActivity = styled.input`
  width: 100%;
  height: 50px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 5)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const LinkText = styled.p`
  padding-top: 5px;
  font-size: 18px;
  cursor: pointer;
  color: #38b6ff;
`;

const ClimatesList = props => {
  const {
    viewMode,
    token,
    searchCity,
    citySearchableText,
    currentPage,
    changePage,
    elementsPerPage,
    climatesCount,
    getClimate,
    //cities,
    loading = false,
    cities,
    climate,
    saveClimate,
    editClimate,
    setClimate,
    onRemoveClimate,
    isEditing,
    handleisEditing,
    renderAddCity,
    handleAddCity,
    handleModalExcel,
    renderModalExcel,
    country,
    regionsForSelect,
    citiesForSelect,
    getCitiesForSelect,
  } = props;

  const renderCitiesList = (city, key) => {
    return (
      <Item key={key}>
        <SectionContainer width={'20%'}>
          <Primary>{city.city.name}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{city.region.name}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{city.best_time_to_visit_for_weather_start_date}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{city.best_time_to_visit_for_weather_end_date}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'15%'} textAlign="right">
          {!viewMode ? (
            <>
              <ActionIcon
                onClick={() => {
                  setClimate(city);
                  handleAddCity(true);
                  handleisEditing(true);
                }}
                color="#38b6ff"
                className="fas fa-edit"
              />

              <ActionIcon
                onClick={() => {
                  onRemoveClimate(city.id);
                }}
                color="#ff0000ad"
                className="fas fa-trash-alt"
              />
            </>
          ) : (
            <ActionIcon
              onClick={() => {
                setClimate(city);
                handleAddCity(true);
                handleisEditing(true);
              }}
              color="#38b6ff"
              className="fas fa-eye"
            />
          )}
        </SectionContainer>
      </Item>
    );
  };

  return (
    <Container>
      {!renderAddCity && (
        <>
          <HeaderContainer style={{ marginBottom: 20 }}>
            <FlexRow>
              <Pagination
                currentPage={currentPage}
                changePage={changePage}
                totalPages={Math.ceil(climatesCount / elementsPerPage)}
              />
              <FlexColumn width={30}>
                <InputActivity
                  type="text"
                  placeholder="Search City"
                  name="citySearchableText"
                  value={citySearchableText}
                  onChange={searchCity}
                />
              </FlexColumn>
              {!viewMode && (
                <FlexColumn width={25}>
                  <FlexRow>
                    <LinkText onClick={() => handleModalExcel(true)} style={{ marginTop: 15 }}>
                      Import from sheet
                    </LinkText>
                    <Button onClick={() => handleAddCity(true)} style={{ marginTop: 0 }}>
                      Add City
                    </Button>
                  </FlexRow>
                </FlexColumn>
              )}
            </FlexRow>
          </HeaderContainer>
          <GridContainer>
            <THeader style={{ marginTop: 60 }}>
              <TitleContainer width={'20%'}>
                <Title>NAME</Title>
              </TitleContainer>
              <TitleContainer width={'20%'}>
                <Title>REGION</Title>
              </TitleContainer>
              <TitleContainer width={'20%'}>
                <Title>BEST TIME TO VISIT FOR WEATHER START DATE</Title>
              </TitleContainer>
              <TitleContainer width={'20%'}>
                <Title>BEST TIME TO VISIT FOR WEATHER END DATE</Title>
              </TitleContainer>
              <TitleContainer width={'15%'} />
            </THeader>
            <TBody>
              {cities.map((city, key) => {
                return renderCitiesList(city, key);
              })}
              {(!cities || !cities.length) && <ListEmpty message="There are currently no cities" />}
            </TBody>
          </GridContainer>
        </>
      )}

      {renderAddCity && (
        <AddClimateCityForm
          viewMode={viewMode}
          isEditing={isEditing}
          handleisEditing={handleisEditing}
          setClimate={setClimate}
          country={country}
          handleAddCity={handleAddCity}
          climate={climate}
          saveClimate={saveClimate}
          editClimate={editClimate}
          regionsForSelect={regionsForSelect}
          citiesForSelect={citiesForSelect}
          getCitiesForSelect={getCitiesForSelect}
        />
      )}

      <ModalExcel
        get={getClimate}
        id={country.id}
        token={token}
        url="climates"
        currentPage={currentPage}
        elementsPerPage={elementsPerPage}
        renderModalExcel={renderModalExcel}
        handleModalExcel={handleModalExcel}
      ></ModalExcel>
    </Container>
  );
};

export default ClimatesList;
