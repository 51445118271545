import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import moment from 'moment';
const parse = require('html-react-parser');

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const HowUseBox = styled.div`
  margin-left: 50px;
  margin-right: 30px;
`;

const HeaderTitle = styled.p`
  color: #38b6ff;
  font-size: 29px;
  text-align: center;
  margin-bottom: 10px;
  width: 33%;
`;

const TextArea = styled.textarea`
border-style: solid;
    border-width: 1px;
    border: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #bebcbc;
    height: 100px;
    resize: none;
    padding: 10px;
    width: 100%;
}
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const Label = styled.p`
  font-size: 13px;
  line-height: 0;
  color: #999999;
`;

const TextAreaContainer = styled.div`
  width: 33%;
  margin: 5px;
`;

const ContentTitle = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 28px;
  text-align: center;
  margin-left: 70px;
  margin-right: 70px;
  color: #808080;
  font-weight: 600;
`;

const ContentBody = styled.p`
  font-size: 14px;
  line-height: 1.47;
  color: #999999;
  text-align: justify;
  height: 100%;
  padding-right: 15px;
  overflow: auto;
  height: 100px;
  overflow: auto;
`;

const HeaderDetails = styled.p`
  font-size: 15px;
  color: #999999;
  text-align: ${props => props.textAlign};
`;

const JournalDayStep = props => {
  const {
    currentDay,
    message_morning,
    handleChange,
    message_today,
    message_evening,
    stepFields,
    saveDay,
    daysQuantity,
    headerTitle,
    date,
  } = props;
  const dayDate = moment(date)
    .format('dddd ')
    .toString();
  const restDate = moment(date)
    .format('MMMM Do, YYYY')
    .toString();
  return (
    <HowUseBox>
      <FlexBox>
        <HeaderDetails textAlign={'left'}>
          {currentDay} of {daysQuantity}
        </HeaderDetails>
        <HeaderTitle>{headerTitle}</HeaderTitle>
        <HeaderDetails textAlign={'right'}>
          <b>{dayDate} </b>
          <span style={{ fontWeight: 300 }}>{restDate}</span>
        </HeaderDetails>
      </FlexBox>
      <FlexBox style={{ marginTop: '80px' }}>
        <TextAreaContainer>
          <Label>This morning I am thankful for:</Label>
          <TextArea
            placeholder="ex. something"
            name="message_morning"
            value={message_morning}
            onChange={handleChange}
          />
        </TextAreaContainer>
        <TextAreaContainer>
          <Label>Today my Why is:</Label>

          <TextArea
            placeholder="ex. something"
            name="message_today"
            value={message_today}
            onChange={handleChange}
          />
        </TextAreaContainer>
        <TextAreaContainer>
          <Label>This evening I am thankful for:</Label>
          <TextArea
            placeholder="ex. something"
            name="message_evening"
            value={message_evening}
            onChange={handleChange}
          />
        </TextAreaContainer>
      </FlexBox>
      {props.children && props.children}
      {stepFields && (
        <>
          {stepFields.strengthName && (
            <HeaderDetails>
              Strength of the day:
              <span style={{ color: '#38b6ff' }}> {stepFields.strengthName} </span>
            </HeaderDetails>
          )}

          <ContentBody>{parse(stepFields.strengthDescription)}</ContentBody>
        </>
      )}

      <FlexBox>
        <Button onClick={saveDay} style={{ margin: 'auto', marginBottom: '25px' }}>
          Done
        </Button>
      </FlexBox>
    </HowUseBox>
  );
};

export default JournalDayStep;
