import React, { Component } from 'react';
import styled from 'styled-components';
import Checkbox from '../../Login/CheckboxRemember';
import { COUNTRY_LIST } from 'constants/index';
import Input from '../../Input';
import StepContainer from '../../StepContainer';
import StepButtons from '../../../components/StepButtons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FBLoginButton from '../../Login/FBloginButton';
import GoogleLoginButton from '../../Login/GoogleLoginButton';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const InputsContainer = styled.div`
  display: flex;
  margin: 20px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const InputsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 1400px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;

const FormError = styled.p`
  color: red;
  font-size: 10px;
  margin-top: 0px;
`;

const StepTitle = styled.p`
  margin-top: 23px;
  color: #9e9e9e;
  margin-left: 40px;
`;

const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ButtonsBox = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const NextButton = styled.div`
  display: flex;
  width: 30%;
  margin-right: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
  }
`;

const ClearButton = styled.div`
  display: flex;
  margin-right: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  justify-content: center;
`;
const LightButton = styled.button`
  border-radius: 5px;
  color: white;
  background-color: #00b4d51b;
  border: 1px solid #00b4d51b;
  width: 150px;
  height: 50px;
  color: #38b6ff;
  cursor: pointer;
`;

const Button = styled.button`
  border-radius: 5px;
  color: white;
  background-color: #38b6ff;
  border: 1px solid #38b6ff;
  width: 150px;
  height: 50px;
  color: white;
  cursor: pointer;
`;

const Arrow = styled.span`
  margin-left: 10px;
  margin-right: 10px;
`;

export default class Step1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data, changeStep, facebookRegist, googleRegist, cleanForm, step } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={data}
        isInitialValid={data.firstName ? true : false}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Required')
            .email('Invalid Format'),
          firstName: Yup.string().required('Required'),
          password: Yup.string()
            .required('Required')
            .min(8, 'The password must have more than eight characters'),
          country: Yup.string().required('Required'),
          phone: Yup.string()
            .required('Required')
            .matches(phoneRegExp, 'Phone number is not valid'),
          lastName: Yup.string().required('Required'),
          confirmEmail: Yup.string()
            .required('Required')
            .test('passwords-match', 'Emails dont match', function(value) {
              return this.parent.email === value;
            }),
          confirmPassword: Yup.string()
            .required('Required')
            .test('passwords-match', 'Passwords dont match', function(value) {
              return this.parent.password === value;
            }),
          city: Yup.string().required('Required'),
          aceptTerms: Yup.bool().oneOf([true], 'Field must be checked'),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          setFieldTouched,
          resetForm,
        }) => {
          return (
            <>
              <StepContainer title={'Sign Up'}>
                <InputsContainer>
                  <InputsColumn>
                    <Input
                      placeholder="First Name*"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      tabIndex="1"
                      onBlur={handleBlur}
                      disabled={data.provider !== 'local'}
                    />
                    <FormError>
                      {errors.firstName && touched.firstName && errors.firstName}
                    </FormError>
                    <Input
                      placeholder="Email*"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      tabIndex="3"
                      onBlur={handleBlur}
                      disabled={data.provider !== 'local'}
                    />
                    <FormError>{errors.email && touched.email && errors.email}</FormError>

                    <Input
                      placeholder="Password*"
                      name="password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      tabIndex="5"
                      onBlur={handleBlur}
                      disabled={data.provider !== 'local'}
                    />
                    <FormError>{errors.password && touched.password && errors.password}</FormError>
                    <Input
                      placeholder="Country*"
                      name="country"
                      list="counrty_list"
                      value={values.country}
                      onChange={handleChange}
                      tabIndex="7"
                      onBlur={handleBlur}
                    />
                    <FormError>{errors.country && touched.country && errors.country}</FormError>

                    <datalist id="counrty_list">
                      {COUNTRY_LIST.map((country, key) => (
                        <option key={key} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </datalist>

                    <Input
                      placeholder="City*"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      tabIndex="9"
                      onBlur={handleBlur}
                    />
                    <FormError>{errors.city && touched.city && errors.city}</FormError>
                    <Checkbox
                      isChecked={values.aceptTerms}
                      toggleChange={handleChange}
                      name="aceptTerms"
                      label={
                        <span>
                          I agree to the <a style={{ cursor: 'pointer' }}>Terms and Conditions</a>*
                        </span>
                      }
                      tabIndex="11"
                    />
                    <FormError>
                      {errors.aceptTerms && touched.aceptTerms && errors.aceptTerms}
                    </FormError>
                  </InputsColumn>
                  <InputsColumn>
                    <Input
                      placeholder="Last Name*"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      tabIndex="2"
                      onBlur={handleBlur}
                      disabled={data.provider !== 'local'}
                    />
                    <FormError>{errors.lastName && touched.lastName && errors.lastName}</FormError>

                    <Input
                      placeholder="Confirm Email*"
                      name="confirmEmail"
                      value={values.confirmEmail}
                      onChange={handleChange}
                      tabIndex="4"
                      onBlur={handleBlur}
                      disabled={data.provider !== 'local'}
                    />
                    <FormError>
                      {errors.confirmEmail && touched.confirmEmail && errors.confirmEmail}
                    </FormError>

                    <Input
                      placeholder="Confirm Password*"
                      name="confirmPassword"
                      type="password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      tabIndex="6"
                      onBlur={handleBlur}
                      disabled={data.provider !== 'local'}
                    />
                    <FormError>
                      {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                    </FormError>

                    <Input
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      tabIndex="8"
                      onBlur={handleBlur}
                    />

                    <Input
                      placeholder="Phone*"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      tabIndex="10"
                      onBlur={handleBlur}
                    />
                    <FormError>{errors.phone && touched.phone && errors.phone}</FormError>
                  </InputsColumn>
                </InputsContainer>
                <StepTitle>Or:</StepTitle>
                <ButtonsBox>
                  <SocialButtons>
                    <GoogleLoginButton googleLogin={googleRegist} />
                    <FBLoginButton facebookLogin={facebookRegist} />
                  </SocialButtons>
                  <NextButton>
                    <Button
                      onClick={() => {
                        for (let property in values) {
                          setFieldTouched(property, true);
                        }
                        if (isValid) {
                          changeStep(step + 1, values);
                        } else {
                          console.error('form is not valid, errors:', errors);
                        }
                      }}
                    >
                      Next <Arrow>→</Arrow>
                    </Button>
                  </NextButton>
                </ButtonsBox>
              </StepContainer>
              <ClearButton>
                <LightButton
                  onClick={() => {
                    cleanForm();
                    resetForm();
                  }}
                >
                  Clear Form
                </LightButton>
              </ClearButton>
            </>
          );
        }}
      </Formik>
    );
  }
}
