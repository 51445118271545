import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';
import TripView from './TripForm';

class CreateTripContainer extends Component {
  state = { suggestionsModal: false };

  componentDidMount() {
    const {
      saveTripData,
      match: { params },
    } = this.props;

    if (params.customer) {
      saveTripData({ user_id: params.customer });
    }
  }

  handleModal = suggestionsModal => {
    this.setState({ suggestionsModal });
  };

  render() {
    const { suggestionsModal } = this.state;
    return (
      <TripView
        {...this.props}
        suggestionsModal={suggestionsModal}
        handleModal={this.handleModal}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    step: state.tripReducer.tripForm.step,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
    resetTripForm: () => dispatch(tripActions.resetTripForm()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateTripContainer);
