import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import RadioInput from 'components/RadioInput';
import { ISVEGAN } from 'constants/index';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

class IsVeganView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { step, changeStep, handleChange, is_vegan } = this.props;

    return (
      <Container>
        <PrimaryText text="Are you—or anyone in your potential travel group—vegan or vegetarian?" />

        <RadioInput
          name="is_vegan"
          options={ISVEGAN}
          handleChange={handleChange}
          currentValue={is_vegan}
          verticalAlign
        />

        <StepButtons
          nextDisabled={!is_vegan}
          backStep={() => changeStep(step - 1)}
          nextStep={() => changeStep(step + 1)}
        />
      </Container>
    );
  }
}

export default IsVeganView;
