import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import { SHOPPING } from 'constants/index';
import RadioInput from 'components/RadioInput';

const SelectShoppingBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SelectShoppingPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`;

class SelectShoppingView extends Component {
  render() {
    const { step, state, changeStep, handleChange, shoppingMaping } = this.props;
    return (
      <SelectShoppingBox>
        <SelectShoppingPrimaryBox>
          <PrimaryText text="What types of shopping interest you? Please select all that apply and rate your interest level low, medium, or high." />
          <Content>
            {SHOPPING.map(shopping => (
              <RadioInput
                style={{ marginBottom: 50 }}
                label={shopping}
                name={shoppingMaping[shopping]}
                options={[
                  { numericValue: 0, text: 'Nothing' },
                  { numericValue: 1, text: 'Low' },
                  { numericValue: 2, text: 'Medium' },
                  { numericValue: 3, text: 'High' },
                ]}
                handleChange={handleChange}
                currentValue={state[shoppingMaping[shopping]]}
                verticalAlign
              />
            ))}
          </Content>
          <StepButtons
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(step + 1)}
          />
        </SelectShoppingPrimaryBox>
      </SelectShoppingBox>
    );
  }
}

export default SelectShoppingView;
