import React, { Component } from 'react';
import { replace } from 'modules/history';
import TripDetailView from './TripDetailView';
import { connect } from 'react-redux';
import * as tripActions from '../../actions/tripActions';
import * as authenticPlanActions from '../../actions/authenticPlanActions';
import * as goalActions from '../../actions/goalActions';

class TripDetailsContainer extends Component {
  state = {
    alertCreateItinerary: false,
    alertEditTrip: false,
    alertCreateCharge: false,
    authenticPlanModal: false,
    goalsModal: false,
    updateItineraryModal: false,
    alertCreateItineraryOnSucces: null,
    //trip dates
    start_date: null,
    departure_time: 'Afternoon',
    end_date: null,
    arriving_time: 'Afternoon',
  };

  componentDidMount() {
    const {
      fetchTrip,
      match: { params },
    } = this.props;

    fetchTrip(params.id);
  }

  approveAndCharge = () => {
    const { createTripChargeRequest, tripDetails } = this.props;

    if (tripDetails.start_date && tripDetails.end_date) {
      createTripChargeRequest(tripDetails.id);
    } else {
      this.setState({ alertCreateCharge: true });
    }
  };

  toEditTrip = () => {
    const { tripDetails } = this.props;

    replace(`/trip/edit/${tripDetails.id}`);
  };

  activateAtPlan = () => {
    const {
      createAuthenticPlanRequest,
      match: { params },
    } = this.props;

    createAuthenticPlanRequest({ trip_id: params.id });
  };

  changeAuthenticPlanModal = () => {
    const {
      fetchTrip,
      match: { params },
    } = this.props;

    fetchTrip(params.id);

    this.setState({ authenticPlanModal: !this.state.authenticPlanModal });
  };

  changeGoalsModal = () => {
    const {
      fetchTrip,
      match: { params },
    } = this.props;

    fetchTrip(params.id);
    this.setState({ goalsModal: !this.state.goalsModal });
  };
  activateGoal = () => {
    const {
      createGoalRequest,
      match: { params },
    } = this.props;

    createGoalRequest({
      trip_id: params.id,
    });
  };

  toggleAlert = name => {
    this.setState({ [name]: !this.state[name] });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  changeDate = (value, date) => {
    const newDate = { [value]: date };
    this.setState({
      ...newDate,
    });
  };

  updateTripDates = () => {
    const {
      saveTripData,
      editTripSimple,
      match: { params },
    } = this.props;
    this.toggleAlert('setDatesModal');

    const { start_date, departure_time, end_date, arriving_time } = this.state;
    saveTripData({ start_date, departure_time, end_date, arriving_time, know_dates: 1 });
    editTripSimple(params.id, {
      start_date,
      departure_time,
      end_date,
      arriving_time,
      know_dates: 1,
    });
  };

  render() {
    const {
      alertCreateItinerary,
      alertCreateItineraryMessage,
      authenticPlanModal,
      goalsModal,
      alertCreateItineraryOnSucces,
      alertCreateCharge,
      start_date,
      departure_time,
      end_date,
      arriving_time,
      setDatesModal,
    } = this.state;

    return (
      <TripDetailView
        {...this.props}
        approveAndCharge={this.approveAndCharge}
        alertCreateItinerary={alertCreateItinerary}
        toEditTrip={this.toEditTrip}
        alertCreateItineraryMessage={alertCreateItineraryMessage}
        activateAtPlan={this.activateAtPlan}
        activateGoal={this.activateGoal}
        changeAuthenticPlanModal={this.changeAuthenticPlanModal}
        authenticPlanModal={authenticPlanModal}
        changeGoalsModal={this.changeGoalsModal}
        goalsModal={goalsModal}
        alertCreateItineraryOnSucces={alertCreateItineraryOnSucces}
        alertCreateCharge={alertCreateCharge}
        toggleAlert={this.toggleAlert}
        changeDate={this.changeDate}
        start_date={start_date}
        departure_time={departure_time}
        end_date={end_date}
        arriving_time={arriving_time}
        handleChange={this.handleChange}
        setDatesModal={setDatesModal}
        updateTripDates={this.updateTripDates}
      />
    );
  }
}

function mapStateToProps(state) {
  //const customer = state.customerReducer.customer ? state.customerReducer.customer.profile : null;
  return {
    tripDetails: state.tripReducer.tripDetails,
    //customer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchTrip: id => dispatch(tripActions.getTrip(id)),
    createTripChargeRequest: id => dispatch(tripActions.createTripChargeRequest(id)),
    createAuthenticPlanRequest: data =>
      dispatch(authenticPlanActions.createAuthenticPlanRequest(data)),
    createGoalRequest: data => dispatch(goalActions.createGoalRequest(data)),

    saveTripData: data => dispatch(tripActions.saveTripData(data)),
    editTripSimple: (id, data) => dispatch(tripActions.editTripSimple(id, data)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripDetailsContainer);
