/*
 * Reducer actions related with customers
 */
import { ActionTypes } from 'constants/index';

export function getAllCities(page, elementsPerPage, country_id) {
  return {
    type: ActionTypes.GET_ALL_CITIES,
    page,
    elementsPerPage,
    country_id,
  };
}

export function getAllCitiesSuccess(payload) {
  return {
    type: ActionTypes.GET_ALL_CITIES_SUCCESS,
    cities: payload.rows,
    citiesCount: payload.count,
  };
}

export function getAllCitiesFailure() {
  return {
    type: ActionTypes.GET_ALL_CITIES_FAILURE,
  };
}

export function createCities(city) {
  return {
    type: ActionTypes.CREATE_CITIES,
    city,
  };
}

export function createCitiesSuccess() {
  return {
    type: ActionTypes.CREATE_CITIES_SUCCESS,
  };
}

export function createCitiesFailure() {
  return {
    type: ActionTypes.CREATE_CITIES_FAILURE,
  };
}

export function getCities(id) {
  return {
    type: ActionTypes.GET_CITIES,
    id,
  };
}

export function getCitiesSuccess(city) {
  return {
    type: ActionTypes.GET_CITIES_SUCCESS,
    city,
  };
}

export function getCitiesFailure() {
  return {
    type: ActionTypes.GET_CITIES_FAILURE,
  };
}

export function editCities(id, city) {
  return {
    type: ActionTypes.EDIT_CITIES,
    id,
    city,
  };
}

export function editCitiesSuccess() {
  return {
    type: ActionTypes.EDIT_CITIES_SUCCESS,
  };
}

export function editCitiesFailure() {
  return {
    type: ActionTypes.EDIT_CITIES_FAILURE,
  };
}

export function removeCities(id) {
  return {
    type: ActionTypes.REMOVE_CITIES,
    id,
  };
}

export function removeCitiesSuccess(id) {
  return {
    type: ActionTypes.REMOVE_CITIES_SUCCESS,
    id,
  };
}

export function removeCitiesFailure() {
  return {
    type: ActionTypes.REMOVE_CITIES_FAILURE,
  };
}

export function getCitiesForSelect(params) {
  return {
    type: ActionTypes.GET_CITIES_FOR_SELECT,
    params,
  };
}

export function getCitiesForSelectSuccess(citiesForSelect) {
  return {
    type: ActionTypes.GET_CITIES_FOR_SELECT_SUCCESS,
    citiesForSelect,
  };
}

export function getCitiesForSelectFailure() {
  return {
    type: ActionTypes.GET_CITIES_FOR_SELECT_FAILURE,
  };
}
