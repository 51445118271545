import React from 'react';
import styled from 'styled-components';
import Logo from 'assets/media/images/Authentic-Traveling-Logo.png';

import Button from 'components/Button';
import { Link } from 'react-router-dom';

import './styles.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  font-size: 40px;
  color: #353535;
  text-align: center;
`;

const Login = props => {
  return (
    <Container>
      <img src={Logo} width="250" />
      <i
        className="far fa-check-circle"
        style={{
          fontSize: '125px',
          color: '#38b6ff',
          width: '100%',
          height: '100%',
          marginTop: '80px',
          marginBottom: '40px',
        }}
      ></i>
      <Title>Your payment was successful!</Title>

      <Link to="/welcome">
        <Button>Let's Get Started</Button>
      </Link>
    </Container>
  );
};

export default Login;
