import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Modal from 'components/Modal';
import * as requestBoutiquePackageActions from '../../actions/requestBoutiquePackageActions';

const Container = styled.div`
  height: 75px;
  border-radius: 4px;
  padding: 15px;
  text-align: -webkit-center;

  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  padding: 0px;
  max-height: 75px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 20px;
  align-items: center;
`;

const BoxHeader = styled.div`
  height: 75px;
  padding: 0px 30px;
  object-fit: contain;
  border-radius: 5px;
  background-color: #38b6ff;
  display: table;
`;

const Title = styled.div`
  display: table-cell;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  vertical-align: middle;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  text-transform: uppercase;
`;

const Button = styled.button`
  border-radius: 5px;
  color: white;
  border: 1px solid #38b6ff;
  width: 158px;
  height: 34px;
  color: ${props => (props.selected ? 'white' : '#38b6ff')};
  background-color: ${props => (props.selected ? '#38b6ff' : 'transparent')};
  cursor: pointer;
  margin: 5px 20px;
`;

const Description = styled.p`
  font-size: 16px;
  font-stretch: normal;
  letter-spacing: normal;
  color: #38b6ff;
`;

const StatusBox = styled.div`
  border-color: #9e9e9e;
  border-radius: 11px;
  border-width: 1px;
  border-style: solid;
  width: 90%;
  margin: auto;
  padding: 10px 20px;
  margin-bottom: 12px;
`;

const Text = styled.p`
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.fontSize || '13'}px;
  line-height: 18px;
  text-transform: capitalize;
  color: #999999;
  text-align: center;
`;

const Textarea = styled.textarea`
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  margin-bottom: 5px;
  width: 90%;
  padding: 10px;
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

class BoutiquePackage extends Component {
  state = {
    isOpen: false,
    description: '',
  };

  componentWillReceiveProps(nextProps) {
    const { requestBoutiquePackage } = this.props;

    if (nextProps.requestBoutiquePackage !== requestBoutiquePackage) {
      const description = '';
      this.setState({ description });
    }
  }

  toggleModal = isOpen => {
    this.setState({ isOpen });
  };

  handleChange = evt => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  };

  renderStatus = () => {
    const {
      customerApprobeBoutiquePackageRequest,
      isSelected,
      requestBoutiquePackage,
      current_product,
      updateRequestBoutiquePackage,
    } = this.props;

    return (
      <>
        <Text fontSize={25}>Current status:</Text>
        {current_product && isSelected && (
          <StatusBox>
            <Text style={{ fontWeight: 'bold' }}>
              Great! Your custom package is currently active
            </Text>
            <Text fontSize={15}>Description: {current_product.description} </Text>
            <Text fontSize={20}>Subscription price: ${current_product.price} </Text>
            <Text fontSize={20}>Day price: ${current_product.day_price} </Text>
          </StatusBox>
        )}

        {requestBoutiquePackage.status == 'pending' && (
          <StatusBox>
            <Text style={{ fontWeight: 'bold' }}>Support team will reply soon</Text>
            <Text fontSize={15}>
              <span style={{ fontWeight: 'bold' }}>Pending request: </span>
              {requestBoutiquePackage.description}
            </Text>
          </StatusBox>
        )}
        {requestBoutiquePackage.status == 'approved_for_admin' && (
          <StatusBox>
            <Text style={{ fontWeight: 'bold' }}>
              The package was checked by the support team. Here you can see the price assigned and
              choose to approve or reject the package
            </Text>
            <Text fontSize={15}>Description: {requestBoutiquePackage.description} </Text>
            <Text fontSize={20}>Subscription price: ${requestBoutiquePackage.price} </Text>
            <Text fontSize={20}>Day price: ${requestBoutiquePackage.day_price} </Text>

            <Button
              onClick={() => {
                customerApprobeBoutiquePackageRequest();
                this.toggleModal(false);
              }}
            >
              Approve
            </Button>
            <Button
              onClick={() => {
                updateRequestBoutiquePackage(requestBoutiquePackage.id, {
                  status: 'rejected_for_customer',
                });
                this.toggleModal(true);
              }}
            >
              Reject
            </Button>
          </StatusBox>
        )}

        {requestBoutiquePackage.status == 'rejected_for_admin' && (
          <StatusBox>
            <Text style={{ fontWeight: 'bold' }}>
              Your package was rejected, you can try modifying it.
              <Text fontSize={15}>Description: {requestBoutiquePackage.description} </Text>
            </Text>
          </StatusBox>
        )}
        {requestBoutiquePackage.status == 'rejected_for_customer' && (
          <StatusBox>
            <Text style={{ fontWeight: 'bold' }}>
              You have rejected the price of this package. You can send a new request.
              <Text fontSize={15}>Description: {requestBoutiquePackage.description} </Text>
              <Text fontSize={20}>Subscription price: ${requestBoutiquePackage.price} </Text>
              <Text fontSize={20}>Day price: ${requestBoutiquePackage.day_price} </Text>{' '}
            </Text>
          </StatusBox>
        )}
      </>
    );
  };

  render() {
    const { isOpen, description } = this.state;
    const { isSelected, save, requestBoutiquePackage } = this.props;

    return (
      <Container>
        <BoxHeader>
          <Title>Boutique Package</Title>
        </BoxHeader>
        <Description>Request to support team your preferences for your plan.</Description>
        <Button selected={isSelected} onClick={() => this.toggleModal(true)}>
          {isSelected ? 'Selected' : 'Select'}
        </Button>

        <Modal
          closeIcon
          isVisible={isOpen}
          cancelText="Cancel"
          onCancel={() => this.toggleModal(false)}
          successText="Send"
          onSuccess={() => {
            save({ description });
            this.toggleModal(false);
          }}
          disabled={!description}
        >
          {requestBoutiquePackage && this.renderStatus(requestBoutiquePackage)}
          <Text fontSize={25}>Request a update:</Text>

          <Textarea rows={15} name="description" value={description} onChange={this.handleChange} />
        </Modal>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    save: data => dispatch(requestBoutiquePackageActions.saveRequestBoutiquePackage(data)),
    customerApprobeBoutiquePackageRequest: () =>
      dispatch(requestBoutiquePackageActions.customerApprobeBoutiquePackageRequest()),
    updateRequestBoutiquePackage: (id, data) =>
      dispatch(requestBoutiquePackageActions.updateRequestBoutiquePackage(id, data)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BoutiquePackage);
