import React, { Component } from 'react';
import styled from 'styled-components';
import StepContainer from 'components/StepContainer';
import Button from 'components/Button';
import { goBack } from 'modules/history';
import StepButtons from 'components/StepButtons';

const FlexBox = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: baseline;
  flex-direction: ${props => props.flexDirection};
`;

const SubStepContainer = styled.div`
  margin-left: 5%;
  margin-right: 5%;
`;

const TextArea = styled.textarea`
border-style: solid;
    border-width: 1px;
    border: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #bebcbc;
    height: ${props => props.height || 100}px;
    resize: none;
    padding: 10px;
    width: 100%;
}
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const Label = styled.p`
  font-size: 13px;
  line-height: 0;
  color: #999999;
`;

const TextAreaContainer = styled.div`
  width: 33%;
  margin: 5px;
`;

const LongTextAreaContainer = styled.div`
  width: 100%;
  margin: 5px;
`;

const IntroContainer = styled.div`
  width: 75%;
  margin: 20px;
`;
const ModalDiv = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-position-x: center;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-blend-mode: multiply;
  text-align: left;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 50px;
  overflow-x: scroll;
  z-index: 1050;
`;

const StepSubtitle = styled.p`
  text-align: center;
  color: #808080;
  font-size: 30px;
  margin-top: 40px;
  margin-left: 80px;
  margin-right: 80px;
`;

const Text = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  line-height: 1.47;
  color: #999999;
  text-align: justify;
  height: 100%;
  padding-right: 15px;
  overflow: auto;
`;

class PreparingToReturnView extends Component {
  render() {
    const { createAtPlan, handleChange, step, changeStep, data } = this.props;
    const {
      strength_1,
      strength_2,
      strength_3,
      strength_4,
      strength_5,
      strength_6,
      first_goal_1,
      first_goal_2,
      first_goal_3,
      second_goal_1,
      second_goal_2,
      second_goal_3,
      third_goal_1,
      third_goal_2,
      third_goal_3,
      declaration_1,
      declaration_2,
      declaration_3,
      declaration_4,
      declaration_5,
    } = data;
    return (
      <ModalDiv>
        <StepContainer closeIcon width={'70%'} closeAction={() => goBack()}>
          {step == 1 && (
            <>
              <StepSubtitle>Your Authentic Travel Plan</StepSubtitle>
              <FlexBox justifyContent="center">
                <IntroContainer>
                  <Text>
                    What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book. It has survived not only five
                    <br />
                    centuries, but also the leap into electronic typesetting, remaining essentially
                    unchanged. It was popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages, and more recently with desktop publishing
                    software like Aldus PageMaker including <br /> <br />
                    versions of Lorem Ipsum. Why do we use it? It is a long established fact that a
                    reader will be distracted by the readable content of a page when looking at its
                    layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                    distribution of letters, as opposed to using 'Content here, content here',
                    making it look like readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model text, and a search for
                    'lorem ipsum' will uncover many web sites still in their infancy. Various
                    versions have evolved over the years, sometimes by accident, sometimes on
                    purpose (injected humour and the like).
                  </Text>
                  <Button
                    onClick={() => changeStep(2)}
                    style={{ float: 'right', marginTop: '20px' }}
                  >
                    Let's get started
                  </Button>
                </IntroContainer>
              </FlexBox>
            </>
          )}

          {step == 2 && (
            <>
              <StepSubtitle>Your Authentic Travel Plan</StepSubtitle>
              <SubStepContainer>
                <Text>On my journey, I will be using the following Core Strength to do this.</Text>

                <FlexBox justifyContent="space-between">
                  <TextAreaContainer>
                    <Label>Strength 1:</Label>
                    <TextArea
                      placeholder="Give definition and explain what it does..."
                      name="strength_1"
                      value={strength_1}
                      onChange={handleChange}
                    />
                  </TextAreaContainer>
                  <TextAreaContainer>
                    <Label>Strength 2:</Label>
                    <TextArea
                      placeholder="Give definition and explain what it does..."
                      name="strength_2"
                      value={strength_2}
                      onChange={handleChange}
                    />
                  </TextAreaContainer>

                  <TextAreaContainer>
                    <Label>Strength 3:</Label>
                    <TextArea
                      placeholder="Give definition and explain what it does..."
                      name="strength_3"
                      value={strength_3}
                      onChange={handleChange}
                    />
                  </TextAreaContainer>
                </FlexBox>

                <FlexBox justifyContent="space-between">
                  <TextAreaContainer>
                    <Label>Strength 4:</Label>
                    <TextArea
                      placeholder="Give definition and explain what it does..."
                      name="strength_4"
                      value={strength_4}
                      onChange={handleChange}
                    />
                  </TextAreaContainer>
                  <TextAreaContainer>
                    <Label>Strength 5:</Label>
                    <TextArea
                      placeholder="Give definition and explain what it does..."
                      name="strength_5"
                      value={strength_5}
                      onChange={handleChange}
                    />
                  </TextAreaContainer>

                  <TextAreaContainer>
                    <Label>Strength 6:</Label>
                    <TextArea
                      placeholder="Give definition and explain what it does..."
                      name="strength_6"
                      value={strength_6}
                      onChange={handleChange}
                    />
                  </TextAreaContainer>
                </FlexBox>
              </SubStepContainer>
              <StepButtons
                style={{ marginTop: '45px' }}
                backStep={() => changeStep(step - 1)}
                nextStep={() => changeStep(step + 1)}
              />
            </>
          )}

          {step == 3 && (
            <>
              <StepSubtitle>Your Authentic Travel Plan</StepSubtitle>
              <FlexBox justifyContent="center">
                <IntroContainer>
                  <Text style={{ marginBottom: '50px' }}>
                    In addition, I will be protected by the 5 Foundational Strengths i Have honed
                    before leaving home, wich will allow me to :
                  </Text>

                  <Text>
                    - Be mindful and stay in the present moment regardless of what happens.
                  </Text>
                  <Text>
                    - Be confident and reach for the stars in an efort to make the most out of my
                    life.
                  </Text>
                  <Text>- Be resilent and quickly recover from setbacks.</Text>
                  <Text>- Be anti-fragile and learn and grow from pressure or obstacles.</Text>
                  <Text>
                    - Be purpose-filled and maintain a sense of direction based on long-term and
                    overarching goals.
                  </Text>
                </IntroContainer>
              </FlexBox>

              <StepButtons
                style={{ marginTop: '45px' }}
                backStep={() => changeStep(step - 1)}
                nextStep={() => changeStep(step + 1)}
              />
            </>
          )}

          {step == 4 && (
            <>
              <StepSubtitle>Your 1'st Goal</StepSubtitle>
              <FlexBox justifyContent="center">
                <IntroContainer>
                  <Text style={{ marginBottom: '50px' }}>Whatever your first goal is:</Text>
                  <FlexBox justifyContent="space-between" flexDirection="column">
                    <LongTextAreaContainer>
                      <Label>I want to achieve this goal because: </Label>
                      <TextArea
                        height="60"
                        name="first_goal_1"
                        value={first_goal_1}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                    <LongTextAreaContainer>
                      <Label>I will measure this goal by:</Label>
                      <TextArea
                        height="60"
                        name="first_goal_2"
                        value={first_goal_2}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>

                    <LongTextAreaContainer>
                      <Label>I will know when I have accomplished this goal when:</Label>
                      <TextArea
                        height="60"
                        name="first_goal_3"
                        value={first_goal_3}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                  </FlexBox>
                </IntroContainer>
              </FlexBox>

              <StepButtons
                style={{ marginTop: '45px' }}
                backStep={() => changeStep(step - 1)}
                nextStep={() => changeStep(step + 1)}
              />
            </>
          )}

          {step == 5 && (
            <>
              <StepSubtitle>Your 2'nd Goal</StepSubtitle>
              <FlexBox justifyContent="center">
                <IntroContainer>
                  <Text style={{ marginBottom: '50px' }}>Whatever your second goal is:</Text>
                  <FlexBox justifyContent="space-between" flexDirection="column">
                    <LongTextAreaContainer>
                      <Label>I want to achieve this goal because: </Label>
                      <TextArea
                        height="60"
                        name="second_goal_1"
                        value={second_goal_1}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                    <LongTextAreaContainer>
                      <Label>I will measure this goal by:</Label>
                      <TextArea
                        height="60"
                        name="second_goal_2"
                        value={second_goal_2}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>

                    <LongTextAreaContainer>
                      <Label>I will know when I have accomplished this goal when:</Label>
                      <TextArea
                        height="60"
                        name="second_goal_3"
                        value={second_goal_3}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                  </FlexBox>
                </IntroContainer>
              </FlexBox>

              <StepButtons
                style={{ marginTop: '45px' }}
                backStep={() => changeStep(step - 1)}
                nextStep={() => changeStep(step + 1)}
              />
            </>
          )}

          {step == 6 && (
            <>
              <StepSubtitle>Your 3'nd Goal</StepSubtitle>
              <FlexBox justifyContent="center">
                <IntroContainer>
                  <Text style={{ marginBottom: '50px' }}>Whatever your third goal is:</Text>
                  <FlexBox justifyContent="space-between" flexDirection="column">
                    <LongTextAreaContainer>
                      <Label>I want to achieve this goal because: </Label>
                      <TextArea
                        height="60"
                        name="third_goal_1"
                        value={third_goal_1}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                    <LongTextAreaContainer>
                      <Label>I will measure this goal by:</Label>
                      <TextArea
                        height="60"
                        name="third_goal_2"
                        value={third_goal_2}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>

                    <LongTextAreaContainer>
                      <Label>I will know when I have accomplished this goal when:</Label>
                      <TextArea
                        height="60"
                        name="third_goal_3"
                        value={third_goal_3}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                  </FlexBox>
                </IntroContainer>
              </FlexBox>

              <StepButtons
                style={{ marginTop: '45px' }}
                backStep={() => changeStep(step - 1)}
                nextStep={() => changeStep(step + 1)}
              />
            </>
          )}

          {step == 7 && (
            <>
              <StepSubtitle>Declarations</StepSubtitle>
              <FlexBox justifyContent="center">
                <IntroContainer>
                  <FlexBox justifyContent="space-between" flexDirection="column">
                    <LongTextAreaContainer>
                      <Label>
                        When I return home from my journey. I will be happier and more satisfied
                        because:
                      </Label>
                      <TextArea
                        height="60"
                        name="declaration_1"
                        value={declaration_1}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                    <LongTextAreaContainer>
                      <Label>This journey is good for me because:</Label>
                      <TextArea
                        height="60"
                        name="declaration_2"
                        value={declaration_2}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>

                    <LongTextAreaContainer>
                      <Label>I have always wanted to make these changes because:</Label>
                      <TextArea
                        height="60"
                        name="declaration_3"
                        value={declaration_3}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                  </FlexBox>
                </IntroContainer>
              </FlexBox>

              <StepButtons
                style={{ marginTop: '45px' }}
                backStep={() => changeStep(step - 1)}
                nextStep={() => changeStep(step + 1)}
              />
            </>
          )}

          {step == 8 && (
            <>
              <StepSubtitle>Declarations</StepSubtitle>
              <FlexBox justifyContent="center">
                <IntroContainer>
                  <FlexBox justifyContent="space-between" flexDirection="column">
                    <LongTextAreaContainer>
                      <Label>I have to make these changes because:</Label>
                      <TextArea
                        height="60"
                        name="declaration_4"
                        value={declaration_4}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                    <LongTextAreaContainer>
                      <Label>
                        I will rely on the following people for support during my journey (include
                        how you will communicate):
                      </Label>
                      <TextArea
                        height="60"
                        name="declaration_5"
                        value={declaration_5}
                        onChange={handleChange}
                      />
                    </LongTextAreaContainer>
                  </FlexBox>
                </IntroContainer>
              </FlexBox>

              <StepButtons
                style={{ marginTop: '45px' }}
                customNextText="Done"
                backStep={() => changeStep(step - 1)}
                nextStep={createAtPlan}
              />
            </>
          )}
        </StepContainer>
      </ModalDiv>
    );
  }
}

export default PreparingToReturnView;
