import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import Input from 'components/Input';
import Dropzone from 'components/DropZone';
import config from 'config/appConfig';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

const DropzoneContainer = styled.div`
  margin-bottom: 34px;
  width: 100%;
  margin-top: 35px;
  box-shadow: 0px 6px 18px 0 rgba(0,0,0,0.04);
  background-color: #ffffff;
  margin-bottom: 39px;
  height: 255px;
  display: flex;
  justify-content: center;
  position: relative;
}
`;

const ImageProfile = styled.img`
  height: 95%;
  width: auto;
  margin: auto;
`;

const ReplaceImageText = styled.p`
  color: white;
  position: absolute;
  top: 40%;
`;
class AddTitleView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      title,
      step,
      changeTitle,
      changeStep,
      token,
      updateImage,
      replaceImage,
      image,
    } = this.props;
    return (
      <Container>
        <PrimaryText text="What would you like to call this journey?" />
        <Input height={53} placeholder="Title" name="title" value={title} onChange={changeTitle} />

        <DropzoneContainer>
          {!image && (
            <Dropzone
              title="Choose image"
              height={255}
              url={`${config.BASE_URL}/api/v1/upload/trip`}
              authToken={token}
              handleResponse={updateImage}
              folder={'trip'}
            />
          )}
          {image && (
            <>
              <ImageProfile src={image} />
              {<ReplaceImageText onClick={replaceImage}>Click to Replace Image</ReplaceImageText>}
            </>
          )}
        </DropzoneContainer>

        <StepButtons
          customNextText="Finish"
          nextDisabled={!title}
          nextStep={() => changeStep(step + 1)}
          backStep={() => changeStep(step - 1)}
        />
      </Container>
    );
  }
}

export default AddTitleView;
