import React from 'react';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import Modal from 'components/Modal';
import Pagination from 'components/PaginationButtons';
import TripDetailItem from 'components/TripDetailItem';
import Loader from 'components/Loader';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;
const HeaderList = styled.div`
  display: flex;
  margin-right: 38px;
  margin-bottom: 20px;
  justify-content: flex-end;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;
const TBody = styled.div``;

const Trips = props => {
  const {
    currentPage,
    changePage,
    tripsCount,
    elementsPerPage,
    trips,
    toEditTrip,
    alertEditTrip,
    loading,
  } = props;

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader type="pulse" size={125} />
        </LoaderContainer>
      ) : (
        <>
          <GridContainer>
            <HeaderList>
              {' '}
              <Pagination
                currentPage={currentPage}
                changePage={changePage}
                totalPages={Math.ceil(tripsCount / elementsPerPage)}
              />
            </HeaderList>
            <TBody>
              {trips.map((trip, key) => (
                <TripDetailItem
                  key={key}
                  viewButton
                  tripDetails={trip}
                  toEditTrip={() => toEditTrip(trip)}
                />
              ))}
              {(!trips || !trips.length) && <ListEmpty message="There are currently no trips" />}
            </TBody>
          </GridContainer>
        </>
      )}
    </>
  );
};

export default Trips;
