import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  regions: [],
  loading: false,
  region: null,
  regionsForSelect: [],
};

export default {
  regionsReducer: handleActions(
    {
      [ActionTypes.CREATE_REGIONS](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.CREATE_REGIONS_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.CREATE_REGIONS_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_REGIONS](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_REGIONS_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          region: action.region,
        };
      },
      [ActionTypes.GET_REGIONS_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_ALL_REGIONS](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_ALL_REGIONS_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          regions: action.regions,
          regionsCount: action.regionsCount,
        };
      },
      [ActionTypes.GET_ALL_REGIONS_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.EDIT_REGIONS](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.EDIT_REGIONS_SUCCESS](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.EDIT_REGIONS_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_REGIONS_FOR_SELECT](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_REGIONS_FOR_SELECT_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          regionsForSelect: action.regionsForSelect || [],
        };
      },
      [ActionTypes.GET_REGIONS_FOR_SELECT_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.REMOVE_REGIONS](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.REMOVE_REGIONS_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          regions: state.regions.filter(region => region.id !== action.id),
        };
      },
      [ActionTypes.REMOVE_REGIONS_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
    },
    appState,
  ),
};
