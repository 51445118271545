import { put, call } from 'redux-saga/effects';
import * as suggestionActions from 'actions/suggestionActions';
import * as appActions from 'actions/app';

//Countries
export function* getCountriesAsync(api, action) {
  const { page, elementsPerPage, search } = action;
  const { status, data } = yield call(api.getCountries, { page, elementsPerPage, search });
  if (status === 200) {
    yield put(suggestionActions.getCountriesSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* getCountryAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.getCountry, id);

  if (status === 200) {
    yield put(suggestionActions.getCountrySuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

//Visit Suggestions
export function* getVisitAsync(api, action) {
  const { id } = action; //country_id
  const { status, data } = yield call(api.getVisit, id);

  if (status === 200) {
    yield put(suggestionActions.getVisitSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* saveVisitAsync(api, action) {
  const { id, visit_suggestion } = action;
  let { status, data } = yield call(api.saveVisit, { country_id: id, ...visit_suggestion });
  data = data.id ? data : visit_suggestion;

  if (status === 200) {
    yield put(appActions.showAlert('Visit Suggestion saved!', { variant: 'success' }));
    yield put(suggestionActions.saveVisitSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
    yield put(suggestionActions.saveVisitFailure(visit_suggestion));
  }
}

//Average Daily Expenses
export function* getAverageDailyExpensesAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.getAverageDailyExpenses, id);

  if (status === 200) {
    yield put(suggestionActions.getAverageDailyExpensesSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* saveAverageDailyExpensesAsync(api, action) {
  const { id, daily_expenses } = action;
  let { status, data } = yield call(api.saveAverageDailyExpenses, {
    country_id: id,
    ...daily_expenses,
  });
  data = data.id ? data : daily_expenses;

  if (status === 200) {
    yield put(appActions.showAlert('Average Daily Expenses saved!', { variant: 'success' }));
    yield put(suggestionActions.saveAverageDailyExpensesSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
    yield put(suggestionActions.saveAverageDailyExpensesFailure(daily_expenses));
  }
}

//Accommodation Pricings
export function* getAccommodationPricingAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.getAccommodationPricing, id);

  if (status === 200) {
    yield put(suggestionActions.getAccommodationPricingSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* saveAccommodationPricingAsync(api, action) {
  const { id, accommodation_pricing } = action;
  let { status, data } = yield call(api.saveAccommodationPricing, {
    country_id: id,
    ...accommodation_pricing,
  });
  data = data.id ? data : accommodation_pricing;

  if (status === 200) {
    yield put(appActions.showAlert('Accommodation Pricing saved!', { variant: 'success' }));
    yield put(suggestionActions.saveAccommodationPricingSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
    yield put(suggestionActions.saveAccommodationPricingFailure(accommodation_pricing));
  }
}

//Climate
export function* getClimatesAsync(api, action) {
  const { params } = action;
  const { status, data } = yield call(api.getClimate, params);
  if (status === 200) {
    yield put(suggestionActions.getClimateSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* saveClimateAsync(api, action) {
  const { id, climate } = action;
  let { status, data } = yield call(api.saveClimate, { country_id: id, ...climate });
  data = data.id ? data : climate;

  if (status === 200) {
    yield put(appActions.showAlert('Climate saved!', { variant: 'success' }));
    yield put(suggestionActions.saveClimateSuccess());
    yield put(
      suggestionActions.getClimateRequest({
        currentPage: 1,
        elementsPerPage: 10,
        country_id: id,
        search: '',
      }),
    );
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
    yield put(suggestionActions.saveClimateFailure(climate));
  }
}

export function* updateClimate(api, action) {
  const { id, climate } = action;
  const response = yield call(api.editClimate, id, climate);

  if (response.status === 200) {
    yield put(appActions.showAlert('Climate updated!', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* removeClimateAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.deleteClimate, id);

  if (status === 204) {
    yield put(appActions.showAlert('Climate deleted.', { variant: 'success' }));
    yield put(suggestionActions.removeClimateSuccess(id));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

// Lifecycle
export function* getLifecyclesAsync(api, action) {
  const { params } = action;
  const { status, data } = yield call(api.getLifecycles, params);

  if (status === 200) {
    yield put(suggestionActions.getLifecyclesSuccess(data));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(suggestionActions.getLifecyclesFailure());
  }
}

export function* getLifecycleAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.getLifecycle, id);

  if (status === 200) {
    yield put(suggestionActions.getLifecycleSuccess(data));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(suggestionActions.getLifecycleFailure());
  }
}

export function* removeLifecycleAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.deleteLifecycle, id);

  if (status === 204) {
    yield put(appActions.showAlert('Lifecycle deleted.', { variant: 'success' }));
    yield put(suggestionActions.removeLifecycleSuccess(id));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(suggestionActions.removeLifecycleFailure());
  }
}

export function* saveLifecycleAsync(api, action) {
  const { id, payload } = action;
  const isEditing = !!id;
  const { status, data } = isEditing
    ? yield call(api.editLifecycle, id, payload)
    : yield call(api.createLifecycle, payload);

  if (status === 200) {
    yield put(
      appActions.showAlert(`Lifecycle ${isEditing ? 'updated' : 'created'}.`, {
        variant: 'success',
      }),
    );

    yield put(suggestionActions.saveLifecycleSuccess());
    // reload list
    const { country_id, type } = payload;
    yield put(suggestionActions.getLifecyclesRequest({ country_id, by: type }));
    // hide form
    yield put(suggestionActions.showLifecycleForm(false));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(suggestionActions.saveLifecycleFailure());
  }
}

// Cuisine Speciality
export function* getCuisineSpecialitiesAsync(api, action) {
  const { params } = action;
  const { status, data } = yield call(api.getCuisineSpecialities, params);

  if (status === 200) {
    yield put(suggestionActions.getCuisineSpecialitiesSuccess(data));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(suggestionActions.getCuisineSpecialitiesFailure());
  }
}

export function* getCuisineSpecialityAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.getCuisineSpeciality, id);

  if (status === 200) {
    yield put(suggestionActions.getCuisineSpecialitySuccess(data));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(suggestionActions.getCuisineSpecialityFailure());
  }
}

export function* removeCuisineSpecialityAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.deleteCuisineSpeciality, id);

  if (status === 204) {
    yield put(appActions.showAlert('Cuisine speciality deleted.', { variant: 'success' }));
    yield put(suggestionActions.removeCuisineSpecialitySuccess(id));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(suggestionActions.removeCuisineSpecialityFailure());
  }
}

export function* saveCuisineSpecialityAsync(api, action) {
  const { id, payload, params } = action;
  const isEditing = !!id;

  const { status, data } = isEditing
    ? yield call(api.editCuisineSpeciality, id, payload)
    : yield call(api.createCuisineSpeciality, payload);

  if (status === 200) {
    yield put(
      appActions.showAlert(`Cuisine speciality ${isEditing ? 'updated' : 'created'}.`, {
        variant: 'success',
      }),
    );

    yield put(suggestionActions.saveCuisineSpecialitySuccess());
    // reload list
    const { country_id, type } = payload;
    yield put(
      suggestionActions.getCuisineSpecialitiesRequest(api.getCuisineSpecialities, {
        country_id,
        currentPage: 1,
        elementsPerPage: 10,
        search: '',
      }),
    );
    // hide form
    yield put(suggestionActions.showCuisineSpecialityForm(false));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(suggestionActions.saveCuisineSpecialityFailure());
  }
}

export function* getCountriesForSelect(api) {
  const response = yield call(api.getCountriesForSelect);
  if (response.status == 200) {
    yield put(suggestionActions.getCountriesForSelectSuccess(response.data));
  } else {
    yield put(suggestionActions.getCountriesForSelectFailure());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
