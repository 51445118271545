import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import { OUTDOORACT } from 'constants/index';
import RadioInput from 'components/RadioInput';

const SelectOutdoorActivitiesBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SelectOutdoorActivitiesPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`;

class SelectOutdoorActivitiesView extends Component {
  render() {
    const { step, state, changeStep, handleChange, outdoorActivitiesMaping } = this.props;
    return (
      <SelectOutdoorActivitiesBox>
        <SelectOutdoorActivitiesPrimaryBox>
          <PrimaryText text="What types of outdoor activities interest you? Please select all that apply and rate your interest level low, medium, or high." />
          <Content>
            {OUTDOORACT.map(outdoorActivities => (
              <RadioInput
                style={{ marginBottom: 50 }}
                label={outdoorActivities}
                name={outdoorActivitiesMaping[outdoorActivities]}
                options={[
                  { numericValue: 0, text: 'Nothing' },
                  { numericValue: 1, text: 'Low' },
                  { numericValue: 2, text: 'Medium' },
                  { numericValue: 3, text: 'High' },
                ]}
                handleChange={handleChange}
                currentValue={state[outdoorActivitiesMaping[outdoorActivities]]}
                verticalAlign
              />
            ))}
          </Content>
          <StepButtons
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(step + 1)}
          />
        </SelectOutdoorActivitiesPrimaryBox>
      </SelectOutdoorActivitiesBox>
    );
  }
}

export default SelectOutdoorActivitiesView;
