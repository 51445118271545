import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'modules/history';
import styled from 'styled-components';
import moment from 'moment';
import defaultAvatar from 'assets/media/images/avatar.png';
import {
  GENERALPREFERENCES,
  ACCOMMODATION,
  FOODIEDESTINATION,
  EATERIES,
  ISVEGAN,
} from 'constants/index';

const Container = styled.div`
  margin: 38px;
  margin-top: 0px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const TripDetailsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 36px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  padding: 0px 20px 0px 20px;
  padding: 20px 10px;
  flex-direction: row;
`;

const SectionDetails = styled.div`
  display: flex;
  width: ${props => (props.widthAuto ? 'auto' : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
`;

const Avatar = styled.img`
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 15px;
  object-fit: cover;
`;
const Primary = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
  overflow-wrap: break-word;
`;
const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 10px;
`;
const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: ${props => props.backgroundColor || '#38b6ff'};
`;

const SubItem = styled.div`
  position: relative;
  width: ${props => props.width}%;
  height: 100%;
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: left;
  ${props => props.hasPadding && 'padding: 25px 5px 25px 5px;'};
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 1px;
  background-color: #ebebeb;
  margin: auto;
`;

const CitiesBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CitiesSelected = styled.div`
  display: flex;
  align-items: start;
  margin-right: 15px;
`;

class TransactionList extends Component {
  render() {
    const { tripDetails, toEditTrip, viewButton } = this.props;
    if (!tripDetails) return null;
    const { user } = tripDetails;
    return (
      <Container>
        <TripDetailsContainer>
          {user && (
            <SubItem width={15}>
              <SectionDetails hasPadding>
                <SectionDetails>
                  <Avatar src={user.avatar || defaultAvatar} />
                  <SubItem width={100}>
                    <Primary>
                      {user.first_name} {user.last_name}
                    </Primary>
                    <Secondary>
                      {user.country}, {user.city}
                    </Secondary>
                  </SubItem>
                </SectionDetails>

                <VerticalLine />
              </SectionDetails>
            </SubItem>
          )}
          <SubItem width={user ? 85 : 100}>
            <SectionDetails hasPadding>
              <SubItem width={16.5}>
                <Secondary>Arriving Time</Secondary>
                {tripDetails.know_dates == 1 ? (
                  <SectionDetails alignItems="baseline">
                    {moment(tripDetails.start_date).format('MM-DD-YYYY')}

                    <Primary>&nbsp; In the {tripDetails.arriving_time}</Primary>
                  </SectionDetails>
                ) : (
                  'No selected'
                )}
                <VerticalLine />
              </SubItem>
              <SubItem width={16.5}>
                <Secondary>Deapture Time</Secondary>

                {tripDetails.know_dates == 1 ? (
                  <SectionDetails alignItems="baseline">
                    {moment(tripDetails.end_date).format('MM-DD-YYYY')}
                    <Primary> &nbsp; In the{tripDetails.departure_time}</Primary>
                  </SectionDetails>
                ) : (
                  'No selected'
                )}
                <VerticalLine />
              </SubItem>

              {tripDetails.like_to_go && (
                <SubItem width={33}>
                  <Secondary>Would you like to visit: </Secondary>
                  <Primary>{tripDetails.cities_selected.map(city => city.name)}</Primary>
                  <VerticalLine />
                </SubItem>
              )}

              {tripDetails.traveling_with && tripDetails.traveling_with.length > 0 && (
                <SubItem width={33}>
                  <Secondary>Travelinh with: </Secondary>
                  <Primary>{tripDetails.traveling_with.map(tWith => `${tWith} / `)}</Primary>
                  <VerticalLine />
                </SubItem>
              )}
            </SectionDetails>
            <SectionDetails hasPadding>
              <SubItem width={33}>
                <Secondary>General Preference</Secondary>
                <Primary>{GENERALPREFERENCES[tripDetails.general_preference - 1]}</Primary>
                <VerticalLine />
              </SubItem>

              <SubItem width={33}>
                <Secondary>Travel with Others</Secondary>
                <Primary>{tripDetails.travel_with_others ? 'Yes' : 'No'}</Primary>
              </SubItem>

              {tripDetails.know_how_many && (
                <SubItem width={33}>
                  <VerticalLine />
                  <Secondary>How Many</Secondary>
                  <Primary>{tripDetails.how_many}</Primary>
                  <VerticalLine />
                </SubItem>
              )}
            </SectionDetails>
            <SectionDetails hasPadding>
              {tripDetails.days_quantity && (
                <SubItem width={33}>
                  <Secondary>Days Quantity</Secondary>
                  <Primary>{tripDetails.days_quantity}</Primary>
                  <VerticalLine />
                </SubItem>
              )}

              {tripDetails.month_ranges.length > 0 && (
                <SubItem width={33}>
                  <Secondary>Month Ranges</Secondary>
                  <Primary>{tripDetails.month_ranges.map(tWith => `${tWith} / `)}</Primary>
                  <VerticalLine />
                </SubItem>
              )}
            </SectionDetails>

            <SectionDetails hasPadding>
              <SubItem width={33}>
                <Secondary>Accommodation Preference</Secondary>
                <Primary>{ACCOMMODATION[tripDetails.accommodation - 1]}</Primary>
                <VerticalLine />
              </SubItem>

              <SubItem width={33}>
                <Secondary>Fooddie Preference</Secondary>
                <Primary>{FOODIEDESTINATION[tripDetails.foodie_destination - 1]}</Primary>
                <VerticalLine />
              </SubItem>

              <SubItem width={33}>
                <Secondary>Eateries Preference</Secondary>
                <Primary>{EATERIES[tripDetails.eateries - 1]}</Primary>
                <VerticalLine />
              </SubItem>
            </SectionDetails>

            <SectionDetails hasPadding>
              <SubItem width={33}>
                <Secondary>Vegan</Secondary>
                <Primary>{ISVEGAN[tripDetails.is_vegan - 1]}</Primary>
                <VerticalLine />
              </SubItem>

              <SubItem width={33}>
                <Secondary>Rental Car</Secondary>
                <Primary>{tripDetails.rental_car ? 'Yes' : 'No'}</Primary>
                <VerticalLine />
              </SubItem>

              <SubItem width={33}>
                <Secondary>Humidity Levels</Secondary>
                <Primary>{tripDetails.humidity_levels.map(hume => `${hume} /`)}</Primary>
                <VerticalLine />
              </SubItem>
            </SectionDetails>

            <SectionDetails hasPadding>
              <SubItem width={33}>
                <Secondary>Raining Level</Secondary>
                <Primary>{tripDetails.raining_level}</Primary>
                <VerticalLine />
              </SubItem>

              <SubItem width={33}>
                <Secondary>Landscapes</Secondary>
                <Primary>{tripDetails.landscapes.map(land => `${land} /`)}</Primary>
                <VerticalLine />
              </SubItem>

              <SubItem width={33}>
                <Secondary>Temperature Ranges</Secondary>
                <Primary>{tripDetails.temperature_ranges.map(rang => `${rang} /`)}</Primary>
                <VerticalLine />
              </SubItem>
            </SectionDetails>

            <SectionDetails hasPadding>
              <SubItem width={33}>
                <Secondary>Itinerary Cities</Secondary>
                <CitiesBox>
                  {tripDetails.stops &&
                    tripDetails.stops.map(({ city }, index) => (
                      <Primary>
                        {index !== tripDetails.stops.length - 1
                          ? `${city.name}, `
                          : `${city.name}.`}
                      </Primary>
                    ))}
                </CitiesBox>
                <VerticalLine />
              </SubItem>

              <SubItem width={33}>
                <Secondary>Status</Secondary>
                <CitiesBox>
                  <CitiesSelected widthAuto>
                    <Primary>
                      {tripDetails.status == 'pending' &&
                        'This trip does not have an assigned itinerary.'}
                      {tripDetails.status == 'with_itinerary' && ' Waiting for user approval.'}
                      {tripDetails.status == 'user_approve' && ' User approved.'}
                      {tripDetails.status == 'approved' && ' Approved.'}
                      {tripDetails.status == 'finished' && ' Finished.'}
                      {tripDetails.status == 'generating_itinerary' && ' Generating itinerary.'}
                      {tripDetails.status == 'error_generating_itinerary' &&
                        ' Error generating itinerary.'}
                    </Primary>
                  </CitiesSelected>
                </CitiesBox>
              </SubItem>
            </SectionDetails>

            <SectionDetails style={{ justifyContent: 'flex-end' }}>
              {viewButton && <Button onClick={() => push(`/trips/${tripDetails.id}`)}>View</Button>}
              <Button onClick={toEditTrip}>Edit Trip</Button>
            </SectionDetails>
          </SubItem>
        </TripDetailsContainer>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  null,
  mapDispatchToProps,
)(TransactionList);
