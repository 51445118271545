import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import completed from 'assets/media/icons/ok.png';
import pending from 'assets/media/icons/pending.png';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 20px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
`;

const LinkText = styled.p`
  font-size: 14px;
  cursor: pointer;
  color: #38b6ff;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const TBody = styled.div``;

const ActionIcon = styled.i`
  color: #38b6ff;
  font-size: 20px;
  padding-right: 10px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

class SuggestionsList extends Component {
  getStatusIcon = status => {
    const icons = {
      completed,
      incompleted: pending,
    };

    return icons[status];
  };

  renderSuggestion(
    {
      id,
      name,
      iso,
      capital,
      region,
      subregion,
      phone_code,
      timezone,
      latitude_capital,
      longitude_capital,
      flag,
    },
    key,
    setCountryId,
  ) {
    return (
      <Item key={key}>
        <SectionContainer width={'10%'}>
          <Primary className="without-margin">
            <img className="flagImage" src={flag} />
          </Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'25%'}>
          <Link onClick={() => setCountryId(id)}>
            <LinkText>{name}</LinkText>
          </Link>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{capital}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{subregion}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{timezone}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'5%'} textAlign="right">
          <ActionIcon className="fas fa-eye" onClick={() => setCountryId(id)} />
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const { countries, loading, setCountryId } = this.props;

    return (
      <>
        {loading ? (
          <LoaderContainer>
            <Loader type="pulse" size={125} />
          </LoaderContainer>
        ) : (
          <GridContainer>
            <THeader>
              <TitleContainer width={'10%'}>
                <Title>FLAG</Title>
              </TitleContainer>
              <TitleContainer width={'25%'}>
                <Title>COUNTRY</Title>
              </TitleContainer>
              <TitleContainer width={'20%'}>
                <Title>CAPITAL</Title>
              </TitleContainer>
              <TitleContainer width={'20%'}>
                <Title>REGION</Title>
              </TitleContainer>
              <TitleContainer width={'20%'}>
                <Title>TIMEZONE</Title>
              </TitleContainer>
            </THeader>
            <TBody>
              {countries.map((country, key) => {
                return this.renderSuggestion(country, key, setCountryId);
              })}
              {(!countries || !countries.length) && (
                <ListEmpty message="There are currently no countries" />
              )}
            </TBody>
          </GridContainer>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(null, mapDispatchToProps)(SuggestionsList);
