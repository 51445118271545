import React from 'react';
import styled from 'styled-components';

const Pagination = styled.div`
  display: inline-block;
`;

const Page = styled.a`
  color: ${props => (props.active ? 'white' : '#38b6ff')};
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: ${props => (props.active ? '#38b6ff' : '#00b4d51b')};
  height: 30px;
  width: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
`;

export default props => {
  const { currentPage, changePage, totalPages } = props;
  const nextPage = currentPage + 1;
  const backPage = currentPage - 1;

  return (
    <div>
      {totalPages > 1 && (
        <Pagination>
          {currentPage > 1 && (
            <>
              <Page onClick={() => changePage(backPage)}>&#x25C4;</Page>
              <Page onClick={() => changePage(backPage)}>{backPage}</Page>
            </>
          )}
          <Page active>{currentPage}</Page>
          {nextPage <= totalPages && (
            <>
              <Page onClick={() => changePage(nextPage)}>{nextPage}</Page>
              <Page onClick={() => changePage(nextPage)}> &#x25BA;</Page>
            </>
          )}
        </Pagination>
      )}
    </div>
  );
};
