import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LoginButton = styled.button`
  border-radius: 5px;
  min-width: 50%;
  color: white;
  background-color: ${props => (!props.disabled ? '#38b6ff' : '#c9e2ef')};
  cursor: pointer;
`;

const LoginButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LinkText = styled.p`
  color: #38b6ff;
  cursor: pointer;
`;

export default props => (
  <LoginButtons>
    <LoginButton onClick={props.sendEmailLogin} disabled={!props.isValid}>
      LOGIN
    </LoginButton>
    <Link to="/recover-password">
      <LinkText>I forgot my password</LinkText>
    </Link>
  </LoginButtons>
);
