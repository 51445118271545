import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  products: [],
};

export default {
  productReducer: handleActions(
    {
      [ActionTypes.SAVE_AVAILABLE_PRODUCTS](state, action) {
        return {
          ...state,
          products: action.products,
          productsCount: action.productsCount,
        };
      },
    },
    appState,
  ),
};
