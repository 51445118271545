import React from 'react';
import styled from 'styled-components';
import PdfView from 'components/PdfView';

const Text = styled.p`
  margin: 12px;
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.weight};
  color: ${props => (props.color ? props.color : 'black')};
`;

const TripDetailsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  padding: 0px 20px 0px 20px;
  padding: 20px 10px;
  flex-direction: row;
  border-radius: 15px;
  margin-bottom: 20px;
`;

const PreTripCourseImage = styled.img`
  width: 50%;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 15px;
`;

const FlexBox = styled.div`
  display: flex;
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'initial')};
  width: ${props => props.width}%;
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  padding: 15px;
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: #38b6ff;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const PreTripCoursePreviewView = props => {
  const {
    preTripCourse,
    buyPreTripCourse,
    setDivSize,
    onDocumentLoadSuccess,
    changePage,
    numPages,
    pageNumber,
    width,
    buyoption,
  } = props;
  if (!preTripCourse) return null;

  return (
    <FlexBox width={100} alignItems={'start'} flexDirection={'column'}>
      <Header>
        <Button onClick={() => props.history.goBack()}>Back</Button>

        {buyoption && <Button onClick={() => buyPreTripCourse(preTripCourse)}>Obtain</Button>}
      </Header>
      <TripDetailsContainer>
        <PdfView
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          width={width}
          pageNumber={pageNumber}
          numPages={numPages}
          changePage={changePage}
          file={preTripCourse.file}
        />
      </TripDetailsContainer>
    </FlexBox>
  );
};

export default PreTripCoursePreviewView;
