import React, { Component } from 'react';
import ExplanacionConceptsView from './ExplanationConceptsView';
import { connect } from 'react-redux';
import * as diagnosticActions from '../../../actions/diagnosticActions';
import { selectFields } from './StepFields/GeneralConcepts';

class ExplanacionConcepts extends Component {
  constructor(props) {
    super(props);
    this.state = { localStep: 1 };
  }

  changeStep = step => {
    if (1 <= step && step < 3) {
      this.setState({ localStep: step });
    } else {
      const { changeCurrentStep, updateDiagnosticStepUser } = this.props;
      const globalStep = 2; // 2 = well being
      changeCurrentStep(globalStep);
      updateDiagnosticStepUser({ diagnostic_step: globalStep });
    }
  };

  render() {
    const stepFields = selectFields(this.state.localStep);
    return (
      <ExplanacionConceptsView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        stepFields={stepFields}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(diagnosticActions.changeCurrentStep(step)),
    updateDiagnosticStepUser: step => dispatch(diagnosticActions.updateDiagnosticStepUser(step)),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(ExplanacionConcepts);
