import React, { Component } from 'react';
import styled from 'styled-components';
import Input from 'components/Input';
import Button from 'components/Button';

import { Formik } from 'formik';
import * as Yup from 'yup';

const Select = styled.select`
  background: white;
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  height: 44px;
  margin-top: 15px;
  padding: 10px;
  width: 100%;
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const Container = styled.div`
  padding: 30px;
`;

export default class CitiesForm extends Component {
  render() {
    const { city, regionsForSelect, submit } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={city}
        isInitialValid={false}
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          region_id: Yup.string().required('Required'),
        })}
      >
        {({ values, handleChange, handleBlur, isValid }) => {
          return (
            <Container>
              <Input
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <Select name="region_id" value={values.region_id} onChange={handleChange}>
                <option disabled selected value>
                  Select Region
                </option>
                {regionsForSelect.map(region => (
                  <option value={`${region.id}`}>{region.name}</option>
                ))}
              </Select>
              <Button style={{ marginTop: 15 }} disabled={!isValid} onClick={() => submit(values)}>
                Save
              </Button>
            </Container>
          );
        }}
      </Formik>
    );
  }
}
