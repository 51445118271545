import { put, call, select } from 'redux-saga/effects';
import * as itineraryActions from '../actions/itineraryActions';
import * as appActions from '../actions/app';
import { replace } from 'modules/history';
export function* getActivitiesByCity(api, action) {
  const response = yield call(api.getActivitiesByCity, action.city_id);
  if (response.status == 200) {
    yield put(itineraryActions.saveActivitiesByCity(response.data));
  }
}

export function* getRestaurantsByCity(api, action) {
  const response = yield call(api.getRestaurantsByCity, action.city_id);
  if (response.status == 200) {
    yield put(itineraryActions.saveRestaurantsByCity(response.data));
  }
}

export function* getAccommodationsByCity(api, action) {
  const response = yield call(api.getAccommodationsByCity, action.city_id);
  if (response.status == 200) {
    yield put(itineraryActions.saveAccommodationsByCity(response.data));
  }
}

export function* saveItinerary(api, action) {
  const state = yield select();
  const { daysWithActivities, stops, itinerary } = state.itineraryReducer;
  const response = yield call(api.saveItinerary, {
    itinerary,
    daysWithActivities,
    stops,
  });
  if (response.status == 200) {
    yield put(appActions.showAlert('Itinerary Created!', { variant: 'success' }));
    yield replace(`/trips/${itinerary.trip_id}`);
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* generateItineraryRequest(api, action) {
  const state = yield select();
  const { fisrt_activity_time, lunch_time, back_to_hotel_time } = action;

  const {
    itinerary,
    stopSelected: { city: city_selected },
    daySelectedId: dayId,
    stopSelectedId: stopId,
    daysWithActivities,
  } = state.itineraryReducer;

  let activitiesIds = [];

  daysWithActivities.forEach(dwa => {
    activitiesIds.push(
      ...dwa.activities.reduce((acum, act) => {
        if (act.type == 'activity') {
          acum.push(act.id);
        }
        return acum;
      }, []),
    );
  });

  const response = yield call(api.generateItineraryRequest, {
    itinerary,
    city_selected,
    fisrt_activity_time,
    lunch_time,
    back_to_hotel_time,
    dayId,
    stopId,
    activitiesIds,
  });
  if (response.status == 200) {
    yield put(itineraryActions.generateItinerarySuccess(response.data));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getItinerary(api, action) {
  const response = yield call(api.getItinerary, action.id);
  if (response.status == 200) {
    if (action.initialize) {
      //The itinerary initialization is used for the case to be edited.
      yield put(itineraryActions.initializeItinerary(response.data));
    } else {
      yield put(itineraryActions.getItinerarySuccess(response.data));
    }
  }
}

export function* markItineraryAsDefault(api, action) {
  const response = yield call(api.markItineraryAsDefault, action.data);
  if (response.status == 200) {
    yield put(itineraryActions.markItineraryAsDefaultSuccess(response.data));
  } else {
    yield put(itineraryActions.markItineraryAsDefaultFailure(response.data));
  }
}
