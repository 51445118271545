import React, { Component } from 'react';
import styled from 'styled-components';
import Pagination from 'components/PaginationButtons';
import ListEmpty from 'components/ListEmpty';
import Button from 'components/Button';
import Loader from 'components/Loader';
import ToggleSwitch from 'components/ToggleSwitch';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Modal from 'components/Modal';
import ModalExcel from 'components/Suggestions/ModalExcel';

const Container = styled.div``;

const HeaderContainer = styled.div`
  margin: 20px;
  padding-bottom: 5px;
  position: relative;
  width: auto;
  position: relative;
  height: 50px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.widthSize &&
    `
    width: ${props.widthSize}%;
  `}
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 900px) {
    width: 100% !important;
  }
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  line-height: 0;
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

const Span = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  letter-spacing: normal;
  text-align: start;
  color: #999999;
  padding-top: 10px;
`;

const Select = styled.select`
  height: 52px;
  border-radius: 5px;
  background-color: #ffffff;
  border: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
`;

const ActionIcon = styled.i`
  color: ${props => props.color};
  font-size: 20px;
  padding-right: 10px;
`;

const GridContainer = styled.div`
  margin: 20px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TBody = styled.div``;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;
const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const InputActivity = styled.input`
  width: 100%;
  height: 50px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 5)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent || 'space-between'};
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const LinkText = styled.p`
  padding-top: 5px;
  font-size: 18px;
  cursor: pointer;
  color: #38b6ff;
`;

const elementsPerPage = 10;

class CuisineSpeciality extends Component {
  state = {
    delayTyping: null,
    search: '',
    by: 'Country',
    isOpen: false,
    idSelected: null,
  };

  submit = values => {
    const {
      item: { id },
    } = this.props;
    const { save } = this.props;

    save(id, values);
  };

  renderForm = () => {
    const {
      item,
      switchFormShow,
      countryId,
      viewMode,
      regionsForSelect,
      citiesForSelect,
      getCitiesForSelect,
    } = this.props;
    const isEditing = item && item.id;

    return (
      <GridContainer>
        <FlexRow style={{ justifyContent: 'space-between', marginBottom: 20 }}>
          <Label>{`${isEditing ? 'Edit' : 'Add new'} cuisine speciality`}</Label>
          <Button onClick={() => switchFormShow(false)} style={{ marginTop: 0 }}>
            Back
          </Button>
        </FlexRow>
        <Formik
          enableReinitialize
          initialValues={{ country_id: countryId, ...item }}
          onSubmit={this.submit}
          isInitialValid={isEditing}
          validationSchema={Yup.object().shape({
            type: Yup.string(),
            high_quality_cuisine: Yup.string().required('Required'),
            beer: Yup.string().required('Required'),
            cheese_and_dairy: Yup.string().required('Required'),
            coffee_and_tea: Yup.string().required('Required'),
            desserts: Yup.string().required('Required'),
            fruit_and_vegetables: Yup.string().required('Required'),
            health_food: Yup.string().required('Required'),
            liquor: Yup.string().required('Required'),
            pasta_and_noodles: Yup.string().required('Required'),
            red_meat: Yup.string().required('Required'),
            seafood: Yup.string().required('Required'),
            street_food: Yup.string().required('Required'),
            vegan_dining: Yup.string().required('Required'),
            vegetarian_dining: Yup.string().required('Required'),
            wine: Yup.string().required('Required'),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
            isValid,
            handleSubmit,
          }) => {
            return (
              <GridContainer>
                <FlexRow justifyContent="flex-start">
                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Type</Label>
                    <Select
                      placeholder="Type"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      disabled={viewMode}
                    >
                      <option value={'Country'}>Country</option>
                      <option value={'Region'}>Region</option>
                      <option value={'City/Area'}>City/Area</option>
                    </Select>
                  </FlexColumn>

                  {values.type !== 'Country' && (
                    <FlexColumn widthSize={33} marginBottom={40}>
                      <Label color="#38b6ff">Region</Label>

                      <Select
                        name="region"
                        value={values.region}
                        onChange={e => {
                          handleChange(e);
                          getCitiesForSelect({ region_id: e.target.value });
                          setFieldValue('city', '');
                        }}
                        onBlur={handleBlur}
                        disabled={viewMode}
                      >
                        <option className="selectPlaceholder" value="" disabled selected hidden>
                          Select Region
                        </option>
                        {regionsForSelect.map(region => (
                          <option value={`${region.id}`}>{region.name}</option>
                        ))}
                      </Select>
                    </FlexColumn>
                  )}

                  {values.type !== 'Country' && values.type === 'City/Area' && (
                    <FlexColumn widthSize={33} marginBottom={40}>
                      <Label color="#38b6ff">City/Area</Label>
                      <Select
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.city && touched.city}
                        disabled={viewMode}
                      >
                        <option className="selectPlaceholder" value="" disabled selected hidden>
                          Select City
                        </option>
                        {citiesForSelect.map(city => (
                          <option value={`${city.id}`}>{city.name}</option>
                        ))}
                      </Select>
                    </FlexColumn>
                  )}
                </FlexRow>

                <FlexRow>
                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">High-Quality Cuisine?</Label>
                    <ToggleSwitch
                      size="sm"
                      name="high_quality_cuisine"
                      value={values.high_quality_cuisine}
                      checked={values.high_quality_cuisine === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Beer</Label>
                    <ToggleSwitch
                      size="sm"
                      name="beer"
                      value={values.beer}
                      checked={values.beer === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Cheese & Dairy</Label>
                    <ToggleSwitch
                      size="sm"
                      name="cheese_and_dairy"
                      value={values.cheese_and_dairy}
                      checked={values.cheese_and_dairy === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Coffee & Tea</Label>
                    <ToggleSwitch
                      size="sm"
                      name="coffee_and_tea"
                      value={values.coffee_and_tea}
                      checked={values.coffee_and_tea === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Desserts</Label>
                    <ToggleSwitch
                      size="sm"
                      name="desserts"
                      value={values.desserts}
                      checked={values.desserts === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Fruit & Vegetables</Label>
                    <ToggleSwitch
                      size="sm"
                      name="fruit_and_vegetables"
                      value={values.fruit_and_vegetables}
                      checked={values.fruit_and_vegetables === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Health Food</Label>
                    <ToggleSwitch
                      size="sm"
                      name="health_food"
                      value={values.health_food}
                      checked={values.health_food === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Liquor</Label>
                    <ToggleSwitch
                      size="sm"
                      name="liquor"
                      value={values.liquor}
                      checked={values.liquor === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Pasta & Noodles</Label>
                    <ToggleSwitch
                      size="sm"
                      name="pasta_and_noodles"
                      value={values.pasta_and_noodles}
                      checked={values.pasta_and_noodles === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Red Meat</Label>
                    <ToggleSwitch
                      size="sm"
                      name="red_meat"
                      value={values.red_meat}
                      checked={values.red_meat === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Seafood</Label>
                    <ToggleSwitch
                      size="sm"
                      name="seafood"
                      value={values.seafood}
                      checked={values.seafood === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Street Food</Label>
                    <ToggleSwitch
                      size="sm"
                      name="street_food"
                      value={values.street_food}
                      checked={values.street_food === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Vegan Dining</Label>
                    <ToggleSwitch
                      size="sm"
                      name="vegan_dining"
                      value={values.vegan_dining}
                      checked={values.vegan_dining === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Vegetarian Dining</Label>
                    <ToggleSwitch
                      size="sm"
                      name="vegetarian_dining"
                      value={values.vegetarian_dining}
                      checked={values.vegetarian_dining === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>

                  <FlexColumn widthSize={33} marginBottom={40}>
                    <Label color="#38b6ff">Wine</Label>
                    <ToggleSwitch
                      size="sm"
                      name="wine"
                      value={values.wine}
                      checked={values.wine === 'True'}
                      onChange={({ target }) => {
                        const { name, checked } = target;
                        setFieldValue(name, checked ? 'True' : 'False');
                      }}
                    />
                  </FlexColumn>
                </FlexRow>

                {!viewMode && (
                  <Button disabled={!isValid} onClick={handleSubmit}>
                    Save
                  </Button>
                )}
              </GridContainer>
            );
          }}
        </Formik>
      </GridContainer>
    );
  };

  filter = event => {
    const { name, value } = event.target;
    const { find } = this.props;

    clearTimeout(this.state.delayTyping);

    const delayTyping = setTimeout(() => {
      const { countryId, search, by } = this.props;
      find({ country_id: countryId, search, by, [name]: value });
    }, 1000);

    this.setState({
      [name]: value,
      delayTyping,
    });
  };

  changePage = page => {
    const { search, by } = this.props;
    const { find, countryId } = this.props;

    find({ country_id: countryId, page, search, by });
  };

  toggleModal = (isOpen, idSelected) => {
    this.setState({ isOpen, idSelected });
  };

  onRemove = () => {
    const { idSelected } = this.state;
    const { remove } = this.props;

    remove(idSelected);

    this.toggleModal(false, null);
  };

  renderItem = (
    {
      id,
      city,
      region,
      high_quality_cuisine,
      beer,
      cheese_and_dairy,
      coffee_and_tea,
      desserts,
      fruit_and_vegetables,
      health_food,
      liquor,
      pasta_and_noodles,
      red_meat,
      seafood,
      street_food,
      vegan_dining,
      vegetarian_dining,
      wine,
    },
    key,
    viewMode,
  ) => {
    const { by } = this.state;
    const { findOne, switchFormShow } = this.props;

    return (
      <Item key={key}>
        {by === 'City/Area' && (
          <SectionContainer width={'20%'}>
            <Primary>{city}</Primary>
            <VerticalLine />
          </SectionContainer>
        )}

        {by !== 'Country' && (
          <SectionContainer width={'20%'}>
            <Primary>{region}</Primary>
            <VerticalLine />
          </SectionContainer>
        )}

        <SectionContainer width={'20%'}>
          <Primary>{high_quality_cuisine}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{beer}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{cheese_and_dairy}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{coffee_and_tea}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{desserts}</Primary>
          <VerticalLine />
        </SectionContainer>

        <SectionContainer width={'20%'}>
          <Primary>{fruit_and_vegetables}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{health_food}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{liquor}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{pasta_and_noodles}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{red_meat}</Primary>
          <VerticalLine />
        </SectionContainer>

        <SectionContainer width={'20%'}>
          <Primary>{seafood}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{street_food}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{vegan_dining}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{vegetarian_dining}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{wine}</Primary>
          <VerticalLine />
        </SectionContainer>

        <SectionContainer width={'15%'} textAlign="right">
          {!viewMode ? (
            <>
              <ActionIcon
                onClick={() => {
                  findOne(id);
                  switchFormShow(true);
                }}
                color="#38b6ff"
                className="fas fa-edit"
              />
              <ActionIcon
                onClick={() => {
                  this.toggleModal(true, id);
                }}
                color="#ff0000ad"
                className="fas fa-trash-alt"
              />
            </>
          ) : (
            <ActionIcon
              onClick={() => {
                findOne(id);
                switchFormShow(true);
              }}
              color="#38b6ff"
              className="fas fa-eye"
            />
          )}
        </SectionContainer>
      </Item>
    );
  };

  render() {
    const { search, by, isOpen } = this.state;
    const {
      handleModalExcel,
      renderModalExcel,
      country,
      token,
      switchFormShow,
      list,
      count,
      currentPage,
      loading,
      showForm,
      cuisineSearchableText,
      searchCuisine,
      find,
      viewMode,
    } = this.props;

    if (loading) {
      return (
        <LoaderContainer>
          <Loader type="pulse" size={125} />
        </LoaderContainer>
      );
    }

    return (
      <Container>
        {!showForm && (
          <Container>
            <HeaderContainer style={{ marginBottom: 20 }}>
              <FlexRow>
                <FlexColumn width={30}>
                  <InputActivity
                    type="text"
                    placeholder="Search"
                    name="cuisineSearchableText"
                    value={cuisineSearchableText}
                    onChange={searchCuisine}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Select placeholder="By" name="by" value={by} onChange={this.filter}>
                    <option value={'Country'}>Country</option>
                    <option value={'Region'}>Region</option>
                    <option value={'City/Area'}>City/Area</option>
                  </Select>
                </FlexColumn>
                <FlexColumn>
                  <Pagination
                    currentPage={currentPage}
                    changePage={this.changePage}
                    totalPages={Math.ceil(count / elementsPerPage)}
                  />
                </FlexColumn>
                {!viewMode && (
                  <FlexColumn width={25}>
                    <FlexRow>
                      <LinkText
                        onClick={() => handleModalExcel(true)}
                        style={{ marginTop: 15, marginRight: 15 }}
                      >
                        Import from sheet
                      </LinkText>
                      <Button
                        onClick={() => {
                          switchFormShow(true);
                        }}
                        style={{ marginTop: 0 }}
                      >
                        Add
                      </Button>
                    </FlexRow>
                  </FlexColumn>
                )}
              </FlexRow>
            </HeaderContainer>
            <GridContainer>
              <THeader>
                {by === 'City/Area' && (
                  <TitleContainer width={'20%'}>
                    <Title>City/Area</Title>
                  </TitleContainer>
                )}
                {by !== 'Country' && (
                  <TitleContainer width={'20%'}>
                    <Title>Region</Title>
                  </TitleContainer>
                )}

                <TitleContainer width={'20%'}>
                  <Title>High-Quality Cuisine?</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Beer</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Cheese & Dairy</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Coffee & Tea</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Desserts</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Fruit & Vegetables</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Health Food</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Liquor</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Pasta & Noodles</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Red Meat</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Seafood</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Street Food</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Vegan Dining</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Vegetarian Dining</Title>
                </TitleContainer>
                <TitleContainer width={'20%'}>
                  <Title>Wine</Title>
                </TitleContainer>

                <TitleContainer width={'15%'} />
              </THeader>
              <TBody>
                {(list || []).map((lifecycle, key) => {
                  return this.renderItem(lifecycle, key, viewMode);
                })}
                {(!list || !list.length) && (
                  <ListEmpty message="There are currently no cuisine specialities" />
                )}
              </TBody>
            </GridContainer>
          </Container>
        )}
        {showForm && this.renderForm()}
        <Modal
          closeIcon
          isVisible={isOpen}
          title="DELETE CUISINE SPECIALITIES"
          cancelText="Cancel"
          onCancel={() => this.toggleModal(false, null)}
          successText="Remove"
          onSuccess={this.onRemove}
        >
          Are you sure of remove the cuisine speciality?
        </Modal>

        <ModalExcel
          get={find}
          id={country.id}
          token={token}
          url="cuisine-specialities"
          currentPage={currentPage}
          elementsPerPage={elementsPerPage}
          renderModalExcel={renderModalExcel}
          handleModalExcel={handleModalExcel}
        ></ModalExcel>
      </Container>
    );
  }
}

export default CuisineSpeciality;
