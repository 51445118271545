import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import { WELLNESS } from 'constants/index';
import RadioInput from 'components/RadioInput';

const SelectddWellnessBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SelectddWellnessPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`;

class SelectddWellnessView extends Component {
  render() {
    const { step, state, changeStep, handleChange, wellnessMaping } = this.props;
    return (
      <SelectddWellnessBox>
        <SelectddWellnessPrimaryBox>
          <PrimaryText text="What types of tour wellness interest you? Please select all that apply and rate your interest level low, medium, or high." />
          <Content>
            {WELLNESS.map(wellness => (
              <RadioInput
                style={{ marginBottom: 50 }}
                label={wellness}
                name={wellnessMaping[wellness]}
                options={[
                  { numericValue: 0, text: 'Nothing' },
                  { numericValue: 1, text: 'Low' },
                  { numericValue: 2, text: 'Medium' },
                  { numericValue: 3, text: 'High' },
                ]}
                handleChange={handleChange}
                currentValue={state[wellnessMaping[wellness]]}
                verticalAlign
              />
            ))}
          </Content>
          <StepButtons
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(step + 1)}
          />
        </SelectddWellnessPrimaryBox>
      </SelectddWellnessBox>
    );
  }
}

export default SelectddWellnessView;
