import React, { Component } from 'react';
import SelectTourClassesView from './SelectTourClassesView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import { TOURCLASSES } from 'constants/index';

const tourClassesMaping = Object.fromEntries(
  TOURCLASSES.map(tourClasses => [
    tourClasses,
    tourClasses
      .toLowerCase()
      .replaceAll(/\s/g, '_')
      .replaceAll('_&', '')
      .replaceAll(',', '')
      .replaceAll('/', '_')
      .replaceAll('-', '_'),
  ]),
);

class SelectTourClassesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      castles_palaces_villas: 0,
      historic_important_sites: 0,
      markets: 0,
      modern_marvels: 0,
      monuments_statues: 0,
      nature_parks: 0,
      neighborhood_town_walks: 0,
      notable_architecture: 0,
      other_tourClasses: 0,
      sacred_religious_sites: 0,
      scenic_views: 0,
      scenic_walking_areas: 0,
      squares: 0,
      water_theme_parks: 0,
    };
  }
  componentDidMount() {
    const {
      art_classes,
      audio_guides,
      baking_cooking_classes,
      bike_tours,
      boat_tours,
      bus_tours,
      dance_classes,
      exercise_classes,
      food_drink_tours,
      hiking_tours,
      music_lessons,
      other_tour_classes,
      private_tours,
      self_guided_tours,
      walking_tours,
      wellness_classes,
      wild_life_tours,
    } = this.props;
    this.setState({
      art_classes,
      audio_guides,
      baking_cooking_classes,
      bike_tours,
      boat_tours,
      bus_tours,
      dance_classes,
      exercise_classes,
      food_drink_tours,
      hiking_tours,
      music_lessons,
      other_tour_classes,
      private_tours,
      self_guided_tours,
      walking_tours,
      wellness_classes,
      wild_life_tours,
    });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const {
      art_classes,
      audio_guides,
      baking_cooking_classes,
      bike_tours,
      boat_tours,
      bus_tours,
      dance_classes,
      exercise_classes,
      food_drink_tours,
      hiking_tours,
      music_lessons,
      other_tour_classes,
      private_tours,
      self_guided_tours,
      walking_tours,
      wellness_classes,
      wild_life_tours,
    } = this.state;
    changeCurrentStep(step);
    saveTripData({
      tour_classes: {
        art_classes,
        audio_guides,
        baking_cooking_classes,
        bike_tours,
        boat_tours,
        bus_tours,
        dance_classes,
        exercise_classes,
        food_drink_tours,
        hiking_tours,
        music_lessons,
        other_tour_classes,
        private_tours,
        self_guided_tours,
        walking_tours,
        wellness_classes,
        wild_life_tours,
      },
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <SelectTourClassesView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        tourClassesMaping={tourClassesMaping}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    art_classes: state.tripReducer.tripForm.tour_classes.art_classes,
    audio_guides: state.tripReducer.tripForm.tour_classes.audio_guides,
    baking_cooking_classes: state.tripReducer.tripForm.tour_classes.baking_cooking_classes,
    bike_tours: state.tripReducer.tripForm.tour_classes.bike_tours,
    boat_tours: state.tripReducer.tripForm.tour_classes.boat_tours,
    bus_tours: state.tripReducer.tripForm.tour_classes.bus_tours,
    dance_classes: state.tripReducer.tripForm.tour_classes.dance_classes,
    exercise_classes: state.tripReducer.tripForm.tour_classes.exercise_classes,
    food_drink_tours: state.tripReducer.tripForm.tour_classes.food_drink_tours,
    hiking_tours: state.tripReducer.tripForm.tour_classes.hiking_tours,
    music_lessons: state.tripReducer.tripForm.tour_classes.music_lessons,
    other_tour_classes: state.tripReducer.tripForm.tour_classes.other_tour_classes,
    private_tours: state.tripReducer.tripForm.tour_classes.private_tours,
    self_guided_tours: state.tripReducer.tripForm.tour_classes.self_guided_tours,
    walking_tours: state.tripReducer.tripForm.tour_classes.walking_tours,
    wellness_classes: state.tripReducer.tripForm.tour_classes.wellness_classes,
    wild_life_tours: state.tripReducer.tripForm.tour_classes.wild_life_tours,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectTourClassesContainer);
