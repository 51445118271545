import React from 'react';
import styled from 'styled-components';
import FBLoginButton from './FBloginButton';
import GoogleLoginButton from './GoogleLoginButton';
const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 65%;
  justify-content: space-between;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export default props => {
  const { googleLogin, facebookLogin } = props;

  return (
    <SocialButtons>
      <GoogleLoginButton googleLogin={googleLogin} />
      <FBLoginButton facebookLogin={facebookLogin} />
    </SocialButtons>
  );
};
