import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import SelectedButton from '../../SelectedButton';
import PrimaryText from '../PrimaryText';
import { TEMPERATURE_RANGES } from 'constants/index';
import { Formik } from 'formik';

const TravelingForBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TravelingForPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 500px;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
`;

const Container = styled.div`
  height: 90%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

class SelectTemperatureRangeView extends Component {
  render() {
    const { step, state, changeStep, temperature_ranges } = this.props;

    return (
      <TravelingForBox>
        <TravelingForPrimaryBox>
          <PrimaryText text="Weather can play an important part in the enjoyably of a journey. Which of the following temperature ranges would you be OK experiencing?" />

          <Formik enableReinitialize initialValues={{ temperature_ranges }} isInitialValid={true}>
            {({ values, handleChange, errors, handleSubmit, setFieldValue }) => {
              const changeMonthRanges = value => {
                const temperature_ranges = [...values.temperature_ranges];
                const exist = temperature_ranges.indexOf(value);
                if (exist != -1) {
                  temperature_ranges.splice(exist, 1);
                } else {
                  temperature_ranges.push(value);
                }
                setFieldValue('temperature_ranges', temperature_ranges);
              };
              return (
                <Container>
                  <Content>
                    {TEMPERATURE_RANGES.map(temperatureRange => (
                      <SelectedButton
                        text={temperatureRange}
                        onClick={() => changeMonthRanges(temperatureRange)}
                        active={values.temperature_ranges.includes(temperatureRange)}
                      />
                    ))}
                  </Content>

                  <StepButtons
                    nextDisabled={!values.temperature_ranges.length}
                    backStep={() => changeStep(step - 1, values)}
                    nextStep={() => changeStep(step + 1, values)}
                  />
                </Container>
              );
            }}
          </Formik>
        </TravelingForPrimaryBox>
      </TravelingForBox>
    );
  }
}

export default SelectTemperatureRangeView;
