import React, { Component } from 'react';
import StepOneView from './StepOneView';
import { connect } from 'react-redux';
import * as registerActions from '../../../actions/registerActions';

const INITIAL_STATE = {
  firstName: '',
  email: '',
  password: '',
  country: '',
  phone: '',
  lastName: '',
  confirmEmail: '',
  confirmPassword: '',
  city: '',
  aceptTerms: false,
  provider: 'local',
  state: '',
};

class StepOneContainer extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const { newUser } = this.props;
    if (newUser) {
      this.setState({ ...newUser });
    }
  }

  cleanForm = () => {
    this.setState(INITIAL_STATE);
  };
  changeStep = (step, user) => {
    const { registerFirstStep, changeCurrentStep } = this.props;
    if (step == 2) {
      registerFirstStep(user);
    }
    changeCurrentStep(step);
  };

  facebookRegist = response => {
    this.setState({
      firstName: response.first_name,
      lastName: response.last_name,
      email: response.email,
      confirmEmail: response.email,
      provider: 'facebook',
      accessToken: response.accessToken,
      password: '12346578',
      confirmPassword: '12346578',
    });
  };

  googleRegist = response => {
    const { profileObj } = response;
    if (profileObj) {
      this.setState({
        firstName: profileObj.givenName,
        lastName: profileObj.familyName,
        email: profileObj.email,
        confirmEmail: profileObj.email,
        provider: 'google',
        accessToken: response.tokenId,
        password: '12346578',
        confirmPassword: '12346578',
      });
    }
  };

  render() {
    return (
      <StepOneView
        {...this.props}
        changeStep={this.changeStep}
        data={this.state}
        facebookRegist={this.facebookRegist}
        googleRegist={this.googleRegist}
        cleanForm={this.cleanForm}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    newUser: state.registerReducer.newUser,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    registerFirstStep: newUser => dispatch(registerActions.registerStep(newUser)),
    changeCurrentStep: step => dispatch(registerActions.changeCurrentStep(step)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepOneContainer);
