import React, { Component } from 'react';
import RegionsForm from './RegionsForm';
import { connect } from 'react-redux';
import * as regionsActions from '../../actions/regionsActions';
import * as suggestionActions from '../../actions/suggestionActions';

class CreateRegions extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getRegions,
      getCountriesForSelect,
    } = this.props;
    getCountriesForSelect();
    getRegions(id);
  }

  editRegion = region => {
    const {
      match: {
        params: { id },
      },
      editRegions,
    } = this.props;
    editRegions(id, region);
  };

  render() {
    const { region, countriesForSelect } = this.props;

    return (
      <RegionsForm
        region={region}
        submit={this.editRegion}
        countriesForSelect={countriesForSelect}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    region: state.regionsReducer.region,
    countriesForSelect: state.suggestionReducer.countriesForSelect,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getRegions: id => dispatch(regionsActions.getRegions(id)),
    editRegions: (id, region) => dispatch(regionsActions.editRegions(id, region)),
    getCountriesForSelect: () => dispatch(suggestionActions.getCountriesForSelect()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateRegions);
