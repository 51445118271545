import { ActionTypes } from 'constants/index';

export function getMeInvoices() {
  return {
    type: ActionTypes.GET_ME_INVOICES,
  };
}

export function saveMeInvoices(invoices) {
  return {
    type: ActionTypes.SAVE_ME_INVOICES,
    invoices: invoices.rows,
    subscription: invoices.subscription,
  };
}
