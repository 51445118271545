import React, { Component } from 'react';
import RecoverPasswordForm from '../../components/NewPassword/RecoverPasswordForm';
import PageWithBackgroundImage from '../../components/PageWithBackgroundImage';

class ForgotPasswordView extends Component {
  render() {
    const { recoverPassword } = this.props;

    return (
      <PageWithBackgroundImage>
        <RecoverPasswordForm recoverPassword={recoverPassword} />
      </PageWithBackgroundImage>
    );
  }
}

export default ForgotPasswordView;
