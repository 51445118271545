import React from 'react';
import AddGeneralPreference from 'components/Trip/Steps/AddGeneralPreferenceContainer';
import ChooseCity from 'components/Trip/Steps/ChooseCityContainer';
import TravelWithOthers from 'components/Trip/Steps/TravelWithOthersContainer';
import SelectDates from 'components/Trip/Steps/SelectDatesContainer';
import SelectEntertainment from 'components/Trip/Steps/SelectEntertainmentContainer';
import SelectFoodDrink from 'components/Trip/Steps/SelectFoodDrinkContainer';
import SelectMuseum from 'components/Trip/Steps/SelectMuseumContainer';
import SelectNightlife from 'components/Trip/Steps/SelectNightlifeContainer';
import SelectOutdoorActivities from 'components/Trip/Steps/SelectOutdoorActivitiesContainer';
import SelectSightseeing from 'components/Trip/Steps/SelectSightseeingContainer';
import SelectShopping from 'components/Trip/Steps/SelectShoppingContainer';
import SelectTourClasses from 'components/Trip/Steps/SelectTourClassesContainer';
import SelectWellness from 'components/Trip/Steps/SelectWellnessContainer';
import FoodieDestination from 'components/Trip/Steps/FoodieDestinationContainer';
import Eateries from 'components/Trip/Steps/EateriesContainer';
import IsVegan from 'components/Trip/Steps/IsVeganContainer';
import RentalCar from 'components/Trip/Steps/RentalCarContainer';
import TemperatureRanges from 'components/Trip/Steps/TemperatureRangesContainer';
import HumidityLevel from 'components/Trip/Steps/HumidityLevelContainer';
import RainingLevel from 'components/Trip/Steps/RainingLevelContainer';
import Landscapes from 'components/Trip/Steps/LandscapesContainer';
import TravelingWith from 'components/Trip/Steps/TravelingWithContainer';
import KnowDates from 'components/Trip/Steps/KnowDatesContainer';
import SelectDatesRange from 'components/Trip/Steps/SelectDatesRangeContainer';
import SelectDaysQuantity from 'components/Trip/Steps/SelectDaysQuantityContainer';
import Accommodation from 'components/Trip/Steps/AccommodationContainer';
import DailyBudget from 'components/Trip/Steps/DailyBudgetContainer';
import PastDestinations from 'components/Trip/Steps/PastDestinationsContainer';
import AddTitle from 'components/Trip/Steps/AddTitleContainer';
import Modal from 'components/Modal';
import Button from 'components/Button';

import SuggestionsContainer from 'routes/Meeting/SuggestionsContainer';
import { Redirect } from 'react-router-dom';
const TripView = props => {
  const { step, match, suggestionsModal, handleModal } = props;

  return (
    <>
      <div style={{ float: 'right', margin: 30 }}>
        <Button onClick={() => handleModal(true)}>Suggestions</Button>
      </div>
      {step == 1 && <AddGeneralPreference step={step} />}
      {step == 2 && <ChooseCity step={step} />}
      {step == 3 && <PastDestinations step={step} />}
      {step == 4 && <TravelWithOthers step={step} />}
      {step == 5 && <TravelingWith step={step} />}
      {step == 6 && <KnowDates step={step} />}
      {step == 7 && <SelectDates step={step} />}
      {step == 8 && <SelectDatesRange step={step} />}
      {step == 9 && <SelectDaysQuantity step={step} />}
      {step == 10 && <DailyBudget step={step} />}
      {step == 11 && <SelectEntertainment step={step} />}
      {step == 12 && <SelectFoodDrink step={step} />}
      {step == 13 && <SelectMuseum step={step} />}
      {step == 14 && <SelectNightlife step={step} />}
      {step == 15 && <SelectOutdoorActivities step={step} />}
      {step == 16 && <SelectSightseeing step={step} />}
      {step == 17 && <SelectShopping step={step} />}
      {step == 18 && <SelectTourClasses step={step} />}
      {step == 19 && <SelectWellness step={step} />}
      {step == 20 && <Accommodation step={step} />}
      {step == 21 && <FoodieDestination step={step} />}
      {step == 22 && <Eateries step={step} />}
      {step == 23 && <IsVegan step={step} />}
      {step == 24 && <RentalCar step={step} />}
      {step == 25 && <TemperatureRanges step={step} />}
      {step == 26 && <HumidityLevel step={step} />}
      {step == 27 && <RainingLevel step={step} />}
      {step == 28 && <Landscapes step={step} />}
      {step == 29 && <AddTitle step={step} match={match} />}

      {step == 30 && (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )}

      <Modal
        closeIcon
        isVisible={suggestionsModal}
        cancelText="Cancel"
        onCancel={() => handleModal(false)}
        width="90%"
      >
        <SuggestionsContainer />
      </Modal>
    </>
  );
};

export default TripView;
