import React, { Component } from 'react';
import StepContainer from '../../StepContainer';
import StepButtons from '../../StepButtons';
import RangeInput from '../../RangeInput';
import styled from 'styled-components';
import ProgressBarSteps from 'components/ProgressBarSteps';

const WellBeingTestContainer = styled.div`
  margin: 0 22%;
  height: 450px;
  @media (max-width: 1000px) {
    margin-left: 15%;
    margin-right: 15%;
    height: auto;
  }
`;

const CurrentStepIndicator = styled.p`
  color: #9e9e9e;
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
`;

const ProgresBarContainer = styled.div`
  width: 14%;
  position: absolute;
  bottom: 40px;
  right: 100px;
  @media (max-width: 875px) {
    display: none;
  }
`;
export default class Step2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { changeStep, handleChange, state, stepFields } = this.props;
    const { localStep } = state;
    return (
      <>
        <StepContainer subtitle={stepFields.subtitle} needSpace>
          <WellBeingTestContainer>
            {stepFields.content.map((field, key) => (
              <RangeInput
                key={key}
                label={field.label}
                name={field.name}
                handleChange={handleChange}
                min="0"
                max="10"
                value={state[field.name]}
                step="1"
                minQuantityLabel={
                  field.importantMinQuantityLabel
                    ? field.importantMinQuantityLabel
                    : stepFields.minQuantityLabel
                }
                minQuantityValue={stepFields.minQuantityValue}
                maxQuantityLabel={
                  field.importantMaxQuantityValue
                    ? field.importantMaxQuantityValue
                    : stepFields.maxQuantityLabel
                }
                maxQuantityValue={stepFields.maxQuantityValue}
                mediumQuantityLabel={
                  field.importantMediumQuantityValue
                    ? field.importantMediumQuantityValue
                    : stepFields.mediumQuantityLabel
                }
                mediumQuantityValue={stepFields.mediumQuantityValue}
              />
            ))}
          </WellBeingTestContainer>
          {/* <Progress className="testProgressBar" status="success" percent={88} /> */}
          <ProgresBarContainer>
            <ProgressBarSteps percent={Math.round((localStep * 100) / 8)} />
          </ProgresBarContainer>
          <CurrentStepIndicator>Part 1 of 3</CurrentStepIndicator>
          <StepButtons
            backStep={() => changeStep(localStep - 1)}
            nextStep={() => changeStep(localStep + 1)}
          />
        </StepContainer>
      </>
    );
  }
}
