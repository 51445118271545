import React, { Component } from 'react';
import AccommodationsView from './AccommodationsView';
import { connect } from 'react-redux';
import * as AccommodationsActions from 'actions/accommodationActions';

class AccommodationsContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10, isOpen: false };

  componentDidMount() {
    this.fetchAccommodations();
  }

  fetchAccommodations = () => {
    const { currentPage, elementsPerPage } = this.state;

    this.props.onGetAccommodations(currentPage, elementsPerPage);
  };

  changePage = currentPage => {
    const { elementsPerPage } = this.state;
    this.props.onGetAccommodations(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  toggleModal = accommodationSelected => {
    const { isOpen } = this.state;

    if (!isOpen) {
      window.scrollTo(0, 0);
    }

    this.setState({
      isOpen: !isOpen,
      accommodationSelected,
    });
  };

  removeAccommodation = () => {
    const { onRemoveAccommodation } = this.props;
    const { accommodationSelected } = this.state;

    onRemoveAccommodation(accommodationSelected);

    this.setState({
      isOpen: false,
      accommodationSelected,
    });
  };

  render() {
    const { accommodationsCount, accommodations, loading } = this.props;
    const { currentPage, elementsPerPage, isOpen } = this.state;
    return (
      <AccommodationsView
        {...this.props}
        getAccommodations={this.getAccommodations}
        accommodations={accommodations}
        currentPage={currentPage}
        changePage={this.changePage}
        accommodationsCount={accommodationsCount}
        elementsPerPage={elementsPerPage}
        isOpen={isOpen}
        toggleModal={this.toggleModal}
        removeAccommodation={this.removeAccommodation}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    accommodations: state.accommodationReducer.accommodations,
    accommodationsCount: state.accommodationReducer.accommodationsCount,
    loading: state.accommodationReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onGetAccommodations: (page, elementsPerPage) =>
      dispatch(AccommodationsActions.getAccommodations(page, elementsPerPage)),
    onRemoveAccommodation: id => dispatch(AccommodationsActions.removeAccommodation(id)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccommodationsContainer);
