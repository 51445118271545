import React from 'react';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import CustomerListIterm from 'components/Customer/CustomerListItem';
import Pagination from 'components/PaginationButtons';
import Loader from 'components/Loader';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const TBody = styled.div``;

const Customers = props => {
  const { currentPage, changePage, customersCount, elementsPerPage, customers, loading } = props;

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader type="pulse" size={125} />
        </LoaderContainer>
      ) : (
        <>
          <Pagination
            currentPage={currentPage}
            changePage={changePage}
            totalPages={Math.ceil(customersCount / elementsPerPage)}
          />
          <GridContainer>
            <THeader>
              <TitleContainer width={'10%'} />
              <TitleContainer width={'20%'}>
                <Title left>CUSTOMER</Title>
              </TitleContainer>
              <TitleContainer width={'20%'}>
                <Title>LOCATION</Title>
              </TitleContainer>
              <TitleContainer width={'14%'}>
                <Title>PHONE</Title>
              </TitleContainer>
              <TitleContainer width={'10%'}>
                <Title>TEST</Title>
              </TitleContainer>
              <TitleContainer width={'10%'}>
                <Title>STATUS</Title>
              </TitleContainer>
              <TitleContainer width={'16%'}></TitleContainer>
            </THeader>
            <TBody>
              {customers.map((customer, key) => (
                <CustomerListIterm customer={customer} key={key} />
              ))}
              {(!customers || !customers.length) && (
                <ListEmpty message="There are currently no customers" />
              )}
            </TBody>
          </GridContainer>
        </>
      )}
    </>
  );
};

export default Customers;
