import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { appColor } from 'modules/theme';
import ModalExcel from 'components/Suggestions/ModalExcel';

const GridContainer = styled.div`
  overflow-x: auto;
`;

const FlexRow = styled.div`
  display: flex;
  width: auto;
  justify-content: space-around;
  display: inline-flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 3)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 3)}px;
  margin-top: ${props => (props.marginTop ? props.marginTop : 0)}%;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const InputActivity = styled.p`
  width: ${props => props.width && props.width}px;
  height: 30px;
  object-fit: contain;
  border-radius: 0px;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#f8f7f7')};
  border: none;
  margin-top: ${props => (props.marginTop ? props.marginTop : -15)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: ${props => (props.fontSize ? props.fontSize : 20)}px;
    color: #545454;
  }
  font-family: 'Josefin Sans', sans-serif;
  color: #545454;
  font-size: ${props => (props.fontSize ? props.fontSize : 20)}px;
  padding-left: ${props => props.paddingLeft && props.paddingLeft}%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const LabelExpenses = styled.p`
  width: ${props => props.width}px;
  height: 30px;
  margin-bottom: 20px;
  font-size: 15px;
  color: black;
  border-radius: 5px 5px 0px 0px;
  text-decoration: none;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
`;

const AcommodationPricingsForm = props => {
  const {
    token,
    viewMode,
    accommodationPricing,
    saveAccommodationPricing,
    handleModalExcel,
    renderModalExcel,
    country: { id },
  } = props;

  return (
    <GridContainer>
      <Formik enableReinitialize initialValues={accommodationPricing} isInitialValid={true}>
        {({ values, handleChange }) => {
          return (
            <>
              <FlexRow>
                <FlexColumn>
                  <LabelExpenses width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Backpacker Accommodations (Less Than)
                  </LabelExpenses>
                  <InputActivity>${values.backpacker_accommodations_less_than}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'320'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Backpacker Accommodations (Less Than in $)
                  </LabelExpenses>
                  <InputActivity>${values.backpacker_accommodations_less_than_usd}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'270'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Budget Accommodations (Minimum)
                  </LabelExpenses>
                  <InputActivity>${values.budget_accommodations_minimum}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Budget Accommodations (Minimum in $)
                  </LabelExpenses>
                  <InputActivity>${values.budget_accommodations_minimum_usd}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'270'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Budget Accommodations (Maximum)
                  </LabelExpenses>
                  <InputActivity>${values.budget_accommodations_maximum}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Budget Accommodations (Maximum in $)
                  </LabelExpenses>
                  <InputActivity>${values.budget_accommodations_maximum_usd}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'270'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Moderate Accommodations (Minimum)
                  </LabelExpenses>
                  <InputActivity>${values.moderate_accommodations_minimum}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Moderate Accommodations (Minimum in $)
                  </LabelExpenses>
                  <InputActivity>${values.moderate_accommodations_minimum_usd}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'270'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Moderate Accommodations (Maximum)
                  </LabelExpenses>
                  <InputActivity>${values.moderate_accommodations_maximum}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Moderate Accommodations (Maximum in $)
                  </LabelExpenses>
                  <InputActivity>${values.moderate_accommodations_maximum_usd}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'270'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Pricier Accommodations (Minimum)
                  </LabelExpenses>
                  <InputActivity>${values.pricier_accommodations_minimum}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Pricier Accommodations (Minimum in $)
                  </LabelExpenses>
                  <InputActivity>${values.pricier_accommodations_minimum_usd}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'270'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Pricier Accommodations (Maximum)
                  </LabelExpenses>
                  <InputActivity>${values.pricier_accommodations_maximum}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'300'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Pricier Accommodations (Maximum in $)
                  </LabelExpenses>
                  <InputActivity>${values.pricier_accommodations_maximum_usd}</InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'370'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Splurge Accommodations (Most Rooms More Than)
                  </LabelExpenses>
                  <InputActivity>
                    ${values.splurge_accommodations_most_rooms_more_than}
                  </InputActivity>
                </FlexColumn>
                <FlexColumn>
                  <LabelExpenses width={'400'} backgroundColor={'rgba(86, 193, 254, 0.2)'}>
                    Splurge Accommodations (Most Rooms More Than in $)
                  </LabelExpenses>
                  <InputActivity>
                    ${values.splurge_accommodations_most_rooms_more_than_usd}
                  </InputActivity>
                </FlexColumn>
              </FlexRow>
            </>
          );
        }}
      </Formik>
      {/*<ModalExcel
        id={id}
        token={token}
        url="accommodation-pricings"
        renderModalExcel={renderModalExcel}
        handleModalExcel={handleModalExcel}
      ></ModalExcel>*/}
    </GridContainer>
  );
};
export default AcommodationPricingsForm;
