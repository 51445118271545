import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import Input from 'components/Input';
import Dropzone from 'components/DropZone';
import RadioInput from 'components/RadioInput';
import { ACCOMMODATION } from 'constants/index';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

class AccommodationView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { step, changeStep, handleChange, accommodation } = this.props;

    return (
      <Container>
        <PrimaryText text="Which of the following categories best ts the type of accommodations you would realistically expect to stay in most nights on your upcoming journey?" />

        <RadioInput
          name="accommodation"
          options={ACCOMMODATION}
          handleChange={handleChange}
          currentValue={accommodation}
          verticalAlign
        />

        <StepButtons
          nextDisabled={!accommodation}
          backStep={() => changeStep(step - 1)}
          nextStep={() => changeStep(step + 1)}
        />
      </Container>
    );
  }
}

export default AccommodationView;
