import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  preTripCourses: [],
  loading: false,
  purchasedPreTripCourses: [],
};

export default {
  preTripCourseReducer: handleActions(
    {
      [ActionTypes.GET_PRE_TRIP_COURSES](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.SAVE_PRE_TRIP_COURSES](state, action) {
        return {
          ...state,
          preTripCourses: action.preTripCourses,
          preTripCoursesCount: action.preTripCoursesCount,
          loading: false,
        };
      },
      [ActionTypes.SAVE_PRE_TRIP_COURSE](state, action) {
        return {
          ...state,
          preTripCourse: action.preTripCourse,
        };
      },
      [ActionTypes.PURCHASED_PRE_TRIP_COURSES](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.SAVE_PURCHASED_PRE_TRIP_COURSES](state, action) {
        return {
          ...state,
          purchasedPreTripCourses: action.purchasedPreTripCourses,
          purchasedPreTripCoursesCount: action.purchasedPreTripCoursesCount,
          loading: false,
        };
      },
      [ActionTypes.SAVE_ALL_PURCHASED_PRE_TRIP_COURSES](state, action) {
        return {
          ...state,
          allPurchasePreTripCourses: action.allPurchasePreTripCourses,
          allPurchasePreTripCoursesCount: action.allPurchasePreTripCoursesCount,
        };
      },
    },
    appState,
  ),
};
