import { put, call } from 'redux-saga/effects';
import * as paymentMethodsActions from '../actions/paymentMethodsActions';
import * as appActions from '../actions/app';

export function* getPaymentMethods(api, action) {
  const response = yield call(api.getPaymentMethods);
  if (response.status == 200) {
    yield put(paymentMethodsActions.savePaymentMethods(response.data));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* createPaymentMethod(api, action) {
  const response = yield call(api.createPaymentMethod, action.data);
  if (response.status == 200) {
    yield put(appActions.showAlert('Card created!', { variant: 'success' }));
    yield put(paymentMethodsActions.getPaymentMethods(response.data));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* deletePaymentMethod(api, action) {
  const response = yield call(api.deletePaymentMethod, action.data);
  if (response.status == 200) {
    yield put(appActions.showAlert('Card deleted!', { variant: 'success' }));
    yield put(paymentMethodsActions.getPaymentMethods(response.data));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* setDefaultMethod(api, action) {
  const response = yield call(api.setDefaultMethod, action.data);
  if (response.status == 200) {
    yield put(appActions.showAlert('Default card updated!', { variant: 'success' }));
    yield put(paymentMethodsActions.getPaymentMethods(response.data));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
