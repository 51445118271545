import React from 'react';
import styled from 'styled-components';
import EmailLoginForm from '../../components/Login/EmailLoginForm';
import SocialLoginButtons from '../../components/Login/SocialLoginButtons';
import PageWithBackgroundImage from '../../components/PageWithBackgroundImage';
import LoginHeader from 'components/Login/LoginHeader';

import './styles.css';

const SimpleText = styled.div`
  margin-left: 18%;
  color: #9e9e9e;
`;

const Login = props => {
  const {
    emailLogin,
    facebookLogin,
    googleLogin,
    isLoggedIn,
    email,
    password,
    remember,
    loading,
  } = props;
  return (
    <PageWithBackgroundImage>
      <LoginHeader />
      <EmailLoginForm
        emailLogin={emailLogin}
        isLoggedIn={isLoggedIn}
        email={email}
        password={password}
        remember={remember}
        loading={loading}
      />
      <SimpleText>Or:</SimpleText>
      <SocialLoginButtons facebookLogin={facebookLogin} googleLogin={googleLogin} />
    </PageWithBackgroundImage>
  );
};

export default Login;
