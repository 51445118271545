import React, { Component } from 'react';
import styled from 'styled-components';
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';

const QuantitiValue = styled.p`
  color: #38b6ff;
  float: right;
  font-size: 14px;
  margin-bottom: 8px;
`;

export default class ProgressBarStep extends Component {
  render() {
    const { percent } = this.props;
    return (
      <>
        <QuantitiValue>{percent}%</QuantitiValue>
        <Progress className="testProgressBar" status="success" percent={percent} />
      </>
    );
  }
}
