import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PreTripCourseItem from 'components/preTripCourseItem';
import Pagination from 'components/PaginationButtons';
import Modal from 'components/Modal';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';

const PreTripCoursesBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;
const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: #38b6ff;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;
const PreTripCoursesView = props => {
  const {
    preTripCourses,
    currentPage,
    changePage,
    preTripCoursesCount,
    elementsPerPage,
    deletePreTripCourse,
    isOpen,
    toggleModal,
    role,
    loading,
  } = props;
  if (!preTripCourses) return null;

  return (
    <>
      <Header>
        {role == 'admin' && (
          <Link to={'/pre-trip-courses/create'}>
            <Button>{'Add Pre Trip Course'}</Button>
          </Link>
        )}
      </Header>

      {loading ? (
        <LoaderContainer>
          <Loader type="pulse" size={125} />
        </LoaderContainer>
      ) : (
        <>
          <Pagination
            currentPage={currentPage}
            changePage={changePage}
            totalPages={Math.ceil(preTripCoursesCount / elementsPerPage)}
          />
          <PreTripCoursesBox>
            {preTripCourses.map(preTripCourse => (
              <PreTripCourseItem
                role={role}
                preTripCourse={preTripCourse}
                deletePreTripCourse={toggleModal}
              />
            ))}
          </PreTripCoursesBox>
          {preTripCourses.length == 0 && (
            <ListEmpty message="There are currently no Pre Trip Courses" />
          )}
        </>
      )}

      <Modal
        closeIcon
        isVisible={isOpen}
        title="DELETE COURSE"
        cancelText="Cancel"
        onCancel={toggleModal}
        successText="Remove"
        onSuccess={deletePreTripCourse}
      >
        Are you sure of remove the ad on?
      </Modal>
    </>
  );
};

export default PreTripCoursesView;
