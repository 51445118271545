import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import Input from 'components/Input';
import Dropzone from 'components/DropZone';
import RadioInput from 'components/RadioInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

class AddGeneralPreferenceView extends Component {
  render() {
    const { step, changeStep, handleChange, know_dates, options } = this.props;

    let nextStep;
    switch (know_dates) {
      case 1: // Yes, I know specic dates
        nextStep = 7;
        break;
      case 2: // No, but I have an idea of which months would work.
        nextStep = 8;
        break;
      case 3: // No, I'm not sure yet.
        nextStep = 9;
        break;
    }

    return (
      <Container>
        <PrimaryText text="Do you know when you'd your journey to be?" />
        <RadioInput
          name="know_dates"
          options={options}
          handleChange={handleChange}
          currentValue={know_dates}
          verticalAlign
        />
        <StepButtons
          nextDisabled={!know_dates}
          backStep={() => changeStep(step - 1)}
          nextStep={() => changeStep(nextStep)}
        />
      </Container>
    );
  }
}

export default AddGeneralPreferenceView;
