import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import SelectedButton from '../../SelectedButton';
import PrimaryText from '../PrimaryText';
import { RAININGLEVELS } from 'constants/index';
import { Formik } from 'formik';

const TravelingForBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TravelingForPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 500px;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
`;

const Container = styled.div`
  height: 90%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

class RainingLevelView extends Component {
  render() {
    const { step, state, changeStep, raining_level } = this.props;

    return (
      <TravelingForBox>
        <TravelingForPrimaryBox>
          <PrimaryText text="Which of the following levels of raining would you be OK experiencing?" />

          <Formik enableReinitialize initialValues={{ raining_level }} isInitialValid={true}>
            {({ values, handleChange, errors, handleSubmit, setFieldValue }) => {
              return (
                <Container>
                  <Content>
                    {RAININGLEVELS.map(rainingLevel => (
                      <SelectedButton
                        text={rainingLevel}
                        onClick={() => setFieldValue('raining_level', rainingLevel)}
                        active={values.raining_level == rainingLevel}
                      />
                    ))}
                  </Content>

                  <StepButtons
                    nextDisabled={!values.raining_level}
                    backStep={() => changeStep(step - 1, values)}
                    nextStep={() => changeStep(step + 1, values)}
                  />
                </Container>
              );
            }}
          </Formik>
        </TravelingForPrimaryBox>
      </TravelingForBox>
    );
  }
}

export default RainingLevelView;
