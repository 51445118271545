import React, { Component } from 'react';
import styled from 'styled-components';
import Input from '../Input';

const ResetPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 20px auto 50px auto;
`;

const BigButton = styled.button`
  border-radius: 5px;
  background-color: #38b6ff;
  color: white;
  height: 52px;
  cursor: pointer;
  margin-top: 25px;
`;

const Title = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 50px;
  color: #353535;
`;
const BlueText = styled.a`
  color: #38b6ff;
  text-align: center;
  margin: 35px;
  cursor: pointer;
  @media (min-width: 700px) {
    display: none;
  }
`;

export default class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { password: '', confirm: '' };
  }

  sendResetPassword = () => {
    const { resetPassword } = this.props;
    const { password, confirm } = this.state;
    if (password == confirm) {
      resetPassword(password);
      this.setState({ password: '', confirm: '' });
    } else {
      alert('Passwords dont match.');
    }
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  render() {
    return (
      <ResetPasswordContainer>
        <Title>Reset your password.</Title>
        <Input
          height={60}
          placeholder="New Password"
          name="password"
          value={this.state.password}
          onChange={this.handleChange}
          type="password"
        />
        <Input
          height={60}
          placeholder="Confirm"
          name="confirm"
          value={this.state.confirm}
          onChange={this.handleChange}
          type="password"
        />
        <BigButton onClick={this.sendResetPassword}>Reset Password</BigButton>
        <BlueText href="AuthenticTraveling://">Go to App</BlueText>
      </ResetPasswordContainer>
    );
  }
}
