import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  cities: [],
  loading: false,
  city: null,
  citiesCount: null,
  citiesForSelect: [],
};

export default {
  citiesReducer: handleActions(
    {
      [ActionTypes.CREATE_CITIES](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.CREATE_CITIES_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.CREATE_CITIES_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_CITIES](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_CITIES_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          city: action.city,
        };
      },
      [ActionTypes.GET_CITIES_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_ALL_CITIES](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_ALL_CITIES_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          cities: action.cities,
          citiesCount: action.citiesCount,
        };
      },
      [ActionTypes.GET_ALL_CITIES_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.EDIT_CITIES](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.EDIT_CITIES_SUCCESS](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.EDIT_CITIES_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.REMOVE_CITIES](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.REMOVE_CITIES_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          cities: state.cities.filter(city => city.id != action.id),
        };
      },
      [ActionTypes.REMOVE_CITIES_FAILURE](state) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GET_CITIES_FOR_SELECT](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_CITIES_FOR_SELECT_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          citiesForSelect: action.citiesForSelect || [],
        };
      },
      [ActionTypes.GET_CITIES_FOR_SELECT_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
    },
    appState,
  ),
};
