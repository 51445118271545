import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  activities: [],
  places: [],
  drivingDurations: [],
  walkingDurations: [],
  loading: false,
};

export default {
  activityReducer: handleActions(
    {
      [ActionTypes.GET_ACTIVITIES](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_ACTIVITIES_SUCCESS](state, action) {
        return {
          ...state,
          activities: action.activities,
          activitiesCount: action.activitiesCount,
          loading: false,
        };
      },
      [ActionTypes.GET_ACTIVITY](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_ACTIVITY_SUCCESS](state, action) {
        return {
          ...state,
          activity: action.activity,
          loading: false,
        };
      },
      [ActionTypes.SAVE_ACTIVITY_PLACES](state, action) {
        return {
          ...state,
          places: action.places,
        };
      },
      [ActionTypes.SAVE_DRIVING_DURATIONS](state, action) {
        return {
          ...state,
          drivingDurations: [...state.drivingDurations, action.drivingDuration],
        };
      },
      [ActionTypes.SAVE_WALKING_DURATIONS](state, action) {
        return {
          ...state,
          walkingDurations: [...state.walkingDurations, action.walkingDuration],
        };
      },
      [ActionTypes.RESET_DURATIONS](state) {
        return {
          ...state,
          drivingDurations: [],
          walkingDurations: [],
        };
      },
      [ActionTypes.REMOVE_ACTIVITY](state, action) {
        return {
          ...state,
        };
      },
    },
    appState,
  ),
};
