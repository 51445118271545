import React, { Component } from 'react';
import AccommodationView from './AccommodationView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';

class Accommodations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accommodation: null,
    };
  }

  componentDidMount() {
    const { accommodation } = this.props;
    this.setState({ accommodation });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accommodation != this.props.accommodation)
      this.setState({ accommodation: this.props.accommodation });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { accommodation } = this.state;
    saveTripData({ accommodation });
    changeCurrentStep(step);
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { accommodation } = this.state;

    return (
      <AccommodationView
        {...this.props}
        changeStep={this.changeStep}
        handleChange={this.handleChange}
        accommodation={accommodation}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    accommodation: state.tripReducer.tripForm.accommodation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Accommodations);
