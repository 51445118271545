import React, { Component } from 'react';
import RecoverPasswordView from './RecoverPasswordView';
import { connect } from 'react-redux';
import * as loginActions from '../../actions/loginActions';

class RecoverPasswordContainer extends Component {
  constructor(props) {
    super(props);
  }

  recoverPassword = email => {
    this.props.onRecoverPassword(email);
  };

  render() {
    return <RecoverPasswordView {...this.props} recoverPassword={this.recoverPassword} />;
  }
}

function mapStateToProps(state) {
  return { state };
}
function mapDispatchToProps(dispatch) {
  return {
    onRecoverPassword: email => dispatch(loginActions.requestRecoverPassword(email)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecoverPasswordContainer);
