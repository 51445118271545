import React, { Component } from 'react';
import styled from 'styled-components';
import Dropzone from 'components/DropZone';
import Modal from 'components/Modal';
import config from 'config/appConfig';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

const DropzoneContainer = styled.div`
  margin-bottom: 34px;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 39px;
  height: 255px;
  display: flex;
  justify-content: center;
  position: relative;
}
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  line-height: 0;
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

class ModalExcel extends Component {
  constructor(props) {
    super(props);
  }

  handleResponse = response => {
    const { id, handleModalExcel, get, currentPage, elementsPerPage } = this.props;

    if (get) {
      get({ country_id: id, currentPage, elementsPerPage });
    }

    handleModalExcel(false);
  };

  render() {
    const { renderModalExcel, handleModalExcel, id, token, url } = this.props;
    return (
      <Modal
        isVisible={renderModalExcel}
        title="Import from sheet"
        onCancel={() => handleModalExcel(false)}
        successText="Save"
      >
        <Container>
          <DropzoneContainer>
            <Dropzone
              handleResponse={this.handleResponse}
              width={750}
              height={255}
              authToken={token}
              url={`${config.BASE_URL}/api/v1/${url}/import/${id}`}
            />
          </DropzoneContainer>
        </Container>
      </Modal>
    );
  }
}

export default ModalExcel;
