import React, { Component } from 'react';
import KnowDatesView from './KnowDatesView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';
import { KNOW_DATES } from 'constants/index';

class KnowDatess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      know_dates: null,
    };
  }

  componentDidMount() {
    const { know_dates } = this.props;
    this.setState({ know_dates });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.know_dates != this.props.know_dates)
      this.setState({ know_dates: this.props.know_dates });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { know_dates } = this.state;
    if (know_dates == 1) {
      saveTripData({ know_dates, month_ranges: [], days_quantity: null });
    } else if (know_dates == 3) {
      saveTripData({ know_dates, days_quantity: null });
    } else {
      saveTripData({ know_dates });
    }

    changeCurrentStep(step);
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { know_dates } = this.state;

    return (
      <KnowDatesView
        {...this.props}
        changeStep={this.changeStep}
        handleChange={this.handleChange}
        know_dates={know_dates}
        options={KNOW_DATES}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    know_dates: state.tripReducer.tripForm.know_dates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KnowDatess);
