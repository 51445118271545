import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PrimaryText from '../PrimaryText';
import SecondaryText from '../SecondaryText';

import 'react-datepicker/dist/react-datepicker.css';
import '../styles.css';

const HaveDatesBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const HaveDatesPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 80%;
`;

const SelectBox = styled.div`
  display: flex;
`;

const DatePickerContainer = styled.div`
  width: 100%;
  margin-left: ${props => props.marginLeft}px;
  margin-right: ${props => props.marginright}px;
`;

const Select = styled.select`
  background: white;
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  height: 44px;
  padding: 10px;
  width: 100%;
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;
class HaveDatesView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { step, state, changeStep, changeDate, handleChange } = this.props;
    const { start_date, departure_time, end_date, arriving_time } = state;
    return (
      <HaveDatesBox>
        <HaveDatesPrimaryBox>
          <PrimaryText text="Select Dates" />
          <Content>
            <SecondaryText text="When do you arrive?" />
            <SelectBox>
              <DatePickerContainer marginRight={10}>
                <DatePicker
                  className="datepickerTrip"
                  showPopperArrow={false}
                  selected={start_date}
                  onChange={date => changeDate('start_date', date)}
                  minDate={moment()
                    .add(2, 'd')
                    .toDate()}
                  placeholderText="Start Date"
                />
              </DatePickerContainer>

              <DatePickerContainer marginLeft={10}>
                <Select
                  placeholder="Arriving Date"
                  name="arriving_time"
                  value={arriving_time}
                  onChange={handleChange}
                >
                  <option value={'Morning'}>Morning</option>
                  <option value={'Afternoon'}>Afternoon</option>
                  <option value={'Evening'}>Evening</option>
                </Select>
              </DatePickerContainer>
            </SelectBox>

            <SecondaryText text="When do you leave?" />
            <SelectBox>
              <DatePickerContainer marginRight={10}>
                <DatePicker
                  className="datepickerTrip"
                  showPopperArrow={false}
                  selected={end_date}
                  onChange={date => changeDate('end_date', date)}
                  minDate={moment()
                    .add(5, 'd')
                    .toDate()}
                  placeholderText="Start Date"
                />
              </DatePickerContainer>

              <DatePickerContainer marginLeft={10}>
                <Select
                  placeholder="Departure Date"
                  name="departure_time"
                  value={departure_time}
                  onChange={handleChange}
                >
                  <option value={'Morning'}>Morning</option>
                  <option value={'Afternoon'}>Afternoon</option>
                  <option value={'Evening'}>Evening</option>
                </Select>
              </DatePickerContainer>
            </SelectBox>
          </Content>

          <StepButtons
            nextDisabled={!start_date || !departure_time || !end_date || !arriving_time}
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(step + 3)}
          />
        </HaveDatesPrimaryBox>
      </HaveDatesBox>
    );
  }
}

export default HaveDatesView;
