import React from 'react';
import styled from 'styled-components';
import Dropzone from 'components/DropZone';
import PlanBox from 'components/Register/PlanBox';
import Modal from 'components/Modal';
import BoutiquePackage from './BoutiquePackage';
import defaultAvatar from 'assets/media/images/avatar.png';
import { push } from 'modules/history';
import { BOUTIQUE_PACKAGE_ID } from 'constants/index';
import config from 'config/appConfig';

const parse = require('html-react-parser');

const InputsContainer = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const InputsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 1400px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media (max-width: 900px) {
    width: 90%;
  }
`;
const TitlePage = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 16px;
  margin-left: 20px;
  text-align: left;
  color: #3e3e3e;
`;
const LabelSettings = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18.5px;
  line-height: 0;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
`;

const InputSettings = styled.input`
  width: 100%;
  height: 82px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: 25px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const ImageProfile = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 70px;
  margin-left: 20px;
  object-fit: cover;
`;

const FlexBox = styled.div`
  display: flex;
`;

const AvatarBox = styled.div`
  width: 225px;
`;

const UserName = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.92px;
  color: #464646;
  text-transform: uppercase;
`;

const SecondaryText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #38b6ff;
  cursor: pointer;
`;

const Button = styled.button`
  border-radius: 5px;
  background-color: #38b6ff;
  color: white;
  background-color: '#38b6ff';
  border: 1px solid #38b6ff;
  width: 150px;
  height: 50px;
  color: 'white';
  margin: 5px;
`;

const DropzoneContainer = styled.div`
  padding: 80px;
`;

const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  flex-wrap: wrap;
  justify-content: center;
`;

const ModalProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: space-between;
  flex-direction: row;
`;

const ModalProduct = styled.div`
  width: 50%;
  padding: 12px;
  justify-content: center;
  @media (max-width: 840px) {
    width: 100%;
  }
`;

const ModalTitle = styled.p`
  font-weight: bold;
  text-align: center;
  margin-top: 25px;
  color: #3c3c3c;
`;

const LearnMore = styled.span`
  font-size: 15px;
  color: #38b6ff;
  cursor: pointer;
`;

const Container = styled.div`
  margin: 10px;
`;

const WhiteBox = styled.div`
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  padding: 20px 10px;
  width: 99%;
  max-height: 400px;
  overflow-x: auto;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  align-items: center;
  margin-top: 15px;
`;

const SettingsView = props => {
  const {
    first_name,
    last_name,
    country,
    city,
    phone,
    avatar,
    changeAvatar,
    product_id,
    alertIsOpen,
    role_code,
  } = props.data;
  const {
    handleChange,
    saveChanges,
    activeDropzone,
    updateImage,
    token,
    selectPlan,
    possibleProducts,
    updateProductModal,
    changeProductsModal,
    productsModal,
    alertDegrade,
    updateDegradeModal,
    requestBoutiquePackage,
    current_product,
  } = props;

  return (
    <Container>
      <FlexBox style={{ justifyContent: 'flex-end', padding: '20px' }}>
        <Button onClick={saveChanges}>Save Changes</Button>
      </FlexBox>
      <TitlePage>PERSONAL INFORMATION</TitlePage>
      <FlexBox>
        <AvatarBox>
          <ImageProfile src={avatar || defaultAvatar} />
        </AvatarBox>
        <div>
          <UserName>
            {first_name} {last_name}
          </UserName>
          <SecondaryText onClick={activeDropzone}>Change Avatar</SecondaryText>
        </div>
      </FlexBox>
      {changeAvatar && (
        <DropzoneContainer>
          <Dropzone
            title="Choose image"
            height={170}
            url={`${config.BASE_URL}/api/v1/upload/avatar`}
            authToken={token}
            handleResponse={updateImage}
            folder={'avatar'}
          />
        </DropzoneContainer>
      )}
      <TitlePage>ACCOUN INFORMATION</TitlePage>
      <InputsContainer>
        <InputsColumn>
          <LabelSettings>First Name</LabelSettings>
          <InputSettings
            placeholder="First Name"
            name="first_name"
            value={first_name}
            onChange={handleChange}
          />
          <LabelSettings>Phone</LabelSettings>
          <InputSettings placeholder="Phone" name="phone" value={phone} onChange={handleChange} />
        </InputsColumn>
        <InputsColumn>
          <LabelSettings>Last Name</LabelSettings>
          <InputSettings
            placeholder="Last Name"
            name="last_name"
            value={last_name}
            onChange={handleChange}
          />
        </InputsColumn>
      </InputsContainer>
      <TitlePage>LOCATION INFORMATION</TitlePage>
      <InputsContainer>
        <InputsColumn>
          <LabelSettings>Country</LabelSettings>
          <InputSettings
            placeholder="Country"
            name="country"
            value={country}
            onChange={handleChange}
          />
        </InputsColumn>
        <InputsColumn>
          <LabelSettings>City</LabelSettings>
          <InputSettings placeholder="City" name="city" value={city} onChange={handleChange} />
        </InputsColumn>
      </InputsContainer>
      {role_code != 'admin' && (
        <>
          <TitlePage>PAYMENT INFORMATION</TitlePage>

          <InputsContainer>
            <InputsColumn>
              <WhiteBox>
                <LabelSettings>
                  <i className={'fas fa-credit-card'} style={{ padding: '9px' }} />
                  Payment Method
                </LabelSettings>
                <Button onClick={() => push('/update-payment-method')}>Update</Button>
              </WhiteBox>
            </InputsColumn>
            <InputsColumn>
              <WhiteBox>
                <LabelSettings>
                  <i className={'fas fa-tags'} style={{ padding: '9px' }} />
                  Subscriptions
                </LabelSettings>
                <Button onClick={() => push('/subscriptions')}>View</Button>
              </WhiteBox>
            </InputsColumn>
            <InputsColumn>
              <WhiteBox>
                <LabelSettings>
                  <i className={'fas fa-file-invoice-dollar'} style={{ padding: '9px' }} />
                  Payment History
                </LabelSettings>
                <Button onClick={() => push('/payment-history')}>View</Button>
              </WhiteBox>
            </InputsColumn>
          </InputsContainer>

          <TitlePage style={{ marginTop: 50, marginBottom: 40 }}>PACKAGES</TitlePage>

          {current_product && (
            <BoutiquePackage
              isSelected={current_product.is_boutique}
              requestBoutiquePackage={requestBoutiquePackage}
              current_product={current_product}
            />
          )}

          <PlansContainer>
            {possibleProducts &&
              possibleProducts
                .filter(p => !p.is_boutique)
                .map((product, key) => (
                  <PlanBox
                    key={key}
                    product={product}
                    product_id={product_id}
                    selectPlan={selectPlan}
                    changeProductsModal={changeProductsModal}
                    productsModal={productsModal}
                  >
                    {product.title}
                  </PlanBox>
                ))}
          </PlansContainer>
        </>
      )}

      <Modal isVisible={productsModal}>
        <>
          <ModalTitle>Choose the Plan That's Right For You</ModalTitle>
          <ModalProducts>
            {possibleProducts.map((product, key) => (
              <ModalProduct key={key}>
                <LearnMore onClick={() => selectPlan(product.id)}>{product.title}.</LearnMore>
                {parse(product.description)}
              </ModalProduct>
            ))}
            <Button onClick={changeProductsModal}>Back to Packages</Button>
            <div>
              <p>
                <span style={{ color: '#38b6ff' }}>*</span> when available
              </p>

              <p>
                <span style={{ color: '#38b6ff' }}>**</span> limited hours
              </p>
            </div>
          </ModalProducts>
        </>
      </Modal>

      <Modal
        closeIcon
        isVisible={alertIsOpen}
        title="New Charges"
        successText="Ok"
        onSuccess={updateProductModal}
      >
        <p style={{ textAlign: 'center' }}>
          Changing plans may incur changes in charges on your credit card.
        </p>
      </Modal>

      <Modal
        closeIcon
        isVisible={alertDegrade}
        title="You cannot do this now."
        successText="Ok"
        onSuccess={updateDegradeModal}
      >
        <p style={{ textAlign: 'center' }}>
          You cannot lower the level of your subscription because you have pending trips to finish.
          Please try again when you no longer have upcoming unfinished trips.
        </p>
      </Modal>
    </Container>
  );
};

export default SettingsView;
