import React from 'react';
import styled from 'styled-components';
import Dropzone from 'components/DropZone';
import ReactMapboxGl, { Layer, Feature, Marker } from 'react-mapbox-gl';
import config from 'config/appConfig';
import marker from 'assets/media/icons/marker.png';
import SelectedButton from 'components/SelectedButton';
import Checkbox from 'components/Login/CheckboxRemember';
import RangeInput from 'components/RangeInput';

const Map = ReactMapboxGl({
  accessToken: config.MAPBOX_ACCESS_TOKEN,
});

const Container = styled.div`
  margin: 25px 10px;
`;

const FlexRow = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const TagsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  max-height: 305px;
  overflow-y: scroll;
  background-color: white;
  padding-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

const InputAccommodation = styled.input`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 25)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const Select = styled.select`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: 25px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const TextAreaAccommodation = styled.textarea`
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: 25px;
  resize: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding: 20px;
`;

const ImageProfile = styled.img`
  height: 95%;
  width: auto;
  margin: auto;
`;

const FlexBox = styled.div`
  display: flex;
`;

const Mark = styled.img`
  width: 40px;
  height: 40px;
`;

const DropzoneContainer = styled.div`
  margin-bottom: 34px;
  width: 100%;
  margin-top: 35px;
  box-shadow: 0px 6px 18px 0 rgba(0,0,0,0.04);
  background-color: #ffffff;
  margin-bottom: 39px;
  height: 255px;
  display: flex;
  justify-content: center;
  position: relative;
}
`;

const CityOption = styled.div`
  background-color: ${props => (props.active ? '#38b6ff' : '#f4f4f4')};
  border: none;
  height: 50px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
`;

const TextOption = styled.p`
  color: ${props => (props.active ? 'white' : '#999999')};
  margin-left: 8px;
  font-family: 'Josefin Sans', sans-serif;
`;

const PlacesList = styled.div`
  background-color: #ffffff;
  padding: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  border-radius: 5px;
`;
const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 140px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: #38b6ff;
`;

const ReplaceImageText = styled.p`
  color: white;
  position: absolute;
  top: 40%;
`;

const LittleText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #464646;
  width: 33%;
  text-align: center;
`;

const AccommodationForm = props => {
  const {
    name,
    image,
    budget,
    address,
    website,
    phone_number,
    description,
    lng,
    lat,
    city_name,
    city_id,
    showCityList,
    showPlaceList,
    addressInput,
    require_reservation,
    aditional_notes,
    rating,
    district,
    high_recomended,
    private_rooms,
    private_bathrooms,
    free_wifi,
    english_speak_staff,
    air_conditioning,
    parking,
    breakfast,
    elevator,
  } = props.data;

  const {
    handleChange,
    updateImage,
    token,
    clickMap,
    handleChangeAddress,
    places,
    selectPlace,
    saveOrUpdate,
    searchCity,
    citiesFiltered,
    selectCity,
    editMode,
    replaceImage,
    updateShowCityList,
    updateShowPlaceList,
    changeTags,
    handleChangeCheckbox,
  } = props;

  return (
    <Container>
      <Header>
        <Button onClick={saveOrUpdate}>
          {editMode ? 'Update Accommodation' : 'Create Accommodation'}
        </Button>
      </Header>
      <FlexRow>
        <FlexColumn marginLeft={10} marginRight={20}>
          <FlexRow>
            <FlexColumn marginRight={10}>
              <Label>Business Name</Label>
              <InputAccommodation
                placeholder="Business Name"
                name="name"
                value={name}
                onChange={handleChange}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn marginRight={10}>
              <Label>Budget</Label>
              <InputAccommodation
                type="number"
                placeholder="Budget"
                name="budget"
                value={budget}
                onChange={handleChange}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn marginBottom={25}>
              <Label>City</Label>
              <InputAccommodation
                marginBottom={'0'}
                placeholder="City"
                name="city_name"
                value={city_name}
                onChange={searchCity}
                onFocus={e => {
                  updateShowCityList(true);
                }}
              />
              {showCityList && citiesFiltered.length > 0 && (
                <PlacesList>
                  {citiesFiltered.slice(0, 3).map((city, key) => (
                    <CityOption
                      active={city.id == city_id}
                      onClick={() => {
                        selectCity(city);
                        updateShowCityList(false);
                      }}
                      key={key}
                    >
                      <TextOption active={city.id == city_id}>{city.name}</TextOption>
                    </CityOption>
                  ))}
                </PlacesList>
              )}
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn marginRight={10}>
              <Label>Address</Label>
              <InputAccommodation
                placeholder="Address"
                name="address"
                value={addressInput}
                onChange={handleChangeAddress}
                onFocus={() => {
                  updateShowPlaceList(true);
                }}
              />
            </FlexColumn>
            <FlexColumn marginLeft={10}>
              <FlexRow>
                <FlexColumn>
                  <Label>Lat</Label>
                  <InputAccommodation
                    disabled
                    placeholder="Lat"
                    name="lat"
                    value={lat.toFixed(5)}
                    onChange={handleChange}
                  />
                </FlexColumn>
                <FlexColumn marginLeft={10}>
                  <Label>Lng</Label>
                  <InputAccommodation
                    disabled
                    placeholder="Lng"
                    name="lng"
                    value={lng.toFixed(5)}
                    onChange={handleChange}
                  />
                </FlexColumn>
              </FlexRow>
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn width={90}>
              {showPlaceList && places.length > 0 && (
                <PlacesList>
                  {places.map((place, key) => (
                    <CityOption
                      active={place.place_name == address}
                      key={key}
                      onClick={() => {
                        selectPlace(place);
                        updateShowPlaceList(false);
                      }}
                    >
                      <TextOption active={place.place_name == address}>
                        {place.place_name}
                      </TextOption>
                    </CityOption>
                  ))}
                </PlacesList>
              )}
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn marginRight={10}>
              <Label>Website</Label>
              <InputAccommodation
                placeholder="Website"
                name="website"
                value={website}
                onChange={handleChange}
              />
            </FlexColumn>
            <FlexColumn marginLeft={10}>
              <Label>Phone Number</Label>
              <InputAccommodation
                placeholder="Phone Number"
                name="phone_number"
                value={phone_number}
                onChange={handleChange}
              />
            </FlexColumn>
          </FlexRow>
          <FlexColumn>
            <Label>District</Label>
            <InputAccommodation
              placeholder="District"
              name="district"
              value={district}
              onChange={handleChange}
            />
          </FlexColumn>
          <FlexRow>
            <Checkbox
              isChecked={require_reservation}
              toggleChange={e => handleChangeCheckbox(e, 'require_reservation')}
              name="require_reservation"
              label={<Label>Is it necessary to make a reservation in advance?</Label>}
            />
          </FlexRow>

          <FlexRow>
            <FlexColumn
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              width={100}
            >
              <Label>Rating:</Label>
              <RangeInput
                name="rating"
                handleChange={handleChange}
                min="1"
                max="5"
                value={rating}
                minQuantityLabel="1"
                maxQuantityLabel="5"
                style={{ width: '50%', margin: 0 }}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <Checkbox
              isChecked={high_recomended}
              toggleChange={e => handleChangeCheckbox(e, 'high_recomended')}
              name="high_recomended"
              label={<Label>High recomended?</Label>}
            />
          </FlexRow>

          <FlexRow>
            <FlexColumn marginRight={10}>
              <Checkbox
                isChecked={private_rooms}
                toggleChange={e => handleChangeCheckbox(e, 'private_rooms')}
                name="private_rooms"
                label={<Label>Provate Rooms?</Label>}
              />
            </FlexColumn>
            <FlexColumn marginRight={10}>
              <Checkbox
                isChecked={private_bathrooms}
                toggleChange={e => handleChangeCheckbox(e, 'private_bathrooms')}
                name="private_bathrooms"
                label={<Label>Private Bath rooms?</Label>}
              />
            </FlexColumn>
            <FlexColumn marginRight={10}>
              <Checkbox
                isChecked={free_wifi}
                toggleChange={e => handleChangeCheckbox(e, 'free_wifi')}
                name="free_wifi"
                label={<Label>Free WIFI?</Label>}
              />
            </FlexColumn>
          </FlexRow>

          <FlexRow>
            <FlexColumn marginRight={10}>
              <Checkbox
                isChecked={english_speak_staff}
                toggleChange={e => handleChangeCheckbox(e, 'english_speak_staff')}
                name="english_speak_staff"
                label={<Label>Staff speak english?</Label>}
              />
            </FlexColumn>
            <FlexColumn marginRight={10}>
              <Checkbox
                isChecked={air_conditioning}
                toggleChange={e => handleChangeCheckbox(e, 'air_conditioning')}
                name="air_conditioning"
                label={<Label>Air Conditioning?</Label>}
              />
            </FlexColumn>
          </FlexRow>

          <FlexRow>
            <FlexColumn marginRight={10}>
              <Checkbox
                isChecked={parking}
                toggleChange={e => handleChangeCheckbox(e, 'parking')}
                name="parking"
                label={<Label>Parking?</Label>}
              />
            </FlexColumn>
            <FlexColumn marginRight={10}>
              <Checkbox
                isChecked={breakfast}
                toggleChange={e => handleChangeCheckbox(e, 'breakfast')}
                name="breakfast"
                label={<Label>Breakfast?</Label>}
              />
            </FlexColumn>
          </FlexRow>

          <FlexRow>
            <FlexColumn marginRight={10}>
              <Checkbox
                isChecked={elevator}
                toggleChange={e => handleChangeCheckbox(e, 'elevator')}
                name="elevator"
                label={<Label>Elevator?</Label>}
              />
            </FlexColumn>
          </FlexRow>

          <FlexRow>
            <FlexColumn width={100}>
              <Label>Description</Label>
              <TextAreaAccommodation
                placeholder="Description"
                name="description"
                value={description}
                onChange={handleChange}
              />
            </FlexColumn>
          </FlexRow>

          <FlexRow>
            <FlexColumn width={100}>
              <Label>Aditional notes</Label>
              <TextAreaAccommodation
                placeholder="Aditional notes"
                name="aditional_notes"
                value={aditional_notes}
                onChange={handleChange}
              />
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
        <FlexColumn width={90} marginLeft={20}>
          <FlexRow>
            <DropzoneContainer>
              {!image && (
                <Dropzone
                  title="Choose image"
                  height={255}
                  url={`${config.BASE_URL}/api/v1/upload/accommodation`}
                  authToken={token}
                  handleResponse={updateImage}
                  folder={'accommodation'}
                />
              )}
              {image && (
                <>
                  <ImageProfile src={image} />
                  {
                    <ReplaceImageText onClick={replaceImage}>
                      Click to Replace Image
                    </ReplaceImageText>
                  }
                </>
              )}
            </DropzoneContainer>
          </FlexRow>
          <FlexRow>
            <Map
              onClick={clickMap}
              style="mapbox://styles/mapbox/streets-v9"
              containerStyle={{
                width: '100%',
                height: '580px',
              }}
              center={[lng, lat]}
              zoom={[15]}
            >
              <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
                <Feature coordinates={[lng, lat]} />
              </Layer>
              <Marker coordinates={[lng, lat]}>
                <Mark src={marker} />
              </Marker>
            </Map>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </Container>
  );
};

export default AccommodationForm;
