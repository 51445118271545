import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  paymentMethods: [],
  loading: false,
};

export default {
  paymentMethodReducer: handleActions(
    {
      [ActionTypes.GET_PAYMENT_METHODS](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.SAVE_PAYMENT_METHODS](state, action) {
        return {
          ...state,
          paymentMethods: action.data,
          loading: false,
        };
      },
    },
    appState,
  ),
};
