import React, { Component } from 'react';
import config from '../../config/appConfig';
import { GoogleLogin } from 'react-google-login';
import GoogleButton from './GoogleButton';

export default class GoogleLoginButton extends Component {
  responseGoogle = response => {
    if (response.error) {
      console.log('Error in google login', response.error);
      return;
    }
    const { googleLogin } = this.props;
    googleLogin(response);
  };
  render() {
    return (
      <GoogleLogin
        clientId={config.WEB_CLIENT_ID_GOOGLE}
        autoLoad={false}
        onSuccess={this.responseGoogle}
        onFailure={this.responseGoogle}
        cookiePolicy={'single_host_origin'}
        render={renderProps => <GoogleButton renderProps={renderProps} />}
      />
    );
  }
}
