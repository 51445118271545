import React, { Component } from 'react';
import RegionsListView from './RegionsListView';
import { connect } from 'react-redux';
import * as regionsActions from 'actions/regionsActions';

class RegionsListContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10, removeModalIsOpen: false, regionSelected: null };

  componentDidMount() {
    const {
      getAllRegions,
      match: { params },
    } = this.props;
    const { currentPage, elementsPerPage } = this.state;

    if (params.country_id) {
      getAllRegions(currentPage, elementsPerPage, params.country_id);
    } else {
      getAllRegions(currentPage, elementsPerPage);
    }
  }

  changePage = currentPage => {
    const { elementsPerPage } = this.state;

    const {
      getAllRegions,
      match: { params },
    } = this.props;

    if (params.country_id) {
      getAllRegions(currentPage, elementsPerPage, params.country_id);
    } else {
      getAllRegions(currentPage, elementsPerPage);
    }

    this.setState({ currentPage });
  };

  toggleModal = () => {
    this.setState({ removeModalIsOpen: !this.state.removeModalIsOpen });
  };

  selectRegion = regionSelected => {
    this.setState({ regionSelected }, () => this.toggleModal());
  };

  removeRegion = () => {
    const { removeRegions } = this.props;
    const { regionSelected } = this.state;
    removeRegions(regionSelected);
    this.toggleModal();
  };

  render() {
    const {
      regionsCount,
      regions,
      loading,
      match: { params },
    } = this.props;

    const { currentPage, elementsPerPage, removeModalIsOpen } = this.state;
    return (
      <RegionsListView
        {...this.props}
        regions={regions}
        currentPage={currentPage}
        changePage={this.changePage}
        regionsCount={regionsCount}
        elementsPerPage={elementsPerPage}
        loading={loading}
        removeModalIsOpen={removeModalIsOpen}
        toggleModal={this.toggleModal}
        removeRegion={this.removeRegion}
        selectRegion={this.selectRegion}
        country_id={params.country_id}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    regions: state.regionsReducer.regions,
    regionsCount: state.regionsReducer.regionsCount,
    loading: state.regionsReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAllRegions: (page, elementsPerPage, country_id) =>
      dispatch(regionsActions.getAllRegions(page, elementsPerPage, country_id)),
    removeRegions: id => dispatch(regionsActions.removeRegions(id)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegionsListContainer);
