import { put, call } from 'redux-saga/effects';
import * as registerActions from '../actions/registerActions';
import * as loginActions from '../actions/loginActions';
import * as appActions from '../actions/app';
import { replace } from 'modules/history';

export function* registerNewUser(api, action) {
  const response = yield call(api.registerRequest, action.newUser);
  if (response.status == 200) {
    yield call(api.setAuthorization, response.data.token);
    yield put(loginActions.onLoginResponse(response));
    yield put(registerActions.registerFinished());
    yield replace('/register-success');
  } else {
    yield put(loginActions.loginFailed());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
