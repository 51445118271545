import { put, call } from 'redux-saga/effects';
import * as authenticPlanActions from '../actions/authenticPlanActions';
import * as tripActions from '../actions/tripActions';
import * as appActions from '../actions/app';
import { goBack } from 'modules/history';

export function* createAuthenticPlanRequest(api, action) {
  const response = yield call(api.createAuthenticPlanRequest, action.data);

  if (response.status == 200) {
    yield put(tripActions.saveTrip({ authenticPlan: response.data }));

    yield put(appActions.showAlert('At activated.', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getAutehnticPlanRequest(api, action) {
  const response = yield call(api.getAutehnticPlanRequest, action.id);
  if (response.status == 200) {
    yield put(authenticPlanActions.getAuthenticPlanSuccess(response.data));
  }
}

export function* updateAutehnticPlanRequest(api, action) {
  const response = yield call(api.updateAutehnticPlanRequest, action.id, action.data);
  if (response.status == 200) {
    yield goBack();
    yield put(appActions.showAlert('Authentic travel  plan updated.', { variant: 'success' }));
  }
}
