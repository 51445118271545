import { selectTravelPersonality } from 'components/ExplanationResults/Steps/StepFields/ExplanationResutls';

export const calculateTravelPersonalityResult = travelPersonalities => {
  // const { travelPersonalities } = this.props.diagnostics;
  const travelPersonalityResult = sumObjetValues(travelPersonalities);
  if (travelPersonalityResult <= 14) {
    return selectTravelPersonality(1);
  } else if (travelPersonalityResult >= 15 && travelPersonalityResult <= 17) {
    return selectTravelPersonality(2);
  } else if (travelPersonalityResult >= 18 && travelPersonalityResult <= 19) {
    return selectTravelPersonality(3);
  } else if (travelPersonalityResult >= 20 && travelPersonalityResult <= 21) {
    return selectTravelPersonality(4);
  } else if (travelPersonalityResult >= 22 && travelPersonalityResult <= 24) {
    return selectTravelPersonality(5);
  } else {
    return selectTravelPersonality(6);
  }
};

export const calculateCoreStrengthsResult = ({ strengths }) => {
  //const { strengths } = this.props.diagnostics;

  const coreStrengths = [
    { name: 'appreciation_good', label: 'Appreciation For Good' },
    { name: 'communal_duty', label: 'Communal Duty' },
    { name: 'courage', label: 'Courage' },
    { name: 'creativity', label: 'Creativity' },
    { name: 'curiosity', label: 'Curiosity' },
    { name: 'fairness', label: 'Fairness' },
    { name: 'forgiveness', label: 'Forgiveness' },
    { name: 'gratitude', label: 'Gratitude' },
    { name: 'humility', label: 'Humility' },
    { name: 'integrity', label: 'Integrity' },
    { name: 'kindness', label: 'Kindness' },
    { name: 'leadership', label: 'Leadership' },
    { name: 'love', label: 'Love' },
    { name: 'open_mindedness', label: 'Open-Mindedness' },
    { name: 'optimism', label: 'Optimism' },
    { name: 'passion_learning', label: 'Passion For Learning' },
    { name: 'perseverance', label: 'Perseverance' },
    { name: 'prudence', label: 'Prudence' },
    { name: 'self_control', label: 'Self-Control' },
    { name: 'sense_humor', label: 'Sense Of Humor' },
    { name: 'social_awarenes', label: 'Social Awarenes' },
    { name: 'spirituality', label: 'Spirituality' },
    { name: 'vibrancy', label: 'Vibrancy' },
    { name: 'wisdom', label: 'Wisdom' },
  ];

  let coreStrengthsResults = coreStrengths.map(obj => {
    const { name, label } = obj;
    const value = sumObjetValues(filterForName(strengths, name));
    return { value, label };
  });
  coreStrengthsResults.sort(orderValues('value', 'desc'));
  return coreStrengthsResults.splice(0, 5);
};

export const calculateWellBeingResults = ({ wellBeings }) => {
  const wellBeingNames = [
    { name: 'achievement', maxValue: 30, label: 'Achievement' },
    { name: 'connections', maxValue: 30, label: 'Connections' },
    { name: 'fitness', maxValue: 30, label: 'Fitness' },
    { name: 'growth', maxValue: 30, label: 'Growth Emotions' },
    { name: 'immersion', maxValue: 30, label: 'Active Immersion' },
    { name: 'overall', maxValue: 10, label: 'Overall well-being' },
    { name: 'significance', maxValue: 30, label: 'Significance' },
    { name: 'stunting', maxValue: 30, label: 'Stunting Emotions' },
  ];

  return wellBeingNames.map(obj => {
    const { name, maxValue, label } = obj;
    const percent = calculatePercent(wellBeings, name, maxValue);
    return { percent, label };
  });
};

export const calculateFundationalStrengthsResult = ({ strengths }) => {
  //const { strengths } = this.props.diagnostics;

  const fundationalStrengths = [
    'mindfuldness',
    'confidence',
    'resilience',
    'anti_fragility',
    'purpose',
  ];

  return fundationalStrengths.map(name => sumObjetValues(filterForName(strengths, name)));
};

const calculatePercent = (objet, filter, maxValue) =>
  (sumObjetValues(filterForName(objet, filter)) * 100) / maxValue;

const sumObjetValues = objet => {
  let sum = 0;
  for (let el in objet) {
    if (objet.hasOwnProperty(el)) {
      sum += parseFloat(objet[el]);
    }
  }

  return sum;
};

const filterForName = (objet, name) => {
  return Object.keys(objet)
    .filter(value => value.startsWith(name))
    .reduce((obj, key) => {
      obj[key] = objet[key];
      return obj;
    }, {});
};

const orderValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};
