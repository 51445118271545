import styled from 'styled-components';

export default styled.button`
  border-radius: 5px;
  color: white;
  background-color: #ff00002b;
  border: 1px solid #ff00002b;
  width: 150px;
  height: 50px;
  color: red;
  margin: 0px 5px;
  cursor: pointer;
`;
