import React, { Component } from 'react';
import TheCallingView from './JournalTheCallingView';
import { connect } from 'react-redux';
import * as journalActions from 'actions/journalActions';
import { selectFields } from './StepFields/TheCalling';

class TheCalling extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      getCallingRequest,
      match: { params },
    } = this.props;
    getCallingRequest(params.id, params.day);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.calling && this.props.calling) ||
      (prevProps.calling && prevProps.calling != this.props.calling)
    ) {
      const { message_morning, message_today, message_evening } = this.props.calling;
      this.setState({ message_morning, message_today, message_evening });
    }
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  saveDay = () => {
    const {
      createDayCallingRequest,
      match: { params },
      day,
      calling: { id },
    } = this.props;
    const { message_morning, message_today, message_evening } = this.state;

    createDayCallingRequest(id, {
      message_morning,
      message_today,
      message_evening,
      status: 'completed',
    });
  };

  render() {
    const { day, calling, journal } = this.props;
    if (!calling) return null;
    const { message_morning, message_today, message_evening } = this.state;
    const stepFields = selectFields(+day);
    const daysQuantity = journal.journalCallings.length;

    return (
      <TheCallingView
        {...this.props}
        saveDay={this.saveDay}
        stepFields={stepFields}
        currentDay={day}
        message_morning={message_morning}
        message_today={message_today}
        message_evening={message_evening}
        handleChange={this.handleChange}
        date={calling.date}
        daysQuantity={daysQuantity}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    calling: state.journalReducer.calling,
    journal: state.journalReducer.journal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDayCallingRequest: (id, calling) =>
      dispatch(journalActions.createDayCallingRequest(id, calling)),
    getCallingRequest: (journal_id, day) =>
      dispatch(journalActions.getCallingRequest({ journal_id, day })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TheCalling);
