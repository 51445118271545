import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import Input from 'components/Input';
import Dropzone from 'components/DropZone';
import RadioInput from 'components/RadioInput';
import { FOODIEDESTINATION } from 'constants/index';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

class FoodieDestinationView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { step, changeStep, handleChange, foodie_destination } = this.props;

    return (
      <Container>
        <PrimaryText text='How important is it for the places you visit to be "foodie" destinations?' />

        <RadioInput
          name="foodie_destination"
          options={FOODIEDESTINATION}
          handleChange={handleChange}
          currentValue={foodie_destination}
          verticalAlign
        />

        <StepButtons
          nextDisabled={!foodie_destination}
          backStep={() => changeStep(step - 1)}
          nextStep={() => changeStep(step + 1)}
        />
      </Container>
    );
  }
}

export default FoodieDestinationView;
