import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { replace } from 'modules/history';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 15px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  opacity: ${props => (props.disabled ? '0.4' : '1')};
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  height: 100%;
  align-items: center;
  padding: 0px 5px 0px 15px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.align ? props.align : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
  color: ${props => (props.color ? props.color : 'initial')};
`;
const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 10px;
`;

const Title = styled.p`
  font-size: 25px;
  font-weight: 400;
`;

const Subtitle = styled.p`
  font-size: 20px;
  font-weight: 200;
  color: #9e9e9e;
`;
const TBody = styled.div``;

class JournalDayList extends Component {
  renderJournalDay(title, day, status, date, dayType, step, journal_id) {
    const disabled =
      moment(date)
        .startOf('day')
        .diff(moment(Date.now()).startOf('day'), 'days') > 0;

    return (
      <Item disabled={disabled}>
        <SectionContainer width={'20%'} align="left">
          <Secondary>Title</Secondary>
          <Primary>{title}</Primary>
        </SectionContainer>
        <SectionContainer width={'15%'} align="left">
          <Secondary>Type</Secondary>
          <Primary>{dayType}</Primary>
        </SectionContainer>
        <SectionContainer width={'15%'} align="left">
          <Secondary>Date</Secondary>
          <Primary>{moment(date).format('DD MMM YYYY')}</Primary>
        </SectionContainer>
        <SectionContainer width={'32%'} align="left">
          <Secondary>Status</Secondary>
          {status == 'pending' && <Primary color="#9e9e9e">Not Completed</Primary>}
          {status == 'completed' && <Primary color="#00b4d5">Completed</Primary>}
        </SectionContainer>

        <SectionContainer width={'17%'} align="right">
          <Primary className="link">
            <Link
              color="#00b4d5"
              to={
                disabled ? `/journal/${journal_id}` : `/journal-step/${journal_id}/${step}/${day}`
              }
            >
              {day && status == 'pending' && (
                <Primary color="#00b4d5">
                  <i style={{ marginRight: '5px', color: '#00b4d5' }} className="fas fa-file-alt" />
                  Write
                </Primary>
              )}
              {day && status == 'completed' && (
                <Primary className="link">
                  <Link
                    color="#00b4d5"
                    to={
                      disabled
                        ? `/journal/${journal_id}`
                        : `/journal-step/${journal_id}/${step}/${day}`
                    }
                  >
                    <Primary color="#00b4d5">
                      <i style={{ marginRight: '2px', color: '#00b4d5' }} className="fas fa-eye" />{' '}
                      View
                    </Primary>
                  </Link>
                </Primary>
              )}
              {!day && (
                <Primary className="link">
                  <Link color="#00b4d5" to={`/journal-step/${journal_id}/${step}/${day}`}>
                    <Primary color="#00b4d5">
                      <i
                        style={{ marginRight: '5px', color: '#00b4d5' }}
                        className="fas fa-file-alt"
                      />
                      Read
                    </Primary>
                  </Link>
                </Primary>
              )}
            </Link>
          </Primary>
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const {
      journal: {
        first_introduction_completed,
        second_introduction_completed,
        journalCallings,
        journalDeaptures,
        journalPreparingReturns,
        journalReturns,
        journalRoads,
        id,
        step,
      },
    } = this.props;
    const testStartDate =
      (journalCallings && journalCallings.length > 0 && journalCallings[0].date) ||
      (journalDeaptures && journalDeaptures.length > 0 && journalDeaptures[0].date);
    return (
      <GridContainer>
        <Header>
          <Primary
            style={{ cursor: 'pointer' }}
            onClick={() => replace('/trip-content')}
            color="#00b4d5"
          >
            ← Back to journey list
          </Primary>
          <Title>The Journal</Title>
        </Header>
        <TBody>
          <Subtitle>Introductions</Subtitle>
          {this.renderJournalDay(
            'Authentic Travel Journal',
            null,
            first_introduction_completed ? 'completed' : 'pending',
            testStartDate,
            'Introduction',
            1,
            id,
          )}
          {this.renderJournalDay(
            'Authentic Travel Journal',
            null,
            second_introduction_completed ? 'completed' : 'pending',
            testStartDate,
            'Introduction',
            2,
            id,
          )}

          <Subtitle>Journal</Subtitle>
          {(journalCallings || []).map(calling => {
            return this.renderJournalDay(
              `Day ${calling.day}`,
              calling.day,
              calling.status,
              calling.date,
              'The Calling',
              3,
              id,
            );
          })}
          {(journalDeaptures || []).map(deapture => {
            return this.renderJournalDay(
              `Day ${deapture.day}`,
              deapture.day,
              deapture.status,
              deapture.date,
              'The Deapture',
              4,
              id,
            );
          })}
          {(journalRoads || []).map(road => {
            return this.renderJournalDay(
              `Day ${road.day}`,
              road.day,
              road.status,
              road.date,
              'The Road',
              5,
              id,
            );
          })}
          {(journalPreparingReturns || []).map(preparinReturn => {
            return this.renderJournalDay(
              `Day ${preparinReturn.day}`,
              preparinReturn.day,
              preparinReturn.status,
              preparinReturn.date,
              'Preparing to return',
              6,
              id,
            );
          })}
          {(journalReturns || []).map(returns => {
            return this.renderJournalDay(
              `Day ${returns.day}`,
              returns.day,
              returns.status,
              returns.date,
              'Return',
              7,
              id,
            );
          })}
        </TBody>
      </GridContainer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  null,
  mapDispatchToProps,
)(JournalDayList);
