import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  meetings: [],
  meeting: null,
  loadingMeeting: false,
};

export default {
  meetingReducer: handleActions(
    {
      [ActionTypes.SAVE_ME_MEETINGS](state, action) {
        return {
          ...state,
          meetings: action.meetings,
          meetingsCount: action.meetingsCount,
          loadingMeeting: false,
        };
      },
      [ActionTypes.GET_CUSTOMER_MEETINGS](state, action) {
        return {
          ...state,
          loadingMeeting: true,
        };
      },
      [ActionTypes.GET_MEETING_SUCCESS](state, action) {
        return {
          ...state,
          meeting: action.data,
          loadingMeeting: false,
        };
      },
      [ActionTypes.GET_INITIAL_MEETING_REQUEST](state, action) {
        return {
          ...state,
          loadingMeeting: true,
        };
      },
      [ActionTypes.SAVE_MEETING_SUCCESS](state, action) {
        return {
          ...state,
          meetings: [...state.meetings, action.data],
        };
      },
    },
    appState,
  ),
};
