import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';

// some of this code is a variation on https://jsfiddle.net/cmyker/u6rr5moq/
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart;
    var width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx;

    var fontSize = (height / 500).toFixed(2);
    ctx.font = fontSize + 'em sans-serif';
    ctx.textBaseline = 'middle';
    ctx.overflowWrap = 'breakWord';

    var text = 'Your Core Strengths',
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;
    ctx.fillText(text, textX, textY + 20);
  },
});

const data = {
  datasets: [
    {
      backgroundColor: ['#145F91', '#001837', '#6DC9FF', '#22AEFF', '#1A85C3'],
      borderColor: '#13a2f4',
      borderWidth: 1,
      hoverBackgroundColor: '#999999',
      hoverBorderColor: 'grey',
    },
  ],
};

const CoreStrengthsChart = props => {
  data.datasets[0].data = props.coreStrengthsResult.map(core => core.value);
  data.labels = props.coreStrengthsResult.map(core => core.label);
  return <Doughnut data={data} weight={10} />;
};

export default CoreStrengthsChart;
