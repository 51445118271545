import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import SelectedButton from '../../SelectedButton';
import PrimaryText from '../PrimaryText';
import { MONTH_RANGES } from 'constants/index';
import { Formik } from 'formik';

const TravelingForBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TravelingForPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
`;

const Container = styled.div`
  height: 90%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

class SelectDatesRangeView extends Component {
  render() {
    const { step, know_dates, changeStep, month_ranges } = this.props;

    return (
      <TravelingForBox>
        <TravelingForPrimaryBox>
          <PrimaryText text="Please select all that apply." />

          <Formik enableReinitialize initialValues={{ month_ranges }} isInitialValid={true}>
            {({ values, handleChange, errors, handleSubmit, setFieldValue }) => {
              const changeMonthRanges = value => {
                const month_ranges = [...values.month_ranges];
                const exist = month_ranges.indexOf(value);
                if (exist != -1) {
                  month_ranges.splice(exist, 1);
                } else {
                  month_ranges.push(value);
                }
                setFieldValue('month_ranges', month_ranges);
              };
              return (
                <Container>
                  <Content>
                    {MONTH_RANGES.map(monthRange => (
                      <SelectedButton
                        text={monthRange}
                        onClick={() => changeMonthRanges(monthRange)}
                        active={values.month_ranges.includes(monthRange)}
                      />
                    ))}
                  </Content>

                  <StepButtons
                    nextDisabled={!month_ranges}
                    backStep={() => changeStep(step - 2, values)}
                    nextStep={() => changeStep(step + 1, values)}
                  />
                </Container>
              );
            }}
          </Formik>
        </TravelingForPrimaryBox>
      </TravelingForBox>
    );
  }
}

export default SelectDatesRangeView;
