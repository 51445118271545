import React, { Component } from 'react';
import SuggestionsView from './SuggestionsView';
import { connect } from 'react-redux';
import * as SuggestionActions from 'actions/suggestionActions';

class SuggestionsContainer extends Component {
    state = { currentPage: 1, elementsPerPage: 10, countrySearchableText: '' };

    componentDidMount() {
        const { currentPage, elementsPerPage } = this.state;
        const { onGetCountries } = this.props;
        onGetCountries(currentPage, elementsPerPage);
    }

    changePage = currentPage => {
        const { elementsPerPage } = this.state;
        const { onGetCountries } = this.props;
        onGetCountries(currentPage, elementsPerPage);
        this.setState({ currentPage });
    };

    searchCountry = event => {
        const text = event.target.value;
        this.setState({ countrySearchableText: text }, () => {
            const { currentPage, elementsPerPage, countrySearchableText } = this.state;
            const { onGetCountries } = this.props;
            onGetCountries(currentPage, elementsPerPage, countrySearchableText);
        })
    }

    render() {
        const { countries, countriesCount, loading } = this.props;
        const { currentPage, elementsPerPage, countrySearchableText } = this.state;
        return (
            <SuggestionsView
                countries={countries}
                countriesCount={countriesCount}
                currentPage={currentPage}
                elementsPerPage={elementsPerPage}
                changePage={this.changePage}
                loading={loading}
                countrySearchableText={countrySearchableText}
                searchCountry={this.searchCountry}
                {...this.props}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        countries: state.suggestionReducer.countries,
        countriesCount: state.suggestionReducer.countriesCount,
        loading: state.suggestionReducer.loading,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        onGetCountries: (page, elementsPerPage, search) =>
            dispatch(SuggestionActions.getCountriesRequest(page, elementsPerPage, search)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SuggestionsContainer);
