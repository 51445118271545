import { ActionTypes } from 'constants/index';

export function getMeTransactions() {
  return {
    type: ActionTypes.GET_ME_TRANSACTIONS,
  };
}

export function saveMeTransactions(transactions) {
  return {
    type: ActionTypes.SAVE_ME_TRANSACTIONS,
    transactions,
  };
}
