import React, { Component } from 'react';
import styled from 'styled-components';
import Tabs from 'components/Tabs';
import VisitSuggestionForm from './VisitSuggestionForm';
import AcommodationPricingsForm from './AcommodationPricings';
import AverageDailyExpenses from './AverageDailyExpenses';
import ClimatesList from './ClimatesList';
import LifecycleForm from 'components/Suggestions/Lifecycle';
import CuisineSpecialityForm from 'components/Suggestions/CuisineSpeciality';
import Loader from 'components/Loader';
import Button from 'components/Button';

const Container = styled.div`
  overflow-x: auto;
  display: block;
  max-width: 100%;
  padding: 25px;
`;
const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  border-radius: 5px;
`;
const Avatar = styled.img`
  border-radius: 5px;
  width: 300px;
  height: 200px;
`;
const Primary = styled.p`
  font-size: 18px;
  margin: 0px;
`;
const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 18px;
  margin: 0px 5px 0px 0px;
`;
const FlexRow = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;
class CreateSuggestionForm extends Component {
  render() {
    const {
      tabs,
      token,
      cities,
      climates,
      country,
      isEditing,
      changePage,
      searchCity,
      getClimate,
      setClimate,
      currentPage,
      saveClimate,
      editClimate,
      climatesCount,
      renderAddCity,
      handleAddCity,
      handleModalExcel,
      renderModalExcel,
      elementsPerPage,
      onRemoveClimate,
      citySearchableText,
      loading,
      activeTab,
      weather,
      loadingData,
      handleisEditing,
      dailyExpenses,
      accommodationPricing,
      saveVisitSuggestion,
      editVisitSuggestion,
      saveAverageDailyExpenses,
      saveAccommodationPricing,
      getLifecyclesRequest,
      searchLifeCycle,
      cuisineSearchableText,
      searchCuisine,
      getLifecycleRequest,
      removeLifecycleRequest,
      saveLifecycle,
      onShowLifecycleForm,
      lifecycles,
      lifecycle,
      lifecycleCount,
      lifecycleCurrentPage,
      loadingLifecycle,
      showLifecycleForm,
      getCuisineSpecialitiesRequest,
      getCuisineSpecialityRequest,
      removeCuisineSpecialityRequest,
      saveCuisineSpeciality,
      onShowCuisineSpecialityForm,
      cuisineSpecialities,
      cuisineSpeciality,
      cuisineSpecialityCount,
      cuisineSpecialityCurrentPage,
      loadingCuisineSpeciality,
      showCuisineSpecialityForm,
      lifecyclesearchable,
      country_id,
      setCountryId,
    } = this.props;
    return (
      <>
        {loading ? (
          <LoaderContainer>
            <Loader type="pulse" size={125} />
          </LoaderContainer>
        ) : (
          <>
            <FlexRow>
              <Button style={{ marginBottom: '20px' }} onClick={() => setCountryId(null)}>
                Back
              </Button>
            </FlexRow>
            <DetailsContainer>
              <FlexRow>
                <Avatar src={country.flag} />
                <FlexColumn style={{ margin: 20 }}>
                  <FlexRow style={{ marginBottom: 40 }}>
                    <Secondary>Name: </Secondary>
                    <Primary>{country.name}</Primary>
                  </FlexRow>
                  <FlexRow style={{ marginBottom: 40 }}>
                    <Secondary>Timezone: </Secondary>
                    <Primary>{country.timezone}</Primary>
                  </FlexRow>
                  <FlexRow>
                    <Secondary>Latitude Capital: </Secondary>
                    <Primary>{country.latitude_capital}°</Primary>
                  </FlexRow>
                </FlexColumn>
                <FlexColumn style={{ margin: 20 }}>
                  <FlexRow style={{ marginBottom: 40 }}>
                    <Secondary>Capital: </Secondary>
                    <Primary>{country.capital}</Primary>
                  </FlexRow>
                  <FlexRow style={{ marginBottom: 40 }}>
                    <Secondary>Currency: </Secondary>
                    <Primary>{country.currency}</Primary>
                  </FlexRow>
                  <FlexRow>
                    <Secondary>Longitude Capital: </Secondary>
                    <Primary>{country.longitude_capital}°</Primary>
                  </FlexRow>
                </FlexColumn>
                <FlexColumn style={{ margin: 20 }}>
                  <FlexRow style={{ marginBottom: 40 }}>
                    <Secondary>Region: </Secondary>
                    <Primary>{country.subregion}</Primary>
                  </FlexRow>
                  <FlexRow>
                    <Secondary>Phone code: </Secondary>
                    <Primary>{country.phone_code}</Primary>
                  </FlexRow>
                </FlexColumn>
              </FlexRow>
            </DetailsContainer>
            <Tabs tabs={tabs} active={activeTab} />
            {loadingData ? (
              <LoaderContainer>
                <Loader type="pulse" size={125} />
              </LoaderContainer>
            ) : (
              <>
                {activeTab === 'Climates' && (
                  <ClimatesList
                    viewMode={true}
                    token={token}
                    cities={cities}
                    country={country}
                    climates={climates}
                    isEditing={isEditing}
                    changePage={changePage}
                    searchCity={searchCity}
                    getClimate={getClimate}
                    setClimate={setClimate}
                    saveClimate={saveClimate}
                    editClimate={editClimate}
                    currentPage={currentPage}
                    renderAddCity={renderAddCity}
                    handleAddCity={handleAddCity}
                    renderModalExcel={renderModalExcel}
                    handleModalExcel={handleModalExcel}
                    climatesCount={climatesCount}
                    onRemoveClimate={onRemoveClimate}
                    handleisEditing={handleisEditing}
                    elementsPerPage={elementsPerPage}
                    citySearchableText={citySearchableText}
                    searchLifeCycle={searchLifeCycle}
                  />
                )}
                {activeTab === 'Visit Suggestions' && (
                  <VisitSuggestionForm
                    viewMode={true}
                    token={token}
                    country={country}
                    weather={weather}
                    renderModalExcel={renderModalExcel}
                    handleModalExcel={handleModalExcel}
                    saveVisitSuggestion={saveVisitSuggestion}
                    editVisitSuggestion={editVisitSuggestion}
                  />
                )}
                {activeTab === 'AVG Daily Expenses' && (
                  <AverageDailyExpenses
                    viewMode={true}
                    token={token}
                    country={country}
                    dailyExpenses={dailyExpenses}
                    renderModalExcel={renderModalExcel}
                    handleModalExcel={handleModalExcel}
                    saveAverageDailyExpenses={saveAverageDailyExpenses}
                  />
                )}
                {activeTab === 'Accommodation Pricings' && (
                  <AcommodationPricingsForm
                    viewMode={true}
                    token={token}
                    country={country}
                    renderModalExcel={renderModalExcel}
                    handleModalExcel={handleModalExcel}
                    accommodationPricing={accommodationPricing}
                    saveAccommodationPricing={saveAccommodationPricing}
                  />
                )}
                {activeTab === 'Lifecycle' && (
                  <LifecycleForm
                    viewMode={true}
                    token={token}
                    country={country}
                    renderModalExcel={renderModalExcel}
                    handleModalExcel={handleModalExcel}
                    find={getLifecyclesRequest}
                    findOne={getLifecycleRequest}
                    remove={removeLifecycleRequest}
                    save={saveLifecycle}
                    switchFormShow={onShowLifecycleForm}
                    list={lifecycles}
                    item={lifecycle}
                    count={lifecycleCount}
                    currentPage={lifecycleCurrentPage}
                    loading={loadingLifecycle}
                    showForm={showLifecycleForm}
                    countryId={country_id}
                    lifecyclesearchable={lifecyclesearchable}
                    searchLifeCycle={searchLifeCycle}
                  />
                )}

                {activeTab === 'Cuisine specialities' && (
                  <CuisineSpecialityForm
                    token={token}
                    viewMode={true}
                    country={country}
                    renderModalExcel={renderModalExcel}
                    handleModalExcel={handleModalExcel}
                    cuisineSearchableText={cuisineSearchableText}
                    searchCuisine={searchCuisine}
                    find={getCuisineSpecialitiesRequest}
                    findOne={getCuisineSpecialityRequest}
                    remove={removeCuisineSpecialityRequest}
                    save={saveCuisineSpeciality}
                    switchFormShow={onShowCuisineSpecialityForm}
                    list={cuisineSpecialities}
                    item={cuisineSpeciality}
                    count={cuisineSpecialityCount}
                    currentPage={cuisineSpecialityCurrentPage}
                    loading={loadingCuisineSpeciality}
                    showForm={showCuisineSpecialityForm}
                    countryId={country_id}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}
export default CreateSuggestionForm;
