import React from 'react';
import styled from 'styled-components';
import Master from '../../assets/media/icons/master.png';
import Visa from '../../assets/media/icons/visa.png';
import Cirrus from '../../assets/media/icons/cirrus.png';
import Maestro from '../../assets/media/icons/maestro.png';
import Americanexpress from '../../assets/media/icons/americanexpress.png';

export const List = styled.div`
  display: flex;
  align-items: center;
`;

export const Card = styled.img`
  margin: 8px;
  align-items: center;
  width: auto;
  height: 30px;
`;
const CardList = () => (
  <List>
    <Card src={Visa} />
    <Card src={Master} />
    <Card src={Maestro} />
    <Card src={Cirrus} />
    <Card src={Americanexpress} />
  </List>
);

export default CardList;
