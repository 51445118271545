import React, { Component } from 'react';
import styled from 'styled-components';
import StepContainer from 'components/StepContainer';
import JournalDayStep from 'components/JournalDayStep';
import { goBack } from 'modules/history';

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const TextArea = styled.textarea`
border-style: solid;
    border-width: 1px;
    border: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #bebcbc;
    height: 100px;
    resize: none;
    padding: 10px;
    width: 100%;
}
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const Label = styled.p`
  font-size: 13px;
  line-height: 0;
  color: #999999;
`;

const TextAreaContainer = styled.div`
  width: 33%;
  margin: 5px;
`;

class PreparingToReturnView extends Component {
  render() {
    const {
      message_new_learn,
      message_new_life,
      message_new_ability,
      handleChange,
      currentDay,
      date,
    } = this.props;
    return (
      <StepContainer closeIcon width={'70%'} closeAction={() => goBack()}>
        <JournalDayStep
          {...this.props}
          headerTitle="Preparing To Return"
          daysQuantity={2}
          date={date}
        >
          {currentDay == 1 && (
            <FlexBox>
              <TextAreaContainer>
                <Label>
                  While on my journey, these were the three most important things I learner
                </Label>
                <TextArea
                  placeholder="ex. something"
                  name="message_new_learn"
                  value={message_new_learn}
                  onChange={handleChange}
                />
              </TextAreaContainer>
              <TextAreaContainer>
                <Label>
                  Based on my journey, this is how I intend to live my life diferently when I get
                  home:
                </Label>
                <TextArea
                  placeholder="ex. something"
                  name="message_new_life"
                  value={message_new_life}
                  onChange={handleChange}
                />
              </TextAreaContainer>

              <TextAreaContainer>
                <Label>
                  I now have this ability, wich I Never imagined I could have before I left home:
                </Label>
                <TextArea
                  placeholder="ex. something"
                  name="message_new_ability"
                  value={message_new_ability}
                  onChange={handleChange}
                />
              </TextAreaContainer>
            </FlexBox>
          )}
        </JournalDayStep>
      </StepContainer>
    );
  }
}

export default PreparingToReturnView;
