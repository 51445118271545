import React from 'react';
import styled from 'styled-components';
import { MONTHS } from '../../constants';
import { Formik } from 'formik';
import ModalExcel from 'components/Suggestions/ModalExcel';

const GridContainer = styled.div`
  padding-top: 40px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: ${props => props.backgroundColor || '#38b6ff'};
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  line-height: 0;
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const BoxContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 5px;
`;

const acceptable = styled.div``;
const Select = styled.select`
  width: 100%;
  height: 52px;
  border-radius: 5px;
  background-color: #ffffff;
  border: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  margin-left: 5px;
  margin-right: 20px;
`;

const LinkText = styled.p`
  padding-top: 5px;
  font-size: 18px;
  cursor: pointer;
  color: #38b6ff;
`;

const InputActivity = styled.input`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 25)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const VisitSuggestionForm = props => {
  const {
    token,
    viewMode,
    renderModalExcel,
    handleModalExcel,
    weather,
    saveVisitSuggestion,
    country: { id },
  } = props;

  const selectSuggestion = (name, values, handleChange) => {
    let value = '';
    switch (name) {
      case 'early_january':
        value = values.early_january;
        break;
      case 'mid_january':
        value = values.mid_january;
        break;
      case 'late_january':
        value = values.late_january;
        break;
      case 'early_february':
        value = values.early_february;
        break;
      case 'mid_february':
        value = values.mid_february;
        break;
      case 'late_february':
        value = values.late_february;
        break;
      case 'early_march':
        value = values.early_march;
        break;
      case 'mid_march':
        value = values.mid_march;
        break;
      case 'late_march':
        value = values.late_march;
        break;
      case 'early_april':
        value = values.early_april;
        break;
      case 'mid_april':
        value = values.mid_april;
        break;
      case 'late_april':
        value = values.late_april;
        break;
      case 'early_may':
        value = values.early_may;
        break;
      case 'mid_may':
        value = values.mid_may;
        break;
      case 'late_may':
        value = values.late_may;
        break;
      case 'early_june':
        value = values.early_june;
        break;
      case 'mid_june':
        value = values.mid_june;
        break;
      case 'late_june':
        value = values.late_june;
        break;
      case 'early_july':
        value = values.early_july;
        break;
      case 'mid_july':
        value = values.mid_july;
        break;
      case 'late_july':
        value = values.late_july;
        break;
      case 'early_august':
        value = values.early_august;
        break;
      case 'mid_august':
        value = values.mid_august;
        break;
      case 'late_august':
        value = values.late_august;
        break;
      case 'early_september':
        value = values.early_september;
        break;
      case 'mid_september':
        value = values.mid_september;
        break;
      case 'late_september':
        value = values.late_september;
        break;
      case 'early_october':
        value = values.early_october;
        break;
      case 'mid_october':
        value = values.mid_october;
        break;
      case 'late_october':
        value = values.late_october;
        break;
      case 'early_november':
        value = values.early_november;
        break;
      case 'mid_november':
        value = values.mid_november;
        break;
      case 'late_november':
        value = values.late_november;
        break;
      case 'early_december':
        value = values.early_december;
        break;
      case 'mid_december':
        value = values.mid_december;
        break;
      case 'late_december':
        value = values.late_december;
        break;
      default:
        break;
    }

    return !viewMode ? (
      <Select placeholder="Suggestion" name={name} value={value} onChange={handleChange}>
        <option value={'Inappropiate'}>Inappropiate</option>
        <option value={'Acceptable'}>Acceptable</option>
        <option value={'Best'}>Best</option>
      </Select>
    ) : (
      <InputActivity name={name} value={value} min={0} disabled />
    );
  };

  const renderMonthsSuggestions = (values, handleChange) =>
    MONTHS.map((month, key) => {
      const monthLowerCase = month.toLocaleLowerCase();
      const earlyMonth = 'early_' + monthLowerCase;
      const midMonth = 'mid_' + monthLowerCase;
      const lateMonth = 'late_' + monthLowerCase;

      return (
        <FlexRow
          key={key}
          style={{
            borderBottom: '1px solid #fafafa',
            paddingBottom: 5,
            marginTop: 30,
          }}
        >
          <FlexColumn width={30}>
            <Label color={'#38b6ff'} marginBottom={30} marginTop={25}>
              {month}
            </Label>
          </FlexColumn>
          <FlexColumn>
            <FlexRow>
              <Label small color={'black'} marginTop={25}>
                Early:
              </Label>
              {selectSuggestion(earlyMonth, values, handleChange)}
            </FlexRow>
          </FlexColumn>
          <FlexColumn>
            <FlexRow>
              <Label small color={'black'} marginTop={25}>
                Middle:
              </Label>
              {selectSuggestion(midMonth, values, handleChange)}
            </FlexRow>
          </FlexColumn>
          <FlexColumn>
            <FlexRow>
              <Label small color={'black'} marginTop={25}>
                Late:
              </Label>
              {selectSuggestion(lateMonth, values, handleChange)}
            </FlexRow>
          </FlexColumn>
        </FlexRow>
      );
    });

  return (
    <GridContainer>
      <FlexColumn>
        <FlexRow>
          <FlexColumn width={90}>
            <Label marginTop={15}>Best Time To Visit For Weather Start Date</Label>
          </FlexColumn>
          {/* <LinkText onClick={() => handleModalExcel(true)} style={{ marginTop: 10 }}>
            Import from sheet
          </LinkText>*/}
        </FlexRow>

        <BoxContainer>
          <Formik enableReinitialize initialValues={weather} isInitialValid={true}>
            {({ values, handleChange }) => {
              return (
                <>
                  {renderMonthsSuggestions(values, handleChange)}
                  {!viewMode && (
                    <Button onClick={() => saveVisitSuggestion(id, values)}>Save</Button>
                  )}
                </>
              );
            }}
          </Formik>
        </BoxContainer>
      </FlexColumn>
      {/*<ModalExcel
        id={id}
        token={token}
        url="visit-suggestions"
        renderModalExcel={renderModalExcel}
        handleModalExcel={handleModalExcel}
      ></ModalExcel>*/}
    </GridContainer>
  );
};

export default VisitSuggestionForm;
