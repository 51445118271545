import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';

const RentalCarBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ceneter;
`;

const RentalCarPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const LightButton = styled.button`
  border-radius: 5px;
  color: white;
  background-color: ${props => (props.active ? '#00b4d51b;' : 'inherit;')}
  border: 1px solid ${props => (props.active ? '#38b6ff;' : '#b7b7b7;')}
  width: 268px;
  height: 52px;
  color: ${props => (props.active ? '#38b6ff;' : '#b7b7b7;')}
  margin: 15px 5px;
  font-family: 'Josefin Sans', sans-serif;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: center;
`;

class RentalCarView extends Component {
  render() {
    const { step, state, changeStep, setRentalCar } = this.props;
    const { rental_car } = state;
    return (
      <RentalCarBox>
        <RentalCarPrimaryBox>
          <PrimaryText text="To get the most out of many destinations, driving a rental car is strongly encouraged. Are you OK driving a rental car on this upcoming trip?" />
          <ButtonBox>
            <LightButton active={rental_car} onClick={() => setRentalCar(true)}>
              Yes
            </LightButton>
            <LightButton active={!rental_car} onClick={() => setRentalCar(false)}>
              No
            </LightButton>
          </ButtonBox>

          <StepButtons
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(step + 1)}
          />
        </RentalCarPrimaryBox>
      </RentalCarBox>
    );
  }
}

export default RentalCarView;
