import React, { Component } from 'react';
import RegionsForm from './RegionsForm';
import { connect } from 'react-redux';
import * as regionsActions from '../../actions/regionsActions';
import * as suggestionActions from '../../actions/suggestionActions';

class CreateRegions extends Component {
  componentDidMount() {
    const { getCountriesForSelect } = this.props;
    getCountriesForSelect();
  }
  createRegion = region => {
    const { createRegions } = this.props;
    createRegions(region);
  };

  render() {
    const {
      name,
      countriesForSelect,
      match: { params },
    } = this.props;
    return (
      <RegionsForm
        name={name}
        submit={this.createRegion}
        countriesForSelect={countriesForSelect}
        region={{ country_id: params.country_id }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    name: state.regionsReducer.name,
    countriesForSelect: state.suggestionReducer.countriesForSelect,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createRegions: region => dispatch(regionsActions.createRegions(region)),
    getCountriesForSelect: () => dispatch(suggestionActions.getCountriesForSelect()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateRegions);
