import { keyMirror } from 'modules/helpers';

export const BOUTIQUE_PACKAGE_ID = 5;

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  SWITCH_MENU: undefined,
  EXCEPTION: undefined,
  USER_LOGIN: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,
  USER_LOGOUT: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,
  GITHUB_GET_REPOS: undefined,
  GITHUB_GET_REPOS_SUCCESS: undefined,
  GITHUB_GET_REPOS_FAILURE: undefined,
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
  // login
  LOGIN_EMAIL_REQUEST: 'LOGIN_EMAIL_REQUEST',
  LOGIN_RESPONSE: 'LOGIN_RESPONSE',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT',
  LOGIN_FACEBOOK_REQUEST: 'LOGIN_FACEBOOK_REQUEST',
  LOGIN_GOOGLE_REQUEST: 'LOGIN_GOOGLE_REQUEST',
  RECOVER_PASSWORD: 'RECOVER_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  UPDATE_USER_DIAGNOSTIC_STEP: 'UPDATE_USER_DIAGNOSTIC_STEP',
  // customers
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMER: 'GET_CUSTOMER',
  GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
  SAVE_CUSTOMERS: 'SAVE_CUSTOMERS',
  GET_FRIENDS: 'GET_FRIENDS',
  GET_FRIENDS_SUCCESS: 'GET_FRIENDS_SUCCESS',
  // register
  REGISTER_ADD_PROPERTIES: 'REGISTER_ADD_PROPERTIES',
  REGISTER_CHANGE_CURRENT_STEP: 'REGISTER_CHANGE_CURRENT_STEP',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_FINISHED: 'REGISTER_FINISHED',
  // startup
  STARTUP: 'STARTUP',
  // diagnostic
  DIAGNOSTIC_CHANGE_CURRENT_STEP: 'DIAGNOSTIC_CHANGE_CURRENT_STEP',
  // well being
  DIAGNOSTIC_CREATE_WELL_BEING: 'DIAGNOSTIC_CREATE_WELL_BEING',
  DIAGNOSTIC_GET_WELL_BEING: 'DIAGNOSTIC_GET_WELL_BEING',
  DIAGNOSTIC_RECIVE_WELL_BEING: 'DIAGNOSTIC_RECIVE_WELL_BEING',
  // strengths
  DIAGNOSTIC_CREATE_STRENGTHS: 'DIAGNOSTIC_CREATE_STRENGTHS',
  DIAGNOSTIC_GET_STRENGTHS: 'DIAGNOSTIC_GET_STRENGTHS',
  DIAGNOSTIC_RECIVE_STRENGTHS: 'DIAGNOSTIC_RECIVE_STRENGTHS',
  // travel personality
  DIAGNOSTIC_CREATE_TRAVEL_PERSONALITY: 'DIAGNOSTIC_CREATE_TRAVEL_PERSONALITY',
  DIAGNOSTIC_GET_TRAVEL_PERSONALITY: 'DIAGNOSTIC_GET_TRAVEL_PERSONALITY',
  DIAGNOSTIC_RECIVE_TRAVEL_PERSONALITY: 'DIAGNOSTIC_RECIVE_TRAVEL_PERSONALITY',
  // porducts
  GET_AVAILABLE_PRODUCTS: 'GET_AVAILABLE_PRODUCTS',
  SAVE_AVAILABLE_PRODUCTS: 'SAVE_AVAILABLE_PRODUCTS',

  // boutique package
  GET_REQUESTS_BOUTIQUE_PACKAGES: 'GET_REQUESTS_BOUTIQUE_PACKAGES',
  GET_REQUESTS_BOUTIQUE_PACKAGES_SUCCESS: 'GET_REQUESTS_BOUTIQUE_PACKAGES_SUCCESS',
  GET_REQUESTS_BOUTIQUE_PACKAGES_FAILURE: 'GET_REQUESTS_BOUTIQUE_PACKAGES_FAILURE',
  GET_REQUEST_BOUTIQUE_PACKAGE: 'GET_REQUEST_BOUTIQUE_PACKAGE',
  GET_REQUEST_BOUTIQUE_PACKAGE_SUCCESS: 'GET_REQUEST_BOUTIQUE_PACKAGE_SUCCESS',
  GET_REQUEST_BOUTIQUE_PACKAGE_FAILURE: 'GET_REQUEST_BOUTIQUE_PACKAGE_FAILURE',
  GET_MY_REQUEST_BOUTIQUE_PACKAGE: 'GET_MY_REQUEST_BOUTIQUE_PACKAGE',
  GET_MY_REQUEST_BOUTIQUE_PACKAGE_SUCCESS: 'GET_MY_REQUEST_BOUTIQUE_PACKAGE_SUCCESS',
  GET_MY_REQUEST_BOUTIQUE_PACKAGE_FAILURE: 'GET_MY_REQUEST_BOUTIQUE_PACKAGE_FAILURE',
  SAVE_PACKAGE_BOUTIQUE: 'SAVE_PACKAGE_BOUTIQUE',
  SAVE_PACKAGE_BOUTIQUE_SUCCESS: 'SAVE_PACKAGE_BOUTIQUE_SUCCESS',
  SAVE_PACKAGE_BOUTIQUE_FAILURE: 'SAVE_PACKAGE_BOUTIQUE_FAILURE',
  UPDATE_PACKAGE_BOUTIQUE: 'UPDATE_PACKAGE_BOUTIQUE',
  UPDATE_PACKAGE_BOUTIQUE_SUCCESS: 'UPDATE_PACKAGE_BOUTIQUE_SUCCESS',
  UPDATE_PACKAGE_BOUTIQUE_FAILURE: 'UPDATE_PACKAGE_BOUTIQUE_FAILURE',
  CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_REQUEST: 'CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_REQUEST',
  CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_SUCCESS: 'CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_SUCCESS',
  CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_FAILURE: 'CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_FAILURE',
  //profile

  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_RESPONSE: 'UPDATE_PROFILE_RESPONSE',
  UPDATE_DIAGNOSTIC_STEP_PERSIST: 'UPDATE_DIAGNOSTIC_STEP_PERSIST',
  VERIFY_DEGRADE_PLAN_REQUEST: 'VERIFY_DEGRADE_PLAN_REQUEST',
  VERIFY_DEGRADE_PLAN_SUCCESS: 'VERIFY_DEGRADE_PLAN_SUCCESS',
  // meeting
  UPDATE_MEETING: 'UPDATE_MEETING',
  UPDATE_MEETING_SUCCESS: 'UPDATE_MEETING_SUCCESS',
  GET_POSSIBLE_MEETING_HOUR: 'GET_POSSIBLE_MEETING_HOUR',
  RECIVE_POSSIBLE_MEETING_HOUR: 'RECIVE_POSSIBLE_MEETING_HOUR',
  SAVE_MEETING: 'SAVE_MEETING',
  SAVE_CURRENT_MEETING_ID: 'SAVE_CURRENT_MEETING_ID',
  GET_MEETING_REQUEST: 'GET_MEETING_REQUEST',
  GET_MEETING_SUCCESS: 'GET_MEETING_SUCCESS',
  SAVE_MEETING_SUCCESS: 'SAVE_MEETING_SUCCESS',
  GET_INITIAL_MEETING_REQUEST: 'GET_INITIAL_MEETING_REQUEST',
  // trip
  TRIP_CHANGE_CURRENT_STEP: 'TRIP_CHANGE_CURRENT_STEP',
  SAVE_TRIP_DATA: 'SAVE_TRIP_DATA',
  GET_DESTINATIONS: 'GET_DESTINATIONS',
  SAVE_DESTINATIONS: 'SAVE_DESTINATIONS',
  SUBMIT_TRIP: 'SUBMIT_TRIP',
  GET_TRIP: 'GET_TRIP',
  SAVE_TRIP: 'SAVE_TRIP',
  GET_ACTIVITITIES_BY_CITIES: 'GET_ACTIVITITIES_BY_CITIES',
  SAVE_ACTIVITITIES_BY_CITIES: 'SAVE_ACTIVITITIES_BY_CITIES',
  GET_RESTAURANTS_BY_CITIES: 'GET_RESTAURANTS_BY_CITIES',
  SAVE_RESTAURANTS_BY_CITIES: 'SAVE_RESTAURANTS_BY_CITIES',
  GET_ACCOMMODATIONS_BY_CITIES: 'GET_ACCOMMODATIONS_BY_CITIES',
  SAVE_ACCOMMODATIONS_BY_CITIES: 'SAVE_ACCOMMODATIONS_BY_CITIES',
  GET_ME_TRIPS: 'GET_ME_TRIPS',
  SAVE_ME_TRIPS: 'SAVE_ME_TRIPS',
  EDIT_TRIP: 'EDIT_TRIP',
  RESET_TRIP_FORM: 'RESET_TRIP_FORM',
  EDIT_TRIP_SIMPLE_REQUEST: 'EDIT_TRIP_SIMPLE_REQUEST',
  CREATE_TRIP_CHARGE_REQUEST: 'CREATE_TRIP_CHARGE_REQUEST',
  GET_ALL_TRIPS_REQUEST: 'GET_ALL_TRIPS_REQUEST',
  GET_ALL_TRIPS_SUCCESS: 'GET_ALL_TRIPS_SUCCESS',
  //Trip costs
  GET_COSTS: 'GET_COSTS',
  GET_COSTS_SUCCESS: 'GET_COSTS_SUCCESS',
  GET_COSTS_FAILURE: 'GET_COSTS_FAILURE',
  UPDATE_COST: 'UPDATE_COST',
  UPDATE_COST_SUCCESS: 'UPDATE_COST_SUCCESS',
  UPDATE_COST_FAILURE: 'UPDATE_COST_FAILURE',

  // payment methods
  GET_PAYMENT_METHODS: 'GET_PAYMENT_METHODS',
  SAVE_PAYMENT_METHODS: 'SAVE_PAYMENT_METHODS',
  CREATE_PAYMENT_METHODS: 'CREATE_PAYMENT_METHODS',
  DELETE_PAYMENT_METHOD: 'DELETE_PAYMENT_METHOD',
  SET_DEFAULT_METHOD: 'SET_DEFAULT_METHOD',

  // activities
  GET_ACTIVITIES: 'GET_ACTIVITIES',
  GET_ACTIVITY: 'GET_ACTIVITY',
  GET_ACTIVITY_SUCCESS: 'GET_ACTIVITY_SUCCESS',
  GET_ACTIVITIES_SUCCESS: 'GET_ACTIVITIES_SUCCESS',
  REFRESH_COORDINATES: 'REFRESH_COORDINATES',
  REFRESH_ADDRESS: 'REFRESH_ADDRESS',
  SAVE_ACTIVITY_PLACES: 'SAVE_ACTIVITY_PLACES',
  SAVE_ACTIVITY: 'SAVE_ACTIVITY',
  REMOVE_ACTIVITY: 'REMOVE_ACTIVITY',
  UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
  SAVE_STOPS: 'SAVE_STOPS',
  GET_DISTANCE_TIME: 'GET_DISTANCE_TIME',
  RESET_DURATIONS: 'RESET_DURATIONS',
  SAVE_DRIVING_DURATIONS: 'SAVE_DRIVING_DURATIONS',
  SAVE_WALKING_DURATIONS: 'SAVE_WALKING_DURATIONS',

  // restaurants
  GET_RESTAURANTS: 'GET_RESTAURANTS',
  GET_RESTAURANTS_SUCCESS: 'GET_RESTAURANTS_SUCCESS',
  GET_RESTAURANTS_FAILURE: 'GET_RESTAURANTS_FAILURE',
  GET_RESTAURANT: 'GET_RESTAURANT',
  GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
  GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',
  REMOVE_RESTAURANT: 'REMOVE_RESTAURANT',
  REMOVE_RESTAURANT_SUCCESS: 'REMOVE_RESTAURANT_SUCCESS',
  REMOVE_RESTAURANT_FAILURE: 'REMOVE_RESTAURANT_FAILURE',
  SAVE_RESTAURANT: 'SAVE_RESTAURANT',
  SAVE_RESTAURANT_SUCCESS: 'SAVE_RESTAURANT_SUCCESS',
  SAVE_RESTAURANT_FAILURE: 'SAVE_RESTAURANT_FAILURE',
  UPDATE_RESTAURANT: 'UPDATE_RESTAURANT',
  UPDATE_RESTAURANT_SUCCESS: 'UPDATE_RESTAURANT_SUCCESS',
  UPDATE_RESTAURANT_FAILURE: 'UPDATE_RESTAURANT_FAILURE',

  // acommodationn
  GET_ACCOMMODATIONS: 'GET_ACCOMMODATIONS',
  GET_ACCOMMODATIONS_SUCCESS: 'GET_ACCOMMODATIONS_SUCCESS',
  GET_ACCOMMODATIONS_FAILURE: 'GET_ACCOMMODATIONS_FAILURE',
  GET_ACCOMMODATION: 'GET_ACCOMMODATION',
  GET_ACCOMMODATION_SUCCESS: 'GET_ACCOMMODATION_SUCCESS',
  GET_ACCOMMODATION_FAILURE: 'GET_ACCOMMODATION_FAILURE',
  REMOVE_ACCOMMODATION: 'REMOVE_ACCOMMODATION',
  REMOVE_ACCOMMODATION_SUCCESS: 'REMOVE_ACCOMMODATION_SUCCESS',
  REMOVE_ACCOMMODATION_FAILURE: 'REMOVE_ACCOMMODATION_FAILURE',
  SAVE_ACCOMMODATION: 'SAVE_ACCOMMODATION',
  SAVE_ACCOMMODATION_SUCCESS: 'SAVE_ACCOMMODATION_SUCCESS',
  SAVE_ACCOMMODATION_FAILURE: 'SAVE_ACCOMMODATION_FAILURE',
  UPDATE_ACCOMMODATION: 'UPDATE_ACCOMMODATION',
  UPDATE_ACCOMMODATION_SUCCESS: 'UPDATE_ACCOMMODATION_SUCCESS',
  UPDATE_ACCOMMODATION_FAILURE: 'UPDATE_ACCOMMODATION_FAILURE',

  // itinerary
  SAVE_ITINERARY_DATA: 'SAVE_ITINERARY_DATA',
  SELECT_STOP: 'SELECT_STOP',
  ADD_DAY: 'ADD_DAY',
  SELECT_DAY: 'SELECT_DAY',
  ADD_ACTIVITY: 'ADD_ACTIVITY',
  SAVE_ITINERARY: 'SAVE_ITINERARY',
  ADD_STOP: 'ADD_STOP',
  INITIALIZE_ITINERARY: 'INITIALIZE_ITINERARY',
  DELETE_DAY: 'DELETE_DAY',
  DELETE_STOP: 'DELETE_STOP',
  DELETE_ACTIVITY: 'DELETE_ACTIVITY',
  GENERATE_ITINERARY_REQUEST: 'GENERATE_ITINERARY_REQUEST',
  GENERATE_ITINERARY_SUCCESS: 'GENERATE_ITINERARY_SUCCESS',
  GET_ITINERARY: 'GET_ITINERARY',
  GET_ITINERARY_SUCCESS: 'GET_ITINERARY_SUCCESS',
  GET_ITINERARY_FAILURE: 'GET_ITINERARY_FAILURE',
  MARK_ITINERARY_AS_DEFAULT: 'MARK_ITINERARY_AS_DEFAULT',
  MARK_ITINERARY_AS_DEFAULT_SUCCESS: 'MARK_ITINERARY_AS_DEFAULT_SUCCESS',
  MARK_ITINERARY_AS_DEFAULT_FAILURE: 'MARK_ITINERARY_AS_DEFAULT_FAILURE',

  // transactions
  GET_ME_TRANSACTIONS: 'GET_ME_TRANSACTIONS',
  SAVE_ME_TRANSACTIONS: 'SAVE_ME_TRANSACTIONS',

  // invoices
  GET_ME_INVOICES: 'GET_ME_INVOICES',
  SAVE_ME_INVOICES: 'SAVE_ME_INVOICES',

  // ad ons
  CREATE_PRE_TRIP_COURSE: 'CREATE_PRE_TRIP_COURSE',
  SAVE_PRE_TRIP_COURSES: 'SAVE_PRE_TRIP_COURSES',
  GET_PRE_TRIP_COURSES: 'GET_PRE_TRIP_COURSES',
  GET_PRE_TRIP_COURSE: 'GET_PRE_TRIP_COURSE',
  SAVE_PRE_TRIP_COURSE: 'SAVE_PRE_TRIP_COURSE',
  DELETE_PRE_TRIP_COURSE: 'DELETE_PRE_TRIP_COURSE',
  UPDATE_PRE_TRIP_COURSE: 'UPDATE_PRE_TRIP_COURSE',
  BUY_PRE_TRIP_COURSE: 'BUY_PRE_TRIP_COURSE',
  PURCHASED_PRE_TRIP_COURSES: 'PURCHASED_PRE_TRIP_COURSES',
  SAVE_PURCHASED_PRE_TRIP_COURSES: 'SAVE_PURCHASED_PRE_TRIP_COURSES',
  ALL_PURCHASED_PRE_TRIP_COURSES: 'ALL_PURCHASED_PRE_TRIP_COURSES',
  SAVE_ALL_PURCHASED_PRE_TRIP_COURSES: 'SAVE_ALL_PURCHASED_PRE_TRIP_COURSES',

  // calls
  GET_CUSTOMER_MEETINGS: 'GET_CUSTOMER_MEETINGS',
  SAVE_ME_MEETINGS: 'SAVE_ME_MEETINGS',

  // cities
  CREATE_CITIES: 'CREATE_CITIES',
  CREATE_CITIES_SUCCESS: 'CREATE_CITIES_SUCCESS',
  CREATE_CITIES_FAILURE: 'CREATE_CITIES_FAILURE',
  GET_CITIES: 'GET_CITIES',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
  GET_CITIES_FAILURE: 'GET_CITIES_FAILURE',
  GET_ALL_CITIES: 'GET_ALL_CITIES',
  GET_ALL_CITIES_SUCCESS: 'GET_ALL_CITIES_SUCCESS',
  GET_ALL_CITIES_FAILURE: 'GET_ALL_CITIES_FAILURE',
  EDIT_CITIES: 'EDIT_CITIES',
  EDIT_CITIES_SUCCESS: 'EDIT_CITIES_SUCCESS',
  EDIT_CITIES_FAILURE: 'EDIT_CITIES_FAILURE',
  REMOVE_CITIES: 'REMOVE_CITIES',
  REMOVE_CITIES_SUCCESS: 'REMOVE_CITIES_SUCCESS',
  REMOVE_CITIES_FAILURE: 'REMOVE_CITIES_FAILURE',
  GET_CITIES_FOR_SELECT: 'GET_CITIES_FOR_SELECT',
  GET_CITIES_FOR_SELECT_SUCCESS: 'GET_CITIES_FOR_SELECT_SUCCESS',
  GET_CITIES_FOR_SELECT_FAILURE: 'GET_CITIES_FOR_SELECT_FAILURE',

  // regions
  CREATE_REGIONS: 'CREATE_REGIONS',
  CREATE_REGIONS_SUCCESS: 'CREATE_REGIONS_SUCCESS',
  CREATE_REGIONS_FAILURE: 'CREATE_REGIONS_FAILURE',
  GET_REGIONS: 'GET_REGIONS',
  GET_REGIONS_SUCCESS: 'GET_REGIONS_SUCCESS',
  GET_REGIONS_FAILURE: 'GET_REGIONS_FAILURE',
  GET_ALL_REGIONS: 'GET_ALL_REGIONS',
  GET_ALL_REGIONS_SUCCESS: 'GET_ALL_REGIONS_SUCCESS',
  GET_ALL_REGIONS_FAILURE: 'GET_ALL_REGIONS_FAILURE',
  EDIT_REGIONS: 'EDIT_REGIONS',
  EDIT_REGIONS_SUCCESS: 'EDIT_REGIONS_SUCCESS',
  EDIT_REGIONS_FAILURE: 'EDIT_REGIONS_FAILURE',
  GET_REGIONS_FOR_SELECT: 'GET_REGIONS_FOR_SELECT',
  GET_REGIONS_FOR_SELECT_SUCCESS: 'GET_REGIONS_FOR_SELECT_SUCCESS',
  GET_REGIONS_FOR_SELECT_FAILURE: 'GET_REGIONS_FOR_SELECT_FAILURE',
  REMOVE_REGIONS: 'REMOVE_REGIONS',
  REMOVE_REGIONS_SUCCESS: 'REMOVE_REGIONS_SUCCESS',
  REMOVE_REGIONS_FAILURE: 'REMOVE_REGIONS_FAILURE',

  // Journal
  UPDATE_JOURNAL_REQUEST: 'UPDATE_JOURNAL_REQUEST',
  GET_JOURNAL_REQUEST: 'GET_JOURNAL_REQUEST',
  GET_JOURNAL_SUCCESS: 'GET_JOURNAL_SUCCESS',
  CREATE_DAY_CALLING_REQUEST: 'CREATE_DAY_CALLING_REQUEST',
  CREATE_DAY_DEAPTURE_REQUEST: 'CREATE_DAY_DEAPTURE_REQUEST',
  CREATE_DAY_ROAD_REQUEST: 'CREATE_DAY_ROAD_REQUEST',
  CREATE_DAY_PREPARING_RETURN_REQUEST: 'CREATE_DAY_PREPARING_RETURN_REQUEST',
  CREATE_DAY_RETURN_REQUEST: 'CREATE_DAY_RETURN_REQUEST',
  GET_CALLING_REQUEST: 'GET_CALLING_REQUEST',
  GET_CALLING_SUCCESS: 'GET_CALLING_SUCCESS',

  GET_DEAPTURE_REQUEST: 'GET_DEAPTURE_REQUEST',
  GET_DEAPTURE_SUCCESS: 'GET_DEAPTURE_SUCCESS',
  GET_ROAD_REQUEST: 'GET_ROAD_REQUEST',
  GET_ROAD_SUCCESS: 'GET_ROAD_SUCCESS',
  GET_PREPARING_RETURN_REQUEST: 'GET_PREPARING_RETURN_REQUEST',
  GET_PREPARING_RETURN_SUCCESS: 'GET_PREPARING_RETURN_SUCCESS',
  GET_RETURN_REQUEST: 'GET_RETURN_REQUEST',
  GET_RETURN_SUCCESS: 'GET_RETURN_SUCCESS',

  // Authentic plan
  CREATE_AUTHENTIC_PLAN_REQUEST: 'CREATE_AUTHENTIC_PLAN_REQUEST',
  GET_AUTHENTIC_PLAN_REQUEST: 'GET_AUTHENTIC_PLAN_REQUEST',
  GET_AUTHENTIC_PLAN_SUCCESS: 'GET_AUTHENTIC_PLAN_SUCCESS',
  UPDATE_AUTHENTIC_PLAN_REQUEST: 'UPDATE_AUTHENTIC_PLAN_REQUEST',

  // Goal
  CREATE_GOAL_REQUEST: 'CREATE_GOAL_REQUEST',
  GET_GOAL_REQUEST: 'GET_GOAL_REQUEST',
  GET_GOAL_SUCCESS: 'GET_GOAL_SUCCESS',
  UPDATE_GOAL_REQUEST: 'UPDATE_GOAL_REQUEST',

  // couse
  CREATE_COURSE_REQUEST: 'CREATE_COURSE_REQUEST',
  GET_COURSE_REQUEST: 'GET_COURSE_REQUEST',
  GET_COURSE_SUCCESS: 'GET_COURSE_SUCCESS',
  UPDATE_COURSE_REQUEST: 'UPDATE_COURSE_REQUEST',
  CREATE_COURSE_SUCCESS: 'CREATE_COURSE_SUCCESS',
  GET_CUSTOMER_COURSES_REQUEST: 'GET_CUSTOMER_COURSES_REQUEST',
  GET_CUSTOMER_COURSES_SUCCESS: 'GET_CUSTOMER_COURSES_SUCCESS',
  UPDATE_COURSE_STATUS_REQUEST: 'UPDATE_COURSE_STATUS_REQUEST',
  UPDATE_COURSE_STATUS_SUCCESS: 'UPDATE_COURSE_STATUS_SUCCESS',

  // Suggestions
  GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE: 'GET_COUNTRIES_FAILURE',
  GET_COUNTRY_REQUEST: 'GET_COUNTRY_REQUEST',
  GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_FAILURE: 'GET_COUNTRY_FAILURE',
  GET_VISIT_REQUEST: 'GET_VISIT_REQUEST',
  GET_VISIT_SUCCESS: 'GET_VISIT_SUCCESS',
  GET_VISIT_FAILURE: 'GET_VISIT_FAILURE',
  SAVE_VISIT_REQUEST: 'SAVE_VISIT_REQUEST',
  SAVE_VISIT_SUCCESS: 'SAVE_VISIT_SUCCESS',
  SAVE_VISIT_FAILURE: 'SAVE_VISIT_FAILURE',
  GET_AVERAGE_DAILY_EXPENSES_REQUEST: 'GET_AVERAGE_DAILY_EXPENSES_REQUEST',
  GET_AVERAGE_DAILY_EXPENSES_SUCCESS: 'GET_AVERAGE_DAILY_EXPENSES_SUCCESS',
  GET_AVERAGE_DAILY_EXPENSES_FAILURE: 'GET_AVERAGE_DAILY_EXPENSES_FAILURE',
  SAVE_AVERAGE_DAILY_EXPENSES_REQUEST: 'SAVE_AVERAGE_DAILY_EXPENSES_REQUEST',
  SAVE_AVERAGE_DAILY_EXPENSES_SUCCESS: 'SAVE_AVERAGE_DAILY_EXPENSES_SUCCESS',
  SAVE_AVERAGE_DAILY_EXPENSES_FAILURE: 'SAVE_AVERAGE_DAILY_EXPENSES_FAILURE',
  GET_ACCOMMODATION_PRICING_REQUEST: 'GET_ACCOMMODATION_PRICING_REQUEST',
  GET_ACCOMMODATION_PRICING_SUCCESS: 'GET_ACCOMMODATION_PRICING_SUCCESS',
  GET_ACCOMMODATION_PRICING_FAILURE: 'GET_ACCOMMODATION_PRICING_FAILURE',
  SAVE_ACCOMMODATION_PRICING_REQUEST: 'SAVE_ACCOMMODATION_PRICING_REQUEST',
  SAVE_ACCOMMODATION_PRICING_SUCCESS: 'SAVE_ACCOMMODATION_PRICING_SUCCESS',
  SAVE_ACCOMMODATION_PRICING_FAILURE: 'SAVE_ACCOMMODATION_PRICING_FAILURE',
  GET_CLIMATE_REQUEST: 'GET_CLIMATE_REQUEST',
  GET_CLIMATE_SUCCESS: 'GET_CLIMATE_SUCCESS',
  GET_CLIMATE_FAILURE: 'GET_CLIMATE_FAILURE',
  SAVE_CLIMATE_REQUEST: 'SAVE_CLIMATE_REQUEST',
  SAVE_CLIMATE_SUCCESS: 'SAVE_CLIMATE_SUCCESS',
  SAVE_CLIMATE_FAILURE: 'SAVE_CLIMATE_FAILURE',
  SET_CLIMATE: 'SET_CLIMATE',
  UPDATE_CLIMATE: 'UPDATE_CLIMATE',
  REMOVE_CLIMATE: 'REMOVE_CLIMATE',
  REMOVE_CLIMATE_SUCCESS: 'REMOVE_CLIMATE_SUCCESS',
  GET_LIFECYCLES_REQUEST: 'GET_LIFECYCLES_REQUEST',
  GET_LIFECYCLES_SUCCESS: 'GET_LIFECYCLES_SUCCESS',
  GET_LIFECYCLES_FAILURE: 'GET_LIFECYCLES_FAILURE',
  GET_LIFECYCLE_REQUEST: 'GET_LIFECYCLE_REQUEST',
  GET_LIFECYCLE_SUCCESS: 'GET_LIFECYCLE_SUCCESS',
  GET_LIFECYCLE_FAILURE: 'GET_LIFECYCLE_FAILURE',
  REMOVE_LIFECYCLE_REQUEST: 'REMOVE_LIFECYCLE_REQUEST',
  REMOVE_LIFECYCLE_SUCCESS: 'REMOVE_LIFECYCLE_SUCCESS',
  REMOVE_LIFECYCLE_FAILURE: 'REMOVE_LIFECYCLE_FAILURE',
  SAVE_LIFECYCLE_REQUEST: 'SAVE_LIFECYCLE_REQUEST',
  SAVE_LIFECYCLE_SUCCESS: 'SAVE_LIFECYCLE_SUCCESS',
  SAVE_LIFECYCLE_FAILURE: 'SAVE_LIFECYCLE_FAILURE',
  SHOW_LIFECYCLE_FORM: 'SHOW_LIFECYCLE_FORM',
  GET_CUISINE_SPECIALITIES_REQUEST: 'GET_CUISINE_SPECIALITIES_REQUEST',
  GET_CUISINE_SPECIALITIES_SUCCESS: 'GET_CUISINE_SPECIALITIES_SUCCESS',
  GET_CUISINE_SPECIALITIES_FAILURE: 'GET_CUISINE_SPECIALITIES_FAILURE',
  GET_CUISINE_SPECIALITY_REQUEST: 'GET_CUISINE_SPECIALITY_REQUEST',
  GET_CUISINE_SPECIALITY_SUCCESS: 'GET_CUISINE_SPECIALITY_SUCCESS',
  GET_CUISINE_SPECIALITY_FAILURE: 'GET_CUISINE_SPECIALITY_FAILURE',
  REMOVE_CUISINE_SPECIALITY_REQUEST: 'REMOVE_CUISINE_SPECIALITY_REQUEST',
  REMOVE_CUISINE_SPECIALITY_SUCCESS: 'REMOVE_CUISINE_SPECIALITY_SUCCESS',
  REMOVE_CUISINE_SPECIALITY_FAILURE: 'REMOVE_CUISINE_SPECIALITY_FAILURE',
  SAVE_CUISINE_SPECIALITY_REQUEST: 'SAVE_CUISINE_SPECIALITY_REQUEST',
  SAVE_CUISINE_SPECIALITY_SUCCESS: 'SAVE_CUISINE_SPECIALITY_SUCCESS',
  SAVE_CUISINE_SPECIALITY_FAILURE: 'SAVE_CUISINE_SPECIALITY_FAILURE',
  SHOW_CUISINE_SPECIALITY_FORM: 'SHOW_CUISINE_SPECIALITY_FORM',
  GET_COUNTRIES_FOR_SELECT: 'GET_COUNTRIES_FOR_SELECT',
  GET_COUNTRIES_FOR_SELECT_SUCCESS: 'GET_COUNTRIES_FOR_SELECT_SUCCESS',
  GET_COUNTRIES_FOR_SELECT_FAILURE: 'GET_COUNTRIES_FOR_SELECT_FAILURE',
});

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const GENERALPREFERENCES = [
  'I prefer o-the-beaten-path destinations that are rarely visited by travelers, even if this means that there is very little infrastructure for tourists, especially foreigners.',
  'I prefer up-and-coming destinations that are only just beginning to become popular with foreign visitors. There may be a few hotels, tours, or restaurants catering to visitors in these locales.',
  'I prefer trustworthy-yet-authentic destinations that aren’t yet regular stops for most foreign visitors but still have clean and comfortable hotels, good restaurants, and a variety of tours.',
  'I prefer the typical “must-see” spots that are regular destinations for travelers. I enjoy how they oer a wide-range of clean and comfortable hotels, tours, and restaurants.',
];

export const ENTERTAINMENT = [
  'Festivals',
  'Film',
  'Live Music',
  'Sporting Events',
  'Theater',
  'Other Entertainment',
];

export const FOODDRINK = [
  'Local Specialities',
  'Beer',
  'Cheese & Dairy',
  'Coffee & Tea',
  'Dessert',
  'Food & Drink Tours',
  'Fruit & Vegetables',
  'Liquor',
  'Markets',
  'Red Meat',
  'Seafood',
  'Street Food',
  'Wine',
  'Other Food And Drink',
];

export const MUSEUMS = [
  'Art Museums',
  'Aquariums & Zoos',
  'Childrens Museums',
  'History Museums',
  'Military Museums',
  'Natural History Museums',
  'Science Museums',
  'Speciality Museums',
  'Other Museums',
];
export const OUTDOORACT = [
  'Adventure Sports',
  'Beaches',
  'Biking',
  'Camping',
  'Golf',
  'Hiking',
  'Horseback Riding',
  'Parks & Gardens',
  'Scenic Views',
  'Water Sports',
  'Wild Life',
  'Winter Sports',
  'Other Outdoor Activities',
];

export const SIGHTSEEING = [
  'Castles, Palaces & Villas',
  'Historic & Important Sites',
  'Markets',
  'Modern Marvels',
  'Monuments & Statues',
  'Nature & Parks',
  'Neighborhood/Town Walks',
  'Notable Architecture',
  'Sacred & Religious Sites',
  'Scenic Views',
  'Scenic Walking Areas',
  'Squares',
  'Water & Theme Parks',
  'Other Sightseeing',
];

export const SHOPPING = [
  'Artisan Shops & Specialty Stores',
  'Department Stores',
  'Food & Drink',
  'Malls',
  'Markets',
  'Shopping Districts/Streets',
  'Other Shooping',
];

export const WELLNESS = [
  'Classes',
  'Gyms',
  'Massage',
  'Meditation',
  'Resorts & Retreats',
  'Saunas/Bathhouses',
  'Spas & Thermal Springs',
  'Yoga',
  'Other Wellness',
];

export const TOURCLASSES = [
  'Art Classes',
  'Audio Guides',
  'Bike Tours',
  'Boat Tours',
  'Bus Tours',
  'Baking & Cooking Classes',
  'Dance Classes',
  'Exercise Classes',
  'Food & Drink Tours',
  'Hiking Tours',
  'Music Lessons',
  'Private Tours',
  'Self-Guided Tours',
  'Walking Tours',
  'Wellness Classes',
  'Wild Life Tours',
  'Other Tour Classes',
];

export const NIGHTLIFE = [
  'Cocktails, Craft Beer, & Wine',
  'Dance Clubs & Discos',
  'Live Music',
  'Local Hangouts',
  'Pubs',
  'Specialty Spots',
  'Other Night Life',
];

export const MOTIVES = [
  'Anniversary',
  'Honeymoon',
  'Babymoon',
  'Weekend Getaway',
  'Business Trip',
  'Trip with friends',
  'Family Vacation',
  'Birthday',
  'Other Movies',
];

export const MEETING = [
  'Food & Drink',
  'Museums',
  'Outdoor Activities',
  'Must-see Spots ',
  'Tours',
  'Shopping',
  'Historic Sites',
  'Culture',
  'Wellnes',
  'Arts & Entertainment',
  'Nightlife',
  'Volunteerism',
];
export const ACCOMMODATION = [
  'Private rooms in guesthouses, dorm beds in hostels (often with a shared bathroom), and campgrounds',
  'Private rooms in hostels and lower-end hotels outside the city center',
  'Straight-forward, no-frills, centrally-located hotels or B&Bs',
  'Upscale, centrally-located boutique hotels and posh B&Bs.',
  'Elegant hotels or resorts with prime locations, ne dining, expert service, and various on-site amenities',
];

export const FOODIEDESTINATION = [
  'It’s very important—I don’t want to travel somewhere without high-quality cuisine.',
  'I prefer it, but it’s not a deal-breaker',
  'Other aspects are more important to me.',
];

export const EATERIES = [
  'Simple, bare-bones establishments like bakeries, food carts, cafeterias, and takeaway spots.',
  'Basic, no-frills (but tasty) sit-down restaurants where you can sample the regional cuisine—often with locals',
  'Atmospheric eateries that focus on providing high-quality ingredients and thoughtful food presentation without destroying your budget.',
  'Elegant establishments with superb service, rened cuisine, and—sometimes—a dress code.',
];

export const ISVEGAN = ['Yes, Vegan', 'Yes, Vegetarian', 'Yes, both vegan and vegetarian', 'No'];

export const TRAVELING_WITH = ['Friend(s)', 'Family', 'Children', 'Signicant Other'];

export const KNOW_DATES = [
  'Yes, I know specic dates',
  'No, but I have an idea of which months would work.',
  "No, I'm not sure yet.",
];

export const DAYS_QUANTITY = [
  "I'm not sure",
  'Less than 5 days',
  '5-7 days',
  '8-10 days',
  '11-13 days',
  '14-16 days',
  '17-19 days',
  '20-22 days',
  'More than 22 days',
];

export const TEMPERATURE_RANGES = [
  'Greater than 100°F',
  '91-100°F',
  '81-90°F',
  '71-80°F',
  '61-70°F',
  '51-60°F',
  '41-50°F',
  '32-40°F',
  'Less than 32°F',
];

export const HUMIDITYLEVELS = [
  'Super sticky',
  'Quite sticky',
  'Somewhat sticky',
  'A little sticky',
  'Comfortable',
  'Dry',
];

export const RAININGLEVELS = [
  'Almost all of the time',
  'A lot of the time',
  'About half of the time',
  'Some of the time',
  'Rarely',
  'Almost never',
];

export const LANDSCAPES = [
  'Desserts',
  'Forests',
  'Lakes',
  'Mountains',
  'Plains',
  'Valleys',
  'Coastal',
  'Rainforests',
];

export const MONTH_RANGES = [
  'Early January',
  'Mid January',
  'Late January',
  'Early February',
  'Mid February',
  'Late February',
  'Early March',
  'Mid March',
  'Late March',
  'Early April',
  'Mid April',
  'Late April',
  'Early May',
  'Mid May',
  'Late May',
  'Early June',
  'Mid June',
  'Late June',
  'Early July',
  'Mid July',
  'Late July',
  'Early August',
  'Mid August',
  'Late August',
  'Early September',
  'Mid September',
  'Late September',
  'Early October',
  'Mid October',
  'Late October',
  'Early November',
  'Mid November',
  'Late November',
  'Early December',
  'Mid December',
  'Late December',
];

export const HERE_BEFORE = ['Never', 'One Time', 'Many Times'];

export const LIKES = [
  'Food and Beverages',
  'Must See Attractions',
  'Musseum/Art',
  'Historical buildings',
  'Spas & Wellnes',
  'Outdoors',
  'Tours',
  'Local culture',
  'Shopping',
  'Perfomrs & Shows',
  'Nightlife',
  'Adventure Sports',
];

export const ADVENTURE_SPORTS = [
  'Kayaking',
  'Rafting/Tubing',
  'Sky diving',
  'Diving/Snorkeling',
  'Ziplining',
  'Mountain biking',
  'Paragliding',
  'Surfing',
  'Skiing',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const COUNTRY_LIST = [
  { name: 'United States', code: 'US' },
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Aland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People’s Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'North Macedonia, Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const DATE_LIST = [
  { name: 'Early January', value: 'Early January' },
  { name: 'Middle January', value: 'Middle January' },
  { name: 'Late January', value: 'Late January' },
  { name: 'Early February', value: 'Early February' },
  { name: 'Middle February', value: 'Middle February' },
  { name: 'Late February', value: 'Late February' },
  { name: 'Early March', value: 'Early March' },
  { name: 'Middle March', value: 'Middle March' },
  { name: 'Late March', value: 'Late March' },
  { name: 'Early April', value: 'Early April' },
  { name: 'Middle April', value: 'Middle April' },
  { name: 'Late April', value: 'Late April' },
  { name: 'Early May', value: 'Early May' },
  { name: 'Middle May', value: 'Middle May' },
  { name: 'Late May', value: 'Late May' },
  { name: 'Early June', value: 'Early June' },
  { name: 'Middle June', value: 'Middle June' },
  { name: 'Late June', value: 'Late June' },
  { name: 'Early July', value: 'Early July' },
  { name: 'Middle July', value: 'Middle July' },
  { name: 'Late July', value: 'Late July' },
  { name: 'Early August', value: 'Early August' },
  { name: 'Middle August', value: 'Middle August' },
  { name: 'Late August', value: 'Late August' },
  { name: 'Early September', value: 'Early September' },
  { name: 'Middle September', value: 'Middle September' },
  { name: 'Late September', value: 'Late September' },
  { name: 'Early October', value: 'Early October' },
  { name: 'Middle October', value: 'Middle October' },
  { name: 'Late October', value: 'Late October' },
  { name: 'Early November', value: 'Early November' },
  { name: 'Middle November', value: 'Middle November' },
  { name: 'Late November', value: 'Late November' },
  { name: 'Early December', value: 'Early December' },
  { name: 'Middle December', value: 'Middle December' },
  { name: 'Late December', value: 'Late December' },
];
