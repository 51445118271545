export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        strengthName: '',
        strengthDescription:
          '<p>You’ve just returned home after a great adventure and you deserve to devout some time to yourself. Take the day to decompress and relax as much as possible. Avoid unnecessary work and let in whatever feelings come to you. There will be ample time in the future to think about everything you did, saw, and learned. Right now, you’ve earned a break.</p>',
      };
    case 2:
      return {
        strengthName: '',
        strengthDescription:
          '<p>For thousands of years, humans have been sharing stories of heroes and heroins who venture out into the unknown, overcome great obstacles, and return home bearing the gift of wisdom to share with others. In what ways might your own journey fit this mold? What great lessons did you learn? How will you share this knowledge with others? Consider writing out your trip in a narrative, third-person perspective. Begin by listing what you did in chronological order in an outline and then add in more and more details. If you need to, look back to your journal entries and notes to refresh your memory. Create an imaginary final act, wherein you share what you’ve learned with others. Finally, add ‘Long, long, ago…’ to the beginning and then ‘And the hero lived happily ever after’ to the end. Share this story with someone who will appreciate it.</p>',
      };
    case 3:
      return {
        strengthName: 'Apreciation of Good',
        strengthDescription:
          '<p>The road isn’t the only place where you find people and activities worthy of appreciation. Upon returning home, make an effort to seek out the members of your community that create beauty and goodness on a regular basis. Learn about the artists, athletes, musicians, dancers, teachers, therapists, mentors, parents, coaches, and others who are working hard to bing about positive change. Marvel at what they have accomplished (you may even consider meeting with them), and use their example as motivation to contribute to the greater good yourself. Notice the effect this has on your overall sense of connection with others in your community.</p>',
      };
    case 4:
      return {
        strengthName: 'Curiosity',
        strengthDescription:
          '<p>What did you learn about Curiosity during your journey? How can you incorporate more of it into your life? How will Curiosity help to improve the overall quality of your relationships moving forward?</p>',
      };
    case 5:
      return {
        strengthName: 'Gratitude',
        strengthDescription:
          '<p>What did you learn about Gratitude during your journey? How can you incorporate more of it into your life? How will Gratitude help to improve the overall quality of your relationships?</p>',
      };
    case 6:
      return {
        strengthName: 'Love',
        strengthDescription:
          '<p>What did you learn about Love during your journey? How can you incorporate more of it into your life? How will Love help to improve the overall quality of your relationships moving forward?</p>',
      };
    case 7:
      return {
        strengthName: 'Open-Mindedness',
        strengthDescription:
          '<p>What did you learn about Open-Mindedness during your journey? How can you incorporate more of it into your life? How will Open-Mindedness help to improve the overall quality of your relationships?</p>',
      };
    case 8:
      return {
        strengthName: 'Appreciation for Good',
        strengthDescription:
          '<p>How often do you reflect on your own inner beauty and goodness? If you’re like most people, the answer is rarely. Take a moment to think about all the wonderful things you do that bring beauty and positivity into the world. How do these things affect others? In what ways might you be able to amplify this reach to help even more people?</p>',
      };
    case 9:
      return {
        strengthName: 'Curiosity',
        strengthDescription:
          '<p>Pay attention to the new questions that emerge about your journey. Perhaps a friend asks you a question about which you had never though. Or maybe you notice something in one of your photos that makes you wonder. Create a list of these questions and see if you can find answers them in some way.</p>',
      };
    case 10:
      return {
        strengthName: 'Gratitude',
        strengthDescription:
          '<p>What were the three biggest obstacles you faced on your journey? How did you feel in the moment when confronted with these challenges? What good came from these obstacles? Can you get yourself to feel retrospective Gratitude? How might you be able to apply this to other challenges from your past? How can retrospective Gratitude change your perception of the things that have happened?</p>',
      };
    case 11:
      return {
        strengthName: 'Love',
        strengthDescription:
          '<p>Give those you care about the gift of time. When you get together, focus on making them happy while also savoring the things that you like about them.</p>',
      };
    case 12:
      return {
        strengthName: 'Open-Mindedness',
        strengthDescription:
          '<p>Good judgement usually comes from making decisions based on a critical analysis of the problem rather than simple gut instinct or ‘common knowledge’. Next time you’re faced with a strategic decision, incorporate Open-Mindedness into the process by adding a different perspective or opinion—either through brainstorming or the input of others. Be sure to avoid the tendency to reject ideas before weighting their actual merits.</p>',
      };
    case 13:
      return {
        strengthName: 'Appreciation for Good',
        strengthDescription:
          '<p>Inject some beauty and goodness into your everyday surroundings. Hang a favorite piece of artwork up in your office, paint a wall in your home a favorite color, pot a pretty plant to look at, change the desktop image on your computer, or buy a comfortable chair to sit in. Notice how this makes you feel.</p>',
      };
    case 14:
      return {
        strengthName: 'Curiosity',
        strengthDescription:
          '<p>Focus some of the Curiosity you’ve been incorporating into your life toward yourself. Take an interested in exploring your values, your fears, your hopes for the future, and what you can do to improve your community. Learn something new about what motivates you to do the things that you do. Use your Curiosity to drive the positive action that you do.</p>',
      };
    case 15:
      return {
        strengthName: 'Love',
        strengthDescription:
          '<p>Give yourself the goal of continuing to express Gratitude as much as possible for the little positives in your daily life, such as a friendly smile from your neighbor, amazing service from your waiter at lunch, a supportive co-worker, or an excellent meal when you get home. Be specific about what you appreciated and how it made you feel. Notice how this affects your overall mood. Does it become easier to be grateful? Are you continually inspired to share positivity with others?</p>',
      };
    case 16:
      return {
        strengthName: 'Gratitude',
        strengthDescription:
          '<p>What would it look like for you to express more Love to your community? In what ways could you share the positive feelings you have inside with those around you? Are there some volunteer opportunities you could take or initiatives for which you could push?</p>',
      };
    case 17:
      return {
        strengthName: 'Gratitude',
        strengthDescription:
          '<p>Pretend you’re a foreigner visiting your home community for the first time. What would stick out to you? What might seem strange, confusing, or even wrong initially? What aspects of your personal life might fall under these labels? Create a series of arguments for why things are the way they are. Then play devil’s advocate and argue for why they should be different. Try to be as objective as possible when making these comparisons. Reflect on your feelings afterward. Is there anything you’ve been doing in your personal or professional life simply out of habit or tradition that could be done more efficiently or ethically? Is there anything you might want to consider avoiding?</p>',
      };
  }
};
