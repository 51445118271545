import React, { Component } from 'react';
import SelectOutdoorActivitiesView from './SelectOutdoorActivitiesView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import { OUTDOORACT } from 'constants/index';

const outdoorActivitiesMaping = Object.fromEntries(
  OUTDOORACT.map(nightlife => [
    nightlife,
    nightlife
      .toLowerCase()
      .replaceAll(/\s/g, '_')
      .replaceAll('_&', '')
      .replaceAll(',', ''),
  ]),
);

class SelectOutdoorActivitiesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adventure_sports: 0,
      beaches: 0,
      biking: 0,
      camping: 0,
      golf: 0,
      hiking: 0,
      horseback_riding: 0,
      other_outdoor_activities: 0,
      parks_gardens: 0,
      scenic_views: 0,
      water_sports: 0,
      wild_life: 0,
      winter_sports: 0,
    };
  }
  componentDidMount() {
    const {
      adventure_sports,
      beaches,
      biking,
      camping,
      golf,
      hiking,
      horseback_riding,
      other_outdoor_activities,
      parks_gardens,
      scenic_views,
      water_sports,
      wild_life,
      winter_sports,
    } = this.props;
    this.setState({
      adventure_sports,
      beaches,
      biking,
      camping,
      golf,
      hiking,
      horseback_riding,
      other_outdoor_activities,
      parks_gardens,
      scenic_views,
      water_sports,
      wild_life,
      winter_sports,
    });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const {
      adventure_sports,
      beaches,
      biking,
      camping,
      golf,
      hiking,
      horseback_riding,
      other_outdoor_activities,
      parks_gardens,
      scenic_views,
      water_sports,
      wild_life,
      winter_sports,
    } = this.state;
    changeCurrentStep(step);
    saveTripData({
      outdoor_activities: {
        adventure_sports,
        beaches,
        biking,
        camping,
        golf,
        hiking,
        horseback_riding,
        other_outdoor_activities,
        parks_gardens,
        scenic_views,
        water_sports,
        wild_life,
        winter_sports,
      },
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <SelectOutdoorActivitiesView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        outdoorActivitiesMaping={outdoorActivitiesMaping}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    adventure_sports: state.tripReducer.tripForm.outdoor_activities.adventure_sports,
    beaches: state.tripReducer.tripForm.outdoor_activities.beaches,
    biking: state.tripReducer.tripForm.outdoor_activities.biking,
    camping: state.tripReducer.tripForm.outdoor_activities.camping,
    golf: state.tripReducer.tripForm.outdoor_activities.golf,
    hiking: state.tripReducer.tripForm.outdoor_activities.hiking,
    horseback_riding: state.tripReducer.tripForm.outdoor_activities.horseback_riding,
    other_outdoor_activities:
      state.tripReducer.tripForm.outdoor_activities.other_outdoor_activities,
    parks_gardens: state.tripReducer.tripForm.outdoor_activities.parks_gardens,
    scenic_views: state.tripReducer.tripForm.outdoor_activities.scenic_views,
    water_sports: state.tripReducer.tripForm.outdoor_activities.water_sports,
    wild_life: state.tripReducer.tripForm.outdoor_activities.wild_life,
    winter_sports: state.tripReducer.tripForm.outdoor_activities.winter_sports,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectOutdoorActivitiesContainer);
