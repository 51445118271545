import styled from 'styled-components';

const Input = styled.input`
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  height: ${props => (props.height ? props.height : 44)}px;
  margin-top: 15px;
  padding: 10px;
  width: 100%;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

export default Input;
