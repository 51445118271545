import React, { Component } from 'react';
import PreTripCoursePreviewView from './PreTripCoursePreviewView';
import { connect } from 'react-redux';
import * as preTripCourseActions from '../../actions/preTripCourseActions';
import config from 'config/appConfig';

class PreTripCoursesContainer extends Component {
  state = { numPages: null, pageNumber: 1, width: null };

  componentDidMount() {
    const {
      getPreTripCourse,
      match: { params },
    } = this.props;
    getPreTripCourse(params.id);

    this.setDivSize();
    window.addEventListener('resize', this.setDivSize);
  }

  setDivSize = () => {
    let width;
    if (window.innerWidth < 700) {
      width = window.innerWidth;
    } else if (window.innerWidth < 1300) {
      width = window.innerWidth - 300;
    } else {
      width = window.innerWidth - 500;
    }
    this.setState({ width });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  changePage = pageNumber => {
    this.setState({ pageNumber });
  };

  render() {
    const { numPages, pageNumber, width } = this.state;

    const {
      match: { params },
    } = this.props;
    return (
      <PreTripCoursePreviewView
        {...this.props}
        setDivSize={this.setDivSize}
        onDocumentLoadSuccess={this.onDocumentLoadSuccess}
        changePage={this.changePage}
        numPages={numPages}
        pageNumber={pageNumber}
        width={width}
        buyoption={params.buyoption}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    preTripCourse: state.preTripCourseReducer.preTripCourse,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getPreTripCourse: id => dispatch(preTripCourseActions.getPreTripCourse(id)),
    buyPreTripCourse: preTripCourse =>
      dispatch(preTripCourseActions.buyPreTripCourse(preTripCourse)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreTripCoursesContainer);
