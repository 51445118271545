import { put, call } from 'redux-saga/effects';
import * as loginActions from 'actions/loginActions';
import * as appActions from 'actions/app';
import * as tripActions from '../actions/tripActions';

export function* loginEmailAsync(api, action) {
  const response = yield call(api.loginEmail, { email: action.email, password: action.password });
  if (response.status == 200) {
    yield call(api.setAuthorization, response.data.token);
    yield put(loginActions.onLoginResponse(response));
    yield put(appActions.showAlert('Hello! And welcome!', { variant: 'success' }));
  } else {
    yield put(loginActions.loginFailed());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* loginFacebookAsync(api, action) {
  const response = yield call(api.loginFacebok, action.accessToken);
  if (response.status == 200) {
    yield call(api.setAuthorization, response.data.token);
    yield put(loginActions.onLoginResponse(response));
    yield put(appActions.showAlert('Hello! And welcome!', { variant: 'success' }));
  } else {
    yield put(loginActions.loginFailed());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* loginGoogleAsync(api, action) {
  const response = yield call(api.loginGoogle, action.accessToken);
  if (response.status == 200) {
    yield call(api.setAuthorization, response.data.token);
    yield put(loginActions.onLoginResponse(response));
    yield put(appActions.showAlert('Hello! And welcome!', { variant: 'success' }));
  } else {
    yield put(loginActions.loginFailed());
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* recoverPassword(api, action) {
  const response = yield call(api.recoverPasswordRequest, action.email);
  if (response.status == 200) {
    yield put(appActions.showAlert('E-mail sent!', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
export function* resetPassword(api, action) {
  const response = yield call(api.resetPasswordRequest, action);
  if (response.status == 200) {
    yield put(appActions.showAlert('Your password has been reset!', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* logout(api) {
  yield call(api.removeAuthToken);
  yield put(tripActions.resetTripForm());
}
