import React from 'react';
import styled from 'styled-components';
import Modal from 'components/Modal';
import ListEmpty from 'components/ListEmpty';
import Pagination from 'components/PaginationButtons';
import Loader from 'components/Loader';
import BoutiquePackageListItem from 'components/RequestBoutiquePackage/BoutiquePackageListItem';

const parse = require('html-react-parser');

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const ModalContent = styled.div`
  padding: 0px 20px;
  justify-content: left;
`;

const Primary = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
  text-align: left;
`;
const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 14px;
  text-align: left;
`;

const Input = styled.input`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 25)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const Select = styled.select`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  margin-bottom: 25px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const TBody = styled.div``;

const isDisabled = status => {
  if (!status) {
    return true;
  }
  if (status == 'approved') {
    return true;
  }

  return false;
};

const RequestsOfBoutiquePackagesView = props => {
  const {
    list,
    count,
    currentPage,
    loading,
    changePage,
    elementsPerPage,
    toggleModal,
    isOpen,
    handleChange,
    requestSelected,
    price,
    day_price,
    status,
    save,
  } = props;

  if (loading) {
    return (
      <LoaderContainer>
        <Loader type="pulse" size={125} />
      </LoaderContainer>
    );
  }

  return (
    <>
      <Pagination
        currentPage={currentPage}
        changePage={changePage}
        totalPages={Math.ceil(count / elementsPerPage)}
      />
      <GridContainer>
        <THeader>
          <TitleContainer width={'30%'}>
            <Title>Name</Title>
          </TitleContainer>
          <TitleContainer width={'25%'}>
            <Title>Email</Title>
          </TitleContainer>
          <TitleContainer width={'20%'}>
            <Title>Status</Title>
          </TitleContainer>
          <TitleContainer width={'25%'}>
            <Title>Action</Title>
          </TitleContainer>
        </THeader>
        <TBody>
          {list.map((item, key) => (
            <BoutiquePackageListItem
              showDetails={() => toggleModal(true, item)}
              item={item}
              key={key}
            />
          ))}
          {(!list || !list.length) && (
            <ListEmpty message="There are currently no boutique packages" />
          )}
        </TBody>
      </GridContainer>

      <Modal
        closeIcon
        isVisible={isOpen}
        title="REQUEST DETAILS"
        cancelText="Cancel"
        onCancel={() => toggleModal(false, null)}
        successText="Save"
        onSuccess={save}
        disabled={isDisabled(status)}
      >
        {requestSelected && (
          <ModalContent>
            <Secondary>Request description:</Secondary>
            <Primary>{parse(requestSelected.description)}</Primary>
            <br />
            <Secondary>Estimated subscription price:</Secondary>$
            <Input
              placeholder=" Enter package subscription price"
              name="price"
              value={price}
              onChange={handleChange}
            />
            <br />
            <Secondary>Estimated price per day:</Secondary>$
            <Input
              placeholder=" Enter day price"
              name="day_price"
              value={day_price}
              onChange={handleChange}
            />
            <br />
            {isDisabled(status) ? (
              <Primary>This package has already been approved by the customer.</Primary>
            ) : (
              <>
                <Secondary>Status:</Secondary>
                <Select placeholder="Status" name="status" value={status} onChange={handleChange}>
                  <option>Select a status</option>
                  <option value={'approved_for_admin'}>Approve</option>
                  <option value={'rejected_for_admin'}>Reject</option>
                </Select>
              </>
            )}
          </ModalContent>
        )}
      </Modal>
    </>
  );
};

export default RequestsOfBoutiquePackagesView;
