export default {
	BASE_URL: 'https://api.authentictraveling.com',
	WEB_CLIENT_ID_GOOGLE: '251641170125-ta6jupm2fp7lebkfecku6m3ebtuc64bc.apps.googleusercontent.com',
	APP_ID_FACEBOOK: '446269779324702',
	STRIPE_PUBLIC_KEY: 'pk_test_YQUcuTZCN3cuWZ1HVxbnH5oH',
	MAPBOX_ACCESS_TOKEN:
		'pk.eyJ1IjoiYW5kcmV3c2NvdHQiLCJhIjoiY2l1d2FwdGZsMDRkZDJvb2VlYms3Znk1ZyJ9.Wx9gtdm-zf3pFewspAssJg',
	MAPBOX_BASE_URL: 'https://api.mapbox.com',
	INTERCOM_APP_ID: 'z6dnyqb2',
};
