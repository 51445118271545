/* App config for apis
 */
import config from '../config/appConfig';
const base_url = config.BASE_URL;
const mapbox_url = config.MAPBOX_BASE_URL;

const ApiConstants = {
  BASE_API: `${base_url}/api/v1`,
  // login
  LOGIN_EMAIL: `${base_url}/api/v1/token`,
  LOGIN_FACEBOOK: `${base_url}/api/v1/login-facebook`,
  LOGIN_GOOGLE: `${base_url}/api/v1/login-google`,
  RECOVER_PASSWORD: `${base_url}/api/v1/users/forgot`,
  RESET_PASSWORD: `${base_url}/api/v1/users/password-reset`,
  // customers
  GET_CUSTOMERS: `${base_url}/api/v1/users/customers`,
  GET_CUSTOMER: `${base_url}/api/v1/users`,
  GET_FRIENDS: `${base_url}/api/v1/users/friends`,
  // register
  REGISTER_REQUEST: `${base_url}/api/v1/register`,
  // well being
  DIAGNOSTIC_SAVE_WELL_BEING: `${base_url}/api/v1/test-well-being/create`,
  DIAGNOSTIC_GET_WELL_BEING: `${base_url}/api/v1/test-well-being/get-by-user`,
  // strengths
  DIAGNOSTIC_SAVE_STRENGTHS: `${base_url}/api/v1/test-strengths/create`,
  DIAGNOSTIC_GET_STRENGTHS: `${base_url}/api/v1/test-strengths/get-by-user`,
  // travel personality
  DIAGNOSTIC_SAVE_TRAVEL_PERSONALITY: `${base_url}/api/v1/test-travel-personality/create`,
  DIAGNOSTIC_GET_TRAVEL_PERSONALITY: `${base_url}/api/v1/test-travel-personality/get-by-user`,
  // products
  GET_AVAILABLE_PRODUCTS: `${base_url}/api/v1/products`,
  BOUTIQUE_PACKAGE: `${base_url}/api/v1/requests-boutique-packages`,
  // profile
  UPDATE_PROFILE: `${base_url}/api/v1/users/me`,
  // meeting
  GET_POSSIBLE_MEETING_HOUR: `${base_url}/api/v1/meetings/free-turns`,
  MEETING: `${base_url}/api/v1/meetings`,
  // trip
  GET_DESTINATIONS: `${base_url}/api/v1/cities`,
  TRIP: `${base_url}/api/v1/trips`,
  ITINERARY: `${base_url}/api/v1/itineraries`,
  //trip costs
  GET_COSTS: `${base_url}/api/v1/trips/get-costs`,
  UPDATE_COST: `${base_url}/api/v1/trips/update-costs`,
  // payment method
  PAYMENT_METHODS: `${base_url}/api/v1/payments/payment-methods`,
  SET_DEFAULT_METHODS: `${base_url}/api/v1/payments/set-default-payment-methods`,
  // activities
  ACTIVITIES: `${base_url}/api/v1/activities`,
  MAPBOX_GEOCODE: `${mapbox_url}/geocoding/v5/mapbox.places`,
  MAPBOX_DIRECTIONS_DRIVING: `${mapbox_url}/directions/v5/mapbox/driving`,
  MAPBOX_DIRECTIONS_WALKING: `${mapbox_url}/directions/v5/mapbox/walking`,
  ACTIVITIES_BY_CITY: `${base_url}/api/v1/activities-by-city`,
  RESTAURANTS_BY_CITY: `${base_url}/api/v1/restaurants-by-city`,
  ACCOMMODATIONS_BY_CITY: `${base_url}/api/v1/accommodations-by-city`,

  //restaurants
  RESTAURANTS: `${base_url}/api/v1/restaurants`,
  //accommodations
  ACCOMMODATIONS: `${base_url}/api/v1/accommodations`,

  // suggestions
  COUNTRIES: `${base_url}/api/v1/countries`,
  VISIT_SUGGESTIONS: `${base_url}/api/v1/visit-suggestions`,
  AVERAGE_DAILY_EXPENSES: `${base_url}/api/v1/average-daily-expenses`,
  ACCOMMODATION_PRICINGS: `${base_url}/api/v1/accommodation-pricings`,
  CLIMATES: `${base_url}/api/v1/climates`,
  LIFECYCLES: `${base_url}/api/v1/lifecycles`,
  CUISINE_SPECIALITIES: `${base_url}/api/v1/cuisine-specialities`,
  //transactions
  TRANSACTION: `${base_url}/api/v1/transactions`,
  //invoices
  INVOICE: `${base_url}/api/v1/invoices`,
  //city
  CITIES: `${base_url}/api/v1/cities`,

  //regions
  REGIONS: `${base_url}/api/v1/regions`,

  // ad ons
  PRE_TRIP_COURSE: `${base_url}/api/v1/pre-trip-courses`,
  PURCHASED_PRE_TRIP_COURSES: `${base_url}/api/v1/purchase-pre-trip-courses`,
  //journals
  JOURNALS: `${base_url}/api/v1/journals`,
  JOURNAL_CALLINGS: `${base_url}/api/v1/journal-callings`,
  JOURNAL_DEAPTURES: `${base_url}/api/v1/journal-deaptures`,
  JOURNAL_PREPARING_RETURNS: `${base_url}/api/v1/journal-preparing-returns`,
  JOURNAL_RETURNS: `${base_url}/api/v1/journal-returns`,
  JOURNAL_ROADS: `${base_url}/api/v1/journal-roads`,
  AT_PLANS: `${base_url}/api/v1/at-plans`,
  GOALS: `${base_url}/api/v1/goals`,
  COURSES: `${base_url}/api/v1/courses`,
};
export default ApiConstants;
