/*
 * Reducer actions related with customers
 */
import { ActionTypes } from 'constants/index';

export function getAccommodations(page, elementsPerPage) {
  return {
    type: ActionTypes.GET_ACCOMMODATIONS,
    page,
    elementsPerPage,
  };
}

export function getAccommodationsSuccess(payload) {
  return {
    type: ActionTypes.GET_ACCOMMODATIONS_SUCCESS,
    accommodations: payload.rows,
    accommodationsCount: payload.count,
  };
}

export function getAccommodation(id) {
  return {
    type: ActionTypes.GET_ACCOMMODATION,
    id,
  };
}

export function getAccommodationSuccess(accommodation) {
  return {
    type: ActionTypes.GET_ACCOMMODATION_SUCCESS,
    accommodation,
  };
}

export function refreshCoordinates(address) {
  return {
    type: ActionTypes.REFRESH_COORDINATES,
    address,
  };
}
export function refreshAddress(longLat) {
  return {
    type: ActionTypes.REFRESH_ADDRESS,
    longLat,
  };
}

export function saveAccommodation(accommodation) {
  return {
    type: ActionTypes.SAVE_ACCOMMODATION,
    accommodation,
  };
}

export function saveAccommodationSuccess() {
  return {
    type: ActionTypes.SAVE_ACCOMMODATION_SUCCESS,
  };
}

export function saveAccommodationFailure() {
  return {
    type: ActionTypes.SAVE_ACCOMMODATION_FAILURE,
  };
}

export function removeAccommodation(id) {
  return {
    type: ActionTypes.REMOVE_ACCOMMODATION,
    id,
  };
}

export function updateAccommodation(accommodation, id) {
  return {
    type: ActionTypes.UPDATE_ACCOMMODATION,
    accommodation,
    id,
  };
}

export function updateAccommodationSuccess() {
  return {
    type: ActionTypes.UPDATE_ACCOMMODATION_SUCCESS,
  };
}

export function updateAccommodationFailure() {
  return {
    type: ActionTypes.UPDATE_ACCOMMODATION_FAILURE,
  };
}

export function removeAccommodationSuccess() {
  return {
    type: ActionTypes.REMOVE_ACCOMMODATION_SUCCESS,
  };
}

export function removeAccommodationFailure() {
  return {
    type: ActionTypes.REMOVE_ACCOMMODATION_FAILURE,
  };
}
