export const defaultState = {
  localStep: 1,
  travel_personality_1: 2,
  travel_personality_2: 2,
  travel_personality_3: 2,
  travel_personality_4: 2,
  travel_personality_5: 2,
  travel_personality_6: 2,
  travel_personality_7: 2,
  travel_personality_8: 2,
  travel_personality_9: 2,
  travel_personality_10: 2,
};

// label: 'Being aware of what thoughts pass through my mind.',
//         name: 'mindfuldness_1',
//         options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Very Often'],
//         subtitle: 'How often have you experienced the following over the past week?',

export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        label: "You arrive in a new city after a long flight. What's the first thing that you do? ",
        name: 'travel_personality_1',
        options: [
          'Spend some time recuperating in your hotel room.',
          'Clean up a bit before heading out.',
          'Get out and explore—there’s so much to see!',
        ],
      };
    case 2:
      return {
        label: 'It’s the year 2100 and vacations to the Moon have now become a thing. Will you: ',

        name: 'travel_personality_2',
        options: [
          'Wait until these trips have been around for quite awhile and the price drops before even considering them. ',
          'Wait for a short period of time before you consider taking such a trip, just to be certain they’ve “worked all the bugs out',
          'Be willing to pay more to be one of the first to take a trip, enjoying the sense of discovery and adventure that comes with the journey. ',
        ],
      };
    case 3:
      return {
        label: 'You’re on a dock when great white sharks appear in the water. Do you:',

        name: 'travel_personality_3',
        options: [
          'Head back to shore to avoid danger.',
          'Continue standing on the dock.',
          'Walk right up to the edge so you can see the sharks clearly.',
        ],
      };
    case 4:
      return {
        label: 'Which of these is most likely on your bucket list? ',

        name: 'travel_personality_4',
        options: [
          'Relaxing on a beach in the Bahamas.',
          'Immersing yourself in the art and culture of Florence, Italy. ',
          'Marching with the penguins in Antarctica.',
        ],
      };
    case 5:
      return {
        label: 'When packing for a trip do you: ',

        name: 'travel_personality_5',
        options: [
          'Fold things carefully and deliberately. ',
          'Put things away at a moderate pace.',
          'Quickly toss what you need in your suitcase.',
        ],
      };
    case 6:
      return {
        label: 'If you were going to take a short vacation, would you rather:',

        name: 'travel_personality_6',
        options: [
          'Drive to the destination',
          'Fly but stay in one place to relax once you get there.',
          'Fly and rent a car to explore the surrounding area.',
        ],
      };
    case 7:
      return {
        label: 'A friend invites you to go hiking one Saturday afternoon. Your response is: ',

        name: 'travel_personality_7',
        options: [
          'Why would anyone want to do that?',
          'The idea sounds nice but this couch is too comfortable to leave.',
          'Let’s go!',
        ],
      };
    case 8:
      return {
        label: 'Which of the following best describes you:',

        name: 'travel_personality_8',
        options: [
          'You have a wide circle of friends and spend lots of time socializing.',
          'You enjoy the company of others but sometimes need to be alone. ',
          'You have a small circle of close friends who you see occasionally. ',
        ],
      };
    case 9:
      return {
        label: 'Which of the following best describes your relationship with stress and pressure: ',

        name: 'travel_personality_9',
        options: [
          'You are regularly overwhelmed by it.',
          'You are able to handle it usually, but will occasionally feel overwhelmed.[',
          'These things do not cause you much anxiety. ',
        ],
      };
    case 10:
      return {
        label: 'You’re at a world-class restaurant in Paris deciding what to eat. Do you: ',

        name: 'travel_personality_10',
        options: [
          'Think about what to eat carefully and cautiously. ',
          'Make a decision relatively quickly but question it later once everyone’s food arrives.',
          'Make a decision quickly and easily, knowing that whatever you pick, it will be delicious. ',
        ],
      };
  }
};
