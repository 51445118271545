import { ActionTypes } from 'constants/index';

//Countries
export function getCountriesRequest(page, elementsPerPage, search) {
  return {
    type: ActionTypes.GET_COUNTRIES_REQUEST,
    page,
    elementsPerPage,
    search,
  };
}
export function getCountriesSuccess(data) {
  return {
    type: ActionTypes.GET_COUNTRIES_SUCCESS,
    countries: data.rows,
    countriesCount: data.count,
  };
}
export function getCountriesFailure() {
  return {
    type: ActionTypes.GET_COUNTRIES_FAILURE,
  };
}
export function getCountryRequest(id) {
  return {
    type: ActionTypes.GET_COUNTRY_REQUEST,
    id,
  };
}
export function getCountrySuccess(country) {
  return {
    type: ActionTypes.GET_COUNTRY_SUCCESS,
    country,
  };
}
export function getCountryFailure() {
  return {
    type: ActionTypes.GET_COUNTRY_FAILURE,
  };
}

//Visit Suggestions
export function getVisitRequest(id) {
  return {
    type: ActionTypes.GET_VISIT_REQUEST,
    id,
  };
}
export function getVisitSuccess(visit_suggestion) {
  return {
    type: ActionTypes.GET_VISIT_SUCCESS,
    visit_suggestion,
  };
}
export function getVisitFailure() {
  return {
    type: ActionTypes.GET_VISIT_FAILURE,
  };
}
export function saveVisitRequest(id, visit_suggestion) {
  return {
    type: ActionTypes.SAVE_VISIT_REQUEST,
    id,
    visit_suggestion,
  };
}
export function saveVisitSuccess(visit_suggestion) {
  return {
    type: ActionTypes.SAVE_VISIT_SUCCESS,
    visit_suggestion,
  };
}
export function saveVisitFailure() {
  return {
    type: ActionTypes.SAVE_VISIT_FAILURE,
  };
}

//Average Daily Expenses
export function getAverageDailyExpensesRequest(id) {
  return {
    type: ActionTypes.GET_AVERAGE_DAILY_EXPENSES_REQUEST,
    id,
  };
}
export function getAverageDailyExpensesSuccess(daily_expenses) {
  return {
    type: ActionTypes.GET_AVERAGE_DAILY_EXPENSES_SUCCESS,
    daily_expenses,
  };
}
export function getAverageDailyExpensesFailure() {
  return {
    type: ActionTypes.GET_AVERAGE_DAILY_EXPENSES_FAILURE,
  };
}
export function saveAverageDailyExpensesRequest(id, daily_expenses) {
  return {
    type: ActionTypes.SAVE_AVERAGE_DAILY_EXPENSES_REQUEST,
    id,
    daily_expenses,
  };
}
export function saveAverageDailyExpensesSuccess(daily_expenses) {
  return {
    type: ActionTypes.SAVE_AVERAGE_DAILY_EXPENSES_SUCCESS,
    daily_expenses,
  };
}
export function saveAverageDailyExpensesFailure() {
  return {
    type: ActionTypes.SAVE_AVERAGE_DAILY_EXPENSES_FAILURE,
  };
}

//Accommodation Pricings
export function getAccommodationPricingRequest(id) {
  return {
    type: ActionTypes.GET_ACCOMMODATION_PRICING_REQUEST,
    id,
  };
}
export function getAccommodationPricingSuccess(accommodation_pricing) {
  return {
    type: ActionTypes.GET_ACCOMMODATION_PRICING_SUCCESS,
    accommodation_pricing,
  };
}
export function getAccommodationPricingFailure() {
  return {
    type: ActionTypes.GET_ACCOMMODATION_PRICING_FAILURE,
  };
}
export function saveAccommodationPricingRequest(id, accommodation_pricing) {
  return {
    type: ActionTypes.SAVE_ACCOMMODATION_PRICING_REQUEST,
    id,
    accommodation_pricing,
  };
}
export function saveAccommodationPricingSuccess(accommodation_pricing) {
  return {
    type: ActionTypes.SAVE_ACCOMMODATION_PRICING_SUCCESS,
    accommodation_pricing,
  };
}
export function saveAccommodationPricingFailure() {
  return {
    type: ActionTypes.SAVE_ACCOMMODATION_PRICING_FAILURE,
  };
}

//Climates

export function getClimateRequest(params) {
  return {
    type: ActionTypes.GET_CLIMATE_REQUEST,
    params,
  };
}
export function getClimateSuccess(payload) {
  return {
    type: ActionTypes.GET_CLIMATE_SUCCESS,
    climates: payload.rows,
    climatesCount: payload.count,
  };
}
export function getClimateFailure() {
  return {
    type: ActionTypes.GET_CLIMATE_FAILURE,
  };
}

export function saveClimateRequest(id, climate) {
  return {
    type: ActionTypes.SAVE_CLIMATE_REQUEST,
    id,
    climate,
  };
}
export function saveClimateSuccess() {
  return {
    type: ActionTypes.SAVE_CLIMATE_SUCCESS,
  };
}

export function setClimate(climate) {
  return {
    type: ActionTypes.SET_CLIMATE,
    climate,
  };
}

export function saveClimateFailure() {
  return {
    type: ActionTypes.SAVE_CLIMATE_FAILURE,
  };
}

export function updateClimate(id, climate) {
  return {
    type: ActionTypes.UPDATE_CLIMATE,
    id,
    climate,
  };
}

export function removeClimate(id) {
  return {
    type: ActionTypes.REMOVE_CLIMATE,
    id,
  };
}

export function removeClimateSuccess(id) {
  return {
    type: ActionTypes.REMOVE_CLIMATE_SUCCESS,
    id,
  };
}

//
export function getLifecyclesRequest(params) {
  return {
    type: ActionTypes.GET_LIFECYCLES_REQUEST,
    params,
  };
}

export function getLifecyclesSuccess(payload) {
  return {
    type: ActionTypes.GET_LIFECYCLES_SUCCESS,
    payload,
  };
}

export function getLifecyclesFailure() {
  return {
    type: ActionTypes.GET_LIFECYCLES_FAILURE,
  };
}

export function getLifecycleRequest(id) {
  return {
    type: ActionTypes.GET_LIFECYCLE_REQUEST,
    id,
  };
}

export function getLifecycleSuccess(payload) {
  return {
    type: ActionTypes.GET_LIFECYCLE_SUCCESS,
    payload,
  };
}

export function getLifecycleFailure() {
  return {
    type: ActionTypes.GET_LIFECYCLE_FAILURE,
  };
}

export function removeLifecycleRequest(id) {
  return {
    type: ActionTypes.REMOVE_LIFECYCLE_REQUEST,
    id,
  };
}

export function removeLifecycleSuccess(id) {
  return {
    type: ActionTypes.REMOVE_LIFECYCLE_SUCCESS,
    id,
  };
}

export function removeLifecycleFailure() {
  return {
    type: ActionTypes.REMOVE_LIFECYCLE_FAILURE,
  };
}

export function saveLifecycle(id, payload) {
  return {
    type: ActionTypes.SAVE_LIFECYCLE_REQUEST,
    id,
    payload,
  };
}

export function saveLifecycleSuccess() {
  return {
    type: ActionTypes.SAVE_LIFECYCLE_SUCCESS,
  };
}

export function saveLifecycleFailure() {
  return {
    type: ActionTypes.SAVE_LIFECYCLE_FAILURE,
  };
}

export function showLifecycleForm(showForm) {
  return {
    type: ActionTypes.SHOW_LIFECYCLE_FORM,
    showForm,
  };
}

export function getCuisineSpecialitiesRequest(params) {
  return {
    type: ActionTypes.GET_CUISINE_SPECIALITIES_REQUEST,
    params,
  };
}

export function getCuisineSpecialitiesSuccess(payload) {
  return {
    type: ActionTypes.GET_CUISINE_SPECIALITIES_SUCCESS,
    payload,
  };
}

export function getCuisineSpecialitiesFailure() {
  return {
    type: ActionTypes.GET_CUISINE_SPECIALITIES_FAILURE,
  };
}

export function getCuisineSpecialityRequest(id) {
  return {
    type: ActionTypes.GET_CUISINE_SPECIALITY_REQUEST,
    id,
  };
}

export function getCuisineSpecialitySuccess(payload) {
  return {
    type: ActionTypes.GET_CUISINE_SPECIALITY_SUCCESS,
    payload,
  };
}

export function getCuisineSpecialityFailure() {
  return {
    type: ActionTypes.GET_CUISINE_SPECIALITY_FAILURE,
  };
}

export function removeCuisineSpecialityRequest(id) {
  return {
    type: ActionTypes.REMOVE_CUISINE_SPECIALITY_REQUEST,
    id,
  };
}

export function removeCuisineSpecialitySuccess(id) {
  return {
    type: ActionTypes.REMOVE_CUISINE_SPECIALITY_SUCCESS,
    id,
  };
}

export function removeCuisineSpecialityFailure() {
  return {
    type: ActionTypes.REMOVE_CUISINE_SPECIALITY_FAILURE,
  };
}

export function saveCuisineSpeciality(id, payload) {
  return {
    type: ActionTypes.SAVE_CUISINE_SPECIALITY_REQUEST,
    id,
    payload,
  };
}

export function saveCuisineSpecialitySuccess() {
  return {
    type: ActionTypes.SAVE_CUISINE_SPECIALITY_SUCCESS,
  };
}

export function saveCuisineSpecialityFailure() {
  return {
    type: ActionTypes.SAVE_CUISINE_SPECIALITY_FAILURE,
  };
}

export function showCuisineSpecialityForm(showForm) {
  return {
    type: ActionTypes.SHOW_CUISINE_SPECIALITY_FORM,
    showForm,
  };
}

export function getCountriesForSelect(id) {
  return {
    type: ActionTypes.GET_COUNTRIES_FOR_SELECT,
    id,
  };
}

export function getCountriesForSelectSuccess(countriesForSelect) {
  return {
    type: ActionTypes.GET_COUNTRIES_FOR_SELECT_SUCCESS,
    countriesForSelect,
  };
}

export function getCountriesForSelectFailure() {
  return {
    type: ActionTypes.GET_COUNTRIES_FOR_SELECT_FAILURE,
  };
}
