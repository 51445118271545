import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import SelectedButton from '../../SelectedButton';
import PrimaryText from '../PrimaryText';
import { ENTERTAINMENT } from 'constants/index';
import RadioInput from 'components/RadioInput';

const SelectEntertainmentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SelectEntertainmentPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`;

class SelectEntertainmentView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { step, state, changeStep, handleChange, entertainmentMaping } = this.props;

    return (
      <SelectEntertainmentBox>
        <SelectEntertainmentPrimaryBox>
          <PrimaryText text="What types of entertainment interest you? Please select all that apply and rate your interest level low, medium, or high" />
          <Content>
            {ENTERTAINMENT.map(enrteainment => (
              <RadioInput
                style={{ marginBottom: 50 }}
                label={enrteainment}
                name={entertainmentMaping[enrteainment]}
                options={[
                  { numericValue: 0, text: 'Nothing' },
                  { numericValue: 1, text: 'Low' },
                  { numericValue: 2, text: 'Medium' },
                  { numericValue: 3, text: 'High' },
                ]}
                handleChange={handleChange}
                currentValue={state[entertainmentMaping[enrteainment]]}
                verticalAlign
              />
            ))}
          </Content>
          <StepButtons
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(step + 1)}
          />
        </SelectEntertainmentPrimaryBox>
      </SelectEntertainmentBox>
    );
  }
}

export default SelectEntertainmentView;
