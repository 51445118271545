import { ActionTypes } from 'constants/index';

export function createAuthenticPlanRequest(data) {
  return {
    type: ActionTypes.CREATE_AUTHENTIC_PLAN_REQUEST,
    data,
  };
}

export function getAuthenticPlanRequest(id) {
  return {
    type: ActionTypes.GET_AUTHENTIC_PLAN_REQUEST,
    id,
  };
}

export function getAuthenticPlanSuccess(authenticPlan) {
  return {
    type: ActionTypes.GET_AUTHENTIC_PLAN_SUCCESS,
    authenticPlan,
  };
}

export function updateAuthenticPlanRequest(id, data) {
  return {
    type: ActionTypes.UPDATE_AUTHENTIC_PLAN_REQUEST,
    id,
    data,
  };
}
