import React from 'react';
import styled from 'styled-components';
import RegisterHeader from '../../components/Register/RegisterHeader';
import './styles.css';
import StepOne from '../../components/Register/Steps/StepOneContainer';
import StepThree from '../../components/Register/Steps/StepThreeContainer';
import StepTwo from '../../components/Register/Steps/StepTwoContainer';
import { Elements } from 'react-stripe-elements';
import BackgroundImage from '../../../assets/media/images/register-background.jpg';

const RegisterDiv = styled.div`
  width: 100%;
  background-image: url(${BackgroundImage});
  background-position-x: center;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-blend-mode: multiply;
  text-align: left;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  overflow-x: scroll;
`;

const RegisterView = props => {
  return (
    <Elements>
      <RegisterDiv>
        <RegisterHeader step={props.step} />
        {props.step == 1 && <StepOne step={props.step} />}
        {props.step == 2 && <StepTwo step={props.step} />}
        {props.step == 3 && <StepThree step={props.step} />}
      </RegisterDiv>
    </Elements>
  );
};

export default RegisterView;
