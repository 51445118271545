import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  stops: [], //The stops / cities that were added (first row).
  days: [], //All the days that were added, (those of all stops / cities).
  daysSelected: [], //It is used to know what day is marked at each stop, This is to avoid losing the selected day when you change stop
  daysWithActivities: [], //Contains the dayId, stopId and a array with te activities.
  daySelectedId: null, //Current selected day.
  daysCount: 0,
  stopsCount: 0,
  itinerary: { title: '', start_date: null, end_date: null, type: null },
  itineraryForCustomer: null,
};

export default {
  itineraryReducer: handleActions(
    {
      [ActionTypes.SAVE_ITINERARY_DATA](state, action) {
        return {
          ...state,
          itinerary: { ...state.itinerary, ...action.data },
        };
      },

      [ActionTypes.ADD_STOP](state, action) {
        return {
          ...state,
          stops: [...state.stops, { id: state.stopsCount, ...action.stop }],
          stopsCount: state.stopsCount + 1,

          //select the new stop for can add a new day
          stopSelected: { id: state.stopsCount, ...action.stop },
          stopSelectedId: state.stopsCount,
          //add a day when add a stop
          days: [...state.days, { id: state.daysCount, stopId: state.stopsCount }],
          daysCount: state.daysCount + 1,
          daysWithActivities: [
            ...state.daysWithActivities,
            {
              dayId: state.daysCount,
              stopId: state.stopsCount,
              activities: [],
            },
          ],

          //select the last added day
          daySelectedId: state.days.length,
          daysSelected: [
            ...state.daysSelected,
            { stopId: state.stopsCount, dayId: state.days.length },
          ],
        };
      },
      [ActionTypes.SAVE_ACTIVITITIES_BY_CITIES](state, action) {
        return {
          ...state,
          activitiesByCity: action.data,
        };
      },
      [ActionTypes.SAVE_RESTAURANTS_BY_CITIES](state, action) {
        return {
          ...state,
          restaurantsByCity: action.data,
        };
      },
      [ActionTypes.SAVE_ACCOMMODATIONS_BY_CITIES](state, action) {
        return {
          ...state,
          accommodationsByCity: action.data,
        };
      },
      [ActionTypes.SELECT_STOP](state, action) {
        let daySelectedId = state.daysSelected.filter(day => day.stopId == action.stopSelectedId);
        daySelectedId = daySelectedId.length > 0 ? daySelectedId[0].dayId : null;

        return {
          ...state,
          stopSelected: action.stopSelected,
          stopSelectedId: action.stopSelected.id,
          daySelectedId,
        };
      },
      [ActionTypes.ADD_DAY](state) {
        return {
          ...state,
          days: [...state.days, { id: state.daysCount, stopId: state.stopSelectedId }],
          daysCount: state.daysCount + 1,
          daysWithActivities: [
            ...state.daysWithActivities,
            {
              dayId: state.daysCount,
              stopId: state.stopSelectedId,
              activities: [],
            },
          ],
        };
      },

      [ActionTypes.SELECT_DAY](state, action) {
        const existSelected = state.daysSelected.filter(day => day.stopId == state.stopSelectedId);

        return {
          ...state,
          daysSelected:
            state.daysSelected.length == 0
              ? [{ stopId: state.stopSelectedId, dayId: action.day.id }]
              : existSelected.length == 0
              ? [
                  ...state.daysSelected,
                  {
                    stopId: state.stopSelectedId,
                    dayId: action.day.id,
                  },
                ]
              : state.daysSelected.map((day, i) =>
                  day.stopId == state.stopSelectedId
                    ? {
                        stopId: state.stopSelectedId,
                        dayId: action.day.id,
                      }
                    : day,
                ),
          daySelectedId: action.day.id,
        };
      },

      [ActionTypes.ADD_ACTIVITY](state, action) {
        const existSelected = state.daysWithActivities.filter(
          act => act.dayId == state.daySelectedId,
        );

        return {
          ...state,
          daysWithActivities:
            existSelected.length == 0
              ? [
                  ...state.daysWithActivities,
                  {
                    dayId: state.daySelectedId,
                    stopId: state.stopSelectedId,
                    activities: [action.activity],
                  },
                ]
              : state.daysWithActivities.map((act, i) =>
                  act.dayId == state.daySelectedId
                    ? {
                        dayId: state.daySelectedId,
                        stopId: state.stopSelectedId,
                        activities: [...act.activities, action.activity],
                      }
                    : act,
                ),
        };
      },
      [ActionTypes.GET_ITINERARY_SUCCESS](state, action) {
        return {
          ...state,
          itineraryForCustomer: action.itinerary,
        };
      },

      [ActionTypes.INITIALIZE_ITINERARY](state, action) {
        const { itinerary } = action;
        const { end_date, id, is_default, start_date, status, trip_id, type, title } = itinerary;

        const stops = [];
        const days = [];
        const daysWithActivities = [];
        itinerary.stops.map((stop, stopidx) => {
          stops.push({
            id: stopidx,
            city: { id: stop.city_id, name: stop.city.name },
          });
          stop.days.map(day => {
            days.push({ id: days.length, stopId: stopidx });
            const activities = day.activities.map(act => ({
              ...act.activity,
              type: act.type,
              start_date: act.start_date,
            }));
            daysWithActivities.push({ dayId: days.length - 1, stopId: stopidx, activities });
          });
        });

        return {
          ...state,
          stops,
          days,
          daysWithActivities,
          stopSelectedId: stops.length ? 0 : null,
          daySelectedId: days.length ? 0 : null,
          daysSelected: [],
          daysCount: daysWithActivities.length,
          stopsCount: itinerary.stops.length,
          itinerary: {
            id,
            title: title || '',
            is_default,
            status,
            trip_id,
            type,
            end_date: end_date ? new Date(end_date) : null,
            start_date: start_date ? new Date(start_date) : null,
          },
        };
      },

      [ActionTypes.DELETE_STOP](state, action) {
        return {
          ...state,
          stops: state.stops.filter(stop => stop.id != action.id),
          days: state.days.filter(day => day.stopId != action.id),
          daysSelected: state.daysSelected.filter(daySelec => daySelec.stopId != action.id),
          daysWithActivities: state.daysWithActivities.filter(
            dayWithAct => dayWithAct.stopId != action.id,
          ),
          stopSelectedId: state.stops.length == 1 ? null : state.stopSelectedId,
          daySelectedId: state.stops.length == 1 ? null : state.daySelectedId,
        };
      },
      [ActionTypes.DELETE_DAY](state, action) {
        return {
          ...state,
          days: state.days.filter(day => day.id != action.id),
          daysSelected: state.daysSelected.filter(daySelec => daySelec.id != action.id),
          daysWithActivities: state.daysWithActivities.filter(
            dayWithAct => dayWithAct.dayId != action.id,
          ),
        };
      },
      [ActionTypes.DELETE_ACTIVITY](state, action) {
        return {
          ...state,
          daysWithActivities: state.daysWithActivities.map(dayWithAct => {
            return dayWithAct.dayId == state.daySelectedId &&
              dayWithAct.stopId == state.stopSelectedId
              ? {
                  ...dayWithAct,
                  activities: dayWithAct.activities.filter(
                    (activity, actIdx) => actIdx != action.id,
                  ),
                }
              : dayWithAct;
          }),
        };
      },
      [ActionTypes.MARK_ITINERARY_AS_DEFAULT](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.MARK_ITINERARY_AS_DEFAULT_SUCCESS](state) {
        return {
          ...state,
          loading: false,
          itineraryForCustomer: { ...state.itineraryForCustomer, is_default: true },
        };
      },
      [ActionTypes.MARK_ITINERARY_AS_DEFAULT_FAILURE](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
      [ActionTypes.GENERATE_ITINERARY_SUCCESS](state, action) {
        const newDaysWithActivities = [...state.daysWithActivities];
        const dwaIndex = newDaysWithActivities.findIndex(
          dwa =>
            dwa.stopId == action.itinerary_day.stopId && dwa.dayId == action.itinerary_day.dayId,
        );

        if (dwaIndex == -1) {
          newDaysWithActivities.push(action.itinerary_day);
        } else {
          newDaysWithActivities[dwaIndex] = action.itinerary_day;
        }
        return {
          ...state,
          loading: false,
          daysWithActivities: newDaysWithActivities,
        };
      },
    },
    appState,
  ),
};
