import React, { Component } from 'react';
import moment from 'moment';
import JournalListDaysView from './JournalListDaysView';
import { connect } from 'react-redux';
import * as journalActions from 'actions/journalActions';

class JournalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      getJournalRequest,
      match: { params },
    } = this.props;
    getJournalRequest(params.id);
  }

  render() {
    const { journal } = this.props;
    if (!journal) return null;

    return <JournalListDaysView step={journal.step} journal={journal} {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    journal: state.journalReducer.journal,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getJournalRequest: id => dispatch(journalActions.getJournalRequest(id)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JournalContainer);
