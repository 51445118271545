import React, { Component } from 'react';
import PdfView from 'components/PdfView';
import { connect } from 'react-redux';
import * as courseActions from 'actions/courseActions';
import config from 'config/appConfig';
import styled from 'styled-components';
import { replace } from 'modules/history';

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: #38b6ff;
`;

class CoursesDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { numPages: null, pageNumber: 1, width: null, file: null };
  }

  componentDidMount() {
    this.setDivSize();
    window.addEventListener('resize', this.setDivSize);

    const {
      match: { params },
    } = this.props;
    this.setState({ file: `${config.BASE_URL}/files/courses/${params.type}.pdf` });
  }

  setDivSize = () => {
    let width;
    if (window.innerWidth < 700) {
      width = window.innerWidth;
    } else if (window.innerWidth < 1300) {
      width = window.innerWidth - 300;
    } else {
      width = window.innerWidth - 500;
    }
    this.setState({ width });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  changePage = pageNumber => {
    this.setState({ pageNumber });
  };

  markAsCompleted = () => {
    const {
      match: { params },
      updateCourseStatusRequest,
    } = this.props;
    updateCourseStatusRequest(params.type);
  };

  render() {
    const { width, pageNumber, numPages, file } = this.state;

    const {
      match: { params },
    } = this.props;
    return (
      <>
        <Button onClick={() => replace('/courses')}>Back</Button>
        <PdfView
          onDocumentLoadSuccess={this.onDocumentLoadSuccess}
          width={width}
          pageNumber={pageNumber}
          numPages={numPages}
          changePage={this.changePage}
          file={file}
        />

        {params.status == 'pending' && (
          <Button style={{ float: 'right', marginBottom: '100px' }} onClick={this.markAsCompleted}>
            Mark as completed
          </Button>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    updateCourseStatusRequest: type => dispatch(courseActions.updateCourseStatusRequest(type)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoursesDetailContainer);
