export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        strengthName: '',
        strengthDescription:
          '<p>On this, the final day before your departure, give yourself a chance to unload any unnecessary baggage you might be carrying. Is there anything you can avoid bringing with on this journey, such as regrets, external expectations, past poor decisions, or broken promises? What kinds of beneficial things could you bring with instead? Envision how it will feel tomorrow to walk out into a world of adventure and growth with these things.</p>',
      };
    case 2:
      return {
        strengthName: 'Curiosity',
        strengthDescription:
          '<p> Today you’re taking the first—and hardest step—toward a life of adventure and growth: leaving the comfort of home. As you venture out into the greater world, take a moment to appreciate the courage and conviction it took to get here. Let your mind and body bask in this sense of accomplishment and know that all the pre-trip planning and preparation you’ve done will help make this a life-changing journey. We’ll be with you every step of the way, and cannot wait to share this saga with you.</p>',
      };
  }
};
