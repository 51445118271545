import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import { ActionTypes } from '../constants/index';
import { startupApp } from './startupSaga';
import { getCustomersAsync, getFriendsAsync, getCustomerDetailsAsync } from './customerSaga';
import {
  getActivitiesAsync,
  getActivityAsync,
  getMapboxCoordinates,
  getMapboxAddress,
  saveActivity,
  removeActivityAsync,
  updateActivityAsync,
  getMapboxDistanceTime,
} from './activitySaga';

import {
  getRestaurantsAsync,
  getRestaurantAsync,
  saveRestaurant,
  removeRestaurantAsync,
  updateRestaurantAsync,
} from './restaurantSaga';

import {
  getAccommodationsAsync,
  getAccommodationAsync,
  saveAccommodation,
  removeAccommodationAsync,
  updateAccommodationAsync,
} from './accommodationSaga';

import {
  getCountriesAsync,
  getCountryAsync,
  getVisitAsync,
  saveVisitAsync,
  getAverageDailyExpensesAsync,
  saveAverageDailyExpensesAsync,
  getAccommodationPricingAsync,
  saveAccommodationPricingAsync,
  getClimatesAsync,
  saveClimateAsync,
  updateClimate,
  removeClimateAsync,
  getLifecyclesAsync,
  getLifecycleAsync,
  removeLifecycleAsync,
  saveLifecycleAsync,
  getCuisineSpecialitiesAsync,
  getCuisineSpecialityAsync,
  removeCuisineSpecialityAsync,
  saveCuisineSpecialityAsync,
  getCountriesForSelect,
} from './suggestionSaga';

import {
  loginEmailAsync,
  loginFacebookAsync,
  loginGoogleAsync,
  recoverPassword,
  resetPassword,
  logout,
} from './loginSaga';
import {
  saveWellBeing,
  saveStrengths,
  saveTravelPersonality,
  getWellBeing,
  getSrengths,
  getTravelPersonality,
  updateDiagnosticStep,
  updateMeeting,
  getPossibleMeetingHours,
  saveMeeting,
} from './diagnosticSaga';
import { registerNewUser } from './registerSaga';
import { getAvailableProducts } from './productsSaga';
import {
  getDestinations,
  persistTrip,
  getTrip,
  getMeTrips,
  editTrip,
  editTripSimple,
  createTripChargeRequest,
  getAllTripsRequest,
  getCostsRequest,
  updateCostsRequest,
} from './tripSaga';
import {
  getActivitiesByCity,
  getRestaurantsByCity,
  getAccommodationsByCity,
  saveItinerary,
  generateItineraryRequest,
  getItinerary,
  markItineraryAsDefault,
} from './itinerarySaga';
import { getMeTransactions } from './transactionSaga';
import { getMeInvoices } from './invoiceSaga';

import { updateProfile, verifyDegradePlanRequest } from './profileSaga';
import Api from '../api';
import {
  getPaymentMethods,
  createPaymentMethod,
  deletePaymentMethod,
  setDefaultMethod,
} from './paymentMethodsSaga';
import { getMeetings, getMeetingRequest, getInitialMeetingRequest } from './meetingSaga';
import {
  createPreTripCourse,
  getPreTripCourses,
  getPreTripCourse,
  updatePreTripCourse,
  deletePreTripCourse,
  buyPreTripCourse,
  purchasePreTripCourses,
  allPurchasePreTripCourses,
} from './preTripCourseSaga';

import {
  updateJournalRequest,
  getJournalRequest,
  createDayCallingRequest,
  createDayDeaptureRequest,
  createDayTheReturnRequest,
  createDayPreparingReturnRequest,
  createDayRoadRequest,
  getCallingRequest,
  getDeaptureRequest,
  getPreparingReturnRequest,
  getReturnRequest,
  getRoadRequest,
} from './journalSaga';

import {
  createAuthenticPlanRequest,
  getAutehnticPlanRequest,
  updateAutehnticPlanRequest,
} from './authenticPlanSaga';

import {
  getRequestsBoutiquePackages,
  getRequestBoutiquePackage,
  getMyRequestBoutiquePackage,
  saveRequestBoutiquePackage,
  updateRequestBoutiquePackage,
  customerApprobeBoutiquePackageRequest,
} from './boutiquePackageSaga';

import { createGoalRequest, getGoalRequest, updateGoalRequest } from './goalSaga';

import {
  createCourseRequest,
  getCourseRequest,
  updateCourseRequest,
  getCustomerCoursesRequest,
  updateCourseStatusRequest,
} from './courseSaga';

import {
  createCities,
  getCities,
  editCities,
  getAllCities,
  removeCities,
  getCitiesForSelect,
} from './citiesSaga';

import {
  createRegions,
  getRegions,
  editRegions,
  getRegionsForSelect,
  getAllRegions,
  removeRegions,
} from './regionsSaga';

/**
 * rootSaga
 */
const api = Api.create();

export default function* root() {
  yield all([
    //startup
    takeLatest(ActionTypes.STARTUP, startupApp, api),
    //login
    takeLatest(ActionTypes.LOGIN_FACEBOOK_REQUEST, loginFacebookAsync, api),
    takeLatest(ActionTypes.LOGIN_GOOGLE_REQUEST, loginGoogleAsync, api),
    takeLatest(ActionTypes.LOGIN_EMAIL_REQUEST, loginEmailAsync, api),
    takeLatest(ActionTypes.RECOVER_PASSWORD, recoverPassword, api),
    takeLatest(ActionTypes.RESET_PASSWORD, resetPassword, api),
    takeLatest(ActionTypes.LOGOUT, logout, api),
    // register
    takeLatest(ActionTypes.REGISTER_REQUEST, registerNewUser, api),
    // customers
    takeLatest(ActionTypes.GET_CUSTOMERS, getCustomersAsync, api),
    takeLatest(ActionTypes.GET_FRIENDS, getFriendsAsync, api),
    takeLatest(ActionTypes.GET_CUSTOMER, getCustomerDetailsAsync, api),
    // well being
    takeLatest(ActionTypes.DIAGNOSTIC_CREATE_WELL_BEING, saveWellBeing, api),
    takeLatest(ActionTypes.DIAGNOSTIC_CREATE_STRENGTHS, saveStrengths, api),
    // strengths
    takeLatest(ActionTypes.DIAGNOSTIC_GET_STRENGTHS, getSrengths, api),
    takeLatest(ActionTypes.DIAGNOSTIC_GET_TRAVEL_PERSONALITY, getTravelPersonality, api),
    // travel personality
    takeLatest(ActionTypes.DIAGNOSTIC_CREATE_TRAVEL_PERSONALITY, saveTravelPersonality, api),
    takeLatest(ActionTypes.DIAGNOSTIC_GET_WELL_BEING, getWellBeing, api),
    //products
    takeLatest(ActionTypes.GET_AVAILABLE_PRODUCTS, getAvailableProducts, api),

    // request boutique
    takeLatest(ActionTypes.GET_REQUESTS_BOUTIQUE_PACKAGES, getRequestsBoutiquePackages, api),
    takeLatest(ActionTypes.GET_REQUEST_BOUTIQUE_PACKAGE, getRequestBoutiquePackage, api),
    takeLatest(ActionTypes.GET_MY_REQUEST_BOUTIQUE_PACKAGE, getMyRequestBoutiquePackage, api),
    takeLatest(ActionTypes.SAVE_PACKAGE_BOUTIQUE, saveRequestBoutiquePackage, api),
    takeLatest(ActionTypes.UPDATE_PACKAGE_BOUTIQUE, updateRequestBoutiquePackage, api),
    takeLatest(
      ActionTypes.CUSTOMER_APPROBE_BOUTIQUE_PACKAGE_REQUEST,
      customerApprobeBoutiquePackageRequest,
      api,
    ),

    //profile
    takeLatest(ActionTypes.UPDATE_PROFILE, updateProfile, api),
    takeLatest(ActionTypes.VERIFY_DEGRADE_PLAN_REQUEST, verifyDegradePlanRequest, api),
    // profile
    takeLatest(ActionTypes.UPDATE_DIAGNOSTIC_STEP_PERSIST, updateDiagnosticStep, api),
    // meeting
    takeLatest(ActionTypes.GET_POSSIBLE_MEETING_HOUR, getPossibleMeetingHours, api),
    takeLatest(ActionTypes.SAVE_MEETING, saveMeeting, api),
    takeLatest(ActionTypes.UPDATE_MEETING, updateMeeting, api),
    takeLatest(ActionTypes.GET_CUSTOMER_MEETINGS, getMeetings, api),
    takeLatest(ActionTypes.GET_MEETING_REQUEST, getMeetingRequest, api),
    takeLatest(ActionTypes.GET_INITIAL_MEETING_REQUEST, getInitialMeetingRequest, api),

    //trip
    takeLatest(ActionTypes.GET_DESTINATIONS, getDestinations, api),
    takeLatest(ActionTypes.SUBMIT_TRIP, persistTrip, api),
    takeLatest(ActionTypes.GET_TRIP, getTrip, api),
    takeLatest(ActionTypes.GET_ME_TRIPS, getMeTrips, api),
    takeLatest(ActionTypes.EDIT_TRIP, editTrip, api),
    takeLatest(ActionTypes.EDIT_TRIP_SIMPLE_REQUEST, editTripSimple, api),
    takeLatest(ActionTypes.CREATE_TRIP_CHARGE_REQUEST, createTripChargeRequest, api),
    takeLatest(ActionTypes.GET_ALL_TRIPS_REQUEST, getAllTripsRequest, api),
    //Trip costs
    takeLatest(ActionTypes.GET_COSTS, getCostsRequest, api),
    takeLatest(ActionTypes.UPDATE_COST, updateCostsRequest, api),
    // payment methods
    takeLatest(ActionTypes.GET_PAYMENT_METHODS, getPaymentMethods, api),
    takeLatest(ActionTypes.CREATE_PAYMENT_METHODS, createPaymentMethod, api),
    takeLatest(ActionTypes.DELETE_PAYMENT_METHOD, deletePaymentMethod, api),
    takeLatest(ActionTypes.SET_DEFAULT_METHOD, setDefaultMethod, api),

    // suggestions
    takeLatest(ActionTypes.GET_COUNTRIES_REQUEST, getCountriesAsync, api),
    takeLatest(ActionTypes.GET_COUNTRY_REQUEST, getCountryAsync, api),
    takeLatest(ActionTypes.GET_VISIT_REQUEST, getVisitAsync, api),
    takeLatest(ActionTypes.SAVE_VISIT_REQUEST, saveVisitAsync, api),
    takeLatest(ActionTypes.GET_AVERAGE_DAILY_EXPENSES_REQUEST, getAverageDailyExpensesAsync, api),
    takeLatest(ActionTypes.SAVE_AVERAGE_DAILY_EXPENSES_REQUEST, saveAverageDailyExpensesAsync, api),
    takeLatest(ActionTypes.GET_ACCOMMODATION_PRICING_REQUEST, getAccommodationPricingAsync, api),
    takeLatest(ActionTypes.SAVE_ACCOMMODATION_PRICING_REQUEST, saveAccommodationPricingAsync, api),
    takeLatest(ActionTypes.GET_CLIMATE_REQUEST, getClimatesAsync, api),
    takeLatest(ActionTypes.SAVE_CLIMATE_REQUEST, saveClimateAsync, api),
    takeLatest(ActionTypes.UPDATE_CLIMATE, updateClimate, api),
    takeLatest(ActionTypes.REMOVE_CLIMATE, removeClimateAsync, api),

    takeLatest(ActionTypes.GET_LIFECYCLES_REQUEST, getLifecyclesAsync, api),
    takeLatest(ActionTypes.GET_LIFECYCLE_REQUEST, getLifecycleAsync, api),
    takeLatest(ActionTypes.REMOVE_LIFECYCLE_REQUEST, removeLifecycleAsync, api),
    takeLatest(ActionTypes.SAVE_LIFECYCLE_REQUEST, saveLifecycleAsync, api),

    takeLatest(ActionTypes.GET_CUISINE_SPECIALITIES_REQUEST, getCuisineSpecialitiesAsync, api),
    takeLatest(ActionTypes.GET_CUISINE_SPECIALITY_REQUEST, getCuisineSpecialityAsync, api),
    takeLatest(ActionTypes.REMOVE_CUISINE_SPECIALITY_REQUEST, removeCuisineSpecialityAsync, api),
    takeLatest(ActionTypes.SAVE_CUISINE_SPECIALITY_REQUEST, saveCuisineSpecialityAsync, api),
    takeLatest(ActionTypes.GET_COUNTRIES_FOR_SELECT, getCountriesForSelect, api),

    // activities
    takeLatest(ActionTypes.GET_ACTIVITIES, getActivitiesAsync, api),
    takeLatest(ActionTypes.GET_ACTIVITY, getActivityAsync, api),
    takeLatest(ActionTypes.REFRESH_COORDINATES, getMapboxCoordinates, api),
    takeLatest(ActionTypes.REFRESH_ADDRESS, getMapboxAddress, api),
    takeLatest(ActionTypes.SAVE_ACTIVITY, saveActivity, api),
    takeLatest(ActionTypes.REMOVE_ACTIVITY, removeActivityAsync, api),
    takeLatest(ActionTypes.UPDATE_ACTIVITY, updateActivityAsync, api),
    takeEvery(ActionTypes.GET_DISTANCE_TIME, getMapboxDistanceTime, api),

    // restaurants
    takeLatest(ActionTypes.GET_RESTAURANTS, getRestaurantsAsync, api),
    takeLatest(ActionTypes.GET_RESTAURANT, getRestaurantAsync, api),
    //takeLatest(ActionTypes.REFRESH_COORDINATES, getMapboxCoordinates, api),
    //takeLatest(ActionTypes.REFRESH_ADDRESS, getMapboxAddress, api),
    takeLatest(ActionTypes.SAVE_RESTAURANT, saveRestaurant, api),
    takeLatest(ActionTypes.REMOVE_RESTAURANT, removeRestaurantAsync, api),
    takeLatest(ActionTypes.UPDATE_RESTAURANT, updateRestaurantAsync, api),
    //takeEvery(ActionTypes.GET_DISTANCE_TIME, getMapboxDistanceTime, api),

    // accommodations
    takeLatest(ActionTypes.GET_ACCOMMODATIONS, getAccommodationsAsync, api),
    takeLatest(ActionTypes.GET_ACCOMMODATION, getAccommodationAsync, api),
    //takeLatest(ActionTypes.REFRESH_COORDINATES, getMapboxCoordinates, api),
    //takeLatest(ActionTypes.REFRESH_ADDRESS, getMapboxAddress, api),
    takeLatest(ActionTypes.SAVE_ACCOMMODATION, saveAccommodation, api),
    takeLatest(ActionTypes.REMOVE_ACCOMMODATION, removeAccommodationAsync, api),
    takeLatest(ActionTypes.UPDATE_ACCOMMODATION, updateAccommodationAsync, api),
    //takeEvery(ActionTypes.GET_DISTANCE_TIME, getMapboxDistanceTime, api),

    //itinerary
    takeLatest(ActionTypes.GET_ACTIVITITIES_BY_CITIES, getActivitiesByCity, api),
    takeLatest(ActionTypes.GET_RESTAURANTS_BY_CITIES, getRestaurantsByCity, api),
    takeLatest(ActionTypes.GET_ACCOMMODATIONS_BY_CITIES, getAccommodationsByCity, api),

    takeLatest(ActionTypes.SAVE_ITINERARY, saveItinerary, api),
    takeLatest(ActionTypes.GENERATE_ITINERARY_REQUEST, generateItineraryRequest, api),
    takeLatest(ActionTypes.GET_ITINERARY, getItinerary, api),
    takeLatest(ActionTypes.MARK_ITINERARY_AS_DEFAULT, markItineraryAsDefault, api),

    //transactions
    takeLatest(ActionTypes.GET_ME_TRANSACTIONS, getMeTransactions, api),
    // invoices
    takeLatest(ActionTypes.GET_ME_INVOICES, getMeInvoices, api),
    //ad ons
    takeLatest(ActionTypes.CREATE_PRE_TRIP_COURSE, createPreTripCourse, api),
    takeLatest(ActionTypes.GET_PRE_TRIP_COURSES, getPreTripCourses, api),
    takeLatest(ActionTypes.GET_PRE_TRIP_COURSE, getPreTripCourse, api),
    takeLatest(ActionTypes.UPDATE_PRE_TRIP_COURSE, updatePreTripCourse, api),
    takeLatest(ActionTypes.DELETE_PRE_TRIP_COURSE, deletePreTripCourse, api),
    takeLatest(ActionTypes.BUY_PRE_TRIP_COURSE, buyPreTripCourse, api),
    takeLatest(ActionTypes.PURCHASED_PRE_TRIP_COURSES, purchasePreTripCourses, api),
    takeLatest(ActionTypes.ALL_PURCHASED_PRE_TRIP_COURSES, allPurchasePreTripCourses, api),
    // journal
    takeLatest(ActionTypes.UPDATE_JOURNAL_REQUEST, updateJournalRequest, api),
    takeLatest(ActionTypes.GET_JOURNAL_REQUEST, getJournalRequest, api),
    takeLatest(ActionTypes.CREATE_DAY_CALLING_REQUEST, createDayCallingRequest, api),
    takeLatest(ActionTypes.CREATE_DAY_DEAPTURE_REQUEST, createDayDeaptureRequest, api),
    takeLatest(
      ActionTypes.CREATE_DAY_PREPARING_RETURN_REQUEST,
      createDayPreparingReturnRequest,
      api,
    ),
    takeLatest(ActionTypes.CREATE_DAY_RETURN_REQUEST, createDayTheReturnRequest, api),
    takeLatest(ActionTypes.CREATE_DAY_ROAD_REQUEST, createDayRoadRequest, api),
    takeLatest(ActionTypes.GET_CALLING_REQUEST, getCallingRequest, api),
    takeLatest(ActionTypes.GET_DEAPTURE_REQUEST, getDeaptureRequest, api),
    takeLatest(ActionTypes.GET_PREPARING_RETURN_REQUEST, getPreparingReturnRequest, api),
    takeLatest(ActionTypes.GET_RETURN_REQUEST, getReturnRequest, api),
    takeLatest(ActionTypes.GET_ROAD_REQUEST, getRoadRequest, api),

    //authentic plan
    takeLatest(ActionTypes.CREATE_AUTHENTIC_PLAN_REQUEST, createAuthenticPlanRequest, api),
    takeLatest(ActionTypes.GET_AUTHENTIC_PLAN_REQUEST, getAutehnticPlanRequest, api),
    takeLatest(ActionTypes.UPDATE_AUTHENTIC_PLAN_REQUEST, updateAutehnticPlanRequest, api),

    // goal
    takeLatest(ActionTypes.CREATE_GOAL_REQUEST, createGoalRequest, api),
    takeLatest(ActionTypes.GET_GOAL_REQUEST, getGoalRequest, api),
    takeLatest(ActionTypes.UPDATE_GOAL_REQUEST, updateGoalRequest, api),

    //course
    takeLatest(ActionTypes.CREATE_COURSE_REQUEST, createCourseRequest, api),
    takeLatest(ActionTypes.GET_CUSTOMER_COURSES_REQUEST, getCustomerCoursesRequest, api),
    takeLatest(ActionTypes.UPDATE_COURSE_STATUS_REQUEST, updateCourseStatusRequest, api),

    //cities
    takeLatest(ActionTypes.CREATE_CITIES, createCities, api),
    takeLatest(ActionTypes.GET_CITIES, getCities, api),
    takeLatest(ActionTypes.EDIT_CITIES, editCities, api),
    takeLatest(ActionTypes.GET_ALL_CITIES, getAllCities, api),
    takeLatest(ActionTypes.REMOVE_CITIES, removeCities, api),
    takeLatest(ActionTypes.GET_CITIES_FOR_SELECT, getCitiesForSelect, api),

    //regions
    takeLatest(ActionTypes.CREATE_REGIONS, createRegions, api),
    takeLatest(ActionTypes.GET_REGIONS, getRegions, api),
    takeLatest(ActionTypes.EDIT_REGIONS, editRegions, api),
    takeLatest(ActionTypes.GET_REGIONS_FOR_SELECT, getRegionsForSelect, api),
    takeLatest(ActionTypes.GET_ALL_REGIONS, getAllRegions, api),
    takeLatest(ActionTypes.REMOVE_REGIONS, removeRegions, api),
  ]);
}
