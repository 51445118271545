import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  customers: [],
  friends: [],
  customer: null,
  loading: false,
};

export default {
  customerReducer: handleActions(
    {
      [ActionTypes.GET_CUSTOMERS](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.SAVE_CUSTOMERS](state, action) {
        return {
          ...state,
          customers: action.customers,
          customersCount: action.customersCount,
          loading: false,
        };
      },
      [ActionTypes.GET_FRIENDS](state, action) {
        return {
          ...state,
          loading: true,
          friends: [],
        };
      },

      [ActionTypes.GET_FRIENDS_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
          friends: action.friends.rows,
        };
      },

      [ActionTypes.GET_CUSTOMER](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_CUSTOMER_SUCCESS](state, action) {
        return {
          ...state,
          customer: action.customer,
          loading: false,
        };
      },
      [ActionTypes.CREATE_COURSE_SUCCESS](state, action) {
        return {
          ...state,
          customer: { ...state.customer, courses: [...state.customer.courses, action.course] },
        };
      },
      [ActionTypes.UPDATE_MEETING_SUCCESS](state, action) {
        const newProfile = { ...state.customer.profile };
        const meetings = [...state.customer.profile.meetings];
        let meetingId = meetings.findIndex(meet => meet.id == action.meeting.id);
        meetings[meetingId] = { ...meetings[meetingId], ...action.meeting };
        newProfile.meetings = meetings;

        return {
          ...state,
          customer: { ...state.customer, profile: newProfile },
        };
      },
    },
    appState,
  ),
};
