import React, { Component } from 'react';
import styled from 'styled-components';
import LoginButtons from './LoginButtons';
import CheckboxRemember from './CheckboxRemember';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../Input';

const EmailLoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 20px auto 50px auto;
`;
export default class EmailLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
      password: this.props.password,
      remember: this.props.remember,
    };
  }
  sendEmailLogin = values => {
    const { emailLogin } = this.props;
    const { email, password, remember } = values;
    emailLogin(email, password, remember);
  };

  handleChangeCheckbox = evt => {
    this.setState({ remember: evt.target.checked });
  };
  isInitialValid = props => {
    if (!props.validationSchema) return true;
    return props.validationSchema.isValidSync(props.initialValues);
  };

  render() {
    const { loading } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={this.state}
        isInitialValid={this.isInitialValid}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Required')
            .email('Invalid Format'),
          password: Yup.string().required('Required'),
        })}
      >
        {({ values, handleChange, handleBlur, isValid }) => {
          return (
            <>
              <EmailLoginFormContainer>
                <Input
                  height={60}
                  placeholder="Username | Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  height={60}
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  secureTextEntry={true}
                  onBlur={handleBlur}
                />
                <CheckboxRemember
                  label={'Remember me.'}
                  name="remember"
                  isChecked={values.remember}
                  toggleChange={handleChange}
                />

                <LoginButtons
                  sendEmailLogin={() => this.sendEmailLogin(values)}
                  isValid={isValid || !loading}
                />
              </EmailLoginFormContainer>
            </>
          );
        }}
      </Formik>
    );
  }
}
