import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  goal: null,
};

export default {
  goalReducer: handleActions(
    {
      [ActionTypes.GET_GOAL_SUCCESS](state, action) {
        return {
          ...state,
          goal: action.goal,
        };
      },
    },
    appState,
  ),
};
