import React, { Component } from 'react';
import StrengthsTestView from './StrengthsTestView';
import { connect } from 'react-redux';
import * as diagnosticActions from '../../../actions/diagnosticActions';
import { selectFields, defaultState } from './StepFields/DiagnosticStrengths';

class StrengthsTest extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount() {
    const { strengths, step, previusStep } = this.props;
    if (strengths) {
      this.setState({ ...strengths, localStep: step < previusStep ? 58 : 1 });
    }
  }

  changeStep = step => {
    if (1 <= step && step <= 58) {
      this.setState({ localStep: step });
    } else {
      const { changeCurrentStep, saveStrengthsTest } = this.props;
      const globalStep = step < 1 ? 3 : 6; // 3 = well being / 6 = travel personality
      changeCurrentStep(globalStep);
      saveStrengthsTest(this.state);
    }
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    let stepFields = selectFields(this.state.localStep);
    return (
      <StrengthsTestView
        {...this.props}
        changeStep={this.changeStep}
        data={this.state}
        stepFields={stepFields}
        handleChange={this.handleChange}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    strengths: state.diagnosticReducer.strengths,
    step: state.diagnosticReducer.step,
    previusStep: state.diagnosticReducer.previusStep,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveStrengthsTest: strengths => dispatch(diagnosticActions.saveStrengths(strengths)),
    changeCurrentStep: step => dispatch(diagnosticActions.changeCurrentStep(step)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StrengthsTest);
