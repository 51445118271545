import React, { Component } from 'react';
import WellBeingTestView from './WellBeingTestView';
import { connect } from 'react-redux';
import * as diagnosticActions from '../../../actions/diagnosticActions';
import { selectFields, defaultState } from './StepFields/DiagnosticWellBeing';

class WellBeingTestContainer extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }
  componentDidMount() {
    const { wellBeings, step, previusStep } = this.props;
    if (wellBeings) {
      this.setState({ ...wellBeings, localStep: step < previusStep ? 8 : 1 });
    }
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  changeStep = step => {
    if (1 <= step && step <= 8) {
      this.setState({ localStep: step });
    } else {
      const { changeCurrentStep, saveWellBeingTest } = this.props;
      const globalStep = step < 1 ? 1 : 4; // 1 = general concepts / 4 = strengths
      changeCurrentStep(globalStep);
      saveWellBeingTest(this.state);
    }
  };

  render() {
    const stepFields = selectFields(this.state.localStep);

    return (
      <WellBeingTestView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        stepFields={stepFields}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    wellBeings: state.diagnosticReducer.wellBeings,
    step: state.diagnosticReducer.step,
    previusStep: state.diagnosticReducer.previusStep,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveWellBeingTest: wellBeings => dispatch(diagnosticActions.saveWellBeing(wellBeings)),
    changeCurrentStep: step => dispatch(diagnosticActions.changeCurrentStep(step)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WellBeingTestContainer);
