import { put, call } from 'redux-saga/effects';
import * as preTripCourseActions from '../actions/preTripCourseActions';
import * as appActions from 'actions/app';
import { replace } from 'modules/history';

export function* createPreTripCourse(api, action) {
  //yield put(preTripCourseActions.enableLoader());
  const response = yield call(api.createPreTripCourse, action.preTripCourse);
  if (response.status == 200) {
    yield put(appActions.showAlert('Pre Trip Course saved!', { variant: 'success' }));
    yield replace(`/pre-trip-courses`);
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getPreTripCourses(api, action) {
  const { page, elementsPerPage } = action;
  const response = yield call(api.getPreTripCourses, { page, elementsPerPage });

  if (response.status === 200) {
    yield put(preTripCourseActions.savePreTripCourses(response.data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* getPreTripCourse(api, action) {
  const { id } = action;
  const response = yield call(api.getPreTripCourse, id);
  if (response.status === 200) {
    yield put(preTripCourseActions.savePreTripCourse(response.data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* updatePreTripCourse(api, action) {
  const { id, preTripCourse } = action;
  const response = yield call(api.updatePreTripCourse, id, preTripCourse);

  if (response.status === 200) {
    yield put(appActions.showAlert('Pre Trip Course updated!', { variant: 'success' }));
    yield replace(`/pre-trip-courses`);
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* deletePreTripCourse(api, action) {
  const { id } = action;
  const { status } = yield call(api.deletePreTripCourse, id);

  if (status === 204) {
    const response = yield call(api.getPreTripCourses, { page: 1, elementsPerPage: 12 });
    if (response.status === 200) {
      yield put(preTripCourseActions.savePreTripCourses(response.data));
    } else {
      yield put(appActions.showAlert(data.message, { variant: 'danger' }));
    }
    yield put(appActions.showAlert('Pre Trip Course deleted!', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* buyPreTripCourse(api, action) {
  const { preTripCourse } = action;
  const response = yield call(api.buyPreTripCourse, preTripCourse);

  if (response.status === 200) {
    yield put(appActions.showAlert('Pre Trip Course purchased!', { variant: 'success' }));
    yield replace(`/purchase-pre-trip-courses`);
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* purchasePreTripCourses(api, action) {
  const { page, elementsPerPage } = action;
  const response = yield call(api.purchasePreTripCourses, { page, elementsPerPage });
  if (response.status === 200) {
    yield put(preTripCourseActions.savePurchasePreTripCourses(response.data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* allPurchasePreTripCourses(api, action) {
  const { page, elementsPerPage } = action;
  const response = yield call(api.allPurchasePreTripCourses, { page, elementsPerPage });
  if (response.status === 200) {
    yield put(preTripCourseActions.saveAllPurchasePreTripCourses(response.data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}
