import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import { GENERALPREFERENCES } from 'constants/index';
import RadioInput from 'components/RadioInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

class AddGeneralPreferenceView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { step, changeStep, handleChange, general_preference } = this.props;

    return (
      <Container>
        <PrimaryText text="Which of these descriptions best describes the kinds of places you enjoy visiting the most while traveling?" />

        <RadioInput
          name="general_preference"
          options={GENERALPREFERENCES}
          handleChange={handleChange}
          currentValue={general_preference}
          verticalAlign
        />

        <StepButtons
          nonDisplayBack
          nextDisabled={!general_preference}
          nextStep={() => changeStep(step + 1)}
        />
      </Container>
    );
  }
}

export default AddGeneralPreferenceView;
