import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as loginActions from './../actions/loginActions';
import styled from 'styled-components';
import { Button } from 'styled-minimal';

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  flex-direction: row;
  background: ${props => (!props.lightColor ? '#fff' : '')};
  box-shadow: ${props => (!props.lightColor ? '0px 6px 18px 0 rgba(0, 0, 0, 0.04)' : '')};
  border-radius: 17px;
`;

const TabContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  align-self: center;
  text-align: center;
`;

const Tab = styled.a`
  width: 100%;
  display: inline-block;
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.01);
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  float: right;
  cursor: pointer;
  &: hover {
    opacity: 0.5;
  }
  ${props =>
    props.active
      ? `
    background-color: #38b6ff;
    color: #ffffff;
    `
      : `
    background-color: #ffffff;
    color: #999999;
    `};
`;

class Tabs extends Component {
  render() {
    const { tabs, active, lightColor } = this.props;
    return (
      <Container lightColor={lightColor}>
        {tabs.map((tab, key) => {
          return (
            <TabContainer key={key}>
              <Tab onClick={tab.action} active={active === tab.title}>
                {tab.title}
              </Tab>
            </TabContainer>
          );
        })}
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  null,
  mapDispatchToProps,
)(Tabs);
