import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  wellBeings: null,
  strengths: null,
  travelPersonalities: null,
  step: null,
  possibleHours: null,
  currentMeetingId: null,
  previusStep: null,
};

export default {
  diagnosticReducer: handleActions(
    {
      [ActionTypes.DIAGNOSTIC_RECIVE_WELL_BEING](state, action) {
        return {
          ...state,
          wellBeings: { ...state.wellBeings, ...action.wellBeings },
        };
      },
      [ActionTypes.DIAGNOSTIC_RECIVE_STRENGTHS](state, action) {
        return {
          ...state,
          strengths: { ...state.strengths, ...action.strengths },
        };
      },
      [ActionTypes.DIAGNOSTIC_RECIVE_TRAVEL_PERSONALITY](state, action) {
        return {
          ...state,
          travelPersonalities: { ...state.travelPersonalities, ...action.travelPersonalities },
        };
      },
      [ActionTypes.DIAGNOSTIC_CHANGE_CURRENT_STEP](state, action) {
        return {
          ...state,
          step: action.step,
          previusStep: state.step,
        };
      },
      [ActionTypes.RECIVE_POSSIBLE_MEETING_HOUR](state, action) {
        return {
          ...state,
          possibleHours: action.hours,
        };
      },
      [ActionTypes.SAVE_CURRENT_MEETING_ID](state, action) {
        return {
          ...state,
          currentMeetingId: action.id,
        };
      },
      [ActionTypes.LOGIN_RESPONSE](state, action) {
        return {
          ...appState,
        };
      },
    },
    appState,
  ),
};
