import React from 'react';
import styled from 'styled-components';
import { replace } from 'modules/history';
import Modal from 'components/Modal';
import TripDetailItem from 'components/TripDetailItem';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import SecondaryText from 'components/Trip/SecondaryText';

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: ${props => props.backgroundColor || '#38b6ff'};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  width: ${props => props.width}%;
  margin-left: 30px;
  margin-right: 30px;

  @media (max-width: 900px) {
    margin: 0;
    width: 100%;
    justify-content: flex-start;
  }
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const ModalTitle = styled.p`
  font-weight: bold;
  text-align: center;
  margin-top: 25px;
  color: #3c3c3c;
`;

const ModalProducts = styled.div`
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  justify-content: center;
`;

const Text = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  line-height: 1.47;
  color: #999999;
  text-align: ${props => props.textAlign || 'justify'};
  padding-right: 15px;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 80%;
`;

const DatePickerContainer = styled.div`
  width: 100%;
  margin-left: ${props => props.marginLeft}px;
  margin-right: ${props => props.marginright}px;
`;

const Select = styled.select`
  background: white;
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  height: 44px;
  padding: 10px;
  width: 100%;
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const ItinerariesList = styled.div`
  width: 100%;
  padding-left: 38px;
  padding-right: 38px;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const ActionIcon = styled.i`
  color: ${props => props.color};
  font-size: 20px;
  padding-right: 10px;
`;

const Title = styled.p`
  font-size: 30px;
`;

const renderAtItem = (title, content) => {
  return (
    <Text>
      <span style={{ fontWeight: 'bold', marginRight: '5px' }}>{title}: </span>
      {content || 'Incomplete.'}
    </Text>
  );
};

const TripDetails = props => {
  const {
    tripDetails,
    approveAndCharge,
    alertCreateItinerary,
    toEditTrip,
    alertCreateItineraryMessage,
    activateAtPlan,
    activateGoal,
    changeAuthenticPlanModal,
    authenticPlanModal,
    changeGoalsModal,
    goalsModal,
    alertCreateItineraryOnSucces,
    alertCreateCharge,
    changeDate,
    toggleAlert,
    setDatesModal,
    start_date,
    departure_time,
    end_date,
    arriving_time,
    handleChange,
    updateTripDates,
  } = props;

  if (!tripDetails) return null;
  const { authenticPlan, goal, user } = tripDetails;
  return (
    <>
      <HeaderBox>
        <Header justifyContent="flex-start" width={20}>
          <Button onClick={() => replace(`/customers/${tripDetails.user.id}`)}>Back</Button>
        </Header>
        <Header justifyContent="flex-end" width={80} style={{ flexWrap: 'wrap' }}>
          {!authenticPlan && <Button onClick={activateAtPlan}>Activate AUT Plan</Button>}
          {!tripDetails.goal && <Button onClick={activateGoal}>Activate Goals</Button>}

          {authenticPlan && <Button onClick={changeAuthenticPlanModal}>View AUT Plan</Button>}
          {tripDetails.goal && <Button onClick={changeGoalsModal}>View Goals</Button>}

          {!tripDetails.start_date && !tripDetails.end_date && (
            <Button backgroundColor="#ba0d0d" onClick={() => toggleAlert('setDatesModal')}>
              Add Dates
            </Button>
          )}
          {tripDetails.status == 'user_approve' && (
            <Button backgroundColor="#4BC142" onClick={approveAndCharge}>
              Approve & Charge
            </Button>
          )}
        </Header>
      </HeaderBox>
      <Title style={{ marginLeft: 38 }}>Trip Details:</Title>

      <TripDetailItem tripDetails={tripDetails} toEditTrip={toEditTrip} />

      <ItinerariesList>
        <Title>Itineraries</Title>

        {tripDetails.itineraries.map((itinerary, key) => (
          <Item key={key}>
            {itinerary.status == 'created' ? (
              <>
                <SectionContainer width={'30%'}>
                  <Text textAlign="center">
                    <span style={{ color: 'black' }}> Title:</span> {itinerary.title}
                    {itinerary.is_default && (
                      <span style={{ color: '#4BC142', fontWeight: 'bold' }}> (Default)</span>
                    )}
                  </Text>
                  <VerticalLine />
                </SectionContainer>
                <SectionContainer width={'10%'}>
                  <Text textAlign="center">
                    <span style={{ color: 'black' }}>Type:</span> {itinerary.type}
                  </Text>
                  <VerticalLine />
                </SectionContainer>
                <SectionContainer width={'25%'}>
                  <Text textAlign="center">
                    <span style={{ color: 'black' }}>Start Date:</span>
                    {moment(itinerary.start_date).format('MM-DD-YYYY')}
                  </Text>
                  <VerticalLine />
                </SectionContainer>
                <SectionContainer width={'25%'}>
                  <Text textAlign="center">
                    <span style={{ color: 'black' }}>End Date:</span>
                    {moment(itinerary.end_date).format('MM-DD-YYYY')}
                  </Text>
                  <VerticalLine />
                </SectionContainer>
              </>
            ) : (
              <SectionContainer width={'90%'}>
                <Text textAlign="center">Available to create a new itinerary</Text>
                <VerticalLine />
              </SectionContainer>
            )}

            <SectionContainer width={'10%'} style={{ textAlign: 'center' }}>
              <Link to={`/itinerary/${itinerary.id}`}>
                <ActionIcon color="#38b6ff" className="fas fa-edit" />
              </Link>
            </SectionContainer>
          </Item>
        ))}
      </ItinerariesList>

      <Modal
        closeIcon
        isVisible={alertCreateItinerary}
        title="This itinerary cannot be edited."
        cancelText="Ok"
        onCancel={() => toggleAlert('alertCreateItinerary')}
        cancelText={alertCreateItineraryOnSucces ? 'Cancel' : 'Ok'}
        onSuccess={alertCreateItineraryOnSucces}
        successText={alertCreateItineraryOnSucces ? 'Continue' : null}
      >
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
          <p>{alertCreateItineraryMessage}</p>
        </div>
      </Modal>

      <Modal
        closeIcon
        isVisible={alertCreateCharge}
        title="This itinerary cannot be charged."
        onSuccess={() => toggleAlert('alertCreateCharge')}
        successText="Ok"
      >
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
          <p>
            You cannot charge for this trip because it has no assigned dates, click
            <span
              style={{ color: '#38b6ff', cursor: 'pointer' }}
              onClick={() => {
                toggleAlert('alertCreateCharge');
                toggleAlert('setDatesModal');
              }}
            >
              {' '}
              here{' '}
            </span>
            to add dates.
          </p>
        </div>
      </Modal>

      {authenticPlan && (
        <Modal
          isVisible={authenticPlanModal}
          onCancel={changeAuthenticPlanModal}
          cancelText="Close"
        >
          <>
            <ModalTitle>Autentic Travel Plan</ModalTitle>
            <ModalProducts>
              <div style={{ width: '100%', height: '400px', overflow: 'auto' }}>
                <Text style={{ fontWeight: '300', fontSize: '28px', textAlign: 'center' }}>
                  Strengths:
                </Text>
                {renderAtItem('Strengt 1', authenticPlan.strength_1)}
                {renderAtItem('Strengt 2', authenticPlan.strength_2)}
                {renderAtItem('Strengt 3', authenticPlan.strength_3)}
                {renderAtItem('Strengt 4', authenticPlan.strength_4)}
                {renderAtItem('Strengt 5', authenticPlan.strength_5)}
                {renderAtItem('Strengt 6', authenticPlan.strength_6)}
                <Text style={{ fontWeight: '300', fontSize: '28px', textAlign: 'center' }}>
                  1'st Goal:
                </Text>
                {renderAtItem('The traveler wants this goal because', authenticPlan.first_goal_1)}
                {renderAtItem('The traveler will measure this goal by', authenticPlan.first_goal_2)}
                {renderAtItem(
                  'The traveler will know when I have achieved this goal when',
                  authenticPlan.first_goal_3,
                )}
                <Text style={{ fontWeight: '300', fontSize: '28px', textAlign: 'center' }}>
                  2'nd Goal:
                </Text>
                {renderAtItem('The traveler wants this goal because', authenticPlan.second_goal_1)}
                {renderAtItem(
                  'The traveler will measure this goal by',
                  authenticPlan.second_goal_2,
                )}
                {renderAtItem(
                  'The traveler will know when I have achieved this goal when',
                  authenticPlan.second_goal_3,
                )}
                <Text style={{ fontWeight: '300', fontSize: '28px', textAlign: 'center' }}>
                  3'nd Goal:
                </Text>
                {renderAtItem('The traveler wants this goal because', authenticPlan.third_goal_1)}
                {renderAtItem('The traveler will measure this goal by', authenticPlan.third_goal_2)}
                {renderAtItem(
                  'The traveler will know when I have achieved this goal when',
                  authenticPlan.third_goal_3,
                )}
                <Text style={{ fontWeight: '300', fontSize: '28px', textAlign: 'center' }}>
                  Declarations:
                </Text>
                {renderAtItem(
                  'When the traveler returns home from his trip. I will be happier and more satisfied why',
                  authenticPlan.declaration_1,
                )}
                {renderAtItem(
                  'This trip is good for the traveler because',
                  authenticPlan.declaration_2,
                )}
                {renderAtItem(
                  'The traveler always wanted to make these changes because',
                  authenticPlan.declaration_3,
                )}
                {renderAtItem(
                  'The traveler has to make these changes because',
                  authenticPlan.declaration_4,
                )}
                {renderAtItem(
                  'The traveler will trust the following people to help him during his trip',
                  authenticPlan.declaration_5,
                )}
              </div>
            </ModalProducts>
          </>
        </Modal>
      )}

      {goal && (
        <Modal isVisible={goalsModal} onCancel={changeGoalsModal} cancelText="Close">
          <>
            <ModalTitle>Goals</ModalTitle>
            <ModalProducts>
              <div style={{ width: '100%', height: '400px', overflow: 'auto' }}>
                <Text style={{ fontWeight: '300', fontSize: '28px', textAlign: 'center' }}>
                  Goal 1:
                </Text>
                {renderAtItem('Goal 1', goal.goal_1)}
                <Text style={{ fontWeight: '300', fontSize: '28px', textAlign: 'center' }}>
                  Goal 2:
                </Text>
                {renderAtItem('Goal 2', goal.goal_2)}
                <Text style={{ fontWeight: '300', fontSize: '28px', textAlign: 'center' }}>
                  Goal 3:
                </Text>
                {renderAtItem('Goal 3', goal.goal_3)}
              </div>
            </ModalProducts>
          </>
        </Modal>
      )}

      <Modal
        isVisible={setDatesModal}
        onCancel={() => toggleAlert('setDatesModal')}
        onSuccess={updateTripDates}
        successText="Save"
        cancelText="Close"
      >
        <>
          <ModalTitle>Select Dates</ModalTitle>
          <ModalProducts>
            <Content>
              <SecondaryText text="When do you arrive?" />
              <ModalProducts>
                <DatePickerContainer marginRight={10}>
                  <DatePicker
                    className="datepickerTrip"
                    showPopperArrow={false}
                    selected={start_date}
                    onChange={date => changeDate('start_date', date)}
                    minDate={moment()
                      .add(2, 'd')
                      .toDate()}
                    placeholderText="Start Date"
                  />
                </DatePickerContainer>

                <DatePickerContainer marginLeft={10}>
                  <Select
                    placeholder="Arriving Date"
                    name="arriving_time"
                    value={arriving_time}
                    onChange={handleChange}
                  >
                    <option value={'Morning'}>Morning</option>
                    <option value={'Afternoon'}>Afternoon</option>
                    <option value={'Evening'}>Evening</option>
                  </Select>
                </DatePickerContainer>
              </ModalProducts>

              <SecondaryText text="When do you leave?" />
              <ModalProducts>
                <DatePickerContainer marginRight={10}>
                  <DatePicker
                    className="datepickerTrip"
                    showPopperArrow={false}
                    selected={end_date}
                    onChange={date => changeDate('end_date', date)}
                    minDate={moment()
                      .add(5, 'd')
                      .toDate()}
                    placeholderText="Start Date"
                  />
                </DatePickerContainer>

                <DatePickerContainer marginLeft={10}>
                  <Select
                    placeholder="Departure Date"
                    name="departure_time"
                    value={departure_time}
                    onChange={handleChange}
                  >
                    <option value={'Morning'}>Morning</option>
                    <option value={'Afternoon'}>Afternoon</option>
                    <option value={'Evening'}>Evening</option>
                  </Select>
                </DatePickerContainer>
              </ModalProducts>
            </Content>
          </ModalProducts>
        </>
      </Modal>
    </>
  );
};

export default TripDetails;
