import React, { Component } from 'react';
import ResetPasswordView from './ResetPasswordView';
import { connect } from 'react-redux';
import * as loginActions from '../../actions/loginActions';

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token_pass_recovery = url.searchParams.get('token_pass_recovery');
    this.setState({ token_pass_recovery });
  }

  resetPassword = password => {
    this.props.onResetPassword(this.state.token_pass_recovery, password);
  };

  render() {
    return <ResetPasswordView {...this.props} resetPassword={this.resetPassword} />;
  }
}

function mapStateToProps(state) {
  return { state };
}
function mapDispatchToProps(dispatch) {
  return {
    onResetPassword: (token_pass_recovery, password) =>
      dispatch(loginActions.requestResetPassword(token_pass_recovery, password)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordContainer);
