import styled from 'styled-components';

const Button = styled.button`
  border-radius: 5px;
  color: white;
  background-color: ${props => (!props.disabled ? '#38b6ff' : '#c9e2ef')};
  cursor: pointer;
  width: 150px;
  height: 50px;
`;

export default Button;
