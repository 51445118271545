import strengths from 'assets/media/images/strengths.jpg';
import travelPersonality from 'assets/media/images/travelPersonality.jpg';
import wellBeing from 'assets/media/images/wellBeing.jpg';

export const selectFields = step => {
  switch (step) {
    case 2:
      return {
        title: 'Part 1: Overall Well-Being',
        image: wellBeing,
        testNumber: 1,
        body:
          '<p>This part consists of 22 questions on sliding 10-point scales. <br/><br/>Please answer them as honestly and accurately as you can, focusing on how you currently feel rather than how you think you should or want to feel.</p>',
      };
    case 4:
      return {
        title: 'Part 2: Personal Strengths',
        image: strengths,
        testNumber: 2,
        body:
          '<p>Thanks for completing Part 1!<br/><br/>Part 2 consists of 58 multiple-choice questions.<br/><br/>Again, please answer them as honestly and accurately as you can, focusing on how you currently feel rather than how you think you should or want to feel.</p>',
      };
    case 6:
      return {
        title: 'Part 3: Your Travel Personality',
        image: travelPersonality,
        testNumber: 3,
        body:
          '<p>Thanks for completing Part 2!<br/><br/>Part 3 consists of 10 multiple-choice questions.<br/><br/> Once again, please answer them as honestly and accurately as you can, focusing on how you currently feel rather than how you think you should feel or how you want to feel.</p>',
      };
  }
};
