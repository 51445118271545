import React from 'react';
import styled from 'styled-components';
import { appColor } from 'modules/theme';
import { Formik } from 'formik';
import ModalExcel from 'components/Suggestions/ModalExcel';

const GridContainer = styled.div`
  padding-top: 20px;
  margin: 10px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  line-height: 0;
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

const InputActivity = styled.input`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 25)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: ${props => props.backgroundColor || '#38b6ff'};
`;
const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkText = styled.p`
  padding-top: 5px;
  font-size: 18px;
  cursor: pointer;
  color: #38b6ff;
`;

const AverageDailyExpenses = props => {
  const {
    country: { id },
    viewMode,
    token,
    dailyExpenses,
    saveAverageDailyExpenses,
    handleModalExcel,
    renderModalExcel,
  } = props;

  return (
    <GridContainer>
      <Formik enableReinitialize initialValues={dailyExpenses} isInitialValid={true}>
        {({ values, handleChange }) => {
          return (
            <>
              {/*<FlexRow>
                <FlexColumn width={90}></FlexColumn>
                <LinkText onClick={() => handleModalExcel(true)} style={{ marginTop: 10 }}>
                  Import from sheet
                </LinkText>
              </FlexRow>*/}
              <Label color={appColor} marginBottom={50}>
                Public Transportation
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Budget</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="public_transportation_budget"
                    value={values.public_transportation_budget}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Moderate</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="public_transportation_moderate"
                    value={values.public_transportation_moderate}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Pricier</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="public_transportation_pricier"
                    value={values.public_transportation_pricier}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Splurge</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="public_transportation_splurge"
                    value={values.public_transportation_splurge}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Sights And Entertainment
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Budget</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="sights_and_entertainment_budget"
                    value={values.sights_and_entertainment_budget}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Moderate</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="sights_and_entertainment_moderate"
                    value={values.sights_and_entertainment_moderate}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Pricier</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="sights_and_entertainment_pricier"
                    value={values.sights_and_entertainment_pricier}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Splurge</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="sights_and_entertainment_splurge"
                    value={values.sights_and_entertainment_splurge}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Meals
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Budget</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="meals_budget"
                    value={values.meals_budget}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Moderate</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="meals_moderate"
                    value={values.meals_moderate}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Pricier</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="pricier"
                    value={values.meals_pricier}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Splurge</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="meals_splurge"
                    value={values.meals_splurge}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Lodging (2 people splitting a double room)
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Budget</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="lodging_couple_budget"
                    value={values.lodging_couple_budget}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Moderate</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="lodging_couple_moderate"
                    value={values.lodging_couple_moderate}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Pricier</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="lodging_couple_pricier"
                    value={values.lodging_couple_pricier}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Splurge</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="lodging_couple_splurge"
                    value={values.lodging_couple_splurge}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Lodging (alone)
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Budget</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="lodging_alone_budget"
                    value={values.lodging_alone_budget}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Moderate</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="lodging_alone_moderate"
                    value={values.lodging_alone_moderate}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Pricier</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="lodging_alone_pricier"
                    value={values.lodging_alone_pricier}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Splurge</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="lodging_alone_splurge"
                    value={values.lodging_alone_splurge}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Total Expenses per Day (applies to cities, figure on less for towns)
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Budget</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="total_expenses_per_day_budget"
                    value={values.total_expenses_per_day_budget}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Moderate</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="total_expenses_per_day_moderate"
                    value={values.total_expenses_per_day_moderate}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Pricier</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="total_expenses_per_day_pricier"
                    value={values.total_expenses_per_day_pricier}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Splurge</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="total_expenses_per_day_splurge"
                    value={values.total_expenses_per_day_splurge}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Total Expenses per Day (not splitting Room)
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Budget</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="total_expenses_per_day_not_splitting_room_budget"
                    value={values.total_expenses_per_day_not_splitting_room_budget}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Moderate</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="total_expenses_per_day_not_splitting_room_moderate"
                    value={values.total_expenses_per_day_not_splitting_room_moderate}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Pricier</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="total_expenses_per_day_not_splitting_room_pricier"
                    value={values.total_expenses_per_day_not_splitting_room_pricier}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Splurge</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="total_expenses_per_day_not_splitting_room_splurge"
                    value={values.total_expenses_per_day_not_splitting_room_splurge}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>
              {!viewMode && (
                <ContainerButton>
                  <Button onClick={() => saveAverageDailyExpenses(id, values)}>Save</Button>
                </ContainerButton>
              )}
            </>
          );
        }}
      </Formik>

      {/*<ModalExcel
        id={id}
        token={token}
        url="average-daily-expenses"
        renderModalExcel={renderModalExcel}
        handleModalExcel={handleModalExcel}
      ></ModalExcel>*/}
    </GridContainer>
  );
};

export default AverageDailyExpenses;
