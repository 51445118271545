import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StepContainerWithImage = styled.div`
  background-color: ${props => (props.color ? props.color : 'white')};
  width: 70%;
  margin: 0 auto 30px auto;
  border-radius: 10px;
  display: flex;
  height: 600px;
  @media (max-width: 1400px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StepContent = styled.div`
  display: inline-block;
  width: 50%;
  height: 100%;
  overflow: auto;
  @media (max-width: 1000px) {
    width: 65%;
  }
  @media (max-width: 770px) {
    width: 100%;
  }
`;

const StepImage = styled.div`
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  @media (max-width: 1000px) {
    width: 35%;
  }
  @media (max-width: 770px) {
    display: none;
  }
`;

const CloseIcon = styled.div`
  position: relative;
  top: 15px;
  right: 15px;
  float: right;
`;

const Image = styled.img`
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const Icon = styled.i`
  color: #696969;
  font-size: 20px;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
`;

export default props => (
  <StepContainerWithImage color={props.color}>
    <StepContent>
      {props.children}
    </StepContent>
    <StepImage>
      <Image src={props.image} />
      {props.closeIcon && (
        <Link to={'/'} style={{ position: 'absolute', right: 5 }}>
          <CloseIcon onClick={props.closeAction}>
            <Icon className="fas fa-times-circle" />
          </CloseIcon>
        </Link>
      )}
    </StepImage>
  </StepContainerWithImage>
);
