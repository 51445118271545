/*
 * Reducer actions related with customers
 */
import { ActionTypes } from 'constants/index';

export function createPreTripCourses(preTripCourse) {
  return {
    type: ActionTypes.CREATE_PRE_TRIP_COURSE,
    preTripCourse,
  };
}
export function savePreTripCourses(preTripCourses) {
  return {
    type: ActionTypes.SAVE_PRE_TRIP_COURSES,
    preTripCourses: preTripCourses.rows,
    preTripCoursesCount: preTripCourses.count,
  };
}

export function getPreTripCourses(page, elementsPerPage) {
  return {
    type: ActionTypes.GET_PRE_TRIP_COURSES,
    page,
    elementsPerPage,
  };
}

export function getPreTripCourse(id) {
  return {
    type: ActionTypes.GET_PRE_TRIP_COURSE,
    id,
  };
}
export function savePreTripCourse(preTripCourse) {
  return {
    type: ActionTypes.SAVE_PRE_TRIP_COURSE,
    preTripCourse,
  };
}

export function updatePreTripCourse(id, preTripCourse) {
  return {
    type: ActionTypes.UPDATE_PRE_TRIP_COURSE,
    id,
    preTripCourse,
  };
}

export function deletePreTripCourse(id) {
  return {
    type: ActionTypes.DELETE_PRE_TRIP_COURSE,
    id,
  };
}
export function buyPreTripCourse(preTripCourse) {
  return {
    type: ActionTypes.BUY_PRE_TRIP_COURSE,
    preTripCourse,
  };
}

export function purchasedPreTripCourses(page, elementsPerPage) {
  return {
    type: ActionTypes.PURCHASED_PRE_TRIP_COURSES,
    page,
    elementsPerPage,
  };
}

export function savePurchasePreTripCourses(payload) {
  return {
    type: ActionTypes.SAVE_PURCHASED_PRE_TRIP_COURSES,
    purchasedPreTripCourses: payload.rows,
    purchasedPreTripCoursesCount: payload.count,
  };
}

export function allPurchasedPreTripCourses(page, elementsPerPage) {
  return {
    type: ActionTypes.ALL_PURCHASED_PRE_TRIP_COURSES,
    page,
    elementsPerPage,
  };
}

export function saveAllPurchasePreTripCourses(allPurchasePreTripCourses) {
  return {
    type: ActionTypes.SAVE_ALL_PURCHASED_PRE_TRIP_COURSES,
    allPurchasePreTripCourses: allPurchasePreTripCourses.rows,
    allPurchasePreTripCoursesCount: allPurchasePreTripCourses.count,
  };
}
