import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import Input from 'components/Input';
import Dropzone from 'components/DropZone';
import RadioInput from 'components/RadioInput';
import { EATERIES } from 'constants/index';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 85px 20% 25px 20%;
  align-items: center;
  @media (max-width: 1000px) {
    margin: 85px 10% 25px 10%;
  }
`;

class EateriesView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { step, changeStep, handleChange, eateries } = this.props;

    return (
      <Container>
        <PrimaryText text="How would you dene the type of eateries you would realistically want to have most of your meals at on your upcoming journey?" />

        <RadioInput
          name="eateries"
          options={EATERIES}
          handleChange={handleChange}
          currentValue={eateries}
          verticalAlign
        />

        <StepButtons
          nextDisabled={!eateries}
          backStep={() => changeStep(step - 1)}
          nextStep={() => changeStep(step + 1)}
        />
      </Container>
    );
  }
}

export default EateriesView;
