import React from 'react';
import styled from 'styled-components';
import defaultAvatar from 'assets/media/images/avatar.png';
import { Link } from 'react-router-dom';

const Item = styled.div`
  display: flex;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  margin: 11px;
  border-radius: 9px;
  height: 155px;
  width: 320px;
`;

const Image = styled.img`
  width: 50%;
  border-radius: 9px 0px 0px 9px;
  object-fit: cover;
`;

const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

const Title = styled.p`
  margin: 12px;
  width: 100%;
`;
const Secondary = styled.p`
  font-size: 13px;
  color: ${props => props.color};
  margin: 0px 12px;
  cursor: ${props => (props.cursorPoiner ? 'pointer' : 'auto')};
`;

const PreTripCourseItem = props => {
  const { id, title, image } = props.preTripCourse;
  const { deletePreTripCourse, role } = props;
  return (
    <Item>
      <Image src={image || defaultAvatar} />
      <FlexBox>
        <Title>{title}</Title>
        {role == 'admin' && (
          <FlexBox>
            <Link to={`/pre-trip-course/${id}`}>
              <Secondary color="#38b6ff" cursorPoiner>
                Edit→
              </Secondary>
            </Link>

            <Secondary onClick={() => deletePreTripCourse(id)} color="#ff0000ad" cursorPoiner>
              Delete
            </Secondary>
          </FlexBox>
        )}
        {role == 'customer' && (
          <FlexBox>
            <Link to={`/preview/pre-trip-courses/${id}/true`}>
              <Secondary color="#38b6ff" cursorPoiner>
                View
              </Secondary>
            </Link>
          </FlexBox>
        )}
      </FlexBox>
    </Item>
  );
};

export default PreTripCourseItem;
