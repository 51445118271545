import React, { Component } from 'react';
import ResetPasswordForm from '../../components/NewPassword/ResetPasswordForm';
import PageWithBackgroundImage from '../../components/PageWithBackgroundImage';

class ForgotPasswordView extends Component {
  render() {
    const { resetPassword } = this.props;

    return (
      <PageWithBackgroundImage>
        <ResetPasswordForm resetPassword={resetPassword} />
      </PageWithBackgroundImage>
    );
  }
}

export default ForgotPasswordView;
