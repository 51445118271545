import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import Pagination from 'components/PaginationButtons';
import ok from 'assets/media/icons/ok.png';
import pending from 'assets/media/icons/pending.png';
import remove from 'assets/media/icons/remove.png';
import defaultAvatar from 'assets/media/images/avatar.png';

const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  display: flex;
`;

const Primary = styled.p`
  font-size: 14px;
  cursor: ${props => (props.pointer ? 'pointer' : 'initial')};
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 14px;
  cursor: ${props => (props.pointer ? 'pointer' : 'initial')};
`;

const TBody = styled.div``;

const Image = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 19px;
  margin-right: 8px;
`;

class AllPurchasedPreTripCourseList extends Component {
  renderPreTripCourse({ created_at, status, PreTripCourse, user }, key) {
    return (
      <Item key={key}>
        <SectionContainer width={'40%'}>
          <Image src={user.avatar || defaultAvatar} />
          <Link to={`/customers/${user.id}`}>
            <Primary pointer>
              {user.first_name} {user.last_name}
            </Primary>
          </Link>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'30%'}>
          <Link to={`/pre-trip-courses/${PreTripCourse.id}`}>
            <Primary pointer>{PreTripCourse.title}</Primary>
          </Link>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'30%'}>
          <Primary className="without-margin">
            <i className="calendar-minus" />
          </Primary>
          <Secondary>{moment(created_at).format('MM-DD-YYYY hh:mm A')}</Secondary> <VerticalLine />
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const {
      allPurchasePreTripCourses,
      currentPage,
      elementsPerPage,
      allPurchasePreTripCoursesCount,
      changePage,
    } = this.props;
    if (!allPurchasePreTripCourses) return null;
    return (
      <GridContainer>
        <Pagination
          currentPage={currentPage}
          changePage={changePage}
          totalPages={Math.ceil(allPurchasePreTripCoursesCount / elementsPerPage)}
        />
        <THeader>
          <TitleContainer width={'40%'}>
            <Title>USER</Title>
          </TitleContainer>
          <TitleContainer width={'30%'}>
            <Title>COURSE</Title>
          </TitleContainer>
          <TitleContainer width={'30%'}>
            <Title>DATE</Title>
          </TitleContainer>
        </THeader>
        <TBody>
          {allPurchasePreTripCourses.map((preTripCourse, key) => {
            return this.renderPreTripCourse(preTripCourse, key);
          })}
          {(!allPurchasePreTripCourses || !allPurchasePreTripCourses.length) && (
            <ListEmpty message="There are currently no Pre Trip Courses" />
          )}
        </TBody>
      </GridContainer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  null,
  mapDispatchToProps,
)(AllPurchasedPreTripCourseList);
