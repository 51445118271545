import React, { Component } from 'react';
import TravelWithOthersView from './TravelWithOthersView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';

class TravelWithOthers extends Component {
  constructor(props) {
    super(props);
    this.state = { travel_with_others: false, how_many: null, know_how_many: false };
  }

  componentDidMount() {
    const { travel_with_others, how_many, know_how_many } = this.props;
    this.setState({ travel_with_others, how_many, know_how_many });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { travel_with_others, how_many, know_how_many } = this.state;
    changeCurrentStep(step);
    saveTripData({ travel_with_others, how_many, know_how_many });
  };

  setTravelWithOthers = value => {
    if (!value) {
      this.setState({ travel_with_others: value, how_many: null, know_how_many: false });
    } else {
      this.setState({ travel_with_others: value });
    }
  };

  handleChangeCheckbox = evt => {
    this.setState({ know_how_many: evt.target.checked, how_many: null });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    return (
      <TravelWithOthersView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        setTravelWithOthers={this.setTravelWithOthers}
        handleChangeCheckbox={this.handleChangeCheckbox}
        handleChange={this.handleChange}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    travel_with_others: state.tripReducer.tripForm.travel_with_others,
    how_many: state.tripReducer.tripForm.how_many,
    know_how_many: state.tripReducer.tripForm.know_how_many,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TravelWithOthers);
