import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DestinationButton from 'components/DestinationButton';
import ActivityButton from 'components/ActivityButton';
import { Redirect } from 'react-router-dom';
import Modal from 'components/Modal';
import Input from 'components/Input';

import './styles.css';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
//import '../styles.css';

const Container = styled.div`
  margin: 38px;
  display: block;
  max-width: 100%;
`;

const DestinationsBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

const AddText = styled.p`
  font-size: 14px;
  color: #38b6ff;
  margin-left: 25px;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 30px;
`;

const InputActivity = styled.input`
  width: 100%;
  height: 50px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 25)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;
const TextOption = styled.p`
  color: ${props => (props.active ? 'white' : '#999999')};
  margin-left: 8px;
  font-family: 'Josefin Sans', sans-serif;
`;

const CityOption = styled.div`
  background-color: ${props => (props.active ? '#38b6ff' : '#f4f4f4')};
  border: none;
  height: 50px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
`;
const PlacesList = styled.div`
  background-color: #ffffff;
  padding: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  border-radius: 5px;
`;

const Button = styled.button`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: ${props => (props.disabled ? 'lightgray' : '#38b6ff')};
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
`;

const InputBox = styled.div`
  margin: 10px;
`;

const ModalBody = styled.div`
  width: 75%;
  margin: auto;
`;

const Label = styled.p`
  font-size: 14px;
  text-align: start;
`;

const Select = styled.select`
  background: white;
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  height: 44px;
  padding: 10px;
  width: 300px;
  margin: 10px
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const CreateItineraryView = props => {
  const {
    selectDestination,
    addDay,
    selectDay,
    stopSelectedId,
    //activities
    selectActivity,
    addActivity,
    deleteActivity,
    activityString,
    activityInput,
    activitySelected,
    activitiesByCity,
    //restaurants
    selectRestaurant,
    addRestaurant,
    restaurantString,
    restaurantInput,
    restaurantSelected,
    restaurantsByCity,
    //accommodations
    selectAccommodation,
    addAccommodation,
    accommodationString,
    accommodationInput,
    accommodationSelected,
    accommodationsByCity,
    //
    stops,
    days,
    daySelectedId,
    isDaySelected,
    daysWithActivities,
    createItinerary,
    stopString,
    handleChange,
    addStop,
    stopModal,
    deleteStop,
    deleteDay,
    changeItineraryDate,
    start_date_activity,
    updateShowList,
    showList,
    moreDaysAlert,
    alertGenerateItinerary,
    toggleAlertGenerateItinerary,
    toggleModal,
    citiesFiltered,
    searchCity,
    start_date,
    end_date,
    type,
    addDayAndUpdateDate,
    saveItineraryData,
    status,
    changeActivityDate,
    title,
    isValidForm,
    itinerary,
    generateActivitiesModal,
    fisrt_activity_time,
    lunch_time,
    back_to_hotel_time,
    generateDayItinerary,
  } = props;

  return (
    <Container>
      <Header>
        {itinerary.is_default && (
          <Label style={{ color: 'red' }}>
            This itinerary cannot be edited because it was approved by the user.
          </Label>
        )}
        <Button disabled={!isValidForm() || itinerary.is_default} onClick={createItinerary}>
          {status == 'pending' ? 'Create Itinerary' : 'Update Itinerary'}
        </Button>
      </Header>
      <Title>Title</Title>

      <Input
        height={53}
        placeholder="Title"
        name="title"
        value={title}
        onChange={e => saveItineraryData({ title: e.target.value })}
      />

      <Title>Dates</Title>
      <DestinationsBox>
        <div style={{ width: 300, margin: 10 }}>
          <Label>Start Date</Label>
          <DatePicker
            className="datepickerTrip"
            showPopperArrow={false}
            selected={start_date}
            onChange={date => changeItineraryDate('start_date', date)}
            minDate={moment()
              .add(2, 'd')
              .toDate()}
            placeholderText="Start Date"
            style={{ width: 200 }}
          />
        </div>
        <div style={{ width: 300, margin: 10 }}>
          <Label>End Date</Label>
          <DatePicker
            className="datepickerTrip"
            showPopperArrow={false}
            selected={end_date}
            onChange={date => changeItineraryDate('end_date', date)}
            minDate={moment()
              .add(2, 'd')
              .toDate()}
            placeholderText="Start Date"
          />
        </div>
      </DestinationsBox>
      <Title>Itinerary Type</Title>

      <div style={{ width: 300, margin: 10 }}>
        <Label>Single / Group</Label>
        <Select
          name="type"
          value={type}
          onChange={e => saveItineraryData({ type: e.target.value })}
        >
          <option className="selectPlaceholder" value="" disabled selected>
            Select Type
          </option>
          <option value="Single">Single</option>
          <option value="Group">Group</option>
        </Select>
      </div>

      <Title>Destinations</Title>
      <DestinationsBox>
        {stops &&
          stops.map((stop, index) => (
            <DestinationButton
              key={index}
              name={stop.city.name}
              date={
                start_date
                  ? [
                      moment(start_date)
                        .add(days.filter(day => day.stopId < stop.id).length, 'days')
                        .format('MMM dddd DD of YYYY'),
                      days.filter(day => day.stopId == stop.id).length > 1
                        ? moment(start_date)
                            .add(
                              days.filter(day => day.stopId < stop.id).length +
                                days.filter(day => day.stopId == stop.id).length -
                                1,
                              'days',
                            )
                            .format('- MMM dddd DD of YYYY')
                        : '',
                    ]
                  : ''
              }
              selected={stop.id == stopSelectedId}
              action={() => selectDestination(stop, index)}
              deleteAction={() => deleteStop(stop.id)}
            />
          ))}
        <AddText onClick={() => toggleModal('stopModal')}>+ add destination</AddText>
      </DestinationsBox>

      {stopSelectedId != null && (
        <>
          <Title>Days</Title>
          <DestinationsBox>
            {days
              .filter(day => day.stopId == stopSelectedId)
              .map((day, index) => (
                <DestinationButton
                  key={index}
                  name={`Day ${index + 1}`}
                  date={
                    (start_date &&
                      moment(start_date)
                        .add(days.filter(day => day.stopId < stopSelectedId).length + index, 'days')
                        .format('MMM dddd DD of YYYY')) ||
                    ''
                  }
                  selected={isDaySelected(day)}
                  action={() => selectDay(day)}
                  deleteAction={() => deleteDay(day.id)}
                />
              ))}
            <AddText onClick={addDay}>+ add day</AddText>
          </DestinationsBox>
        </>
      )}
      {daySelectedId != null && (
        <>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Title>Activities</Title>
            <AddText onClick={() => toggleModal('generateActivitiesModal')}>
              Generate activities for seleted day
            </AddText>
          </div>
          <DestinationsBox>
            {daysWithActivities
              .filter(act => act.dayId == daySelectedId)
              .map((day, index) =>
                day.activities.map((activity, actIdx) => (
                  <ActivityButton
                    key={index}
                    name={activity.name}
                    category={activity.type}
                    hour={`${moment.utc(activity.start_date).format('hh:mm A')}-${moment
                      .utc(activity.start_date)
                      .add(activity.duration, 'minutes')
                      .format('hh:mm A')}`}
                    description={activity.description}
                    deleteAction={() => deleteActivity(actIdx)}
                  />
                )),
              )}
            <div>
              <AddText onClick={() => toggleModal('activityInput')}>+ add activity</AddText>
              <AddText onClick={() => toggleModal('restaurantInput')}>+ add restaurant</AddText>
              <AddText onClick={() => toggleModal('accommodationInput')}>
                + add accommodation
              </AddText>
            </div>
          </DestinationsBox>
          <Modal
            disabled={!activitySelected || !start_date_activity}
            closeIcon
            isVisible={activityInput}
            title="New Activity"
            cancelText="Cancel"
            onCancel={() => toggleModal('activityInput')}
            successText="Add"
            onSuccess={addActivity}
          >
            {activitiesByCity && activitiesByCity.length > 0 ? (
              <ModalBody>
                <InputBox>
                  <Label>Search a activity</Label>
                  <InputActivity
                    marginBottom="0"
                    placeholder="Search a activity"
                    name="activityString"
                    value={activityString}
                    onChange={handleChange}
                    onFocus={e => {
                      updateShowList(true);
                    }}
                  />
                  {showList && activityString && (
                    <PlacesList>
                      {activitiesByCity
                        .filter(act =>
                          act.name.toUpperCase().includes(activityString.toUpperCase()),
                        )
                        .map((activity, index) => (
                          <CityOption
                            onClick={() => {
                              selectActivity(activity);
                              updateShowList(false);
                            }}
                            key={index}
                          >
                            <TextOption>{activity.name}</TextOption>
                          </CityOption>
                        ))}
                    </PlacesList>
                  )}
                </InputBox>
                <InputBox>
                  <DestinationsBox>
                    <Label>Choose schedule</Label>
                    <DatePicker
                      placeholderText="Choose schedule"
                      className="datepickerActivity"
                      selected={start_date_activity}
                      onChange={date => changeActivityDate('start_date_activity', date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </DestinationsBox>
                </InputBox>
              </ModalBody>
            ) : (
              <p style={{ textAlign: 'center' }}>
                There are no activities for this city,
                <Link to="/activities/create">click here, </Link>
                and create one
              </p>
            )}
          </Modal>

          <Modal
            disabled={!restaurantSelected || !start_date_activity}
            closeIcon
            isVisible={restaurantInput}
            title="New Activity"
            cancelText="Cancel"
            onCancel={() => toggleModal('restaurantInput')}
            successText="Add"
            onSuccess={addRestaurant}
          >
            {restaurantsByCity && restaurantsByCity.length > 0 ? (
              <ModalBody>
                <InputBox>
                  <Label>Search a restaurant</Label>
                  <InputActivity
                    marginBottom="0"
                    placeholder="Search a restaurant"
                    name="restaurantString"
                    value={restaurantString}
                    onChange={handleChange}
                    onFocus={e => {
                      updateShowList(true);
                    }}
                  />
                  {showList && restaurantString && (
                    <PlacesList>
                      {restaurantsByCity
                        .filter(act =>
                          act.name.toUpperCase().includes(restaurantString.toUpperCase()),
                        )
                        .map((restaurant, index) => (
                          <CityOption
                            onClick={() => {
                              selectRestaurant(restaurant);
                              updateShowList(false);
                            }}
                            key={index}
                          >
                            <TextOption>{restaurant.name}</TextOption>
                          </CityOption>
                        ))}
                    </PlacesList>
                  )}
                </InputBox>
                <InputBox>
                  <DestinationsBox>
                    <Label>Choose schedule</Label>
                    <DatePicker
                      placeholderText="Choose schedule"
                      className="datepickerActivity"
                      selected={start_date_activity}
                      onChange={date => changeActivityDate('start_date_activity', date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </DestinationsBox>
                </InputBox>
              </ModalBody>
            ) : (
              <p style={{ textAlign: 'center' }}>
                There are no activities for this city,
                <Link to="/activities/create">click here, </Link>
                and create one
              </p>
            )}
          </Modal>

          <Modal
            disabled={!accommodationSelected || !start_date_activity}
            closeIcon
            isVisible={accommodationInput}
            title="New Activity"
            cancelText="Cancel"
            onCancel={() => toggleModal('accommodationInput')}
            successText="Add"
            onSuccess={addAccommodation}
          >
            {accommodationsByCity && accommodationsByCity.length > 0 ? (
              <ModalBody>
                <InputBox>
                  <Label>Search a accommodation</Label>
                  <InputActivity
                    marginBottom="0"
                    placeholder="Search a accommodation"
                    name="accommodationString"
                    value={accommodationString}
                    onChange={handleChange}
                    onFocus={e => {
                      updateShowList(true);
                    }}
                  />
                  {showList && accommodationString && (
                    <PlacesList>
                      {accommodationsByCity
                        .filter(act =>
                          act.name.toUpperCase().includes(accommodationString.toUpperCase()),
                        )
                        .map((accommodation, index) => (
                          <CityOption
                            onClick={() => {
                              selectAccommodation(accommodation);
                              updateShowList(false);
                            }}
                            key={index}
                          >
                            <TextOption>{accommodation.name}</TextOption>
                          </CityOption>
                        ))}
                    </PlacesList>
                  )}
                </InputBox>
                <InputBox>
                  <DestinationsBox>
                    <Label>Choose schedule</Label>
                    <DatePicker
                      placeholderText="Choose schedule"
                      className="datepickerActivity"
                      selected={start_date_activity}
                      onChange={date => changeActivityDate('start_date_activity', date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </DestinationsBox>
                </InputBox>
              </ModalBody>
            ) : (
              <p style={{ textAlign: 'center' }}>
                There are no activities for this city,
                <Link to="/activities/create">click here, </Link>
                and create one
              </p>
            )}
          </Modal>
        </>
      )}

      <Modal
        disabled={!activitySelected}
        closeIcon
        isVisible={stopModal}
        title="Select City"
        cancelText="Cancel"
        onCancel={() => toggleModal('stopModal')}
        successText="Add"
        onSuccess={addActivity}
      >
        {/* {activitiesByCity && activitiesByCity.length > 0 ? ( */}
        <ModalBody>
          {stopModal && (
            <>
              <InputActivity
                marginBottom="0"
                autocomplete="false"
                type="search"
                placeholder="Enter the city you want to travel"
                name="stopString"
                value={stopString}
                onChange={searchCity}
              />
              {stopString && citiesFiltered.length > 0 && (
                <PlacesList>
                  {citiesFiltered
                    .filter(citySelected => {
                      return citySelected.name.toUpperCase().includes(stopString.toUpperCase());
                    })
                    .map((citySelected, index) => {
                      return (
                        <CityOption onClick={() => addStop({ city: citySelected })} key={index}>
                          <TextOption>{citySelected.name}</TextOption>
                        </CityOption>
                      );
                    })}
                </PlacesList>
              )}
            </>
          )}
        </ModalBody>
        {/* ) : (
          <p style={{ textAlign: 'center' }}>
            There are no cities,
            <Link to="/cities/create">click here </Link>
            and create one
          </p>
        )} */}
      </Modal>

      <Modal
        closeIcon
        isVisible={moreDaysAlert}
        title="Add days"
        cancelText="Cancel"
        onCancel={() => toggleModal('moreDaysAlert')}
        successText="Ok"
        onSuccess={addDayAndUpdateDate}
      >
        There are no days left, are you sure you want to add another day to your trip?
      </Modal>

      <Modal
        closeIcon
        isVisible={alertGenerateItinerary}
        title="The itinerary cannot be generated"
        cancelText="Ok"
        onCancel={toggleAlertGenerateItinerary}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p>In order to generate the itinerary you first have to add destinations and days.</p>
        </div>
      </Modal>

      <Modal
        closeIcon
        isVisible={generateActivitiesModal}
        title="Generate one day itinerary."
        cancelText="Cancel"
        onCancel={() => toggleModal('generateActivitiesModal')}
        successText="Ok"
        onSuccess={generateDayItinerary}
      >
        <DestinationsBox>
          <Label>At what time would you like to have the first activity?</Label>

          <DatePicker
            placeholderText="Choose schedule"
            className="datepickerActivity"
            selected={fisrt_activity_time}
            onChange={date => changeActivityDate('fisrt_activity_time', date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />

          <Label>What time would you like to have lunch?</Label>
          <DatePicker
            placeholderText="Choose schedule"
            className="datepickerActivity"
            selected={lunch_time}
            onChange={date => changeActivityDate('lunch_time', date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />

          <Label>At what time would you like to return to the hotel?</Label>
          <DatePicker
            placeholderText="Choose schedule"
            className="datepickerActivity"
            selected={back_to_hotel_time}
            onChange={date => changeActivityDate('back_to_hotel_time', date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </DestinationsBox>
      </Modal>
    </Container>
  );
};

export default CreateItineraryView;
