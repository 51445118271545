import React from 'react';
import styled from 'styled-components';
import DiagnosticResult from '../../components/ExplanationResults/Steps/ExplanationResultsContainer';

const DiagnosticsDiv = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-position-x: center;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-blend-mode: multiply;
  text-align: left;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 50px;
  overflow-x: auto;
  z-index: 1050;
`;

const DiagnosticResultsCustomerView = props => {
  return (
    <DiagnosticsDiv>
      <DiagnosticResult />
    </DiagnosticsDiv>
  );
};

export default DiagnosticResultsCustomerView;
