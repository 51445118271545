import { put, call } from 'redux-saga/effects';
import * as restaurantActions from 'actions/restaurantActions';
import * as appActions from 'actions/app';
import { replace } from 'modules/history';

export function* getRestaurantsAsync(api, action) {
  const { page, elementsPerPage } = action;
  const { status, data } = yield call(api.getRestaurants, { page, elementsPerPage });

  if (status === 200) {
    yield put(restaurantActions.getRestaurantsSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* getRestaurantAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.getRestaurant, id);
  if (status === 200) {
    yield put(restaurantActions.getRestaurantSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* saveRestaurant(api, action) {
  const response = yield call(api.saveRestaurant, action.restaurant);
  if (response.status === 200) {
    yield put(restaurantActions.saveRestaurantSuccess());

    yield put(appActions.showAlert('Restaurant saved!', { variant: 'success' }));
    yield replace(`/restaurants`);
  } else {
    yield put(restaurantActions.saveRestaurantFailure());

    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
export function* removeRestaurantAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.deleteRestaurant, id);

  if (status === 204) {
    yield put(appActions.showAlert('Restaurant deleted.', { variant: 'success' }));
    const response = yield call(api.getRestaurants, { page: 1, elementsPerPage: 10 });
    yield put(restaurantActions.removeRestaurantSuccess());

    if (response.status === 200) {
      yield put(restaurantActions.getRestaurantsSuccess(response.data));
    } else {
      yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
    }
  } else {
    yield put(restaurantActions.removeRestaurantFailure());

    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* updateRestaurantAsync(api, action) {
  const response = yield call(api.updateRestaurants, action.id, action.restaurant);
  if (response.status === 200) {
    yield replace(`/restaurants`);
    yield put(restaurantActions.updateRestaurantSuccess());

    yield put(appActions.showAlert('Restaurant updated!', { variant: 'success' }));
  } else {
    yield put(restaurantActions.updateRestaurantFailure());

    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
