import React, { Component } from 'react';
import SelectFoodDrinkView from './SelectFoodDrinkView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import { FOODDRINK } from 'constants/index';

const foodAndDrinkMaping = Object.fromEntries(
  FOODDRINK.map(foodAndDrink => [
    foodAndDrink,
    foodAndDrink
      .toLowerCase()
      .replaceAll(/\s/g, '_')
      .replaceAll('_&', ''),
  ]),
);

class HaveDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beer: 0,
      cheese_dairy: 0,
      coffee_tea: 0,
      dessert: 0,
      food_drink_tours: 0,
      fruit_vegetables: 0,
      liquor: 0,
      local_specialities: 0,
      markets: 0,
      other_food_and_drink: 0,
      red_meat: 0,
      seafood: 0,
      street_food: 0,
      wine: 0,
    };
  }
  componentDidMount() {
    const {
      beer,
      cheese_dairy,
      coffee_tea,
      dessert,
      food_drink_tours,
      fruit_vegetables,
      liquor,
      local_specialities,
      markets,
      other_food_and_drink,
      red_meat,
      seafood,
      street_food,
      wine,
    } = this.props;
    this.setState({
      beer,
      cheese_dairy,
      coffee_tea,
      dessert,
      food_drink_tours,
      fruit_vegetables,
      liquor,
      local_specialities,
      markets,
      other_food_and_drink,
      red_meat,
      seafood,
      street_food,
      wine,
    });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const {
      beer,
      cheese_dairy,
      coffee_tea,
      dessert,
      food_drink_tours,
      fruit_vegetables,
      liquor,
      local_specialities,
      markets,
      other_food_and_drink,
      red_meat,
      seafood,
      street_food,
      wine,
    } = this.state;
    changeCurrentStep(step);
    saveTripData({
      food_and_drink: {
        beer,
        cheese_dairy,
        coffee_tea,
        dessert,
        food_drink_tours,
        fruit_vegetables,
        liquor,
        local_specialities,
        markets,
        other_food_and_drink,
        red_meat,
        seafood,
        street_food,
        wine,
      },
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <SelectFoodDrinkView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        foodAndDrinkMaping={foodAndDrinkMaping}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    beer: state.tripReducer.tripForm.food_and_drink.beer,
    cheese_dairy: state.tripReducer.tripForm.food_and_drink.cheese_dairy,
    coffee_tea: state.tripReducer.tripForm.food_and_drink.coffee_tea,
    dessert: state.tripReducer.tripForm.food_and_drink.dessert,
    food_drink_tours: state.tripReducer.tripForm.food_and_drink.food_drink_tours,
    fruit_vegetables: state.tripReducer.tripForm.food_and_drink.fruit_vegetables,
    liquor: state.tripReducer.tripForm.food_and_drink.liquor,
    local_specialities: state.tripReducer.tripForm.food_and_drink.local_specialities,
    markets: state.tripReducer.tripForm.food_and_drink.markets,
    other_food_and_drink: state.tripReducer.tripForm.food_and_drink.other_food_and_drink,
    red_meat: state.tripReducer.tripForm.food_and_drink.red_meat,
    seafood: state.tripReducer.tripForm.food_and_drink.seafood,
    street_food: state.tripReducer.tripForm.food_and_drink.street_food,
    wine: state.tripReducer.tripForm.food_and_drink.wine,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HaveDates);
