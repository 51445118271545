import React, { Component } from 'react';
import PreTripCoursesForm from './PreTripCoursesForm';
import { connect } from 'react-redux';
import * as preTripCourseActions from '../../actions/preTripCourseActions';

class PreTripCoursesContainer extends Component {
  state = { title: '', image: '', file: '' };

  componentDidMount() {
    const {
      getPreTripCourse,
      match: { params },
    } = this.props;
    getPreTripCourse(params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.preTripCourse != this.props.preTripCourse) {
      this.setState({
        ...this.props.preTripCourse,
      });
    }
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  updateImage = response => {
    this.setState({ changeAvatar: false, image: response.url });
  };
  replaceImage = () => {
    this.setState({ image: null });
  };

  updateFile = response => {
    this.setState({ file: response.url });
  };

  replaceFile = () => {
    this.setState({ file: null });
  };

  render() {
    return (
      <PreTripCoursesForm
        {...this.props}
        updateImage={this.updateImage}
        handleChange={this.handleChange}
        replaceImage={this.replaceImage}
        editorChange={this.editorChange}
        data={this.state}
        updateFile={this.updateFile}
        replaceFile={this.replaceFile}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
    preTripCourse: state.preTripCourseReducer.preTripCourse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPreTripCourse: id => dispatch(preTripCourseActions.getPreTripCourse(id)),
    updatePreTripCourse: (id, preTripCourse) =>
      dispatch(preTripCourseActions.updatePreTripCourse(id, preTripCourse)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreTripCoursesContainer);
