import React, { Component } from 'react';
import CustomersView from './CustomersView';
import { connect } from 'react-redux';
import * as CustomersActions from '../../actions/customerActions';

class CustomersContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10 };

  componentDidMount() {
    this.props.onGetCustomers(this.state.currentPage, this.state.elementsPerPage);
  }

  changePage = currentPage => {
    const { elementsPerPage } = this.state;
    this.props.onGetCustomers(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  render() {
    const { customersCount, customers, loading } = this.props;
    const { currentPage, elementsPerPage } = this.state;
    return (
      <CustomersView
        {...this.props}
        getCustomers={this.getCustomers}
        customers={customers}
        currentPage={currentPage}
        changePage={this.changePage}
        customersCount={customersCount}
        elementsPerPage={elementsPerPage}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.customerReducer.customers,
    customersCount: state.customerReducer.customersCount,
    loading: state.customerReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onGetCustomers: (page, elementsPerPage) =>
      dispatch(CustomersActions.getCustomers(page, elementsPerPage)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomersContainer);
