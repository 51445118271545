/*
 * Reducer actions related with customers
 */
import { ActionTypes } from 'constants/index';

export function updateProfile(profile) {
  return {
    type: ActionTypes.UPDATE_PROFILE,
    profile,
  };
}

export function verifyDegradePlanRequest() {
  return {
    type: ActionTypes.VERIFY_DEGRADE_PLAN_REQUEST,
  };
}

export function verifyDegradePlanSuccess(data) {
  return {
    type: ActionTypes.VERIFY_DEGRADE_PLAN_SUCCESS,
    data,
  };
}
