export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        strengthName: 'Appreciation for Good',
        strengthDescription:
          '<p>As you prepare for your trip, get into the habit of noticing the natural beauty around you. Give yourself the goal of finding at least one instance of natural beauty a day, increasing this number as you become more observant of the beauty around you. Examples of natural beauty to look for include—but are certainly not limited to—plants, wild animals, landscapes, sunrises, sunsets, and the stars.</p> <br/><br/> <p>Think back to the curiosity-filled years of your childhood—when there were new things to be experienced and learned around every corner. When was the last time you felt that same sense of wonder? What would life be like if every day brought opportunities for adventure and discovery?</p>',
      };
    case 2:
      return {
        strengthName: 'Curiosity',
        strengthDescription:
          '<p>Expand your knowledge about an aspect of a place you plan on visiting, whether it be the history, the food, the culture, the traditions, the language, or the art. Use as many different sources to get this information, including books, magazines, internet message boards, podcasts, videos, and interviews.</p> <br/> <p>Do you ever daydream? If so, what do you daydream about? Are there any patterns to your daydreams? Do you repeatedly take journeys to the same places, encounter the same people, or do the same things? Imagine you were able to fulfill one of your daydreams. What—if anything—is stopping you from doing just that?</p>',
      };
    case 3:
      return {
        strengthName: 'Gratitude',
        strengthDescription:
          '<p>Who or what in your life do you take for granted? Is it a child, a relative, a spouse, your health, your career, or something else? Spend 20 minutes reflecting on the good things in your life that you might be overlooking. How might you work to better appreciate these things in the future? Can you express your Gratitude for these people or things in some way? How might you keep these efforts up on your upcoming journey?</p><br/><br/><p>Find a spot with a panoramic view, such as a tall building or a hill. Look out over the horizon and think about what might be lying behind the threshold of your vision. How far into the distance can your imagination carry you? To the end of the country? To the other side of the world? What types of wondrous things might you discover by heading towards the horizon? What kinds of things would you learn?</p> ',
      };
    case 4:
      return {
        strengthName: 'Love',
        strengthDescription:
          '<p>Before you leave home, make a list of the five people you’re most going to miss on your journey. Then, write down the five things that you most appreciate for each of these people. As you travel, look for ways in which you can express your Love to these people—ideally in ways that relate to what you appreciate about them. For instance, if you love how your uncle is always challenging you, you might consider giving him a framed photo of you at the top of a mountain you climbed.</p> <br/><br/> <p>Leaving the comfort of home for the uncertainties of the road can be hard. But knowing that you’re not the first person in history to do so can help make this easier. Think of someone who has traveled often—a friend, a travel writer, a television presenter—and imagine the worries they must have had before they left home for the first time. What do you think their greatest fears were? How do you think they dealt with these worries? If possible, reach out to this individual and ask them yourself.</p>',
      };
    case 5:
      return {
        strengthName: 'Open-Mindedness',
        strengthDescription:
          '<p>Think back to the last time you traveled. This could be a vacation abroad, a trip for work, or a long weekend getaway. Identify at least one thing you did that you weren’t happy about, such as not following through with a goal, failing to appreciate the moment, or letting your emotions get the better of you. Brainstorm some ways in which you could change this, either through action or inaction. Try applying these insights to your upcoming journey.</p> <br/><br/><p>Many of us have a tendency to underestimate our abilities. What’s one trait that you think all world travelers have? Do you yourself possess this? If not, what could you do to acquire this trait?</p> <br/><br/><p>Confronting fears and challenges is part of the adventure that is travel. With this in mind, when you envision leaving home, what are the biggest concerns you have? Are these realistic? What are the chances that your concerns will really happen? Are any of your fears irrational—such as getting permanently lost or being eaten by a bear? If so, know that these are not real and therefore don’t have any power over you. How would you respond if you did have to confront one of your major concerns? Close your eyes and see yourself overcome these challenges. Remember that you have it within yourself to conquer all these things.',
      };
    case 6:
      return {
        strengthName: 'Appreciation for Good',
        strengthDescription:
          '<p>Over the course of the next week, track the goodness you find within your life. This can be in the form of actions, thoughts, or words. When you find goodness, take a moment to fully appreciate it. Notice how the goodness in your life affects you. Look for patterns in how it comes about. How much of this goodness comes from others? Are there particular people or circumstances that help or hinder your ability to find goodness in life? How might you increase the amount and quality of goodness you experience on a daily basis? Can you apply any of these techniques to your upcoming journey?</p><br/><br/><p>Confronting fears and challenges is part of the adventure that is travel. With this in mind, when you envision leaving home, what are the biggest concerns you have? Are these realistic? What are the chances that your concerns will really happen? Are any of your fears irrational—such as getting permanently lost or being eaten by a bear? If so, know that these are not real and therefore don’t have any power over you. How would you respond if you did have to confront one of your major concerns? Close your eyes and see yourself overcome these challenges. Remember that you have it within yourself to conquer all these things.</p>',
      };
    case 7:
      return {
        strengthName: 'Curiosity',
        strengthDescription:
          '<p>Make a pre-trip list of unknowns about the places you’re going to visit. Bring this with you through your journey and try to get answers to these questions.</p> <br/><br/> <p>Create an idealized day for your upcoming journey. List out in detail what you would do from morning to night. Where would you go? What would you discover? How would you feel? Are there any aspects of this ideal day that surprise you?</p>',
      };
    case 8:
      return {
        strengthName: 'Gratitude',
        strengthDescription:
          '<p>We often neglect to express our Gratitude towards others, even when we ourselves are aware of the gifts they give to us. As you prepare for your upcoming journey, express your Gratitude to someone whom you haven’t told before, ideally in person. Notice how sharing your Gratitude makes both of you feel. What do they say to you in return? Do you feel more grateful after your visit? With whom else in your life might you want to share your Gratitude?</p><br/><br/><p>Create a list of travel experiences you would enjoy having that aren’t specific to any city or country. Examples include making a new friend, trying a local culinary specialty, or spending some time alone in nature. Bring this list with you on your journey and see how many you can accomplish.</p>',
      };
    case 9:
      return {
        strengthName: 'Love',
        strengthDescription:
          '<p>Practice loving kindness meditation before your journey. Find a quiet place where you can sit in peace. Close your eyes, relax your muscles, and take a few deep breaths. Take turns focusing on different aspects of specific individuals that you appreciation—such as your mom’s honesty or your friend’s loyalty—allowing your entire body to fill with the warm of these positive qualities. Do this for 10 to 20 minutes a day. Notice how you feel before, during, and after these meditation sessions. Try incorporating this practice into your upcoming journey.</p><br/><br/><p>Imagine yourself as the protagonist of a novel that takes place over the course of your journey. What would happened during that story? Where would you go? What would you do? Who would you meet? What kind of narrative arc would you hope to see occur? What lessons would you bring back with you?</p>',
      };
    case 10:
      return {
        strengthName: 'Open-Mindedness',
        strengthDescription:
          '<p>Ask someone with whom you’ve traveled in the past to critically appraise your judgment on that trip. Promise them that you will listen to what they have to say without getting upset or defensive. See if there’s anything you gain from this conversation that you can apply to your upcoming trip.</p><br/><br/><p>No great journey is undertaken entirely alone, even by solo travelers. While we might not see or notice them, there are always people in the background and on the sidelines that guide and encourage us. Who might be those people in your life? Who is helping (no matter how subtly) to help you reach your travel goals? How can you amplify their positive energy within your life? Is there anything you can bring from home that will remind you of their encouragement when you’re out on the road?</p>',
      };
    case 11:
      return {
        strengthName: 'Appreciation for Good',
        strengthDescription:
          '<p>It’s not uncommon for us to overlook goodness in others, especially if we spend a lot of time with them. Over the course of the next week, allow yourself to reflect on the worthwhile aspects of the people in your life. What in particular do you enjoy about them? How do they improve your life? Keep an eye out for this hidden goodness on your journey.</p><br/><br/><p>Envision yourself returning home from a successful journey. Newly-gained confidence, experience, and wisdom radiates out of you. Who along the way might have helped you? What would they look like? What would they do? Look for people like this on the road—they will help you achieve your goals.</p>',
      };
    case 12:
      return {
        strengthName: 'Curiosity',
        strengthDescription:
          '<p>When you fail to keep your Curiosity in check, you can offend others who feel you are being nosy or intrusive. While it’s natural to want to know what’s going on in others’ lives—especially if we care about them—expressing too much Curiosity can make them feel uncomfortable and want to distance themselves. If you’re very curious, others might think of you as overly excited, rude, or even impolite. Next time you feel the urge to ask someone something personal, step back and consider whether or not this is a nosy question. Do you really have a right to this information? Will the other person want to share this with you? How would you feel if someone asked you this question? Pay attention to this on your journey.</p><br/><br/><p>Research shows that being purpose-minded helps us to better overcome obstacles. With that in mind, and in your own words, what is your purpose, or Why, for traveling? Why are you leaving home? What is the long-term reason (or reasons) for doing what you are doing?</p>',
      };
    case 13:
      return {
        strengthName: 'Gratitude',
        strengthDescription:
          '<p>It’s almost impossible to notice the good in your life when you’re not present. When your mind is worried about what happened in the past and what you need to get done in the future, you only end up thinking about the negative and the missing. Practice stilling your mind for five minutes every morning and evening. Notice how living in the present moment shifts your awareness away from the bad and toward the good. Note what you become aware of during these sessions. As you go along, see if you can’t increase the number of things you recognize as being worthy of Gratitude within your life. Try to keep this practice up while on the road.</p><br/><br/><p> Goals are also an important part of travel. By setting and completing goals, you take the steps necessary to fulfill your purpose, or Why, for traveling. They provide short-term motivation and long-term vision. Goals ensure that your life on the road follows your own personal values. With this in mind, what are three goals you’d like to accomplish on your upcoming trip? Remember, the best goals are SMART (Specific, Measurable, Attainable, Relevant, and Time-Based).</p> ',
      };
    case 14:
      return {
        strengthName: 'Love',
        strengthDescription:
          '<p>Practice expressing Love—both verbally and non-verbally—to those you care about, regardless of circumstances. Remind yourself that Love is unconditional. Notice how this makes you feel. Now try to spread this practice on to others, starting with friends, then moving to acquaintances, and then finally onto complete strangers. How does this affect how you see the world? Can you apply this to the people you meet on your upcoming trip?</p><br/><br/><p>No matter how well we plan, no trip goes exactly as we expect it to. As such, it’s important that we don’t hang on too tightly to our expectations for any journey. What is meant to happen will happen, and what we’re not ready for yet won’t. Do you have any expectations for your trip? Are they based on fantasy rather than reality? Are any of these things outside of your control? When can I schedule expectation-free time during my journey?</p>',
      };
    case 15:
      return {
        strengthName: 'Open-Mindedness',
        strengthDescription:
          '<p>One of the best things about travel is the exchange of ideas it promotes. Before leaving on your next trip, practice discussing topics that are important to you with people who hold differing opinions. For some, this will be easy. For others, it will feel rather uncomfortable at first. If you find yourself in the latter camp, stick with it—it will get easier the more you do it. Do your best to avoid reacting with emotion, even when you strongly disagree with something. Rather, listen intently and with compassion, trying your best to understand their perspective.</p><br/><br/><p>What are some things currently available to you that you would thoroughly enjoy? Would you like to have dinner with a friend, take a walk in the woods, or grab a coffee at your favorite cafe? Knowing that you’ll soon be on the road, allow yourself to indulge in a few of the joyful aspects of home life.</p>',
      };
  }
};
