import React, { Component } from 'react';
import SelectEntertainmentView from './SelectEntertainmentView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import { ENTERTAINMENT } from 'constants/index';

const entertainmentMaping = Object.fromEntries(
  ENTERTAINMENT.map(entertainment => [
    entertainment,
    entertainment
      .toLowerCase()
      .replaceAll(/\s/g, '_')
      .replaceAll('_&', ''),
  ]),
);

class HaveDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      festivals: 0,
      film: 0,
      live_music: 0,
      other_entertainment: 0,
      sporting_events: 0,
      theater: 0,
    };
  }
  componentDidMount() {
    const {
      festivals,
      film,
      live_music,
      other_entertainment,
      sporting_events,
      theater,
    } = this.props;
    this.setState({ festivals, film, live_music, other_entertainment, sporting_events, theater });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const {
      festivals,
      film,
      live_music,
      other_entertainment,
      sporting_events,
      theater,
    } = this.state;
    changeCurrentStep(step);
    saveTripData({
      entertainment: { festivals, film, live_music, other_entertainment, sporting_events, theater },
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <SelectEntertainmentView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        entertainmentMaping={entertainmentMaping}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    festivals: state.tripReducer.tripForm.entertainment.festivals,
    film: state.tripReducer.tripForm.entertainment.film,
    live_music: state.tripReducer.tripForm.entertainment.live_music,
    other_entertainment: state.tripReducer.tripForm.entertainment.other_entertainment,
    sporting_events: state.tripReducer.tripForm.entertainment.sporting_events,
    theater: state.tripReducer.tripForm.entertainment.theater,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HaveDates);
