import { put, call } from 'redux-saga/effects';
import * as profileActions from '../actions/profileActions';
import * as loginActions from '../actions/loginActions';
import * as appActions from '../actions/app';

export function* updateProfile(api, action) {
  const response = yield call(api.updateProfile, { ...action.profile });
  if (response.status == 200) {
    yield put(loginActions.updateProfileResponse(response.data));
    yield put(appActions.showAlert('Profile Updated.', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* verifyDegradePlanRequest(api) {
  const response = yield call(api.verifyDegradePlanRequest);
  if (response.status == 200) {
    yield put(profileActions.verifyDegradePlanSuccess(response.data));
  }
}
