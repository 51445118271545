import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { appColor } from 'modules/theme';
import ModalExcel from 'components/Suggestions/ModalExcel';

const GridContainer = styled.div`
  margin: 10px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  line-height: 0;
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

const InputActivity = styled.input`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 25)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: ${props => props.backgroundColor || '#38b6ff'};
`;
const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkText = styled.p`
  padding-top: 5px;
  font-size: 18px;
  cursor: pointer;
  color: #38b6ff;
`;

const AcommodationPricingsForm = props => {
  const {
    token,
    viewMode,
    accommodationPricing,
    saveAccommodationPricing,
    handleModalExcel,
    renderModalExcel,
    country: { id },
  } = props;

  return (
    <GridContainer>
      <Formik enableReinitialize initialValues={accommodationPricing} isInitialValid={true}>
        {({ values, handleChange }) => {
          return (
            <>
              {/*<FlexRow>
                <FlexColumn width={90}></FlexColumn>
                <LinkText onClick={() => handleModalExcel(true)}>Import from sheet</LinkText>
              </FlexRow>*/}
              <Label color={appColor} marginBottom={50}>
                Backpacker Accommodations
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Less Than</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="backpacker_accommodations_less_than"
                    value={values.backpacker_accommodations_less_than}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Less Than in $</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="backpacker_accommodations_less_than_usd"
                    value={values.backpacker_accommodations_less_than_usd}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Budget Accommodations
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Minimum</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="budget_accommodations_minimum"
                    value={values.budget_accommodations_minimum}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Minimum in $</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="budget_accommodations_minimum_usd"
                    value={values.budget_accommodations_minimum_usd}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Maximum</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="budget_accommodations_maximum"
                    value={values.budget_accommodations_maximum}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Maximum in $</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="budget_accommodations_maximum_usd"
                    value={values.budget_accommodations_maximum_usd}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Moderate Accommodations
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Minimum</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="moderate_accommodations_minimum"
                    value={values.moderate_accommodations_minimum}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Minimum in $</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="moderate_accommodations_minimum_usd"
                    value={values.moderate_accommodations_minimum_usd}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Maximum</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="moderate_accommodations_maximum"
                    value={values.moderate_accommodations_maximum}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Maximum in $</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="moderate_accommodations_maximum_usd"
                    value={values.moderate_accommodations_maximum_usd}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Pricier Accommodations
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Minimum</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="pricier_accommodations_minimum"
                    value={values.pricier_accommodations_minimum}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Minimum in $</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="pricier_accommodations_minimum_usd"
                    value={values.pricier_accommodations_minimum_usd}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Maximum</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="pricier_accommodations_maximum"
                    value={values.pricier_accommodations_maximum}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Maximum in $</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="pricier_accommodations_maximum_usd"
                    value={values.pricier_accommodations_maximum_usd}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>

              <Label color={appColor} marginBottom={50}>
                Splurge Accommodations
              </Label>
              <FlexRow>
                <FlexColumn>
                  <Label>Most Rooms More Than</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="splurge_accommodations_most_rooms_more_than"
                    value={values.splurge_accommodations_most_rooms_more_than}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
                <FlexColumn>
                  <Label>Most Rooms More Than in $</Label>
                  <InputActivity
                    type="number"
                    placeholder="price"
                    name="splurge_accommodations_most_rooms_more_than_usd"
                    value={values.splurge_accommodations_most_rooms_more_than_usd}
                    onChange={handleChange}
                    min={0}
                    disabled={viewMode}
                  />
                </FlexColumn>
              </FlexRow>
              {!viewMode && (
                <ContainerButton>
                  <Button onClick={() => saveAccommodationPricing(id, values)}>Save</Button>
                </ContainerButton>
              )}
            </>
          );
        }}
      </Formik>
      {/*<ModalExcel
        id={id}
        token={token}
        url="accommodation-pricings"
        renderModalExcel={renderModalExcel}
        handleModalExcel={handleModalExcel}
      ></ModalExcel>*/}
    </GridContainer>
  );
};

export default AcommodationPricingsForm;
