import React, { Component } from 'react';
import RestaurantsView from './RestaurantsView';
import { connect } from 'react-redux';
import * as RestaurantsActions from 'actions/restaurantActions';

class RestaurantsContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10, isOpen: false };

  componentDidMount() {
    this.fetchRestaurants();
  }

  fetchRestaurants = () => {
    const { currentPage, elementsPerPage } = this.state;

    this.props.onGetRestaurants(currentPage, elementsPerPage);
  };

  changePage = currentPage => {
    const { elementsPerPage } = this.state;
    this.props.onGetRestaurants(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  toggleModal = restaurantSelected => {
    const { isOpen } = this.state;

    if (!isOpen) {
      window.scrollTo(0, 0);
    }

    this.setState({
      isOpen: !isOpen,
      restaurantSelected,
    });
  };

  removeRestaurant = () => {
    const { onRemoveRestaurant } = this.props;
    const { restaurantSelected } = this.state;

    onRemoveRestaurant(restaurantSelected);

    this.setState({
      isOpen: false,
      restaurantSelected,
    });
  };

  render() {
    const { restaurantsCount, restaurants, loading } = this.props;
    const { currentPage, elementsPerPage, isOpen } = this.state;
    return (
      <RestaurantsView
        {...this.props}
        getRestaurants={this.getRestaurants}
        restaurants={restaurants}
        currentPage={currentPage}
        changePage={this.changePage}
        restaurantsCount={restaurantsCount}
        elementsPerPage={elementsPerPage}
        isOpen={isOpen}
        toggleModal={this.toggleModal}
        removeRestaurant={this.removeRestaurant}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    restaurants: state.restaurantReducer.restaurants,
    restaurantsCount: state.restaurantReducer.restaurantsCount,
    loading: state.restaurantReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onGetRestaurants: (page, elementsPerPage) =>
      dispatch(RestaurantsActions.getRestaurants(page, elementsPerPage)),
    onRemoveRestaurant: id => dispatch(RestaurantsActions.removeRestaurant(id)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestaurantsContainer);
