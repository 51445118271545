import React, { Component } from 'react';
import StepContainer from 'components/StepContainer';
import StepButtons from 'components/StepButtons';
import PlanBox from 'components/Register/PlanBox';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { BOUTIQUE_PACKAGE_ID } from 'constants/index';
import styled from 'styled-components';

const parse = require('html-react-parser');

const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-bottom: 60px;
  flex-wrap: wrap;
  justify-content: center;
`;

const ModalProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: space-between;
  flex-direction: row;
`;

const ModalProduct = styled.div`
  width: 50%;
  padding: 12px;
  justify-content: center;
  @media (max-width: 840px) {
    width: 100%;
  }
`;

const ModalTitle = styled.p`
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
`;

const LearnMore = styled.span`
  font-size: 15px;
  color: #38b6ff;
  cursor: pointer;
  text-transform: uppercase;
`;

class Step3 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      changeStep,
      selectPlan,
      selectedPlan,
      step,
      products,
      productsModal,
      changeProductsModal,
    } = this.props;
    if (!products) return null;
    return (
      <>
        <StepContainer title={'Choose a Package'} color={'transparent'}>
          <PlansContainer>
            {products
              .filter(p => p.id !== BOUTIQUE_PACKAGE_ID)
              .map((product, key) => (
                <PlanBox
                  key={key}
                  product={product}
                  product_id={selectedPlan}
                  selectPlan={selectPlan}
                  changeProductsModal={changeProductsModal}
                />
              ))}
          </PlansContainer>
        </StepContainer>
        <StepButtons
          nonDisplayNext
          backStep={() => changeStep(step - 1)}
          nextStep={() => changeStep(step + 1)}
        />

        <Modal isVisible={productsModal}>
          <>
            <ModalTitle>Choose the Plan That's Right for You</ModalTitle>
            <ModalProducts>
              {products.map((product, key) => (
                <ModalProduct key={key}>
                  <LearnMore onClick={() => selectPlan(product.id)}>{product.title}</LearnMore>
                  <p style={{ lineHeight: 1.5, textAlign: 'initial' }}>
                    {parse(product.description)}
                  </p>
                </ModalProduct>
              ))}
              <Button onClick={changeProductsModal}>Back to Packages</Button>

              <div>
                <p>
                  <span style={{ color: '#38b6ff' }}>*</span> when available
                </p>

                <p>
                  <span style={{ color: '#38b6ff' }}>**</span> limited hours
                </p>
              </div>
            </ModalProducts>
          </>
        </Modal>
      </>
    );
  }
}

export default Step3;
