import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  tripDetails: null,
  tripForm: {
    step: 1,
    user_id: null,
    // choose cities
    like_to_go: false,
    cities_selected: [],
    citiesFiltered: [],
    //past destinations
    past_destinations: [{ destination: '', year: '', description: '', like_back: false }],
    travel_with_others: false,
    // how many
    how_many: null,
    know_how_many: false,
    traveling_with: [],
    know_dates: null,
    month_ranges: [],
    children_olds: [''],
    //entretainment
    entertainment: {
      festivals: 0,
      film: 0,
      live_music: 0,
      other_entertainment: 0,
      sporting_events: 0,
      theater: 0,
    },
    //Food & drink
    food_and_drink: {
      beer: 0,
      cheese_dairy: 0,
      coffee_tea: 0,
      dessert: 0,
      food_drink_tours: 0,
      fruit_vegetables: 0,
      liquor: 0,
      local_specialities: 0,
      markets: 0,
      other_food_and_drink: 0,
      red_meat: 0,
      seafood: 0,
      street_food: 0,
      wine: 0,
    },
    //Museum
    musseum: {
      aquariums_zoos: 0,
      art_museums: 0,
      childrens_museums: 0,
      history_museums: 0,
      military_museums: 0,
      natural_history_museums: 0,
      other_museums: 0,
      science_museums: 0,
      speciality_museums: 0,
    },
    // Nightlife
    nightlife: {
      cocktails_craft_beer_wine: 0,
      dance_clubs_discos: 0,
      live_music: 0,
      local_hangouts: 0,
      other_night_life: 0,
      pubs: 0,
      specialty_spots: 0,
    },
    // Outdoor activities
    outdoor_activities: {
      adventure_sports: 0,
      beaches: 0,
      biking: 0,
      camping: 0,
      golf: 0,
      hiking: 0,
      horseback_riding: 0,
      other_outdoor_activities: 0,
      parks_gardens: 0,
      scenic_views: 0,
      water_sports: 0,
      wild_life: 0,
      winter_sports: 0,
    },
    // Sightseeing
    sightseeing: {
      castles_palaces_villas: 0,
      historic_important_sites: 0,
      markets: 0,
      modern_marvels: 0,
      monuments_statues: 0,
      nature_parks: 0,
      neighborhood_town_walks: 0,
      notable_architecture: 0,
      other_sightseeing: 0,
      sacred_religious_sites: 0,
      scenic_views: 0,
      scenic_walking_areas: 0,
      squares: 0,
      water_theme_parks: 0,
    },
    // Shopping
    shopping: {
      artisan_shops_specialty_stores: 0,
      department_stores: 0,
      food_drink: 0,
      malls: 0,
      markets: 0,
      other_shooping: 0,
      shopping_districts_streets: 0,
    },
    // Tour classes
    tour_classes: {
      art_classes: 0,
      audio_guides: 0,
      baking_cooking_classes: 0,
      bike_tours: 0,
      boat_tours: 0,
      bus_tours: 0,
      dance_classes: 0,
      exercise_classes: 0,
      food_drink_tours: 0,
      hiking_tours: 0,
      music_lessons: 0,
      other_tour_classes: 0,
      private_tours: 0,
      self_guided_tours: 0,
      walking_tours: 0,
      wellness_classes: 0,
      wild_life_tours: 0,
    },
    //Wellness
    wellness: {
      classes: 0,
      gyms: 0,
      massage: 0,
      meditation: 0,
      other_wellness: 0,
      resorts_retreats: 0,
      saunas_bathhouses: 0,
      spas_thermal_springs: 0,
      yoga: 0,
    },

    //accommodation
    accommodation: null,
    //Foodie destination
    foodie_destination: null,
    //eateries
    eateries: null,
    //is_vegan
    is_vegan: null,
    //rental car
    rental_car: false,
    //temperature range
    // Humidity level:
    humidity_levels: [],
    //raining level
    raining_level: null,
    //landscapes
    landscapes: [],
    // temperature ranges
    temperature_ranges: [],
    start_date: null,
    end_date: null,
    arriving_time: 'Morning',
    departure_time: 'Morning',
  },
  trips: [],
  activitiesByCity: [],
  loading: false,
  costs: [],
};

export default {
  tripReducer: handleActions(
    {
      [ActionTypes.TRIP_CHANGE_CURRENT_STEP](state, action) {
        return {
          ...state,
          tripForm: { ...state.tripForm, step: action.step },
        };
      },
      [ActionTypes.SAVE_TRIP_DATA](state, action) {
        return {
          ...state,
          tripForm: { ...state.tripForm, ...action.data },
        };
      },
      [ActionTypes.SAVE_DESTINATIONS](state, action) {
        return {
          ...state,
          tripForm: { ...state.tripForm, citiesFiltered: action.data.rows },
        };
      },
      [ActionTypes.SAVE_TRIP](state, action) {
        return {
          ...state,
          tripDetails: { ...state.tripDetails, ...action.tripDetails },
        };
      },
      [ActionTypes.SAVE_ACTIVITITIES_BY_CITIES](state, action) {
        return {
          ...state,
          activitiesByCity: action.data,
        };
      },
      [ActionTypes.SAVE_ME_TRIPS](state, action) {
        return {
          ...state,
          trips: action.trips,
          tripForm: appState.tripForm,
          tripDetails: null,
        };
      },
      [ActionTypes.GET_ALL_TRIPS_REQUEST](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.GET_ALL_TRIPS_SUCCESS](state, action) {
        return {
          ...state,
          trips: action.data.rows,
          tripsCount: action.data.count,
          loading: false,
        };
      },
      [ActionTypes.RESET_TRIP_FORM](state) {
        return {
          ...appState,
        };
      },

      [ActionTypes.GET_COSTS](state, action) {
        return {
          ...state,
          loading: true,
          costs: [],
        };
      },
      [ActionTypes.GET_COSTS_SUCCESS](state, action) {
        return {
          ...state,
          costs: action.costs,
          loading: false,
        };
      },

      [ActionTypes.UPDATE_COST](state, action) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.UPDATE_COSTS_SUCCESS](state, action) {
        return {
          ...state,
          loading: false,
        };
      },
    },
    appState,
  ),
};
