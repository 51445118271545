import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Pagination from 'components/PaginationButtons';
import MeetingsList from 'components/MeetingsList';
import Modal from 'components/Modal';
import Loader from 'components/Loader';

const Container = styled.div`
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const HeaderContainer = styled.div`
  padding-bottom: 5px;
  width: auto;
  height: 88px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const DatePickerBox = styled.p`
  margin: 35px 30%;
`;

const PrimaryText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 25px;
  color: ${props => props.color};
  text-align: center;
`;

const Select = styled.select`
  background: white;
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  height: 44px;
  margin-top: 15px;
  padding: 10px;
  width: 100%;
  ::placeholder {
    color: #bebcbc;
    margin-left: 8px;
    font-size: 12px;
  }
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: #38b6ff;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;
const Meetings = props => {
  const {
    currentPage,
    changePage,
    meetingsCount,
    elementsPerPage,
    meetings,
    toggleModal,
    saveMeetingFnc,
    selectedDate,
    changeDate,
    possibleHours,
    changeHour,
    hour,
    addMeetingModal,
    loadingMeeting,
  } = props;
  return (
    <Container>
      {loadingMeeting ? (
        <LoaderContainer>
          <Loader type="pulse" size={125} />
        </LoaderContainer>
      ) : (
        <>
          <HeaderContainer>
            <Button onClick={toggleModal}>Schedule Discussion</Button>
            <div style={{ marginRight: '20px' }}>
              <Pagination
                currentPage={currentPage}
                changePage={changePage}
                totalPages={Math.ceil(meetingsCount / elementsPerPage)}
              />
            </div>
          </HeaderContainer>
          <MeetingsList meetings={meetings} />
        </>
      )}

      <Modal
        closeIcon
        isVisible={addMeetingModal}
        cancelText="Cancel"
        onCancel={toggleModal}
        successText="Save"
        onSuccess={() => saveMeetingFnc({ date: hour })}
      >
        <DatePickerBox>
          <PrimaryText color="#696969">Book a meeting</PrimaryText>
          <DatePicker
            placeholderText="Select Date"
            className="datepicker"
            showPopperArrow={false}
            selected={selectedDate}
            onChange={date => changeDate(date)}
            minDate={moment()
              .add(3, 'd')
              .toDate()}
          />
          <Select name="hour" onChange={changeHour}>
            <option className="selectPlaceholder" value="" disabled selected hidden>
              Select Hour
            </option>
            {possibleHours &&
              possibleHours.free_time_slots.map((hour, key) => (
                <option key={key} value={hour.utcDate}>
                  {hour.formatedHour}
                </option>
              ))}
          </Select>
        </DatePickerBox>
      </Modal>
    </Container>
  );
};

export default Meetings;
