import React, { Component } from 'react';
import TripListAdminView from './TripsListAdminView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';
import { push } from 'modules/history';

class TripListAdminContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10 };

  componentDidMount() {
    const { getAllTripsRequest } = this.props;
    getAllTripsRequest(this.state.currentPage, this.state.elementsPerPage);
  }

  changePage = currentPage => {
    const { elementsPerPage } = this.state;
    const { getAllTripsRequest } = this.props;
    getAllTripsRequest(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  toEditTrip = trip => {
    push(`/trip/edit/${trip.id}`);
  };

  render() {
    const { tripsCount, trips, loading } = this.props;
    const { currentPage, elementsPerPage, alertEditTrip } = this.state;
    return (
      <TripListAdminView
        {...this.props}
        trips={trips}
        currentPage={currentPage}
        changePage={this.changePage}
        tripsCount={tripsCount}
        elementsPerPage={elementsPerPage}
        toEditTrip={this.toEditTrip}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    trips: state.tripReducer.trips,
    tripsCount: state.tripReducer.tripsCount,
    loading: state.tripReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAllTripsRequest: (page, elementsPerPage) =>
      dispatch(tripActions.getAllTripsRequest(page, elementsPerPage)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripListAdminContainer);
