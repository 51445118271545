import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import defaultImage from 'assets/media/images/default.png';
import ListEmpty from 'components/ListEmpty';
import { push } from 'modules/history';
import Modal from 'components/Modal';

const GridContainer = styled.div`
  margin: 38px;
  display: block;
  max-width: 100%;
  text-align: left;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: 36px;
  min-width: 1000px;
  flex-direction: row;
  justify-content: space-between;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 15px;
  height: 100px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  height: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  display: flex;
  justify-content: ${props => props.justifyContent || 'center'};
`;

const Image = styled.img`
  width: 86px;
  height: 86px;
  border-radius: 9px;
  margin: 5px;
  object-fit: cover;
`;

const Primary = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
  color: ${props => (props.color ? props.color : 'initial')};
`;
const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 10px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 75px;
  width: 1px;
  background-color: #ebebeb;
`;

const Title = styled.p`
  font-weight: 400;
  font-size: 25px;
`;

const ItineraryItem = styled.div`
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  height: 75px;
  margin-bottom: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  justify-content: space-between;
  :hover {
    background-color: #38b6ff21;
  }
`;

const Select = styled.select`
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  :focus {
    outline: none;
  }
  :focus-visible {
    outline: none;
  }

  width: 100px;
  height: 52px;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgb(0 0 0 / 4%);
  background-color: #ffffff;
  border: none;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #38b6ff;

  border-color: #38b6ff;
  border-style: solid;
  border-width: 1px;
`;

const TBody = styled.div``;

class TripList extends Component {
  createTrip = () => {
    const { goTo, customerId } = this.props;
    const path = customerId ? `/trip/create/${customerId}` : '/trips/create';
    goTo(path);
  };

  viewTrip = id => {
    const { goTo, customerId } = this.props;
    if (customerId) {
      goTo(`/trips/${id}`);
    } else {
      goTo(`/customer-trip/${id}`);
    }
  };

  renderTrip({ id, title, image, goal, authenticPlan, journal, itineraries }) {
    const { toggleItinerariesModal, selectCurrentItineraries } = this.props;
    return (
      <Item key={id}>
        <SectionContainer width={'10%'} justifyContent="end">
          <Image src={image || defaultImage} />
        </SectionContainer>
        <SectionContainer width={'20%'} justifyContent="end">
          <div>
            <Secondary>Journey Title</Secondary>
            <Primary>
              <i class="fas fa-map-marker-alt" style={{ color: '#38b6ff' }} />
              {title}
            </Primary>
            <VerticalLine />
          </div>
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <div>
            <Primary>
              <VerticalLine />
              <Link to={authenticPlan ? `/at-plan/${authenticPlan.id}` : '/'}>
                <Primary color={authenticPlan ? '#00b4d5' : 'gray'}>
                  <i
                    style={{ marginRight: '2px', color: authenticPlan ? '#00b4d5' : 'gray' }}
                    className="fas fa-file-alt"
                  />
                  AT Plan
                </Primary>
              </Link>
            </Primary>
          </div>
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <div>
            <Primary>
              <VerticalLine />
              <Link to={journal ? `/journal/${journal.id}` : ''}>
                <Primary color={journal ? '#00b4d5' : 'gray'}>
                  <i
                    style={{ marginRight: '2px', color: journal ? '#00b4d5' : 'gray' }}
                    className="fas fa-file-alt"
                  />
                  Journal
                </Primary>
              </Link>
            </Primary>
          </div>
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <div>
            <Primary>
              <VerticalLine />
              <Link to={goal ? `/goal/${goal.id}` : '/'}>
                <Primary color={goal ? '#00b4d5' : 'gray'}>
                  <i
                    style={{ marginRight: '2px', color: goal ? '#00b4d5' : 'gray' }}
                    className="fas fa-file-alt"
                  />
                  Goals
                </Primary>
              </Link>
            </Primary>
          </div>
        </SectionContainer>

        <SectionContainer width={'10%'}>
          <div>
            <Primary>
              <Primary
                style={{ cursor: 'pointer' }}
                color="#00b4d5"
                onClick={() => {
                  toggleItinerariesModal();
                  selectCurrentItineraries(itineraries);
                }}
              >
                <i style={{ marginRight: '2px', color: '#00b4d5' }} className="fas fa-eye" />
                View
              </Primary>
            </Primary>
          </div>
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const {
      trips,
      itinerariesModal,
      toggleItinerariesModal,
      currentItineraries,
      handleChange,
      types_filter,
    } = this.props;
    return (
      <GridContainer>
        <Header>
          <Title>Your Journeys</Title>
        </Header>
        <TBody>
          {trips.length > 0 &&
            trips.map(trip => {
              return this.renderTrip(trip);
            })}
          {trips.length == 0 && <ListEmpty message="There are currently no journeys" />}
        </TBody>

        <Modal
          closeIcon
          isVisible={itinerariesModal}
          onCancel={toggleItinerariesModal}
          hiddeButtons
          width={'50%'}
          widthMedium={'50%'}
        >
          <GridContainer>
            <Title style={{ textAlign: 'left' }}>Itineraries</Title>
            <div style={{ minHeight: 325 }}>
              {currentItineraries
                .filter(itinerary =>
                  types_filter == 'All' ? true : itinerary.type == types_filter,
                )
                .map(itinerary => (
                  <ItineraryItem
                    onClick={() => push(`/customer-trip/${itinerary.trip_id}/${itinerary.id}`)}
                  >
                    <p>{itinerary.title}</p>
                    {itinerary.is_default && (
                      <p style={{ color: '#4BC142', fontWeight: 'bold' }}>Default</p>
                    )}
                  </ItineraryItem>
                ))}
            </div>

            <Select name="types_filter" value={types_filter} onChange={handleChange}>
              <option value="All">All</option>
              <option value="Group">Group</option>
              <option value="Single">Single</option>
            </Select>
          </GridContainer>
        </Modal>
      </GridContainer>
    );
  }
}

export default TripList;
