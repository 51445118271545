import React, { Component } from 'react';
import SelectNightlifeView from './SelectNightlifeView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';
import { NIGHTLIFE } from 'constants/index';

const nightlifeMaping = Object.fromEntries(
  NIGHTLIFE.map(nightlife => [
    nightlife,
    nightlife
      .toLowerCase()
      .replaceAll(/\s/g, '_')
      .replaceAll('_&', '')
      .replaceAll(',', ''),
  ]),
);

class SelectNightlifeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cocktails_craft_beer_wine: 0,
      dance_clubs_discos: 0,
      live_music: 0,
      local_hangouts: 0,
      other_night_life: 0,
      pubs: 0,
      specialty_spots: 0,
    };
  }
  componentDidMount() {
    const {
      cocktails_craft_beer_wine,
      dance_clubs_discos,
      live_music,
      local_hangouts,
      other_night_life,
      pubs,
      specialty_spots,
    } = this.props;
    this.setState({
      cocktails_craft_beer_wine,
      dance_clubs_discos,
      live_music,
      local_hangouts,
      other_night_life,
      pubs,
      specialty_spots,
    });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const {
      cocktails_craft_beer_wine,
      dance_clubs_discos,
      live_music,
      local_hangouts,
      other_night_life,
      pubs,
      specialty_spots,
    } = this.state;
    changeCurrentStep(step);
    saveTripData({
      nightlife: {
        cocktails_craft_beer_wine,
        dance_clubs_discos,
        live_music,
        local_hangouts,
        other_night_life,
        pubs,
        specialty_spots,
      },
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <SelectNightlifeView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        handleChange={this.handleChange}
        nightlifeMaping={nightlifeMaping}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    cocktails_craft_beer_wine: state.tripReducer.tripForm.nightlife.cocktails_craft_beer_wine,
    dance_clubs_discos: state.tripReducer.tripForm.nightlife.dance_clubs_discos,
    live_music: state.tripReducer.tripForm.nightlife.live_music,
    local_hangouts: state.tripReducer.tripForm.nightlife.local_hangouts,
    other_night_life: state.tripReducer.tripForm.nightlife.other_night_life,
    pubs: state.tripReducer.tripForm.nightlife.pubs,
    specialty_spots: state.tripReducer.tripForm.nightlife.specialty_spots,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectNightlifeContainer);
