import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import Checkbox from '../../Login/CheckboxRemember';

const TravelWithOthersBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TravelWithOthersPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const LightButton = styled.button`
  border-radius: 5px;
  color: white;
  background-color: ${props => (props.active ? '#00b4d51b;' : 'inherit;')}
  border: 1px solid ${props => (props.active ? '#38b6ff;' : '#b7b7b7;')}
  width: 268px;
  height: 52px;
  color: ${props => (props.active ? '#38b6ff;' : '#b7b7b7;')}
  margin: 15px 5px;
  font-family: 'Josefin Sans', sans-serif;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: center;
`;

const InputActivity = styled.input`
  width: 100px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  margin-left: 14px;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
`;

const Text = styled.text`
  width: 100%;
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18.5px;
  line-height: 18px;
  text-transform: capitalize;
  color: #999999;
  margin-top: 40px;
  margin-bottom: 7px;
`;

class TravelWithOthersView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      step,
      state,
      changeStep,
      setTravelWithOthers,
      handleChangeCheckbox,
      handleChange,
    } = this.props;
    const { travel_with_others, how_many, know_how_many } = state;
    return (
      <TravelWithOthersBox>
        <TravelWithOthersPrimaryBox>
          <PrimaryText text="Are you planning on bringing others on your journey?" />
          <ButtonBox>
            <LightButton active={travel_with_others} onClick={() => setTravelWithOthers(true)}>
              Yes
            </LightButton>
            <LightButton active={!travel_with_others} onClick={() => setTravelWithOthers(false)}>
              No
            </LightButton>
          </ButtonBox>

          {travel_with_others && (
            <>
              <Checkbox
                style={{ justifyContent: 'center' }}
                labelInverted
                label="Do you know approximately how many people you will travel with?"
                name="know_how_many"
                isChecked={know_how_many}
                toggleChange={handleChangeCheckbox}
              />
              {know_how_many && (
                <div style={{ marginBottom: 15 }}>
                  <Text>How many? </Text>
                  <InputActivity
                    type="number"
                    name={`how_many`}
                    value={how_many}
                    onChange={handleChange}
                  />
                </div>
              )}
            </>
          )}
          <StepButtons
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(travel_with_others ? step + 1 : step + 2)}
          />
        </TravelWithOthersPrimaryBox>
      </TravelWithOthersBox>
    );
  }
}

export default TravelWithOthersView;
