import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  journal: null,
  calling: null,
  deapture: null,
  road: null,
  preparingReturn: null,
  returnData: null,
};

export default {
  journalReducer: handleActions(
    {
      [ActionTypes.GET_JOURNAL_SUCCESS](state, action) {
        return {
          ...state,
          journal: action.journal,
        };
      },
      [ActionTypes.GET_CALLING_SUCCESS](state, action) {
        return {
          ...state,
          calling: action.calling,
        };
      },
      [ActionTypes.GET_DEAPTURE_SUCCESS](state, action) {
        return {
          ...state,
          deapture: action.deapture,
        };
      },
      [ActionTypes.GET_ROAD_SUCCESS](state, action) {
        return {
          ...state,
          road: action.road,
        };
      },
      [ActionTypes.GET_PREPARING_RETURN_SUCCESS](state, action) {
        return {
          ...state,
          preparingReturn: action.preparingReturn,
        };
      },
      [ActionTypes.GET_RETURN_SUCCESS](state, action) {
        return {
          ...state,
          returnData: action.returnData,
        };
      },
    },
    appState,
  ),
};
