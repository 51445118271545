import React, { Component } from 'react';
import CitiesForm from './CitiesForm';
import { connect } from 'react-redux';
import * as citiesActions from '../../actions/citiesActions';
import * as regionsActions from '../../actions/regionsActions';

class CreateCities extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getCities,
      getRegionsForSelect,
    } = this.props;
    getCities(id);
    getRegionsForSelect();
  }

  editCity = city => {
    const {
      match: {
        params: { id },
      },
      editCities,
    } = this.props;
    editCities(id, city);
  };

  render() {
    const { city, regionsForSelect } = this.props;
    return <CitiesForm city={city} regionsForSelect={regionsForSelect} submit={this.editCity} />;
  }
}

function mapStateToProps(state) {
  return {
    city: state.citiesReducer.city,
    regionsForSelect: state.regionsReducer.regionsForSelect,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getCities: id => dispatch(citiesActions.getCities(id)),

    editCities: (id, city) => dispatch(citiesActions.editCities(id, city)),
    getRegionsForSelect: () => dispatch(regionsActions.getRegionsForSelect()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateCities);
