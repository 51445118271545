import React, { Component } from 'react';
import CreateSuggestionView from './CreateSuggestionView';
import * as SuggestionActions from 'actions/suggestionActions';
import * as regionsActions from 'actions/regionsActions';
import * as citiesActions from 'actions/citiesActions';

import { connect } from 'react-redux';

class CreateSuggestionContainer extends Component {
  state = {
    currentPage: 1,
    elementsPerPage: 10,
    activeTab: 'Climates',
    citySearchableText: '',
    lifecyclesearchable: '',
    cuisineSearchableText: '',
    renderAddCity: false,
    isEditing: false,
    renderModalExcel: false,
    delayTyping: null,
  };

  componentDidMount() {
    const {
      getCountryRequest,
      getClimate,
      match: { params },
      getRegionsForSelect,
    } = this.props;
    const { currentPage, elementsPerPage, citySearchableText } = this.state;
    getCountryRequest(params.id);
    getClimate({ country_id: params.id, currentPage, elementsPerPage, search: citySearchableText });

    getRegionsForSelect({ country_id: params.id });
    //getCitiesForSelect();
  }

  changeTab = activeTab => {
    this.setState({
      activeTab,
    });
    const {
      country: { id, visit_suggestion_id, daily_expenses_id, accommodation_id },
      getClimate,
      getLifecyclesRequest,
      getVisitSuggestion,
      getCuisineSpecialitiesRequest,
      getAverageDailyExpenses,
      getAccommodationPricing,
      match: { params },
    } = this.props;

    const {
      currentPage,
      elementsPerPage,
      citySearchableText,
      lifecyclesearchable,
      cuisineSearchableText,
    } = this.state;
    const lifeCycles = {
      country_id: id,
      currentPage,
      elementsPerPage,
      search: lifecyclesearchable,
    };
    const climates = { country_id: id, currentPage, elementsPerPage, search: citySearchableText };
    const Cuisinespecialities = {
      country_id: id,
      currentPage,
      elementsPerPage,
      search: cuisineSearchableText,
    };

    if (activeTab == 'Climates' && id) {
      getClimate(climates);
    }
    if (activeTab == 'Lifecycle' && id) {
      getLifecyclesRequest(lifeCycles);
    }
    if (activeTab == 'Cuisine specialities' && id) {
      getCuisineSpecialitiesRequest(Cuisinespecialities);
    }
    if (activeTab == 'Visit Suggestions' && visit_suggestion_id) {
      getVisitSuggestion(visit_suggestion_id);
    }
    if (activeTab == 'AVG Daily Expenses' && daily_expenses_id) {
      getAverageDailyExpenses(daily_expenses_id);
    }
    if (activeTab == 'Accommodation Pricings') {
      getAccommodationPricing(params.id);
    }
  };

  //LifeCycle

  searchLifeCycle = event => {
    const { name, value } = event.target;

    clearTimeout(this.state.delayTyping);

    const delayTyping = setTimeout(() => {
      const {
        getLifecyclesRequest,
        match: { params },
      } = this.props;

      const { currentPage, elementsPerPage } = this.state;
      getLifecyclesRequest({
        country_id: params.id,
        currentPage,
        elementsPerPage,
        search: value,
      });
    }, 1000);
    this.setState({
      [name]: value,
      delayTyping,
    });
  };
  changePage = currentPage => {
    const {
      getLifecyclesRequest,
      match: { params },
    } = this.props;
    const { elementsPerPage, lifecyclesearchable } = this.state;
    getLifecyclesRequest({
      country_id: params.id,
      currentPage,
      elementsPerPage,
      search: lifecyclesearchable,
    });
    this.setState({ currentPage });
  };

  //Cuisine
  searchCuisine = event => {
    const { name, value } = event.target;

    clearTimeout(this.state.delayTyping);

    const delayTyping = setTimeout(() => {
      const {
        getCuisineSpecialitiesRequest,
        match: { params },
      } = this.props;
      const { currentPage, elementsPerPage } = this.state;
      getCuisineSpecialitiesRequest({
        country_id: params.id,
        currentPage,
        elementsPerPage,
        search: value,
      });
    }, 1000);

    this.setState({
      [name]: value,
      delayTyping,
    });
  };

  changePage = currentPage => {
    const {
      getCuisineSpecialitiesRequest,
      match: { params },
    } = this.props;
    const { elementsPerPage, cuisineSearchableText } = this.state;
    getCuisineSpecialitiesRequest({
      country_id: params.id,
      currentPage,
      elementsPerPage,
      search: cuisineSearchableText,
    });
    this.setState({ currentPage });
  };

  handleAddCity = status => {
    this.setState({ renderAddCity: status });
  };

  handleisEditing = status => {
    this.setState({ isEditing: status });
  };

  //searchCity
  searchCity = event => {
    const { name, value } = event.target;

    clearTimeout(this.state.delayTyping);

    const delayTyping = setTimeout(() => {
      const {
        getClimate,
        match: { params },
      } = this.props;
      const { currentPage, elementsPerPage } = this.state;
      getClimate({
        country_id: params.id,
        currentPage,
        elementsPerPage,
        search: value,
      });
    }, 1000);
    this.setState({
      [name]: value,
      delayTyping,
    });
  };

  changePage = currentPage => {
    const {
      getClimate,
      match: { params },
    } = this.props;
    const { elementsPerPage, citySearchableText } = this.state;
    getClimate({
      country_id: params.id,
      currentPage,
      elementsPerPage,
      search: citySearchableText,
    });
    this.setState({ currentPage });
  };

  handleAddCity = status => {
    this.setState({ renderAddCity: status });
  };

  handleModalExcel = status => {
    this.setState({ renderModalExcel: status });
  };

  handleisEditing = status => {
    this.setState({ isEditing: status });
  };

  render() {
    const {
      token,
      cities,
      climate,
      country,
      weather,
      loading,
      getClimate,
      setClimate,
      loadingData,
      saveClimate,
      editClimate,
      dailyExpenses,
      climatesCount,
      onRemoveClimate,
      saveVisitSuggestion,
      accommodationPricing,
      saveAverageDailyExpenses,
      saveAccommodationPricing,
      getLifecyclesRequest,
      getLifecycleRequest,
      removeLifecycleRequest,
      saveLifecycle,
      onShowLifecycleForm,
      lifecycles,
      lifecycle,
      lifecycleCount,
      lifecycleCurrentPage,
      loadingLifecycle,
      showLifecycleForm,
      getCuisineSpecialitiesRequest,
      getCuisineSpecialityRequest,
      removeCuisineSpecialityRequest,
      saveCuisineSpeciality,
      onShowCuisineSpecialityForm,
      cuisineSpecialities,
      cuisineSpeciality,
      cuisineSpecialityCount,
      cuisineSpecialityCurrentPage,
      loadingCuisineSpeciality,
      showCuisineSpecialityForm,
      getCitiesForSelect,
      regionsForSelect,
      citiesForSelect,
    } = this.props;
    const {
      activeTab,
      isEditing,
      currentPage,
      renderAddCity,
      renderModalExcel,
      elementsPerPage,
      citySearchableText,
    } = this.state;
    const tabs = [
      {
        action: () => this.changeTab('Climates'),
        title: 'Climates',
      },
      {
        action: () => this.changeTab('Visit Suggestions'),
        title: 'Visit Suggestions',
      },
      {
        action: () => this.changeTab('AVG Daily Expenses'),
        title: 'AVG Daily Expenses',
      },
      {
        action: () => this.changeTab('Accommodation Pricings'),
        title: 'Accommodation Pricings',
      },
      {
        action: () => this.changeTab('Lifecycle'),
        title: 'Lifecycle',
      },
      {
        action: () => this.changeTab('Cuisine specialities'),
        title: 'Cuisine specialities',
      },
    ];
    return (
      <CreateSuggestionView
        {...this.props}
        tabs={tabs}
        token={token}
        cities={cities}
        loading={loading}
        weather={weather}
        country={country}
        climate={climate}
        isEditing={isEditing}
        activeTab={activeTab}
        getClimate={getClimate}
        setClimate={setClimate}
        loadingData={loadingData}
        saveClimate={saveClimate}
        editClimate={editClimate}
        currentPage={currentPage}
        searchCity={this.searchCity}
        changePage={this.changePage}
        searchCuisine={this.searchCuisine}
        climatesCount={climatesCount}
        renderAddCity={renderAddCity}
        dailyExpenses={dailyExpenses}
        elementsPerPage={elementsPerPage}
        onRemoveClimate={onRemoveClimate}
        handleAddCity={this.handleAddCity}
        handleModalExcel={this.handleModalExcel}
        renderModalExcel={renderModalExcel}
        handleisEditing={this.handleisEditing}
        citySearchableText={citySearchableText}
        saveVisitSuggestion={saveVisitSuggestion}
        accommodationPricing={accommodationPricing}
        saveAverageDailyExpenses={saveAverageDailyExpenses}
        saveAccommodationPricing={saveAccommodationPricing}
        getLifecyclesRequest={getLifecyclesRequest}
        getLifecycleRequest={getLifecycleRequest}
        removeLifecycleRequest={removeLifecycleRequest}
        saveLifecycle={saveLifecycle}
        onShowLifecycleForm={onShowLifecycleForm}
        lifecycles={lifecycles}
        lifecycle={lifecycle}
        lifecycleCount={lifecycleCount}
        lifecycleCurrentPage={lifecycleCurrentPage}
        loadingLifecycle={loadingLifecycle}
        showLifecycleForm={showLifecycleForm}
        searchLifeCycle={this.searchLifeCycle}
        getCuisineSpecialitiesRequest={getCuisineSpecialitiesRequest}
        getCuisineSpecialityRequest={getCuisineSpecialityRequest}
        removeCuisineSpecialityRequest={removeCuisineSpecialityRequest}
        saveCuisineSpeciality={saveCuisineSpeciality}
        onShowCuisineSpecialityForm={onShowCuisineSpecialityForm}
        cuisineSpecialities={cuisineSpecialities}
        cuisineSpeciality={cuisineSpeciality}
        cuisineSpecialityCount={cuisineSpecialityCount}
        cuisineSpecialityCurrentPage={cuisineSpecialityCurrentPage}
        loadingCuisineSpeciality={loadingCuisineSpeciality}
        showCuisineSpecialityForm={showCuisineSpecialityForm}
        getCitiesForSelect={getCitiesForSelect}
        regionsForSelect={regionsForSelect}
        citiesForSelect={citiesForSelect}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
    country: state.suggestionReducer.country,
    weather: state.suggestionReducer.visit_suggestion,
    dailyExpenses: state.suggestionReducer.daily_expenses,
    accommodationPricing: state.suggestionReducer.accommodation_pricing,
    climate: state.suggestionReducer.climate,
    climatesCount: state.suggestionReducer.climatesCount,
    cities: state.suggestionReducer.climates,
    loading: state.suggestionReducer.loading,
    loadingData: state.suggestionReducer.loadingData,
    lifecycles: state.suggestionReducer.lifecycles,
    lifecycle: state.suggestionReducer.lifecycle,
    lifecycleCount: state.suggestionReducer.lifecycleCount,
    lifecycleCurrentPage: state.suggestionReducer.lifecycleCurrentPage,
    loadingLifecycle: state.suggestionReducer.loadingLifecycle,
    showLifecycleForm: state.suggestionReducer.showLifecycleForm,
    cuisineSpecialities: state.suggestionReducer.cuisineSpecialities,
    cuisineSpeciality: state.suggestionReducer.cuisineSpeciality,
    cuisineSpecialityCount: state.suggestionReducer.cuisineSpecialityCount,
    cuisineSpecialityCurrentPage: state.suggestionReducer.cuisineSpecialityCurrentPage,
    loadingCuisineSpeciality: state.suggestionReducer.loadingCuisineSpeciality,
    showCuisineSpecialityForm: state.suggestionReducer.showCuisineSpecialityForm,
    regionsForSelect: state.regionsReducer.regionsForSelect,
    citiesForSelect: state.citiesReducer.citiesForSelect,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCountryRequest: id => dispatch(SuggestionActions.getCountryRequest(id)),
    getVisitSuggestion: id => dispatch(SuggestionActions.getVisitRequest(id)),
    saveVisitSuggestion: (id, weather) => dispatch(SuggestionActions.saveVisitRequest(id, weather)),
    getAverageDailyExpenses: id => dispatch(SuggestionActions.getAverageDailyExpensesRequest(id)),
    saveAverageDailyExpenses: (id, daily_expenses) =>
      dispatch(SuggestionActions.saveAverageDailyExpensesRequest(id, daily_expenses)),
    getAccommodationPricing: id => dispatch(SuggestionActions.getAccommodationPricingRequest(id)),
    saveAccommodationPricing: (id, accommodation_pricing) =>
      dispatch(SuggestionActions.saveAccommodationPricingRequest(id, accommodation_pricing)),
    getClimate: params => dispatch(SuggestionActions.getClimateRequest(params)),
    setClimate: climate => dispatch(SuggestionActions.setClimate(climate)),
    saveClimate: (id, climate) => dispatch(SuggestionActions.saveClimateRequest(id, climate)),
    editClimate: (id, climate) => dispatch(SuggestionActions.updateClimate(id, climate)),
    onRemoveClimate: id => dispatch(SuggestionActions.removeClimate(id)),
    getLifecyclesRequest: params => dispatch(SuggestionActions.getLifecyclesRequest(params)),
    getLifecycleRequest: id => dispatch(SuggestionActions.getLifecycleRequest(id)),
    removeLifecycleRequest: id => dispatch(SuggestionActions.removeLifecycleRequest(id)),
    saveLifecycle: (id, data) => dispatch(SuggestionActions.saveLifecycle(id, data)),
    onShowLifecycleForm: showForm => dispatch(SuggestionActions.showLifecycleForm(showForm)),
    getCuisineSpecialitiesRequest: params =>
      dispatch(SuggestionActions.getCuisineSpecialitiesRequest(params)),
    getCuisineSpecialityRequest: id => dispatch(SuggestionActions.getCuisineSpecialityRequest(id)),
    removeCuisineSpecialityRequest: id =>
      dispatch(SuggestionActions.removeCuisineSpecialityRequest(id)),
    saveCuisineSpeciality: (id, data) =>
      dispatch(SuggestionActions.saveCuisineSpeciality(id, data)),
    onShowCuisineSpecialityForm: showForm =>
      dispatch(SuggestionActions.showCuisineSpecialityForm(showForm)),
    getRegionsForSelect: params => dispatch(regionsActions.getRegionsForSelect(params)),
    getCitiesForSelect: params => dispatch(citiesActions.getCitiesForSelect(params)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateSuggestionContainer);
