import React, { Component } from 'react';
import AddGeneralPreferenceView from './AddGeneralPreferenceView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';

class AddGeneralPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      general_preference: null,
    };
  }

  componentDidMount() {
    const { general_preference } = this.props;
    this.setState({ general_preference });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.general_preference != this.props.general_preference)
      this.setState({ general_preference: this.props.general_preference });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { general_preference } = this.state;
    saveTripData({ general_preference });
    changeCurrentStep(step);
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { general_preference } = this.state;

    return (
      <AddGeneralPreferenceView
        {...this.props}
        changeStep={this.changeStep}
        handleChange={this.handleChange}
        general_preference={general_preference}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    general_preference: state.tripReducer.tripForm.general_preference,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddGeneralPreferences);
