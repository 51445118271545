import React, { Component } from 'react';
import styled from 'styled-components';
import Tick from '../../../assets/media/icons/tick.png';
import './../styles.css';

const PlanBoxContainer = styled.div`
  border-radius: 4px;
  padding: 15px;
  text-align: -webkit-center;
  margin-bottom: 70px;
`;
const PlanBoxHeader = styled.div`
  width: 209px;
  min-height: 75px;
  object-fit: contain;
  border-radius: 5px;
  background-color: #38b6ff;
  display: table;
`;
const PlanBoxBody = styled.div`
  width: 208px;
  border-radius: 5px;
  height: 100%;
  box-shadow: 0px 17px 29px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10px 20px 10px;
  position: relative;
`;

const PlanName = styled.div`
  display: table-cell;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  vertical-align: middle;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  text-transform: uppercase;
`;

const PlanPrice = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 50.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9e9e9e;
  margin-top: 35px;
  position: relative;
`;

const ContainsContainer = styled.div`
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const ContainBox = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: 20px;
  margin-right: 20px;
`;

const Contains = styled.div`
  font-size: 11.5px;
  font-stretch: normal;
  letter-spacing: normal;
  padding-top: 5px;
  color: #38b6ff;
  margin-right: 4px;
`;

const LearnMore = styled.p`
  font-size: 11.5px;
  color: #38b6ff;
  cursor: pointer;
  position: absolute;
  bottom: 55px;
`;

const Dollar = styled.span`
  font-size: 16px;
  position: absolute;
  top: 3px;
  left: -10px;
`;

const SelectPlanButton = styled.button`
  border-radius: 5px;
  color: white;
  border: 1px solid #38b6ff;
  width: 158px;
  height: 34px;
  color: ${props => (props.selected ? 'white' : '#38b6ff')};
  background-color: ${props => (props.selected ? '#38b6ff' : 'transparent')};

  margin-top: 15px;
  cursor: pointer;
  position: absolute;
  bottom: 22px;
`;

export default class PlanBox extends Component {
  render() {
    const {
      product_id,
      selectPlan,
      changeProductsModal,
      product: { id, title, price, features, day_price },
    } = this.props;

    return (
      <PlanBoxContainer>
        <PlanBoxHeader>
          <PlanName>{title}</PlanName>
        </PlanBoxHeader>
        <PlanBoxBody>
          <PlanPrice>
            <Dollar>$</Dollar>
            {price}
          </PlanPrice>

          <ContainsContainer>
            {features.map((feature, key) => (
              <ContainBox key={key}>
                <Contains>✓</Contains>
                <Contains>{feature.name}</Contains>
              </ContainBox>
            ))}
          </ContainsContainer>
          <LearnMore onClick={changeProductsModal}>Learn More →</LearnMore>

          <SelectPlanButton onClick={() => selectPlan(id)} selected={id == product_id}>
            {id == product_id ? 'Selected' : 'Select'}
          </SelectPlanButton>
        </PlanBoxBody>
      </PlanBoxContainer>
    );
  }
}
