import React, { Component } from 'react';
import ChooseCityView from './ChooseCityView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';
import { debounce } from 'lodash';

class ChooseCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities_selected: [],
      searchString: '',
      like_to_go: false,
    };
  }

  componentDidMount() {
    const { cities_selected, like_to_go } = this.props;
    if (cities_selected) {
      this.setState({
        cities_selected,
        like_to_go,
      });
    }

    this.refreshCities();
  }

  refreshCities = () => {
    const { getDestinations } = this.props;
    const { cities_selected, searchString } = this.state;
    //const notIn = cities_selected.map(dest => dest.id);
    getDestinations(searchString);
  };

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { cities_selected, like_to_go } = this.state;
    saveTripData({ cities_selected, like_to_go });
    changeCurrentStep(step);
  };

  selectCity = city => {
    const newCity = { id: city.id, name: city.name };
    this.setState(
      prevState => ({
        cities_selected: [...prevState.cities_selected, newCity],
        searchString: '',
      }),
      () => this.refreshCities(),
    );
  };

  searchData = debounce(async search => {
    this.refreshCities();
  }, 600);

  searchCity = event => {
    const search = event.target.value;
    this.setState({ searchString: search });
    this.searchData();
  };

  removeCity = city => {
    this.setState(
      prevState => ({
        cities_selected: prevState.cities_selected.filter(selected => selected.id !== city.id),
      }),
      () => this.refreshCities(),
    );
  };

  handleChangeCheckbox = evt => {
    this.setState({ like_to_go: evt.target.checked, cities_selected: [] });
  };
  render() {
    return (
      <ChooseCityView
        {...this.props}
        changeStep={this.changeStep}
        selectCity={this.selectCity}
        state={this.state}
        searchCity={this.searchCity}
        removeCity={this.removeCity}
        handleChangeCheckbox={this.handleChangeCheckbox}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    cities_selected: state.tripReducer.tripForm.cities_selected,
    like_to_go: state.tripReducer.tripForm.like_to_go,
    citiesFiltered: state.tripReducer.tripForm.citiesFiltered,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
    getDestinations: search => dispatch(tripActions.getDestinations(search)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseCity);
