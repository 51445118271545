import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import config from '../../config/appConfig';
import FacebookButton from './FacebookButton';
export default class FBLoginButton extends Component {
  responseFacebook = response => {
    const { accessToken } = response;
    const { facebookLogin } = this.props;
    facebookLogin(response);
  };

  render() {
    return (
      <FacebookLogin
        appId={config.APP_ID_FACEBOOK}
        autoLoad={false}
        fields="email,picture,first_name,last_name,location"
        callback={this.responseFacebook}
        render={renderProps => <FacebookButton renderProps={renderProps} />}
      />
    );
  }
}
