import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  isLoggedIn: false,
  profile: null,
  token: null,
  canDegradePlan: false,
  loading: false,
};

export default {
  loginReducer: handleActions(
    {
      [ActionTypes.LOGIN_EMAIL_REQUEST](state, action) {
        return {
          ...state,
          email: action.email,
          password: action.password,
          remember: action.remember,
          loading: true,
        };
      },
      [ActionTypes.LOGIN_RESPONSE](state, action) {
        return {
          ...state,
          isLoggedIn: true,
          profile: action.response.data.user,
          token: action.response.data.token,
          loading: false,
        };
      },
      [ActionTypes.LOGIN_FAILED](state) {
        return {
          ...state,
          isLoggedIn: false,
          profile: null,
          token: null,
          loading: false,
        };
      },
      [ActionTypes.LOGOUT](state) {
        let res = {};
        if (!state.remember) {
          res = { email: '', password: '' };
        }
        return {
          ...state,
          ...res,
          isLoggedIn: false,
          profile: null,
          token: null,
        };
      },
      [ActionTypes.UPDATE_USER_DIAGNOSTIC_STEP](state, action) {
        return {
          ...state,
          profile: { ...state.profile, diagnostic_step: action.id },
        };
      },
      [ActionTypes.UPDATE_PROFILE_RESPONSE](state, action) {
        return {
          ...state,
          profile: action.response,
        };
      },
      [ActionTypes.VERIFY_DEGRADE_PLAN_SUCCESS](state, action) {
        return {
          ...state,
          canDegradePlan: action.data.canDegradePlan,
        };
      },
    },
    appState,
  ),
};
