import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  invoices: [],
  loading: false,
  subscription: { product: null },
};

export default {
  invoiceReducer: handleActions(
    {
      [ActionTypes.GET_ME_INVOICES](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.SAVE_ME_INVOICES](state, action) {
        return {
          ...state,
          invoices: action.invoices,
          subscription: action.subscription,
          loading: false,
        };
      },
    },
    appState,
  ),
};
