import React, { Component } from 'react';
import styled from 'styled-components';
import Input from '../Input';

const RecoverPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 20px auto 50px auto;
`;

const BigButton = styled.button`
  border-radius: 5px;
  background-color: #38b6ff;
  color: white;
  height: 52px;
  cursor: pointer;
  margin-top: 25px;
`;

const Title = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 50px;
  color: #353535;
`;

export default class RecoverPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '' };
  }
  sendRecoverPassword = () => {
    const { recoverPassword } = this.props;
    const { email } = this.state;
    recoverPassword(email);
  };
  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    return (
      <RecoverPasswordContainer>
        <Title>Reecover your password.</Title>
        <Input
          height={60}
          placeholder="Email"
          name="email"
          value={this.state.email}
          onChange={this.handleChange}
        />

        <BigButton onClick={this.sendRecoverPassword}> Recover Password</BigButton>
      </RecoverPasswordContainer>
    );
  }
}
