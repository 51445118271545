import welcome from 'assets/media/images/welcome.jpg';
import howThisWork from 'assets/media/images/howThisWork.jpg';

export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        title: 'Welcome to Authentic Traveling!',
        image: welcome,
        body:
          "<p>You've taken the first step to a happier, healthier, and more fulfilling future. We're so excited to help you on your path. <br/><br/> Before we can begin, however, we first need to determine where you're starting from. <br/><br/> All you need is about 25 minutes to answer some questions. </p>",
      };
    case 2:
      return {
        title: 'How This Works',
        image: howThisWork,
        body:
          "<p>This assessment is divided into three parts, each measuring a different aspect of your life. Taken as a whole, they provide a clearer picture of where you currently are—and where you'll want to go—on your upcoming transformational journey. <br/><br/> <b style='color: black'>Part 1: Overall Well-Being </b> - Find out in what areas of life you are thriving and where there might be opportunities for growth. <br/><br/><b style='color: black'>Part 2: Personal Strengths</b> - Uncover the Core Strengths you can use to transform you life and achieve you dreams.  <br/><br/><b style='color: black'>Part 3: Your Travel Personality:</b> - Identify the unique characteristics and qualities that impact how you travel and determine what it will take to make your next trip an unforgettable journey.</p>",
      };
  }
};
