// Component that render a dropdown button
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import upArrow from 'assets/media/icons/up-arrow.png';
import downArrow from 'assets/media/icons/down-arrow.png';

const DropdownButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
`;

const SelectedItem = styled.div`
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
`;

const MenuItems = styled.div`
  display: ${props => (props.isDropdownOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  width: 160px;
  height: 40px;
  top: 54px;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: #fff;
  box-shadow: 0 2px 3px 0 rgba(39, 39, 39, 0.08), 0 1px 1px 0 rgba(39, 39, 39, 0.23);
`;

const MenuItem = styled.div`
  width: 100%;
  height: 38px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${props =>
    props.selected ? props.theme.theme_secondary_light : props.theme.theme_text_light};
`;

const Icon = styled.img`
  width: 11px;
  height: 5px;
  margin-left: 10px;
`;

const Avatar = styled.img`
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
`;

const Label = styled.div`
  margin-left: 10px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.span`
  text-transform: uppercase;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.96px;
  text-align: left;
  color: #464646;
`;

const Subtitle = styled.span`
  display: block;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: #999999;
`;

const Item = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.96px;
  text-align: left;
  color: #464646;
`;

const Dropdown = props => {
  const {
    onClickDropdownButton,
    isDropdownOpen,
    menuItems,
    capLabel,
    boldItems,
    dropdownId,
    title,
    subtitle,
    avatar,
  } = props;

  return (
    <DropdownButton id={dropdownId}>
      <SelectedItem onClick={onClickDropdownButton}>
        <Avatar src={avatar} />
        <Label>
          <Title bold={true}>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Label>
        <Icon src={isDropdownOpen ? downArrow : downArrow} />
      </SelectedItem>
      <MenuItems isDropdownOpen={isDropdownOpen}>
        {menuItems.map(item => (
          <MenuItem onClick={item.onClick} key={item.id}>
            <Item bold={boldItems}>{item.label}</Item>
          </MenuItem>
        ))}
      </MenuItems>
    </DropdownButton>
  );
};

Dropdown.propTypes = {
  onClickDropdownButton: PropTypes.func.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
  ).isRequired,
  capLabel: PropTypes.bool,
  boldItems: PropTypes.bool,
  dropdownId: PropTypes.string,
};

Dropdown.defaultProps = {
  capLabel: false,
  boldItems: true,
  dropdownId: '',
  title: 'Dropdown',
  subtitle: '',
};

export default Dropdown;
