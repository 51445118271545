import React, { Component } from 'react';
import IsVeganView from './IsVeganView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';

class IsVegan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_vegan: null,
    };
  }

  componentDidMount() {
    const { is_vegan } = this.props;
    this.setState({ is_vegan });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.is_vegan != this.props.is_vegan) this.setState({ is_vegan: this.props.is_vegan });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { is_vegan } = this.state;
    saveTripData({ is_vegan });
    changeCurrentStep(step);
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { is_vegan } = this.state;

    return (
      <IsVeganView
        {...this.props}
        changeStep={this.changeStep}
        handleChange={this.handleChange}
        is_vegan={is_vegan}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    is_vegan: state.tripReducer.tripForm.is_vegan,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IsVegan);
