import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from '../../../assets/media/images/Authentic-Traveling-Logo.png';
import { Link } from 'react-router-dom';

import './../styles.css';
const Header = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 90px;
  display: flex;
  padding-left: 20px;
  padding-right: 40px;
`;
const AppLogo = styled.div`
  width: 25%;
  @media (max-width: 1000px) {
    width: 50%;
  }
`;
const GetStarted = styled.div`
  width: 25%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-color: #9e9e9e;
  @media (max-width: 1000px) {
    width: 50%;
  }
`;
const RegistText = styled.p`
  color: #9e9e9e;
`;

const RegisterSteps = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    display: none;
  }
`;
const LinkText = styled.span`
  color: #38b6ff;
`;
export default class RegisterHeader extends Component {
  render() {
    const { step } = this.props;
    return (
      <Header>
        <AppLogo>
          <Link to={'/'}>
            <img src={Logo} width="250" />
          </Link>
        </AppLogo>
        <GetStarted>
          <RegistText>
            Already have an account?
            <span>
              <Link to={'/login'}>
                <LinkText> Log in now!→ </LinkText>
              </Link>
            </span>
          </RegistText>
        </GetStarted>
        <RegisterSteps>
          <ul className="progressbar">
            <li className={step >= 1 ? 'active' : ''}>Sign Up</li>
            <li className={step >= 2 ? 'active' : ''}>Choose a Package</li>
            <li className={step >= 3 ? 'active' : ''}>Pay</li>
          </ul>
        </RegisterSteps>
      </Header>
    );
  }
}
