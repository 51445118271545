import React from 'react';
import styled from 'styled-components';
import WellBeingTest from '../../components/Diagnostics/Steps/WellBeingTestContainer';
import StrengthsTest from '../../components/Diagnostics/Steps/StrengthsTestContainer';
import TravelPersonalityTest from '../../components/Diagnostics/Steps/TravelPersonalityTestContainer';
import ExplanationConcepts from '../../components/ExplanationConcepts/Steps/ExplanationConceptsContainer';
import StepIndicator from '../../components/Diagnostics/Steps/StepIndicatorContainer';
import DiagnosticFinished from '../../components/Diagnostics/Steps/DiagnosticFinishedContainer';

import PlanMeeting from '../../components/PlanMeeting/Steps/PlanMeetingContainer';
import { Redirect } from 'react-router-dom';

const DiagnosticsDiv = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-position-x: center;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-blend-mode: multiply;
  text-align: left;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 50px;
  overflow-x: auto;
  z-index: 1050;
`;

const DiagnosticsView = props => {
  const { step } = props;
  return (
    <DiagnosticsDiv>
      {step == 1 && <ExplanationConcepts />}

      {step == 2 && <StepIndicator step={step} />}
      {step == 3 && <WellBeingTest />}

      {step == 4 && <StepIndicator step={step} />}
      {step == 5 && <StrengthsTest />}

      {step == 6 && <StepIndicator step={step} />}
      {step == 7 && <TravelPersonalityTest />}
      {step == 8 && <DiagnosticFinished />}

      {/* {step == 8 && <DiagnosticResult />} */}
      {step == 9 && <PlanMeeting />}
      {step == 10 && (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )}
    </DiagnosticsDiv>
  );
};

export default DiagnosticsView;
