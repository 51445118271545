import React, { Component } from 'react';
import PurchasePreTripCoursesView from './PurchasePreTripCoursesView';
import { connect } from 'react-redux';
import * as preTripCourseActions from '../../actions/preTripCourseActions';

class PreTripCoursesContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10 };

  componentDidMount() {
    const { getPurchasedPreTripCourses } = this.props;
    const { currentPage, elementsPerPage } = this.state;
    getPurchasedPreTripCourses(currentPage, elementsPerPage);
  }

  changePage = currentPage => {
    const { getPurchasedPreTripCourses } = this.props;
    const { elementsPerPage } = this.state;
    getPurchasedPreTripCourses(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  render() {
    const { currentPage, elementsPerPage } = this.state;

    return (
      <PurchasePreTripCoursesView
        {...this.props}
        changePage={this.changePage}
        currentPage={currentPage}
        elementsPerPage={elementsPerPage}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    purchasedPreTripCourses: state.preTripCourseReducer.purchasedPreTripCourses,
    purchasedPreTripCoursesCount: state.preTripCourseReducer.purchasedPreTripCoursesCount,
    loading: state.preTripCourseReducer.loading,
    role: state.loginReducer.profile.role_code,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getPurchasedPreTripCourses: (currentPage, elementsPerPage) =>
      dispatch(preTripCourseActions.purchasedPreTripCourses(currentPage, elementsPerPage)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreTripCoursesContainer);
