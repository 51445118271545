import React from 'react';
import styled from 'styled-components';
import logo from '../../../assets/media/images/google-logo.png';

const GoogleButton = styled.a`
  border-radius: 4px;
  width: 50%;
  outline: none;
  *zoom: 1;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  vertical-align: middle;
  border: 1px solid;
  -webkit-transition: all 0.2s ease;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.01);
  font-size: 10px;
  display: flex;
  color: #b22222;
  margin-right: 20px;
  border-color: #b22222;
  cursor: pointer;
  &:hover {
    background: #b22222;
    color: #fff;
    border-color: #b22222;
  }
`;

const TextButton = styled.p`
  margin: auto;
`;

const GoogleLogo = styled.img`
  margin: 13px 7px 13px 13px;
  height: 17px;
`;

export default props => (
  <GoogleButton onClick={props.renderProps.onClick}>
    <GoogleLogo src={logo} />
    <TextButton>LOGIN WITH GOOGLE</TextButton>
  </GoogleButton>
);
