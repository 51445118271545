import { put, call } from 'redux-saga/effects';
import * as courseActions from '../actions/courseActions';
import * as appActions from '../actions/app';
import { goBack } from 'modules/history';
import { replace } from 'modules/history';

export function* createCourseRequest(api, action) {
  const response = yield call(api.createCourseRequest, action.data);

  if (response.status == 200) {
    yield put(courseActions.createCourseSuccess(response.data));
    yield put(appActions.showAlert('Courses activated.', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getCourseRequest(api, action) {
  const response = yield call(api.getCourseRequest, action.id);
  if (response.status == 200) {
    yield put(courseActions.getCourseSuccess(response.data));
  }
}

export function* updateCourseRequest(api, action) {
  const response = yield call(api.updateCourseRequest, action.id, action.data);
  if (response.status == 200) {
    yield goBack();
    yield put(appActions.showAlert('Authentic travel  plan updated.', { variant: 'success' }));
  }
}

export function* getCustomerCoursesRequest(api) {
  const response = yield call(api.getCustomerCoursesRequest);
  if (response.status == 200) {
    yield put(courseActions.getCustomerCoursesSuccess(response.data));
  }
}

export function* updateCourseStatusRequest(api, action) {
  const response = yield call(api.updateCourseStatusRequest, action.typeCourse);
  if (response.status == 200) {
    yield put(appActions.showAlert('Course completed!', { variant: 'success' }));

    yield replace(`/courses`);
  }
}
