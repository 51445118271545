import React from 'react';
import styled from 'styled-components';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import Input from '../../components/Input';
const PayemntMethodForm = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-position-x: center;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-blend-mode: multiply;
  text-align: left;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 50px;
  overflow-x: scroll;
  z-index: 1050;
`;

const PaymentMethodContainer = styled.div`
  background-color: white;
  width: 930px;
  margin: 30px auto;
  border-radius: 10px;
  @media (max-width: 1400px) {
    width: 700px;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const FormTitle = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 16px;
  color: #3e3e3e;
  text-align: center;
  padding-top: 35px;
`;

const CardInfoSecondary = styled.div`
  display: flex;
`;

const LeftElement = styled.div`
  width: 50%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
`;

const RightElement = styled.div`
  width: 50%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

const CardInformation = styled.div`
  padding: 60px;
`;

const Button = styled.button`
  border-radius: 5px;
  min-width: 50%;
  color: white;
  background-color: ${props => props.color};
  cursor: pointer;
  height: 50px;
  font-family: 'Josefin Sans', sans-serif;
  cursor: pointer;
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  margin-bottom: 0px;
`;

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '12px',
        color: '#bebcbc',
        letterSpacing: '0.025em',
        marginTop: '2px',
        fontFamily: 'Josefin Sans, sans-serif',
        '::placeholder': {
          color: '#bebcbc',
          fontFamily: 'Josefin Sans, sans-serif',
          marginTop: '2px',
        },
        height: '42px',
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};
const PaymentForm = props => {
  const { getCardToken, addCardModal } = props;
  return (
    <PayemntMethodForm>
      <PaymentMethodContainer>
        <FormTitle>ADD PAYMENT METHOD</FormTitle>
        <CardInformation>
          <Label>Card Number</Label>

          <CardNumberElement
            placeholder="Card Number* ex: 1234 5678 9012 3546"
            {...createOptions()}
          />
          <CardInfoSecondary>
            <LeftElement>
              <Label>Expires On</Label>
              <CardExpiryElement placeholder="Exp*" {...createOptions()} />
            </LeftElement>
            <RightElement>
              <Label>Security Code</Label>
              <CardCvcElement placeholder="CCV*" {...createOptions()} />
            </RightElement>
          </CardInfoSecondary>
          <CardInfoSecondary>
            <LeftElement>
              <Button color={'#38b6ff'} onClick={getCardToken}>
                Add payment
              </Button>
            </LeftElement>
            <RightElement>
              <Button color={'#F27076'} onClick={addCardModal}>
                Cancel
              </Button>
            </RightElement>
          </CardInfoSecondary>
        </CardInformation>
      </PaymentMethodContainer>
    </PayemntMethodForm>
  );
};

export default PaymentForm;
