import React, { Component } from 'react';
import StepThreeView from './StepThreeView';
import { connect } from 'react-redux';
import * as registerActions from '../../../actions/registerActions';
import { injectStripe } from 'react-stripe-elements';

class StepThreeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeStep = async step => {
    const { registerThreeStep, changeCurrentStep, registerNewUser, newUser } = this.props;
    if (step == 4) {
      const cardToken = await this.getCardToken();
      if (cardToken.hasOwnProperty('token')) {
        registerThreeStep(cardToken);
        registerNewUser({ ...newUser, ...cardToken });
      } else {
        alert(cardToken.error.message);
      }
    } else {
      changeCurrentStep(step);
    }
  };

  getCardToken = () => {
    return this.props.stripe
      .createToken({ name: 'Card', type: 'card' })
      .then(cardToken => cardToken)
      .catch(err => err);
  };

  render() {
    return (
      <StepThreeView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        stripe={this.props.stripe}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    newUser: state.registerReducer.newUser,
    loading: state.registerReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    registerThreeStep: newUser => dispatch(registerActions.registerStep(newUser)),
    changeCurrentStep: step => dispatch(registerActions.changeCurrentStep(step)),
    registerNewUser: newUser => dispatch(registerActions.registerNewUser(newUser)),
  };
}

export default injectStripe(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StepThreeContainer),
);
