import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import { SIGHTSEEING } from 'constants/index';
import RadioInput from 'components/RadioInput';

const SelectSightseeingBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SelectSightseeingPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`;

class SelectSightseeingView extends Component {
  render() {
    const { step, state, changeStep, handleChange, sightseeingMaping } = this.props;
    return (
      <SelectSightseeingBox>
        <SelectSightseeingPrimaryBox>
          <PrimaryText text="What types of sightseeing interest you? Please select all that apply and rate your interest level low, medium, or high." />
          <Content>
            {SIGHTSEEING.map(sightseeing => (
              <RadioInput
                style={{ marginBottom: 50 }}
                label={sightseeing}
                name={sightseeingMaping[sightseeing]}
                options={[
                  { numericValue: 0, text: 'Nothing' },
                  { numericValue: 1, text: 'Low' },
                  { numericValue: 2, text: 'Medium' },
                  { numericValue: 3, text: 'High' },
                ]}
                handleChange={handleChange}
                currentValue={state[sightseeingMaping[sightseeing]]}
                verticalAlign
              />
            ))}
          </Content>
          <StepButtons
            backStep={() => changeStep(step - 1)}
            nextStep={() => changeStep(step + 1)}
          />
        </SelectSightseeingPrimaryBox>
      </SelectSightseeingBox>
    );
  }
}

export default SelectSightseeingView;
