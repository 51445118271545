import React, { Component } from 'react';
import moment from 'moment';
import CreateItineraryView from './CreateItineraryView';
import { connect } from 'react-redux';
import * as itineraryActions from '../../actions/itineraryActions';
import * as tripActions from '../../actions/tripActions';
import { debounce } from 'lodash';

class CreateItineraryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityString: '',
      activityInput: false,
      restaurantString: '',
      restaurantInput: false,
      accommodationString: '',
      accommodationInput: false,
      stopString: '',
      stopModal: false,
      start_date_activity: null,
      showList: true,
      moreDaysAlert: false,
      alertGenerateItinerary: false,
      cityInput: false,
      generateActivitiesModal: false,
    };
  }

  componentDidMount() {
    const {
      match: { params },
      getItinerary,
    } = this.props;
    getItinerary(params.id);
  }

  componentDidUpdate() {
    const {
      getActivitiesByCity,
      getRestaurantsByCity,
      getAccommodationsByCity,
      activitiesByCity,
      stops,
    } = this.props;
    if (!activitiesByCity && stops.length > 0) {
      getActivitiesByCity(stops[0].city.id);
      getRestaurantsByCity(stops[0].city.id);
      getAccommodationsByCity(stops[0].city.id);
    }
  }

  isValidForm = () => {
    const { start_date, end_date, title, type } = this.props;
    return start_date && end_date && title && type;
  };

  selectDestination = (stop, stopSelected) => {
    const {
      getActivitiesByCity,
      getRestaurantsByCity,
      getAccommodationsByCity,
      selectStop,
    } = this.props;
    getActivitiesByCity(stop.city.id);
    getRestaurantsByCity(stop.city.id);
    getAccommodationsByCity(stop.city.id);

    selectStop(stop, stopSelected);
  };

  isDaySelected = day => {
    const { daysSelected, stopSelectedId } = this.props;
    const currentDay = daysSelected.filter(day => day.stopId == stopSelectedId);
    if (!currentDay[0] || !day) return false;

    return currentDay[0].dayId == day.id;
  };

  addActivity = () => {
    const { onAddActivity } = this.props;
    let { activitySelected: activity, start_date_activity } = this.state;
    //The dates and times of the activities are shown and saved in utc (0), in this way it is achieved that the same hours are shown from anywhere in the world regardless of the time slot
    let start_date = moment(start_date_activity).utcOffset('+00:00', true);
    let newActivity = { ...activity, start_date, type: 'activity' };

    onAddActivity(newActivity);
    this.setState({ activityInput: false, activityString: '', activitySelected: null });
  };

  addRestaurant = () => {
    const { onAddActivity } = this.props;
    let { restaurantSelected: restaurant, start_date_activity } = this.state;
    //The dates and times of the activities are shown and saved in utc (0), in this way it is achieved that the same hours are shown from anywhere in the world regardless of the time slot
    let start_date = moment(start_date_activity).utcOffset('+00:00', true);
    let newActivity = { ...restaurant, start_date, type: 'restaurant' };

    onAddActivity(newActivity);
    this.setState({ restaurantInput: false, restaurantString: '', restaurantSelected: null });
  };

  addAccommodation = () => {
    const { onAddActivity } = this.props;
    let { accommodationSelected: accommodation, start_date_activity } = this.state;
    //The dates and times of the activities are shown and saved in utc (0), in this way it is achieved that the same hours are shown from anywhere in the world regardless of the time slot
    let start_date = moment(start_date_activity).utcOffset('+00:00', true);
    let newActivity = { ...accommodation, start_date, type: 'accommodation' };

    onAddActivity(newActivity);
    this.setState({
      accommodationInput: false,
      accommodationString: '',
      accommodationSelected: null,
    });
  };

  selectActivity = activitySelected => {
    this.setState({ activitySelected, activityString: activitySelected.name });
  };

  selectRestaurant = restaurantSelected => {
    this.setState({ restaurantSelected, restaurantString: restaurantSelected.name });
  };

  selectAccommodation = accommodationSelected => {
    this.setState({ accommodationSelected, accommodationString: accommodationSelected.name });
  };
  updateShowList = status => {
    this.setState({ showList: status });
  };

  addStop = stop => {
    const {
      addStop,
      getActivitiesByCity,
      getRestaurantsByCity,
      getAccommodationsByCity,
    } = this.props;
    getActivitiesByCity(stop.city.id);
    getRestaurantsByCity(stop.city.id);
    getAccommodationsByCity(stop.city.id);

    addStop(stop);
    this.setState({ stopModal: false, stopString: '' });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  addDay = () => {
    const { onAddDay, start_date, end_date, daysWithActivities } = this.props;
    const newDay = moment(start_date).add(daysWithActivities.length, 'days');
    const lastDay = moment(end_date);
    if (newDay > lastDay) {
      this.toggleModal('moreDaysAlert');
    } else {
      onAddDay();
    }
  };

  toggleModal = modalName => {
    this.setState({
      [modalName]: !this.state[modalName],
    });
  };

  toggleAlertGenerateItinerary = () => {
    this.setState({
      alertGenerateItinerary: !this.state.alertGenerateItinerary,
    });
  };

  refreshCities = () => {
    const { getDestinations } = this.props;
    const { searchString } = this.state;
    getDestinations(searchString);
  };

  searchData = debounce(async search => {
    this.refreshCities();
  }, 600);

  searchCity = event => {
    const search = event.target.value;
    this.setState({ stopString: search });
    this.searchData();
  };

  changeItineraryDate = (value, date) => {
    const newDate = { [value]: date };
    const { saveItineraryData } = this.props;
    saveItineraryData(newDate);
  };

  changeActivityDate = (value, date) => {
    this.setState({ [value]: date });
  };

  addDayAndUpdateDate = () => {
    const { onAddDay, daysWithActivities, start_date, saveItineraryData } = this.props;
    onAddDay();
    this.toggleModal('moreDaysAlert');
    saveItineraryData({
      end_date: moment(start_date)
        .add(daysWithActivities.length, 'days')
        .toDate(),
    });
  };

  generateDayItinerary = () => {
    const { generateItineraryRequest } = this.props;
    let { fisrt_activity_time, lunch_time, back_to_hotel_time } = this.state;
    fisrt_activity_time = moment(fisrt_activity_time).utcOffset('+00:00', true);
    lunch_time = moment(lunch_time).utcOffset('+00:00', true);
    back_to_hotel_time = moment(back_to_hotel_time).utcOffset('+00:00', true);

    generateItineraryRequest(fisrt_activity_time, lunch_time, back_to_hotel_time);

    this.toggleModal('generateActivitiesModal');
  };
  render() {
    const {
      stopSelected,
      daySelected,
      activityString,
      activityInput,
      stopString,
      stopModal,
      start_date_activity,
      showList,
      activitySelected,
      moreDaysAlert,
      alertGenerateItinerary,
      cityInput,
      restaurantString,
      restaurantInput,
      restaurantSelected,
      accommodationString,
      accommodationInput,
      accommodationSelected,
      generateActivitiesModal,
      fisrt_activity_time,
      lunch_time,
      back_to_hotel_time,
    } = this.state;
    return (
      <CreateItineraryView
        {...this.props}
        selectDestination={this.selectDestination}
        stopSelected={stopSelected}
        daySelected={daySelected}
        //activities
        activityString={activityString}
        activityInput={activityInput}
        activitySelected={activitySelected}
        selectActivity={this.selectActivity}
        addActivity={this.addActivity}
        //restaurants
        restaurantString={restaurantString}
        restaurantInput={restaurantInput}
        restaurantSelected={restaurantSelected}
        selectRestaurant={this.selectRestaurant}
        addRestaurant={this.addRestaurant}
        //accommodations
        accommodationString={accommodationString}
        accommodationInput={accommodationInput}
        accommodationSelected={accommodationSelected}
        selectAccommodation={this.selectAccommodation}
        addAccommodation={this.addAccommodation}
        //
        isDaySelected={this.isDaySelected}
        handleChange={this.handleChange}
        stopString={stopString}
        stopModal={stopModal}
        addStop={this.addStop}
        changeItineraryDate={this.changeItineraryDate}
        changeActivityDate={this.changeActivityDate}
        start_date_activity={start_date_activity}
        updateShowList={this.updateShowList}
        showList={showList}
        addDay={this.addDay}
        moreDaysAlert={moreDaysAlert}
        toggleModal={this.toggleModal}
        alertGenerateItinerary={alertGenerateItinerary}
        toggleAlertGenerateItinerary={this.toggleAlertGenerateItinerary}
        cityInput={cityInput}
        searchCity={this.searchCity}
        addDayAndUpdateDate={this.addDayAndUpdateDate}
        isValidForm={this.isValidForm}
        generateActivitiesModal={generateActivitiesModal}
        fisrt_activity_time={fisrt_activity_time}
        lunch_time={lunch_time}
        back_to_hotel_time={back_to_hotel_time}
        generateDayItinerary={this.generateDayItinerary}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    activitiesByCity: state.itineraryReducer.activitiesByCity,
    restaurantsByCity: state.itineraryReducer.restaurantsByCity,
    accommodationsByCity: state.itineraryReducer.accommodationsByCity,
    itinerary: state.itineraryReducer.itinerary,
    stopSelectedId: state.itineraryReducer.stopSelectedId,
    stops: state.itineraryReducer.stops,
    days: state.itineraryReducer.days,
    start_date: state.itineraryReducer.itinerary.start_date,
    end_date: state.itineraryReducer.itinerary.end_date,
    status: state.itineraryReducer.itinerary.status,
    title: state.itineraryReducer.itinerary.title,

    type: state.itineraryReducer.itinerary.type,
    daysSelected: state.itineraryReducer.daysSelected,
    daysWithActivities: state.itineraryReducer.daysWithActivities,
    daySelectedId: state.itineraryReducer.daySelectedId,
    citiesFiltered: state.tripReducer.tripForm.citiesFiltered,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    saveItineraryData: data => dispatch(itineraryActions.saveItineraryData(data)),
    getItinerary: id => dispatch(itineraryActions.getItinerary(id, true)),
    getActivitiesByCity: city_id => dispatch(itineraryActions.getActivitiesByCity(city_id)),
    getRestaurantsByCity: city_id => dispatch(itineraryActions.getRestaurantsByCity(city_id)),
    getAccommodationsByCity: city_id => dispatch(itineraryActions.getAccommodationsByCity(city_id)),
    selectStop: (stop, stopSelected) => dispatch(itineraryActions.selectStop(stop, stopSelected)),
    onAddDay: () => dispatch(itineraryActions.addDay()),
    selectDay: day => dispatch(itineraryActions.selectDay(day)),
    onAddActivity: activity => dispatch(itineraryActions.addActivity(activity)),
    createItinerary: () => dispatch(itineraryActions.createItinerary()),
    addStop: stop => dispatch(itineraryActions.addStop(stop)),
    deleteStop: id => dispatch(itineraryActions.deleteStop(id)),
    deleteDay: id => dispatch(itineraryActions.deleteDay(id)),
    deleteActivity: id => dispatch(itineraryActions.deleteActivity(id)),
    getDestinations: search => dispatch(tripActions.getDestinations(search)),
    generateItineraryRequest: (fisrt_activity_time, lunch_time, back_to_hotel_time) =>
      dispatch(
        itineraryActions.generateItineraryRequest(
          fisrt_activity_time,
          lunch_time,
          back_to_hotel_time,
        ),
      ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateItineraryContainer);
