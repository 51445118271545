import { hot } from 'react-hot-loader/root';
import React from 'react';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled, { css, ThemeProvider } from 'styled-components';
import Intercom from 'react-intercom';
import history from 'modules/history';
import theme, { headerHeight } from 'modules/theme';
import { utils } from 'styled-minimal';
import config from 'config';

// private
import ComingSoon from 'routes/ComingSoon';
import Customers from 'routes/Customers';
import CustomerDetails from 'routes/CustomerDetails';
import Diagnostics from 'routes/Diagnostics';
import DiagnosticResults from 'routes/DiagnosticResults/DiagnosticResultsCustomerContainer';
import Settings from 'routes/ProfileSettings';
import CreateCities from 'routes/Cities/CreateCities';
import EditCities from 'routes/Cities/EditCities';
import CitiesList from 'routes/Cities/CitiesListContainer';
import RegionsList from 'routes/Regions/RegionsListContainer';
import CreateRegions from 'routes/Regions/CreateRegions';
import EditRegions from 'routes/Regions/EditRegions';

import CreateTrip from 'routes/Trip/CreateTripContainer';
import EditTrip from 'routes/Trip/EditTripContainer';
import UpdatePaymentMethod from 'routes/UpdatePaymentMethod';
import CreateActivity from 'routes/ActivityDetail/CreateActivityContainer';
import EditActivity from 'routes/ActivityDetail/EditActivityContainer';
import Activities from 'routes/Activities';
import CreateRestaurant from 'routes/RestaurantsDetail/CreateRestaurantContainer';
import EditRestaurant from 'routes/RestaurantsDetail/EditRestaurantContainer';
import Restaurants from 'routes/Restaurants';
import CreateAccommodation from 'routes/AccommodationsDetails/CreateAccommodationContainer';
import EditAccommodation from 'routes/AccommodationsDetails/EditAccommodationContainer';
import Accommodations from 'routes/Accommodations';
import TripDetail from 'routes/Trip/TripDetailContainer';
import CreateItinerary from 'routes/Itinerary/CreateItineraryContainer';
import TripCustomers from 'routes/Trip/TripCustomersContainer';
import TransactionsCustomer from 'routes/Transactions/TransactionCustomerContainer';
import InvoicesCustomer from 'routes/Invoices/InvoiceCustomerContainer';
import PreTripCourses from 'routes/PreTripCourses/PreTripCoursesContainer';
import CreatePreTripCourses from 'routes/PreTripCourses/CreatePreTripCoursesContainer';
import EditPreTripCourses from 'routes/PreTripCourses/EditPreTripCoursesContainer';
import Meetings from 'routes/Meeting/MeetingsContainer';
import PreTripCoursePreview from 'routes/PreTripCourses/PreTripCoursePreviewContainer';
import PurchasePreTripCourses from 'routes/PreTripCourses/PurchasePreTripCoursesContainer';
import AllPurchasePreTripCourses from 'routes/PreTripCourses/AllPurchasePreTripCoursesContainer';
import CustomerTrip from 'routes/Trip/CustomerTripDetailContainer';
import Journal from 'routes/Journal';
import JournalListDays from 'routes/JournalListDays';
import AuthenticPlanUpdate from 'routes/AuthenticPlan/AuthenticPlanUpdateContainer';
import GoalUpdate from 'routes/Goal/GoalUpdateContainer';
import CoursesCustomer from 'routes/Courses/CoursesCustomerContainer';
import CoursesDetail from 'routes/Courses/CoursesDetailContainer';
import TripsListAdmin from 'routes/TripsListAdmin';
import Suggestions from 'routes/Suggestions';
import CreateSuggestion from 'routes/Suggestions/Create';
import RequestsOfBoutiquePackages from 'routes/RequestsOfBoutiquePackages';

// public
import NotFound from 'routes/NotFound';
import Login from 'routes/Login';
import RecoverPassword from 'routes/RecoverPassword';
import ResetPassword from 'routes/ResetPassword';
import Register from 'routes/Register/RegisterContainer';
import RegisterSuccess from 'routes/Register/RegisterSuccessContainer';
import Welcome from 'routes/Welcome/WelcomeContainer';
import SystemAlerts from 'components/SystemAlerts';
import GlobalStyles from 'components/GlobalStyles';
import RoutePublic from 'components/RoutePublic';
import RoutePrivate from 'components/RoutePrivate';

import configApp from 'config/appConfig';
import * as startUpActions from './actions/startUpActions';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
`;

const MainPrivate = ({ isAuthenticated }) =>
  isAuthenticated &&
  css`
    padding: ${utils.px(headerHeight)} 0 0;
  `;

const Main = styled.main`
  min-height: 100vh;

  ${MainPrivate};
`;

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.startup();
  }

  render() {
    const { isLoggedIn, role_code, profile } = this.props;
    const routePublicRedirect = role_code === 'admin' ? '/customers' : '/trip-content';
    const user = {};
    if (profile) {
      user.user_id = profile.id;
      user.email = profile.email;
      user.name = `${profile.first_name} ${profile.last_name}`;
    }

    return (
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <AppWrapper logged={isLoggedIn}>
            <Helmet
              defer={false}
              htmlAttributes={{ lang: 'pt-br' }}
              encodeSpecialCharacters={true}
              defaultTitle={config.name}
              titleTemplate={`%s | ${config.name}`}
              titleAttributes={{ itemprop: 'name', lang: 'pt-br' }}
            />
            <Main isAuthenticated={isLoggedIn}>
              {role_code != 'admin' && (
                <Intercom
                  style={{ marginBottom: '60px' }}
                  appID={configApp.INTERCOM_APP_ID}
                  {...user}
                />
              )}

              <Switch>
                {/* Public links */}
                <RoutePublic
                  isAuthenticated={isLoggedIn}
                  to={routePublicRedirect}
                  path="/"
                  exact
                  component={Login}
                />
                <RoutePublic
                  isAuthenticated={isLoggedIn}
                  to={routePublicRedirect}
                  path="/login"
                  exact
                  component={Login}
                />
                <RoutePublic
                  isAuthenticated={isLoggedIn}
                  to={routePublicRedirect}
                  path="/register"
                  exact
                  component={Register}
                />
                <RoutePublic
                  isAuthenticated={isLoggedIn}
                  to={routePublicRedirect}
                  path="/recover-password"
                  exact
                  component={RecoverPassword}
                />
                <RoutePublic
                  isAuthenticated={isLoggedIn}
                  to={routePublicRedirect}
                  path="/reset-password"
                  exact
                  component={ResetPassword}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/register-success"
                  component={RegisterSuccess}
                  allow={['admin', 'customer']}
                  withoutMenu
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/welcome"
                  component={Welcome}
                  allow={['customer']}
                  withoutMenu
                />
                {/* Admin links */}
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/customers/:id"
                  component={CustomerDetails}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/customers"
                  component={Customers}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/payments"
                  component={ComingSoon}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/activities/create"
                  component={CreateActivity}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/activities/:id"
                  component={EditActivity}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/activities"
                  component={Activities}
                  allow={['admin']}
                />

                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/restaurants/create"
                  component={CreateRestaurant}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/restaurants/:id"
                  component={EditRestaurant}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/restaurants"
                  component={Restaurants}
                  allow={['admin']}
                />

                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/accommodations/create"
                  component={CreateAccommodation}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/accommodations/:id"
                  component={EditAccommodation}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/accommodations"
                  component={Accommodations}
                  allow={['admin']}
                />

                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/trip/edit/:id"
                  component={EditTrip}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/trips/create"
                  component={CreateTrip}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/itinerary/:id"
                  component={CreateItinerary}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/requests-of-boutique-packages"
                  component={RequestsOfBoutiquePackages}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/trips/:id"
                  component={TripDetail}
                  allow={['admin', 'customer']}
                />

                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/trip/create/:customer/:meeting"
                  component={CreateTrip}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/trip/create/:customer"
                  component={CreateTrip}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/pre-trip-course/:id"
                  component={EditPreTripCourses}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/pre-trip-courses/create"
                  component={CreatePreTripCourses}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/pre-trip-courses"
                  component={PreTripCourses}
                  allow={['admin', 'customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/all-purchase-pre-trip-courses"
                  component={AllPurchasePreTripCourses}
                  allow={['admin']}
                />
                {/* Suggestions links */}
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/suggestions"
                  exact
                  component={Suggestions}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/suggestions/country/:id"
                  component={CreateSuggestion}
                  exact
                  allow={['admin']}
                />
                {/* Customer links */}
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/trip-content"
                  component={TripCustomers}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/diagnostics"
                  component={Diagnostics}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/journal-step/:id/:step/:day"
                  component={Journal}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/journal/:id"
                  component={JournalListDays}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/diagnostic-results"
                  component={DiagnosticResults}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/subscriptions"
                  component={InvoicesCustomer}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/settings"
                  component={Settings}
                  allow={['customer', 'admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/cities/create/country/:country_id"
                  component={CreateCities}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/cities/create"
                  component={CreateCities}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/cities/edit/:id"
                  component={EditCities}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/cities/country/:country_id"
                  component={CitiesList}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/cities"
                  component={CitiesList}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/regions/edit/:id"
                  component={EditRegions}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/regions/create/country/:country_id"
                  component={CreateRegions}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/regions/create"
                  component={CreateRegions}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/regions/country/:country_id"
                  component={RegionsList}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/regions"
                  component={RegionsList}
                  allow={['admin']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/payment-history"
                  component={TransactionsCustomer}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/update-payment-method"
                  component={UpdatePaymentMethod}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/purchase-pre-trip-courses"
                  component={PurchasePreTripCourses}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/preview/pre-trip-courses/:id/:buyoption"
                  component={PreTripCoursePreview}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/preview/pre-trip-courses/:id/"
                  component={PreTripCoursePreview}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/schedule-calls"
                  component={Meetings}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/customer-trip/:trip_id/:itinerary_id"
                  component={CustomerTrip}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/at-plan/:id"
                  component={AuthenticPlanUpdate}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/goal/:id"
                  component={GoalUpdate}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/courses/:type/:status"
                  component={CoursesDetail}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/courses"
                  component={CoursesCustomer}
                  allow={['customer']}
                />
                <RoutePrivate
                  isAuthenticated={isLoggedIn}
                  role_code={role_code}
                  profile={profile}
                  path="/trips"
                  component={TripsListAdmin}
                  allow={['admin']}
                />
                <Route component={NotFound} />
              </Switch>
            </Main>
            <SystemAlerts />
            <GlobalStyles />
          </AppWrapper>
        </ThemeProvider>
      </Router>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isLoggedIn: state.loginReducer.isLoggedIn,
    role_code: state.loginReducer.profile ? state.loginReducer.profile.role_code : null,
    profile: state.loginReducer.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startup: () => dispatch(startUpActions.startup()),
  };
}

export default hot(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
