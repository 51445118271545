import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = { newUser: null, step: 1, loading: false };

export default {
  registerReducer: handleActions(
    {
      [ActionTypes.REGISTER_REQUEST](state, action) {
        return {
          ...state,
          newUser: { ...state.newUser, ...action.newProperties },
          loading: true,
        };
      },
      [ActionTypes.REGISTER_ADD_PROPERTIES](state, action) {
        return {
          ...state,
          newUser: { ...state.newUser, ...action.newProperties },
        };
      },
      [ActionTypes.REGISTER_FINISHED](state) {
        return {
          ...state,
          newUser: null,
          step: 1,
          loading: false,
        };
      },
      [ActionTypes.REGISTER_CHANGE_CURRENT_STEP](state, action) {
        return {
          ...state,
          step: action.step,
        };
      },
    },
    appState,
  ),
};
