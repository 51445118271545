import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-position-x: center;
  background-size: cover;
  background-blend-mode: multiply;
  text-align: left;
  height: -webkit-fill-available;
  position: fixed;
  right: 0;
  top: 0;
  padding-top: 50px;
  overflow-x: scroll;
  z-index: 1050;
`;

const ModalContainer = styled.div`
  background-color: white;
  width: ${props => props.width || '70%'};
  margin: 30px auto;
  border-radius: 10px;
  @media (max-width: 1400px) {
    width: ${props => props.widthMedium || '80%'};
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const Header = styled.p`
  text-align: center;
  color: #9e9e9e;
  font-size: 20px;
  margin: 40px;
`;

const Box = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  padding: 20px;
  text-align: center;
`;

const CloseIcon = styled.div`
  position: relative;
  top: 15px;
  right: 15px;
  float: right;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Josefin Sans', sans-serif;
`;

const Button = styled.button`
  border-radius: 5px;
  color: white;
  background-color: ${props => (props.success ? '#38b6ff' : 'inherit')};
  border: 1px solid #38b6ff;
  width: 150px;
  height: 50px;
  color: ${props => (props.success ? 'white' : '#38b6ff')};
  margin: 30px 5px;
  cursor: pointer;
  ${props => props.disabled && 'opacity: 0.7;'}
`;

const Modal = props => {
  if (!props.isVisible) {
    return null;
  }

  return (
    <Container>
      <ModalContainer width={props.width} widthMedium={props.widthMedium}>
        <Box>
          {props.onCancel && (
            <CloseIcon onClick={props.onCancel}>
              <i style={{ color: '#696969' }} className="fas fa-times-circle" />
            </CloseIcon>
          )}
          {props.title && <Header>{props.title}</Header>}
          <Body>{props.children}</Body>
          {!props.hiddeButtons && (
            <ButtonGroup>
              {props.onCancel && (
                <Button onClick={props.onCancel}>{props.cancelText || 'Cancel'}</Button>
              )}
              {props.onSuccess && (
                <Button onClick={props.onSuccess} success disabled={props.disabled}>
                  {props.successText || 'Success'}
                </Button>
              )}
            </ButtonGroup>
          )}
        </Box>
      </ModalContainer>
    </Container>
  );
};

export default Modal;
