import React from 'react';
import styled from 'styled-components';
import defaultAvatar from 'assets/media/images/avatar.png';
import Tabs from 'components/Tabs';
import TripList from 'components/TripList';
import TransactionList from 'components/TransactionList';
import DiagnosticTestSummary from 'components/DiagnosticTestSummary';
import InvoicesList from 'components/InvoicesList';
import CoursesList from 'routes/Courses/CoursesListContainer.jsx';
import MeetingsList from 'components/MeetingsList';

const Container = styled.div`
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const CustomerDetailsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  padding: 0px 20px 0px 20px;
`;

const SectionDetails = styled.div`
  position: relative;
  width: 20%;
  height: 100%;
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: left;
  ${props => props.hasPadding && 'padding: 25px 5px 25px 5px;'};
`;

const Avatar = styled.img``;

const Primary = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;
const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 10px;
`;

const CustomerDetails = props => {
  const { customer, tabs, activeTab, goTo, updateMeeting } = props;

  if (!customer) {
    return null;
  }

  const {
    profile,
    trips,
    transactions,
    testStrengths,
    testTravelPersonalities,
    testWellBeings,
    invoices,
    subscription,
    courses,
  } = customer;

  return (
    <Container>
      <CustomerDetailsContainer>
        <SectionDetails>
          <Avatar src={profile.avatar || defaultAvatar} />
        </SectionDetails>
        <SectionDetails hasPadding>
          <Secondary>Full Name</Secondary>
          <Primary>{profile.first_name}</Primary>
          <Primary>{profile.last_name}</Primary>
        </SectionDetails>
        <SectionDetails hasPadding>
          <Secondary>Address</Secondary>
          <Primary>{profile.city}</Primary>
          <Primary>{profile.country}</Primary>
        </SectionDetails>
        <SectionDetails hasPadding>
          <Secondary>Contact</Secondary>
          <Primary>{profile.phone}</Primary>
          <Primary>{profile.email}</Primary>
        </SectionDetails>
        <SectionDetails hasPadding>
          <Secondary>Status</Secondary>
          <Primary>{subscription.status}</Primary>
        </SectionDetails>
      </CustomerDetailsContainer>
      <Tabs tabs={tabs} active={activeTab} />
      {activeTab === 'Trips' && (
        <TripList isAdmin={true} goTo={goTo} trips={trips} customerId={profile.id} />
      )}
      {activeTab === 'Payments' && <TransactionList transactions={transactions} />}
      {activeTab === 'Diagnostic test' && (
        <DiagnosticTestSummary
          strengths={testStrengths}
          travelPersonalities={testTravelPersonalities}
          wellBeings={testWellBeings}
        />
      )}
      {activeTab === 'Subscriptions' && (
        <InvoicesList invoices={invoices} subscription={subscription} />
      )}
      {activeTab === 'Discussions' && (
        <MeetingsList
          isAdmin
          meetings={profile.meetings}
          user_id={profile.id}
          updateMeeting={updateMeeting}
        />
      )}
      {activeTab === 'Courses' && <CoursesList />}
    </Container>
  );
};

export default CustomerDetails;
