import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StepContainer = styled.div`
  background-color: ${props => (props.color ? props.color : 'white')};
  width: ${props => props.width || '70%'};
  margin: 0 auto 30px auto;
  border-radius: 10px;
  @media (max-width: 1150px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StepContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: table;
`;
const StepSubtitle = styled.p`
  text-align: center;
  color: #808080;
  font-size: 30px;
  margin: 40px 80px 0 80px;
  height: 70px;
  @media (max-width: 1000px) {
    height: auto;
    margin-bottom: 10px;
  }
`;

const StepTitle = styled.p`
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
`;

const ImageContainer = styled.div`
  text-align: center;
  margin: 50px;
`;
const CloseIcon = styled.div`
  position: relative;
  top: 15px;
  right: 15px;
  float: right;
`;

const Icon = styled.i`
  color: #696969;
  font-size: 20px;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
`;

export default props => (
  <StepContainer style={props.style} color={props.color} width={props.width}>
    <StepContent>
      {props.closeIcon && (
        <Link to={'/'}>
          <CloseIcon onClick={props.closeAction}>
            <Icon className="fas fa-times-circle" />
          </CloseIcon>
        </Link>
      )}
      {props.logoHeader && (
        <ImageContainer>
          <img width="250" src={props.logoHeader} />
        </ImageContainer>
      )}
      {props.title && <StepTitle>{props.title}</StepTitle>}

      {props.subtitle ? (
        <StepSubtitle>{props.subtitle}</StepSubtitle>
      ) : (
          props.needSpace && <StepSubtitle>&nbsp;&nbsp;</StepSubtitle>
        )}

      {props.children && <>{props.children}</>}
    </StepContent>
  </StepContainer>
);
