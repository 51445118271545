import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import PrimaryText from '../PrimaryText';
import SelectedButton from 'components/SelectedButton';
import RangeInput from 'components/RangeInput';

const DailyBudgetBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const DailyBudgetPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const CheckboxBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  justify-content: center;
  align-items: center;
  height: 90%;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
class DailyBudgetView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      state,
      step,
      changeStep,
      handleChange,
      know_dates,
      setDailyBudget,
      toggleBar,
    } = this.props;
    const { price_total_budget, show_bar } = state;

    return (
      <>
        <DailyBudgetBox>
          <DailyBudgetPrimaryBox>
            <PrimaryText text="Excluding airfare—the cost of which can vary greatly—what is your total budget for this journey" />
            <Content>
              <CheckboxBox>
                <SelectedButton
                  text={'Less than $1000'}
                  active={price_total_budget == 'lessThan1000'}
                  onClick={() => {
                    toggleBar(false);
                    setDailyBudget('lessThan1000');
                  }}
                />
                <SelectedButton
                  text={'Between $1000 & $10000'}
                  active={show_bar}
                  onClick={() => {
                    toggleBar(true);
                    setDailyBudget(5500);
                  }}
                />
                <SelectedButton
                  text={'More than $10000'}
                  active={price_total_budget == 'moreThan10000'}
                  onClick={() => {
                    toggleBar(false);
                    setDailyBudget('moreThan10000');
                  }}
                />
                <SelectedButton
                  text="I'm not sure."
                  active={price_total_budget == null}
                  onClick={() => toggleBar(false)}
                />
              </CheckboxBox>
              {show_bar && (
                <CheckboxBox>
                  <InputBox>
                    <RangeInput
                      name="price_total_budget"
                      handleChange={handleChange}
                      min="1000"
                      max="10000"
                      value={price_total_budget}
                      step="500"
                      minQuantityLabel="$1000"
                      maxQuantityLabel="$10000"
                      mediumQuantityValue={'$5500'}
                      isPrice
                    />
                  </InputBox>
                </CheckboxBox>
              )}
            </Content>
            <StepButtons
              backStep={() => changeStep(know_dates == 1 ? step - 3 : step - 1)}
              nextStep={() => changeStep(step + 1)}
            />
          </DailyBudgetPrimaryBox>
        </DailyBudgetBox>
      </>
    );
  }
}

export default DailyBudgetView;
