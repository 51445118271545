import React, { Component } from 'react';
import CitiesForm from './CitiesForm';
import { connect } from 'react-redux';
import * as citiesActions from '../../actions/citiesActions';
import * as regionsActions from '../../actions/regionsActions';

class CreateCities extends Component {
  componentDidMount() {
    const { getRegionsForSelect } = this.props;
    getRegionsForSelect();
  }

  createCity = city => {
    const { createCities } = this.props;
    createCities(city);
  };

  render() {
    const { name, regionsForSelect } = this.props;
    return <CitiesForm name={name} regionsForSelect={regionsForSelect} submit={this.createCity} />;
  }
}

function mapStateToProps(state) {
  return {
    name: state.citiesReducer.name,
    regionsForSelect: state.regionsReducer.regionsForSelect,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createCities: cities => dispatch(citiesActions.createCities(cities)),
    getRegionsForSelect: () => dispatch(regionsActions.getRegionsForSelect()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateCities);
