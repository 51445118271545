import React from 'react';
import styled from 'styled-components';

const PrimaryText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 40px;
  color: #464646;
  text-align: center;
`;

export default props => <PrimaryText>{props.text}</PrimaryText>;
