import React from 'react';
import styled from 'styled-components';

const SecondaryText = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #464646;
  margin-bottom: 10px;
`;
export default props => <SecondaryText>{props.text}</SecondaryText>;
