import { ActionTypes } from 'constants/index';

export function saveItineraryData(data) {
  return {
    type: ActionTypes.SAVE_ITINERARY_DATA,
    data,
  };
}

export function getActivitiesByCity(city_id) {
  return {
    type: ActionTypes.GET_ACTIVITITIES_BY_CITIES,
    city_id,
  };
}

export function saveActivitiesByCity(data) {
  return {
    type: ActionTypes.SAVE_ACTIVITITIES_BY_CITIES,
    data,
  };
}

export function getRestaurantsByCity(city_id) {
  return {
    type: ActionTypes.GET_RESTAURANTS_BY_CITIES,
    city_id,
  };
}

export function saveRestaurantsByCity(data) {
  return {
    type: ActionTypes.SAVE_RESTAURANTS_BY_CITIES,
    data,
  };
}

export function getAccommodationsByCity(city_id) {
  return {
    type: ActionTypes.GET_ACCOMMODATIONS_BY_CITIES,
    city_id,
  };
}

export function saveAccommodationsByCity(data) {
  return {
    type: ActionTypes.SAVE_ACCOMMODATIONS_BY_CITIES,
    data,
  };
}

export function selectStop(stopSelected, stopSelectedId) {
  return {
    type: ActionTypes.SELECT_STOP,
    stopSelected,
    stopSelectedId,
  };
}

export function addDay() {
  return {
    type: ActionTypes.ADD_DAY,
  };
}

export function selectDay(day) {
  return {
    type: ActionTypes.SELECT_DAY,
    day,
  };
}

export function addActivity(activity) {
  return {
    type: ActionTypes.ADD_ACTIVITY,
    activity,
  };
}

export function createItinerary() {
  return {
    type: ActionTypes.SAVE_ITINERARY,
  };
}

export function addStop(stop) {
  return {
    type: ActionTypes.ADD_STOP,
    stop,
  };
}

export function initializeItinerary(itinerary) {
  return {
    type: ActionTypes.INITIALIZE_ITINERARY,
    itinerary,
  };
}

export function deleteStop(id) {
  return {
    type: ActionTypes.DELETE_STOP,
    id,
  };
}
export function deleteDay(id) {
  return {
    type: ActionTypes.DELETE_DAY,
    id,
  };
}

export function deleteActivity(id) {
  return {
    type: ActionTypes.DELETE_ACTIVITY,
    id,
  };
}

export function generateItineraryRequest(fisrt_activity_time, lunch_time, back_to_hotel_time) {
  return {
    type: ActionTypes.GENERATE_ITINERARY_REQUEST,
    fisrt_activity_time,
    lunch_time,
    back_to_hotel_time,
  };
}
export function generateItinerarySuccess(itinerary_day) {
  return {
    type: ActionTypes.GENERATE_ITINERARY_SUCCESS,
    itinerary_day,
  };
}

export function getItinerary(id, initialize) {
  return {
    type: ActionTypes.GET_ITINERARY,
    id,
    initialize,
  };
}

export function getItinerarySuccess(itinerary) {
  return {
    type: ActionTypes.GET_ITINERARY_SUCCESS,
    itinerary,
  };
}

export function getItineraryFailure() {
  return {
    type: ActionTypes.GET_ITINERARY_FAILURE,
  };
}

export function markItineraryAsDefault(data) {
  return {
    type: ActionTypes.MARK_ITINERARY_AS_DEFAULT,
    data,
  };
}

export function markItineraryAsDefaultSuccess() {
  return {
    type: ActionTypes.MARK_ITINERARY_AS_DEFAULT_SUCCESS,
  };
}

export function markItineraryAsDefaultFailure() {
  return {
    type: ActionTypes.MARK_ITINERARY_AS_DEFAULT_FAILURE,
  };
}
