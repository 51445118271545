import React from 'react';
import styled from 'styled-components';
const parse = require('html-react-parser');

export const Conatiner = styled.div`
  align-items: center;
`;

export const Title = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => props.titleSize || 20}px;
  font-weight: 600;
  text-align: center;
  font-weight: 600;
  color: #464646;
  margin-top: 60px;
  margin-left: 70px;
  margin-right: 70px;
`;

export const Content = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  line-height: 1.47;
  color: #999999;
  text-align: justify;
  height: 100%;
  padding-right: 15px;
  max-height: ${props => props.maxHeight}px;
  overflow: auto;
`;

const ConceptContent = props => {
  return (
    <Conatiner>
      <Title titleSize={props.titleSize}>{props.title}</Title>
      {props.isHtml ? (
        <Content maxHeight={props.maxHeight}>
          {parse(props.content)} {props.secondaryContent}
        </Content>
      ) : (
          <Content>
            {props.content} {props.secondaryContent}
          </Content>
        )}
    </Conatiner>
  );
};

export default ConceptContent;
