import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import ListEmpty from 'components/ListEmpty';
import Pagination from 'components/PaginationButtons';
import Loader from 'components/Loader';

const Button = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: #38b6ff;
`;
const GridContainer = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const THeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  min-width: 1000px;
`;

const TitleContainer = styled.div`
  width: ${props => props.width};
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

const Title = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props => (props.left ? 'left' : 'center')};
  color: #999999;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

const Primary = styled.p`
  font-size: 14px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin: 0px;
`;

const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 14px;
  cursor: ${props => (props.pointer ? 'pointer' : 'initial')};
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TBody = styled.div``;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

class PreTripCourseList extends Component {
  renderPreTripCourse({ created_at, status, PreTripCourse }, key) {
    return (
      <Item key={key}>
        <SectionContainer width={'40%'}>
          <Primary>{PreTripCourse.title}</Primary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'30%'}>
          <Primary className="without-margin">
            <i className="calendar-minus" />
          </Primary>
          <Secondary>{moment(created_at).format('MM-DD-YYYY hh:mm A')}</Secondary> <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'30%'}>
          <Link to={`/preview/pre-trip-courses/${PreTripCourse.id}`}>
            <Secondary pointer>View Details</Secondary>
          </Link>
        </SectionContainer>
      </Item>
    );
  }

  render() {
    const {
      purchasedPreTripCourses,
      currentPage,
      changePage,
      purchasedPreTripCoursesCount,
      elementsPerPage,
      role,
      loading,
    } = this.props;
    return (
      <>
        {loading ? (
          <LoaderContainer>
            <Loader type="pulse" size={125} />
          </LoaderContainer>
        ) : (
            <GridContainer>
              <Header>
                <div>
                  <Pagination
                    currentPage={currentPage}
                    changePage={changePage}
                    totalPages={Math.ceil(purchasedPreTripCoursesCount / elementsPerPage)}
                  />
                </div>

                <div>
                  {role !== 'admin' && (
                    <Link to={'/pre-trip-courses'}>
                      <Button>Obtain Pre-Trip Courses</Button>
                    </Link>
                  )}
                </div>
              </Header>

              <THeader>
                <TitleContainer width={'40%'}>
                  <Title>COURSE</Title>
                </TitleContainer>
                <TitleContainer width={'30%'}>
                  <Title>DATE</Title>
                </TitleContainer>
                <TitleContainer width={'30%'} />
              </THeader>
              <TBody>
                {purchasedPreTripCourses.map((PreTripCourse, key) => {
                  return this.renderPreTripCourse(PreTripCourse, key);
                })}
                {purchasedPreTripCourses.length == 0 && (
                  <ListEmpty message="There are currently no customer Pre Trip Courses" />
                )}
              </TBody>
            </GridContainer>
          )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  null,
  mapDispatchToProps,
)(PreTripCourseList);
