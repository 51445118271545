import React, { Component } from 'react';
import RequestsOfBoutiquePackagesView from './RequestsOfBoutiquePackagesView';
import { connect } from 'react-redux';
import * as requestBoutiquePackageActions from '../../actions/requestBoutiquePackageActions';

const elementsPerPage = 10;

class RequestsOfBoutiquePackagesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      requestSelected: null,
      price: null,
      day_price: null,
      status: '',
    };
  }

  componentDidMount() {
    const { getRequestsBoutiquePackages } = this.props;

    getRequestsBoutiquePackages({});
  }

  changePage = page => {
    const { getRequestsBoutiquePackages } = this.props;

    getRequestsBoutiquePackages({ page });
  };

  toggleModal = (isOpen, requestSelected) => {
    this.setState({
      isOpen,
      requestSelected,
    });

    if (requestSelected) {
      // initialize
      this.setState({
        price: requestSelected.price,
        day_price: requestSelected.day_price,
        status: requestSelected.status,
      });
    }
  };

  save = () => {
    const { updateRequestBoutiquePackage } = this.props;
    const {
      price,
      day_price,
      status,
      requestSelected: { id },
    } = this.state;

    updateRequestBoutiquePackage(id, { price, day_price, status, day_price });
    this.toggleModal(false, null);
  };

  handleChange = evt => {
    const { name, value } = evt.target;

    this.setState({ [name]: value });
  };

  render() {
    const { list, count, currentPage, loading } = this.props;
    const { isOpen, requestSelected, price, day_price, status } = this.state;

    return (
      <RequestsOfBoutiquePackagesView
        list={list}
        count={count}
        currentPage={currentPage}
        loading={loading}
        changePage={this.changePage}
        elementsPerPage={elementsPerPage}
        toggleModal={this.toggleModal}
        isOpen={isOpen}
        handleChange={this.handleChange}
        save={this.save}
        requestSelected={requestSelected}
        price={price}
        day_price={day_price}
        status={status}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.boutiquePackageReducer.list,
    count: state.boutiquePackageReducer.count,
    currentPage: state.boutiquePackageReducer.currentPage,
    loading: state.boutiquePackageReducer.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRequestsBoutiquePackages: params =>
      dispatch(requestBoutiquePackageActions.getRequestsBoutiquePackages(params)),
    updateRequestBoutiquePackage: (id, data) =>
      dispatch(requestBoutiquePackageActions.updateRequestBoutiquePackage(id, data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestsOfBoutiquePackagesContainer);
