import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { appColor, headerHeight } from 'modules/theme';

import * as loginActions from './../actions/loginActions';

import { utils } from 'styled-minimal';
import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import Logo from 'assets/media/images/Authentic-Traveling-Logo.png';
import defaultAvatar from 'assets/media/images/avatar.png';

const { responsive, spacer } = utils;

const HeaderWrapper = styled.header`
  background-color: #ffffff;
  height: ${headerHeight}px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;
  border-bottom: 1px solid #e5e5e5;

  &:before {
    bottom: 0;
    content: '';
    height: 0.2rem;
    left: 0;
    position: absolute;
    right: 0;
  }
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  padding-bottom: ${spacer(2)};
  padding-top: ${spacer(2)};
  padding-left: 32px;
`;

const RightMenu = styled.div`
  align-items: center;
  display: flex;
  padding: ${spacer(2)};
`;

class Header extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    onLogout: PropTypes.func.isRequired,
  };

  state = {
    isOpenDropdown: false,
  };

  render() {
    const { profile, onLogout } = this.props;
    const { isOpenDropdown } = this.state;
    const menuItems = [
      {
        id: 1,
        label: 'Logout',
        onClick: () => onLogout(),
      },
    ];

    return (
      <HeaderWrapper>
        <HeaderContainer>
          <img src={Logo} width="172" />
          <RightMenu>
            <Dropdown
              title={`${profile.first_name} ${profile.last_name}`}
              subtitle={profile.role_name}
              avatar={profile.avatar || defaultAvatar}
              menuItems={menuItems}
              isDropdownOpen={isOpenDropdown}
              onClickDropdownButton={() => this.setState({ isOpenDropdown: !isOpenDropdown })}
            />
          </RightMenu>
        </HeaderContainer>
      </HeaderWrapper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLogout: () => dispatch(loginActions.logout()),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(Header);
