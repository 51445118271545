import React from 'react';
import styled from 'styled-components';
import { appColor } from 'modules/theme';
import Button from 'components/Button';
import { MONTHS } from 'constants/index';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DATE_LIST } from 'constants/index';

const GridContainer = styled.div`
  margin: 10px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 100)}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const Label = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  font-size: ${props => (props.small ? '15px' : '18.5px')};
  line-height: 1;
  letter-spacing: normal;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  color: ${props => (props.color ? props.color : '#999999')};
  margin-top: ${props => props.marginTop && props.marginTop}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 20)}px;
`;

const InputActivity = styled.input`
  width: 95%;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  text-align: ${props => (props.textAling ? props.textAling : 'start')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
`;

const Select = styled.select`
  width: 100%;
  height: 52px;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  border: none;
  ::placeholder {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    color: #999999;
  }
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #616161;
  margin-left: 5px;
  margin-right: 20px;
`;

const AddClimateCityForm = props => {
  const {
    viewMode,
    isEditing,
    handleisEditing,
    setClimate,
    handleAddCity,
    climate,
    saveClimate,
    editClimate,
    regionsForSelect,
    citiesForSelect,
    getCitiesForSelect,
    country: { id },
  } = props;

  const selectClimate = (name, values, handleChange) => {
    let value = '';
    switch (name) {
      //january
      case 'average_high_january':
        value = values.average_high_january;
        break;
      case 'average_low_january':
        value = values.average_low_january;
        break;
      case 'daily_chance_of_precipitation_january':
        value = values.daily_chance_of_precipitation_january;
        break;
      case 'average_dew_point_january':
        value = values.average_dew_point_january;
        break;
      case 'average_sunshine_hours_january':
        value = values.average_sunshine_hours_january;
        break;
      //february
      case 'average_high_february':
        value = values.average_high_february;
        break;
      case 'average_low_february':
        value = values.average_low_february;
        break;
      case 'daily_chance_of_precipitation_february':
        value = values.daily_chance_of_precipitation_february;
        break;
      case 'average_dew_point_february':
        value = values.average_dew_point_february;
        break;
      case 'average_sunshine_hours_february':
        value = values.average_sunshine_hours_february;
        break;
      //march
      case 'average_high_march':
        value = values.average_high_march;
        break;
      case 'average_low_march':
        value = values.average_low_march;
        break;
      case 'daily_chance_of_precipitation_march':
        value = values.daily_chance_of_precipitation_march;
        break;
      case 'average_dew_point_march':
        value = values.average_dew_point_march;
        break;
      case 'average_sunshine_hours_march':
        value = values.average_sunshine_hours_march;
        break;
      //april
      case 'average_high_april':
        value = values.average_high_april;
        break;
      case 'average_low_april':
        value = values.average_low_april;
        break;
      case 'daily_chance_of_precipitation_april':
        value = values.daily_chance_of_precipitation_april;
        break;
      case 'average_dew_point_april':
        value = values.average_dew_point_april;
        break;
      case 'average_sunshine_hours_april':
        value = values.average_sunshine_hours_april;
        break;
      //may
      case 'average_high_may':
        value = values.average_high_may;
        break;
      case 'average_low_may':
        value = values.average_low_may;
        break;
      case 'daily_chance_of_precipitation_may':
        value = values.daily_chance_of_precipitation_may;
        break;
      case 'average_dew_point_may':
        value = values.average_dew_point_may;
        break;
      case 'average_sunshine_hours_may':
        value = values.average_sunshine_hours_may;
        break;
      //june
      case 'average_high_june':
        value = values.average_high_june;
        break;
      case 'average_low_june':
        value = values.average_low_june;
        break;
      case 'daily_chance_of_precipitation_june':
        value = values.daily_chance_of_precipitation_june;
        break;
      case 'average_dew_point_june':
        value = values.average_dew_point_june;
        break;
      case 'average_sunshine_hours_june':
        value = values.average_sunshine_hours_june;
        break;
      //july
      case 'average_high_july':
        value = values.average_high_july;
        break;
      case 'average_low_july':
        value = values.average_low_july;
        break;
      case 'daily_chance_of_precipitation_july':
        value = values.daily_chance_of_precipitation_july;
        break;
      case 'average_dew_point_july':
        value = values.average_dew_point_july;
        break;
      case 'average_sunshine_hours_july':
        value = values.average_sunshine_hours_july;
        break;
      //august
      case 'average_high_august':
        value = values.average_high_august;
        break;
      case 'average_low_august':
        value = values.average_low_august;
        break;
      case 'daily_chance_of_precipitation_august':
        value = values.daily_chance_of_precipitation_august;
        break;
      case 'average_dew_point_august':
        value = values.average_dew_point_august;
        break;
      case 'average_sunshine_hours_august':
        value = values.average_sunshine_hours_august;
        break;
      //september
      case 'average_high_september':
        value = values.average_high_september;
        break;
      case 'average_low_september':
        value = values.average_low_september;
        break;
      case 'daily_chance_of_precipitation_september':
        value = values.daily_chance_of_precipitation_september;
        break;
      case 'average_dew_point_september':
        value = values.average_dew_point_september;
        break;
      case 'average_sunshine_hours_september':
        value = values.average_sunshine_hours_september;
        break;
      //october
      case 'average_high_october':
        value = values.average_high_october;
        break;
      case 'average_low_october':
        value = values.average_low_october;
        break;
      case 'daily_chance_of_precipitation_october':
        value = values.daily_chance_of_precipitation_october;
        break;
      case 'average_dew_point_october':
        value = values.average_dew_point_october;
        break;
      case 'average_sunshine_hours_october':
        value = values.average_sunshine_hours_october;
        break;
      //november
      case 'average_high_november':
        value = values.average_high_november;
        break;
      case 'average_low_november':
        value = values.average_low_november;
        break;
      case 'daily_chance_of_precipitation_november':
        value = values.daily_chance_of_precipitation_november;
        break;
      case 'average_dew_point_november':
        value = values.average_dew_point_november;
        break;
      case 'average_sunshine_hours_november':
        value = values.average_sunshine_hours_november;
        break;
      //december
      case 'average_high_december':
        value = values.average_high_december;
        break;
      case 'average_low_december':
        value = values.average_low_december;
        break;
      case 'daily_chance_of_precipitation_december':
        value = values.daily_chance_of_precipitation_december;
        break;
      case 'average_dew_point_december':
        value = values.average_dew_point_december;
        break;
      case 'average_sunshine_hours_december':
        value = values.average_sunshine_hours_december;
        break;
      default:
        break;
    }
    1;
    return (
      <InputActivity
        type="number"
        placeholder="0"
        name={name}
        value={value}
        onChange={handleChange}
        disabled={viewMode}
      />
    );
  };

  const renderPerMonths = (values, handleChange) =>
    MONTHS.map((month, key) => {
      const monthLowerCase = month.toLocaleLowerCase();
      const averageHigh = 'average_high_' + monthLowerCase;
      const averageLow = 'average_low_' + monthLowerCase;
      const dailyChancePrecipitation = 'daily_chance_of_precipitation_' + monthLowerCase;
      const averageDewPoint = 'average_dew_point_' + monthLowerCase;
      const averageSunshineHours = 'average_sunshine_hours_' + monthLowerCase;
      return (
        <FlexRow
          key={key}
          style={{
            borderBottom: '1px solid #fafafa',
            paddingBottom: 5,
            marginTop: 10,
          }}
        >
          <FlexColumn width={50}>
            <Label color={'#38b6ff'} marginTop={60}>
              {month}
            </Label>
          </FlexColumn>
          <FlexColumn marginRight={5}>
            <Label small>Average High</Label>
            {selectClimate(averageHigh, values, handleChange)}
          </FlexColumn>
          <FlexColumn marginRight={5}>
            <Label small>Average Low</Label>
            {selectClimate(averageLow, values, handleChange)}
          </FlexColumn>
          <FlexColumn marginRight={5}>
            <Label small>Daily Chance Of Precipitation</Label>
            {selectClimate(dailyChancePrecipitation, values, handleChange)}
          </FlexColumn>
          <FlexColumn marginRight={5}>
            <Label small>Average Dew Point</Label>
            {selectClimate(averageDewPoint, values, handleChange)}
          </FlexColumn>
          <FlexColumn marginRight={5}>
            <Label small>Average Sunshine Hours</Label>
            {selectClimate(averageSunshineHours, values, handleChange)}
          </FlexColumn>
        </FlexRow>
      );
    });

  return (
    <GridContainer>
      <FlexRow style={{ justifyContent: 'space-between', marginBottom: 20 }}>
        {isEditing ? <Label>Edit City</Label> : <Label>Add New City</Label>}
        <Button
          onClick={() => {
            handleAddCity(false);
            handleisEditing(false);
            setClimate(null);
          }}
          style={{ marginTop: 0 }}
        >
          Back
        </Button>
      </FlexRow>
      <Formik
        enableReinitialize
        initialValues={climate}
        isInitialValid={isEditing ? true : false}
        validationSchema={Yup.object().shape({
          city_id: Yup.string().required('Required'),
          region_id: Yup.string().required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, resetForm }) => {
          return (
            <>
              <GridContainer>
                <FlexRow>
                  <FlexColumn marginRight={30}>
                    <Label>Region</Label>
                    {/* <InputActivity
                      marginBottom={1}
                      type="text"
                      placeholder="region"
                      name="region"
                      value={values.region}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.region && touched.region}
                      min={0}
                      disabled={viewMode}
                    /> */}

                    <Select
                      marginBottom={1}
                      name="region_id"
                      value={values.region_id}
                      onChange={e => {
                        handleChange(e);
                        getCitiesForSelect({ region_id: e.target.value });
                      }}
                      onBlur={handleBlur}
                      error={errors.region_id && touched.region_id}
                      disabled={viewMode}
                    >
                      <option className="selectPlaceholder" value="" disabled selected hidden>
                        Select Region
                      </option>
                      {regionsForSelect.map(region => (
                        <option value={`${region.id}`}>{region.name}</option>
                      ))}
                    </Select>
                  </FlexColumn>
                  <FlexColumn marginRight={30}>
                    <Label>City</Label>

                    <Select
                      marginBottom={1}
                      name="city_id"
                      value={values.city_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.city_id && touched.city_id}
                      disabled={viewMode}
                    >
                      <option className="selectPlaceholder" value="" disabled selected hidden>
                        Select City
                      </option>
                      {citiesForSelect.map(city => (
                        <option value={`${city.id}`}>{city.name}</option>
                      ))}
                    </Select>

                    {/* <InputActivity
                      marginBottom={1}
                      type="text"
                      placeholder="city"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.city && touched.city}
                      min={0}
                      disabled={viewMode}
                    /> */}
                  </FlexColumn>
                </FlexRow>
                <FlexRow
                  style={{ justifyContent: 'space-between', marginBottom: 40, marginTop: 20 }}
                >
                  <Label>Climate</Label>
                </FlexRow>
                {renderPerMonths(values, handleChange)}
                <FlexRow>
                  <FlexColumn marginRight={30}>
                    <Label>Best time to visit for weather start date</Label>
                    {!viewMode ? (
                      <Select
                        name="best_time_to_visit_for_weather_start_date"
                        value={values.best_time_to_visit_for_weather_start_date}
                        onChange={handleChange}
                        disabled={viewMode}
                      >
                        {DATE_LIST.map(date => (
                          <option value={`${date.value}`}>{date.name}</option>
                        ))}
                      </Select>
                    ) : (
                      <InputActivity
                        marginBottom={1}
                        value={values.best_time_to_visit_for_weather_start_date}
                        disabled={viewMode}
                      />
                    )}
                  </FlexColumn>
                  <FlexColumn marginRight={30}>
                    <Label>Best time to visit for weather end date</Label>
                    {!viewMode ? (
                      <Select
                        name="best_time_to_visit_for_weather_end_date"
                        value={values.best_time_to_visit_for_weather_end_date}
                        onChange={handleChange}
                        disabled={viewMode}
                      >
                        {DATE_LIST.map(date => (
                          <option value={`${date.value}`}>{date.name}</option>
                        ))}
                      </Select>
                    ) : (
                      <InputActivity
                        marginBottom={1}
                        value={values.best_time_to_visit_for_weather_end_date}
                        disabled={viewMode}
                      />
                    )}
                  </FlexColumn>
                  <FlexColumn marginRight={30}>
                    <Label>Best time to visit for weather start date 2</Label>
                    {!viewMode ? (
                      <Select
                        name="best_time_to_visit_for_weather_start_date_2"
                        value={values.best_time_to_visit_for_weather_start_date_2}
                        onChange={handleChange}
                        disabled={viewMode}
                      >
                        {DATE_LIST.map(date => (
                          <option value={`${date.value}`}>{date.name}</option>
                        ))}
                      </Select>
                    ) : (
                      <InputActivity
                        marginBottom={1}
                        value={values.best_time_to_visit_for_weather_start_date_2}
                        disabled={viewMode}
                      />
                    )}
                  </FlexColumn>
                  <FlexColumn marginRight={30}>
                    <Label>Best time to visit for weather end date 2</Label>
                    {!viewMode ? (
                      <Select
                        name="best_time_to_visit_for_weather_end_date_2"
                        value={values.best_time_to_visit_for_weather_end_date_2}
                        onChange={handleChange}
                      >
                        {DATE_LIST.map(date => (
                          <option value={`${date.value}`}>{date.name}</option>
                        ))}
                      </Select>
                    ) : (
                      <InputActivity
                        marginBottom={1}
                        value={values.best_time_to_visit_for_weather_end_date_2}
                        disabled={viewMode}
                      />
                    )}
                  </FlexColumn>
                </FlexRow>
                {!viewMode && (
                  <Button
                    style={{ marginTop: 20 }}
                    disabled={!isValid}
                    onClick={() => {
                      handleAddCity(false);
                      if (isEditing) {
                        editClimate(values.id, values);
                        handleisEditing(false);
                      } else {
                        saveClimate(id, values);
                      }
                    }}
                  >
                    Save
                  </Button>
                )}
              </GridContainer>
            </>
          );
        }}
      </Formik>
    </GridContainer>
  );
};

export default AddClimateCityForm;
