import React from 'react';
import styled from 'styled-components';
import './styles.css';
import { Link } from 'react-router-dom';
import defaultAvatar from 'assets/media/images/avatar.png';
import ok from 'assets/media/icons/ok.png';
import pending from 'assets/media/icons/pending.png';
import remove from 'assets/media/icons/remove.png';

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 36px;
  height: 70px;
  min-width: 1000px;
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
`;

const SectionContainer = styled.div`
  position: relative;
  width: ${props => props.width};
  height: 100%;
  align-items: center;
  padding: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: ${props => (props.left ? 'left' : 'center')};
`;

const Image = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 19px;
  margin-top: 19px;
  object-fit: cover;
`;

const Primary = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;
const Secondary = styled.p`
  color: #9e9e9e;
  font-size: 10px;
`;

const VerticalLine = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
`;

export default class CustomerListItem extends React.Component {
  getStatus = status => {
    const names = {
      active: 'Active',
      trialing: 'Trialing',
      incomplete: 'Incomplete',
      incomplete_expired: 'Incomplete Expired',
      past_due: 'Past Due',
      canceled: 'Canceled',
      unpaid: 'Unpaid',
    };

    return names[status];
  };

  getStatusIcon = status => {
    const icons = {
      active: ok,
      trialing: remove,
      incomplete: pending,
      incomplete_expired: remove,
      past_due: remove,
      canceled: remove,
      unpaid: remove,
    };

    return icons[status];
  };

  getDiagnosticStatus = diagnostic_step => {
    if (diagnostic_step < 9) {
      return 'Pending';
    }

    return 'Completed';
  };

  getDiagnosticStatusIcon = diagnostic_step => {
    if (diagnostic_step < 9) {
      return pending;
    }

    return ok;
  };

  getMeetingIcon = status => {
    const style = {
      completed: 'calendar-check',
      rejected: 'calendar-times',
      pending: 'calendar-plus',
      in_process: 'calendar-minus',
    };

    return `far icons fa-${style[status]} ${status}`;
  };

  render() {
    const { customer } = this.props;
    return (
      <Item>
        <SectionContainer width={'10%'}>
          <Image src={customer.avatar || defaultAvatar} />
        </SectionContainer>
        <SectionContainer width={'20%'} left>
          <Primary>
            {customer.first_name} {customer.last_name}
          </Primary>
          <Secondary>{customer.email}</Secondary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'20%'}>
          <Primary>{customer.city}</Primary>
          <Secondary>{customer.country}</Secondary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'14%'}>
          <Primary>{customer.phone}</Primary>
          <Secondary>Phone</Secondary>
          <VerticalLine />
        </SectionContainer>
        <SectionContainer width={'10%'}>
          <Primary className="without-margin">
            <img
              className="calendarImage"
              src={this.getDiagnosticStatusIcon(customer.diagnostic_step)}
            />
          </Primary>
          <Secondary className="without-margin">
            {this.getDiagnosticStatus(customer.diagnostic_step)}
          </Secondary>
          <VerticalLine />
        </SectionContainer>

        <SectionContainer width={'10%'}>
          <Primary className="without-margin">
            <img className="calendarImage" src={this.getStatusIcon(customer.subscription.status)} />
          </Primary>
          <Secondary className="without-margin">
            {this.getStatus(customer.subscription.status)}
          </Secondary>
          <VerticalLine />
        </SectionContainer>
        {/* {customer.meeting ? (
          <SectionContainer width={'12%'}>
            <Link to={`/meeting?id=${customer.id}`}>
              <Primary className="without-margin">
                <i className={this.getMeetingIcon(customer.meeting.status)} />
              </Primary>
              <Secondary className={`without-margin ${customer.meeting.status}`}>
                {moment(customer.meeting.start_date).format('MM-DD-YYYY hh:mm A')}
              </Secondary>
            </Link>
          </SectionContainer>
        ) : (
          <SectionContainer width={'12%'}>
            <Primary className="without-margin">
              <i className="far fa-calendar icons non-meeting" />
            </Primary>
            <Secondary className="without-margin">Undefined</Secondary>
            <VerticalLine />
          </SectionContainer>
        )} */}
        <SectionContainer width={'16%'}>
          <Primary className="link">
            <Link to={`/customers/${customer.id}`}>
              View Details <i className="fas fa-chevron-right" />
            </Link>
          </Primary>
        </SectionContainer>
      </Item>
    );
  }
}
