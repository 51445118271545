import React, { Component } from 'react';
import PreTripCoursesForm from './PreTripCoursesForm';
import { connect } from 'react-redux';
import * as preTripCourseActions from '../../actions/preTripCourseActions';

class PreTripCoursesContainer extends Component {
  state = { title: '', image: '', file: '' };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  updateImage = response => {
    this.setState({ image: response.url });
  };
  replaceImage = () => {
    this.setState({ image: null });
  };

  updateFile = response => {
    this.setState({ file: response.url });
  };

  replaceFile = () => {
    this.setState({ file: null });
  };

  render() {
    const { title } = this.state;
    return (
      <PreTripCoursesForm
        {...this.props}
        updateImage={this.updateImage}
        title={title}
        handleChange={this.handleChange}
        replaceImage={this.replaceImage}
        data={this.state}
        updateFile={this.updateFile}
        replaceFile={this.replaceFile}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.loginReducer.token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createPreTripCourses: preTripCourse =>
      dispatch(preTripCourseActions.createPreTripCourses(preTripCourse)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreTripCoursesContainer);
