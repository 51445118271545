import React, { Component } from 'react';
import AddTitleView from './AddTitleView';
import { connect } from 'react-redux';
import * as tripActions from 'actions/tripActions';
import * as diagnosticActions from 'actions/diagnosticActions';

class AddTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      image: '',
    };
  }

  componentDidMount() {
    const { title, image } = this.props;
    this.setState({ title, image });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.title != this.props.title)
      this.setState({ title: this.props.title, image: this.props.image });
  }

  changeStep = step => {
    const {
      updateMeeting,
      changeCurrentStep,
      saveTripData,
      persistTripData,
      editTrip,
      match: { params },
    } = this.props;
    const { title, image } = this.state;

    saveTripData({ title, image });
    changeCurrentStep(step);

    if (step == 30) {
      if (!params.id) {
        persistTripData();
        if (params.meeting) {
          updateMeeting({ id: params.meeting, status: 'completed' });
        }
      } else {
        editTrip(params.id);
      }
    }
  };

  changeTitle = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  updateImage = response => {
    this.setState({ changeAvatar: false, image: response.url });
  };

  replaceImage = () => {
    this.setState({ image: null });
  };

  render() {
    const { title, image } = this.state;
    const { token } = this.props;
    return (
      <AddTitleView
        {...this.props}
        changeStep={this.changeStep}
        changeTitle={this.changeTitle}
        title={title}
        image={image}
        token={token}
        updateImage={this.updateImage}
        replaceImage={this.replaceImage}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    title: state.tripReducer.tripForm.title,
    image: state.tripReducer.tripForm.image,
    token: state.loginReducer.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
    persistTripData: () => dispatch(tripActions.persistTripData()),
    editTrip: id => dispatch(tripActions.editTrip(id)),
    updateMeeting: meeting => dispatch(diagnosticActions.updateMeeting(meeting)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTitle);
