import React, { Component } from 'react';
import CoursesList from 'components/Courses/CoursesList';
import { connect } from 'react-redux';
import * as courseActions from 'actions/courseActions';

class CoursesListContainer extends Component {
  sendTest = (type, name) => {
    const {
      customer: {
        profile: { id: user_id },
      },
      createCourseRequest,
    } = this.props;
    createCourseRequest({ user_id, type, name });
  };

  getCourseStatus = type => {
    const {
      customer: { courses },
    } = this.props;
    const course = courses.find(co => co.type == type);
    if (!course) return null;
    return course.status;
  };

  render() {
    const courses = [
      {
        type: 'introduction',
        name: 'Re-Introduction to 5 Foundamental Strengths',
        status: this.getCourseStatus('introduction'),
      },
      {
        type: 'purpose',
        name: 'Purpose Course',
        status: this.getCourseStatus('purpose'),
      },
      {
        type: 'mindfulness',
        name: 'Mindfulness Course',
        status: this.getCourseStatus('mindfulness'),
      },
      {
        type: 'confidence',
        name: 'Confidence Course',
        status: this.getCourseStatus('confidence'),
      },
      {
        type: 'resilence',
        name: 'Resilence Course',
        status: this.getCourseStatus('resilence'),
      },
      {
        type: 'antifragility',
        name: 'Anti-Fragility Course',
        status: this.getCourseStatus('antifragility'),
      },
    ];

    return <CoursesList sendTest={this.sendTest} courses={courses} isAdmin />;
  }
}

function mapStateToProps(state) {
  return {
    customer: state.customerReducer.customer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createCourseRequest: course => dispatch(courseActions.createCourseRequest(course)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoursesListContainer);
