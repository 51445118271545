import React, { Component } from 'react';
import RentalCarView from './RentalCarView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';

class RentalCar extends Component {
  constructor(props) {
    super(props);
    this.state = { rental_car: false };
  }

  componentDidMount() {
    const { rental_car } = this.props;
    this.setState({ rental_car });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { rental_car } = this.state;
    changeCurrentStep(step);
    saveTripData({ rental_car });
  };

  setRentalCar = value => {
    this.setState({ rental_car: value });
  };

  render() {
    return (
      <RentalCarView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        setRentalCar={this.setRentalCar}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    rental_car: state.tripReducer.tripForm.rental_car,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RentalCar);
