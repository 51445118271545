import React, { Component } from 'react';
import ActivitiesView from './ActivitiesView';
import { connect } from 'react-redux';
import * as ActivitiesActions from 'actions/activityActions';

class ActivitiesContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10, isOpen: false };

  componentDidMount() {
    this.fetchActivities();
  }

  fetchActivities = () => {
    const { currentPage, elementsPerPage } = this.state;

    this.props.onGetActivities(currentPage, elementsPerPage);
  };

  changePage = currentPage => {
    const { elementsPerPage } = this.state;
    this.props.onGetActivities(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  toggleModal = activitySelected => {
    const { isOpen } = this.state;

    if (!isOpen) {
      window.scrollTo(0, 0);
    }

    this.setState({
      isOpen: !isOpen,
      activitySelected,
    });
  };

  removeActivity = () => {
    const { onRemoveActivity } = this.props;
    const { activitySelected } = this.state;

    onRemoveActivity(activitySelected);

    this.setState({
      isOpen: false,
      activitySelected,
    });
  };

  render() {
    const { activitiesCount, activities, loading } = this.props;
    const { currentPage, elementsPerPage, isOpen } = this.state;
    return (
      <ActivitiesView
        {...this.props}
        getActivities={this.getActivities}
        activities={activities}
        currentPage={currentPage}
        changePage={this.changePage}
        activitiesCount={activitiesCount}
        elementsPerPage={elementsPerPage}
        isOpen={isOpen}
        toggleModal={this.toggleModal}
        removeActivity={this.removeActivity}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    activities: state.activityReducer.activities,
    activitiesCount: state.activityReducer.activitiesCount,
    loading: state.activityReducer.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onGetActivities: (page, elementsPerPage) =>
      dispatch(ActivitiesActions.getActivities(page, elementsPerPage)),
    onRemoveActivity: id => dispatch(ActivitiesActions.removeActivity(id)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivitiesContainer);
