import React, { Component } from 'react';
import { replace } from 'modules/history';
import StepContainerWithImage from '../../StepContainerWithImage';
import StepContainer from '../../StepContainer';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import ConceptContent from '../../ConceptContent';
import WellBeingChart from '../Charts/WellBeingChart';
import FoundationalStrengthsChart from '../Charts/FoundationalStrengthsChart';
import CoreStrengthsChart from '../Charts/CoreStrengthsChart';
import Modal from 'components/Modal';

const ExplanacionResultsBox = styled.div`
  margin-left: 50px;
  margin-right: 30px;
  height: 534px;
  @media (max-width: 1000px) {
    margin-left: 15%;
    margin-right: 15%;
    height: auto;
  }
`;

const GraphContainer = styled.div`
  margin: 25px 30%;
  @media (max-width: 1000px) {
    margin-left: 15%;
    margin-right: 15%;
  }
`;

export const CoreStrngthsText = styled.p`
  font-size: 15px;
  color: #999999;
  margin: 60px;
  text-align: center;
`;

class ExplanacionResultsView extends Component {
  constructor(props) {
    super(props);
  }

  retrunChart = step => {
    const {
      wellBeingResults,
      fundationalStrengthsResult,
      travelPersonalityResult,
      coreStrengthsResult,
    } = this.props.state;

    switch (step) {
      case 3: {
        return <WellBeingChart wellBeingResults={wellBeingResults} />;
      }
      case 5: {
        return (
          <FoundationalStrengthsChart fundationalStrengthsResult={fundationalStrengthsResult} />
        );
      }
      // case 6: {
      //   return <CoreStrengthsChart coreStrengthsResult={coreStrengthsResult} />;
      // }
      case 7: {
        return <ConceptContent isHtml content={travelPersonalityResult} />;
      }
    }
  };

  render() {
    const { changeStep, state, stepFields, meeting, loadingMeeting } = this.props;
    const {
      localStep,
      wellBeingResults,
      fundationalStrengthsResult,
      travelPersonalityResult,
      coreStrengthsResult,
    } = state;

    if (loadingMeeting) return null;
    if (!meeting) {
      return (
        <Modal
          closeIcon
          isVisible={true}
          title="Test Incomplete"
          cancelText="Ok"
          onCancel={() => replace('/')}
        >
          <p style={{ textAlign: 'center' }}>
            You did'nt complete your diagnostic test. <a href="/diagnostics">Click here</a> to
            complete.
          </p>
        </Modal>
      );
    } else if (meeting && meeting.status != 'completed') {
      return (
        <Modal
          closeIcon
          isVisible={true}
          title="We’re analyzing your results—check back soon!"
          cancelText="Ok"
          onCancel={() => replace('/')}
        ></Modal>
      );
    }

    return (
      <>
        {localStep != 7 && localStep != 9 && (
          <StepContainerWithImage closeIcon image={stepFields.image}>
            <ExplanacionResultsBox>
              <div style={{ height: '85%' }}>
                <ConceptContent
                  isHtml
                  content={stepFields.content}
                  title={stepFields.title}
                  maxHeight={400}
                  secondaryContent={this.retrunChart(localStep)}
                />
              </div>

              <StepButtons
                customNextText={localStep == 1 ? "Let's start" : null}
                style={{ height: '26%' }}
                justifyContent={'space-between'}
                hiddenBack={localStep == 1}
                backStep={() => changeStep(localStep - 1)}
                nextStep={() => changeStep(localStep + 1)}
              />
            </ExplanacionResultsBox>
          </StepContainerWithImage>
        )}
        {localStep == 7 && (
          <StepContainer subtitle={stepFields.title} closeIcon>
            <GraphContainer>
              <CoreStrengthsChart coreStrengthsResult={coreStrengthsResult} />
            </GraphContainer>
            <CoreStrngthsText>
              Here's what each of your Core Strengths means: <a> Learn more</a>
            </CoreStrngthsText>

            <StepButtons
              backStep={() => changeStep(localStep - 1)}
              nextStep={() => changeStep(localStep + 1)}
            />
          </StepContainer>
        )}
        {localStep == 9 && (
          <StepContainerWithImage closeIcon image={stepFields.image}>
            <ExplanacionResultsBox>
              <div style={{ height: '85%' }}>
                <ConceptContent
                  maxHeight={400}
                  isHtml
                  content={travelPersonalityResult.content}
                  title={`Your Travel Personality is the ${travelPersonalityResult.name}`}
                />
              </div>

              <StepButtons
                style={{ height: '26%' }}
                justifyContent={'space-between'}
                hiddenBack={localStep == 1}
                backStep={() => changeStep(localStep - 1)}
                nextStep={() => changeStep(localStep + 1)}
              />
            </ExplanacionResultsBox>
          </StepContainerWithImage>
        )}
      </>
    );
  }
}

export default ExplanacionResultsView;
