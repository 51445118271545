import { put, call, select } from 'redux-saga/effects';
import * as requestBoutiquePackageActions from 'actions/requestBoutiquePackageActions';
import * as invoiceActions from 'actions/invoiceActions';
import * as appActions from 'actions/app';

export function* getRequestsBoutiquePackages(api, action) {
  const { params } = action;
  const { status, data } = yield call(api.getRequestsBoutiquePackages, params);

  if (status === 200) {
    yield put(requestBoutiquePackageActions.getRequestsBoutiquePackagesSuccess(data));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(requestBoutiquePackageActions.getRequestsBoutiquePackagesFailure());
  }
}

export function* getRequestBoutiquePackage(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.getRequestBoutiquePackage, id);

  if (status === 200) {
    yield put(requestBoutiquePackageActions.getRequestBoutiquePackageSuccess(data));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(requestBoutiquePackageActions.getRequestBoutiquePackageFailure());
  }
}

export function* getMyRequestBoutiquePackage(api, action) {
  const { status, data } = yield call(api.getMyRequestBoutiquePackage);

  if (status === 200) {
    yield put(requestBoutiquePackageActions.getMyRequestBoutiquePackageSuccess(data));
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(requestBoutiquePackageActions.getMyRequestBoutiquePackageFailure());
  }
}

export function* saveRequestBoutiquePackage(api, action) {
  const { payload } = action;

  const { status, data } = yield call(api.saveRequestBoutiquePackage, payload);

  if (status === 200) {
    yield put(
      appActions.showAlert('Request boutique package saved.', {
        variant: 'success',
      }),
    );
    yield put(requestBoutiquePackageActions.saveRequestBoutiqueSuccess());

    yield put(requestBoutiquePackageActions.getMyRequestBoutiquePackage());
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(requestBoutiquePackageActions.saveRequestBoutiqueFailure());
  }
}

export function* updateRequestBoutiquePackage(api, action) {
  const { id, payload } = action;

  const { status, data } = yield call(api.updateRequestBoutiquePackage, id, payload);

  if (status === 200) {
    yield put(
      appActions.showAlert('Request boutique package saved.', {
        variant: 'success',
      }),
    );

    yield put(requestBoutiquePackageActions.updateRequestBoutiqueSuccess());
    const state = yield select();
    if (state.loginReducer.profile.role_id != 1) {
      yield put(requestBoutiquePackageActions.getMyRequestBoutiquePackage());
    } else {
      yield put(requestBoutiquePackageActions.getRequestsBoutiquePackages());
    }
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(requestBoutiquePackageActions.updateRequestBoutiqueFailure());
  }
}

export function* customerApprobeBoutiquePackageRequest(api, action) {
  //const { id, payload } = action;

  const { status, data } = yield call(api.customerApprobeBoutiquePackageRequest);

  if (status === 200) {
    yield put(
      appActions.showAlert('Request boutique package activated.', {
        variant: 'success',
      }),
    );

    yield put(requestBoutiquePackageActions.customerApprobeBoutiquePackageSuccess());
    yield put(invoiceActions.getMeInvoices());
  } else {
    const { message } = data;
    yield put(appActions.showAlert(message, { variant: 'danger' }));
    yield put(requestBoutiquePackageActions.customerApprobeBoutiquePackageFailure());
  }
}
