/*
 * Reducer actions related with customers
 */
import { ActionTypes } from 'constants/index';

export function getRestaurants(page, elementsPerPage) {
  return {
    type: ActionTypes.GET_RESTAURANTS,
    page,
    elementsPerPage,
  };
}

export function getRestaurantsSuccess(payload) {
  return {
    type: ActionTypes.GET_RESTAURANTS_SUCCESS,
    restaurants: payload.rows,
    restaurantsCount: payload.count,
  };
}

export function getRestaurant(id) {
  return {
    type: ActionTypes.GET_RESTAURANT,
    id,
  };
}

export function getRestaurantSuccess(restaurant) {
  return {
    type: ActionTypes.GET_RESTAURANT_SUCCESS,
    restaurant,
  };
}

export function refreshCoordinates(address) {
  return {
    type: ActionTypes.REFRESH_COORDINATES,
    address,
  };
}
export function refreshAddress(longLat) {
  return {
    type: ActionTypes.REFRESH_ADDRESS,
    longLat,
  };
}

export function saveRestaurant(restaurant) {
  return {
    type: ActionTypes.SAVE_RESTAURANT,
    restaurant,
  };
}

export function saveRestaurantSuccess() {
  return {
    type: ActionTypes.SAVE_RESTAURANT_SUCCESS,
  };
}

export function saveRestaurantFailure() {
  return {
    type: ActionTypes.SAVE_RESTAURANT_FAILURE,
  };
}

export function removeRestaurant(id, page, elementsPerPage) {
  return {
    type: ActionTypes.REMOVE_RESTAURANT,
    id,
  };
}

export function updateRestaurant(restaurant, id) {
  return {
    type: ActionTypes.UPDATE_RESTAURANT,
    restaurant,
    id,
  };
}

export function updateRestaurantSuccess() {
  return {
    type: ActionTypes.UPDATE_RESTAURANT_SUCCESS,
  };
}

export function updateRestaurantFailure() {
  return {
    type: ActionTypes.UPDATE_RESTAURANT_FAILURE,
  };
}

export function removeRestaurantSuccess() {
  return {
    type: ActionTypes.REMOVE_RESTAURANT_SUCCESS,
  };
}

export function removeRestaurantFailure() {
  return {
    type: ActionTypes.REMOVE_RESTAURANT_FAILURE,
  };
}
