import React, { Component } from 'react';
import AllPurchasePreTripCoursesView from './AllPurchasePreTripCoursesView';
import { connect } from 'react-redux';
import * as preTripCourseActions from '../../actions/preTripCourseActions';

class AllPurchasedPreTripCoursesContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 10 };

  componentDidMount() {
    const { getPurchasedPreTripCourses } = this.props;
    const { currentPage, elementsPerPage } = this.state;
    getPurchasedPreTripCourses(currentPage, elementsPerPage);
  }

  changePage = currentPage => {
    const { getPurchasedPreTripCourses } = this.props;
    const { elementsPerPage } = this.state;
    getPurchasedPreTripCourses(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  render() {
    const { currentPage, elementsPerPage } = this.state;
    return (
      <AllPurchasePreTripCoursesView
        {...this.props}
        currentPage={currentPage}
        elementsPerPage={elementsPerPage}
        changePage={this.changePage}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    allPurchasePreTripCourses: state.preTripCourseReducer.allPurchasePreTripCourses,
    allPurchasePreTripCoursesCount: state.preTripCourseReducer.allPurchasePreTripCoursesCount,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getPurchasedPreTripCourses: (page, elementsPerPage) =>
      dispatch(preTripCourseActions.allPurchasedPreTripCourses(page, elementsPerPage)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllPurchasedPreTripCoursesContainer);
