import React, { Component } from 'react';
import SettingsView from './SettingsView';
import { connect } from 'react-redux';
import * as profileActions from '../../actions/profileActions';
import * as productsActions from '../../actions/productsActions';
import * as requestBoutiquePackageActions from '../../actions/requestBoutiquePackageActions';
import * as invoiceActions from 'actions/invoiceActions';

class SettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      city: '',
      country: '',
      phone: '',
      changeAvatar: false,
      avatar: '',
      selectedProduct: '',
      alertIsOpen: false,
      productsModal: false,
      alertDisplayed: false,
      alertDegrade: false,
    };
  }

  componentDidMount() {
    const {
      getAvailableProducts,
      onGetMeInvoices,
      verifyDegradePlanRequest,
      findRequest,
      profile,
    } = this.props;
    this.setState({ ...this.props.profile, product_id: this.props.product_id });
    getAvailableProducts();
    onGetMeInvoices();
    verifyDegradePlanRequest();
    if (profile.role_code != 'admin') {
      findRequest();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product_id != this.props.product_id) {
      this.setState({ product_id: this.props.product_id });
    }
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  saveChanges = () => {
    const { updateProfile } = this.props;
    updateProfile(this.state);
  };

  activeDropzone = () => {
    this.setState({ changeAvatar: !this.state.changeAvatar });
  };

  updateImage = response => {
    this.setState({ changeAvatar: false, avatar: response.url });
  };

  selectPlan = product_id => {
    const { product_id: current_product_id, canDegradePlan } = this.props;
    if (!canDegradePlan && current_product_id > product_id) {
      this.setState({ alertDegrade: true });
      return;
    }

    const { alertDisplayed } = this.state;
    if (!alertDisplayed) {
      this.setState({ product_id, alertDisplayed: true, alertIsOpen: true, productsModal: false });
    } else {
      this.setState({ product_id, productsModal: false });
    }
  };

  updateProductModal = () => {
    this.setState({ alertIsOpen: !this.state.alertIsOpen });
  };

  changeProductsModal = () => {
    this.setState({ productsModal: !this.state.productsModal });
  };

  updateDegradeModal = () => {
    this.setState({ alertDegrade: !this.state.alertDegrade });
  };

  render() {
    const { productsModal, alertIsOpen, alertDegrade } = this.state;

    return (
      <SettingsView
        {...this.props}
        data={this.state}
        handleChange={this.handleChange}
        saveChanges={this.saveChanges}
        activeDropzone={this.activeDropzone}
        updateImage={this.updateImage}
        selectPlan={this.selectPlan}
        showProducts={this.showProducts}
        booleanUpdate={this.booleanUpdate}
        updateProductModal={this.updateProductModal}
        changeProductsModal={this.changeProductsModal}
        productsModal={productsModal}
        alertIsOpen={alertIsOpen}
        alertDegrade={alertDegrade}
        updateDegradeModal={this.updateDegradeModal}
      />
    );
  }
}

function mapStateToProps(state) {
  const product_id = state.invoiceReducer.subscription.product
    ? state.invoiceReducer.subscription.product.id
    : null;
  return {
    profile: state.loginReducer.profile,
    token: state.loginReducer.token,
    canDegradePlan: state.loginReducer.canDegradePlan,
    possibleProducts: state.productReducer.products,
    product_id,
    current_product: state.invoiceReducer.subscription.product,
    requestBoutiquePackage: state.boutiquePackageReducer.item,
    loadingBoutiquePackage: state.boutiquePackageReducer.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProfile: profile => dispatch(profileActions.updateProfile(profile)),
    getAvailableProducts: () => dispatch(productsActions.getAvailableProducts()),
    onGetMeInvoices: () => dispatch(invoiceActions.getMeInvoices()),
    //If there is a trip in progress or pending, then you cannot drop the plan.
    verifyDegradePlanRequest: () => dispatch(profileActions.verifyDegradePlanRequest()),
    findRequest: data => dispatch(requestBoutiquePackageActions.getMyRequestBoutiquePackage(data)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsContainer);
