import React, { Component } from 'react';
import StepContainer from '../../StepContainer';
import diagnosticFinished from 'assets/media/images/diagnosticFinished.jpg';
import Button from 'components/Button';
import styled from 'styled-components';

const DiagnosticFinishedContainer = styled.div`
  height: 100%;
  position: relative;
`;

const StepImage = styled.div`
  display: inline-grid;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
  width: 65%;
`;

const Text = styled.p`
  color: white;
  font-size: ${props => props.fontSize}em;
  text-align: center;
  font-weight: 600;}


`;
class DiagnosticFinishedView extends Component {
  render() {
    const { changeStep } = this.props;

    return (
      <>
        <StepContainer style={{ height: '100%' }}>
          <DiagnosticFinishedContainer>
            <StepImage>
              <Image src={diagnosticFinished} />
            </StepImage>
            <ContentContainer>
              <Text fontSize={2}>
                Congratulations—You’ve Taken the First Step to Life-Changing Travel
              </Text>
              <Text fontSize={1}>
                Our team is already hard to work going over your responses. <br /> It normally takes
                a few days to complete our analysis. <br /> While we're doing so, let's schedule a
                time to discuss what we found and determine what your next steps should be.
              </Text>
              <Text>
                <Button onClick={() => changeStep(9)}>Schedule Discussion</Button>
              </Text>
            </ContentContainer>
          </DiagnosticFinishedContainer>
        </StepContainer>
      </>
    );
  }
}

export default DiagnosticFinishedView;
