import React from 'react';
import styled from 'styled-components';
import LoginHeader from '../components/Login/LoginHeader';
import LoginBackground from '../../assets/media/images/login-background.jpeg';

import './styles.css';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
const DataSection = styled.div`
  width: 50%;
  padding-right: 5%;

  padding-left: 5%;

  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 1300px) {
    padding: 3%;
  }
`;
const ImageSection = styled.div`
  width: 50%;
  background-image: url(${LoginBackground});
  background-position-x: center;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-blend-mode: multiply;
  text-align: left;
  height: 100%;
  display: table;
  position: fixed;
  right: 0;
  top: 0;
  @media (max-width: 1000px) {
    display: none;
  }
`;
const Login = props => {
  return (
    <>
      <LoginContainer>
        <DataSection>{props.children}</DataSection>
        <ImageSection />
      </LoginContainer>
    </>
  );
};

export default Login;
