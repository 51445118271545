import React from 'react';
import styled from 'styled-components';
import ReactMapboxGl, { Popup, Marker } from 'react-mapbox-gl';
import marker from 'assets/media/icons/marker.png';
import config from 'config/appConfig';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import Tabs from 'components/Tabs';
import ListEmpty from 'components/ListEmpty';
import Modal from 'components/Modal';
import './styles.css';
import defaultImage from 'assets/media/images/default.png';
import TripCostModal from '../../components/TripCostModal';
import {
  GENERALPREFERENCES,
  ACCOMMODATION,
  FOODIEDESTINATION,
  EATERIES,
  ISVEGAN,
} from 'constants/index';

const Map = ReactMapboxGl({
  accessToken: config.MAPBOX_ACCESS_TOKEN,
});

const Container = styled.div`
  margin: 38px;
  overflow-x: auto;
  display: block;
  max-width: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}%;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
  height: ${props => props.height && props.height}px;
`;

const ScreenPart = styled.div`
  width: ${props => props.width}%;
  margin: 20px 10px;
`;
const TripDetailMap = styled.div`
  width: 55%;
  margin-top: 15px;
  margin-left: 15px;
`;

const WhiteBox = styled.div`
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  padding: 20px 10px;
  width: 99%;
  max-height: 400px;
  overflow-x: auto;
  border-radius: 10px;
  margin: ${props => props.margin}px 0;
`;

const SimpleDayBox = styled.div`
  background: #fff;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.04);
  padding-top: 20px;
  width: 99%;
  max-height: 400px;
  border-radius: 10px;
  margin: 20px 0;
`;

const Text = styled.p`
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
  margin: 4px 0;
  cursor: ${props => (props.cursor ? 'pointer' : 'initial')};
  text-align: ${props => (props.center ? 'center' : 'initial')};
`;

const FlexBox = styled.div`
  display: flex;
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'initial')};
  width: ${props => props.width}%;
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
`;

const CityImage = styled.img`
  border-radius: 7px;
  margin: 3px;
  width: 120px;
  height: 120px;
`;

const IconBox = styled.div`
  background-color: ${props => props.color}66;
  border-radius: 8px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const GetDirectionBtn = styled.div`
  padding: 0 10px;
  min-height: 35px;
  border-radius: 6px;
  background-color: #99999966;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: poinyer;
`;

const None = styled.div`
  visibility: hidden;
`;

const Mark = styled.img`
  width: 40px;
  height: 40px;
`;
const FixedContainer = styled.div`
  min-width: 1200px;
`;

const Activity = styled.div`
  display: flex;
  min-width: 300px;
`;

const ActivityImage = styled.img`
  width: 33%;
  border-radius: 15px 0 0 15px;
  object-fit: cover;
`;

const ActivityDetail = styled.div`
  padding: 15px;
  margin: 10px;
`;

const ActivityDesc = styled.div`
  max-height: 350px;
  overflow-x: auto;
  padding-right: 10px;
`;
const CloseIcon = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const CityListImage = styled.img`
  width: 37px;
  height: 37px;
  border-radius: 7px;
  margin: 3px;
  border-radius: 25px;
  margin-left: 13px;
`;

const FlightIcon = styled.div`
  background-color: #38b6ff66;
  border-radius: 25px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
`;

const SeeMore = styled.div`
  background-color: #38b6ff40;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
  height: 35px;
  cursor: pointer;
`;

const DayDetails = styled.div`
  margin: 15px;
`;

const GrayLi = styled.li`
  color: #999999;
`;

const Button = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  width: 180px;
  display: inline-block;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  letter-spacing: 1px;
  vertical-align: middle;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 1.3rem;
  padding: 1.3rem 1.3rem;
  margin: 20px;
  cursor: pointer;
  color: white;
  border: 2px solid;
  background-color: ${props => props.color || '#38b6ff'};
`;

momentDurationFormatSetup(moment);

const renderActivities = (activities, time, props) => {
  return (
    activities.length > 0 && (
      <WhiteBox margin={20}>
        <FlexBox width={100} alignItems={'start'} flexDirection={'column'}>
          <Text fontSize={27} fontWeight="600" color={'black'}>
            In The {time}
          </Text>
          {activities.map((act, key) => renderActivity(act, props, key))}
        </FlexBox>
      </WhiteBox>
    )
  );
};

const renderCustomer = (customer, props, key) => {
  return <p> {customer.first_name}</p>;
};

const renderActivity = (act, props, key) => {
  const { activity } = act;
  const {
    activityIcon,
    refreshCoordinates,
    selectActivity,
    walkingDurations,
    drivingDurations,
    geoloCationAlert,
  } = props;
  const { icon, color } = activityIcon(act.type);
  return (
    <FlexBox key={key} width={100} alignItems={'start'} flexDirection={'column'}>
      <Text fontSize={15} fontWeight="400" color={'#999999'}>
        {moment.utc(act.start_date).format('hh:mm A')} -
        {moment
          .utc(act.start_date)
          .add(activity.duration, 'minutes')
          .format('hh:mm A')}
      </Text>

      <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
        <FlexBox width={65} alignItems={'start'} flexDirection={'row'}>
          <WhiteBox margin={5}>
            <FlexBox width={100} alignItems={'baseline'} flexDirection={'row'}>
              <IconBox color={color}>
                <i className={icon} style={{ padding: '9px', color }} />
              </IconBox>
              <Text fontSize={15} fontWeight="400" color={color}>
                {activity.name}
              </Text>
            </FlexBox>
          </WhiteBox>
        </FlexBox>

        <FlexBox width={35} alignItems={'center'} flexDirection={'column'} justifyContent="center">
          <FlexBox width={100} alignItems={'center'} flexDirection={'row'} justifyContent="center">
            {geoloCationAlert && key == 0 ? (
              <Text center fontSize={15} fontWeight="400" color={'#999999'}>
                Please active the geolocation
              </Text>
            ) : (
              <>
                <Text center fontSize={15} fontWeight="400" color={'#999999'}>
                  {drivingDurations[act.position] != null ? (
                    <>
                      <i className="fas fa-car" style={{ padding: '9px', color: '#999999' }} />
                      {moment
                        .duration(drivingDurations[act.position], 'seconds')
                        .format('h [hrs], m [min]')}
                    </>
                  ) : (
                    <>
                      <i className="fas fa-car" style={{ padding: '9px', color: '#999999' }} />
                      Not found
                    </>
                  )}
                </Text>
                <Text center fontSize={15} fontWeight="400" color={'#999999'}>
                  {walkingDurations[act.position] != null ? (
                    <>
                      <i className="fas fa-walking" style={{ padding: '9px', color: '#999999' }} />
                      {moment
                        .duration(walkingDurations[act.position], 'seconds')
                        .format('h [hrs], m [min]')}
                    </>
                  ) : (
                    <>
                      <i className="fas fa-walking" style={{ padding: '9px', color: '#999999' }} />
                      Not found
                    </>
                  )}
                </Text>
              </>
            )}
          </FlexBox>
          <GetDirectionBtn
            onClick={() => {
              refreshCoordinates(activity.address);
              selectActivity(activity);
            }}
          >
            <Text fontSize={15} fontWeight="400" color={'#999999'} cursor>
              <i className="fas fa-map-marker-alt" style={{ padding: '9px', color: '#999999' }} />
              Get Directions
            </Text>
          </GetDirectionBtn>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

const renderSimpleWhiteBox = (title, content) => {
  return (
    <WhiteBox margin={20}>
      <FlexBox width={100} alignItems={'start'} flexDirection={'column'}>
        <Text fontSize={13} fontWeight="300" color={'#99999'}>
          {title}
        </Text>
        <Text fontSize={16} fontWeight="600" color={'black'}>
          {content}.
        </Text>
      </FlexBox>
    </WhiteBox>
  );
};

const renderSimpleDay = (day, startDate, index, viewDayDetail) => {
  return (
    <SimpleDayBox>
      <DayDetails>
        <Text fontSize={16} fontWeight="600" color={'#38b6ff'}>
          Day {index + 1}
        </Text>
        <Text fontSize={20} fontWeight="600" color={'black'}>
          {(startDate &&
            moment(startDate)
              .add(index, 'days')
              .format('dddd, MMM DD')) ||
            ''}
        </Text>
      </DayDetails>
      <ul>
        {day.activities.map(act => (
          <GrayLi>
            <Text fontSize={12} fontWeight="600" color={'#999999'}>
              {act.activity.name}
            </Text>
          </GrayLi>
        ))}
      </ul>

      <SeeMore onClick={() => viewDayDetail(index)}>
        <Text style={{ cursor: 'pointer' }} fontSize={14} fontWeight="600" color={'#38b6ff'}>
          See More
        </Text>
      </SeeMore>
    </SimpleDayBox>
  );
};

const CustomerTripDetails = props => {
  const {
    allDays,
    daySelected,
    changeDay,
    coordinates,
    activitySelected,
    showPopup,
    activePopup,
    tripDetails,
    currentLocation,
    filterForDayMoment,
    activeTab,
    tabs,
    viewDayDetail,
    noDaysAlert,
    noDayAlert,
    approveTripCustomer,
    tripCostModal,
    toggleTripCostModal,
    toggleAddMember,
    addMember,
    friends,
    profile,
    searchFriend,
    getFriend,
    itineraryForCustomer,
    costs,
    handleChange,
    editTripCost,
    addFriend,
  } = props;

  if (!allDays || !tripDetails) return null;

  const day = allDays[daySelected];
  const stop = itineraryForCustomer.stops.find(stop => stop.id == day.stop_id);
  return (
    <FixedContainer>
      <Container>
        <Tabs tabs={tabs} active={activeTab} lightColor />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={toggleTripCostModal}>Trip Cost</Button>
        </div>

        {activeTab == 'Day Overview' && (
          <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
            <ScreenPart width={45}>
              <FlexBox
                width={100}
                alignItems={'start'}
                flexDirection={'row'}
                justifyContent="space-between"
              >
                {daySelected == 0 ? (
                  <None />
                ) : (
                  <Text
                    fontSize={15}
                    fontWeight="400"
                    color={'#999999'}
                    onClick={() => changeDay(daySelected - 1)}
                    cursor
                  >
                    ← Back Day
                  </Text>
                )}

                <Text fontSize={15} fontWeight="400" color={'#999999'}>
                  Day {daySelected + 1} of {allDays.length}
                </Text>
                {allDays.length == daySelected + 1 ? (
                  <None />
                ) : (
                  <Text
                    fontSize={15}
                    fontWeight="400"
                    color={'#999999'}
                    onClick={() => changeDay(daySelected + 1)}
                    cursor
                  >
                    Next Day →
                  </Text>
                )}
              </FlexBox>
              <WhiteBox margin={10}>
                <FlexBox width={100} alignItems={'center'} flexDirection={'row'}>
                  <FlexBox width={85} alignItems={'flex-start'} flexDirection={'column'}>
                    <Text fontSize={15} fontWeight="400" color={'#999999'}>
                      Day {daySelected + 1}
                    </Text>
                    <FlexBox width={100} alignItems={'baseline'} flexDirection={'row'}>
                      <Text fontSize={35} fontWeight="600" color={'black'}>
                        {(tripDetails.start_date &&
                          moment(tripDetails.start_date)
                            .add(daySelected, 'days')
                            .format('dddd')) ||
                          ''}
                      </Text>
                      <Text fontSize={15} fontWeight="400" color={'#999999'}>
                        <span>
                          &nbsp;
                          {(tripDetails.start_date &&
                            moment(tripDetails.start_date)
                              .add(daySelected, 'days')
                              .format('MMM DD')) ||
                            ''}
                        </span>
                      </Text>
                    </FlexBox>
                    <Text fontSize={15} fontWeight="400" color={'#999999'}>
                      {stop.city.name}
                    </Text>
                  </FlexBox>
                  <CityImage
                    src={
                      day.activities[0] && day.activities[0].activity.image
                        ? day.activities[0].activity.image
                        : defaultImage
                    }
                  />
                </FlexBox>
              </WhiteBox>

              {renderActivities(filterForDayMoment(day.activities, 'morning'), 'Morning', props)}

              {renderActivities(
                filterForDayMoment(day.activities, 'afternoon'),
                'Afternoon',
                props,
              )}
              {renderActivities(filterForDayMoment(day.activities, 'evening'), 'Evening', props)}
              {day.activities.length == 0 && (
                <FlexBox
                  width={100}
                  alignItems={'center'}
                  flexDirection={'row'}
                  justifyContent="center"
                >
                  <ListEmpty message="This day has no activities included in the itinerary." />
                </FlexBox>
              )}
            </ScreenPart>
            <TripDetailMap>
              <Map
                style="mapbox://styles/mapbox/streets-v9"
                containerStyle={{
                  width: '100%',
                  height: '620px',
                  borderRadius: '10px',
                  position: 'relative',
                }}
                center={coordinates || currentLocation}
              >
                <Marker coordinates={coordinates || currentLocation} onClick={showPopup}>
                  <Mark src={marker} />
                </Marker>
                {activePopup && activitySelected && (
                  <Popup coordinates={coordinates || currentLocation}>
                    <Activity>
                      <CloseIcon onClick={showPopup}>
                        <i style={{ color: '#696969' }} className="fas fa-times-circle" />
                      </CloseIcon>
                      {activitySelected.image ? (
                        <ActivityImage src={activitySelected.image} />
                      ) : (
                        <ActivityImage src={defaultImage} />
                      )}
                      <ActivityDetail>
                        <FlexBox width={100} alignItems={'start'} flexDirection={'column'}>
                          <Text fontSize={25} fontWeight="400" color={'black'}>
                            {activitySelected.name}
                          </Text>
                          <ActivityDesc>
                            <Text fontSize={14} fontWeight="400" color={'#999999'}>
                              {activitySelected.description}
                            </Text>
                          </ActivityDesc>
                        </FlexBox>
                      </ActivityDetail>
                    </Activity>
                  </Popup>
                )}
              </Map>
            </TripDetailMap>
          </FlexBox>
        )}
        {activeTab == 'Trip Overview' && (
          <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
            <ScreenPart width={40}>
              <Text fontSize={35} fontWeight="600" color={'black'}>
                <span style={{ color: '#38b6ff' }}>Trip: </span> {tripDetails.title}
              </Text>
              <Text fontSize={35} fontWeight="600" color={'black'}>
                <span style={{ color: '#38b6ff' }}>Itinerary: </span> {itineraryForCustomer.title}
              </Text>

              <WhiteBox margin={20}>
                <Text fontSize={25}>
                  <span style={{ color: '#38b6ff' }}>Status: </span>
                  {tripDetails.status == 'pending' && ' Waiting for the creation of the itinerary.'}
                  {tripDetails.status == 'with_itinerary' && ' Waiting for your approval.'}

                  {tripDetails.status == 'user_approve' &&
                    itineraryForCustomer.is_default &&
                    'This itinerary was selected. Waiting for administrator approval.'}
                  {tripDetails.status == 'user_approve' &&
                    !itineraryForCustomer.is_default &&
                    'Other itinerary was selected. Waiting for administrator approval.'}

                  {tripDetails.status == 'approved' && ' Approved.'}
                  {tripDetails.status == 'finished' && ' Finished.'}
                  {tripDetails.status == 'generating_itinerary' && ' Generating itinerary.'}
                  {tripDetails.status == 'error_generating_itinerary' &&
                    ' Error generating itinerary.'}
                </Text>
                {/* If there are itineraries AND If no itinerary was selected. Or if another itinerary was selected */}
                {tripDetails.status == 'with_itinerary' ||
                (tripDetails.status == 'user_approve' && !itineraryForCustomer.is_default) ? (
                  <FlexBox width={100} alignItems={'center'} flexDirection={'column'}>
                    <Button onClick={approveTripCustomer} color="#4BC142">
                      Choose this itinerary.
                    </Button>
                  </FlexBox>
                ) : null}
              </WhiteBox>

              <WhiteBox margin={20}>
                <Text fontSize={25}>
                  <span style={{ color: '#38b6ff' }}>Type: </span>
                  {itineraryForCustomer.type}
                </Text>
              </WhiteBox>
              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Itinerary Cities
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  {itineraryForCustomer.stops &&
                    itineraryForCustomer.stops.map(({ city }) => (
                      <FlexBox alignItems={'center'} flexDirection={'row'}>
                        <Text fontSize={16} fontWeight="600" color={'black'}>
                          {city.name}
                        </Text>
                        ,
                      </FlexBox>
                    ))}
                </FlexBox>
              </WhiteBox>
              <FlexBox
                width={100}
                alignItems={'start'}
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <>
                  <FlexBox width={48} alignItems={'start'} flexDirection={'row'}>
                    <WhiteBox margin={20}>
                      <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                        <FlightIcon>
                          <i className="fas fa-plane-arrival" style={{ color: '#38b6ff' }} />
                        </FlightIcon>
                        <FlexBox width={80} alignItems={'start'} flexDirection={'column'}>
                          <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                            Arrival
                          </Text>
                          <Text fontSize={16} fontWeight="600" color={'black'}>
                            {moment(itineraryForCustomer.start_date).format('dddd, MMM DD')}
                          </Text>
                        </FlexBox>
                      </FlexBox>
                    </WhiteBox>
                  </FlexBox>
                  <FlexBox width={48} alignItems={'start'} flexDirection={'row'}>
                    <WhiteBox margin={20}>
                      <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                        <FlightIcon>
                          <i className="fas fa-plane-departure" style={{ color: '#38b6ff' }} />
                        </FlightIcon>
                        <FlexBox width={80} alignItems={'start'} flexDirection={'column'}>
                          <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                            Deapture
                          </Text>
                          <Text fontSize={16} fontWeight="600" color={'black'}>
                            {moment(itineraryForCustomer.end_date).format('dddd, MMM DD')}
                          </Text>
                        </FlexBox>
                      </FlexBox>
                    </WhiteBox>
                  </FlexBox>
                </>
              </FlexBox>

              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  General Preferences
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{GENERALPREFERENCES[tripDetails.general_preference - 1]}</Text>
                </FlexBox>
              </WhiteBox>

              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Travel With Others
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{tripDetails.travel_with_others ? 'Yes' : 'No'}</Text>
                </FlexBox>
              </WhiteBox>
              {tripDetails.know_how_many && (
                <WhiteBox margin={20}>
                  <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                    How Many
                  </Text>
                  <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                    <Text>{tripDetails.how_many}</Text>
                  </FlexBox>
                </WhiteBox>
              )}

              {tripDetails.days_quantity && (
                <WhiteBox margin={20}>
                  <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                    Days Quantity
                  </Text>
                  <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                    <Text>{tripDetails.days_quantity}</Text>
                  </FlexBox>
                </WhiteBox>
              )}

              {tripDetails.month_ranges.length > 0 && (
                <WhiteBox margin={20}>
                  <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                    Month Ranges
                  </Text>
                  <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                    <Text>{tripDetails.month_ranges.map(tWith => `${tWith} / `)}</Text>
                  </FlexBox>
                </WhiteBox>
              )}

              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Accommodation Preference
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{ACCOMMODATION[tripDetails.accommodation - 1]}</Text>
                </FlexBox>
              </WhiteBox>
              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Fooddie Preference
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{FOODIEDESTINATION[tripDetails.foodie_destination - 1]}</Text>
                </FlexBox>
              </WhiteBox>

              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Eateries Preference
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{EATERIES[tripDetails.eateries - 1]}</Text>
                </FlexBox>
              </WhiteBox>

              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Vegan
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{ISVEGAN[tripDetails.is_vegan - 1]}</Text>
                </FlexBox>
              </WhiteBox>

              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Rental Car
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{tripDetails.rental_car ? 'Yes' : 'No'}</Text>
                </FlexBox>
              </WhiteBox>

              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Humidity Levels
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{tripDetails.humidity_levels.map(hume => `${hume} /`)}</Text>
                </FlexBox>
              </WhiteBox>

              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Raining Level
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{tripDetails.raining_level}</Text>
                </FlexBox>
              </WhiteBox>
              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Landscapes
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{tripDetails.landscapes.map(land => `${land} /`)}</Text>
                </FlexBox>
              </WhiteBox>
              <WhiteBox margin={20}>
                <Text fontSize={13} fontWeight="300" color={'#38b6ff'}>
                  Temperature Ranges
                </Text>
                <FlexBox width={100} alignItems={'start'} flexDirection={'row'}>
                  <Text>{tripDetails.temperature_ranges.map(rang => `${rang} /`)}</Text>
                </FlexBox>
              </WhiteBox>
            </ScreenPart>

            <ScreenPart width={60}>
              <Text fontSize={25} fontWeight="400" color={'black'}>
                Days Details
              </Text>
              <FlexBox
                wrap
                width={100}
                alignItems={'start'}
                flexDirection={'row'}
                justifyContent="space-between"
              >
                {allDays.length > 0 &&
                tripDetails.status != 'generating_itinerary' &&
                tripDetails.status != 'error_generating_itinerary' ? (
                  allDays.map((day, index) => (
                    <FlexBox width={48} alignItems={'start'} flexDirection={'row'}>
                      {renderSimpleDay(day, tripDetails.start_date, index, viewDayDetail)}
                    </FlexBox>
                  ))
                ) : (
                  <FlexBox
                    width={100}
                    alignItems={'center'}
                    flexDirection={'row'}
                    justifyContent="center"
                  >
                    <ListEmpty message="This trip has no days included in the itinerary." />
                  </FlexBox>
                )}
              </FlexBox>
            </ScreenPart>
          </FlexBox>
        )}
        <Modal
          closeIcon
          isVisible={noDaysAlert}
          title="Trip Without Days"
          cancelText="Cancel"
          successText="Ok"
          onSuccess={noDayAlert}
        >
          This trip has no days included in the itinerary.
        </Modal>
      </Container>

      <TripCostModal
        addFriend={addFriend}
        handleChange={handleChange}
        editTripCost={editTripCost}
        profile={profile}
        tripCostModal={tripCostModal}
        toggleTripCostModal={toggleTripCostModal}
        toggleAddMember={toggleAddMember}
        addMember={addMember}
        friends={friends}
        searchFriend={searchFriend}
        getFriend={getFriend}
        costs={costs}
      ></TripCostModal>
    </FixedContainer>
  );
};

export default CustomerTripDetails;
