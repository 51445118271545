import React, { Component } from 'react';
import ExplanationResultsView from './ExplanationResultsView';
import { connect } from 'react-redux';
import { replace } from 'modules/history';
import * as diagnosticActions from '../../../actions/diagnosticActions';
import { selectFields } from './StepFields/ExplanationResutls';
import {
  calculateTravelPersonalityResult,
  calculateCoreStrengthsResult,
  calculateFundationalStrengthsResult,
  calculateWellBeingResults,
} from 'helpers/diagnosticResults';
class ExplanacionResults extends Component {
  constructor(props) {
    super(props);
    this.state = { localStep: 1 };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps != this.props &&
      this.props.diagnostics.wellBeings &&
      this.props.diagnostics.travelPersonalities &&
      this.props.diagnostics.strengths
    ) {
      const wellBeingResults = calculateWellBeingResults(this.props.diagnostics);
      const fundationalStrengthsResult = calculateFundationalStrengthsResult(
        this.props.diagnostics,
      );
      const coreStrengthsResult = calculateCoreStrengthsResult(this.props.diagnostics);
      const travelPersonalityResult = calculateTravelPersonalityResult(this.props.diagnostics);
      this.setState({
        wellBeingResults,
        fundationalStrengthsResult,
        travelPersonalityResult,
        coreStrengthsResult,
      });
    }
  }

  changeStep = step => {
    if (1 <= step && step <= 9) {
      this.setState({ localStep: step });
    } else {
      replace('/');
    }
  };

  render() {
    let stepFields = selectFields(this.state.localStep);
    return (
      <ExplanationResultsView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        stepFields={stepFields}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    diagnostics: state.diagnosticReducer,
    meeting: state.meetingReducer.meeting,
    loadingMeeting: state.meetingReducer.loadingMeeting,
  };
}

export default connect(mapStateToProps)(ExplanacionResults);
