/*
 * Reducer actions related with register
 */
import { ActionTypes } from 'constants/index';

export function registerStep(newProperties) {
  return {
    type: ActionTypes.REGISTER_ADD_PROPERTIES,
    newProperties,
  };
}
export function changeCurrentStep(step) {
  return {
    type: ActionTypes.REGISTER_CHANGE_CURRENT_STEP,
    step,
  };
}
export function registerNewUser(newUser) {
  return {
    type: ActionTypes.REGISTER_REQUEST,
    newUser,
  };
}

export function registerFinished() {
  return {
    type: ActionTypes.REGISTER_FINISHED,
  };
}
