import React, { Component } from 'react';
import PreTripCoursesView from './PreTripCoursesView';
import { connect } from 'react-redux';
import * as preTripCourseActions from '../../actions/preTripCourseActions';

class PreTripCoursesContainer extends Component {
  state = { currentPage: 1, elementsPerPage: 12, isOpen: false };

  componentDidMount() {
    const { getPreTripCourses } = this.props;
    const { currentPage, elementsPerPage } = this.state;
    getPreTripCourses(currentPage, elementsPerPage);
  }

  changePage = currentPage => {
    const { getPreTripCourses } = this.props;
    const { elementsPerPage } = this.state;
    getPreTripCourses(currentPage, elementsPerPage);
    this.setState({ currentPage });
  };

  toggleModal = preTripCourseSelected => {
    const { isOpen } = this.state;

    if (!isOpen) {
      window.scrollTo(0, 0);
    }

    this.setState({
      isOpen: !isOpen,
      preTripCourseSelected,
    });
  };

  deletePreTripCourse = () => {
    const { onDeletePreTripCourse } = this.props;
    const { preTripCourseSelected } = this.state;

    onDeletePreTripCourse(preTripCourseSelected);

    this.setState({
      isOpen: false,
      preTripCourseSelected,
    });
  };

  render() {
    const { currentPage, elementsPerPage, isOpen } = this.state;

    return (
      <PreTripCoursesView
        {...this.props}
        currentPage={currentPage}
        changePage={this.changePage}
        elementsPerPage={elementsPerPage}
        toggleModal={this.toggleModal}
        deletePreTripCourse={this.deletePreTripCourse}
        isOpen={isOpen}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    preTripCourses: state.preTripCourseReducer.preTripCourses,
    preTripCoursesCount: state.preTripCourseReducer.preTripCoursesCount,
    loading: state.preTripCourseReducer.loading,
    role: state.loginReducer.profile.role_code,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getPreTripCourses: (page, elementsPerPage) =>
      dispatch(preTripCourseActions.getPreTripCourses(page, elementsPerPage)),
    onDeletePreTripCourse: id => dispatch(preTripCourseActions.deletePreTripCourse(id)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreTripCoursesContainer);
