import { ActionTypes } from 'constants/index';

export function createGoalRequest(data) {
  return {
    type: ActionTypes.CREATE_GOAL_REQUEST,
    data,
  };
}

export function getGoalRequest(id) {
  return {
    type: ActionTypes.GET_GOAL_REQUEST,
    id,
  };
}

export function getGoalSuccess(goal) {
  return {
    type: ActionTypes.GET_GOAL_SUCCESS,
    goal,
  };
}

export function updateGoalRequest(id, data) {
  return {
    type: ActionTypes.UPDATE_GOAL_REQUEST,
    id,
    data,
  };
}
