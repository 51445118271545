import React, { Component } from 'react';
import HowUseView from './JournalHowUseView';
import { connect } from 'react-redux';
import * as journalActions from 'actions/journalActions';
import { selectFields } from './StepFields/HowUse';

class HowUse extends Component {
  constructor(props) {
    super(props);
    this.state = { localStep: 1 };
  }

  changeStep = step => {
    if (1 <= step && step <= 7) {
      this.setState({ localStep: step });
    } else {
      const {
        journal,
        updateJournalRequest,
        match: { params },
      } = this.props;

      updateJournalRequest({
        second_introduction_completed: true,
        id: params.id,
      });
    }
  };

  render() {
    const stepFields = selectFields(this.state.localStep);

    return (
      <HowUseView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        stepFields={stepFields}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    journal: state.journalReducer.journal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateJournalRequest: journal => dispatch(journalActions.updateJournalRequest(journal)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HowUse);
