import { put, call } from 'redux-saga/effects';
import * as activityActions from 'actions/activityActions';
import * as appActions from 'actions/app';
import { replace } from 'modules/history';

export function* getActivitiesAsync(api, action) {
  const { page, elementsPerPage } = action;
  const { status, data } = yield call(api.getActivities, { page, elementsPerPage });

  if (status === 200) {
    yield put(activityActions.getActivitiesSuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* getActivityAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.getActivity, id);
  if (status === 200) {
    yield put(activityActions.getActivitySuccess(data));
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* getMapboxCoordinates(api, action) {
  const response = yield call(api.getMapboxCoordinates, action.address);
  if (response.status === 200) {
    const options = response.data.features.map(opt => {
      const { center, place_name } = opt;
      return { center, place_name };
    });
    yield put(activityActions.savePlaces(options));
  }
}

export function* getMapboxAddress(api, action) {
  const response = yield call(api.getMapboxAddress, action.longLat);
  if (response.status === 200) {
    const options = response.data.features.map(opt => {
      const { center, place_name } = opt;
      return { center, place_name };
    });
    yield put(activityActions.savePlaces(options));
  }
}

export function* saveActivity(api, action) {
  const response = yield call(api.saveActivity, action.activity);
  if (response.status === 200) {
    yield put(appActions.showAlert('Activity saved!', { variant: 'success' }));
    yield replace(`/activities`);
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}
export function* removeActivityAsync(api, action) {
  const { id } = action;
  const { status, data } = yield call(api.deleteActivity, id);

  if (status === 204) {
    yield put(appActions.showAlert('Activity deleted.', { variant: 'success' }));
    const response = yield call(api.getActivities, { page: 1, elementsPerPage: 10 });

    if (response.status === 200) {
      yield put(activityActions.getActivitiesSuccess(response.data));
    } else {
      yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
    }
  } else {
    yield put(appActions.showAlert(data.message, { variant: 'danger' }));
  }
}

export function* updateActivityAsync(api, action) {
  const response = yield call(api.updateActivities, action.id, action.activity);
  if (response.status === 200) {
    yield replace(`/activities`);
    yield put(appActions.showAlert('Activity updated!', { variant: 'success' }));
  } else {
    yield put(appActions.showAlert(response.data.message, { variant: 'danger' }));
  }
}

export function* getMapboxDistanceTime(api, action) {
  yield put(activityActions.resetDistanceTimeDriving());

  const drivingResponse = yield call(api.getMapboxDistanceTime, action.points, 'driving');

  const walkingResponse = yield call(api.getMapboxDistanceTime, action.points, 'walking');

  for (let step = 0; step < drivingResponse.length; step++) {
    const res = drivingResponse[step];
    if (res.status == 200 && res.data.code == 'Ok') {
      const durations = res.data.routes[0].legs[0].duration;
      yield put(activityActions.saveDistanceTimeDriving(durations));
    } else {
      yield put(activityActions.saveDistanceTimeDriving(null));
    }
  }

  for (let step = 0; step < walkingResponse.length; step++) {
    const res = walkingResponse[step];
    if (res.status == 200 && res.data.code == 'Ok') {
      const durations = res.data.routes[0].legs[0].duration;
      yield put(activityActions.saveDistanceTimeWalking(durations));
    } else {
      yield put(activityActions.saveDistanceTimeWalking(null));
    }
  }
}
