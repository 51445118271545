import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ActivityList from 'components/ActivityList';
import Pagination from 'components/PaginationButtons';
import Modal from 'components/Modal';

const Container = styled.div``;

const HeaderContainer = styled.div`
  margin: 38px;
  padding-bottom: 5px;
  position: relative;
  width: auto;
  position: relative;
  height: 50px;
`;

const Button = styled.button`
  border-radius: 5px;
  color: white;
  background-color: #38b6ff;
  border: 1px solid #38b6ff;
  padding: 15px 25px;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  right: 0px;
`;

const Accommodations = props => {
  const {
    currentPage,
    changePage,
    accommodationsCount,
    elementsPerPage,
    accommodations,
    isOpen,
    toggleModal,
    removeAccommodation,
    loading,
  } = props;

  return (
    <Container>
      <HeaderContainer>
        <Pagination
          currentPage={currentPage}
          changePage={changePage}
          totalPages={Math.ceil(accommodationsCount / elementsPerPage)}
        />
        <Link to="/accommodations/create">
          <Button>Create Accommodation</Button>
        </Link>
      </HeaderContainer>
      <ActivityList
        toEdit="/accommodations"
        activities={accommodations}
        onRemoveActivity={toggleModal}
        loading={loading}
      />
      <Modal
        closeIcon
        isVisible={isOpen}
        title="DELETE ACCOMODATION"
        cancelText="Cancel"
        onCancel={toggleModal}
        successText="Remove"
        onSuccess={removeAccommodation}
      >
        Are you sure of remove the accommodation?
      </Modal>
    </Container>
  );
};

export default Accommodations;
