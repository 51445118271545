import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  authenticPlan: null,
};

export default {
  authenticPlanReducer: handleActions(
    {
      [ActionTypes.GET_AUTHENTIC_PLAN_SUCCESS](state, action) {
        return {
          ...state,
          authenticPlan: action.authenticPlan,
        };
      },
    },
    appState,
  ),
};
