/**
 * Configuration
 * @module config
 */

const config = {
  name: 'Authentic Traveling',
  description: 'Authentic Traveling',
};

export default config;
