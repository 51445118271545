import React, { Component } from 'react';
import TheDeaptureView from './JournalTheDeaptureView';
import { connect } from 'react-redux';
import * as journalActions from 'actions/journalActions';
import { selectFields } from './StepFields/TheDeapture';

class TheDeapture extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const {
      getDeaptureRequest,
      match: { params },
    } = this.props;
    getDeaptureRequest(params.id, params.day);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.deapture && this.props.deapture) ||
      (prevProps.deapture && prevProps.deapture != this.props.deapture)
    ) {
      const { message_morning, message_today, message_evening } = this.props.deapture;
      this.setState({ message_morning, message_today, message_evening });
    }
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  saveDay = () => {
    const {
      createDayDeaptureRequest,
      match: { params },
      day,
      deapture: { id },
    } = this.props;
    const { message_morning, message_today, message_evening } = this.state;

    createDayDeaptureRequest(id, {
      message_morning,
      message_today,
      message_evening,
      status: 'completed',
    });
  };

  render() {
    const { day, deapture } = this.props;
    if (!deapture) return null;
    const { message_morning, message_today, message_evening } = this.state;
    const stepFields = selectFields(+day);

    return (
      <TheDeaptureView
        {...this.props}
        saveDay={this.saveDay}
        stepFields={stepFields}
        currentDay={day}
        message_morning={message_morning}
        message_today={message_today}
        message_evening={message_evening}
        handleChange={this.handleChange}
        date={deapture.date}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    deapture: state.journalReducer.deapture,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDayDeaptureRequest: (id, deapture) =>
      dispatch(journalActions.createDayDeaptureRequest(id, deapture)),
    getDeaptureRequest: (journal_id, day) =>
      dispatch(journalActions.getDeaptureRequest({ journal_id, day })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TheDeapture);
