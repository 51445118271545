import styled from 'styled-components';

export default styled.button`
  border-radius: 5px;
  color: white;
  background-color: #00b4d51b;
  border: 1px solid #00b4d51b;
  width: 150px;
  height: 50px;
  color: #38b6ff;
  margin: 0px 5px;
  cursor: pointer;
`;
