import React, { Component } from 'react';
import StepButtons from '../../StepButtons';
import styled from 'styled-components';
import SelectedButton from '../../SelectedButton';
import PrimaryText from '../PrimaryText';
import { DAYS_QUANTITY } from 'constants/index';
import { Formik } from 'formik';

const TravelingForBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TravelingForPrimaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
`;

const Container = styled.div`
  height: 90%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

class SelectDatesRangeView extends Component {
  render() {
    const { step, know_dates, changeStep, days_quantity } = this.props;

    return (
      <TravelingForBox>
        <TravelingForPrimaryBox>
          <PrimaryText text="Approximately how many days would you like this journey to last?" />

          <Formik enableReinitialize initialValues={{ days_quantity }} isInitialValid={true}>
            {({ values, handleChange, errors, handleSubmit, setFieldValue }) => {
              return (
                <Container>
                  <Content>
                    {DAYS_QUANTITY.map(daysQuantity => (
                      <SelectedButton
                        text={daysQuantity}
                        onClick={() => setFieldValue('days_quantity', daysQuantity)}
                        active={values.days_quantity == daysQuantity}
                      />
                    ))}
                  </Content>

                  <StepButtons
                    nextDisabled={!values.days_quantity}
                    backStep={() => changeStep(know_dates == 2 ? step - 1 : step - 3, values)}
                    nextStep={() => changeStep(step + 1, values)}
                  />
                </Container>
              );
            }}
          </Formik>
        </TravelingForPrimaryBox>
      </TravelingForBox>
    );
  }
}

export default SelectDatesRangeView;
