import React, { Component } from 'react';
import TravelingWithView from './TravelingWithView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';

class HaveDates extends Component {
  changeStep = (step, values) => {
    const { changeCurrentStep, saveTripData } = this.props;
    changeCurrentStep(step);
    saveTripData(values);
  };

  render() {
    return (
      <TravelingWithView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        changeTravelingWith={this.changeTravelingWith}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    traveling_with: state.tripReducer.tripForm.traveling_with,
    children_olds: state.tripReducer.tripForm.children_olds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HaveDates);
