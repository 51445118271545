import React from 'react';
import styled from 'styled-components';

const RadioContainer = styled.div`
  display: grid;
  margin-top: 15px;
`;
const RadioButtonsBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.verticalAlign ? 'column' : 'row')};
  margin: ${props => (props.verticalAlign ? '5px' : 'initial')};
  @media (max-width: 1300px) {
    flex-direction: column;
    margin: 5px 0%;
  }
`;
const Option = styled.span`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 13px;
  cursor: pointer;
`;
const RadioButton = styled.div`
  display: flex;
  border-radius: 5px;
  color: white;
  border: 1px solid;
  border-color: ${props => (props.checked ? '#38b6ff' : '#999999')};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  color: ${props => (props.checked ? '#38b6ff' : '#999999')};
  margin-top: 10px;
  margin-bottom: 10px;
  width: ${props => (props.verticalAlign ? '100%' : '170px')};
  align-items: center;
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 100%;
  }
`;
const OptionLabel = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  text-align: center;
  color: #38b6ff;
  font-size: 23px;
  margin-top: 0;
  margin-bottom: 0;
  height: 35px;
  @media (max-width: 500px) {
    height: 80px;
  }
`;

export default props => (
  <RadioContainer style={props.style}>
    <OptionLabel>{props.label}</OptionLabel>
    <RadioButtonsBox verticalAlign={props.verticalAlign}>
      {props.options.map((option, index) => {
        let numericValue;
        if (option.numericValue != undefined) {
          numericValue = option.numericValue;
        } else {
          if (props.reverseScored) {
            numericValue = props.options.length - index;
          } else {
            numericValue = index + 1;
          }
        }

        return (
          <RadioButton
            key={index}
            checked={props.currentValue == numericValue}
            onClick={() => props.handleChange(props.name, numericValue)}
            verticalAlign={props.verticalAlign}
          >
            {props.currentValue == numericValue ? (
              <i
                className="fas fa-check-circle"
                style={{
                  color: '#38b6ff',
                  marginLeft: '8px',
                  marginRight: '12px',
                }}
              ></i>
            ) : (
              <i
                className="fas fa-circle"
                style={{
                  color: '#999999',
                  marginLeft: '8px',
                  marginRight: '12px',
                }}
              ></i>
            )}

            <Option>{option.text || option}</Option>
          </RadioButton>
        );
      })}
    </RadioButtonsBox>
  </RadioContainer>
);
