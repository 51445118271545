import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  transactions: [],
  loading: false,
};

export default {
  transactionReducer: handleActions(
    {
      [ActionTypes.GET_ME_TRANSACTIONS](state) {
        return {
          ...state,
          loading: true,
        };
      },
      [ActionTypes.SAVE_ME_TRANSACTIONS](state, action) {
        return {
          ...state,
          transactions: action.transactions,
          loading: false,
        };
      },
    },
    appState,
  ),
};
