import React, { Component } from 'react';
import StepThreeView from './StepTwoView';
import { connect } from 'react-redux';
import * as registerActions from '../../../actions/registerActions';
import * as productsActions from '../../../actions/productsActions';

import { Elements, injectStripe } from 'react-stripe-elements';

class StepThreeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedPlan: 1, productsModal: false };
  }

  componentDidMount() {
    const { newUser, getAvailableProducts } = this.props;
    getAvailableProducts();
    if (newUser) {
      this.setState({ selectedPlan: newUser.selectedPlan });
    }
  }

  changeStep = step => {
    const { registerThirdStep, changeCurrentStep } = this.props;
    if (step == 3) {
      registerThirdStep(this.state);
    }
    changeCurrentStep(step);
  };

  selectPlan = selectedPlan => {
    this.setState({ selectedPlan }, () => this.changeStep(3));
  };

  changeProductsModal = () => {
    this.setState({ productsModal: !this.state.productsModal });
  };

  render() {
    const { selectedPlan, productsModal } = this.state;

    return (
      <StepThreeView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        selectPlan={this.selectPlan}
        selectedPlan={selectedPlan}
        changeProductsModal={this.changeProductsModal}
        productsModal={productsModal}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    newUser: state.registerReducer.newUser,
    products: state.productReducer.products,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    registerThirdStep: newUser => dispatch(registerActions.registerStep(newUser)),
    changeCurrentStep: step => dispatch(registerActions.changeCurrentStep(step)),
    getAvailableProducts: () => dispatch(productsActions.getAvailableProducts()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepThreeContainer);
