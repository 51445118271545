import React, { Component } from 'react';
import RegisterView from './RegisterView';
import { connect } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import config from '../../config/appConfig';

class RegisterContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StripeProvider apiKey={config.STRIPE_PUBLIC_KEY}>
        <RegisterView {...this.props} step={this.props.step} />
      </StripeProvider>)
  }
}

function mapStateToProps(state) {
  return { step: state.registerReducer.step };
}
export default connect(
  mapStateToProps,
  null,
)(RegisterContainer);
