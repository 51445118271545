import React, { Component } from 'react';
import SelectDatesView from './SelectDatesView';
import { connect } from 'react-redux';
import * as tripActions from '../../../actions/tripActions';

class HaveDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      departure_time: '',
      end_date: null,
      arriving_time: '',
    };
  }

  componentDidMount() {
    const { start_date, departure_time, end_date, arriving_time } = this.props;
    this.setState({
      start_date: start_date ? new Date(start_date) : null,
      departure_time,
      end_date: end_date ? new Date(end_date) : null,
      arriving_time,
    });
  }

  changeStep = step => {
    const { changeCurrentStep, saveTripData } = this.props;
    const { start_date, departure_time, end_date, arriving_time } = this.state;
    changeCurrentStep(step);
    saveTripData({ start_date, departure_time, end_date, arriving_time });
  };

  changeDate = (value, date) => {
    const newDate = { [value]: date };
    this.setState({
      ...newDate,
    });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  render() {
    return (
      <SelectDatesView
        {...this.props}
        changeStep={this.changeStep}
        state={this.state}
        changeDate={this.changeDate}
        handleChange={this.handleChange}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    start_date,
    departure_time,
    end_date,
    arriving_time,
    know_dates,
  } = state.tripReducer.tripForm;

  return {
    start_date,
    departure_time,
    end_date,
    arriving_time,
    know_dates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCurrentStep: step => dispatch(tripActions.changeCurrentStep(step)),
    saveTripData: data => dispatch(tripActions.saveTripData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HaveDates);
