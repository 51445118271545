export const defaultState = {
  growth_joy: 5,
  growth_contentment: 5,
  growth_positivity: 5,
  stunting_anger: 5,
  stunting_anxiety: 5,
  stunting_sadness: 5,
  immersion_absorb: 5,
  immersion_track_of_time: 5,
  immersion_interest: 5,
  connections_personal_relationships: 5,
  connections_receive_help: 5,
  connections_loved: 5,
  significance_life_direction: 5,
  significance_life_worthwhile: 5,
  significance_life_purpose: 5,
  achievement_spend_time_goals: 5,
  achievement_handle_responsibilities: 5,
  achievement_accomplish_goals: 5,
  fitness_health_prevent_do: 5,
  fitness_dissatisfied: 5,
  fitness_overall_fitness: 5,
  overall_well_being: 5,
  localStep: 1,
};
export const selectFields = step => {
  switch (step) {
    case 1:
      return {
        title: 'Growth Emotions',
        subtitle: 'In your daily life, how often do you feel the following emotions?',
        minQuantityLabel: 'Never',
        mediumQuantityLabel: 'Sometimes',
        mediumQuantityValue: 5,
        minQuantityValue: 0,
        maxQuantityLabel: 'Always',
        maxQuantityValue: 10,
        content: [
          { label: 'Positivity', name: 'growth_positivity' },
          { label: 'Contentment', name: 'growth_contentment' },
          { label: 'Joy', name: 'growth_joy' },
        ],
      };
    case 2:
      return {
        title: 'Stunting Emotions',
        subtitle: 'In your daily life, how often do you feel the following emotions?',
        minQuantityLabel: 'Never',
        mediumQuantityLabel: 'Sometimes',
        mediumQuantityValue: 5,
        minQuantityValue: 0,
        maxQuantityLabel: 'Always',
        maxQuantityValue: 10,
        content: [
          {
            label: 'Anger',
            name: 'stunting_anger',
          },
          {
            label: 'Anxiety',
            name: 'stunting_anxiety',
          },
          {
            label: 'Sadness',
            name: 'stunting_sadness',
          },
        ],
      };
    case 3:
      return {
        title: 'Active Immersion',
        subtitle: 'In your daily life, how often do the following things occur?',
        minQuantityLabel: 'Never',
        mediumQuantityLabel: 'Sometimes',
        mediumQuantityValue: 5,
        minQuantityValue: 0,
        maxQuantityLabel: 'Always',
        maxQuantityValue: 10,
        content: [
          {
            label: 'You become completely absorbed in what you’re doing.',
            name: 'immersion_absorb',
          },
          {
            label: 'You lose track of time when doing an enjoyable activity.',
            name: 'immersion_track_of_time',
          },
          {
            label: 'You feel interested and excited by thoughts or activities.',
            name: 'immersion_interest',
          },
        ],
      };
    case 4:
      return {
        title: 'Connections',
        subtitle: 'How well do the following statements describe your life?',
        minQuantityLabel: 'Not at all',
        mediumQuantityLabel: 'Somewhat',
        mediumQuantityValue: 5,
        minQuantityValue: 0,
        maxQuantityLabel: 'Completely',
        maxQuantityValue: 10,
        content: [
          {
            label: 'You are completely satisfied with your personal relationships.',
            name: 'connections_personal_relationships',
          },
          {
            label: 'You receive help and support from others when you need it.',
            name: 'connections_receive_help',
          },
          {
            label: 'You feel loved.',
            name: 'connections_loved',
          },
        ],
      };
    case 5:
      return {
        title: 'Significance',
        subtitle: 'How well do the following statements describe your life?',
        minQuantityLabel: 'Not at all',
        mediumQuantityLabel: 'Somewhat',
        mediumQuantityValue: 5,
        minQuantityValue: 0,
        maxQuantityLabel: 'Completely',
        maxQuantityValue: 10,
        content: [
          {
            label: 'You feel as though you generally have direction.',
            name: 'significance_life_direction',
          },
          {
            label: 'You feel your life is worthwhile.',
            name: 'significance_life_worthwhile',
          },
          {
            label: 'You lead a life filled with purpose and meaning.',
            name: 'significance_life_purpose',
          },
        ],
      };
    case 6:
      return {
        title: 'Achievement',
        subtitle: 'How well do the following statements describe your life?',
        minQuantityLabel: 'Not at all',
        mediumQuantityLabel: 'Somewhat',
        mediumQuantityValue: 5,
        minQuantityValue: 0,
        maxQuantityLabel: 'Completely',
        maxQuantityValue: 10,
        content: [
          {
            label: 'You regularly spend time making progress towards achieving your goals.',
            name: 'achievement_spend_time_goals',
          },
          {
            label: 'You are able to handle your commitments and responsibilities.',
            name: 'achievement_handle_responsibilities',
          },
          {
            label: 'You regularly accomplish important goals you set for yourself.',
            name: 'achievement_accomplish_goals',
          },
        ],
      };
    case 7:
      return {
        title: 'Fitness',
        subtitle: 'Let’s talk about your physical well-being for a bit.',
        minQuantityLabel: 'Never',
        mediumQuantityLabel: 'Sometimes',
        mediumQuantityValue: 5,
        minQuantityValue: 0,
        maxQuantityLabel: 'All the time',
        maxQuantityValue: 10,
        content: [
          {
            label: 'In comparison to others your age and sex, how is your overall fitness?',
            name: 'fitness_overall_fitness',
            importantMinQuantityLabel: 'Terrible',
            importantMaxQuantityValue: 'Excellent',
          },
          {
            label:
              'How often does your physical health prevent you from doing things that you otherwise would do?',
            name: 'fitness_health_prevent_do',
          },
          {
            label: 'How often do you feel dissatisfied with your overall fitness?',
            name: 'fitness_dissatisfied',
          },
        ],
      };
    case 8:
      return {
        title: 'Well-Being',
        subtitle: 'Rate your overall well-being.',
        minQuantityLabel: 'Terrible',
        minQuantityValue: 0,
        maxQuantityLabel: 'Excellent',
        maxQuantityValue: 10,
        content: [
          {
            label: 'All things considered, how would you rate your overall well-being?',
            name: 'overall_well_being',
          },
        ],
      };
  }
};
